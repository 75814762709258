/* eslint-disable */
import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FieldTitle, useResourceContext, useTranslate, useLocale } from 'ra-core';
import React, { memo } from 'react';
import noop from 'lodash/noop';
import get from 'lodash/get';

// remove the sort icons when not active
const useStyles = makeStyles(
  {
    icon: {
      display: 'none',
    },
    active: {
      '& $icon': {
        display: 'inline',
      },
    },
  },
  {
    name: 'RaDatagridHeaderCell',
  },
);

export const DatagridHeaderCell = (props) => {
  const { className, classesObj: classesOverride, field, currentSort, updateSort, disableSort, ...rest } = props;
  const resource = useResourceContext(props);
  const classes = useStyles(props);
  const translate = useTranslate();
  const locale = useLocale();

  let label = get(field, ['props', 'label']);
  const translatableInput = get(field, ['props', 'translatableInput']);

  // TRANSLATABLE_INPUT - GET COLUMN LABEL FOR HEADER TABLE
  if (translatableInput) {
    // label = `${translate(label)} (${locale})`;
  }

  const FieldTitleRef = React.forwardRef((props, ref) => (
    <FieldTitle innerRef={ref} label={label} source={field.props.source} resource={resource} />
  ));

  const sortingHandler = {
    'data-sort': field.props.sortBy || field.props.source, // @deprecated. Use data-field instead.
    'data-field': field.props.sortBy || field.props.source,
    'data-order': field.props.sortByOrder || 'ASC',
    onClick: disableSort ? noop : updateSort,
  };

  const tooltipLabel = translate(field.props.tooltip || (disableSort ? '' : 'ra.action.sort'));

  return (
    <TableCell
      className={classnames(className, field.props.headerClassName)}
      align={field.props.textAlign}
      variant="head"
      {...sortingHandler}
      {...rest}
    >
      <Tooltip title={tooltipLabel} placement="top-start" disableHoverListener={!tooltipLabel}>
        {field.props.sortable !== false && (field.props.sortBy || field.props.source) ? (
          <TableSortLabel
            active={currentSort.field === (field.props.sortBy || field.props.source)}
            direction={currentSort.order === 'ASC' ? 'asc' : 'desc'}
            classes={classes}
          >
            <FieldTitle label={label} source={field.props.source} resource={resource} />
          </TableSortLabel>
        ) : (
          <FieldTitleRef />
        )}
      </Tooltip>
    </TableCell>
  );
};

DatagridHeaderCell.propTypes = {
  className: PropTypes.string,
  classesObj: PropTypes.object,
  field: PropTypes.element,
  currentSort: PropTypes.shape({
    sort: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  disableSort: PropTypes.bool,
  resource: PropTypes.string,
  updateSort: PropTypes.func.isRequired,
};

export default memo(
  DatagridHeaderCell,
  (props, nextProps) =>
    props.updateSort === nextProps.updateSort &&
    props.currentSort.field === nextProps.currentSort.field &&
    props.currentSort.order === nextProps.currentSort.order &&
    props.resource === nextProps.resource &&
    props.disableSort === nextProps.disableSort &&
    props.className === nextProps.className,
);
