/* eslint-disable max-len, no-undef */
import React, { useMemo } from 'react';
import { required, AutocompleteInput, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

const SpinTypeSelector = ({
  data, ...otherProps
}) => {
  const translate = useTranslate();
  const { values: formValues } = useFormState();

  const choices = useMemo(
    () => data.map(item => ({
      ...item,
      name: item.name,
    })),
    [data],
  );

  return (
    <AutocompleteInput
      variant="outlined"
      label={translate('resources.prepaid.fields.spinType', {
        _: 'Spin Type',
      })}
      source="spinTypeId"
      disabled={!formValues.gameId}
      choices={choices}
      validate={required()}
      {...otherProps}
    />
  );
};

SpinTypeSelector.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

SpinTypeSelector.defaultProps = {
  data: [],
};

export default SpinTypeSelector;
