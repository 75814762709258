import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

function SkeletonItem(props) {
  const { width } = props;

  return (
    <Box
      padding={2}
      display="flex"
      alignItems="center"
    >
      <Box
        padding="0 9px"
        marginRight={4}
      >
        <Skeleton
          variant="rect"
          width={24}
          height={24}
        />
      </Box>
      <Box
        width={42}
        marginRight={4}
        flex="1"
      >
        <Skeleton
          variant="rect"
          width={width}
          height={30}
        />
      </Box>
    </Box>
  );
}

SkeletonItem.propTypes = {
  width: PropTypes.string,
};

SkeletonItem.defaultProps = {
  width: '100%',
};

export default SkeletonItem;
