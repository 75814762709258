/* eslint-disable */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { kebabCase, get, isEmpty, omit } from 'lodash';
import { Datagrid, ImageField, ReferenceField, SingleFieldList, TextField, useTranslate } from 'react-admin';
import { ChipField, IdField, RefField, NumberField, ArrayField, JsonField, BooleanField } from '../ra/fields';
import CustomLabeled from '../ra/labeled';
import { guessFieldComponent } from './wealth-component.guesser';
import { useViewType } from '../../hooks';
import Empty from '../../components/empty';
import { checkArrayReferenceField } from '../../../services/util';
import CustomLabel from '../../../custom-core-admin/CustomLabel';
import LinkField from '../ra/fields/link.field';
import set from 'lodash/set';
import { canI, RbacAction } from '../../../services/provider/rbacProvider';

const getSourceRefTitle = (source, properties) => {
  // Use defined `optionText` as ref title
  const definedTitle = Object.keys(properties)?.filter((key) => {
    return properties?.[key]?.properties?.optionText?.default;
  })?.[0];

  if (definedTitle) return definedTitle;

  // if source ref contains property 'name' use 'name' for reference field
  if (Object.keys(properties).includes('name')) {
    return 'name';
  }

  if (Object.keys(properties).includes('username')) {
    return 'username';
  }

  if (Object.keys(properties).includes('blobUrl')) {
    return 'blobUrl';
  }

  // return id for default reference field
  return 'id';
};

const parseSourceRef = (source, properties, apiRef, locale) => {
  const name = source.split('.').pop();
  const sourceSchema = properties?.[name];
  const fieldRef =
    sourceSchema.$ref ||
    sourceSchema?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
    sourceSchema?.properties?.reference?.$ref;
  const sourceRef = apiRef.get(fieldRef);

  let sourceName = source;

  let sourceRefTitle = getSourceRefTitle(source, sourceRef.properties);
  let sourceRefTitleSchema = sourceRef.properties?.[sourceRefTitle];
  let refName = fieldRef.split('/schemas/').pop();
  let translatableInput = false;

  // TRANSLATABLE_INPUT
  if (sourceRefTitleSchema?.properties?.translatable?.default) {
    // sourceRefTitle = `${sourceRefTitle}.${locale}`;
    translatableInput = true;
  }

  if (!sourceSchema?.properties?.reference?.$ref) {
    sourceName = `${source}.id`;
  }

  if (sourceRefTitle === 'id') {
    sourceRefTitleSchema = {
      type: 'string',
      format: 'id',
    };
  }

  if (refName.toLowerCase().endsWith('status')) {
    (refName = 'status'),
      (sourceRefTitleSchema = {
        type: 'string',
        format: 'chip',
      });
  }

  const { PropComponent: FieldComponent, guessedPropsComponent } = guessFieldComponent(sourceRefTitleSchema);

  if (refName.toLowerCase().endsWith('user')) {
    sourceRefTitle = 'username';
    sourceName = 'user.id';
  }

  return {
    source: sourceRefTitle,
    SourceReferenceField: {
      label: source,
      source: sourceName,
      reference: refName,
    },
    FieldComponent,
    guessedPropsComponent,
    translatableInput,
  };
};

// @TODO: REFACTOR use constant for hardcode (view, type, format)
const guessFieldComponentWithProp = ({
  source,
  properties,
  apiRef,
  view = 'show',
  resource,
  resources, // eslint-disable-line no-unused-vars
  hasShow,
  record = {},
  locale,
  translate,
  permissions = [],
}) => {
  const { isShowView } = useViewType();

  const sourceSchema = properties?.[source];
  if (sourceSchema?.properties?.hide?.default) return;
  const realResource = resource.includes('/') ? resource.split('/')[1] : resource;

  // TRANSLATABLE INPUTS
  let translatableInput = sourceSchema?.properties?.translatable?.default;

  if (source === 'name' && !sourceSchema.format && hasShow && !translatableInput) {
    sourceSchema.format = 'name';
  }

  let { PropComponent: FieldComponent, guessedPropsComponent } = guessFieldComponent(sourceSchema);

  let sourceName = source;
  let SourceReferenceField;
  let className = '';
  const type = sourceSchema?.format || sourceSchema?.items?.format;
  const isJSON = type === 'json';
  const collapseJSON = !!sourceSchema?.properties?.collapse;

  const dependOnProperties = sourceSchema?.properties?.dependOn?.properties || {};
  const dependOnPropertyList = Object.keys(dependOnProperties);

  if (isShowView) {
    const shouldBeHidden = dependOnPropertyList.some((i) => {
      const propertyValue = get(record, i);
      const dependOnValue = get(dependOnProperties, i);

      if (dependOnValue?.default && Array.isArray(dependOnValue?.default)) {
        return !dependOnValue?.default?.includes(propertyValue);
      }

      if (dependOnValue?.default) {
        return dependOnValue?.default !== propertyValue;
      }

      return !propertyValue || propertyValue?.length === 0;
    });

    if (shouldBeHidden) {
      return null;
    }
  }

  // ref to a defined object type
  const fieldRef = isJSON
    ? null
    : sourceSchema?.$ref ||
      sourceSchema?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
      sourceSchema?.properties?.reference?.$ref;

  const customRef = sourceSchema?.properties?.reference?.$ref;

  if (fieldRef) {
    const sourceRef = parseSourceRef(source, properties, apiRef, locale);
    sourceName = properties[source]?.properties?.optionText?.default || sourceRef.source;

    FieldComponent = properties[source]?.format === 'id' ? IdField : sourceRef.FieldComponent;
    SourceReferenceField = sourceRef.SourceReferenceField;
    guessedPropsComponent = sourceRef.guessedPropsComponent;
    translatableInput = sourceRef.translatableInput || properties[source]?.properties?.translatable?.default;

    if (view === 'list' && sourceName === 'blobUrl') {
      className = 'custom-image-field';
    }
  }

  // Guess ReferenceField
  if (customRef) {
    const realRef = resources?.find((r) => {
      if (r.name.includes('/')) {
        return r.name === kebabCase(SourceReferenceField.reference);
      }

      return false;
    });

    const reference =
      sourceSchema?.properties?.alias?.default /* custom reference resource */ ||
      realRef?.name ||
      kebabCase(SourceReferenceField.reference);

    // Should not request the player resource twice
    // data response getOne is not the same with getList of player resource
    // so the player detail will be override
    if (isShowView && realResource === 'player' && reference === 'player') {
      return null;
    }

    let label = `resources.${realResource}.fields.${source}`;

    // TRANSLATABLE_INPUT
    if (translatableInput) {
      if (view === 'show') {
        // label = `${translate(label)} (${locale})`;
      }
      sourceName = `${sourceName}.${locale}`;
    }

    const [canIRead, canIList] = [
      canI(RbacAction.READ, reference, permissions),
      canI(RbacAction.LIST, reference, permissions),
    ];

    const isHasLink = canIRead && canIList ? 'show' : false;
    FieldComponent = isHasLink ? FieldComponent : TextField;

    if (!canIRead) {
      permissions.length && console.debug(`[WARNING] You don't have READ perm on ${reference}`);
      return <TextField key={source} label={label} source={SourceReferenceField.source} />;
    }

    return (
      <ReferenceField
        key={source}
        source={SourceReferenceField.source}
        reference={reference}
        label={label}
        link={isHasLink}
      >
        <FieldComponent source={sourceName} className={className} {...guessedPropsComponent} />
      </ReferenceField>
    );
  }

  const isImage = SourceReferenceField?.reference === 'Blob';
  if (isImage) {
    if (translatableInput) {
      sourceName = `${source}.${locale}`;
      return (
        <ImageField
          key={source}
          label={`resources.${realResource}.fields.${source}`}
          source={`${sourceName}.blobUrl`}
          className={className}
        />
      );
    }

    return (
      <ImageField
        key={source}
        label={`resources.${realResource}.fields.${source}`}
        source={`${source}.blobUrl`}
        className={className}
      />
    );
  }

  // Guess ReferenceField
  if (SourceReferenceField) {
    let label = `resources.${realResource}.fields.${source}`;

    // TRANSLATABLE_INPUT
    if (translatableInput) {
      if (view === 'show') {
        // label = `${translate(label)} (${locale})`;
      }
      sourceName = `${sourceName}.${locale}`;
    }

    const [canIRead, canIList] = [
      canI(RbacAction.READ, kebabCase(SourceReferenceField.reference), permissions),
      canI(RbacAction.LIST, kebabCase(SourceReferenceField.reference), permissions),
    ];

    const isHasLink = canIRead && canIList ? 'show' : false;
    FieldComponent = isHasLink ? FieldComponent : TextField;

    if (!canIRead) {
      permissions.length &&
        console.debug(`[WARNING] You don't have READ perm on ${kebabCase(SourceReferenceField.reference)}`);
      return <TextField key={source} label={label} source={SourceReferenceField.source} />;
    }

    return (
      <ReferenceField
        key={source}
        source={SourceReferenceField.source}
        label={label}
        reference={kebabCase(SourceReferenceField.reference)}
        link={isHasLink}
        translatableInput={translatableInput}
      >
        <FieldComponent source={sourceName} className={className} {...guessedPropsComponent} />
      </ReferenceField>
    );
  }

  // Guess ArrayField
  if (checkArrayReferenceField(sourceSchema)) {
    const refField = sourceSchema?.items?.$ref || sourceSchema?.items?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
    const refName = kebabCase(refField?.split('/schemas/')?.pop());

    let chipSource = 'name';
    if (refName === 'user') chipSource = 'username';

    if (sourceSchema?.properties?.optionText?.default) {
      chipSource = sourceSchema.properties.optionText.default;
    }

    const sourceRef = apiRef.get(refField);

    const sourceRefTitleSchema = sourceRef.properties?.[chipSource];

    let translatableInput = false;

    if (sourceRefTitleSchema?.properties?.translatable?.default) {
      chipSource = `${chipSource}.${locale}`;
      translatableInput = true;
    }

    const DatagridContainer = (props) => {
      const {
        data, // eslint-disable-line react/prop-types
        ids, // eslint-disable-line react/prop-types
        reference, // eslint-disable-line react/prop-types
        translatableInput, // eslint-disable-line react/prop-types
      } = props;

      if (isEmpty(data)) {
        return <Empty />;
      }

      const fields = Object.keys(data?.[ids?.[0]] || {});

      let requiredFields = [...fields];
      let tmpResource = resource;
      let sourceRefTitle = 'name';

      if (refField) {
        const sourceRef = apiRef.get(refField);
        requiredFields = fields.filter((field) => sourceRef.required.includes(field));

        if (reference) {
          tmpResource = reference;
        }
      }

      // TODO: need to omit at the BE side, to check wallet implementation is SEAMLESS or TRANSFER
      // TRANSFER don't need to display these keys
      const omitRedundantFieldsFromBrandData = (field) => !['publicKey', 'secretKey'].includes(field);

      const fieldsComp = requiredFields.filter(omitRedundantFieldsFromBrandData).map((field) => {
        // TRANSLATABLE_INPUT
        const translatableInput = sourceRef?.properties?.[field]?.properties?.translatable?.default;
        let source = field;
        let label = `resources.${tmpResource}.fields.${field}`;

        if (translatableInput) {
          // label = `${translate(label)} (${locale})`;
          source = `${field}.${locale}`;
          sourceRefTitle = `${field}.${locale}`;
        }

        const fieldValue = data?.[ids?.[0]]?.[field];
        let fieldType = fieldValue;

        if (isJSON) {
          fieldType = 'json';
        }

        switch (typeof fieldType) {
          case 'json':
            const isJsonString = typeof jsonData === 'string';

            return (
              <JsonField
                key={field}
                label={label}
                source={field}
                addLabel={true}
                jsonString={isJsonString}
                reactJsonOptions={{
                  name: null,
                  collapsed: collapseJSON,
                  collapsed: true,
                  enableClipboard: true,
                  displayDataTypes: false,
                }}
              />
            );
          case 'number':
            return <NumberField source={field} key={field} label={label} />;
          case 'boolean':
            return <BooleanField source={field} key={field} label={label} />;
          case 'object':
            if (!data?.[ids?.[0]]?.[field]) {
              return <TextField source={field} key={field} label={label} />;
            }

            // eslint-disable-next-line react/prop-types
            if (data?.[ids?.[0]]?.[field]?.blobUrl) {
              return (
                <RefField key={field} source={`${field}`} label={label}>
                  <ImageField source="blobUrl" className={className} />
                </RefField>
              );
            }

            if (data?.[ids?.[0]]?.[field]?.[locale]?.blobUrl) {
              return (
                <RefField key={field} source={`${field}.${locale}`} label={label}>
                  <ImageField source="blobUrl" className={className} />
                </RefField>
              );
            }

            if (translatableInput) {
              return <TextField label={label} key={field} source={sourceRefTitle} />;
            }

            return (
              <RefField key={field} source={`${field}.id`} reference={field} label={label} link="show">
                <FieldComponent source="name" {...guessedPropsComponent} />
              </RefField>
            );

          default:
            return <TextField source={field} key={field} label={label} />;
        }
      });

      return (
        <Box style={{ overflowX: 'auto' }}>
          <Datagrid {...props} optimized>
            <RefField label={`resources.${tmpResource}.fields.id`} source={`${tmpResource}.id`} reference={tmpResource}>
              <IdField source="id" hasShow={hasShow} resource={tmpResource} />
            </RefField>
            {fieldsComp}
          </Datagrid>
        </Box>
      );
    };

    const isListView = view === 'list';

    return (
      <ArrayField
        key={refName}
        source={source}
        reference={refName}
        label={`resources.${realResource}.fields.${source}`}
        hasEllipsis={isListView}
        translatableInput={translatableInput}
      >
        {isListView ? (
          <SingleFieldList linkType={false} classes={{ root: 'align-center' }}>
            <ChipField clickable={false} source={chipSource} resource={resource} />
          </SingleFieldList>
        ) : (
          <DatagridContainer />
        )}
      </ArrayField>
    );
  }

  // Guest Normal Field
  const FieldContainer = ({ children, ...props }) => {
    let newProps = props || {};
    const { record, translatableInput } = props;
    let schema = {
      ...sourceSchema,
      sourceName,
    };

    if (translatableInput) {
      const fieldTranslate = sourceName?.split(`.${locale}`)?.[0];

      if (fieldTranslate && typeof record[fieldTranslate] === 'string') {
        set(record, fieldTranslate, JSON.parse(record[fieldTranslate]));
      }
    }

    let CustomComponent = null;

    // Guess Normal Field by "value" for "setting" resource
    if (resource === 'setting' && sourceName === 'value') {
      schema = {
        type: 'string',
        format: record?.format,
      };

      if (record?.maskMe) {
        schema = {
          type: 'mask',
        };
      } else if (record?.format === 'boolean') {
        schema = {
          type: record?.format,
        };
      }

      if (record?.format === 'blob') {
        schema = {
          type: 'string',
          format: 'image',
        };
      }

      if (record?.format === 'url') {
        CustomComponent = <LinkField {...newProps} />;
      }

      if (['language', 'currency'].includes(record?.format)) {
        CustomComponent = (
          <ReferenceField {...newProps} reference={record?.format}>
            <TextField source="name" />
          </ReferenceField>
        );
      }
    }

    let { PropComponent: Component, guessedPropsComponent } = guessFieldComponent(schema);

    if (resource === 'bet' && ['created', 'updated', 'endTime'].includes(sourceName)) {
      guessedPropsComponent.showMillisecond = true;
    }

    if (isJSON) {
      const jsonData = get(record, source);
      const isJsonString = typeof jsonData === 'string';
      Component = JsonField;

      newProps = {
        ...omit(props, ['translatableInput']),
        addLabel: true,
        jsonString: isJsonString,
        reactJsonOptions: {
          // Props passed to react-json-view
          name: null,
          collapsed: collapseJSON,
          collapsed: true,
          enableClipboard: true,
          displayDataTypes: false,
        },
      };
    }

    return children({
      props: newProps,
      isCopy: schema?.properties?.copiable?.default,
      record,
      Component,
      guessedPropsComponent,
      CustomComponent,
    });
  };

  const label = `resources.${realResource}.fields.${source}`;
  // TRANSLATABLE_INPUT
  if (translatableInput) {
    sourceName = `${sourceName}.${locale}`;
    if (view === 'list') {
      source = `${source}.${locale}`;
    }
  }

  return (
    <FieldContainer
      key={source}
      source={sourceName}
      label={label}
      tooltip={sourceSchema?.description}
      translatableInput={translatableInput}
    >
      {({ props, isCopy, record, Component, guessedPropsComponent, CustomComponent }) => {
        if (view === 'list') {
          return CustomComponent || <Component {...props} record={record} {...guessedPropsComponent} />;
        }

        return (
          <CustomLabeled
            label={
              <CustomLabel
                translatableInput={translatableInput}
                realResource={realResource}
                source={source}
                isCopy={isCopy}
                record={record}
              />
            }
            className={guessedPropsComponent?.className || ''}
          >
            {CustomComponent || <Component {...props} record={record} {...guessedPropsComponent} />}
          </CustomLabeled>
        );
      }}
    </FieldContainer>
  );
};

export default guessFieldComponentWithProp;
