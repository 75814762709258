/* eslint-disable */
import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import ReportFilterWrapper from './ReportFilterWrapper';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ReportContext = createContext({
  currentFilters: {},
  appliedFilters: {},
  data: [],
});

export const useReportContext = () => useContext(ReportContext);

const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => queryString.parse(search, { arrayFormat: 'index' }), [search]);
};

const ReportLayout = ({ filterChildren, filterProps, resultChildren, totalTables }) => {
  const query = useQueryParams();

  const appliedFilters = useMemo(() => {
    const DEFAULT_DATE_RANGE = `**||$between||**${moment().subtract(1, 'day').startOf('day').toISOString()},${moment()
      .endOf('day')
      .toISOString()}`;

    return {
      created: DEFAULT_DATE_RANGE,
      ...query,
    };
  }, [query]);

  const [sort, setSort] = useState(Array.from({ length: totalTables }, () => query?.sort?.[i] || 'id,ASC'));
  const [page, setPage] = useState(Array.from({ length: totalTables }, () => +query?.page?.[i] || 1));
  const [perPage, setPerPage] = useState(Array.from({ length: totalTables }, () => +query?.perPage?.[i] || 25));
  const [currentFilters, setCurrentFilters] = useState(appliedFilters);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(Boolean(Object.keys(query)?.length));

  return (
    <ReportContext.Provider
      value={{
        query,
        currentFilters,
        data,
        setData,
        setCurrentFilters,
        appliedFilters,
        setPage,
        setPerPage,
        setSort,
        page,
        perPage,
        sort,
        loading,
        setLoading,
      }}
    >
      <ReportFilterWrapper {...filterProps}>{filterChildren}</ReportFilterWrapper>
      <Box>{resultChildren}</Box>
    </ReportContext.Provider>
  );
};

ReportLayout.propTypes = {
  filterChildren: PropTypes.node,
  resultChildren: PropTypes.node,
  filterProps: PropTypes.object,
  totalTables: PropTypes.number.isRequired,
};

ReportLayout.defaultProps = {
  filterChildren: null,
  resultChildren: null,
  filterProps: {},
};

export default ReportLayout;
