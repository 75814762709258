import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { fetchError } from '../../../redux/actions';
import PageLoader from '../PageComponents/PageLoader';

const ContentLoader = () => null
// const {
//   error, loading, message,
// } = useSelector(({ common }) => common);
// const dispatch = useDispatch();

// useEffect(() => {
//   setTimeout(() => {
//     dispatch(fetchError(''));
//   }, 3000);
// }, [dispatch, error, message]);

// return (
//   <>
//     {loading && <PageLoader />}
//     {error && (
//       <Snackbar
//         open={Boolean(error)}
//         message={error}
//       />
//     )}
//     {message && (
//       <Snackbar
//         open={Boolean(error)}
//         message={message}
//       />
//     )}
//   </>
// );
;

export default ContentLoader;
