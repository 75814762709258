/* eslint-disable */
import React, { useState, useEffect } from "react";
import { FormDataConsumer, useDataProvider, useLocale } from "react-admin";
import { required, useTranslate } from "ra-core";
import { useField } from "react-final-form";
import { AutocompleteInput } from "../../../base/components/ra/inputs";
import { words } from "lodash";
import { TooltipForDisabledInput } from "../../../base/components/custom-tooltip";

const CheatModeApplyGame = ({ source, getResourceNameTranslation }) => {
  const dataProvider = useDataProvider();
  const [choicesGame, setChoicesGame] = useState([]);
  const translate = useTranslate();
  const locale = useLocale();

  const {
    input: { value: valueBrand },
  } = useField("brand");

  const getDataAvaiableGame = async (brandId) => {
    try {
      const { data } = await dataProvider.getList("available-game", {
        pagination: {
          page: 1,
        },
        filter: {
          "brand.id": brandId,
        },
      });

      if (data?.length > 0) {
        setChoicesGame(data[0].games);
      }
    } catch (err) {
      console.error("[ERROR] Get One Game", err?.message);
    }
  };

  useEffect(() => {
    if (valueBrand?.id) {
      getDataAvaiableGame(valueBrand?.id);
    }
  }, [valueBrand]);

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const listResourceNameTranslated = getResourceNameTranslation([
          "group",
          "brand",
        ]);

        const disabledInputMessage = translate("ra.message.pleaseSelectFirst", {
          smart_name: listResourceNameTranslated?.join(", "),
        });

        const inputDisabledByFilterOn = !formData.brand?.id;

        const tooltipDisabledInputProps = {
          title: disabledInputMessage,
          placement: "top-start",
          showMessage: inputDisabledByFilterOn,
        };

        const isTranslatable = choicesGame.some(
          (item) => typeof item.name === "object"
        );

        return (
          <TooltipForDisabledInput {...tooltipDisabledInputProps}>
            <AutocompleteInput
              optionText={isTranslatable ? `name.${locale}` : 'name'}
              label="resources.game.name"
              disabled={inputDisabledByFilterOn}
              fullWidth
              source={source}
              validate={[required()]}
              choices={choicesGame.map(({ name, id }) => ({
                name,
                id,
              }))}
            />
          </TooltipForDisabledInput>
        );
      }}
    </FormDataConsumer>
  );
};

export default CheatModeApplyGame;
