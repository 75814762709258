import React, { memo } from 'react';
import MuiButton from '@material-ui/core/Button';
import { useTranslate } from 'ra-core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core';
import { useFilterFormContext } from '../../../context/form/filterForm.context';

const useStyle = makeStyles(theme => ({
  showFilterBtn: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
    },
  },
}));

const ButtonFilter = memo(() => {
  const {
    setFilterDisplayed, filterDisplayed,
  } = useFilterFormContext();

  const classes = useStyle();

  const translate = useTranslate();

  return (
    <MuiButton
      color="primary"
      variant="text"
      size="small"
      className={classes.showFilterBtn}
      startIcon={filterDisplayed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      onClick={() => setFilterDisplayed(!filterDisplayed)}
    >
      {filterDisplayed
        ? translate('ra.action.hideFilter')
        : translate('ra.action.showFilter')}
    </MuiButton>
  );
});

export default ButtonFilter;
