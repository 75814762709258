/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';
import kebabCase from 'lodash/kebabCase';
import words from 'lodash/words';
import get from 'lodash/get';
import { useListContext, useTranslate, useLocale, AutocompleteInput } from 'react-admin';
import { Grid, Button, Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { ReferenceInput } from '../ra/inputs';
import { useAuthUser, useSchema } from '../../hooks';
import { useFilterFormContext } from '../../context/form/filterForm.context';
import { noop } from '../../../services/util';
import { makeStateStory } from '../../../services/util/makeStateStory';
import { TooltipForDisabledInput } from '../custom-tooltip';
import { useStyles } from './wealth-filter.guesser.utils';
import { getReference, getFiltersFromSchema } from './wealth-input.guesser.utils';
import { guessInputComponent } from './wealth-component.guesser';
import { getSyntaxJsonUnquoteExtract } from '../../../constant';
import resourceSlug from '../../../constant/resource-slug';

const FilterGuesser = props => {
  const {
    properties, resource, excludesInputs, extraInputs, children, filterProps,
  } = props;
  const { containerClassName } = filterProps || {};
  const realResource = resource.includes('/') ? resource.split('/')[1] : resource;
  const translate = useTranslate();
  const classes = useStyles();
  const user = useAuthUser();
  const locale = useLocale();
  const { ref: apiRef } = useSchema();

  const stateStoryKey = `FILTER-${resource}`;
  const {
    setStoryState, getOlderState: getPrevFormValues,
  } = makeStateStory(stateStoryKey);

  const {
    setValues, startSearching,
  } = useFilterFormContext();

  const {
    setFilters, filterValues,
  } = useListContext();

  const getFilterFromProperties = (acc = {}, currItem) => {
    const filterResult = acc;
    filterResult[currItem] = {
      ...properties[currItem],
      name: currItem,
    };
    return filterResult;
  };

  // Search data with filter values
  const handleSearch = valuesSearch => {
    const prevValuesSearch = getPrevFormValues();
    if (JSON.stringify(valuesSearch) === JSON.stringify(prevValuesSearch)) {
      return;
    }

    // Should put `startSearching` function after checking the values is changed
    startSearching();

    let groupBy = valuesSearch?.groupBy;
    if (groupBy && Array.isArray(groupBy)) {
      groupBy = groupBy.join(',');
    }

    setFilters({
      ...valuesSearch,
      groupBy,
    });
  };

  const usedFilters = Object.keys(properties)
    .filter(key => !properties[key]?.properties?.hide && !key.startsWith('_') && !excludesInputs.includes(key))
    .reduce(getFilterFromProperties, {});

  const sourceList = Object.values(usedFilters);
  const blackListFormat = ['code', 'richtext', 'multiline'];

  // Check valid source before render the filter panel
  const checkValidSource = source => {
    // Hide this field if Role's user is GROUP and this field is `group`
    const belongToGroup = ['group', 'groupId'].includes(source.name);
    if (belongToGroup && user?.role?.type === 'GROUP') {
      return false;
    }

    // Check metadata of this field
    const systemFieldList = usedFilters._docMeta?.properties?.systemFields?.default;
    const isNotSystemField = !systemFieldList?.includes(source.name);

    const notInBlacklistFormat = !blackListFormat.includes(source.format);
    const isNotHiddenField = !source.properties?.hideFilter?.default;
    const notInHiddenSystemField = !usedFilters._metaData?.properties?.hideSystemFields;

    return notInBlacklistFormat && isNotHiddenField && (notInHiddenSystemField || isNotSystemField);
  };

  // Translate all sourceNames in an array
  const getResourceNameTranslation = resourceList => resourceList.map(item => {
    const realResourceName = words(item)[0];
    return translate(`resources.${realResourceName}.name`);
  });

  const renderInputElementFromChildren = inputElement => (
    <Grid
      item
      key={get(inputElement, ['props', 'source'])}
      xs={12}
      sm="auto"
      data-field={get(inputElement, ['props', 'source'])}
    >
      {inputElement}
    </Grid>
  );

  useEffect(
    () => function cleanUp() {
      setValues({});
      setFilters({});
    },
    [],
  );

  return (
    <Box className={classes.filterContainer}>
      <Form
        initialValues={{
          ...filterValues,
        }}
        onSubmit={noop}
        render={({
          form, modified,
        }) => {
          const formValues = form.getState().values;
          // Bring formValues into Filter Form Context
          setValues(formValues);
          // Bring formValues into the Story, to access current/older values later
          setStoryState(formValues);

          return (
            <form className={classes.formStyle}>
              <Grid
                container
                spacing={1}
                display="flex"
                style={{
                  gap: '1rem',
                  padding: '0 2px',
                }}
                className={containerClassName}
              >
                {sourceList.filter(checkValidSource).map(source => {
                  let extendProps = {};
                  const sourceSchema = usedFilters?.[source.name];
                  let inputLabel = translate(`resources.${realResource}.fields.${source.name}`);

                  // TRANSLATABLE INPUTS
                  const translatableInput = sourceSchema?.properties?.translatable?.default;

                  const {
                    InputComponent, guessedProps,
                  } = guessInputComponent(sourceSchema, {
                    isFilter: true,
                    translate,
                    resource: realResource,
                    translatableInput,
                  });

                  if (translatableInput) {
                    // inputLabel = `${inputLabel} (${locale})`;
                  }

                  const filterOn = sourceSchema?.properties?.filterOn?.properties || {};
                  const filterOnProps = Object.keys(filterOn || {});

                  const sourceFilterList = filterOnProps.map(item => {
                    if (['group'].includes(item)) {
                      return 'group.id';
                    }
                    return item;
                  });
                  const hasFilter = sourceFilterList?.length > 0;

                  const {
                    refName, refField,
                  } = getReference(sourceSchema);

                  const refFilter = getFiltersFromSchema(sourceSchema, {
                    user,
                    refName,
                    changeInputValue: form.change,
                    formValues,
                    realResource,
                  });

                  if (source.name === 'docStatus') {
                    extendProps = {
                      ...extendProps,
                      onChange: e => {
                        if (e.target.value) {
                          form.change(source.name, e.target.value);
                        }
                      },
                      parse: ids => (ids ? `"${ids}"` : ''),
                      format: data => data?.replaceAll(/"/gi, '') || '',
                    };
                  } else if (source.name === 'games' && resource === resourceSlug.AVAILABLE_GAME) {
                    // Hotfix: To filter only available games in the available page
                    extendProps = {
                      ...extendProps,
                      filterToQuery: value => ({
                        [`name->>'$.${locale}'`]: value || '',
                        enabled: true,
                      }),
                    };
                  }

                  let sourceName = source.name;

                  const OPTION_TEXT_DEFAULT = 'name';
                  let optionText = OPTION_TEXT_DEFAULT;
                  let componentProps = {};
                  let isReferenceInput;

                  const filterConfig = sourceSchema?.properties?.filterConfig || {};

                  // Hotfix: To filter wallet integration as reference value instead of string value without any update of services
                  const isWalletIntegrationFilter = source.name === 'walletIntegration';
                  const filterAsTextInput = filterConfig.type === 'text-input' && !isWalletIntegrationFilter;

                  // filterConfig[type] is prioritized
                  if (refField) {
                    if (!filterAsTextInput) {
                      isReferenceInput = true;
                    }

                    if (refName === 'user') optionText = 'username';
                    if (refName === 'player') optionText = 'nativeId';

                    optionText = sourceSchema?.properties?.optionText?.default || optionText;

                    const isNameInput = filterAsTextInput && optionText === OPTION_TEXT_DEFAULT;

                    // Update the correct sourceName to filter, exclude `.` and `/`
                    // and this sourceName include `Id`
                    if (sourceName.indexOf('.') === -1 && !words(sourceName).includes('Id')) {
                      if (isNameInput) {
                        sourceName = `${sourceName}.name`;
                      } else {
                        sourceName = `${sourceName}.id`;
                        if (filterAsTextInput) {
                          inputLabel = `${inputLabel} ID`;
                        }
                      }
                    }
                  }

                  if (sourceSchema?.type === 'number') {
                    sourceName = `${sourceName}`;
                  }

                  const getSource = () => {
                    const sourceToFilter = filterConfig.source || sourceName;
                    const operatorToFilter = filterConfig.operator || guessedProps?.queryOperation;
                    return operatorToFilter ? `${sourceToFilter}||${operatorToFilter}` : sourceToFilter;
                  };

                  componentProps = {
                    label: inputLabel,
                    key: sourceName,
                    source: getSource(),
                    alwaysOn: true,
                  };

                  if (isReferenceInput) {
                    // Check one of list filterOn has a value
                    // If one has the value => filter this input
                    const filterActivate = sourceFilterList.some(item => get(formValues, item));

                    // Check this field DISABLED or NOT depend on `source filterOn`
                    let inputDisabledByFilterOn = hasFilter ? !filterActivate : false;

                    // Brand won't set DISABLED when role's user is GROUP
                    const belongToBrand = ['brand'].includes(refName);
                    if (user?.role?.type === 'GROUP' && belongToBrand) {
                      inputDisabledByFilterOn = false;
                    }

                    // Check `source filterOn` has UPDATED or NOT
                    const prevFormValues = getPrevFormValues();
                    const sourceFilterUpdated = sourceFilterList?.some(item => {
                      const currentValue = get(formValues, item);
                      const olderValue = get(prevFormValues, item);
                      const isModified = get(modified, item);

                      if (isModified && JSON.stringify(currentValue) !== JSON.stringify(olderValue)) {
                        return true;
                      }

                      return false;
                    });

                    // Source filterOn unSelect or update value => this field clear data
                    if ((inputDisabledByFilterOn || sourceFilterUpdated) && formValues[source.name]) {
                      form.change(source.name, undefined);
                    }

                    const listResourceNameTranslated = getResourceNameTranslation(sourceFilterList);
                    const disabledInputMessage = translate('ra.message.pleaseSelectFirst', {
                      smart_name: listResourceNameTranslated?.join(', '),
                    });

                    const tooltipDisabledInputProps = {
                      title: disabledInputMessage,
                      placement: 'top-start',
                      showMessage: inputDisabledByFilterOn,
                    };

                    const optionTextSort = optionText;

                    // TRANSLATABLE_INPUT
                    if (apiRef.get(refField).properties[optionText]?.properties?.translatable) {
                      const optionTextQuery = `${optionText}${getSyntaxJsonUnquoteExtract(locale)}`;

                      optionText = `${optionText}.${locale}`;
                      // componentProps.label = `${inputLabel} (${locale})`;

                      componentProps.filterToQuery = value => ({
                        [optionTextQuery]: value || '',
                      });
                    }

                    return (
                      <Grid
                        item
                        key={source.name}
                        xs={12}
                        sm="auto"
                        data-field={source.name}
                      >
                        <TooltipForDisabledInput {...tooltipDisabledInputProps}>
                          <ReferenceInput
                            {...componentProps}
                            {...extendProps}
                            optionText={optionTextSort}
                            className={classes.fieldStyle}
                            disabled={inputDisabledByFilterOn}
                            reference={kebabCase(refName)}
                            filter={refFilter}
                            perPage={sourceSchema?.properties?.choiceLimit || 100}
                            sort={{
                              field: sourceSchema?.properties?.choiceSort?.field || optionTextSort,
                              order: sourceSchema?.properties?.choiceSort?.order || 'ASC',
                            }}
                          >
                            <AutocompleteInput
                              variant="outlined"
                              emptyText={`--${translate('ra.text.all').toUpperCase()}--`}
                              optionText={optionText}
                              resettable
                              showLoading={false}
                            />
                          </ReferenceInput>
                        </TooltipForDisabledInput>
                      </Grid>
                    );
                  }

                  if (sourceSchema?.type === 'array') {
                    if (refName === 'user') {
                      optionText = 'username';
                    }

                    componentProps = {
                      label: `resources.${realResource}.fields.${source.name}`,
                      key: sourceName,
                      source: `${sourceName}.id||$eq`,
                      alwaysOn: true,
                    };

                    return (
                      <Grid
                        item
                        key={source.name}
                        xs={12}
                        sm="auto"
                        data-field={source.name}
                      >
                        <ReferenceInput
                          {...componentProps}
                          allowEmpty
                          className={classes.fieldStyle}
                          reference={kebabCase(refName)}
                          optionText={optionText}
                          filter={refFilter}
                          perPage={sourceSchema?.properties?.choiceLimit || 100}
                          sort={{
                            field: sourceSchema?.properties?.choiceSort?.field || optionText,
                            order: sourceSchema?.properties?.choiceSort?.order || 'ASC',
                          }}
                          filterToQuery={value => ({
                            [optionText]: value || '',
                          })}
                        >
                          <AutocompleteInput
                            optionText={optionText}
                            emptyText={`--${translate('ra.text.all').toUpperCase()}--`}
                            resettable
                            showLoading={false}
                          />
                        </ReferenceInput>
                      </Grid>
                    );
                  }

                  if (translatableInput) {
                    componentProps.source = `${componentProps.source}${getSyntaxJsonUnquoteExtract(locale)}`;
                  }

                  return (
                    <Grid
                      item
                      key={source.name}
                      xs={12}
                      sm="auto"
                      data-field={source.name}
                    >
                      <InputComponent
                        {...componentProps}
                        {...guessedProps}
                        {...extendProps}
                        resource={resource}
                        className={classes.fieldStyle}
                        allowEmpty
                        resettable
                      />
                    </Grid>
                  );
                })}
                {React.Children.map(extraInputs, inputElement => renderInputElementFromChildren(inputElement))}
                {children}
              </Grid>
              <Grid>
                <Box
                  textAlign="right"
                  marginTop={2}
                >
                  <Button
                    onClick={() => handleSearch(formValues)}
                    className={classes.btn}
                    disabled={JSON.stringify(filterValues) === JSON.stringify(formValues)}
                  >
                    {translate('ra.action.search')}
                  </Button>
                </Box>
              </Grid>
            </form>
          );
        }}
      />
    </Box>
  );
};

FilterGuesser.propTypes = {
  properties: PropTypes.object,
  resource: PropTypes.string,
  excludesInputs: PropTypes.arrayOf(PropTypes.string),
  filterProps: PropTypes.object,
};

FilterGuesser.defaultProps = {
  properties: {},
  excludesInputs: [],
  filterProps: {},
};

export default memo(FilterGuesser);
