import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import ActionMenu from './components/action.menu';

const SeamlessLogShow = props => (
  <WealthShowGuesser
    {...props}
    actionMenu={<ActionMenu type="toolbar" />}
  />
);

export default SeamlessLogShow;
