import React from 'react';
import {
  SimpleForm,
  Create,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TextInput,
  DateTimeInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import PrizeDropTableInput from './components/prize-drop-table.input';

const PrizeDropCreate = props => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      redirect="show"
    >
      <ReferenceInput
        label="resources.game.name"
        reference="game"
        source="game.id"
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <TextInput
        fullWidth
        source="name"
      />

      <TextInput
        fullWidth
        source="desc"
      />

      <NumberInput
        fullWidth
        source="minBet"
      />

      <DateTimeInput
        fullWidth
        source="startDate"
      />
      <DateTimeInput
        fullWidth
        source="endDate"
      />

      <BooleanInput
        fullWidth
        source="isAllPlayer"
      />
      <FormDataConsumer>
        {({ formData }) => !formData.isAllPlayer && (
          <ReferenceArrayInput
            label="resources.player.name"
            reference="player"
            source="players"
          >
            <AutocompleteArrayInput
              optionText="name"
              optionValue="id"
            />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
      <PrizeDropTableInput source="value" />
    </SimpleForm>
  </Create>
);
export default PrizeDropCreate;
