/* eslint-disable */
import React, { Fragment } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import { Tooltip, Box } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import { SmallButton } from '../../../base/components/ra/buttons';

const createTooltipWrapper = (props) => ({ children, ...restProps }) => (
  <Tooltip {...props} {...restProps}>
    <Box display="inline-block">
      {children}
    </Box>
  </Tooltip>
);

export default function DownloadAction(props) {
  const { record } = props;
  const translate = useTranslate();

  const downloadAble = record?.status === 'COMPLETED' && record?.url;

  const TooltipWrapper = createTooltipWrapper({
    title: translate('ra.text.downloadExportFileDisableTooltip'),
  });
  const DownloadButtonWrapper = !downloadAble ? TooltipWrapper : Fragment;

  return (
    <ToolbarMenu {...props}>
      <DownloadButtonWrapper>
        <SmallButton
          label="wa.common.download"
          disabled={!downloadAble}
          onClick={() => window.open(record?.url, '_self')}
          startIcon={<GetAppIcon />}
        />
      </DownloadButtonWrapper>
    </ToolbarMenu>
  );
}

DownloadAction.propTypes = {
  record: PropTypes.shape({
    status: PropTypes.oneOf(['PENDING', 'COMPLETED', 'FAILED', 'EXPIRED']).isRequired,
    url: PropTypes.string.isRequired,
  }),
};
