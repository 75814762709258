import isEmpty from 'lodash/isEmpty';
import {
  GET_AUTH_INFO,
  GET_AUTH_INFO_SUCCESS,
  GET_AUTH_INFO_FAILED,
  UPDATE_REQUIRE_CHANGE_PASSWORD,
} from '../root.actions';

const initialSettings = {
  user: {},
  requireChangePassword: null,
};

export const authReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_AUTH_INFO:
      return {
        ...state,
        loading: true,
      };

    case GET_AUTH_INFO_SUCCESS:
      return {
        ...state,
        user: isEmpty(action.payload.user) ? {} : {
          ...state.user,
          ...action.payload.user,
        },
        loading: false,
      };

    case GET_AUTH_INFO_FAILED:
      return {
        ...state,
        user: {},
        loading: false,
      };

    case UPDATE_REQUIRE_CHANGE_PASSWORD:
      return {
        ...state,
        requireChangePassword: action.payload,
      };

    default:
      return state;
  }
};
