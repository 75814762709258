import { Typography, Box, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAuthUser } from '../../hooks';

function getTime() {
  const [time, setTime] = useState(moment().format('MM/DD/YYYY HH:mm'));

  function tick() {
    setTime(moment().format('MM/DD/YYYY HH:mm'));
  }

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  return `${time} [UTC +0]`;
}

function HeaderInfo() {
  const user = useAuthUser();
  const muiTheme = useTheme();
  const contrastTextColor = muiTheme.palette.primary.contrastText;

  return (
    <Box
      display="flex"
      minWidth="250px"
      flexDirection="column"
    >
      <Typography
        variant="caption"
        component="div"
        style={{
          color: contrastTextColor,
        }}
      >
        {getTime()}
      </Typography>
      <Typography
        variant="caption"
        component="div"
        style={{
          color: contrastTextColor,
        }}
      >
        {user?.email}
      </Typography>
    </Box>
  );
}

export default HeaderInfo;
