import React, { useState } from 'react';
import { History } from '@material-ui/icons';
import Axios from 'axios';
import Type from 'prop-types';
import {
  sanitizeListRestProps,
  useNotify,
  useRefresh,
  useTranslate,
} from 'ra-core';
import { Box } from '@material-ui/core';
import { TopToolbar } from 'ra-ui-materialui';
import { SmallButton } from '../../../base/components/ra/buttons';
// import ButtonFilter from '../../../base/components/guesser/actions/button-filter.action';
import Confirm from '../../../base/components/ra/layout/Confirm';

export const ActionList = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const revertEvent = () => {
    Axios.post('api/user-statistics/clean-users')
      .then(res => {
        if (res.status === 201) {
          notify(
            translate('ra.message.successfully', {
              smart_name: 'Clean user',
            }),
            'success',
          );
          setConfirmSubmit(false);
          refresh();
        }
      })
      .catch(() => notify('Please try again later!', 'error'));
  };

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <Box>
        {/* <ButtonFilter /> */}
        <SmallButton
          label="ra.action.removeAllUser"
          onClick={() => setConfirmSubmit(true)}
          startIcon={<History />}
        />
      </Box>
      <Confirm
        isOpen={confirmSubmit}
        title={translate('ra.message.are_you_sure')}
        content={translate('ra.message.confirmCleanUser')}
        onConfirm={revertEvent}
        onClose={() => setConfirmSubmit(false)}
      />
    </TopToolbar>
  );
};

ActionList.propTypes = {
  filters: Type.node.isRequired,
};
