/* eslint-disable react/prop-types */
import React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { checkCheatModeEnv } from '../../services/util';

function WalletIntegrationList(props) {
  const excludeFields = checkCheatModeEnv()
    ? []
    : ['createPlayerUrl', 'editBalanceUrl'];

  return (
    <WealthListGuesser
      {...props}
      excludeFields={excludeFields}
    />
  );
}

export default WalletIntegrationList;
