import React from 'react';
import { useTranslate } from 'react-admin';
import { CheckBoxGroup } from '../../../../base/components/ra/inputs';
import FormField from '../../../../base/components/reports/ReportFilterWrapper/FormField';
import resourceSlug from '../../../../constant/resource-slug';

const SOURCE = 'groupBy';
export const GROUP_BY_OPTIONS = {
  DATE: 'date',
  GROUP: 'group',
  BRAND: 'brand',
  GAME: 'game',
  GAME_TYPE: 'gameType',
  BET_TYPE: 'betType',
  MONTH: 'month',
};

const GroupByCheckbox = () => {
  const translate = useTranslate();

  const choices = [
    {
      id: GROUP_BY_OPTIONS.DATE,
      name: translate('resources.report.fields.date'),
    },
    {
      id: GROUP_BY_OPTIONS.GROUP,
      name: translate('resources.report.fields.group'),
    },
    {
      id: GROUP_BY_OPTIONS.BRAND,
      name: translate('resources.report.fields.brand'),
    },

    {
      id: GROUP_BY_OPTIONS.GAME,
      name: translate('resources.report.fields.game'),
    },
    {
      id: GROUP_BY_OPTIONS.GAME_TYPE,
      name: translate('resources.report.fields.gameType'),
    },
    {
      id: GROUP_BY_OPTIONS.BET_TYPE,
      name: translate('resources.report.fields.betType'),
    },
    {
      id: GROUP_BY_OPTIONS.MONTH,
      name: translate('resources.report.fields.month'),
    },
  ];

  return (
    <>
      <FormField name={SOURCE}>
        {() => (
          <CheckBoxGroup
            className="margin-custom-search-panel"
            source={SOURCE}
            label={translate('resources.report.fields.groupBy')}
            choices={choices}
            resource={resourceSlug.REPORT_PERFORMANCE}
            direction="row"
          />
        )}
      </FormField>
    </>
  );
};

GroupByCheckbox.propTypes = {};

GroupByCheckbox.defaultProps = {};

export default GroupByCheckbox;
