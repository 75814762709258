/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { useSelector } from 'react-redux';

import rcSlug from '../../../constant/resource-slug';

const useBaseBetCalculating = ({
  betSize, betLevel, gameId,
}) => {
  const dataProvider = useDataProvider();

  const resources = useSelector(({ admin }) => admin.resources);
  const gameDataFetched = get(resources, 'game.data');

  const [betAmountCalculating, setBetAmountCalculating] = useState(false);
  const [betAmount, setBetAmount] = useState(undefined);

  const getBaseBetByGameId = async () => {
    try {
      let data = gameDataFetched[gameId];
      if (!data) {
        const gameInfo = await dataProvider.getOneWithFilter(rcSlug.GAME, {
          filter: {
            id: gameId,
          },
        });
        data = gameInfo?.data;
      }

      setBetAmountCalculating(false);
      return get(data, 'baseBet');
    } catch (err) {
      console.error('[ERROR] get baseBet by game ID\n', err.message);
    }
    return null;
  };

  const calculateBetAmount = async () => {
    const baseBet = await getBaseBetByGameId();
    const betAmount = baseBet * Number(betSize) * Number(betLevel);

    if (isNumber(betAmount)) {
      setBetAmount(Number(betAmount.toFixed(2)));
    }
  };

  useEffect(() => {
    if (Number(betSize) >= 0 && Number(betLevel) >= 0 && gameId) {
      calculateBetAmount();
    } else {
      setBetAmount(undefined);
    }
  }, [betSize, betLevel, gameId]);

  return {
    betAmountCalculating,
    betAmount,
  };
};

export default useBaseBetCalculating;
