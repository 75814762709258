import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Confirm from '../ra/layout/Confirm';
import { showConfirmDialog } from '../../../services/redux/app/app.actions';
import { initialApps } from '../../../services/redux/app/app.reducer';

const DialogManager = () => {
  const dispatch = useDispatch();
  const {
    isOpen,
    title,
    content,
    onConfirm,
    onClose,
  } = useSelector(({ app }) => app.confirmDialog);

  const [innerOpen, setInnerOpen] = useState(isOpen);
  const timeoutHideDialog = useRef();

  // Hide dialog before resetting its content
  // To avoid this dialog flicker
  const hideDialog = () => {
    setInnerOpen(false);

    timeoutHideDialog.current = setTimeout(() => {
      dispatch(
        showConfirmDialog(initialApps.confirmDialog),
      );
    }, 300);
  };

  // Sync up with open state from store
  useEffect(() => {
    setInnerOpen(isOpen);
  }, [isOpen]);

  // Clear timeout hide dialog when this component un-mount
  useEffect(() => function cleanUp() {
    clearTimeout(timeoutHideDialog.current);
  }, []);

  return (
    <Confirm
      isOpen={innerOpen}
      title={title}
      content={content}
      onConfirm={() => {
        onConfirm?.();
        hideDialog();
      }}
      onClose={() => {
        onClose?.();
        hideDialog();
      }}
    />
  );
};

DialogManager.propTypes = {};

DialogManager.defaultProps = {};

export default DialogManager;
