import { useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { useNotify, useUpdate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-final-form';
import keys from 'lodash/keys';
import useError from './useError';

const useEditButton = props => {
  const {
    resource, record, transform, translatedResourceName, redirectUrl,
  } = props;

  const translate = useTranslate();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();

  const {
    values: formValues, modified,
  } = useFormState();

  const onUpdateSuccess = response => {
    const { data } = response;
    const recordId = data?.id;
    const resourceNameTranslated = translatedResourceName || translate(`resources.${resource}.name`);
    notify(translate('ra.notification.updated'), 'success', {
      smart_name: resourceNameTranslated,
    });

    if (redirectUrl) {
      history.push(redirectUrl);
    } else if (recordId) {
      history.push(`/${resource}/${recordId}/show`);
    }
  };

  const [update, {
    loading: updateLoading, error,
  }] = useUpdate(undefined, undefined, undefined, undefined, {
    onSuccess: onUpdateSuccess,
  });

  const summitOnEdit = () => {
    const data = keys(modified).reduce((acc, curKey) => {
      const fieldName = curKey.split('.')[0];
      if ([undefined, null].includes(formValues[fieldName])) {
        acc[fieldName] = null;
      } else {
        acc[fieldName] = formValues[fieldName];
      }
      return acc;
    }, {});

    update({
      resource,
      payload: {
        id: record?.id,
        data: transform?.(data) || data,
      },
    });
  };

  useEffect(() => {
    if (!updateLoading) {
      if (error) {
        notifyError(error);
      }
    }
  }, [updateLoading, error]);

  return {
    summitOnEdit,
  };
};

export default useEditButton;
