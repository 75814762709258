// WEALTH SCHEMA
export const PULL_WEALTH_SCHEMA = 'PULL_WEALTH_SCHEMA';
export const PULL_WEALTH_SCHEMA_SUCCESS = 'PULL_WEALTH_SCHEMA_SUCCESS';

// NAV
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';

// PAYMENT
export const TOPUP_PGC = 'TOPUP_PGC';

// AUTH
export const GET_AUTH_INFO = 'GET_AUTH_INFO';
export const GET_AUTH_INFO_SUCCESS = 'GET_AUTH_INFO_SUCCESS';
export const GET_AUTH_INFO_FAILED = 'GET_AUTH_INFO_FAILED';
export const UPDATE_REQUIRE_CHANGE_PASSWORD = 'UPDATE_REQUIRE_CHANGE_PASSWORD';

// ANNOUNCEMENT
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_FAILED = 'GET_ANNOUNCEMENTS_FAILED';

// SETTING
export const SETTINGS = 'SETTINGS';

// APP
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const GET_ALLOWED_RESOURCES_SUCCESS = 'GET_ALLOWED_RESOURCES_SUCCESS';
export const STORE_DETAIL_NAME = 'STORE_DETAIL_NAME';
export const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG';

// BET
export const CACHE_BETS_BY_PAGE = 'CACHE_BETS_BY_PAGE';
export const UPDATE_CURRENT_BET_PAGE = 'UPDATE_CURRENT_BET_PAGE';

// WAGER
export const CACHE_WAGER_BY_PAGE = 'CACHE_WAGER_BY_PAGE';
export const UPDATE_CURRENT_WAGER_PAGE = 'UPDATE_CURRENT_WAGER_PAGE';

// LARGE WIN
export const CACHE_LARGE_WIN_BY_PAGE = 'CACHE_LARGE_WIN_BY_PAGE';
export const UPDATE_CURRENT_LARGE_WIN_PAGE = 'UPDATE_CURRENT_LARGE_WIN_PAGE';
