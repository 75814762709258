import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { FormWithRedirect, required, TextInput, useNotify, useRefresh, useTranslate } from 'react-admin';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { SaveButton, Button } from '../../ra/buttons';

function RejectModal({
  open, onClose, record, resource,
}) {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  async function handleSave(formValues) {
    const { notes } = formValues;

    try {
      await axios.post(`api/${resource}/${record?.id}/cancel`, {
        note: notes,
      });

      onClose();
      notify(translate('resources.fields.docStatus.canceled'), 'success');
      refresh();
    } catch (error) {
      onClose();
      if (!error?.response) {
        notify(translate('ra.message.tryAgain'), 'error');
      }
      notify(error?.response?.data?.message, 'error');
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperComponent={Paper}
    >
      <DialogTitle id="form-dialog-title">{`Reject ${resource} - ${record?.id}`}</DialogTitle>
      <FormWithRedirect
        resource={resource}
        save={handleSave}
        render={({
          handleSubmitWithRedirect, pristine, saving,
        }) => (
          <>
            <DialogContent>
              <TextInput
                style={{
                  textTransform: 'capitalize',
                }}
                source="notes"
                fullWidth
                validate={required()}
              />
            </DialogContent>
            <DialogActions>
              <Button
                label="ra.action.cancel"
                onClick={onClose}
                variant="text"
                color="primary"
                disabled={saving}
              />
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
                color="primary"
                variant="text"
                disabled={saving}
                icon={<></>}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
}

export default RejectModal;

RejectModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  record: PropTypes.object,
  resource: PropTypes.string,
};

RejectModal.defaultProps = {
  open: false,
  onClose: () => {},
  record: {},
  resource: '',
};
