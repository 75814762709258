/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const TextSplitting = props => {
  const {
    text,
    leftPart: {
      tag: leftTag,
      props: leftProps = null,
      limitChar: leftLimitChar,
    },
    middlePart: {
      tag: middleTag,
      props: middleProps = null,
    },
    rightPart: {
      tag: rightTag,
      props: rightProps = null,
      limitChar: rightLimitChar,
    },
  } = props;

  const startIdxSplit = text.indexOf(leftLimitChar);
  const endIdxSplit = text.lastIndexOf(rightLimitChar);

  const leftPart = text.slice(0, startIdxSplit);
  const middlePart = text.slice(startIdxSplit + leftLimitChar.length, endIdxSplit);
  const rightPart = text.slice(endIdxSplit + rightLimitChar.length, text.length);

  return (
    <>
      {React.createElement(leftTag, leftProps, leftPart)}
      {React.createElement(middleTag, middleProps, middlePart)}
      {React.createElement(rightTag, rightProps, rightPart)}
    </>
  );
};

export default TextSplitting;

TextSplitting.propTypes = {
  text: PropTypes.string.isRequired,
  leftPart: PropTypes.shape({
    tag: PropTypes.string,
    props: PropTypes.any,
    limitChar: PropTypes.string,
  }).isRequired,
  middlePart: PropTypes.shape({
    tag: PropTypes.string,
    props: PropTypes.any,
  }).isRequired,
  rightPart: PropTypes.shape({
    tag: PropTypes.string,
    props: PropTypes.any,
    limitChar: PropTypes.string,
  }).isRequired,
};

TextSplitting.defaultProps = {};
