/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import * as React from 'react';

const LinkField = props => {
  const {
    record, source,
  } = props;

  return (
    <Link
      variant="body2"
      href={record?.[source]}
      target="_blank"
      rel="noopener noreferrer"
    >
      {record?.[source]}
    </Link>
  );
};

LinkField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
};
LinkField.defaultProps = {
  record: {},
  // eslint-disable-next-line react/default-props-match-prop-types
};

export default LinkField;
