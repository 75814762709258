import React, { useCallback, useEffect, useState } from 'react';
import { required, useTranslate } from 'react-admin';
import moment from 'moment';
import { useForm } from 'react-final-form';
import DatePickerInput from '../../../base/components/ra/inputs/datePicker.input';
import { validateDateTime, dateTimeMustBeAfterCurrent } from '../../../services/util/validate/validateMethods';
import { InputGridWrapper } from '../../../base/components/layout/input-wrapper';

const getRange = (start, end) => [...Array(end - start + 1).keys()].map(i => i + start);

export const EXPIRES_INPUT_ID = 'claimBeforeId';

const ExpiresInput = () => {
  const translate = useTranslate();
  const form = useForm();
  const [errorMessage, setErrorMessage] = useState('');

  const disabledDate = useCallback(d => {
    if (d == null) {
      return null;
    }

    const minDate = moment();

    return minDate != null && d.isBefore(minDate) && !d.isSame(minDate, 'day');
  }, []);

  const disabledTime = useCallback(d => {
    if (d == null) {
      return null;
    }

    const minDate = moment();

    if (d.isSame(minDate, 'day')) {
      return {
        disabledHours: () => (minDate.hour() > 0 ? getRange(0, minDate.hour() - 1) : []),
        disabledMinutes: hour => (hour === minDate.hour() && minDate.minutes() > 0 ? getRange(0, minDate.minutes() - 1) : []),
      };
    }

    return null;
  }, []);

  useEffect(() => {
    const expiresInput = document.getElementById(EXPIRES_INPUT_ID);

    const updateErrorMessage = () => {
      const { claimBefore } = form.getState().values;

      const isExpiresDateBeforeCurrent = dateTimeMustBeAfterCurrent(translate)(claimBefore);

      if (isExpiresDateBeforeCurrent) {
        setErrorMessage(isExpiresDateBeforeCurrent);
      }
    };

    const clearErrorMessage = () => {
      setErrorMessage('');
    };

    expiresInput.addEventListener('blur', updateErrorMessage);
    expiresInput.addEventListener('focus', clearErrorMessage);

    return () => {
      expiresInput.removeEventListener('blur', updateErrorMessage);
      expiresInput.removeEventListener('focus', clearErrorMessage);
    };
  }, []);

  return (
    <InputGridWrapper>
      <DatePickerInput
        id={EXPIRES_INPUT_ID}
        label={translate('resources.reward.fields.claimBefore')}
        source="claimBefore"
        validate={[required(), validateDateTime(translate), dateTimeMustBeAfterCurrent(translate)]}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        errorMessage={errorMessage}
      />
    </InputGridWrapper>
  );
};

export default ExpiresInput;
