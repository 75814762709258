/* eslint-disable */
import React from "react";
import AccessTime from "@material-ui/icons/AccessTime";
import Axios from "axios";
import Type from "prop-types";
import { useNotify, useTranslate, useRefresh } from "ra-core";
import { SmallButton } from "../../../base/components/ra/buttons";
import ToolbarMenu from "../../../base/components/action-menu/toolbar-menu";
import { useError } from "../../../base/hooks";

export const ActionMenu = (props) => {
  const { record, list } = props;
  const notify = useNotify();
  const { notifyError } = useError();
  const translate = useTranslate();
  const refresh = useRefresh();

  const activeEvent = () => {
    Axios.post(`api/cheat-mode/${record?.id}/generate-cheat-mode-list`);
    notify("Successfully", "success");
  };

  const mainComponent = record.enabled && (
    <SmallButton
      label="ra.action.generateCheatModeList"
      onClick={activeEvent}
      startIcon={<AccessTime />}
    />
  );

  if (list) {
    return mainComponent;
  }

  return <ToolbarMenu {...props}>{mainComponent}</ToolbarMenu>;
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};
