import { addExportListener } from './exporters.util';

const gamePerformanceExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;

  const group = await fetchRelatedRecords(records, 'group', 'group');
  const brand = await fetchRelatedRecords(records, 'brand', 'brand');
  const game = await fetchRelatedRecords(records, 'gameId', 'game');
  const currency = await fetchRelatedRecords(records, 'currency', 'currency');

  const gamePerformanceForExport = records.map(data => {
    const result = {
      id: data.id,
      currency: currency[data.currency]?.code,
      group: group[data.group]?.name,
      brand: brand[data.brand]?.name,
      game: data.game,
      gameId: game[data.gameId]?.gameId,
      country: data.country,
      channel: data.channel,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
      wonAmount: data.wonAmount?.toString?.()?.replace?.('.', ','),
      date: data.date,
    };

    return result;
  });

  addExportListener({
    dataList: gamePerformanceForExport,
    dateString: date,
    fileName: 'Game Performance Report',
  });
};

export default gamePerformanceExporter;
