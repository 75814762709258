/* eslint-disable */
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';
import { LIST_REPORTS } from '../../report-winners.list';
import Empty from '../../../../base/components/empty';
import OverallTable from './OverallTable';
import PrepaidTable from './PrepaidTable';
import StandardTable from './StandardTable';

const WinnersReportResults = () => {
  const { data, loading: loadingAllData } = useReportContext();

  const hasNoData = useMemo(() => {
    const totalTableNoData = Object.values(data).reduce((totalNoDataTable, table) => {
      if (!table?.total && Array.isArray(table?.data)) {
        return totalNoDataTable + 1;
      }
      return totalNoDataTable;
    }, 0);
    return totalTableNoData === LIST_REPORTS.length && !loadingAllData;
  }, [data, loadingAllData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 24,
      }}
    >
      {hasNoData ? (
        <Empty />
      ) : (
        <>
          <OverallTable />
          <PrepaidTable />
          <StandardTable />
        </>
      )}
    </Box>
  );
};

export default WinnersReportResults;
