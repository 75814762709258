import React from 'react';
import { NumberField } from 'react-admin';
import PropTypes from 'prop-types';

const DecimalField = ({
  options = {}, ...restProps
}) => (
  <NumberField
    options={{
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      ...options,
    }}
    {...restProps}
  />
);

DecimalField.propTypes = {
  options: PropTypes.object,
};

DecimalField.defaultProps = {
  options: {},
};

export default DecimalField;
