/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useTranslate, useListContext, useResourceContext } from 'ra-core';
import axios from 'axios';
import throttle from 'lodash/throttle';

import { getQueryUrl } from '../../../../services/provider/dataProvider';
import Button from './Button';

const ExportButton = (props) => {
  const {
    maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    sort, // deprecated, to be removed in v4
    date,
    additionFilter = {},
    reportView,
    disabled,
    ...rest
  } = props;
  const {
    filter,
    filterValues: raFilterValues = {},
    currentSort,
    exporter: exporterFromContext,
    total,
    error,
  } = useListContext(props);

  const [isOpenWaitingDialog, setIsOpenWaitingDialog] = useState(false);

  const filterValues = {
    ...additionFilter,
    ...raFilterValues,
  };

  const resource = useResourceContext(props);
  const handleClick = async () => {
    const { query: queryString } = getQueryUrl(resource, {
      sort: currentSort || sort,
      filter: filter
        ? {
            ...filterValues,
            ...filter,
          }
        : filterValues,
    });

    try {
      axios.post(`api/${resource}/export?${queryString}`, {}).catch(() => {
        // Do nothing!
      });
      setIsOpenWaitingDialog(true);
    } catch {
      // Do nothing!
    }
  };

  return (
    <>
      <Button
        onClick={throttle(handleClick, 1000)}
        label={label}
        disabled={disabled || total === 0 || !!error}
        {...sanitizeRestProps(rest)}
      >
        {icon}
      </Button>
      <DialogExportAfter open={isOpenWaitingDialog} onClose={() => setIsOpenWaitingDialog(false)} />
    </>
  );
};

function DialogExportAfter({ onClose, open }) {
  const translate = useTranslate();

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: 'uppercase' }}>
        {translate('ra.message.gotoExportHistoryDetailTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{translate('ra.text.messageAfterClickExport')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={() => onClose()} color="primary">
          {translate('ra.action.close')}
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({ basePath, filterValues, resource, ...rest }) => rest;

ExportButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filterValues: PropTypes.object,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  resource: PropTypes.string,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  icon: PropTypes.element,
};

export default ExportButton;
