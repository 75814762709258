/* eslint-disable */
import * as React from "react";
import PropTypes from "prop-types";
import DeleteWithUndoButton from "./DeleteWithUndoButton";
import DeleteWithConfirmButton from "./DeleteWithConfirmButton";

const DeleteButton = ({ undoable, record, ...props }) => {
  if (!record || record.id == null) {
    return null;
  }
  return undoable ? (
    <DeleteWithUndoButton record={record} {...props} />
  ) : (
    <DeleteWithConfirmButton record={record} {...props} />
  );
};

DeleteButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  // @ts-ignore
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  undoable: PropTypes.bool,
  icon: PropTypes.element,
};

DeleteButton.defaultProps = {
  undoable: false,
};

export default DeleteButton;
