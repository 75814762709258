/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Grid, makeStyles } from '@material-ui/core';
import { useFormState, useForm } from "react-final-form";

import { ReferenceInput, AutocompleteInput } from '../../base/components/ra/inputs';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { FILTER_PREFIX_ON_VALUE } from '../../constant';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& [id$="helper-text"]': {
      display: 'none',
    }
  }
}), {
  name: "InboxRuleList_AdditionFilters"
});

const AdditionFilters = () => {
  const translate = useTranslate();
  const classes  = useStyles();
  
  const form = useForm();
  const { values: formValues } = useFormState();

  useEffect(() => {
    form.change('brandId', undefined);
  }, [formValues.groupId]);

  return (
    <>
      <Grid
        item
        xs={12}
        sm="auto"
        className={classes.textField}
      >
        <ReferenceInput
          label="resources.group.name"
          alwaysOn
          reference="group"
          source="groupId"
          perPage={100}
          filterToQuery={value => ({
            name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
          })}
        >
          <AutocompleteInput
            emptyText={`--${translate(
              'ra.text.any',
            ).toUpperCase()}--`}
            optionText="name"
            resettable
          />
        </ReferenceInput>
      </Grid>
      <Grid
        item
        xs={12}
        sm="auto"
      >
        <ReferenceInput
          label="resources.brand.name"
          alwaysOn
          reference="brand"
          source="brandId"
          optionText="name"
          perPage={100}
          filter={{
            ['group.id']: formValues.groupId ? `${FILTER_PREFIX_ON_VALUE.EQUALS}${formValues.groupId}` : '',
          }}
          disabled={!formValues.groupId}
        >
          <AutocompleteInput
            emptyText={`--${translate(
              'ra.text.any',
            ).toUpperCase()}--`}
            optionText="name"
            resettable
          />
        </ReferenceInput>
      </Grid>
    </>
  );
};

const InboxRuleList = props => {
  return (
    <WealthListGuesser
      {...props}
      // extraFilterInputs={<AdditionFilters />}
      filterChildren={<AdditionFilters />}
    />
  );
};

export default InboxRuleList;
