export const BET_SETTING_CONFIG_STATUS = {
  defaultSetting: {
    // text: 'Based On Bet Setting Default',
    text: 'ra.text.baseOnBetSettingDefault',
    value: 'defaultSetting',
  },
  customSetting: {
    // text: 'Bet Setting Custom',
    text: 'ra.text.betSettingCustom',
    value: 'customSetting',
  },
  noSetting: {
    // text: 'Have No Any Setting',
    text: 'ra.text.haveNoAnySetting',
    value: 'noSetting',
  },
};

export const FORM_INIT_VERSION = {
  NEW_FORM: 'NEW_FORM',
  DRAFT_FORM: 'DRAFT_FORM',
};

export const BORDER_COLOR = '#afafaf';

export const BRAND_BACKGROUND = '#e5e6e5';
