/* eslint-disable react/no-array-index-key */
import React from 'react';
import TableCell from './table-cell';

const EmptyColumn = ({
  col, classes,
}) => Array(col)
  .fill(0)
  .map((_, index) => (
    <TableCell
      key={`empty-column-${index}`}
      classes={classes}
    />
  ));

export default EmptyColumn;
