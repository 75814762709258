import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import {
  FormControl,
  FormHelperText,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { countBy, uniqBy } from 'lodash';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const PrizeDropTableInput = props => {
  const { helperText } = props;
  const [prizeCount, setPrizeCount] = useState(0);
  const [prizeValue, setPrizeValue] = useState(0);
  const [prizeArray, setPrizeArray] = useState([]);
  const [tableData, setTableData] = useState([{}]);

  const { input: { onChange } } = useInput(props);

  function handleAddPrize() {
    if (prizeCount > 0 && prizeValue > 0) {
      const subPrizeArray = new Array(prizeCount).fill(prizeValue);
      const newPrizeArray = [...prizeArray, ...subPrizeArray];
      setPrizeArray([...newPrizeArray.sort((a, b) => b - a)]);
    }
  }

  // Handle transform data
  useEffect(() => {
    // Keep re-count all prizeCount
    const prizeCounters = countBy(prizeArray);
    const uniquePrizeArray = uniqBy(prizeArray);
    const data = uniquePrizeArray.map(prize => ({
      prize,
      prizeCount: prizeCounters?.[prize],
    }));
    setTableData(data);
    // Update to form (Edit/Create)
    onChange(data);
  }, [prizeArray]);

  function setCount(e) {
    setPrizeCount(Number.parseInt(e.target.value, 10));
  }

  function setValue(e) {
    setPrizeValue(Number.parseInt(e.target.value, 10));
  }

  function handleRemovePrize(prize) {
    setPrizeArray(prizeArray.filter(p => p !== prize));
  }

  return (
    <FormControl>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            style={{
              gap: '8px',
            }}
          >
            <TextField
              label="Prize Count"
              type="number"
              variant="filled"
              onChange={setCount}
            />
            <TextField
              label="Prize Value"
              type="number"
              variant="filled"
              onChange={setValue}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleAddPrize}
            >
              Add
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Count</TableCell>
                <TableCell>Prize</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map(item => (
                <TableRow key={JSON.stringify(item)}>
                  <TableCell>{item.prizeCount}</TableCell>
                  <TableCell>{item.prize}</TableCell>
                  <TableCell>
                    <Tooltip title="Remove">
                      <IconButton
                        color="secondary"
                        variant="contained"
                        aria-label="remove"
                        onClick={() => handleRemovePrize(item.prize)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

PrizeDropTableInput.propTypes = {
  // record: PropTypes.object,
  helperText: PropTypes.string,
};

PrizeDropTableInput.defaultProps = {
  // record: {},
  helperText: '',
};

export default PrizeDropTableInput;
