import React, { useCallback, useMemo } from 'react';
import { Labeled, usePermissions, useShowController, useTranslate } from 'react-admin';
import { Chip, Box } from '@material-ui/core';
import get from 'lodash/get';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { PREPAID_APPROVAL_STATUS, PrepaidStatusEnum } from '../../constant';
import resourceSlug from '../../constant/resource-slug';
import RequesterField from './components/RequesterField';
import ToolbarMenuForDetail from './components/ToolbarMenuForDetail';
import { useAuthUser } from '../../base/hooks';

export default function PrepaidShow(props) {
  const { record } = useShowController(props);
  const translate = useTranslate();
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasPrepaidApprovalPermissionToRead = user?.superman || (Array.isArray(permissions) && !!permissions?.find(
    item => item.resource.name === resourceSlug.PREPAID_APPROVAL,
  )?.read);
  const hasPrepaidApprovalPermissionToUpdate = user?.superman || (Array.isArray(permissions) && !!permissions?.find(
    item => item.resource.name === resourceSlug.PREPAID_APPROVAL,
  )?.update);
  const hasPrepaidPermissionToEdit = user?.superman || (Array.isArray(permissions) && !!permissions?.find(item => item.resource.name === resourceSlug.PREPAID)?.update);

  const playerList = get(record, 'players') || [];

  const excludeFields = useMemo(
    () => (hasPrepaidApprovalPermissionToRead ? ['requester'] : ['requester', 'approvalStatus']),
    [hasPrepaidApprovalPermissionToRead],
  );

  const hasEditButton = useCallback(
    detailedPrepaid => {
      const isApprovedPrepaid = detailedPrepaid?.approvalStatus === PREPAID_APPROVAL_STATUS.approved;
      if (isApprovedPrepaid && !hasPrepaidApprovalPermissionToUpdate) return false;
      const isInactivePrepaid = detailedPrepaid?.status !== PrepaidStatusEnum.ACTIVE;
      return isInactivePrepaid && hasPrepaidPermissionToEdit;
    },
    [hasPrepaidPermissionToEdit, hasPrepaidApprovalPermissionToUpdate],
  );

  return (
    <WealthShowGuesser
      {...props}
      actionMenu={<ToolbarMenuForDetail type="toolbar" />}
      excludeFields={excludeFields}
      forceEditAble={hasEditButton}
      extraBlocks={[
        <Box>
          <Box
            style={{
              color: '#A5A5A5',
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: 1,
              marginBottom: '8px',
            }}
          >
            {translate('resources.prepaid.fields.players', {
              _: 'Players',
            })}
          </Box>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            {playerList.length > 0 ? (
              playerList.map(item => (
                <Chip
                  key={item.id}
                  label={item?.nativeId}
                />
              ))
            ) : (
              <Box>{translate('ra.text.allPlayers')}</Box>
            )}
          </Box>
        </Box>,
      ]}
      extraInformBlocks={[
        <Labeled label="resources.prepaid.fields.requester">
          <RequesterField {...props} />
        </Labeled>,
      ]}
    />
  );
}
