import React from 'react';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import ValueTypeSelector from './components/ValueTypeSelector';

export default function GameFeatureEdit(props) {
  return (
    <WealthEditGuesser
      {...props}
      fieldOrder={['name', 'code', 'valueType']}
    >
      <ValueTypeSelector
        source="valueType"
        disabled
      />
    </WealthEditGuesser>
  );
}
