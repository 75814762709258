import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading, required, useTranslate } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import axios from 'axios';
import WealthCreate from '../../../base/components/guesser/wealth-create';
import StringInput from '../../../base/components/customized/common-input/StringInput';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import useConfiguration from './hooks/useConfiguration';
import { NavigationContext } from '../../../App';
import { CUSTOM_ROUTES, REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';
import { getAvailableBrandsEndpoint } from './components/PartnerList';
import { SelectInput } from '../../../base/components/ra/inputs';

const requiredValidator = required();

const BrandSelector = () => {
  const {
    values: {
      groupId, name,
    },
  } = useFormState();
  const form = useForm();
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleBrandChange = e => {
    if (name) return;
    const newBrandId = e.target.value;
    const newBrandName = brands.find(item => item.brandId === newBrandId)?.name;

    form.change('name', newBrandName);
  };

  useEffect(() => {
    axios
      .get(getAvailableBrandsEndpoint(groupId))
      .then(({ data }) => {
        setBrands(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <SelectInput
      variant="outlined"
      validate={requiredValidator}
      fullWidth
      label="common.fields.brand"
      source="brandId"
      isLoading={isLoading}
      choices={brands}
      optionText="name"
      optionValue="brandId"
      onChange={handleBrandChange}
      options={{
        SelectProps: {
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        },
      }}
    />
  );
};

const PartnerCreate = ({
  resource, ...otherProps
}) => {
  const { setNavigationData } = useContext(NavigationContext);
  const {
    data: configInfo, isLoading,
  } = useConfiguration();
  const translate = useTranslate();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: translate('resources.reverse-integration.partner.name'),
      },
      breadcrumbs: [
        {
          label: translate('ra.page.home'),
          link: '/',
        },
        {
          label: translate('resources.group.system-setup.name'),
        },
        {
          label: translate('resources.reverse-integration.name'),
          link: '/reverse-integration',
        },
        {
          label: REVERSE_INTEGRATION_OPERATORS.VERTBET.name,
          link: CUSTOM_ROUTES.VERTBET_PARTNER,
        },
        {
          label: translate('ra.page.create', {
            name: '',
          }),
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, []);

  if (isLoading) return <Loading />;

  const groupId = configInfo?.groupId;

  return (
    <WealthCreate
      resource={resource}
      writableFields={[
        'name',
        'partnerId',
        'brandId',
        'groupId',
        'placeBetUrl',
        'payOutUrl',
        'authorizeUrl',
        'getBalanceUrl',
        'cancelBetUrl',
      ]}
      confirmDialogInfo={{
        content: 'resources.reverse-integration.partner.confirmToCreateContent',
      }}
      translatedResourceName={translate('resources.reverse-integration.partner.name')}
      {...otherProps}
    >
      <GroupSelector
        disabled
        initialValue={groupId}
        validate={requiredValidator}
        variant="outlined"
        fullWidth
        inputProps={{
          helperText: true,
          resettable: false,
        }}
      />
      <BrandSelector />
      <StringInput
        source="name"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <StringInput
        source="partnerId"
        validate={requiredValidator}
        fullWidth
        helperText
        label="resources.reverse-integration.partner.fields.partnerId"
      />
      <UrlInput
        source="authorizeUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="placeBetUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="payOutUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="getBalanceUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
      <UrlInput
        source="cancelBetUrl"
        validate={requiredValidator}
        fullWidth
        helperText
      />
    </WealthCreate>
  );
};

export default PartnerCreate;

PartnerCreate.propTypes = {
  resource: PropTypes.string.isRequired,
  options: PropTypes.shape({
    operatorId: PropTypes.string.isRequired,
  }).isRequired,
};
