import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useReportContext } from '../ReportLayout';
import { ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';
import { TooltipForDisabledInput } from '../../custom-tooltip';

const CurrencyInput = ({
  source, tooltipProps, ...otherProps
}) => {
  const form = useForm();
  const { currentFilters } = useReportContext();

  const translate = useTranslate();

  const currencyType = currentFilters?.currencyType;

  const hasNoCurrencyType = !currencyType;

  return (
    <>
      <OnChange name="group">
        {() => {
          form.change(source, null);
        }}
      </OnChange>
      <OnChange name="brand">
        {() => {
          form.change(source, null);
        }}
      </OnChange>
      <OnChange name="currencyType">
        {() => {
          form.change(source, null);
        }}
      </OnChange>
      <FormField name={source}>
        {() => (
          <TooltipForDisabledInput
            title={translate('ra.message.pleaseSelectFirst', {
              smart_name: translate('resources.report.fields.currencyType'),
            })}
            placement="top-start"
            showMessage={hasNoCurrencyType}
            {...tooltipProps}
          >
            <ReferenceInput
              label={translate('resources.report.fields.currency')}
              source={source}
              reference="currency"
              filter={{
                enabled: true,
                type: currencyType,
                'brands.id||$in': currentFilters?.brand,
                'groups.id||$in': currentFilters?.group,
              }}
              filterToQuery={value => ({
                'code||$cont': value,
              })}
              sort={{
                field: 'code',
                order: 'ASC',
              }}
              disabled={hasNoCurrencyType}
              perPage={100}
              shouldRenderSuggestions={() => !hasNoCurrencyType}
              {...otherProps}
            >
              <AutocompleteInput
                optionText="code"
                resettable
              />
            </ReferenceInput>
          </TooltipForDisabledInput>
        )}
      </FormField>
    </>
  );
};

CurrencyInput.propTypes = {
  source: PropTypes.string,
  tooltipProps: PropTypes.object,
};

CurrencyInput.defaultProps = {
  source: 'currency',
  tooltipProps: {},
};

export default CurrencyInput;
