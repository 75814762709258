/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNotify, useInput, useTranslate } from 'ra-core';
import { Box, TextField, Button } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';

import { TWO_DECIMAL_REGEX } from '../../../../services/util/validate/regularExpression';
import { getNumberOnlyTwoDigitsAfter } from '../../../../services/util/formatNumber';
import { useBetSettingPickerContext } from './bet-setting-picker.context';
import PickerColumn from './picker-column';
import { useBetSettingPickerTranslation } from './bet-setting-picker.hook';
import { useClasses } from './bet-setting-picker.style';
import { PICKER_MAX_WIDTH  } from './bet-setting-picker.constant';

function BetSettingPicker(props) {
  const {
    sourceNamePrefix,
    validate,
    baseBetInit,
    listItemInit = {},
    defaultValueInit = {},
    onResetSetting,
    defaultSettingValue,
    ...restProps
  } = props;

  const form = useForm();

  const {
    // States
    betSize,
    betLevel,
    baseBet,
    totalBet,
    // Set states
    setBetSize,
    setBetLevel,
    setBaseBet,
    setTotalBet,
    // Touches
    betSizeTouch,
    betLevelTouch,
    baseBetTouch,
    totalBetTouch,
    // Update touches
    setBetSizeTouch,
    setBetLevelTouch,
    setBaseBetTouch,
    setTotalBetTouch,
  } = useBetSettingPickerContext();

  const updateSettingValue = {
    betSize: setBetSize,
    betLevel: setBetLevel,
    baseBet: setBaseBet,
    totalBet: setTotalBet,
  };
  const updateSettingTouch = {
    betSize: setBetSizeTouch,
    betLevel: setBetLevelTouch,
    baseBet: setBaseBetTouch,
    totalBet: setTotalBetTouch,
  };
  const settingValue = {
    betSize,
    betLevel,
    baseBet,
    totalBet,
  };
  const settingTouch = {
    betSize: betSizeTouch,
    betLevel: betLevelTouch,
    baseBet: baseBetTouch,
    totalBet: totalBetTouch,
  };

  const pickerColumnRef = useRef();
  const pickerColumnWidth = pickerColumnRef.current?.offsetWidth;

  const classes = useClasses({
    pickerColumnWidth,
  });
  const notify = useNotify();
  const translate = useTranslate();
  const translation = useBetSettingPickerTranslation();

  const [totalBetMap, setTotalBetMap] = useState({});

  const [errors, setErrors] = useState({
    betSize: null,
    betLevel: null,
  });
  const [totalBetError, setTotalBetError] = useState(null);

  const [defaultValue, setDefaultValue] = useState({
    betSize: null,
    betLevel: null,
    baseBet: null,
    totalBet: null,
  });

  const [selectedValue, setSelectedValue] = useState({
    betSize: null,
    betLevel: null,
    baseBet: null,
    totalBet: null,
  });

  const [betSizeField, setBetSizeField] = useState('');
  const [betLevelField, setBetLevelField] = useState('');

  // defaultValueValidate to validate value & defaultValue of betSize & betLevel
  const defaultValueValidate = (_, allValues) => {
    const newErrors = {
      betSize: null,
      betLevel: null,
    };

    const {
      betSize: betSizeValue = [],
      betLevel: betLevelValue = [],
    } = get(allValues, `${sourceNamePrefix}picker.listItem`, {});

    const {
      betSize: betSizeDefault = [],
      betLevel: betLevelDefault = [],
    } = get(allValues, `${sourceNamePrefix}picker.defaultValue`, {});

    switch (true) {
      case betSizeValue.length === 0:
        newErrors.betSize = translation.betSize.isRequired;
        break;

      case betLevelValue.length === 0:
        newErrors.betLevel = translation.betLevel.isRequired;
        break;

      case !betSizeDefault:
        newErrors.betSize = translation.betSizeDefaultIsRequired;
        break;

      case !betLevelDefault:
        newErrors.betLevel = translation.betLevelDefaultIsRequired;
        break;

      default:
        newErrors.betSize = null;
        newErrors.betLevel = null;
        break;
    }

    // catch validate error when form submitting
    // all values valid but betSize & betLevel isn't
    if (baseBet.length > 0) {
      updateSettingTouch.betSize(true);
      updateSettingTouch.betLevel(true);
      updateSettingTouch.totalBet(true);
    }

    // Update errors of betSize & betLevel
    setErrors({
      ...errors,
      ...newErrors,
    });

    // Return error message or undefined
    return newErrors.betSize || newErrors.betLevel;
  };

  const checkTotalBetLimit = (_, allValue) => {
    const {
      totalBetMin, totalBetMax,
    } = get(allValue, sourceNamePrefix.slice(0, sourceNamePrefix.length - 1));
    const totalBetValue = get(allValue, `${sourceNamePrefix}picker.listItem.totalBet`);

    // If totalBet is invalid => don't check validation
    if (totalBetMin >= totalBetMax) {
      setTotalBetError(null);
      return undefined;
    }

    // totalBet is valid!
    // Check all items in totalBet
    // every item must be in min & max bound
    const allInBound = totalBetValue.every(item => item.value >= totalBetMin && item.value <= totalBetMax);
    if (allInBound) {
      setTotalBetError(null);
      return undefined;
    }

    // Update & show total bet error
    const newTotalBetError = translation.totalBetExceedLimit;
    setTotalBetError(newTotalBetError);
    updateSettingTouch.totalBet(true);

    return newTotalBetError;
  };

  const newValidate = validate || [];
  newValidate.push(defaultValueValidate, checkTotalBetLimit);

  const {
    input: {
      onChange,
      onBlur,
      onFocus,
    },
  } = useInput({
    ...restProps,
    validate: newValidate,
  });

  const sortListItem = list => orderBy(list, ['value'], 'asc');

  // `updateSettingList` to update new value for setting name
  // and update touch state
  const updateSettingList = (name, newList) => {
    if (typeof updateSettingValue[name] === 'function') {
      updateSettingValue[name](
        sortListItem(newList),
      );
    }

    if (typeof updateSettingTouch[name] === 'function') {
      updateSettingTouch[name](true);
    }

    updateSettingTouch.totalBet(true);
  };

  // Click to update Default Value
  const handleIconLeftClick = (name, value) => {
    setDefaultValue({
      ...defaultValue,
      [name]: value,
    });
  };

  // handleIconRightClick to delete item of Picker Column
  // and reset default value if it's this one
  const handleIconRightClick = (name, value) => {
    if (defaultValue[name] === value) {
      setDefaultValue({
        ...defaultValue,
        [name]: null,
      });
    }

    // Update settingValue of `name`
    let changedIndex = 0;
    const newArr = settingValue[name].filter((item, idx) => {
      // Delete value in settingValue
      if (item.value === value) {
        changedIndex = idx;
        return false;
      }
      return true;
    });

    updateSettingList(name, newArr);

    // Update selected value
    const nextValueSelected = newArr[changedIndex]?.value || newArr[changedIndex - 1]?.value || null;
    setSelectedValue({
      ...selectedValue,
      [name]: nextValueSelected,
    });
  };

  const handleBetSizeFieldChange = e => {
    e.preventDefault();
    setBetSizeField(e.target.value);
  };

  const handleBetLevelFieldChange = e => {
    e.preventDefault();
    setBetLevelField(e.target.value);
  };

  const addBetSize = e => {
    e.preventDefault();

    const betSizeFieldNumber = Number(betSizeField);
    if (isNaN(betSizeFieldNumber)) {
      setBetSizeField('');
      return;
    }

    if (betSizeFieldNumber <= 0) {
      notify(translation.betSize.mustGreatThanZero, 'error');
      return;
    }

    if (!TWO_DECIMAL_REGEX.test(String(betSizeFieldNumber))) {
      notify(translation.betSize.onlyTwoDigitsAfter, 'error');
      return;
    }

    const betSizeExisted = betSize.some(item => item.value === betSizeFieldNumber);
    if (betSizeExisted) {
      notify(translation.betSize.existed, 'error');
      return;
    }

    updateSettingList(
      'betSize',
      [
        ...betSize,
        {
          value: betSizeFieldNumber,
        },
      ],
    );

    setBetSizeField('');
  };

  const addBetLevel = e => {
    e.preventDefault();

    const betLevelFieldNumber = Number(betLevelField);
    if (isNaN(betLevelFieldNumber)) {
      setBetLevelField('');
      return;
    }

    if (betLevelFieldNumber <= 0) {
      notify(translation.betLevel.mustGreatThanZero, 'error');
      return;
    }

    if (!TWO_DECIMAL_REGEX.test(String(betLevelFieldNumber))) {
      notify(translation.betLevel.onlyTwoDigitsAfter, 'error');
      return;
    }

    const betLevelExisted = betLevel.some(item => item.value === betLevelFieldNumber);
    if (betLevelExisted) {
      notify(translation.betLevel.existed, 'error');
      return;
    }

    updateSettingList(
      'betLevel',
      [
        ...betLevel,
        {
          value: +betLevelFieldNumber.toFixed(2),
        },
      ],
    );

    setBetLevelField('');
  };

  const handlePickerSelect = (pickerName, value) => {
    if (!selectedValue.baseBet && baseBetInit >= 0) {
      setSelectedValue(prev => ({
        ...prev,
        baseBet: baseBetInit,
        [pickerName]: value,
      }));
      return;
    }
    setSelectedValue({
      ...selectedValue,
      [pickerName]: value,
    });
  };

  // reStructureBaseBetValue return the correct baseBet value
  // this returned value will adapt with picker and display it
  const reStructureBaseBetValue = baseBet => {
    switch (true) {
      // Base Bet is an array
      case Array.isArray(baseBet):
        return baseBet.map(item => ({
          value: item,
        }));

      // Base Bet is a number
      case !isNaN(baseBet):
        return [{
          value: baseBet,
        }];

      default:
        return [];
    }
  };

  const referTotalBetAfterIndicatorChange = changer => {
    const itemsWithoutChanger = ['betSize', 'betLevel', 'baseBet'].filter(item => item !== changer);

    const someItemInvalid = itemsWithoutChanger.some(item => selectedValue[item] == null) || selectedValue[changer] == null;
    if (someItemInvalid) {
      return;
    }

    let totalBetItem = itemsWithoutChanger.reduce((acc, cur) => {
      return acc * Number(selectedValue[cur]);
    }, 1);
    
    // Multiple with changer
    totalBetItem *= Number(selectedValue[changer]);
    return getNumberOnlyTwoDigitsAfter(totalBetItem);
  };

  const handleDisplayTotalBet = changer => {
    if (totalBet.length === 0) {
      return;
    }

    const newTotalBetValue = referTotalBetAfterIndicatorChange(changer);
    if (
      newTotalBetValue in totalBetMap &&
      newTotalBetValue === selectedValue.betSize * selectedValue.betLevel * selectedValue.baseBet
    ) {
      setSelectedValue({
        ...selectedValue,
        totalBet: newTotalBetValue,
      });
    } else {
      setSelectedValue({
        ...selectedValue,
        ...totalBetMap[totalBet],
        totalBet: newTotalBetValue,
      });
    }
  };

  useEffect(() => {
    handleDisplayTotalBet('betSize');
  }, [selectedValue.betSize]);

  useEffect(() => {
    handleDisplayTotalBet('betLevel');
  }, [selectedValue.betLevel]);

  useEffect(() => {
    handleDisplayTotalBet('baseBet');
  }, [selectedValue.baseBet]);

  /**
   * getParamsFromTotalBet to get betSize & betLevel & baseBet
   * from totalBet
   * @param {*} totalBet
   * @returns { betSize, betLevel, baseBet }
   */
  const getParamsFromTotalBet = (
    totalBet,
    {
      currentBetSize,
      currentBetLevel,
      currentBaseBet,
    } = {},
  ) => {
    if (currentBetSize * currentBetLevel * currentBaseBet === totalBet) {
      return {};
    }

    return totalBetMap[totalBet] || {};
  };

  // Calculator Total Bets
  // totalBet = betSize * betLevel * baseBet
  useEffect(
    () => {
      const newTotalBetMap = {};
      const totalBetSet = new Set();

      betSize.forEach(bsItem => {
        betLevel.forEach(blItem => {
          baseBet.forEach(bbItem => {
            const totalBetItem = +(bsItem.value * blItem.value * bbItem.value).toFixed(2);
            totalBetSet.add(totalBetItem);
            
            const key = getNumberOnlyTwoDigitsAfter(totalBetItem);
            newTotalBetMap[key] = {
              betSize: bsItem.value,
              betLevel: blItem.value,
              baseBet: bbItem.value,
            };
          });
        });
      });

      // Store totalBet value map into betSize & betLevel & baseBet
      // to reference by totalBet value
      setTotalBetMap(newTotalBetMap);

      const totalBetList = [...totalBetSet].map(item => ({
        value: item,
      }));
      updateSettingList('totalBet', totalBetList);
    },
    [betSize, betLevel, baseBet],
  );

  const AddBetInput = useCallback(({
    inputProps, ...rest
  }) => {
    const textFieldPadding = 10;
    return (
      <TextField
        inputProps={{
          style: {
            textAlign: 'center',
            boxSizing: 'border-box',
            minWidth: `calc(100% - ${textFieldPadding * 2}px)`,
            height: 40,
            padding: textFieldPadding,
            ...(inputProps?.style || {}),
          },
          ...(inputProps || {}),
        }}
        type="number"
        min={1}
        placeholder={translate('ra.action.typeYourNumber')}
        {...rest}
      />
    );
  }, []);

  const AddBetButton = useCallback(({
    children, ...rest
  }) => (
    <Button
      fullWidth
      size="small"
      variant="contained"
      color="primary"
      style={{
        margin: '6px 0px 0px',
      }}
      {...rest}
    >
      {children}
    </Button>
  ), []);

  const TitleElement = useCallback(({
    children, isError,
  }) => (
    <Box
      className={classNames({
        [classes.invalidColor]: isError,
      })}
    >
      {children}
    </Box>
  ), [classes]);

  const HelpText = () => {
    let errorText = '';

    if (settingTouch.betSize && errors.betSize) {
      errorText = errors.betSize;
    } else if (settingTouch.betLevel && errors.betLevel) {
      errorText = errors.betLevel;
    } else if (settingTouch.totalBet && totalBetError) {
      errorText = totalBetError;
    }

    const formPickerErrors = form.getState().errors?.picker;
    if (!errorText && formPickerErrors) {
      errorText = translate(formPickerErrors, { smart_name: translate('wa.common.picker') });
    }

    return <Box className={classes.helpText}>{errorText}</Box>;
  };

  const listenEnter = handler => e => {
    if (e.keyCode === 13) {
      handler(e);
    }
  };

  const makeDefaultIconComp = ({ defaultValue }) => value => (
    <GradeIcon
      className={classNames(classes.iconLeft, {
        [classes.defaultCls]: defaultValue === value,
      })}
    />
  );

  const generateIconRight = () => <DeleteForeverIcon className={classes.iconRight} />;

  const handleTotalBetSelected = (totalBet, action) => {
    // Current setting is correct!
    if ((selectedValue.betSize * selectedValue.betLevel * selectedValue.baseBet) === totalBet) {
      return;
    }

    // Wrong totalBet
    if (totalBetMap[totalBet] == null) {
      return;
    }
    
    if (action === 'scroll-end') {
      setSelectedValue({
        ...selectedValue,
        ...totalBetMap[totalBet],
      });
    } else {
      const totalBetReflecting = selectedValue.betSize * selectedValue.betLevel * selectedValue.baseBet;
      setSelectedValue({
        ...selectedValue,
        totalBet: totalBetReflecting
      });
    }
  };

  useEffect(() => {
    // Get correct baseBet
    const correctBaseBet = reStructureBaseBetValue(baseBetInit);

    if (!correctBaseBet?.length) {
      return;
    }
    
    updateSettingValue.baseBet(correctBaseBet);
    setSelectedValue((prev) => ({
      ...prev,
      baseBet: correctBaseBet[0].value,
    }));
  }, [baseBetInit]);

  useEffect(() => {
    if (isEmpty(defaultSettingValue)) {
      onChange({
        defaultValue: {},
        listItem: {
          betSize: [],
          betLevel: [],
          baseBet: [],
          totalBet: [],
        },
      });
    }
  }, [defaultSettingValue]);

  useEffect(() => {
    if (Array.isArray(listItemInit.betSize)) {
      updateSettingValue.betSize(listItemInit.betSize);
    }

    if (Array.isArray(listItemInit.betLevel)) {
      updateSettingValue.betLevel(listItemInit.betLevel);
    }
  }, [listItemInit]);

  useEffect(() => {
    if (
      isEmpty(defaultValueInit)
      || !('betSize' in defaultValueInit)
      || !('betLevel' in defaultValueInit)
    ) {
      return;
    }

    setDefaultValue(defaultValueInit);
    setSelectedValue((prev) => ({
      ...prev,
      betSize: defaultValueInit.betSize,
      betLevel: defaultValueInit.betLevel,
    }));
  }, [defaultValueInit]);

  useEffect(() => {
      onChange({
        defaultValue,
        listItem: {
          betSize,
          betLevel,
          baseBet,
          totalBet,
        },
      });
    }, [defaultValue, betSize, betLevel, baseBet, totalBet]);

    console.log('@@@ selectedValue', selectedValue);

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.pickerControl}>
          <Box
            ref={pickerColumnRef}
            className={classNames({
              [classes.pickerColumnWrapper]: true,
              [classes.invalidValue]: settingTouch.betSize && errors.betSize,
            })}
          >
            <PickerColumn
              name="betSize"
              title={<TitleElement isError={settingTouch.betSize && errors.betSize}>{translation.betSize.name}</TitleElement>}
              width={PICKER_MAX_WIDTH}
              listItem={betSize}
              selectedValue={selectedValue.betSize}
              onSelected={handlePickerSelect.bind(null, 'betSize')}
              generateIconLeft={makeDefaultIconComp({
                defaultValue: defaultValue.betSize,
              })}
              iconLeftForceDisplay={[defaultValue.betSize]}
              iconLeftOnClick={handleIconLeftClick.bind(null, 'betSize')}
              generateIconRight={generateIconRight}
              iconRightForceDisplay={[]}
              iconRightOnClick={handleIconRightClick.bind(null, 'betSize')}
            />
          </Box>

          <Box className={classes.divideChar}>
            x
          </Box>

          <Box
            className={classNames({
              [classes.pickerColumnWrapper]: true,
              [classes.invalidValue]: settingTouch.betLevel && errors.betLevel,
            })}
          >
            <PickerColumn
              name="betLevel"
              title={<TitleElement isError={settingTouch.betLevel && errors.betLevel}>{translation.betLevel.name}</TitleElement>}
              width={PICKER_MAX_WIDTH}
              listItem={betLevel}
              selectedValue={selectedValue.betLevel}
              onSelected={handlePickerSelect.bind(null, 'betLevel')}
              generateIconLeft={makeDefaultIconComp({
                defaultValue: defaultValue.betLevel,
              })}
              iconLeftForceDisplay={[defaultValue.betLevel]}
              iconLeftOnClick={handleIconLeftClick.bind(null, 'betLevel')}
              generateIconRight={generateIconRight}
              iconRightOnClick={handleIconRightClick.bind(null, 'betLevel')}
              iconRightForceDisplay={[]}
            />
          </Box>

          <Box className={classes.divideChar}>
            x
          </Box>

          <Box className={classes.pickerColumnWrapper}>
            <PickerColumn
              name="baseBet"
              title={translation.baseBet}
              width={PICKER_MAX_WIDTH}
              listItem={baseBet}
              selectedValue={selectedValue.baseBet}
              onSelected={handlePickerSelect.bind(null, 'baseBet')}
            />
          </Box>

          <Box className={classes.divideChar}>
            =
          </Box>

          <Box
            className={classNames({
              [classes.pickerColumnWrapper]: true,
              [classes.invalidValue]: settingTouch.totalBet && totalBetError,
            })}
          >
            <PickerColumn
              name="totalBet"
              title={<TitleElement isError={settingTouch.totalBet && totalBetError}>{translation.totalBet}</TitleElement>}
              width={PICKER_MAX_WIDTH}
              listItem={totalBet}
              selectedValue={selectedValue.totalBet}
              onSelected={handleTotalBetSelected}
            />
          </Box>
        </Box>

        <Box className={classes.addBetControlWrapper}>
          <Box
            className={classes.addBetControl}
            style={{
              width: pickerColumnRef.current?.offsetWidth,
              minWidth: pickerColumnRef.current?.offsetWidth,
            }}
          >
            <Box className={classes.addBetControlContent}>
              <AddBetInput
                value={betSizeField}
                onChange={handleBetSizeFieldChange}
                onKeyDown={listenEnter(addBetSize)}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              <AddBetButton
                disabled={!betSizeField}
                onClick={addBetSize}
              >
                {translate('ra.action.addBetSize')}
              </AddBetButton>
            </Box>
          </Box>

          <Box
            className={classes.addBetControl}
            style={{
              width: pickerColumnRef.current?.offsetWidth,
              minWidth: pickerColumnRef.current?.offsetWidth,
            }}
          >
            <Box className={classes.addBetControlContent}>
              <AddBetInput
                value={betLevelField}
                onChange={handleBetLevelFieldChange}
                onKeyDown={listenEnter(addBetLevel)}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              <AddBetButton
                disabled={!betLevelField}
                onClick={addBetLevel}
              >
                {translate('ra.action.addBetLevel')}
              </AddBetButton>
            </Box>
          </Box>

          {typeof onResetSetting === 'function' && (
            <Box
              className={classes.addBetControl}
              style={{
                width: pickerColumnRef.current?.offsetWidth,
                minWidth: pickerColumnRef.current?.offsetWidth,
              }}
            >
              <Button
                size="small"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<RotateLeftIcon />}
                style={{
                  margin: 0,
                  height: '100%',
                }}
                onClick={onResetSetting}
              >
                {translate('ra.action.resetDefaultSetting')}
              </Button>
            </Box>
          )}

        </Box>
      </Box>

      {/* `HelpText` to Display error message */}
      <HelpText />
    </>
  );
}

BetSettingPicker.propTypes = {
  validate: PropTypes.array,
  baseBetInit: PropTypes.number,
  listItemInit: PropTypes.shape({
    betSize: PropTypes.any,
    betLevel: PropTypes.any,
  }),
  defaultValueInit: PropTypes.shape({
    betSize: PropTypes.number,
    betLevel: PropTypes.number,
  }),
  onResetSetting: PropTypes.func,
  defaultSettingValue: PropTypes.object,
};

BetSettingPicker.defaultProps = {
  validate: [],
  baseBetInit: null,
  listItemInit: {},
  defaultValueInit: {},
  onResetSetting: null,
  defaultSettingValue: {},
};

export default BetSettingPicker;
