import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ReferenceField, TextField, useListContext, useLocale } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import withComplete from './components/withComplete';
import CurrencyFilter from './components/CurrencyFilter';
import ParentBetFilter from './components/ParentBetFilter';
import NativeIdField from './components/NativeIdField';
import resourceSlug from '../../constant/resource-slug';

const useStyles = makeStyles(muiTheme => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px',
    minWidth: 150,
    background: muiTheme.palette.primary.light,
    color: muiTheme.palette.secondary.main,
    '&:hover': {
      backgroundColor: muiTheme.palette.primary.light,
    },
  },
}));

const CompleteButton = withComplete(props => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      {...props}
    />
  );
});

const PlayerStateManagementList = props => {
  const { refetch } = useListContext(props);
  const locale = useLocale();

  return (
    <Box
      sx={{
        '& td': {
          maxWidth: 'unset',
        },
      }}
    >
      <WealthListGuesser
        {...props}
        hideDeleteAction
        excludeFields={['parentBet', 'childrenBets', 'playerId', 'gameId', 'currency']}
        extraActions={(
          <CompleteButton
            onSuccess={refetch}
            {...props}
          />
        )}
        excludeFilterInputs={['currency', 'parentBet', 'childrenBets']}
        extraFilterInputs={[<ParentBetFilter />, <CurrencyFilter />]}
        defaultSort={{
          field: 'updated',
          order: 'ASC',
        }}
        fieldOrder={[
          'id',
          'updated',
          'groupId',
          'brandId',
          'gameId',
          'playerId',
          'betType',
          'remainFreeSpin',
          'status',
          'currency',
        ]}
      >
        <TextField
          source="status"
          sortable={false}
        />
        <NativeIdField
          source="playerId"
          label={`resources.${resourceSlug.PLAYER}.fields.nativeId`}
        />
        <ReferenceField
          source="currency"
          reference="currency"
          forceSorting
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="gameId"
          reference="game"
          forceSorting
        >
          <TextField source={`name.${locale}`} />
        </ReferenceField>
      </WealthListGuesser>
    </Box>
  );
};

export default PlayerStateManagementList;
