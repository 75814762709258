import { camelCase, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import BulkActionButtons from './bulk-action-buttons';
import RefManyBulkActionTypes from './refManyBulkActionTypes.constant';
import WealthListGuesser from './wealth-list.guesser';

export function RefManyEditGuesserList({
  refManyEditProps, ...listProps
}) {
  const {
    referenceResource,
    editingResource,
    editingResourceId,
    editingResourceNameAsProperty,
    refManyBulkAction,
    isManyToMany,
  } = refManyEditProps;

  // mock props to use List component
  const [mockProps, setMockProps] = useState({
    basePath: `/${referenceResource}`,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: {
      pathname: '/',
      search: '',
      hash: '',
      state: undefined,
    },
    match: {
      path: '/',
      url: '/',
      isExact: true,
      params: {},
    },
    options: {},
    permissions: null,
    resource: referenceResource,
    bulkActionButtons: (
      <BulkActionButtons
        refManyEditProps={refManyEditProps}
        refManyEditGuesser
      />
    ),
    actions: false,
    exporter: false,
    filters: <></>,
  });

  // handle set permanent filter
  useEffect(() => {
    if (isManyToMany !== undefined) {
      let permanentFilter;
      const name = [camelCase(editingResource)];
      if (refManyBulkAction === RefManyBulkActionTypes.REMOVE) {
        if (isManyToMany) {
          permanentFilter = {
            [editingResourceNameAsProperty]: {
              'id||$eq': editingResourceId,
            },
          };
        } else {
          permanentFilter = {
            [name]: {
              id: editingResourceId,
            },
          };
        }
      }

      if (refManyBulkAction === RefManyBulkActionTypes.ADD) {
        if (isManyToMany) {
          permanentFilter = {
            $or: [
              `${editingResourceNameAsProperty}.id||$isnull`,
              `${editingResourceNameAsProperty}.id||$ne||${editingResourceId}`,
            ],
          };
        } else {
          permanentFilter = {
            $or: [
              `${name}.id||$isnull`,
              `${name}.id||$ne||${editingResourceId}`,
            ],
          };
        }
      }
      setMockProps({
        ...mockProps,
        basePath: `/${referenceResource}`,
        resource: referenceResource,
        filter: permanentFilter,
      });
    }
  }, [isManyToMany, refManyEditProps.referenceResource]);

  return !isEmpty(mockProps?.filter) ? (
    <WealthListGuesser
      {...listProps}
      {...mockProps}
      basePath={`/${referenceResource}`}
      resource={referenceResource}
      listType="custom"
      optimized
      refManyEditGuesser
      quickEdit
    />
  ) : (
    <Loading />
  );
}

RefManyEditGuesserList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  refManyEditProps: PropTypes.shape({
    editingResource: PropTypes.string,
    editingResourceId: PropTypes.string,
    editingResourceNameAsProperty: PropTypes.string,
    referenceResource: PropTypes.string,
    referenceResourceNameAsProperty: PropTypes.string,
    isManyToMany: PropTypes.bool,
    refManyBulkAction: PropTypes.string,
    onToggleDrawer: PropTypes.func,
  }),
};
