import BetSettingDefaultCreate from './bet-setting-default.create';
import BetSettingDefaultShow from './bet-setting-default.show';
import BetSettingDefaultEdit from './bet-setting-default.edit';
import BetSettingDefaultList from './bet-setting-default.list';

export default {
  create: BetSettingDefaultCreate,
  show: BetSettingDefaultShow,
  edit: BetSettingDefaultEdit,
  list: BetSettingDefaultList,
};
