import React from 'react';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import WealthInputWrapper from '../../base/components/guesser/wealth-input-wrapper';
import IpsComponent from './components/ips.component';

const WhiteListCreate = props => {
  const transform = data => {
    const { ips = [] } = data;

    return {
      ...data,
      ips: ips.map(ip => ip.trim()),
    };
  };

  return (
    <WealthCreateGuesser
      {...props}
      transform={transform}
      customFields={['ips']}
    >
      <WealthInputWrapper source="ips">
        <IpsComponent {...props} />
      </WealthInputWrapper>
    </WealthCreateGuesser>
  );
};

export default WhiteListCreate;
