import React from 'react';
import CustomizedListActionWrapper from '../../../base/components/customized/CustomizedListActionWrapper';
import FilterButton from '../../../base/components/customized/common-list-action-buttons/FilterButton';

const LargeWinListAction = () => (
  <CustomizedListActionWrapper>
    <FilterButton />
  </CustomizedListActionWrapper>
);

export default LargeWinListAction;
