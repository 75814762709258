// TODO: Remove this file when apply 3-table result report
import React from 'react';
import { useTheme, Button } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Link } from 'react-router-dom';
import { getFilterParam } from '../../../services/util/stringUtil';
import { useFormContext } from '../../../base/components/guesser/report/form.context';

export default function ReportWinnersLink(props) {
  const { record } = props;
  const playerId = get(record, 'playerId', '');
  const translate = useTranslate();
  const muiTheme = useTheme();

  const { values } = useFormContext();

  const filters = {
    ...values,
    ...getFilterParam(),
  };

  if (playerId) {
    filters.playerId = playerId;
  }

  const filtersOmitted = omit(filters, ['drawId', 'consolidated']);

  return (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      style={{
        background: muiTheme.palette.primary.light,
        color: muiTheme.palette.secondary.main,
      }}
      component={Link}
      to={{
        pathname: '/report-detail-player',
        search: `filter=${JSON.stringify(filtersOmitted)}`,
      }}
    >
      {translate('ra.action.viewDetail')}
    </Button>
  );
}

ReportWinnersLink.propTypes = {
  record: PropTypes.object,
};

ReportWinnersLink.defaultProps = {
  record: {},
};
