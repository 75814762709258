/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import {
  useCreateController,
  useRedirect,
  Create as RACreate,
  useTranslate,
  useNotify,
  getResources,
} from 'react-admin';
import { useSelector } from 'react-redux';
import Type from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { useError } from '../../../hooks';
import { WealthToolbar } from '../../guesser/wealth-toolbar';
import Actions from './action-toolbar';
import useBulk from '../../../hooks/useBulk';

const Create = props => {
  const {
    children,
    redirect: redirectPath,
    disabledBtnSave, // eslint-disable-line react/prop-types
    alwaysEnableSaveButton,
    forceCustomSave,
    onCustomSave,
    resource,
    transform,
    writableFields,
    confirmDialogInfo,
    translatedResourceName: defaultTranslatedResourceName,
    ...rest
  } = props;

  const resources = useSelector(getResources);
  const { options: { isAcl } } = resources?.filter(r => r.name === resource)?.[0];

  const { basePath } = useCreateController(props);

  const {
    isClonable, getTotalClonedRecords,
  } = useBulk(writableFields);

  const redirect = useRedirect();
  const { notifyError } = useError();
  const translate = useTranslate();
  const notify = useNotify();

  const translatedResourceName = defaultTranslatedResourceName || translate(`resources.${resource}.name`);
  const successMessage = translate('ra.notification.created', {
    smart_name: translatedResourceName,
  });

  const onSuccess = ({ data }) => {
    notify(successMessage);
    redirect(redirectPath, basePath, data.id, data);
  };

  const onFailure = error => {
    notifyError(error);
  };

  useEffect(() => {
    if (!isClonable() || !Array.isArray(children?.props?.children)) return;

    // add bulk clone progress message
    if (isClonable()) {
      const {
        count, total,
      } = getTotalClonedRecords();
      children.props.children // data form as <SimpleForm />
        .push(
          <Box
            display="block"
            fullWidth
            mb={4}
          >
            <Typography
              variant="inherit"
              color="primary"
            >
              {`${count}/${total}`}
              {' '}
              records were cloned.
            </Typography>
          </Box>,
        );
    }
  }, [children?.props?.children]);

  return (
    <RACreate
      {...rest}
      onSuccess={onSuccess}
      onFailure={onFailure}
      resource={resource}
    >
      {React.cloneElement(children, {
        toolbar: (
          <WealthToolbar
            disabledBtnSave={disabledBtnSave}
            forceCustomSave={forceCustomSave}
            onCustomSave={onCustomSave}
            onAfterSave={rest.onAfterSave}
            alwaysEnableSaveButton={alwaysEnableSaveButton}
            isAcl={isAcl}
            transform={transform}
            confirmDialogInfo={confirmDialogInfo}
            translatedResourceName={translatedResourceName}
          />
        ),
      })}
    </RACreate>
  );
};

Create.propTypes = {
  writableFields: Type.arrayOf(Type.string),
  children: Type.oneOfType([Type.element, Type.arrayOf(Type.element)]).isRequired,
  actions: Type.oneOfType([Type.element, Type.arrayOf(Type.element)]),
  redirect: Type.string, // "edit"|"show"|"list"|<custom route>
  forceCustomSave: Type.bool,
  onCustomSave: Type.func,
  alwaysEnableSaveButton: Type.bool,
  resource: Type.string,
  transform: Type.func,
  translatedResourceName: Type.string,
  confirmDialogInfo: Type.shape({
    title: Type.string,
    content: Type.string,
  }),
};

Create.defaultProps = {
  actions: <Actions />,
  redirect: 'show',
  forceCustomSave: false,
  onCustomSave: undefined,
  alwaysEnableSaveButton: undefined,
  resource: '',
  transform: f => f,
  writableFields: [],
  translatedResourceName: '',
  confirmDialogInfo: null,
};

export default Create;
