import React from 'react';
import PropTypes from 'prop-types';
import { useLocale, TextField } from 'react-admin';
import { get, set } from 'lodash';
import { formatNumber } from '../../../../services/util/formatNumber';

const CurrencyField = props => {
  const {
    record, source, currency, ...rest
  } = props;

  const locale = useLocale();

  if (!get(record, source)) {
    set(record, source, 0);
  }

  const currencyCode = currency?.code || record.currency?.code;

  if (currencyCode) {
    const tmpRecord = {
      ...record,
    };

    const tmpValue = formatNumber(
      locale,
      get(record, source) || 0,
      currencyCode,
      {
        min: 1,
        max: 5,
      },
      currency?.prefix || record.currency?.prefix,
    );

    set(tmpRecord, source, tmpValue);

    return (
      <TextField
        {...rest}
        record={tmpRecord}
        source={source}
      />
    );
  }

  return (
    <TextField
      {...rest}
      record={record}
      source={source}
    />
  );
};

CurrencyField.propTypes = {
  record: PropTypes.object,
  currency: PropTypes.string,
  source: PropTypes.string,
  addLabel: PropTypes.bool,
};

CurrencyField.defaultProps = {
  record: {},
  currency: undefined,
  addLabel: true,
  source: '',
};

export default CurrencyField;
