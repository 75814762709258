/* eslint-disable react/prop-types */
import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import DownloadAction from './components/download-action';

function ExportHistoryShow(props) {
  return (
    <WealthShowGuesser
      {...props}
      actionMenu={<DownloadAction type="toolbar" />}
    />
  );
}

export default ExportHistoryShow;
