import { addExportListener } from './exporters.util';

const reportRTPExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;

  const currency = await fetchRelatedRecords(records, 'currency', 'currency');
  const game = await fetchRelatedRecords(records, 'gameId', 'game');

  const rptForExport = records.map(data => {
    const result = {
      id: data.id,
      gameId: game[data.gameId]?.gameId,
      gameName: data.gameName,
      gameType: data.gameType,
      betCount: data.betCount?.toString?.()?.replace?.('.', ','),
      playerCount: data.playerCount,
      currency: currency[data.currency]?.code,
      channel: data.channel,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
      wonAmount: data.wonAmount?.toString?.()?.replace?.('.', ','),
      rtp: data.rtp?.toString?.()?.replace?.('.', ','),
    };

    return result;
  });

  addExportListener({
    dataList: rptForExport,
    dateString: date,
    fileName: 'RTP Report',
  });
};

export default reportRTPExporter;
