/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import ResetPasswordButton from './components/resetPassword.button';
import ForceChangePasswordButton from './components/forceChangePassword.button';
import { useAuthUser } from '../../base/hooks';

function ExtraActions(props) {
  const {
    record, userId, role,
  } = props;

  if (
    !userId
    || !record?.id
    || userId === record.id
    || record?.role?.level >= role.level
  ) {
    return null;
  }

  return (
    <>
      <ResetPasswordButton {...props} />
      <ForceChangePasswordButton {...props} />
    </>
  );
}

ExtraActions.propTypes = {
  record: PropTypes.object,
  userId: PropTypes.string,
};

ExtraActions.defaultProps = {
  record: null,
  userId: '',
};

function UserList(props) {
  const {
    id: userId = '', role = {},
  } = useAuthUser();

  return (
    <>
      <WealthListGuesser
        {...props}
        extraActions={(
          <ExtraActions
            userId={userId}
            role={role}
          />
        )}
      />
    </>
  );
}

export default UserList;
