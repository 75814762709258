import { useNotify, useTranslate } from 'react-admin';
import { get } from 'lodash';

export const standardizeMessageArgs = (args, translate) => {
  const realArgs = {};
  Object.entries(args).forEach(([key, value]) => {
    const realValue = typeof value !== 'string' ? String(value) : value;
    const msgTranslated = translate(realValue);
    if (msgTranslated !== realValue) {
      realArgs[key] = msgTranslated;
      return;
    }
    realArgs[key] = value;
  });

  return realArgs;
};

const useError = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const showError = msg => notify(msg, 'error');

  const additionHandlingErrorMessage = ({
    message, args,
  }) => {
    // if (typeof message === 'string') {
    //   showError(translate(message));
    //   return;
    // }

    if (Array.isArray(message)) {
      message.forEach(msg => {
        let finalMessage = msg;
        const messageTranslated = translate(msg);

        if (messageTranslated !== msg) {
          finalMessage = messageTranslated;
        }

        showError(finalMessage);
      });
      return;
    }

    const argsValid = args && Object.keys(args).length > 0;
    if (argsValid) {
      const realArgs = standardizeMessageArgs(args, translate);
      const messageTranslated = translate(message, realArgs);
      showError(messageTranslated);
      return;
    }

    let errorTranslated = translate('wa.common.error');
    errorTranslated = errorTranslated === 'wa.common.error' ? 'Error' : errorTranslated;

    // EX: "Error! notify something..."
    showError(`${errorTranslated}! ${translate(message)}`);
  };

  const notifyError = error => {
    const messageKey = get(error, 'response.data.key');
    if (typeof messageKey === 'string' && messageKey.indexOf('.') !== -1) {
      showError(translate(messageKey));
      return;
    }

    if (get(error, 'response.data.message')) {
      additionHandlingErrorMessage(error.response.data);
      return;
    }

    if (get(error, 'body.message')) {
      additionHandlingErrorMessage(error.body);
      return;
    }

    if (get(error, 'body.key')) {
      additionHandlingErrorMessage({
        message: error.body.key,
        args: [],
      });
      return;
    }

    if (Array.isArray(error?.message)) {
      error.message.forEach(msg => showError(msg));
      return;
    }

    if (typeof error?.message === 'string') {
      showError(translate(error.message));
      return;
    }

    console.debug('[ERROR NOTIFICATION] error.message', error.message);
    showError(translate('ra.message.tryAgain'));
  };

  return {
    notifyError,
  };
};

export default useError;
