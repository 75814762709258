/* eslint-disable import/no-named-as-default,no-shadow,react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import {
  makeStyles,
  Box,
  Button,
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Typography,
  useTheme,
} from '@material-ui/core';
import { email, required, SimpleForm, FormDataConsumer, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import axios from 'axios';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { TextInput, BooleanInput } from '../../../base/components/ra/inputs';
import { useError, useLoading } from '../../../base/hooks';
import QuickSetupStepper from '../quick-setup-components/QuickSetupStepper';
import QuickSetupPreview from '../quick-setup-components/QuickSetupPreview';
import {
  getFormDataFromStorage,
  removeFormDataToStorage,
  transformErrorResponseToObject,
  transformNewGroupQuickSetupPayload,
  saveFormDataToStorage,
} from '../quick-setup-components/utils';
import {
  QuickSetupErrorHandlingConsumer,
  QuickSetupFieldValidationWrap,
  useQuickSetupErrorHandling,
} from '../quick-setup-components/utils/QuickSetupErrorHandling';
import CreatedConfirmation from '../quick-setup-components/CreatedConfirmation';
import MultiBrandForm from '../quick-setup-components/forms/MultiBrandForm';
import { QUICK_SETUP_NEW_GROUP } from '../../../services/local-storage';
import {
  FieldName,
  FieldValue,
  FieldWrapper,
  Title,
  fieldBgColor,
} from '../quick-setup-components/QuickSetupPreview/HelperComponents';
import DetectStorageVersionDialog from '../quick-setup-components/DetectStorageVersionDialog';
import { FORM_INIT_VERSION } from '../quick-setup-components/constants';
import SlickContent from '../../../base/components/simple-slick/SlickContent';
import CurrenciesReference from '../../../base/components/reference-fields/currencies';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .simple-form > div': {
      padding: 0,
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(translate) {
  const stepsRawText = ['ra.text.createNewGroup', 'ra.text.createNewBrands', 'ra.text.preview'];

  if (typeof translate === 'function') {
    return stepsRawText.map(text => translate(text));
  }

  return stepsRawText;
}

const GroupForm = () => {
  const form = useForm();
  const translate = useTranslate();
  const muiTheme = useTheme();

  useEffect(() => {
    const formDataStorage = getFormDataFromStorage(QUICK_SETUP_NEW_GROUP);
    if (isEmpty(formDataStorage)) {
      return;
    }
    form.initialize(formDataStorage);
  }, []);

  return (
    <Card>
      <CardContent>
        <TextInput
          fullWidth
          label="resources.group.fields.name"
          source="group.name"
          validate={required()}
        />
        <TextInput
          fullWidth
          label="resources.group.fields.managerEmail"
          source="group.managerEmail"
          validate={[required(), email('ra.validation.email')]}
        />
        <CurrenciesReference
          source="group.currencies"
          validate={[required()]}
        />
        <TextInput
          fullWidth
          multiline
          label="resources.prepaid.fields.desc"
          source="group.desc"
          minRows={5}
        />
        <Box
          style={{
            display: 'flex',
            gap: '12px',
          }}
        >
          <BooleanInput
            label="resources.group.fields.enabled"
            source="group.enabled"
            validate={required()}
          />
          <Typography
            style={{
              marginTop: '8px',
              fontStyle: 'italic',
              color: muiTheme.palette.primary.light,
            }}
          >
            {translate('ra.text.groupEnabledNotice')}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const StepContentWrapper = ({ children }) => <Box padding="4px">{children}</Box>;

const QuickSetupNewGroup = () => {
  const translate = useTranslate();
  const steps = getSteps(translate);
  const appLoading = useSelector(({ app }) => app.isLoading);
  const { toggleLoading } = useLoading();
  const notify = useNotify();
  const { notifyError } = useError();
  const { setQuickSetupError } = useQuickSetupErrorHandling();
  const muiTheme = useTheme();

  const [createdDataRes, setCreatedDataRes] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [readyToLoadForm, setReadyToLoadForm] = useState(false);
  const timeoutSaveStorage = useRef();

  const classes = useStyles();

  const handleSaveClick = async values => {
    const payload = transformNewGroupQuickSetupPayload(values);

    try {
      toggleLoading(true);
      const { data } = await axios.post('api/quick-setup', payload);
      setCreatedDataRes(data);
      removeFormDataToStorage(QUICK_SETUP_NEW_GROUP);
    } catch (err) {
      console.debug('[ERROR] when requesting to create new group quick setup\n', err.message);

      const errorMessageData = get(err, 'response.data.message');
      if (!Array.isArray(errorMessageData)) {
        notifyError(err);
        return;
      }

      notify('We have some issues with request data. Please check it again.', {
        type: 'error',
        autoHideDuration: 3000,
      });
      const errorDataObj = transformErrorResponseToObject(errorMessageData);
      setQuickSetupError(errorDataObj);
    } finally {
      toggleLoading(false);
    }
  };

  const handleFormInitVersion = version => {
    if (version === FORM_INIT_VERSION.NEW_FORM) {
      removeFormDataToStorage(QUICK_SETUP_NEW_GROUP);
    }
    setReadyToLoadForm(true);
  };

  const brandNamesCannotDuplicate = values => {
    const errors = {};
    const counterMap = new Map();

    get(values, 'brands', []).forEach((bItem, bIndex) => {
      if (counterMap.get(bItem.name)) {
        if (!Array.isArray(errors.brands)) {
          errors.brands = [];
        }
        const brandIndexesDuplicate = counterMap.get(bItem.name);
        [...brandIndexesDuplicate, bIndex].forEach(idx => {
          errors.brands[idx] = {
            name: 'Duplicate Name',
          };
        });
      } else {
        counterMap.set(bItem.name, [bIndex]);
      }
    });

    return errors;
  };

  return (
    <div className={classes.root}>
      {readyToLoadForm && (
        <>
          <Box padding="4px">
            <MuiStepper
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </MuiStepper>
          </Box>

          <SimpleForm
            initialValues={{}}
            toolbar={null}
            validate={brandNamesCannotDuplicate}
          >
            <FormDataConsumer>
              {({ formData }) => {
                const form = useForm();

                useEffect(() => {
                  timeoutSaveStorage.current = setInterval(() => {
                    saveFormDataToStorage(form.getState().values, QUICK_SETUP_NEW_GROUP);
                  }, 3000);

                  return function cleanUp() {
                    clearInterval(timeoutSaveStorage.current);
                  };
                }, []);
                return (
                  <QuickSetupStepper
                    storageKey={QUICK_SETUP_NEW_GROUP}
                    numOfStep={3}
                    onStepperChange={index => {
                      setActiveStep(index);
                    }}
                    buttonLastStep={(
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginLeft: 'auto',
                        }}
                        disabled={appLoading}
                        onClick={e => {
                          e.stopPropagation();
                          handleSaveClick(formData);
                          clearInterval(timeoutSaveStorage.current);
                        }}
                      >
                        {translate('ra.action.save')}
                      </Button>
                    )}
                  >
                    <SlickContent name="group-form-step">
                      <StepContentWrapper>
                        <GroupForm />
                      </StepContentWrapper>
                    </SlickContent>

                    <SlickContent name="multi-brand-form-step">
                      <StepContentWrapper>
                        <MultiBrandForm storageKey={QUICK_SETUP_NEW_GROUP} />
                      </StepContentWrapper>
                    </SlickContent>

                    <SlickContent name="preview-step">
                      <StepContentWrapper>
                        <FormDataConsumer>
                          {({ formData }) => {
                            const groupData = formData?.group || {};
                            const groupInformation = {
                              name: groupData.name,
                              managerEmail: groupData.managerEmail,
                              desc: groupData.desc,
                              enabled: groupData.enabled,
                            };
                            return (
                              <QuickSetupPreview
                                groupInfo={(
                                  <>
                                    <Title>{translate('ra.text.groupInformation')}</Title>
                                    <Box
                                      style={{
                                        gap: '20px',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        padding: '12px',
                                        paddingBottom: '16px',
                                      }}
                                    >
                                      {keys(groupInformation).map(gKeyItem => (
                                        <QuickSetupFieldValidationWrap
                                          key={gKeyItem}
                                          name={`group.${gKeyItem}`}
                                        >
                                          <QuickSetupErrorHandlingConsumer>
                                            {({
                                              quickSetupError, name,
                                            }) => {
                                              const groupError = get(quickSetupError, name);
                                              const fieldNameKey = `resources.group.fields.${gKeyItem}`;
                                              return (
                                                <FieldWrapper
                                                  key={gKeyItem}
                                                  borderColor={groupError ? muiTheme.palette.error.main : fieldBgColor}
                                                  error={groupError}
                                                >
                                                  <FieldName
                                                    color={groupError ? muiTheme.palette.error.main : undefined}
                                                  >
                                                    {translate(fieldNameKey)}
                                                  </FieldName>
                                                  <FieldValue
                                                    color={groupError ? muiTheme.palette.error.main : undefined}
                                                  >
                                                    {get(groupData, gKeyItem, '')?.toString()}
                                                  </FieldValue>
                                                </FieldWrapper>
                                              );
                                            }}
                                          </QuickSetupErrorHandlingConsumer>
                                        </QuickSetupFieldValidationWrap>
                                      ))}
                                    </Box>
                                  </>
                                )}
                              />
                            );
                          }}
                        </FormDataConsumer>
                      </StepContentWrapper>
                    </SlickContent>
                  </QuickSetupStepper>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </>
      )}

      <DetectStorageVersionDialog
        storageKey={QUICK_SETUP_NEW_GROUP}
        setFormInitVersion={handleFormInitVersion}
      />

      {createdDataRes && (
        <CreatedConfirmation
          data={createdDataRes}
          title={translate('ra.text.newGroupCreateSuccess')}
        />
      )}
    </div>
  );
};

export default QuickSetupNewGroup;
