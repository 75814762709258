/* eslint-disable */
import React, { useState } from 'react';
import { useTranslate, FormDataConsumer, required, minValue } from 'ra-core';
import { SimpleForm, AutocompleteInput } from 'react-admin';
import {
  Box,
  TextField,
  Chip,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Link,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, useFormState } from 'react-final-form';

const GROUP_GAP = '30px';
const GROUP_HEAD_BG = '#4b5251';

const groupStyle = {
  gapSize: '30px',
  tableHeadBg: '#222222',
  tableBodyBg: '#6e6e6e',
  tableBodyColor: '#ffffff',
  labelColor: '#a0a0a0',
  bulletIconColor: '',
  nameColor: '#ffffff',
};

const brandStyle = {
  gapSize: '30px',
  tableHeadBg: '#ebebeb',
  tableBodyBg: '#ffffff',
  tableBodyColor: '#ffffff',
  labelColor: '',
  bulletIconColor: '',
  nameColor: '',
};

const useStyles = makeStyles(theme => ({
  groupAccordion: {
    '&&': {
      borderRadius: '4px',
      overflow: 'hidden',
      marginBottom: '16px',
    },
  },
  brandAccordion: {
    '&&': {
      borderRadius: '4px',
      overflow: 'hidden',
    },

    '& .Mui-expanded': {
      minHeight: '48px !important',
      transition: 'none',
      margin: 0,
    },
  },
  brandAccordionSummary_root: {
    background: `${brandStyle.tableHeadBg} !important`,
    transition: 'none',
  },
  groupAccordionDetails_root: {
    '&&': {
      padding: '24px',
      // background: alpha(theme.palette.primary.light, 0.1),
      background: groupStyle.tableBodyBg,
    },
  },
  playerListTitle: {
    padding: '0 12px',
    margin: '20px 0px 4px',
    display: 'flex',
    alignItems: 'center',

    '&:after': {
      content: '""',
      flex: 1,
      height: '1px',
      background: '#ebebeb',
    },
  },
}));

const ParentNoChild = props => {
  const {
    title, message, bodyColor, headColor, tableBodyColor,
  } = props;

  return (
    <Box
      border="1px solid #d3d3d3"
      borderRadius="4px"
      overflow="hidden"
      marginBottom="16px"
    >
      <Box
        minHeight="48px"
        display="flex"
        alignItems="center"
        padding="8px"
        style={{
          background: headColor || '#ebebeb',
        }}
      >
        {title}
      </Box>
      <Box
        minHeight="60px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="16px"
        color={tableBodyColor || '#626262'}
        fontWeight={600}
        style={{
          background: bodyColor || '#ffffff',
        }}
      >
        {message}
      </Box>
    </Box>
  );
};

const Title = ({
  label,
  name,
  style,
  isReference,
  href,
}) => (
  <Box
    component="span"
    display="flex"
    alignItems="center"
    gridGap="8px"
    fontSize="16px"
  >
    <span
      style={{
        fontWeight: 600,
        color: style?.labelColor || '#888888',
      }}
    >
      {label}
    </span>
    <span
      style={{
        color: style?.bulletColor || '#d3d3d3',
      }}
    >
      ➤
    </span>
    <span
      style={{
        fontWeight: 600,
        color: style?.nameColor,
      }}
    >

    {isReference && href
      ? (
      <Link href={href} style={{ textDecoration: 'none', color: style?.nameColor }}>{name}</Link>
      )
      : name}
      
    </span>
  </Box>
);

const PlayerItems = props => {
  const {
    data: playersData, playerMapIdData,
  } = props;

  return playersData ? (
    <Box padding="8px 12px 18px">
      {playersData.map((pItem, pIndex) => {
        const nativeId = pItem?.nativeId || playerMapIdData[pItem.id]?.nativeId;
        if (!nativeId) {
          return null;
        }

        return (
          <Chip
            key={pItem.id}
            label={nativeId}
            variant="outlined"
            style={{
              margin: '4px',
              padding: '0 4px',
            }}
          />
        );
      })}
    </Box>
  ) : null;
};

const BrandItems = props => {
  const translate = useTranslate();

  const {
    data: brandsData, brandMapIdData, playerMapIdData, classes,
  } = props;

  return brandsData ? (
    <Box
      width="100%"
      borderRadius="4px"
    >
      {brandsData.map((bItem, bIndex) => {
        let brandName = bItem?.name || brandMapIdData[bItem.id]?.name;
        if (!brandName) {
          return null;
        }

        brandName = (
          <Title
            label={translate('resources.brand.name')}
            name={brandName}
            style={{
              labelColor: brandStyle.labelColor,
              bulletColor: brandStyle.bulletIconColor,
              nameColor: brandStyle.nameColor,
            }}
            isReference={!!bItem.id}
            href={bItem.id ? `/#/brand/${bItem.id}/show` : ''}
          />
        );

        return (bItem?.players?.length > 0 ? (
          <Accordion
            key={bItem.id}
            className={classes.brandAccordion}
            expanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                root: classes.brandAccordionSummary_root,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                {brandName}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <Box className={classes.playerListTitle}>
                  <Box
                    color="#878787"
                    marginRight="8px"
                  >
                    {translate('ra.text.playerList')}
                  </Box>
                </Box>
                <PlayerItems
                  data={bItem.players}
                  playerMapIdData={playerMapIdData}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <ParentNoChild
            key={bItem.id}
            title={brandName}
            message={translate('ra.text.inboxRuleNoPlayerSelected')}
          />
        ));
      })}
    </Box>
  ) : null;
};

const DynamicGroupList = props => {
  const {
    data: groupData,
    groupMapIdData,
    brandMapIdData,
    playerMapIdData,
    name,
  } = props;

  const classes = useStyles();
  const muiTheme = useTheme();
  const translate = useTranslate();

  let groupName = groupData.name || groupMapIdData[groupData.id]?.name;
  if (!groupName) {
    return null;
  }

  groupName = (
    <Title
      label={translate('resources.group.name')}
      name={groupName}
      style={{
        labelColor: groupStyle.labelColor,
        bulletColor: groupStyle.bulletIconColor,
        nameColor: groupStyle.nameColor,
      }}
      isReference={!!groupData.id}
      href={groupData.id ? `/#/group/${groupData.id}/show` : ''}
    />
  );

  return (
    <Box
      width="100%"
      marginBottom={GROUP_GAP}
    >
      {groupData?.brands?.length > 0 ? (
        <Accordion
          expanded
          className={classes.groupAccordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              background: GROUP_HEAD_BG,
            }}
          >
            <Typography>{groupName}</Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.groupAccordionDetails_root,
            }}
          >
            <BrandItems
              data={groupData.brands}
              brandMapIdData={brandMapIdData}
              playerMapIdData={playerMapIdData}
              classes={classes}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <ParentNoChild
          title={(
            <Box
              display="flex"
              alignItems="center"
              minHeight="48px"
            >
              {groupName}
            </Box>
          )}
          message={translate('ra.text.inboxRuleNoBrandSelected')}
          headColor={groupStyle.tableHeadBg}
          bodyColor={groupStyle.tableBodyBg}
          tableBodyColor={groupStyle.tableBodyColor}
        />
      )}
    </Box>
  );
};

export default DynamicGroupList;
