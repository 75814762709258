/* eslint-disable no-use-before-define,import/no-cycle, react/no-array-index-key */
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  Bar,
  Label,
  Legend,
  LabelList,
} from 'recharts';
import { useGGRContext } from '.';
import { formatNumber, measureTextWidth, GGR_BAR_GAP, GGR_BAR_SIZE, getChartHeight, getCustomDomain } from './utils';
import { CURRENCY_TYPES } from '../../../../constant';
import {
  GGR_LABEL_FONT_SIZE,
  GGR_AXIS_FONT_WEIGHT,
  GGR_AXIS_FONT_SIZE,
  GGR_AXIS_LABEL_FONT_SIZE,
  GGR_LABEL_OFFSET,
  FIAT_X_AXIS_FIELD_NAME,
} from './ggr-fiat-bar-chart';

export const CRYPTO_X_AXIS_FIELD_NAME = 'GGR';
const CRYPTO_Y_AXIS_FIELD_NAME = 'currencyCode';

const GGRCryptoBarChart = () => {
  const theme = useTheme();
  const { data: rawData } = useGGRContext();
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  const data = useMemo(
    () => rawData?.cryptoData?.data?.filter(item => typeof item[CRYPTO_X_AXIS_FIELD_NAME] === 'number'),
    [rawData],
  );

  if (!Array.isArray(data)) return null;

  const totalFiatCurrency = rawData?.fiatData?.data?.filter(item => typeof item[FIAT_X_AXIS_FIELD_NAME] === 'number')?.length || 0;

  const {
    axisLabelFontSize, axisFontSize, legendHeight,
  } = useMemo(
    () => ({
      axisLabelFontSize: isSmall ? GGR_AXIS_LABEL_FONT_SIZE / 2 : GGR_AXIS_LABEL_FONT_SIZE,
      axisFontSize: isSmall ? GGR_AXIS_FONT_SIZE / 1.5 : GGR_AXIS_FONT_SIZE,
      legendHeight: 80,
    }),
    [isSmall],
  );

  return (
    <Box
      flexGrow={1}
      ml={-2}
    >
      <ResponsiveContainer
        width="100%"
        height={
          isSmall
            ? getChartHeight(data.length)
            : Math.max(getChartHeight(data.length), getChartHeight(totalFiatCurrency))
        } // To balance the height of 2 charts on desktop
      >
        <BarChart
          data={data}
          layout="vertical"
          barSize={GGR_BAR_SIZE}
          maxBarSize={GGR_BAR_SIZE}
          barGap={GGR_BAR_GAP}
          margin={{
            bottom: isSmall ? 10 : 30, // Add margin to fix the bug that X axis label is not displayed fully in recharts
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            type="category"
            dataKey={CRYPTO_Y_AXIS_FIELD_NAME}
            fontWeight={GGR_AXIS_FONT_WEIGHT}
            fontSize={axisFontSize}
            fill="#000"
          />
          <XAxis
            type="number"
            dataKey={CRYPTO_X_AXIS_FIELD_NAME}
            fontWeight={GGR_AXIS_FONT_WEIGHT}
            fontSize={axisFontSize}
            fill="#000"
            allowDataOverflow={false}
            allowDecimals
            tickFormatter={value => formatNumber(value)}
            ticks={data.length === 0 ? [0] : undefined} // To display xAxis if the chart is empty
            domain={getCustomDomain(data, CRYPTO_X_AXIS_FIELD_NAME)} // To fix BarChart with only a negative/positive value not rendering correctly
          >
            <Label
              value={CURRENCY_TYPES.crypto}
              position="bottom"
              offset={isSmall ? -axisLabelFontSize / 2 : axisLabelFontSize / 2}
              fontSize={axisLabelFontSize}
              fill={theme.palette.primary.light}
            />
          </XAxis>
          <ReferenceLine
            x={0}
            stroke="#000"
          />
          <Bar
            dataKey={CRYPTO_X_AXIS_FIELD_NAME}
            fill={theme.palette.primary.light}
          >
            <LabelList
              dataKey={CRYPTO_X_AXIS_FIELD_NAME}
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="right"
            height={legendHeight}
            content={renderLegend}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const renderCustomizedLabel = ({
  x, y, width: barWidth, height: barHeight, value,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const fontSize = isSmall ? GGR_LABEL_FONT_SIZE / 1.5 : GGR_LABEL_FONT_SIZE;

  const formattedNumber = formatNumber(value);
  const valueEstimatedWidth = measureTextWidth(formattedNumber, fontSize);
  const isOverflow = valueEstimatedWidth + GGR_LABEL_OFFSET >= Math.abs(barWidth);
  let offset = isOverflow ? GGR_LABEL_OFFSET : -valueEstimatedWidth - GGR_LABEL_OFFSET;
  const isNegativeValue = value < 0;

  if (isNegativeValue) offset *= -1;

  return (
    <text
      x={x + barWidth + offset}
      y={y + barHeight / 2 + fontSize / 2}
      fill={isOverflow ? theme.palette.primary.main : '#fff'}
      textAnchor={isNegativeValue ? 'end' : 'start'}
      fontSize={fontSize}
      fontWeight={600}
    >
      {formattedNumber}
    </text>
  );
};

const renderLegend = ({ payload }) => {
  const translate = useTranslate();

  return (
    <Box
      ml={3}
      my={{
        xs: 4,
        lg: 2,
      }}
    >
      {payload.map((item, index) => (
        <Box
          key={`item-${index}`}
          fontSize={16}
          display="flex"
          alignItems="center"
          mb={{
            xs: 1,
            md: 2,
          }}
        >
          <Box
            borderRadius={4}
            width={{
              xs: 16,
              lg: 22,
            }}
            height={{
              xs: 16,
              lg: 22,
            }}
            sx={{
              backgroundColor: item.color,
            }}
            mr={1.5}
          />
          <Box>{translate('resources.dashboard.fields.cryptoCurrency')}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default GGRCryptoBarChart;
