import React, { useEffect } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { Button } from '@material-ui/core';
import CustomTooltip from '../../custom-tooltip';
import { useStyle } from '../../guesser/actions/list.action';
import { useFilterFormContext } from '../../../context/form/filterForm.context';

const FilterButton = ({
  label, ...otherProps
}) => {
  const translate = useTranslate();
  const classes = useStyle();

  const { filterValues } = useListContext();

  const {
    setFilterDisplayed, filterDisplayed,
  } = useFilterFormContext();

  const defaultLabel = filterDisplayed ? translate('ra.action.hideFilter') : translate('ra.action.showFilter');

  const filterLabel = label || defaultLabel;

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      setFilterDisplayed(true);
    }
  }, []);

  return (
    <CustomTooltip
      title={filterLabel}
      placement="top"
    >
      <Button
        color="primary"
        variant="text"
        size="small"
        className={classes.showFilterBtn}
        startIcon={filterDisplayed ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => setFilterDisplayed(!filterDisplayed)}
        {...otherProps}
      >
        {filterLabel}
      </Button>
    </CustomTooltip>
  );
};

export default FilterButton;

FilterButton.propTypes = {
  label: PropTypes.string,
};

FilterButton.defaultProps = {
  label: '',
};
