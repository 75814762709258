import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import configureStore from "./redux/store";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import Layout from "../base/components/layout";
import { LanguageContextProvider } from "../base/context/language";
import { TitleContextProvider } from "../base/context/title";
import { ReloadContextProvider } from "../base/context/reload/reload.context";

export const store = configureStore();

const App = (props) => (
  <AppContextProvider>
    <TitleContextProvider>
      <ReloadContextProvider>
        <LanguageContextProvider>
          {() => {
            return (
              <AppWrapper>
                <Layout {...props} />
              </AppWrapper>
            );
          }}
        </LanguageContextProvider>
      </ReloadContextProvider>
    </TitleContextProvider>
  </AppContextProvider>
);

export default App;
