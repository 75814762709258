/* eslint-disable  */
import { required } from "ra-core";
import { TextInput } from "ra-ui-materialui";
import React, { useEffect, useState, memo } from "react";
import { SelectInput } from "../../../base/components/ra/inputs";
import { useAuthUser, useEnumOptions } from "../../../base/hooks";
import { useForm, useFormState } from "react-final-form";
import { GROUP_ADMIN_LEVEL, SYSTEM_ADMIN_LEVEL } from "../../../constant";
import { RoleAccessibility, RoleType } from "../../../services/util";

export const RoleLevelInput = memo(
  ({ resource, source, isEdit, record, disabled, user }) => {
    const enumRoleLevel = Array.from(Array(20)).map((i, index) => ({
      id: (index + 1).toString(),
      name: (index + 1).toString(),
    }));

    const [roleLevel, setRoleLevel] = useState([]);

    const form = useForm();
    const { values } = useFormState();

    useEffect(() => {
      const isTypeGroup = values.type === RoleType.GROUP;

      const tmpRoleLevel = (enumRoleLevel || [])
        .filter((level) =>
          record?.level === SYSTEM_ADMIN_LEVEL
            ? level.id
            : Number(level.id) < SYSTEM_ADMIN_LEVEL
        )
        .filter((level) => {
          const ltLevel = 11;
          return isTypeGroup
            ? Number(level.id) < ltLevel
            : Number(level.id) > GROUP_ADMIN_LEVEL;
        })
        .filter((level) => Number(level.id) < Number(user?.role?.level));

      setRoleLevel(tmpRoleLevel);
    }, [values.type, user]);

    useEffect(() => {
      if (isEdit) {
        if (record.type === values.type) {
          return form.change("level", record.level);
        }
        return form.change("level", values.type === RoleType.GROUP ? 1 : 11);
      }

      return form.change("level", values.type === RoleType.GROUP ? 1 : 11);
    }, [values.type, isEdit]);

    return (
      <SelectInput
        fullWidth
        label="resources.role.fields.level"
        source="level"
        choices={roleLevel}
        defaultValue={roleLevel[0]?.id}
        validate={required()}
        disabled={disabled}
      />
    );
  }
);

export const RoleTypeInput = memo(
  ({ resource, source, isEdit, disabled, user }) => {
    const enumRoleType = useEnumOptions(resource, source);
    const [roleType, setRoleType] = useState([]);

    const form = useForm();
    const { values } = useFormState();

    useEffect(() => {
      const tmpRoleType = (enumRoleType || [])
        .filter((type) =>
          values.accessibility === RoleAccessibility.CUSTOM_ROLE
            ? type.id !== RoleType.SYSTEM
            : type.id
        )
        .filter((type) =>
          user?.role?.type === RoleType.SYSTEM
            ? type.id
            : type.id !== RoleType.SYSTEM
        );

      setRoleType(tmpRoleType);
    }, [user?.role?.type, enumRoleType, values.accessibility]);

    useEffect(() => {
      if (
        values.accessibility === RoleAccessibility.CUSTOM_ROLE &&
        values.type === RoleType.SYSTEM
      ) {
        form.change("type", RoleType.GROUP);
      }
    }, [values.accessibility]);

    return (
      <SelectInput
        fullWidth
        label="resources.role.fields.type"
        source="type"
        choices={roleType}
        defaultValue={roleType?.[0]?.id}
        validate={required()}
        disabled={disabled}
      />
    );
  }
);
