import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ReloadContext = React.createContext(null);

export const useReloadContext = () => useContext(ReloadContext);

export const ReloadContextProvider = ({ children }) => {
  const [valuesContext, setValuesContext] = useState({});

  return (
    <ReloadContext.Provider
      value={{
        setValuesContext,
        valuesContext,
      }}
    >
      {typeof children === 'function' ? children() : children}
    </ReloadContext.Provider>
  );
};

ReloadContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};

ReloadContextProvider.defaultProps = {};
