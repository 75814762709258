/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { isEmpty, isObject } from 'lodash';
import PropTypes from 'prop-types';
import { FORM_INIT_VERSION } from './constants';
import { getFormDataFromStorage } from './utils';

const DetectStorageVersionDialog = props => {
  const {
    storageKey, setFormInitVersion,
  } = props;
  const translate = useTranslate();

  const [open, setOpen] = useState(false);

  const handleGetNewForm = () => {
    setOpen(false);
    setFormInitVersion(FORM_INIT_VERSION.NEW_FORM);
  };

  const handleGetDraftVersion = () => {
    setOpen(false);
    setFormInitVersion(FORM_INIT_VERSION.DRAFT_FORM);
  };

  useEffect(() => {
    const formDataStorage = getFormDataFromStorage(storageKey);
    if (isObject(formDataStorage) && !isEmpty(formDataStorage)) {
      setOpen(true);
      return;
    }
    setFormInitVersion(FORM_INIT_VERSION.NEW_FORM);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
            _event.stopPropagation();
            return;
          }

          setOpen(false);
        }}
      >
        <DialogTitle>
          <Box textAlign="center">
            {translate('ra.text.informationGetDraftVersion')}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexWrap="wrap"
          >
            <Button
              variant="contained"
              style={{
                padding: '20px 40px',
                marginBottom: '20px',
                width: '100%',
                minWidth: '200px',
              }}
              onClick={handleGetNewForm}
            >
              {translate('ra.action.newForm')}
            </Button>

            <Button
              color="primary"
              variant="contained"
              style={{
                padding: '20px 40px',
                marginBottom: '20px',
                width: '100%',
                minWidth: '200px',
              }}
              onClick={handleGetDraftVersion}
            >
              {translate('ra.action.getDraftVersion')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DetectStorageVersionDialog.defaultProps = {};

DetectStorageVersionDialog.propTypes = {
  storageKey: PropTypes.string.isRequired,
  setFormInitVersion: PropTypes.func.isRequired,
};

export default DetectStorageVersionDialog;
