import React, { useEffect, useRef, useState } from 'react';
import { Box, Tooltip, Button as MuiButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import cls from 'classnames';
import { useListContext, useNotify, useTranslate } from 'react-admin';
import { alpha } from '@material-ui/core/styles';
import { Schedule } from '@material-ui/icons';
import Confirm from '../../../../base/components/ra/layout/Confirm';
// eslint-disable-next-line
import { useStyles as useCommonStyles } from '.';
import { useReloadContext } from '../../../../base/context/reload/reload.context';
import { PREPAID_APPROVAL_STATUS, PrepaidStatusEnum } from '../../../../constant';
import resourceSlug from '../../../../constant/resource-slug';

const useStyles = makeStyles(theme => ({
  activateButton: {
    color: ({ disableActivateButton }) => (disableActivateButton ? alpha('#000000', 0.26) : theme.palette.primary.light),
    cursor: ({ disableActivateButton }) => (disableActivateButton ? 'not-allowed' : 'pointer'),
  },
}));

const ActivateButton = props => {
  const { record } = props;
  const commonClasses = useCommonStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { setValuesContext } = useReloadContext();
  const prepaidStatus = record?.status;
  const prepaidId = record?.id;

  const [isDialogDisplay, setIsDialogDisplay] = useState(false);
  const [activeClicked, setActiveClicked] = useState(false);

  const prevLoading = useRef();

  const hasActivated = prepaidStatus === PrepaidStatusEnum.ACTIVE;
  const hasApproved = record.approvalStatus === PREPAID_APPROVAL_STATUS.approved;
  const disableActivateButton = hasActivated || !hasApproved;

  const classes = useStyles({
    disableActivateButton,
  });

  const {
    loading: listLoading, data: listData,
  } = useListContext(props);

  const updateListDataFromClient = status => {
    const tmpListContextData = JSON.parse(JSON.stringify(listData));
    Object.keys(tmpListContextData).forEach(pIdItem => {
      if (pIdItem === prepaidId) {
        tmpListContextData[prepaidId].status = status;
      }
    });

    // Update data on client side
    setValuesContext({
      data: tmpListContextData,
    });
  };

  const handleDialogConfirm = async () => {
    try {
      axios.post(`/api/${resourceSlug.PREPAID}/${record.id}/active`).catch(err => {
        console.debug('[ERROR] after prepaid active\n', err.message);
        // Revert the ACTIVATION action
        setActiveClicked(false);
        updateListDataFromClient(PrepaidStatusEnum.INACTIVE);
      });

      // Handle the Prepaid ACTIVATION on client (browser) side!
      setActiveClicked(true);
      updateListDataFromClient(PrepaidStatusEnum.ACTIVE);
    } catch (err) {
      // Do nothing!
      // This error is handled in the interceptor
      console.debug('[ERROR] at prepaid activation\n', err.message);
    } finally {
      setIsDialogDisplay(false);
    }
  };

  const handleActiveClick = e => {
    e.stopPropagation();
    setIsDialogDisplay(true);
  };

  useEffect(() => {
    if (!listLoading && prevLoading.current === true) {
      setValuesContext({});
    }

    return () => {
      prevLoading.current = listLoading;
    };
  }, [listLoading]);

  useEffect(() => {
    if (activeClicked) {
      const prepaidName = translate('resources.prepaid.name');
      const afterActiveMessage = translate('ra.notification.resourceActivationInProgress', {
        _: '%{smart_name} activation is in progress. Please wait for a moment!',
        smart_name: prepaidName,
      });

      notify(afterActiveMessage, {
        type: 'info',
        autoHideDuration: 3500,
      });
    }
  }, [translate, notify, activeClicked]);

  return (
    <>
      <Tooltip title={translate('ra.action.activate')}>
        <MuiButton
          variant="contained"
          size="small"
          className={cls(commonClasses.button, classes.activateButton)}
          onClick={handleActiveClick}
          disabled={disableActivateButton}
        >
          <Box
            component="span"
            display="flex"
            alignItems="center"
            style={{
              gap: '3px',
            }}
          >
            <Schedule fontSize="16px" />
            <Box component="span">{translate('ra.action.activate')}</Box>
          </Box>
        </MuiButton>
      </Tooltip>
      <Confirm
        isOpen={isDialogDisplay}
        title={translate('ra.text.confirmActivateTitle')}
        content={translate('ra.text.confirmActivateMessage', {
          smart_name: translate('resources.prepaid.name').toLowerCase(),
        })}
        onConfirm={handleDialogConfirm}
        onClose={() => setIsDialogDisplay(false)}
      />
    </>
  );
};

ActivateButton.propTypes = {
  record: PropTypes.object,
};

ActivateButton.defaultProps = {
  record: null,
};

export default ActivateButton;
