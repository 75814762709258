import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import FormField from './FormField';
import { BET_CHANNELS } from '../../../../constant';
import { SelectInput } from '../../ra/inputs';

const ChannelSelect = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  const choices = useMemo(
    () => [
      {
        id: null,
        name: translate('ra.text.all'),
      },
      ...Object.values(BET_CHANNELS).map(item => ({
        id: item,
        name: item,
      })),
    ],
    [],
  );

  return (
    <FormField name={source}>
      {() => (
        <SelectInput
          label={translate('resources.bet.fields.channel')}
          source={source}
          choices={choices}
          {...otherProps}
        />
      )}
    </FormField>
  );
};

ChannelSelect.propTypes = {
  source: PropTypes.string,
};

ChannelSelect.defaultProps = {
  source: 'channel',
};

export default ChannelSelect;
