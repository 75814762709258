import React from 'react';
import { TextField } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { ActionList } from './components/action.list';

function UserStatistics(props) {
  return (
    <WealthListGuesser
      {...props}
      actions={<ActionList />}
      excludeFields={['id']}
    >
      <TextField source="user" />
      <TextField source="lastLogin" />
    </WealthListGuesser>
  );
}

export default UserStatistics;
