import React from 'react';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import WealthInputWrapper from '../../base/components/guesser/wealth-input-wrapper';
import IpsComponent from './components/ips.component';

const WhiteListEdit = props => {
  const transform = data => {
    const {
      ips = [], name, notes,
    } = data;

    return {
      name,
      notes,
      ips: ips.map(ip => ip.trim()),
    };
  };
  return (
    <WealthEditGuesser
      {...props}
      customFields={['ips']}
      transform={transform}
    >
      <WealthInputWrapper source="ips">
        <IpsComponent {...props} />
      </WealthInputWrapper>
    </WealthEditGuesser>
  );
};

export default WhiteListEdit;
