/* eslint-disable no-param-reassign, no-nested-ternary */
import React, { memo } from 'react';
import { FormDataConsumer, required, useTranslate } from 'ra-core';
import { Box, makeStyles } from '@material-ui/core';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import isIP from 'is-ip';
import { TextInput } from '../../../base/components/ra/inputs';

const useStyles = makeStyles(() => ({
  wrapper: {
    '& > div': {
      marginTop: 0,
      '& > ul': {
        marginTop: '16px',
        '& > li': {
          alignItems: 'flex-start',
          '& > p': {
            paddingTop: '2em',
            width: '1em',
          },
          '& > span': {
            paddingTop: '1.5em',
          },
        },
      },
    },
  },
}));

const IpsComponent = memo(() => {
  const translate = useTranslate();

  const validateIp = () => function (value) {
    return isIP(value)
      ? ''
      : translate('wa.exception.invalidIP', {
        key: `"${value}"`,
      });
  };

  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <FormDataConsumer>
        {({ formData }) => {
          const { ips } = formData;
          if (!ips || ips?.length === 0) {
            formData.ips = [''];
          }

          return (
            <ArrayInput
              source="ips"
              validate={required()}
            >
              <SimpleFormIterator disableRemove={ips?.length === 1}>
                <TextInput
                  style={{
                    marginTop: '20px',
                  }}
                  fullWidth
                  placeholder="0.0.0.0"
                  validate={[required(), validateIp()]}
                  label={translate('resources.white-list.fields.ip')}
                />
              </SimpleFormIterator>
            </ArrayInput>
          );
        }}
      </FormDataConsumer>
    </Box>
  );
});

IpsComponent.propTypes = {};

export default IpsComponent;
