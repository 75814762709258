import { addExportListener } from './exporters.util';

const playerExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;
  const groups = await fetchRelatedRecords(records, 'groupId', 'group');
  const brands = await fetchRelatedRecords(records, 'brandId', 'brand');

  const playersForExport = records.map(p => ({
    id: p.id,
    group: groups[p.groupId]?.name,
    brand: brands[p.brandId]?.name,
    nativeId: p.nativeId,
    type: p.typeOfPlayer,
    created: p.created,
    updated: p.updated,
  }));

  addExportListener({
    dataList: playersForExport,
    dateString: date,
    fileName: 'Players data',
  });
};

export default playerExporter;
