import React from 'react';
import { usePermissions } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { useAuthUser } from '../../base/hooks';
import resourceSlug from '../../constant/resource-slug';

export default function BrandList(props) {
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasRegulationReadPermission = user?.superman
    || (Array.isArray(permissions) && !!permissions?.find(item => item.resource.name === resourceSlug.REGULATION)?.read);

  return (
    <WealthListGuesser
      {...props}
      excludeFields={hasRegulationReadPermission ? [] : ['regulation']}
      fieldOrder={[
        'id',
        'created',
        'updated',
        'name',
        'desc',
        'group.id',
        'code',
        'regulation.id',
        'currencies',
        'enabled',
        'domain',
        'walletIntegration.id',
        'defaultLanguage.id',
      ]}
    />
  );
}
