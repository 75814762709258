import React from 'react';
import { useTranslate } from 'react-admin';
import { TextInput } from '../../ra/inputs';
import FormField from './FormField';

const PlayerIdInput = props => {
  const translate = useTranslate();
  return (
    <>
      <FormField name="playerId||$cont">
        {() => (
          <TextInput
            label={translate('resources.report.fields.playerId')}
            source="playerId||$cont"
            resettable
            {...props}
          />
        )}
      </FormField>
    </>
  );
};

PlayerIdInput.propTypes = {};

PlayerIdInput.defaultProps = {};

export default PlayerIdInput;
