import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import FormField from './FormField';
import { SelectInput } from '../../ra/inputs';
import { CURRENCY_TYPES } from '../../../../constant';

const CurrencyTypeSelector = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  const choices = useMemo(
    () => [
      {
        name: '--ALL--',
        id: null,
      },
      ...Object.values(CURRENCY_TYPES).map(type => ({
        id: type,
        name: type,
      })),
    ],
    [],
  );

  return (
    <FormField name={source}>
      {() => (
        <SelectInput
          source={source}
          label={translate('resources.report.fields.currencyType')}
          choices={choices}
          variant="outlined"
          translateChoice
          {...otherProps}
        />
      )}
    </FormField>
  );
};

CurrencyTypeSelector.propTypes = {
  source: PropTypes.string,
};

CurrencyTypeSelector.defaultProps = {
  source: 'currencyType',
};

export default CurrencyTypeSelector;
