import playerExporter from './player.exporter';
import wagerExporter from './wager.exporter';
import reportPlayer from './report-players.exporter';
import reportInvoicing from './report-invoicing.exporter';
import reportRTP from './report-rtp.exporter';
import reportSalesAndProfit from './report-sales-and-profit.exporter';
import reportOperatorProfit from './report-operator-profit.exporter';
import gamePerformanceReport from './report-game-performance.exporter';
import performanceReport from './report-performance.exporter';
import winnersReport from './report-winners.exporter';

export default {
  player: playerExporter,
  wager: wagerExporter,
  'report-players': reportPlayer,
  'report-invoicing': reportInvoicing,
  'report-rtp': reportRTP,
  'report-sales-and-profit': reportSalesAndProfit,
  'report-operator-profit': reportOperatorProfit,
  'report-game-performance': gamePerformanceReport,
  'report-performance': performanceReport,
  'report-winners': winnersReport,
};
