import { Badge, Box, MenuItem, Popper, Paper, MenuList, Grow } from '@material-ui/core';
import React, { cloneElement, isValidElement, useMemo, useRef, useState } from 'react';
import useStyles from './RecursiveMenuItem.style';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

const RecursiveMenuItem = ({ children, link, icon, name, count, isParent, className, ...otherProps }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const listeners = useMemo(() => {
    return { onMouseEnter: () => setOpen(true), onMouseLeave: () => setOpen(false) };
  }, [isParent]);

  const renderMainComponent = () => {
    return (
      <MenuItem
        {...otherProps}
        autoFocus={false}
        ref={ref}
        className={clsx(
          {
            [classes.active]: open,
            className,
          },
          isParent && [classes.navCollapse, 'Cmt-navCollapse', 'Cmt-navCollapseBtn', classes.navCollapseBtn],
          classes.parentMenuItem,
        )}
        {...listeners}
      >
        <NavItemContent icon={icon} name={name} count={count} />
        <Popper
          anchorEl={ref.current}
          open={open}
          placement={isParent ? 'bottom-start' : 'right-start'}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            },
          }}
          className={classes.popover}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }} {...{ timeout: open ? 300 : 0 }}>
              <Paper elevation={4}>
                <MenuList className={clsx(classes.menuList, classes.childMenuList)}>
                  {children?.map((item, idx) => {
                    switch (item.type) {
                      case 'item':
                        return <NavItem key={idx} {...item} />;
                      case 'collapse':
                        return <RecursiveMenuItem key={idx} {...item} />;
                      default:
                        return null;
                    }
                  })}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </MenuItem>
    );
  };

  return renderMainComponent();
};

const NavItem = ({ link, icon, name, count }) => {
  const classes = useStyles();

  return (
    <NavLink className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')} to={link}>
      <NavItemContent name={name} count={count} icon={icon} />
    </NavLink>
  );
};

const NavItemContent = ({ count, name, icon }) => {
  const classes = useStyles();

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const renderBadge = () => {
    if (count > 0) {
      return (
        <Box ml={2} mr={4}>
          <Badge color="secondary" variant="dot" badgeContent={count} />
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      {renderIcon()}
      <Box component="span" className={classes.navText}>
        {name}
      </Box>
      {renderBadge()}
    </>
  );
};

export default RecursiveMenuItem;
