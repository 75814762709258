/* eslint-disable no-case-declarations */
import React from 'react';
import { EmailField, ImageField, TextField, required, email, regex } from 'react-admin';
import { getRegexValidate } from '../../../services/util/validate';
import { REGEX_URL } from '../../../services/util/validate/regularExpression';
import {
  ChipField,
  DocStatusField,
  IdField,
  NameField,
  MaskField,
  TuiViewerField,
  AmountField,
  DateField,
  MultilineAbleCopyField,
  DecimalField,
  NumberField,
  BooleanField,
} from '../ra/fields';
import CodeField from '../ra/fields/code.field';
import LinkField from '../ra/fields/link.field';
import {
  DateInput,
  DateTimeInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  TuiEditorInput,
  NullableBooleanInput,
  BooleanInput,
  NumberRangeInput,
  TranslatableInput,
} from '../ra/inputs';
import DateRangeInput from './date-range-input';
import { getQueryOperation } from '../../../services/util';

export const guessFieldComponent = schema => {
  let PropComponent = TextField;
  const guessedPropsComponent = {};

  switch (schema?.type) {
    case 'boolean':
      PropComponent = BooleanField;
      break;
    case 'mask':
      PropComponent = MaskField;
      break;
    case 'string':
      switch (schema?.format) {
        case 'date':
        case 'datetime-local':
        case 'date-time':
          PropComponent = DateField;
          guessedPropsComponent.showTime = true;
          break;
        case 'email':
          PropComponent = EmailField;
          break;
        case 'ip':
        case 'chip':
          PropComponent = ChipField;
          break;
        case 'image':
          PropComponent = ImageField;
          break;
        case 'richtext':
          PropComponent = TuiViewerField;
          break;
        case 'multiline-able-copy':
          PropComponent = MultilineAbleCopyField;
          guessedPropsComponent.className = 'multiline-able-copy-wrapper';
          break;
        case 'id':
          PropComponent = IdField;
          break;
        case 'name':
          PropComponent = NameField;
          break;
        case 'currency':
          PropComponent = AmountField;
          break;
        case 'text':
        default:
          PropComponent = TextField;
          break;
      }
      break;
    case 'decimal':
      const DecimalFieldMask = props => (
        <DecimalField
          {...props}
          options={schema?.options}
        />
      );
      PropComponent = DecimalFieldMask;
      break;
    case 'number':
      PropComponent = NumberField;
      break;
    default:
      PropComponent = TextField;
      break;
  }

  if (schema?.format === 'url') {
    PropComponent = LinkField;
  }

  if (schema?.sourceName === 'docStatus') {
    PropComponent = DocStatusField;
  }

  if (schema?.format === 'code') {
    PropComponent = CodeField;
  }

  return {
    PropComponent,
    guessedPropsComponent,
  };
};

export const guessInputComponent = (schema, options = {}) => {
  const {
    isFilter, translate, isRequired, resource, translatableInput,
  } = options;

  const guessedProps = {
    valueformat: schema?.type,
    variant: 'outlined',
    validate: [],
    queryOperation: isFilter
      ? getQueryOperation({
        type: schema?.type,
        format: schema?.format,
        isEnum: Boolean(schema?.enum),
        isReference: Boolean(schema?.properties?.reference?.$ref),
        isTextInput: schema?.properties?.filterConfig?.type === 'text-input',
        source: schema?.name,
        translatableInput,
      })
      : null,
  };

  let WrapperComponent = null;

  // TRANSLATABLE INPUTS
  if (schema?.properties?.translatable?.default) {
    WrapperComponent = TranslatableInput;
  }

  if (!isFilter) {
    // Check and add regex validation
    const regexValidation = getRegexValidate(schema?.properties);
    if (!isFilter && regexValidation) {
      guessedProps.validate.push(regexValidation);
    }

    // Check and add required validation
    if (isRequired) {
      guessedProps.validate.push(required());
    }

    if (typeof schema?.default !== 'undefined') {
      guessedProps.defaultValue = schema.default;
    }
  }

  // Default input component
  let InputComponent = null;
  switch (schema?.type) {
    case 'boolean':
      if (isFilter) {
        InputComponent = NullableBooleanInput;
        guessedProps.trueLabel = translate('ra.boolean.true');
        guessedProps.falseLabel = translate('ra.boolean.false');
        guessedProps.nullLabel = `--${translate('ra.text.all').toUpperCase()}--`;
      } else {
        InputComponent = BooleanInput;
      }
      break;

    case 'string':
      InputComponent = TextInput;

      if (schema?.format) guessedProps.valueformat = schema?.format;

      // Don't validate email when using filter feature
      if (schema?.format === 'email' && !isFilter) {
        guessedProps.valueformat = 'string';
        guessedProps.type = 'email';
        guessedProps.validate.push(email());
      }

      if (schema?.format === 'url') {
        InputComponent = TextInput;
        if (!isFilter) {
          const validateUrl = regex(REGEX_URL, 'wa.exception.invalidUrl');
          guessedProps.validate.push(validateUrl);
        }
      }

      if (schema?.format === 'date') {
        InputComponent = DateInput;
      }

      if (['datetime-local', 'date-time'].includes(schema?.format)) {
        InputComponent = DateTimeInput;
        guessedProps.valueformat = 'date';
        guessedProps.isFilter = isFilter;
      }

      if (['datetime-local', 'date-time', 'date'].includes(schema?.format) && isFilter) {
        InputComponent = DateRangeInput;
      }

      if (schema?.format === 'multiline') {
        guessedProps.multiline = true;
        guessedProps.rows = 5;
      }

      if (schema?.format === 'richtext') {
        InputComponent = TuiEditorInput;
        guessedProps.multiline = true;
        guessedProps.rows = 5;
      }

      if (schema?.enum) {
        guessedProps.valueformat = 'selection';
        InputComponent = SelectInput;

        if (schema?.format === 'multiple-choices') {
          InputComponent = SelectArrayInput;
        }

        guessedProps.choices = schema.enum.map(i => ({
          id: i,
          name: i,
        }));

        if (isFilter) {
          guessedProps.emptyText = `--${translate('ra.text.all').toUpperCase()}--`;

          if (resource === 'role' && schema.name === 'level') {
            guessedProps.choices = Array.from(Array(20)).map((key, index) => ({
              id: `${Number(index + 1)}`,
              name: `${index + 1}`,
            }));
          }
        }
      }

      break;

    case 'number':
    case 'decimal':
      if (isFilter) {
        InputComponent = NumberRangeInput;
      } else {
        InputComponent = NumberInput;
      }
      break;

    default:
      InputComponent = TextInput;
  }

  return {
    InputComponent,
    guessedProps,
    WrapperComponent,
  };
};
