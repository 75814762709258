/* eslint-disable no-use-before-define,import/no-cycle, react/no-array-index-key */
import { Box } from '@material-ui/core';
import React from 'react';
import GGRFiatBarChart from './ggr-fiat-bar-chart';
import GGRCryptoBarChart from './ggr-crypto-bar-chart';

const GGRByCurrencyBarChart = () => (
  <Box
    display="grid"
    sx={{
      gridGap: {
        xs: 32,
        lg: 48,
      },
      gridTemplateColumns: {
        lg: 'repeat(2, 1fr)',
      },
      alignItems: 'end',
    }}
  >
    <Box>
      <GGRCryptoBarChart />
    </Box>
    <Box>
      <GGRFiatBarChart />
    </Box>
  </Box>
);

export default GGRByCurrencyBarChart;
