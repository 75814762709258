import React from 'react';
import { useTranslate } from 'ra-core';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles({
  btn: {
    marginLeft: 8,
    fontWeight: 'medium',
  },
  contentText: {
    color: grey[600],
  },
});

const ConfirmDialog = ({
  open,
  onDeny,
  onClose,
  onConfirm,
  disableDeny,
  disableConfirm,
  dialogTitle,
  hideDeny,
  confirmLabel,
  denyLabel,
  children,
}) => {
  const translate = useTranslate();
  const classes = useStyle();

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={() => onClose(false)}
    >
      <Box
        px={{
          xs: 5,
          md: 7,
        }}
        pt={{
          xs: 4,
          md: 6,
        }}
        pb={{
          xs: 2,
          md: 4,
        }}
      >
        <Box
          mb={3}
          component="h2"
          className="font-bold"
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Box>
        <Box>
          <DialogContentText
            className={classes.contentText}
            id="alert-dialog-description"
          >
            {children}
          </DialogContentText>
        </Box>
        <Box
          pt={2}
          align="right"
        >
          {!hideDeny && (
            <Button
              className={classes.btn}
              onClick={() => onDeny(false)}
              color="primary"
              disabled={disableDeny}
            >
              {denyLabel || translate('ra.boolean.false')}
            </Button>
          )}
          <Button
            className={classes.btn}
            onClick={onConfirm}
            color="primary"
            autoFocus
            disabled={disableConfirm}
          >
            {confirmLabel || translate('ra.boolean.true')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  dialogTitle: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  hideDeny: PropTypes.bool,
  disableDeny: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  confirmLabel: PropTypes.string,
  denyLabel: PropTypes.string,
  children: PropTypes.node,
};

ConfirmDialog.defaultProps = {
  hideDeny: false,
  disableDeny: false,
  disableConfirm: false,
  confirmLabel: null,
  denyLabel: null,
  onDeny: f => f,
  onClose: f => f,
  children: null,
  dialogTitle: null,
};
