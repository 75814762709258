import guessFieldComponentWithProp from './wealth-field.guesser';
import guessInputComponentWithProp from './wealth-input.guesser';

export const guessProperty = ({
  source,
  properties,
  apiRef,
  view,
  resource,
  resources,
  required,
  hasShow,
  record,
  locale,
  translate,
  permissions,
}) => {
  if (!properties?.[source]) {
    throw new Error('Unknown property', source, properties);
  }

  switch (view) {
    case 'edit':
    case 'create':
      return guessInputComponentWithProp({
        source,
        properties,
        apiRef,
        view,
        resource,
        resources,
        required,
        record,
        locale,
        translate,
      });

    case 'list':
    case 'show':
    default:
      return guessFieldComponentWithProp({
        source,
        properties,
        apiRef,
        view,
        resource,
        resources,
        hasShow,
        record,
        locale,
        translate,
        permissions,
      });
  }
};
