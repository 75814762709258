import { Box, IconButton, MenuItem, Select, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useListContext, useListPaginationContext, useTranslate } from 'react-admin';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    padding: 0,
    fontSize: 14,
  },
  currentPageLabel: {
    background: 'rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    padding: '6px 10px',
    minWidth: 46,
    height: 'fit-content',
  },
  button: {
    '&:hover': {
      background: 'transparent',
    },
    "&[class*='disabled']": {
      color: 'rgba(0, 0, 0, 0.1)',
    },
  },
});

const InfinitePagination = ({
  rowsPerPage = [25, 50, 100],
  disablePrev,
  disableNext,
  onNextPage,
  onPrevPage,
  currentPage,
}) => {
  const translate = useTranslate();
  const {
    perPage = rowsPerPage[0], setPerPage,
  } = useListPaginationContext();
  const classes = useStyles();
  const { ids } = useListContext();

  const hasNoMorePage = useMemo(() => {
    const isLastPage = ids?.length < perPage;
    const isEmptyPage = !ids?.length;
    return isEmptyPage || isLastPage;
  }, [ids, perPage]);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        gap: 18,
      }}
      minHeight={52}
    >
      <Box
        display="flex"
        sx={{
          gap: 10,
        }}
      >
        <Box>{translate('ra.navigation.page_rows_per_page')}</Box>
        <Select
          value={perPage}
          onChange={e => setPerPage(e.target.value)}
          disableUnderline
          classes={{
            select: classes.root,
          }}
        >
          {rowsPerPage.map(value => (
            <MenuItem
              key={value}
              value={value}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        display="flex"
        alignItems="center"
      >
        <IconButton
          className={classes.button}
          disabled={disablePrev}
        >
          <NavigateBefore onClick={onPrevPage} />
        </IconButton>
        {Boolean(currentPage) && <Box className={classes.currentPageLabel}>{currentPage}</Box>}
        <IconButton
          className={classes.button}
          disabled={hasNoMorePage || disableNext}
        >
          <NavigateNext onClick={onNextPage} />
        </IconButton>
      </Box>
    </Box>
  );
};

InfinitePagination.propTypes = {
  rowsPerPage: PropTypes.arrayOf(PropTypes.number),
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func,
  currentPage: PropTypes.number,
};

InfinitePagination.defaultProps = {
  rowsPerPage: [25, 50, 100],
  disablePrev: false,
  disableNext: false,
  onPrevPage: () => {},
  onNextPage: () => {},
  currentPage: undefined,
};

export default InfinitePagination;
