import React from 'react';
import resourceSlug from '../../../constant/resource-slug';
import CustomLinkField from '../../../base/components/ra/fields/customLink.field';

const RequesterField = props => (
  <CustomLinkField
    source="requester"
    href={record => `/#/${resourceSlug.USER}/${record.requester?.id}/show`}
    title={record => record.requester?.username}
    {...props}
  />
);

export default RequesterField;
