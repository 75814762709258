export const EnumViewType = {
  CREATE: '@VIEW_TYPE/CREATE',
  LIST: '@VIEW_TYPE/LIST',
  EDIT: '@VIEW_TYPE/EDIT',
  SHOW: '@VIEW_TYPE/SHOW',
  OTHER: '@VIEW_TYPE/OTHER',
};

let viewTypeGlobal = EnumViewType.OTHER;

export const registerViewType = (pathname, viewType) => {
  // pathname as `/abc/xyz/...`
  const itemsOfPath = pathname
    ?.replace(/^\//, '') /* trim string with `/` */
    ?.split('/') || []; /* get items of pathname */

  if (viewType) {
    viewTypeGlobal = viewType;
  } else if (itemsOfPath.slice(-1)[0] === 'show') {
    viewTypeGlobal = EnumViewType.SHOW;
  } else if (itemsOfPath.slice(-1)[0] === 'create') {
    viewTypeGlobal = EnumViewType.CREATE;
  } else if (itemsOfPath.length === 1) {
    viewTypeGlobal = EnumViewType.LIST;
  } else if (itemsOfPath.length === 2) {
    viewTypeGlobal = EnumViewType.EDIT;
  } else {
    viewTypeGlobal = EnumViewType.OTHER;
  }
};

const useViewType = () => ({
  currentView: viewTypeGlobal,
  isCreateView: viewTypeGlobal === EnumViewType.CREATE,
  isListView: viewTypeGlobal === EnumViewType.LIST,
  isEditView: viewTypeGlobal === EnumViewType.EDIT,
  isShowView: viewTypeGlobal === EnumViewType.SHOW,
});

export default useViewType;
