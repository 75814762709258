/* eslint-disable */
import React from 'react';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate, useRefresh } from 'ra-core';
import SendIcon from '@material-ui/icons/Send';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import { API_URL, CREDENTIAL_TYPE } from '../../../constant';
import ConfirmCTA from './confirm-cta';

export const ActionMenu = props => {
  const { record } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();

  const sendMessage = async () => {
    try {
      await axios.post(`api/inbox-rule/${record?.id}/trigger`);
      notify('Successfully', 'success');
      refresh();
    } catch (error) {
      if (!error?.response) {
        notify(translate('ra.message.tryAgain'), 'error');
      }
      notify(error?.response?.data?.message, 'error');
    }
  };

  return (
    <ToolbarMenu {...props}>
      <ConfirmCTA
        title={translate('ra.action.sendMessage')}
        content={translate('ra.message.confirmToSendMessage')}
        onConfirm={() => sendMessage()}
        buttonTrigger={{
          icon: <SendIcon />,
          label: 'ra.action.sendMessage',
        }}
      />
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {};

ActionMenu.defaultProps = {};
