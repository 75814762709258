/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  required,
  minValue,
  useDataProvider,
  useTranslate,
  useLocale,
  FormDataConsumer,
  ReferenceArrayInput,
  usePermissions,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Grid, Box, Fade, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import Axios from 'axios';

import moment from 'moment';
import {
  NumberInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
} from '../../../base/components/ra/inputs';
import { InputGridWrapper, InputContainer } from '../../../base/components/layout/input-wrapper';

import { ONLY_INTEGER } from '../../../services/util/validate/regularExpression';
import { testByRegex } from '../../../services/util/validate/validateMethods';
import rcSlug from '../../../constant/resource-slug';
import useTranslateSchemaRef from '../../../base/hooks/useTranslateSchemaRef';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';
import IssuerSelector from './IssuerSelector';
import SpinTypeSelector from './SpinTypeSelector';
import ExpiresInput from './ExpiresInput';
import { useAuthUser } from '../../../base/hooks';

const PrepaidCreateForm = () => {
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasIssuerCreatePermission = user?.superman || (Array.isArray(permissions) && !!permissions?.find(item => item.resource.name === rcSlug.PREPAID_ISSUER)?.create);
  const dataProvider = useDataProvider();
  const form = useForm();
  const locale = useLocale();
  const { values: formValues } = useFormState();
  const translate = useTranslate();
  const translateSchemaRef = useTranslateSchemaRef();

  const [listGameType, setListGameType] = useState([]);
  const [betAmountCalculating, setBetAmountCalculating] = useState(false);

  const locationHash = window.location.hash;
  const locationHashDecode = decodeURIComponent(locationHash);
  const [resourceCloneData] = useState(() => {
    const startIdxObjString = locationHashDecode.indexOf('{');
    let result = null;
    try {
      result = JSON.parse(locationHashDecode.slice(startIdxObjString, locationHashDecode.length));
    } catch (err) {
      console.error('[ERROR] parse clone data fail\n', err.message);
    }
    return result;
  });

  const betSettingDefaultInitValue = {
    betSizes: [],
    betLevels: [],
  };
  const betSettingInitValue = {
    betSizes: [],
    betLevels: [],
  };

  const [availableGame, setAvailableGame] = useState([]);
  const [currenciesSupport, setCurrenciesSupport] = useState([]);
  const [betSettingDefault, setBetSettingDefault] = useState(betSettingDefaultInitValue);
  const [betSetting, setBetSetting] = useState(betSettingInitValue);

  const paramsGetBetSetting = [formValues.groupId, formValues.brandId, formValues.gameId, formValues.currencyId];

  const paramsGetBetSettingDefault = [formValues.gameId, formValues.currencyId];

  const countValueMustGreatThanOneMessage = translate('ra.validation.mustGreatThan', {
    field_a: translate('resources.prepaid.fields.count'),
    field_b: '0',
  });

  const resources = useSelector(({ admin }) => admin.resources);
  const brandDataFetched = get(resources, 'brand.data');
  const gameDataFetched = get(resources, 'game.data');

  // About `AutocompleteInput`: Text which display on list must be string, so number => string
  const convertNumberToStringForChoices = (listItem, field) => listItem.map(item => ({
    ...item,
    [field]: String(get(item, field)),
  }));

  const getAvailableGame = async (groupId, brandId) => {
    try {
      const { data } = await dataProvider.getOneWithFilter(rcSlug.AVAILABLE_GAME, {
        filter: {
          'group.id': groupId,
          'brand.id': brandId,
        },
      });

      if (data && data.games) {
        setAvailableGame(data.games);
      }
    } catch (err) {
      console.error('[ERROR] Get Available Game', err?.message);
    }
  };

  const getBetSettingDefault = async (gameId, currencyId) => {
    try {
      const { data } = await dataProvider.getOneWithFilter(rcSlug.BET_SETTING_DEFAULT, {
        filter: {
          'game.id': gameId,
          'currency.id': currencyId,
        },
      });

      const [betSizes, betLevels] = [get(data, 'betSizes', []), get(data, 'betLevels', [])];

      if (data && betSizes.length && betLevels.length) {
        setBetSettingDefault({
          betSizes: convertNumberToStringForChoices(betSizes, 'value'),
          betLevels: convertNumberToStringForChoices(betLevels, 'value'),
        });
      }
    } catch (err) {
      console.error('[ERROR] Get Bet Setting Default', err?.message);
    }
  };

  const getBetSetting = async (groupId, brandId, gameId, currencyId) => {
    try {
      const { data } = await dataProvider.getOneWithFilter(rcSlug.BET_SETTING, {
        filter: {
          'group.id': groupId,
          'brand.id': brandId,
          'game.id': gameId,
          'currency.id': currencyId,
        },
      });

      const [betSizes, betLevels] = [get(data, 'betSizes', []), get(data, 'betLevels', [])];

      if (data && betSizes.length && betLevels.length) {
        setBetSetting({
          betSizes: convertNumberToStringForChoices(betSizes, 'value'),
          betLevels: convertNumberToStringForChoices(betLevels, 'value'),
        });
      }
    } catch (err) {
      console.error('[ERROR] Get Bet Setting', err?.message);
    }
  };

  const getListSpinType = async gameId => {
    try {
      const { data: listGameTypeData } = await Axios.get(`api/${rcSlug.GAME}/${gameId}/config`);
      if (listGameTypeData) {
        setListGameType(listGameTypeData);
      } else {
        setListGameType([]);
      }
    } catch (err) {
      console.error('[ERROR] get list game type\n', err.message);
    }
  };

  const handleGameSelect = () => {
    form.change('betSize', undefined);
    form.change('betLevel', undefined);
    form.change('spinTypeId', undefined);
    setBetSetting(betSettingInitValue);
    setBetSettingDefault(betSettingDefaultInitValue);
  };

  const handleCurrencySelect = () => {
    form.change('betSize', undefined);
    form.change('betLevel', undefined);
    form.change('players', null);
    setBetSetting(betSettingInitValue);
    setBetSettingDefault(betSettingDefaultInitValue);
  };

  const handleBetSizeSelect = () => {};

  const handleBetLevelSelect = () => {};

  const onlyInteger = fieldName => testByRegex(translateSchemaRef, {
    regex: ONLY_INTEGER,
    translationSchema: {
      name: 'ra.validation.inputMustBeInteger',
      params: {
        smart_name: fieldName,
      },
    },
  });

  const renderGameInput = () => {
    const isTranslatable = availableGame.some(item => typeof item.name === 'object');
    return (
      <AutocompleteInput
        label="resources.prepaid.fields.game"
        source="gameId"
        disabled={!formValues.groupId || !formValues.brandId}
        choices={availableGame.filter(game => game.prepaidEnabled)}
        optionText={isTranslatable ? `name.${locale}` : 'name'}
        optionValue="id"
        onChange={handleGameSelect}
        validate={required()}
      />
    );
  };

  useEffect(() => {
    if (formValues.groupId && formValues.brandId) {
      getAvailableGame(formValues.groupId, formValues.brandId);
    }
  }, [formValues.groupId, formValues.brandId]);

  useEffect(() => {
    const brandSelected = get(brandDataFetched, formValues.brandId);
    if (!formValues.brandId || !brandSelected) {
      return;
    }

    const currencies = get(brandSelected, 'currencies', []);
    if (currencies.length > 0) {
      setCurrenciesSupport(currencies);
    }
  }, [formValues.brandId, brandDataFetched]);

  useEffect(() => {
    if (paramsGetBetSetting.every(item => item)) {
      getBetSetting(...paramsGetBetSetting);
    } else {
      setBetSetting(betSettingInitValue);
    }
  }, [...paramsGetBetSetting]);

  useEffect(() => {
    if (paramsGetBetSettingDefault.every(item => item)) {
      getBetSettingDefault(...paramsGetBetSettingDefault);
    } else {
      setBetSettingDefault(betSettingDefaultInitValue);
    }
  }, [...paramsGetBetSettingDefault]);

  const getBaseBetByGameId = async gameId => {
    try {
      let data = gameDataFetched[gameId];
      if (!data) {
        const gameInfo = await dataProvider.getOneWithFilter(rcSlug.GAME, {
          filter: {
            id: gameId,
          },
        });
        data = gameInfo?.data;
      }

      setBetAmountCalculating(false);
      return get(data, 'baseBet');
    } catch (err) {
      console.error('[ERROR] get baseBet by game ID\n', err.message);
    }
    return null;
  };

  const calculateBetAmount = async () => {
    const {
      betSize, betLevel, gameId,
    } = formValues;

    const baseBet = await getBaseBetByGameId(gameId);
    const betAmount = baseBet * Number(betSize) * Number(betLevel);
    if (isNumber(betAmount)) {
      form.change('betAmount', Number(betAmount.toFixed(2)));
    }
  };

  useEffect(() => {
    if (Number(formValues.betSize) >= 0 && Number(formValues.betLevel) >= 0 && formValues.gameId) {
      setBetAmountCalculating(true);
      form.change('betAmount', undefined);
      calculateBetAmount();
    } else {
      form.change('betAmount', undefined);
    }
  }, [formValues.betSize, formValues.betLevel, formValues.gameId]);

  useEffect(() => {
    if (formValues.gameId) {
      getListSpinType(formValues.gameId);
    }
  }, [formValues.gameId]);

  useEffect(() => {
    if (!formValues.betAmount) {
      form.change('spinTypeId', undefined);
    }
  }, [formValues.betAmount]);

  useEffect(() => {
    if (resourceCloneData) {
      const cloneDataInit = {
        claimBefore: get(resourceCloneData, 'reward.claimBefore'),
        inboxRuleMgsTemplate: get(resourceCloneData, 'inboxRule.message.data'),
        groupId: get(resourceCloneData, 'group.id'),
        brandId: get(resourceCloneData, 'brand.id'),
        gameId: get(resourceCloneData, 'game.id'),
        currencyId: get(resourceCloneData, 'currency.id'),
        count: get(resourceCloneData, 'reward.count'),
        betSize: String(get(resourceCloneData, 'reward.data.betSetting.betSize')),
        betLevel: String(get(resourceCloneData, 'reward.data.betSetting.betLevel')),
        name: get(resourceCloneData, 'name'),
        desc: get(resourceCloneData, 'desc'),
        players: get(resourceCloneData, 'players', []),
        spinTypeId: get(resourceCloneData, 'reward.data.gameMode.id'),
        issuer: get(resourceCloneData, 'issuer'),
      };

      form.initialize({
        ...cloneDataInit,
      });
    } else {
      form.initialize({
        claimBefore: moment().add(1, 'months').startOf('second').toISOString(),
        inboxRuleMgsTemplate:
          "You have received #{reward.count} #{reward.data.gameMode.name} from the prepaid #{prepaid.name} campaign. You have to claim this reward before #{moment(reward.claimBefore).format('YYYY-MM-DD HH:mm')} for game #{game.name.en}",
      });
    }
  }, []);

  const betSizeChoices = betSetting.betSizes.length > 0 ? betSetting.betSizes : betSettingDefault.betSizes;
  const betLevelChoices = betSetting.betLevels.length > 0 ? betSetting.betLevels : betSettingDefault.betLevels;

  const nativeIdNotNullKey = `nativeId${FILTER_PREFIX_ON_VALUE.NOT_EQUALS.replace(/\*/g, '')}`;

  return (
    <>
      <Grid container>
        <InputGridWrapper>
          <ReferenceInput
            fullWidth
            label="resources.prepaid.fields.group"
            reference="group"
            source="groupId"
            validate={required()}
            afterOnChange={() => {
              form.change('brandId', undefined);
              form.change('gameId', undefined);
              form.change('currencyId', undefined);
              form.change('betSize', undefined);
              form.change('betLevel', undefined);
              form.change('players', null);
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </InputGridWrapper>

        <InputGridWrapper>
          <ReferenceInput
            fullWidth
            label="resources.prepaid.fields.brand"
            reference="brand"
            source="brandId"
            filter={{
              'group.id': formValues.groupId,
            }}
            validate={required()}
            disabled={!formValues.groupId}
            afterOnChange={() => {
              form.change('gameId', undefined);
              form.change('currencyId', undefined);
              form.change('betSize', undefined);
              form.change('betLevel', undefined);
              form.change('players', null);
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </InputGridWrapper>

        <InputGridWrapper>{renderGameInput()}</InputGridWrapper>

        <InputGridWrapper>
          <AutocompleteInput
            label="resources.prepaid.fields.currency"
            source="currencyId"
            disabled={!formValues.brandId}
            choices={currenciesSupport}
            optionText="name"
            optionValue="id"
            onChange={handleCurrencySelect}
            validate={required()}
            afterOnChange={() => {
              form.change('players', null);
            }}
          />
        </InputGridWrapper>

        <InputGridWrapper>
          <NumberInput
            fullWidth
            label="ra.field.count"
            source="count"
            min={1}
            step={1}
            validate={[
              required(),
              minValue(1, countValueMustGreatThanOneMessage),
              onlyInteger(
                translate('resources.prepaid.fields.count', {
                  _: 'Count',
                }),
              ),
            ]}
          />
        </InputGridWrapper>

        <ExpiresInput />

        <InputGridWrapper>
          <AutocompleteInput
            label="ra.field.betSize"
            source="betSize"
            disabled={!formValues.gameId || !formValues.currencyId}
            choices={betSizeChoices}
            optionText="value"
            optionValue="value"
            onChange={handleBetSizeSelect}
            validate={required()}
          />
        </InputGridWrapper>

        <InputGridWrapper>
          <AutocompleteInput
            label="ra.field.betLevel"
            source="betLevel"
            disabled={!formValues.gameId || !formValues.currencyId}
            choices={betLevelChoices}
            optionText="value"
            optionValue="value"
            onChange={handleBetLevelSelect}
            validate={required()}
          />
        </InputGridWrapper>

        <InputGridWrapper>
          <Box
            className="pointer-event-none"
            style={{
              position: 'relative',
            }}
          >
            <NumberInput
              disabled
              fullWidth
              label={translate('resources.prepaid.fields.betAmount', {
                _: 'Bet Amount',
              })}
              source="betAmount"
              min={1}
              step={1}
            />
            <Fade
              in={betAmountCalculating}
              style={{
                transitionDelay: betAmountCalculating ? '800ms' : '0ms',
                position: 'absolute',
                top: '5px',
                right: '10px',
                color: '#c8c8c8',
                margin: '8px 0px 4px',
              }}
              unmountOnExit
            >
              <CircularProgress
                style={{
                  width: '30px',
                  height: '30px',
                }}
              />
            </Fade>
          </Box>
        </InputGridWrapper>

        <InputContainer width="100%">
          <SpinTypeSelector data={listGameType} />
        </InputContainer>

        {hasIssuerCreatePermission && (
          <InputContainer width="100%">
            <IssuerSelector validate={required()} />
          </InputContainer>
        )}

        <FormDataConsumer>
          {({ formData }) => (
            <InputContainer width="100%">
              <ReferenceArrayInput
                label={translate('resources.prepaid.fields.players', {
                  _: 'Players',
                })}
                source="players"
                reference="player"
                sort={{
                  field: 'name',
                  order: 'ASC',
                }}
                filterToQuery={value => ({
                  [nativeIdNotNullKey]: 'null',
                  nativeId: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
                })}
                filter={{
                  groupId: formData.groupId,
                  brandId: formData.brandId,
                  'wallet.currencyId': formData.currencyId,
                }}
                disabled={!formData.groupId || !formData.brandId || !formData.currencyId}
                parse={ids => ids?.map(id => ({
                  id,
                }))}
                format={data => data?.map(d => d.id)}
                enableGetChoices={() => Boolean(formData.groupId && formData.brandId && formData.currencyId)}
              >
                <AutocompleteArrayInput
                  optionText="nativeId"
                  enabledSelectAll={false}
                  shouldRenderSuggestions={() => Boolean(formData.groupId && formData.brandId && formData.currencyId)}
                />
              </ReferenceArrayInput>
            </InputContainer>
          )}
        </FormDataConsumer>

        <InputContainer width="100%">
          <TextInput
            fullWidth
            label="resources.prepaid.fields.name"
            source="name"
            validate={required()}
          />
        </InputContainer>

        <InputContainer width="100%">
          <TextInput
            fullWidth
            multiline
            label="resources.prepaid.fields.desc"
            source="desc"
            rows={5}
          />
        </InputContainer>

        <InputContainer width="100%">
          <TextInput
            fullWidth
            multiline
            label="resources.inbox-rule.fields.message"
            source="inboxRuleMgsTemplate"
            rows={5}
            validate={required()}
          />
        </InputContainer>
      </Grid>
    </>
  );
};

export default PrepaidCreateForm;
