/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import {
  useTheme,
  makeStyles,
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Fade,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useForm } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import RemoveIcon from '@material-ui/icons/Remove';
import CropFreeIcon from '@material-ui/icons/CropFree';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { HEADER_HEIGHT } from '../../../../custom-theme/themeConfig';
import BrandForm from '../BrandForm';
import {
  getBrandIndexSource,
  getFormDataFromStorage,
  getNextCloneName,
} from '../utils';
import TooltipButton from '../../../../base/components/button/TooltipButton';
import { BRAND_BACKGROUND } from '../constants';

const useStyles = makeStyles(theme => ({
  brandFormCard: {
    marginBottom: '40px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  btnDelete: {
    background: theme.palette.error.main,
  },
  cardHeaderRoot: {
    borderBottom: '1px solid #0000001f',
    backgroundColor: '#ffffff',
  },
  brandFormContainerRoot: {
    transition: 'height 2s',
  },
  brandFormContainerCollapsing: {
    height: 0,
    overflow: 'hidden',
  },
  brandFormContainerExpanding: {
    height: 'auto',
  },
}));

const MultiBrandForm = props => {
  const { storageKey } = props;

  const form = useForm();
  const muiTheme = useTheme();
  const classes = useStyles();
  const translate = useTranslate();

  const [brandFormList, setBrandFormList] = useState([]);
  const [collapsing, setCollapsing] = useState({});

  // TODO: highlight background of this form
  const focusToNewBrandForm = id => {
    setTimeout(() => {
      const newBrandFormEl = document.getElementById(id);
      window.scrollTo({
        top: newBrandFormEl.offsetTop - HEADER_HEIGHT.lg - 15,
        behavior: 'smooth',
      });
    });
  };

  const handleRemoveBrandFromList = brandId => {
    const newBrandFormList = brandFormList.filter(({ id }) => id !== brandId);
    setBrandFormList(newBrandFormList);

    const formValues = form.getState().values;
    const newBrandListInForm = formValues.brands.filter(
      ({ id }) => id !== brandId,
    );
    form.change('brands', newBrandListInForm);

    // Update collapsing items
    const newCollapsing = {
      ...collapsing,
    };
    delete newCollapsing[brandId];
    setCollapsing(newCollapsing);
  };

  const addNewBrandBelowByIndex = brandFormIndex => {
    const firstItems = brandFormList.slice(0, brandFormIndex);
    const restItems = brandFormList.slice(brandFormIndex, brandFormList.length);

    const formValues = form.getState().values;
    const firstBrands = formValues.brands.slice(0, brandFormIndex);
    const restBrands = formValues.brands.slice(
      brandFormIndex,
      brandFormList.length,
    );

    // Fake id for new Brand
    const newBrandIdFake = uuidv4();

    // Update brand's index in Form
    const newBrand = {
      id: newBrandIdFake,
    };
    form.change('brands', [...firstBrands, newBrand, ...restBrands]);

    // Update brand's index in for Brand List Rendering
    setBrandFormList([...firstItems, newBrand, ...restItems]);

    focusToNewBrandForm(newBrandIdFake);
  };

  const cloneBrand = brandFormIndex => {
    const formValues = form.getState().values;
    const currBrandData = get(formValues, ['brands', brandFormIndex], {});
    const brandDataCloned = JSON.parse(JSON.stringify(currBrandData));

    const newBrandIdFake = uuidv4();

    brandDataCloned.id = newBrandIdFake;
    // Clear brand name
    brandDataCloned.name = getNextCloneName(
      brandDataCloned.name,
      formValues.brands.map(({ name }) => name),
    );

    formValues.brands.push(brandDataCloned);
    form.change('brands', formValues.brands);

    // Adding to latest
    setBrandFormList([
      ...brandFormList,
      {
        id: newBrandIdFake,
      },
    ]);

    focusToNewBrandForm(newBrandIdFake);
  };

  useEffect(() => {
    const formDataStorage = getFormDataFromStorage(storageKey);
    const brandDataFromStorage = get(formDataStorage, 'brands', []);
    if (brandDataFromStorage.length > 0) {
      const listBrandFromCache = get(formDataStorage, 'brands', []).map(
        bItem => ({
          id: bItem.id,
        }),
      );
      setBrandFormList(listBrandFromCache);
    } else {
      const formValues = form.getState().values;
      const newBrandIdFake = uuidv4();
      const newBrand = {
        id: newBrandIdFake,
      };

      setBrandFormList([newBrand]);
      form.change('brands', [
        {
          ...newBrand,
          ...get(formValues, 'brands[0]', {}),
        },
      ]);
    }
  }, []);

  return (
    <Box id="multi-brand-form">
      {brandFormList.map((bFormItem, bFIndex) => {
        const source = getBrandIndexSource(bFIndex);
        const isCollapsing = collapsing[bFormItem.id];
        const CollapsingIconGuesser = isCollapsing ? CropFreeIcon : RemoveIcon;
        const collapsingIconFontSize = isCollapsing ? '24px' : '40px';

        const formValues = form.getState().values;
        const brandName = get(formValues, `${source}.name`);
        const disableClone = !get(formValues, `${source}.name`);

        const hasAnyError = !!get(form.getState(), `errors.brands[${bFIndex}]`);
        return (
          <Card
            key={bFormItem.id}
            id={bFormItem.id}
            className={classes.brandFormCard}
            style={{
              border: '1px solid',
              borderColor:
                isCollapsing && hasAnyError
                  ? muiTheme.palette.error.main
                  : '#ffffff',
              backgroundColor: BRAND_BACKGROUND,
            }}
          >
            <CardHeader
              classes={{
                root: classes.cardHeaderRoot,
              }}
              action={(
                <>
                  {isCollapsing && (
                    <>
                      <Button
                        variant="text"
                        style={{
                          marginLeft: 'auto',
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => addNewBrandBelowByIndex(bFIndex + 1, bFormItem)}
                      >
                        {translate('ra.action.addBrand', {
                          _: 'Add Brand',
                        })}
                      </Button>
                      <TooltipButton
                        tooltipProps={{
                          title: disableClone
                            ? translate(
                              'ra.message.cannotDuplicateBrandWithoutName',
                              {
                                _: 'Cannot duplicate Brand Without Name',
                              },
                            )
                            : '',
                        }}
                        buttonProps={{
                          variant: 'text',
                          startIcon: <FileCopyIcon />,
                          style: {
                            marginLeft: '10px',
                          },
                          disabled: disableClone,
                          onClick: () => cloneBrand(bFIndex),
                        }}
                      >
                        {/* Clone This Brand */}
                        {translate('ra.action.duplicateBrand', {
                          _: 'Duplicate Brand',
                        })}
                      </TooltipButton>
                    </>
                  )}
                  <Tooltip
                    placement="top"
                    title={
                      isCollapsing
                        ? translate('ra.action.expand')
                        : translate('ra.action.collapse')
                    }
                  >
                    <IconButton
                      style={{
                        marginLeft: '10px',
                      }}
                      onClick={() => {
                        setCollapsing({
                          ...collapsing,
                          [bFormItem.id]: !isCollapsing,
                        });
                      }}
                    >
                      <Box
                        style={{
                          width: '32px',
                          height: '32px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CollapsingIconGuesser
                          style={{
                            fontSize: collapsingIconFontSize,
                            color: muiTheme.palette.primary.main,
                          }}
                        />
                      </Box>
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    placement="top"
                    title={
                      brandFormList.length === 1
                        ? translate('ra.message.mustBeLeastOneBrandToCreate',
                          {
                            _: 'Must be least one Brand to create',
                          })
                        : translate('ra.action.delete')
                    }
                  >
                    <IconButton
                      style={{
                        marginLeft: '10px',
                        cursor:
                          brandFormList.length === 1
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                      disableRipple={brandFormList.length === 1}
                      onClick={() => {
                        if (brandFormList.length === 1) {
                          // Not allow to delete!
                          return;
                        }
                        handleRemoveBrandFromList(bFormItem.id);
                      }}
                    >
                      <Box
                        style={{
                          width: '32px',
                          height: '32px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ClearIcon
                          style={{
                            fontSize: '35px',
                            color:
                              brandFormList.length === 1
                                ? '#0000005c'
                                : muiTheme.palette.error.main,
                          }}
                        />
                      </Box>
                    </IconButton>
                  </Tooltip>
                </>
              )}
              title={(
                <Box
                  style={{
                    color: muiTheme.palette.primary.main,
                    fontSize: '18px',
                    fontWeight: '700',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Box component="span">
                    {`${translate(
                      'resources.brand.name',
                    )} #${bFIndex + 1}`}
                  </Box>

                  <Fade in={!!isCollapsing && brandName}>
                    <Box component="span">{`- ${brandName}`}</Box>
                  </Fade>
                </Box>
              )}
            />
            <CardContent
              style={{
                backgroundColor: isCollapsing ? '#ffffff' : BRAND_BACKGROUND,
              }}
            >
              <Box
                className={cls(classes.brandFormContainerRoot, {
                  [classes.brandFormContainerCollapsing]: isCollapsing,
                  [classes.brandFormContainerExpanding]: !isCollapsing,
                })}
              >
                <BrandForm
                  id={bFormItem.id}
                  index={bFIndex}
                  source={source}
                />
              </Box>
              {isCollapsing && (
                <Typography>
                  {translate('ra.message.msgBrandFormCollapsing')}
                </Typography>
              )}
            </CardContent>

            {!isCollapsing && (
              <CardActions
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  background: '#ffffff',
                }}
              >
                <Button
                  variant="text"
                  style={{
                    marginLeft: 'auto',
                  }}
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => addNewBrandBelowByIndex(bFIndex + 1, bFormItem)}
                >
                  {translate('ra.action.addBrand', {
                    _: 'Add Brand',
                  })}
                </Button>
                <TooltipButton
                  tooltipProps={{
                    title: disableClone
                      ? translate('ra.message.cannotDuplicateBrandWithoutName', {
                        _: 'Cannot duplicate Brand Without Name',
                      })
                      : '',
                  }}
                  buttonProps={{
                    variant: 'text',
                    startIcon: <FileCopyIcon />,
                    style: {
                      marginLeft: '10px',
                    },
                    disabled: disableClone,
                    onClick: () => cloneBrand(bFIndex),
                  }}
                >
                  {/* Clone This Brand */}
                  {translate('ra.action.duplicateBrand', {
                    _: 'Duplicate Brand',
                  })}
                </TooltipButton>
              </CardActions>
            )}
          </Card>
        );
      })}
    </Box>
  );
};

MultiBrandForm.defaultProps = {};

MultiBrandForm.propTypes = {
  storageKey: PropTypes.string.isRequired,
};

export default MultiBrandForm;
