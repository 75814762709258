import SwaggerParser from '@apidevtools/swagger-parser';
import { put, takeEvery } from 'redux-saga/effects';
import { PULL_WEALTH_SCHEMA, PULL_WEALTH_SCHEMA_SUCCESS } from '../root.actions';

export function* schemaSaga() {
  yield takeEvery(PULL_WEALTH_SCHEMA, function* () {
    const api = yield SwaggerParser.parse('/api/json');
    const ref = yield SwaggerParser.resolve(api);
    yield put({
      type: PULL_WEALTH_SCHEMA_SUCCESS,
      payload: {
        api,
        ref,
      },
    });
  });
}
