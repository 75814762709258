/* eslint-disable no-restricted-globals, react/prop-types */
import React from 'react';
import {
  SimpleForm,
  useEditController,
  useTranslate,
} from 'react-admin';
import { useUpdateCustom, useAuthUser } from '../../base/hooks';
import { Edit } from '../../base/components/ra/views';
import rcSlug from '../../constant/resource-slug';
import { checkSystemAdmin } from '../../services/util';
import BetSettingEditForm from './components/bet-setting-edit-form';
import { BetSettingFormProvider } from './utils/bet-setting-form.context';

const BetSettingEdit = props => {
  const user = useAuthUser();
  const { record } = useEditController(props);
  const translate = useTranslate();
  const { update } = useUpdateCustom({
    resource: rcSlug.BET_SETTING,
    resourceTranslated: translate(`resources.${rcSlug.BET_SETTING}.name`),
  });

  const isSystemAdmin = checkSystemAdmin(user);

  const updateBetSetting = (values = {}) => {
    const {
      group, brand, game, currency, totalBetMin, totalBetMax, ...restValue
    } = values;
    if (
      !Array.isArray(restValue?.picker?.listItem?.betSize)
      || !Array.isArray(restValue?.picker?.listItem?.betLevel)
      || isNaN(restValue?.picker?.defaultValue?.betSize)
      || isNaN(restValue?.picker?.defaultValue?.betLevel)
    ) {
      return;
    }

    const betSizes = restValue.picker.listItem.betSize.map(item => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betSize,
    }));

    const betLevels = restValue.picker.listItem.betLevel.map(item => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betLevel,
    }));

    const updateData = {
      brand,
      game,
      currency,
      totalBetLimit: [totalBetMin, totalBetMax],
      betSizes,
      betLevels,
    };

    // System admin need to choose a group
    if (isSystemAdmin) {
      updateData.group = group;
    }

    update({
      resource: rcSlug.BET_SETTING,
      payload: {
        id: record?.id,
        data: updateData,
      },
    });
  };

  return (
    <Edit
      {...props}
      alwaysEnableSaveButton
      onCustomSave={updateBetSetting}
    >
      <SimpleForm redirect="show">
        <BetSettingFormProvider>
          <BetSettingEditForm record={record} />
        </BetSettingFormProvider>
      </SimpleForm>
    </Edit>
  );
};

export default BetSettingEdit;
