import React from 'react';
import PropTypes from 'prop-types';
import LanguageSwitcherDesktop from './DesktopVersion';
import LanguageSwitcherMobile from './MobileVersion';

const LanguageSwitcher = props => {
  const { mobileVersion } = props;

  return (
    <>
      {mobileVersion
        ? <LanguageSwitcherMobile {...props} />
        : <LanguageSwitcherDesktop {...props} />}
    </>
  );
};

LanguageSwitcher.propTypes = {
  mobileVersion: PropTypes.bool,
};
LanguageSwitcher.defaultProps = {
  mobileVersion: false,
};

export default LanguageSwitcher;
