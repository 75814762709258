import React, { memo } from 'react';
import { LinearProgress } from '@material-ui/core';

const ValueLoading = props => (
  <LinearProgress {...props} />
);

ValueLoading.propTypes = {};

export default memo(ValueLoading);
