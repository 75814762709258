/* eslint-disable */
import React from "react";
import AccessTime from "@material-ui/icons/AccessTime";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import Type from "prop-types";
import { useNotify, useTranslate, useRefresh } from "ra-core";
import { SmallButton } from "../../../base/components/ra/buttons";
import ToolbarMenu from "../../../base/components/action-menu/toolbar-menu";
import { useError } from "../../../base/hooks";

export const ActionMenu = (props) => {
  const { record, list } = props;
  const notify = useNotify();
  const { notifyError } = useError();
  const translate = useTranslate();
  const refresh = useRefresh();

  const activeEvent = () => {
    Axios.post(`api/cheat-mode-apply/${record?.id}/reset`)
      .then((res) => {
        if (res.status === 201) {
          notify("Successfully", "success");
          refresh();
        }
      })
      .catch((error) => notifyError(error));
  };

  const inactiveEvent = () => {
    Axios.post(`api/cheat-mode-apply/${record?.id}/inactive`)
      .then((res) => {
        if (res.status === 201) {
          notify("Successfully", "success");
          refresh();
        }
      })
      .catch((error) => notifyError(error));
  };

  const mainComponent = record?.status === "INACTIVE" && (
    <SmallButton
      label="ra.action.active"
      onClick={activeEvent}
      startIcon={<AccessTime />}
    />
  );

  const disabledComponent = record?.status === "ACTIVE" && (
    <SmallButton
      label="resources.cheat-mode-apply.text.inactive"
      onClick={inactiveEvent}
      startIcon={<CloseIcon />}
    />
  );

  if (list) {
    return mainComponent;
  }

  return (
    <ToolbarMenu {...props}>
      {mainComponent}
      {disabledComponent}
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};
