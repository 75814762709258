import React from 'react';
import { required } from 'react-admin';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import CurrenciesReference from '../../base/components/reference-fields/currencies';

const GroupCreate = props => (
  <WealthCreateGuesser
    {...props}
    customFields={['currencies']}
    fieldOrder={['name', 'managerEmail', 'currencies', 'desc', 'enabled']}
  >
    <CurrenciesReference
      source="currencies"
      validate={required()}
      {...props}
    />
  </WealthCreateGuesser>
);

export default GroupCreate;
