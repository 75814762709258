/* eslint-disable */
import { isValidElement, useEffect, useMemo } from 'react';
import {
  useListParams,
  useRecordSelection,
  useCheckMinimumRequiredProps,
  defaultExporter,
  CRUD_GET_LIST,
  useResourceContext,
  useGetResourceLabel,
  useGetMainList,
  useNotify,
  useTranslate,
} from 'react-admin';

const defaultSort = {
  field: 'id',
  order: 'ASC',
};

const useCustomListController = props => {
  useCheckMinimumRequiredProps('List', ['basePath'], props);

  const {
    basePath,
    exporter = defaultExporter,
    filterDefaultValues,
    hasCreate,
    sort = defaultSort,
    perPage = 10,
    filter,
    debounce = 500,
    syncWithLocation,
    disabledAutoRefetch,
  } = props;
  const resource = useResourceContext(props);

  if (!resource) {
    throw new Error(
      '<List> was called outside of a ResourceContext and without a resource prop. You must set the resource prop.',
    );
  }
  if (filter && isValidElement(filter)) {
    throw new Error(
      '<List> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer.',
    );
  }

  const translate = useTranslate();
  const notify = useNotify();

  const [query, queryModifiers] = useListParams({
    resource,
    filterDefaultValues,
    sort,
    perPage,
    debounce,
    syncWithLocation,
  });

  const [selectedIds, selectionModifiers] = useRecordSelection(resource);

  /**
   * We want the list of ids to be always available for optimistic rendering,
   * and therefore we need a custom action (CRUD_GET_LIST) that will be used.
   */
  const {
    ids, data, total, error, loading, loaded, refetch,
  } = useGetMainList(
    resource,
    {
      page: query.page,
      perPage: query.perPage,
    },
    {
      field: query.sort,
      order: query.order,
    },
    {
      ...query.filter,
      ...filter,
    },
    {
      action: CRUD_GET_LIST,
      onFailure: error => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', {
        type: 'warning',
        messageArgs: {
          _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
        },
      }),
    },
  );

  const totalPages = Math.ceil(total / query.perPage) || 1;

  useEffect(() => {
    if (disabledAutoRefetch) return;
    if (query.page <= 0 || (!loading && query.page > 1 && ids.length === 0)) {
      // Query for a page that doesn't exist, set page to 1
      queryModifiers.setPage(1);
    } else if (!loading && query.page > totalPages) {
      // Query for a page out of bounds, set page to the last existing page
      // It occurs when deleting the last element of the last page
      queryModifiers.setPage(totalPages);
    }
  }, [loading, query.page, ids, queryModifiers, total, totalPages, disabledAutoRefetch]);

  const currentSort = useMemo(
    () => ({
      field: query.sort,
      order: query.order,
    }),
    [query.sort, query.order],
  );

  const getResourceLabel = useGetResourceLabel();
  const defaultTitle = translate('ra.page.list', {
    name: getResourceLabel(resource, 2),
  });

  return {
    basePath,
    currentSort,
    data,
    defaultTitle,
    displayedFilters: query.displayedFilters,
    error,
    exporter,
    filter,
    filterValues: query.filterValues,
    hasCreate,
    hideFilter: queryModifiers.hideFilter,
    ids,
    loaded: loaded || ids.length > 0,
    loading,
    onSelect: selectionModifiers.select,
    onToggleItem: selectionModifiers.toggle,
    onUnselectItems: selectionModifiers.clearSelection,
    page: query.page,
    perPage: query.perPage,
    refetch,
    resource,
    selectedIds,
    setFilters: queryModifiers.setFilters,
    setPage: queryModifiers.setPage,
    setPerPage: queryModifiers.setPerPage,
    setSort: queryModifiers.setSort,
    showFilter: queryModifiers.showFilter,
    total,
  };
};

export const injectedProps = [
  'basePath',
  'currentSort',
  'data',
  'defaultTitle',
  'displayedFilters',
  'error',
  'exporter',
  'filterValues',
  'hasCreate',
  'hideFilter',
  'ids',
  'loading',
  'loaded',
  'onSelect',
  'onToggleItem',
  'onUnselectItems',
  'page',
  'perPage',
  'refetch',
  'refresh',
  'resource',
  'selectedIds',
  'setFilters',
  'setPage',
  'setPerPage',
  'setSort',
  'showFilter',
  'total',
  'totalPages',
  'version',
];

/**
 * Select the props injected by the useCustomListController hook
 * to be passed to the List children need
 * This is an implementation of pick()
 */
export const getListControllerProps = props => injectedProps.reduce(
  (acc, key) => ({
    ...acc,
    [key]: props[key],
  }),
  {},
);

/**
 * Select the props not injected by the useCustomListController hook
 * to be used inside the List children to sanitize props injected by List
 * This is an implementation of omit()
 */
export const sanitizeListRestProps = props => Object.keys(props)
  .filter(propName => !injectedProps.includes(propName))
  .reduce(
    (acc, key) => ({
      ...acc,
      [key]: props[key],
    }),
    {},
  );

export default useCustomListController;
