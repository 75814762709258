import React, { useState } from 'react';
import {
  Box,
  Button,
  Fade,
  useTheme,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  required,
  FormDataConsumer,
  useTranslate,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { isObject, get } from 'lodash';
import {
  TextInput,
} from '../../../../base/components/ra/inputs';
import { SettingLabel } from '../SettingWrapper';
import IpsComponent from './IpsComponent';

export const WhiteListForm = props => {
  const { source } = props;
  const muiTheme = useTheme();

  const form = useForm();
  const translate = useTranslate();

  const [displayWhitelistForm, setDisplayWhitelistForm] = useState(() => {
    const formValues = form.getState().values;
    const whitelist = get(formValues, source);
    if (isObject(whitelist)) {
      return true;
    }
    return false;
  });

  const handleAddWhitelistClick = () => {
    setDisplayWhitelistForm(true);
  };

  const handleClearWhitelist = () => {
    form.change(source, undefined);
    setDisplayWhitelistForm(false);
  };

  return (
    <Box position="relative">
      <SettingLabel
        style={{
          color: muiTheme.palette.primary.dark,
        }}
      >
        {translate('ra.text.whitelistSetting')}
      </SettingLabel>
      {!displayWhitelistForm ? (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Button
            color="primary"
            variant="contained"
            style={{
              width: '200px',
            }}
            onClick={handleAddWhitelistClick}
          >
            {translate('ra.action.addWhitelist')}
          </Button>
        </Box>
      ) : (
        <Box
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            cursor: 'pointer',
          }}
          onClick={handleClearWhitelist}
        >
          <CancelIcon htmlColor={muiTheme.palette.error.main} />
        </Box>
      )}
      <Fade
        in={displayWhitelistForm}
        timeout={1000}
      >
        <Box>
          {displayWhitelistForm && (
            <FormDataConsumer>
              {() => (
                <>
                  <TextInput
                    fullWidth
                    label="resources.white-list.fields.name"
                    source={`${source}.name`}
                    validate={required()}
                  />
                  <TextInput
                    fullWidth
                    label="resources.white-list.fields.notes"
                    source={`${source}.note`}
                  />
                  <IpsComponent
                    source={`${source}.ips`}
                  />
                </>
              )}
            </FormDataConsumer>
          )}
        </Box>
      </Fade>
    </Box>
  );
};

WhiteListForm.defaultProps = {};

WhiteListForm.propTypes = {
  source: PropTypes.string.isRequired,
};
