import * as React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const SYMBOL_PLACE = {
  PREFIX: 'PREFIX',
  SUFFIX: 'SUFFIX',
};

const printAmountWithSymbol = ({
  amount, currency,
}) => {
  if (!currency) {
    return amount;
  }

  const {
    symbol, symbolPlace,
  } = currency;

  switch (symbolPlace) {
    case SYMBOL_PLACE.PREFIX:
      return `${symbol} ${amount}`;
    case SYMBOL_PLACE.SUFFIX:
      return `${amount} ${symbol}`;
    default:
      return `${symbol} ${amount}`;
  }
};

const AmountField = props => {
  const {
    record, source,
  } = props;

  const currency = get(record, 'currency');
  const amount = get(record, source);

  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `${printAmountWithSymbol({
          amount,
          currency,
        })}`,
      }}
    />
  );
};

AmountField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
AmountField.defaultProps = {
  record: {},
};

export default React.memo(AmountField);
