import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';
import { CURRENCY_TYPES } from '../../../../constant';

const ConsolidatedInput = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <FormField name={source}>
      {() => (
        <ReferenceInput
          label={translate('resources.report.fields.consolidated')}
          source={source}
          reference="currency"
          filter={{
            enabled: true,
            skipACL: true,
            type: CURRENCY_TYPES.fiat,
          }}
          filterToQuery={value => ({
            'code||$cont': value,
          })}
          sort={{
            field: 'code',
            order: 'ASC',
          }}
          perPage={100}
          {...otherProps}
        >
          <AutocompleteInput
            optionText="code"
            resettable
          />
        </ReferenceInput>
      )}
    </FormField>
  );
};

ConsolidatedInput.propTypes = {
  source: PropTypes.string,
};

ConsolidatedInput.defaultProps = {
  source: 'consolidated',
};

export default ConsolidatedInput;
