import { Box, alpha } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import React from "react";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";
import CmtMediaObject from "../../../../../../@coremat/CmtMediaObject";
import { getDateElements } from "../../../../../utils/dateHelper";

const useStyles = makeStyles((theme) => ({
  feedItemRoot: {
    padding: theme.spacing(2, 0),
    position: "relative",
    cursor: "pointer",
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.035)}`,
    "& .Cmt-media-object": {
      alignItems: "center",
    },
    "& .Cmt-media-image": {
      alignSelf: "flex-start",
      width: 56,
    },
    "& .Cmt-media-body": {
      width: "calc(100% - 56px)",
      flex: "inherit",
    },
    "& .Cmt-media-header-content": {
      width: "calc(100% - 75px)",
      flex: "inherit",
    },
    "& .Cmt-media-actions": {
      textAlign: "right",
    },
    "&:first-child": {
      borderTop: `1px solid ${alpha(theme.palette.common.dark, 0.035)}`,
    },
  },
  unRead: {
    "&::before": {
      content: "''",
      width: `calc(100% + ${theme.spacing(8)}px)`,
      height: "100%",
      backgroundColor: "#f9f5ff",
      position: "absolute",
      top: 0,
      left: theme.spacing(-4),
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    fontSize: 14,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  subTitleRoot: (props) => ({
    letterSpacing: 0.4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: props.normal ? "normal" : "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 0,
  }),
}));

const MessageItem = ({ item, subTitleNormal, onClick }) => {
  const classes = useStyles({
    normal: subTitleNormal,
  });

  return (
    <Box
      className={clsx(classes.feedItemRoot, !item.read && classes.unRead)}
      onClick={onClick}
    >
      <CmtMediaObject
        avatarPos="center"
        avatar={
          <CmtAvatar
            size={40}
            src={item.user.avatar?.blobUrl || "/images/logo-system-white.png"}
            alt={item.user.firstName}
          />
        }
        title={item.subject}
        titleProps={{
          className: classes.titleRoot,
        }}
        subTitle={item.message}
        subTitleProps={{
          className: classes.subTitleRoot,
        }}
        actionsComponent={
          <Box fontSize={12} color="text.disabled">
            {getDateElements(item.created).time}
          </Box>
        }
      />
    </Box>
  );
};

export default MessageItem;
