import React, { useMemo } from 'react';
import { GAME_FEATURE_VALUE_TYPES } from '../../../constant';
import { SelectInput } from '../../../base/components/ra/inputs';

const ValueTypeSelector = props => {
  const choices = useMemo(
    () => [
      ...Object.values(GAME_FEATURE_VALUE_TYPES).map(item => ({
        id: item,
        name: item,
      })),
    ],
    [],
  );

  return (
    <SelectInput
      variant="outlined"
      label="resources.game-feature.fields.valueType"
      choices={choices}
      {...props}
      options={{
        SelectProps: {
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        },
      }}
    />
  );
};

ValueTypeSelector.propTypes = {};

ValueTypeSelector.defaultProps = {};

export default ValueTypeSelector;
