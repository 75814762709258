/* eslint-disable */
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Box, Grid, Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import clsx from 'clsx';
import { useStyles } from '../../guesser/report/search-panel';
import ExportButton from './ExportButton';
import { useReportContext } from '../ReportLayout';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { sanitizeObject } from '../../../../services/util';
import queryString from 'query-string';

const ReportFilterWrapper = ({ children, actions, exportButtonProps, generateResultButtonProps, ...otherProps }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  const { pathname } = useLocation();
  const { data, appliedFilters, query, setLoading } = useReportContext();

  const handleFilter = useCallback(
    (formValues) => {
      const params = queryString.stringify(sanitizeObject(formValues), { arrayFormat: 'index' });

      setLoading(true);

      history.push({
        pathname,
        search: params.toString(),
      });
    },
    [pathname],
  );

  return (
    <Form
      initialValues={appliedFilters}
      onSubmit={handleFilter}
      render={({ handleSubmit, dirty }) => {
        const hasNoChange = !dirty && Boolean(Object.keys(query)?.length);

        const hasNoData =
          Object.values(data).reduce((totalNoDataTable, table) => {
            if (!table?.total) {
              return totalNoDataTable + 1;
            }
            return totalNoDataTable;
          }, 0) === Object.values(data).length;

        return (
          <Box
            component="form"
            className={classes.formStyle}
            width="100%"
            display="flex"
            flexDirection="column"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={1} display="flex">
              {children}
            </Grid>
            {actions || (
              <Box display="flex" alignItems="center" my={3}>
                <Button
                  type="submit"
                  disabled={hasNoChange}
                  {...generateResultButtonProps}
                  className={clsx(`${classes.btn} search-panel__export-button`, generateResultButtonProps?.className)}
                >
                  {translate('resources.user.text.report')}
                </Button>
                <ExportButton disabled={hasNoData} {...exportButtonProps} />
              </Box>
            )}
          </Box>
        );
      }}
      {...otherProps}
    />
  );
};

ReportFilterWrapper.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  exportButtonProps: PropTypes.object,
  generateResultButtonProps: PropTypes.object,
};

ReportFilterWrapper.defaultProps = {
  actions: null,
  children: null,
  exportButtonProps: {},
  generateResultButtonProps: {},
};

export default ReportFilterWrapper;
