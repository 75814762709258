/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import GridContainer from '../../base/@crema/core/GridContainer';
import StreamerAdmin from './components/streamer-admin';
import { useAuthUser } from '../../base/hooks';
import { RoleBuiltIn } from '../../services/util';
import { useReloadContext } from '../../base/context/reload/reload.context';
import ChartWrapper from './components/chart-wrapper';
import ChartUnderConstruction from './components/chart-under-construction';

function Dashboard(props) {
  const user = useAuthUser();
  const { setValuesContext } = useReloadContext();

  useEffect(
    () => () => {
      setValuesContext({});
    },
    [],
  );

  if (!user?.role?.id) return null;

  return (
    <Box
      component={GridContainer}
      flexGrow={1}
    >
      <Grid
        item
        xs={12}
      >
        {{
          [RoleBuiltIn.STREAMER_ADMIN]: <StreamerAdmin {...props} />,
          [RoleBuiltIn.SYSTEM_ADMIN]: <ChartWrapper />,
        }[user.role.id] || <ChartUnderConstruction />}
      </Grid>
    </Box>
  );
}

export default Dashboard;
