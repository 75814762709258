/* eslint-disable import/no-cycle */
import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { usePermissions } from 'react-admin';
import ApproveButton from './ApproveButton';
import ActivateButton from './ActivateButton';
import DuplicateButton from './DuplicateButton';
import resourceSlug from '../../../../constant/resource-slug';
import { useAuthUser } from '../../../../base/hooks';

export const useStyles = makeStyles(theme => ({
  button: {
    padding: '6px 16px',
    width: '100%',
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
}));

function ExtraActions(props) {
  const { record } = props;
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasPrepaidApprovalPermissionToUpdate = user?.superman || (Array.isArray(permissions) && !!permissions?.find(
    item => item.resource.name === resourceSlug.PREPAID_APPROVAL,
  )?.update);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        '& button': {
          minWidth: 150,
          width: 'fit-content',
        },
      }}
    >
      {hasPrepaidApprovalPermissionToUpdate && <ApproveButton {...props} />}
      <ActivateButton {...props} />
      <DuplicateButton prepaidId={record?.id} />
    </Box>
  );
}

ExtraActions.propTypes = {
  record: PropTypes.object,
};

ExtraActions.defaultProps = {
  record: null,
};

export default ExtraActions;
