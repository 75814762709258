/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
class StateStory {
  currentState = null;

  olderState = null;

  constructor(initialValue) {
    this.currentState = initialValue;
  }

  setStoryState = newState => {
    const inputValueString = JSON.stringify(newState);
    const currentStoryString = JSON.stringify(this.currentState);

    // The `input newState` is a new state
    // => update new `story` and log `older story`
    if (inputValueString !== currentStoryString) {
      // Log older story
      this.olderState = this.currentState;
      // Update new `story`
      this.currentState = newState;
    }
  };

  // Compare `input state` with `current story`
  sameWithCurrentState = state => {
    const inputValueString = JSON.stringify(state);
    const currentStoryString = JSON.stringify(this.currentState);
    return inputValueString === currentStoryString;
  };

  // Compare `input state` with `older state`
  sameWithOlderState = state => {
    const inputValueString = JSON.stringify(state);
    const olderStoryString = JSON.stringify(this.olderState);
    return inputValueString === olderStoryString;
  };

  // Get current State
  getCurrentState = () => this.currentState;

  // Get older state
  getOlderState = () => this.olderState;
}

let story = {};
export function makeStateStory(key) {
  const invokeAction = (action, ...rest) => {
    if (story[key] && typeof story[key][action] === 'function') {
      const _this = story[key];
      return _this[action].call(_this, ...rest);
    }
    return null;
  };

  return {
    setStoryState(value) {
      if (story[key]) {
        invokeAction('setStoryState', value);
      } else {
        story[key] = new StateStory(value);
      }
    },
    getCurrentState() {
      return invokeAction('getCurrentState');
    },
    getOlderState() {
      return invokeAction('getOlderState');
    },
    sameWithCurrentState(newState) {
      return invokeAction('sameWithCurrentState', newState);
    },
    sameWithOlderState(newState) {
      return invokeAction('sameWithOlderState', newState);
    },
  };
}

makeStateStory.prototype.clearAllStory = () => {
  story = {};
};

export default StateStory;
