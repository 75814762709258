/* eslint-disable */
import React from "react";
import { SimpleForm, useTranslate } from "react-admin";
import { Create } from "../../base/components/ra/views";
import { useAuthUser, useCreateCustom } from "../../base/hooks";
import rcSlug from "../../constant/resource-slug";

import { BetSettingFormProvider } from "./utils/bet-setting-form.context";
import BetSettingCreateForm from "./components/bet-setting-create-form";
import { checkSystemAdmin } from "../../services/util";

const BetSettingDefaultCreate = (props) => {
  const translate = useTranslate();
  const user = useAuthUser();

  const { create } = useCreateCustom({
    resource: rcSlug.BET_SETTING,
    resourceTranslated: translate(`resources.${rcSlug.BET_SETTING}.name`),
  });

  const isSystemAdmin = checkSystemAdmin(user);

  const createBetSetting = (values) => {
    const {
      group,
      brand,
      game,
      currency,
      totalBetMin,
      totalBetMax,
      ...restValue
    } = values;
    if (
      !Array.isArray(restValue?.picker?.listItem?.betSize) ||
      !Array.isArray(restValue?.picker?.listItem?.betLevel) ||
      isNaN(restValue?.picker?.defaultValue?.betSize) ||
      isNaN(restValue?.picker?.defaultValue?.betLevel)
    ) {
      return;
    }

    const betSizes = restValue.picker.listItem.betSize.map((item) => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betSize,
    }));

    const betLevels = restValue.picker.listItem.betLevel.map((item) => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betLevel,
    }));

    const createPayload = {
      resource: rcSlug.BET_SETTING,
      payload: {
        data: {
          brand,
          game,
          currency,
          totalBetLimit: [totalBetMin, totalBetMax],
          betSizes: betSizes,
          betLevels: betLevels,
        },
      },
    };

    // System admin need to choose a group
    if (isSystemAdmin) {
      createPayload.payload.data.group = group;
    }

    create(createPayload);
  };

  return (
    <Create
      {...props}
      alwaysEnableSaveButton={true}
      onCustomSave={createBetSetting}
    >
      <SimpleForm redirect="show">
        <BetSettingFormProvider>
          <BetSettingCreateForm />
        </BetSettingFormProvider>
      </SimpleForm>
    </Create>
  );
};

export default BetSettingDefaultCreate;
