import React from 'react';
import PropTypes from 'prop-types';
import { ChipField as RAChipField } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    '& > svg': {
      fontSize: '1.2rem',
    },
  },
  disabledItem: {
    color: theme.palette.error.main,
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: 6,
    '& [class^="MuiChip-label"]': {
      whiteSpace: 'nowrap',
    },
  },
}));

const ChipField = props => {
  const {
    record, source, resource,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  // Because disabled games won't be displayed on available game page (Not only warning)
  const isDisabledGameInAvailbleResource = resource === 'available-game' && !record?.enabled;
  if (!get(record, source) || isDisabledGameInAvailbleResource) return null;

  const generateTooltipMessage = () => {
    const gameTranslated = translate('resources.game.name');

    return translate('ra.text.disableTooltipMessage', {
      smart_text: gameTranslated.toLowerCase(),
    });
  };

  if (resource?.includes('game') && !record?.enabled) {
    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <RAChipField
          {...props}
          className={classes.disabledItem}
          icon={(
            <Tooltip
              title={generateTooltipMessage()}
              placement="top-start"
            >
              <Box className={classes.icon}>
                <ErrorIcon />
              </Box>
            </Tooltip>
          )}
        />
      </Box>
    );
  }

  return <RAChipField {...props} />;
};

ChipField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  resource: PropTypes.string,
};

ChipField.defaultProps = {
  record: {},
  resource: undefined,
};

export default ChipField;
