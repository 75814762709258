/* eslint-disable  */
import React, { useMemo } from 'react';
import { startCase, get, isObject, set } from 'lodash';
import { ReferenceField, TextField, useListContext, useLocale, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import RowActionField from '../../ra/fields/row-action-field/row-action.field';
import Datagrid from '../../ra/data-grid';
import { IdField, CurrencyField, NumberField, DateField, DecimalField, BooleanField } from '../../ra/fields';
import { useAuthUser } from '../../../hooks';
import EmptyTable from './emptyTable';
import { useSelector } from 'react-redux';
import { DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT, ID_EMPTY } from '../../../../constant';
import CustomLinkField from '../../ra/fields/customLink.field';
import { transformIdValue } from '../../../../services/util/stringUtil';
import { Box, CircularProgress } from '@material-ui/core';

const CustomTextField = (props) => {
  let { record, translatable, source } = props;
  const locale = useLocale();

  if (translatable) {
    const value = get(record, source);

    try {
      if (typeof value === 'string') {
        set(record, source, JSON.parse(value));
      }

      source = `${source}.${locale}`;
    } catch {
      //
    }
  }

  return <TextField {...props} source={source} />;
};

export default function DatagridContainer(dProps) {
  const { response, defaultCurrency, extraActions, excludeSearchFields, excludeFields, ...rest } = dProps;

  const { data, ids, loading } = useListContext();
  const currenciesList = useSelector(({ admin }) => admin.resources.currency.data);

  const reportData = useMemo(() => {
    const rawData = { ...get(data, ids[0], {}) };

    excludeFields?.forEach((excludedKey) => delete rawData[excludedKey]);

    return rawData;
  }, [data, excludeFields]);
  const translate = useTranslate();
  const { role } = useAuthUser();

  const arrangeList = ([a, b], type = 'ASC') => {
    const indexA = response?.[a]?.index || 0;
    const indexB = response?.[b]?.index || 0;
    return type === 'ASC' ? indexA - indexB : indexB - indexA;
  };

  const getFieldComponent = (key) => {
    const {
      type,
      options,
      format,
      label: customLabel,
      reference,
      optionText,
      hidden,
      showSecond,
      sortable,
      translatable,
    } = response?.[key] || {};

    if (hidden) {
      return null;
    }

    let label = startCase(translate(customLabel || `resources.report.fields.${key}`));

    // Quick fix: Add the consolidated as the suffix of the GGR consolidated field title
    const targetCurrencyCode =
      currenciesList?.[reportData.targetCurrencyId]?.code || DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT;
    if (key === 'GGRConsolidated') {
      label += ` (${targetCurrencyCode})`;
    }

    if (format === 'date') {
      return <DateField source={key} key={key} label={label} showTime showSecond={showSecond} sortable={sortable} />;
    }

    if (key === 'wagerId' || key === 'playerId') {
      return (
        <CustomLinkField
          key={key}
          source={key}
          href={(record) => `/#/${reference}/${record[key]}/show`}
          title={(record) => (record[key] ? transformIdValue(record[key]) : ID_EMPTY)}
          sortable={sortable}
        />
      );
    }

    if (reference) {
      return (
        <ReferenceField key={key} reference={reference} source={key} link="show" label={label} sortable={sortable}>
          {format === 'id' ? (
            <IdField source={optionText || key} key={key} label={label} />
          ) : (
            <CustomTextField source={optionText} translatable={translatable} />
          )}
        </ReferenceField>
      );
    }

    if (['group', 'brand'].includes(key)) {
      return (
        <ReferenceField key={key} reference={key} source={key} link={false} label={label} sortable={sortable}>
          <TextField source="name" />
        </ReferenceField>
      );
    }

    if (type === 'number') {
      if (format === 'default_currency' && defaultCurrency?.id) {
        return <CurrencyField key={key} source={key} currency={defaultCurrency} sortable={sortable} />;
      }

      if (format === 'currency') {
        return <CurrencyField key={key} source={key} currency={null} sortable={sortable} />;
      }

      return <NumberField key={key} source={key} sortable={sortable} />;
    }

    if (type === 'decimal') {
      return <DecimalField key={key} source={key} options={options} sortable={sortable} label={label} />;
    }

    if (type === 'boolean') {
      return <BooleanField key={key} source={key} label={label} sortable={sortable} />;
    }

    return key !== 'id' ? (
      <CustomTextField
        label={key === 'rtp' || key?.indexOf('Trend') > 0 ? `${label} (%)` : label}
        key={key}
        source={key}
        sortable={sortable}
        translatable={translatable}
      />
    ) : null;
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" width="100%" p={3} mt={3}>
          <CircularProgress size={28} disableShrink />
        </Box>
      ) : ids?.length > 0 ? (
        <Datagrid {...rest}>
          {/* Render list field rely on some params */}
          {Object.keys(reportData)
            ?.sort((a, b) => arrangeList([a, b], 'ASC'))
            ?.map((key) => getFieldComponent(key))}
          {extraActions && isObject(reportData) && (
            <RowActionField
              label={translate('ra.field.action')}
              hasDelete={false}
              extraActions={extraActions}
              source="action"
              sortable={false}
              record={{
                ...reportData,
              }}
              role={role}
            />
          )}
        </Datagrid>
      ) : (
        <EmptyTable />
      )}
    </>
  );
}

DatagridContainer.propTypes = {
  defaultCurrency: PropTypes.object,
  response: PropTypes.object,
  extraActions: PropTypes.node,
};

DatagridContainer.defaultProps = {
  defaultCurrency: undefined,
  response: undefined,
  extraActions: undefined,
};
