/* eslint-disable  */
import React, { useState, useEffect } from "react";
import {
  FormDataConsumer,
  required,
  useDataProvider,
  ReferenceArrayInput,
  useTranslate,
  useNotify,
  useRedirect,
} from "react-admin";
import { get } from "lodash";
import { useFormState, useForm } from "react-final-form";
import WealthCreateGuesser from "../../base/components/guesser/wealth-create.guesser";
import WealthInputWrapper from "../../base/components/guesser/wealth-input-wrapper";
import CheatModeApplyGame from "./components/game.create";
import {
  AutocompleteInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteArrayInput,
} from "../../base/components/ra/inputs";
import { useAuthUser } from "../../base/hooks";
import { RoleBuiltIn } from "../../services/util";
import { TooltipForDisabledInput } from "../../base/components/custom-tooltip";
import { makeStateStory } from "../../services/util/makeStateStory";
import { useTitleContext } from "../../base/context/title";

const TempState = (props) => {
  const form = useForm();
  const { values: formValues, modified } = useFormState();
  const stateStoryKey = `NORMAL-FORM-cheat-mode-apply`;

  const sourceFilterList = {
    game: {
      "group.id": true,
      "brand.id": true,
    },
    cheatModeTemplate: {
      "game.id": true,
      "group.id": true,
      "brand.id": true,
    },
    [props.enabledBulkCreate ? "players" : "player"]: {
      "group.id": true,
      "brand.id": true,
      // "game.id": true,
      // "cheatModeTemplate.id": true,
    },
  };

  useEffect(() => {
    return () => {
      form.change("player", undefined);
      form.change("players", undefined);
    };
  }, []);

  return Object.keys(sourceFilterList).map((source) => {
    return (
      <FormDataConsumer>
        {() => {
          const { getOlderState } = makeStateStory(stateStoryKey);

          const olderState = getOlderState();

          const sourceFilterUpdated = Object.keys(
            sourceFilterList[source]
          )?.some((item) => {
            const currentValue = get(formValues, item);
            const olderValue = get(olderState, item);
            const isModified = get(modified, item);

            return (
              isModified &&
              JSON.stringify(currentValue) !== JSON.stringify(olderValue)
            );
          });

          // Source filterOn unSelect or update value => this field clear data
          if (sourceFilterUpdated && formValues[source]) {
            form.change(source, undefined);
          }

          return null;
        }}
      </FormDataConsumer>
    );
  });
};

const PlayerInput = ({
  enabledBulkCreate,
  getResourceNameTranslation,
  ...rest
}) => {
  const { values: formValues } = useFormState();
  const [playerIds, setPlayerIds] = useState("");
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const getData = async (groupId, brandId, gameId, cheatModeTemplateId) => {
    try {
      const { data } = await dataProvider.getAll("cheat-mode-apply", {
        filter: {
          "group.id": groupId,
          "brand.id": brandId,
          "game.id": gameId,
          "cheatModeTemplate.id": cheatModeTemplateId,
        },
      });
      if (data?.length) {
        const playerIds = data
          .map((cheatModeApply) => cheatModeApply.player.id)
          .join(",");
        setPlayerIds(playerIds);
      }
    } catch {
      //
    }
  };

  useEffect(() => {
    const {
      group = {},
      brand = {},
      game = {},
      cheatModeTemplate = {},
    } = formValues;

    if (group.id && brand.id && game.id && cheatModeTemplate.id) {
      // getData(group.id, brand.id, game.id, cheatModeTemplate.id);
    }
  }, [
    formValues.group?.id,
    formValues.brand?.id,
    formValues.game?.id,
    formValues.cheatModeTemplate?.id,
  ]);

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const filter = {
          groupId: formData.group?.id,
          brandId: formData.brand?.id,
          "id||$notin": playerIds,
        };

        const {
          group = {},
          brand = {},
          game = {},
          cheatModeTemplate = {},
        } = formData;

        const enabled = group.id && brand.id;
        // const enabled = group.id && brand.id && game.id && cheatModeTemplate.id;

        const commonProps = {
          label: "resources.player.name",
          reference: "player",
          perPage: 50,
          filterToQuery: (value) => ({
            nativeId: value || "",
          }),
          disabled: !enabled,
          filter,
          validate: [required()],
          sort: {
            field: "nativeId",
            order: "ASC",
          },
        };

        const listResourceNameTranslated = getResourceNameTranslation([
          "group",
          "brand",
          // "game",
          // "cheat-mode-template",
        ]);

        const disabledInputMessage = translate("ra.message.pleaseSelectFirst", {
          smart_name: listResourceNameTranslated?.join(", "),
        });

        const tooltipDisabledInputProps = {
          title: disabledInputMessage,
          placement: "top-start",
          showMessage: !enabled,
        };

        return (
          <TooltipForDisabledInput {...tooltipDisabledInputProps}>
            {enabledBulkCreate ? (
              <ReferenceArrayInput
                {...rest}
                source="players"
                parse={(ids) =>
                  ids?.map((id) => ({
                    id,
                  }))
                }
                format={(data) => data?.map((d) => d.id)}
                {...commonProps}
              >
                <AutocompleteArrayInput
                  optionText="nativeId"
                  enabledSelectAll={false}
                />
              </ReferenceArrayInput>
            ) : (
              <ReferenceInput {...rest} source="player.id" {...commonProps}>
                <AutocompleteInput optionText="nativeId" />
              </ReferenceInput>
            )}
          </TooltipForDisabledInput>
        );
      }}
    </FormDataConsumer>
  );
};

const CheatModeApplyCreate = (props) => {
  const user = useAuthUser();

  const enabledBulkCreate = [
    RoleBuiltIn.SYSTEM_ADMIN,
    RoleBuiltIn.GROUP_ADMIN,
    RoleBuiltIn.BRAND_ADMIN,
    RoleBuiltIn.STREAMER_ADMIN,
  ].includes(user?.role?.id);

  const translate = useTranslate();

  const getResourceNameTranslation = (resourceList) =>
    resourceList.map((item) => {
      const realResourceName = item;
      return translate(`resources.${realResourceName}.name`);
    });

  const { setTitle } = useTitleContext();

  const pageTitle = translate("resources.cheat-mode-apply.name");
  const pageNote = translate("resources.cheat-mode-apply.text.note");

  useEffect(() => {
    const element = (
      <div>
        <div>{pageTitle}</div>
        <div
          style={{
            fontSize: "14px",
            marginTop: "5px",
          }}
        >
          {pageNote}
        </div>
      </div>
    );
    setTitle(element);

    return function cleanUp() {
      setTitle(null);
    };
  }, [pageTitle, pageNote]);

  return (
    <WealthCreateGuesser
      {...props}
      customFields={[
        "game",
        "cheatModeTemplate",
        "players",
        "player",
        "systemBulkCreateTemplate",
        "systemBulkCreatePlayer",
      ]}
    >
      <WealthInputWrapper source="game.id">
        <CheatModeApplyGame
          getResourceNameTranslation={getResourceNameTranslation}
        />
      </WealthInputWrapper>

      {enabledBulkCreate && (
        <BooleanInput
          label={`resources.cheat-mode-apply.fields.isAllTemplates`}
          fullWidth
          source="systemBulkCreateTemplate"
          defaultValue={true}
        />
      )}

      <FormDataConsumer>
        {({ formData }) => {
          if (formData.systemBulkCreateTemplate) {
            return null;
          }

          const filter = formData.game?.id
            ? {
                "game.id": formData.game.id,
              }
            : {};

          const listResourceNameTranslated = getResourceNameTranslation([
            "game",
          ]);

          const disabledInputMessage = translate(
            "ra.message.pleaseSelectFirst",
            {
              smart_name: listResourceNameTranslated?.join(", "),
            }
          );

          const inputDisabledByFilterOn = !formData.game?.id;

          const tooltipDisabledInputProps = {
            title: disabledInputMessage,
            placement: "top-start",
            showMessage: inputDisabledByFilterOn,
          };

          return (
            <TooltipForDisabledInput {...tooltipDisabledInputProps}>
              <ReferenceInput
                source="cheatModeTemplate.id"
                label="resources.cheat-mode-apply.fields.cheatModeTemplate"
                reference="cheat-mode-template"
                disabled={inputDisabledByFilterOn}
                filter={filter}
                validate={[required()]}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </TooltipForDisabledInput>
          );
        }}
      </FormDataConsumer>
      {enabledBulkCreate && (
        <BooleanInput
          label={`resources.cheat-mode-apply.fields.isAllPlayers`}
          fullWidth
          source="systemBulkCreatePlayer"
          defaultValue={true}
        />
      )}
      <FormDataConsumer>
        {({ formData }) =>
          !formData.systemBulkCreatePlayer && (
            <PlayerInput
              enabledBulkCreate={enabledBulkCreate}
              {...props}
              getResourceNameTranslation={getResourceNameTranslation}
            />
          )
        }
      </FormDataConsumer>
      <TempState enabledBulkCreate={enabledBulkCreate} />
    </WealthCreateGuesser>
  );
};

export default CheatModeApplyCreate;
