import { addExportListener } from './exporters.util';

const reportPlayerExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;

  const brand = await fetchRelatedRecords(records, 'brand', 'brand');

  const reportPlayerForExport = records.map(data => {
    const result = {
      id: data.id,
      brand: brand[data.brand]?.name,
      playerId: data.playerId,
      nativeId: data.nativeId,
      winnings: data.winnings?.toString?.()?.replace?.('.', ','),
      betCount: data.betCount,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
      wonAmount: data.wonAmount?.toString?.()?.replace?.('.', ','),
      currency: data.currency,
      rtp: data.rtp?.toString?.()?.replace?.('.', ','),
    };

    return result;
  });

  addExportListener({
    dataList: reportPlayerForExport,
    dateString: date,
    fileName: 'Player Report',
  });
};

export default reportPlayerExporter;
