import {
  TOGGLE_LOADING,
  GET_ALLOWED_RESOURCES_SUCCESS,
  STORE_DETAIL_NAME,
  SHOW_CONFIRM_DIALOG,
} from '../root.actions';

export const initialApps = {
  isLoading: false,
  allowedResources: {},
  detailName: '',
  confirmDialog: {
    isOpen: false,
    title: '',
    content: '',
    onConfirm: f => f,
    onClose: f => f,
  },
};

export const appReducer = (state = initialApps, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_ALLOWED_RESOURCES_SUCCESS:
      return {
        ...state,
        allowedResources: action.allowedResources,
      };
    case STORE_DETAIL_NAME:
      return {
        ...state,
        detailName: action.detailName,
      };
    case SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDialog: action.payload,
      };
    default:
      return state;
  }
};
