import React from 'react';
import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';

const DynamicRefField = ({
  link, record = {},
}) => {
  const {
    recordId,
    resource: { name: resourceName } = {},
  } = record;

  if (!link || !record || !recordId || !resourceName) {
    return null;
  }

  return (
    <Link
      href={`/#/${resourceName}/${recordId}/${link}`}
      underline="none"
    >
      {recordId}
    </Link>
  );
};

DynamicRefField.propTypes = {
  link: PropTypes.string,
  record: PropTypes.object,
};

DynamicRefField.defaultProps = {
  link: '',
  record: null,
};

export default DynamicRefField;
