import React from 'react';
import { ReferenceArrayInput } from 'react-admin';
import { AutocompleteArrayInput } from '../ra/inputs';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';

const CurrenciesReference = props => (
  <ReferenceArrayInput
    fullWidth
    label="resources.brand.fields.currencies"
    reference="currency"
    filterToQuery={value => ({
      name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
    })}
    parse={ids => ids.map(id => ({
      id,
    }))}
    format={data => data?.map(d => d.id)}
    source="currencies"
    perPage={500} // To get all currencies (500 >>> total number of current supported currencies)
    {...props}
  >
    <AutocompleteArrayInput
      variant="outlined"
      optionText="name"
    />
  </ReferenceArrayInput>
);

export default CurrenciesReference;
