import React from 'react';
import { SelectInput } from 'react-admin';
import { PREPAID_ISSUER } from '../../../constant';

const IssuerSelector = props => (
  <SelectInput
    variant="outlined"
    source="issuer"
    choices={Object.values(PREPAID_ISSUER).map(issuer => ({
      id: issuer,
      name: issuer,
    }))}
    label="resources.prepaid.fields.issuer"
    {...props}
  />
);

export default IssuerSelector;
