/* eslint-disable react/prop-types */
import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../base/components/ra/labeled';
import RecordIdField from './component/RecordIdField';

const RecordShow = props => (
  <WealthShowGuesser
    excludeFields={['recordId']}
    informFieldOrder={['groupId', 'brandId', 'name', 'desc', 'module', 'recordId', 'count', 'claimBefore']}
    extraInformBlocks={[
      <Labeled
        source="recordId"
        label="resources.reward.fields.recordId"
      >
        <RecordIdField />
      </Labeled>,
    ]}
    {...props}
  />
);

export default RecordShow;
