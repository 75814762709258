import { addExportListener } from './exporters.util';

const performanceExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;

  const isFilterBrand = !!records[0]?.brand;
  const isFilterGroup = !!records[0]?.group;
  let brand;
  let group;

  if (isFilterBrand) {
    brand = await fetchRelatedRecords(records, 'brand', 'brand');
  }

  if (isFilterGroup) {
    group = await fetchRelatedRecords(records, 'group', 'group');
  }

  const performanceForExport = records.map(data => {
    const result = {
      ...data,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
    };

    if (isFilterBrand) {
      result.brand = brand[data.brand]?.name;
    }

    if (isFilterGroup) {
      result.group = group[data.group]?.name;
    }

    if (data.wonAmount) {
      result.wonAmount = data.wonAmount?.toString?.()?.replace?.('.', ',');
    }

    return result;
  });

  addExportListener({
    dataList: performanceForExport,
    dateString: date,
    fileName: 'Performance Report',
  });
};

export default performanceExporter;
