// TODO: Temporarily use auto-render reports
// /* eslint-disable */
// import { Loading, getResources } from 'react-admin';
// import React, { useMemo } from 'react';
// import { useSelector } from 'react-redux';
// import WinnersReportFilters from './components/WinnersReportFilters';
// import ReportLayout from '../../base/components/reports/ReportLayout';
// import WinnersReportResults from './components/WinnersReportResults';
// import { REPORT_TYPES } from '../../constant/resource-slug';

// export const LIST_REPORTS = [REPORT_TYPES.OVERALL, REPORT_TYPES.PREPAID, REPORT_TYPES.STANDARD];

// function ReportWinnersList({ resource }) {
//   const originalResources = useSelector(getResources);

//   const resources = useMemo(() => originalResources, [originalResources?.length]);

//   if (!resources?.length) {
//     return null;
//   }

//   const currentResource = resources.filter((r) => r.name === resource)?.[0];
//   if (!currentResource) {
//     return <Loading />;
//   }

//   const {
//     options: { reportInterface },
//   } = currentResource;

//   return (
//     <ReportLayout
//       totalTables={LIST_REPORTS.length}
//       filterChildren={<WinnersReportFilters reportServiceVersion={reportInterface?.serviceVersion} />}
//       resultChildren={<WinnersReportResults />}
//     />
//   );
// }

// export default ReportWinnersList;

// TODO: Remove the below code when apply 3-table result report
import React from 'react';
import ReportWinnersLink from './components/report-winners.link';
import { useAuthUser } from '../../base/hooks';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { isUserSA } from '../../constant';
import { REPORT_TYPES } from '../../constant/resource-slug';

export const LIST_REPORTS = [REPORT_TYPES.OVERALL, REPORT_TYPES.PREPAID, REPORT_TYPES.STANDARD];

function ReportWinnersList(props) {
  const user = useAuthUser();
  const isSystemAdmin = isUserSA(user);

  return (
    <WealthListGuesser
      {...props}
      extraActions={<ReportWinnersLink />}
      defaultRefFilter={
        isSystemAdmin
          ? {
            group: true,
          }
          : {}
      }
    />
  );
}

export default ReportWinnersList;
