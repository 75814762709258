import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useMemo } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, Grid, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { CurrencyDisplayModeEnum } from '../../../constant';

export const THIN_ENABLED_CURRENCIES_FIELD = 'thinEnabledCurrencies';

const useStyles = makeStyles({
  label: {
    fontSize: 12,
    fontWeight: 700,
    color: 'black',
  },
  radioGroup: {
    '& [class*="label"]': {
      fontSize: 13,
    },
    '& [class*="checked"]': {
      color: '#0F417B',
    },
  },
});

const ThinEnabledCurrenciesConfig = ({
  source, selectedCurrencies,
}) => {
  const classes = useStyles();

  const currenciesRedux = useSelector(({ admin }) => admin.resources.currency.data);

  const translate = useTranslate();

  const form = useForm();

  const hasSelectedCurrencies = selectedCurrencies?.length > 0;

  const currenciesList = useMemo(
    () => selectedCurrencies?.map(currency => {
      const currencyId = currency.id;
      const currencyDetails = currenciesRedux?.[currencyId];

      return {
        ...currency,
        ...currencyDetails,
      };
    }),
    [currenciesRedux, selectedCurrencies],
  );

  const hasAtLeastOneThinEnabled = selectedCurrencies?.find(currency => {
    const currencyId = currency.id;

    const currencyDetail = currenciesList.find(item => item.id === currencyId);

    const { isThinEnabled } = currencyDetail;

    return isThinEnabled;
  });

  const handleCurrencyConfigChange = (value, currencyId) => {
    const currencies = selectedCurrencies.map(item => {
      if (item.id === currencyId) {
        return {
          ...item,
          mode: value,
        };
      }
      return item;
    });

    form.change(source, currencies);
  };

  if (!hasSelectedCurrencies || !hasAtLeastOneThinEnabled) return null;

  return (
    <ThinDisplayConfigWrapper source={THIN_ENABLED_CURRENCIES_FIELD}>
      <Grid
        container
        direction="column"
      >
        {selectedCurrencies?.map(currency => {
          const currencyId = currency?.id;

          if (!currencyId) return null;

          const currencyDetail = currenciesList.find(item => item.id === currencyId);

          const { isThinEnabled } = currencyDetail;

          if (!isThinEnabled) return null;

          return (
            <Fragment key={currencyDetail.code}>
              <FormLabel className={classes.label}>{currencyDetail.code}</FormLabel>
              <RadioGroup
                row
                value={currencyDetail.mode || ''}
                className={classes.radioGroup}
                onChange={e => handleCurrencyConfigChange(e.target.value, currencyId)}
              >
                <FormControlLabel
                  value={CurrencyDisplayModeEnum.NORMAL}
                  control={<Radio />}
                  label={translate('resources.brand.text.normalDisplayed')}
                />
                <FormControlLabel
                  value={CurrencyDisplayModeEnum.THIN}
                  control={<Radio />}
                  label={translate('resources.brand.text.prefixedDisplayed')}
                />
              </RadioGroup>
            </Fragment>
          );
        })}
      </Grid>
    </ThinDisplayConfigWrapper>
  );
};

ThinEnabledCurrenciesConfig.propTypes = {
  source: PropTypes.string.isRequired,
  selectedCurrencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      mode: PropTypes.oneOf(Object.values(CurrencyDisplayModeEnum)),
    }),
  ).isRequired,
};

export default ThinEnabledCurrenciesConfig;

const validateCurrencyConfig = (_, allValues) => {
  const { currencies: selectedCurrencies } = allValues;
  const hasAtLeastOneCurrencyNoMode = Boolean(selectedCurrencies?.find(currency => !currency.mode));
  if (hasAtLeastOneCurrencyNoMode) {
    const errors = {
      message: 'ra.validation.required',
    };
    return errors;
  }

  return null;
};

/* eslint-disable */
const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const ThinDisplayConfigWrapper = ({ children, source, ...other }) => {
  const translate = useTranslate();

  const {
    meta: { error, touched: isTouched, submitted: isSubmitted, invalid },
  } = useInput({
    source,
    validate: [validateCurrencyConfig],
  });

  const errorMessage = error?.message;

  const hasErrorMessage = (isTouched || isSubmitted) && invalid;

  return (
    <>
      <TextField
        variant="outlined"
        label={translate('resources.brand.fields.thinEnabledCurrencies')}
        multiline
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: InputComponent,
        }}
        inputProps={{
          children,
        }}
        required
        error={hasErrorMessage}
        {...other}
      />
      <FormHelperText error>
        {hasErrorMessage ? errorMessage && translate(errorMessage) : <span>&#8203;</span>}
      </FormHelperText>
    </>
  );
};

ThinDisplayConfigWrapper.propTypes = {
  source: PropTypes.string.isRequired,
};
