/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Resource } from 'react-admin';
import { StylesProvider } from '@material-ui/styles';
import authProvider from './services/provider/authProvider';
import WealthCreateGuesser from './base/components/guesser/wealth-create.guesser';
import WealthEditGuesser from './base/components/guesser/wealth-edit.guesser';
import WealthListGuesser from './base/components/guesser/wealth-list.guesser';
import WealthShowGuesser from './base/components/guesser/wealth-show.guesser';
import Layout from './theme/App';
import { customRoutes, publicCustomRoutes } from './routes/customRoutes';
import Dashboard from './routes/dashboard/index';
import dataProvider from './services/provider/dataProvider';
import { i18nProvider } from './services/provider/i18n/i18nProvider';
import { canI, RbacAction } from './services/provider/rbacProvider';
import rootReducer from './services/redux/root.reducer';
import { rootSagas } from './services/redux/root.sagas';
import { getRawTheme, breakpoints } from './custom-theme/themeConfig';
import { otherThemeConfigMapping } from './custom-theme/themeMapping';
import CustomCoreAdmin from './custom-core-admin/CustomCoreAdmin';
import {
  formatResources,
  setBodyBackgroundColor,
  setLoadingImage,
  handleFetchingEnvironment,
  getBackofficeEnv,
  RoleBuiltIn,
  isPreStagingEnv,
} from './services/util';
import Cookie from './services/util/handleCookie';
import { forceLogout } from './services/util/handleByHttpCode';
import { STATUS_REQUIRED_CHANGE_PASSWORD } from './constant';
import { appBrandConfigMapping } from './constant/appBrandMapping';
import { initFirebase } from './services/firebase';
import { CUSTOM_RESOURCES } from './constant/customResources';
import { hasAccessPermissionByRoleId } from './constant/customRoutes';
import resourceSlug from './constant/resource-slug';
import LocalStorage, { userStorageKey } from './services/local-storage';

const getCustomResources = async (permissions) => {
  const { data: userData } = await axios.get('/api/auth/me');

  return Object.values(CUSTOM_RESOURCES).reduce((currentCustomResources, { name, options, accessibleRoleIds }) => {
    let resourceComponents;
    try {
      resourceComponents = require(`./routes/${name}`).default;
    } catch (error) {
      return currentCustomResources;
    }

    // In case of the APIs /auth/me and /permitted haven't updated yet
    const hasGrantedPermission = hasAccessPermissionByRoleId(userData?.role?.id, accessibleRoleIds);

    return [
      ...currentCustomResources,
      <Resource
        name={name}
        list={(canI(RbacAction.LIST, name, permissions) || hasGrantedPermission) && resourceComponents.list}
        edit={(canI(RbacAction.UPDATE, name, permissions) || hasGrantedPermission) && resourceComponents.edit}
        create={(canI(RbacAction.CREATE, name, permissions) || hasGrantedPermission) && resourceComponents.create}
        show={(canI(RbacAction.READ, name, permissions) || hasGrantedPermission) && resourceComponents.show}
        options={options}
      />,
    ];
  }, []);
};

const fetchResources = async (permissions) => {
  let resources = [];
  const isInPreStaging = isPreStagingEnv();
  const user = LocalStorage.getItem(userStorageKey);

  try {
    const accessToken = Cookie.getItem('accessToken');
    if (accessToken) {
      const permittedResource = await axios.get('api/resource/permitted');
      if (permittedResource?.status !== STATUS_REQUIRED_CHANGE_PASSWORD) {
        resources = formatResources(permittedResource?.data);
      }
    } else {
      forceLogout();
    }
  } catch {
    forceLogout();
  }

  const noCustomComponents = [];

  const fetchedResourceComponents = resources
    .filter(resource => {
      const isPlayerStateManagement = resource.name === resourceSlug.PLAYER_STATE_MANAGEMENT;
      // TODO : Remove when finish testing player state management on pre-staging
      if (!isPlayerStateManagement || (isPlayerStateManagement && isInPreStaging)) {
        return !resource.hideOnMenu;
      }
      return false;
    })
    .map(
      ({
        id,
        name,
        list,
        update,
        read,
        icon,
        create,
        delete: hasDelete,
        group: isGroup,
        children,
        submittable,
        approvable,
        cancelAfterApproval,
        isChild,
        prefix,
        reportInterface,
        translatable,
        index,
        isAcl,
      }) => {
        let resourceLoad;

        try {
          resourceLoad = require(`./routes/${name}`).default;
        } catch (error) {
          noCustomComponents.push(name);
        }

        let hasCreate = create;

        const isBrandAdmin = user?.role?.id === RoleBuiltIn.BRAND_ADMIN;
        const isBrandResource = name === resourceSlug.BRAND;

        // Hotfix: Hide create button in brand page if users are Brand Admins even when create permission exists
        if (isBrandAdmin && hasCreate && isBrandResource) {
          hasCreate = false;
        }

        return (
          <Resource
            name={name}
            icon={icon && (() => icon)}
            list={list && canI(RbacAction.LIST, name, permissions) && (resourceLoad?.list || WealthListGuesser)}
            edit={update && canI(RbacAction.UPDATE, name, permissions) && (resourceLoad?.edit || WealthEditGuesser)}
            create={
              hasCreate && canI(RbacAction.CREATE, name, permissions) && (resourceLoad?.create || WealthCreateGuesser)
            }
            show={read && canI(RbacAction.READ, name, permissions) && (resourceLoad?.show || WealthShowGuesser)}
            options={{
              hasDelete: hasDelete && canI(RbacAction.DELETE, name, permissions),
              isGroup,
              resources,
              prefix,
              children,
              isChild,
              submittable: submittable && canI(RbacAction.SUBMIT, name, permissions),
              approvable: approvable && canI(RbacAction.APPROVE, name, permissions),
              cancelAfterApproval,
              resourceId: id,
              reportInterface,
              translatable,
              index,
              isAcl,
            }}
          />
        );
      },
    );
  console.warn(`The following resource got no Customized Components ${noCustomComponents}`);

  const customizedResourceComponents = await getCustomResources(permissions);

  return [...fetchedResourceComponents, ...customizedResourceComponents];
};

export const NavigationContext = React.createContext();

const App = () => {
  const [configLoaded, setConfigLoaded] = useState(false);
  const [{ pageTitleInfo, breadcrumbs }, setNavigationData] = useState({});

  const handleConfigApp = async () => {
    const { APP_THEME, APP_BRAND } = getBackofficeEnv();
    const { background: backgroundByTheme } = otherThemeConfigMapping[APP_THEME];

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw >= breakpoints.values.md) {
      setBodyBackgroundColor(backgroundByTheme);
    }

    const { loadingImage } = appBrandConfigMapping[APP_BRAND];
    setLoadingImage(loadingImage);
  };

  useEffect(() => {
    (async () => {
      await handleFetchingEnvironment();
      await handleConfigApp();
      initFirebase();
      setConfigLoaded(true);
    })();
  }, []);

  const { APP_THEME } = getBackofficeEnv();
  const { loginPageComponent } = otherThemeConfigMapping[APP_THEME];
  const MainLoginPage = loginPageComponent || (() => <></>);

  return configLoaded ? (
    /** Need to add injectFirst to manually override the injection order using the index option
     * because Webpack can mess around with Material UI's rules of JSS precedence
     * */
    <StylesProvider injectFirst>
      <NavigationContext.Provider
        value={{
          setNavigationData,
          breadcrumbs,
          pageTitleInfo,
        }}
      >
        <CustomCoreAdmin
          title="Gaming Backoffice"
          dashboard={Dashboard}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          customReducers={rootReducer}
          customSagas={rootSagas}
          customRoutes={customRoutes}
          layout={Layout}
          theme={getRawTheme(APP_THEME)}
          loginPage={MainLoginPage}
          publicCustomRoutes={publicCustomRoutes}
        >
          {fetchResources}
        </CustomCoreAdmin>
      </NavigationContext.Provider>
    </StylesProvider>
  ) : null;
};

export default App;
