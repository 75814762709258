/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios';
import { useNotify, useTranslate } from 'ra-core';
import { SmallButton } from '../../ra/buttons';
import Confirm from '../../ra/layout/Confirm';
import { RoleBuiltIn } from '../../../../services/util';

const RESOURCE_NEED_CACHE = ['brand', 'game', 'available-game'];

const ClearCacheButton = props => {
  const {
    resource, record, user, isShowView,
  } = props;

  if (
    !RESOURCE_NEED_CACHE.includes(resource)
    || user?.role?.id !== RoleBuiltIn.SYSTEM_ADMIN
  ) {
    return null;
  }

  const notify = useNotify();
  const translate = useTranslate();
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const clearCache = async () => {
    const url = isShowView
      ? `api/${resource}/${record?.id}/clear-cache`
      : `api/${resource}/clear-all-caches`;

    try {
      await Axios.post(url);
      notify('Successfully', 'success');
      setConfirmSubmit(false);
    } catch (error) {
      notify('Clear cache failure', 'error');
    }
  };

  const label = isShowView
    ? translate('ra.action.clearCache')
    : translate('ra.action.clearAllCaches', {
      resource,
    });
  const content = isShowView
    ? translate('ra.message.confirmClearCache')
    : translate('ra.message.confirmClearAllCaches', {
      resource,
    });

  return (
    <>
      <SmallButton
        label={label}
        onClick={() => setConfirmSubmit(true)}
        startIcon={<DeleteIcon />}
      />
      <Confirm
        isOpen={confirmSubmit}
        title={label}
        content={content}
        onConfirm={clearCache}
        onClose={() => setConfirmSubmit(false)}
      />
    </>
  );
};

export default ClearCacheButton;
