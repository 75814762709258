import TranslateIcon from '@material-ui/icons/Translate';
import axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate } from 'ra-core';
import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';

const ActionMenu = props => {
  const {
    record, resource,
  } = props;
  const notify = useNotify();
  const translate = useTranslate();

  const triggerEvent = async () => {
    try {
      await axios.post(`api/${resource}/${record?.id}/trigger`);
      notify('Successfully', 'success');
    } catch {
      notify('Trigger failure', 'error');
    }
  };

  return (
    <ToolbarMenu {...props}>
      <Tooltip
        placement="top"
        title={translate('ra.action.translate')}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={triggerEvent}
        >
          <TranslateIcon />
        </Button>
      </Tooltip>
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
  resource: Type.string,
};

ActionMenu.defaultProps = {
  record: {},
  resource: '',
};

export default ActionMenu;
