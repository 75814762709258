import React from 'react';
import ReferenceAutocompleteInput from './ReferenceAutocompleteInput';

const GroupSelector = props => (
  <ReferenceAutocompleteInput
    label="common.fields.group"
    source="groupId"
    reference="group"
    {...props}
  />
);

export default GroupSelector;

GroupSelector.propTypes = {};

GroupSelector.defaultProps = {};
