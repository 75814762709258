import { CACHE_WAGER_BY_PAGE, UPDATE_CURRENT_WAGER_PAGE } from '../root.actions';

export const cacheWagerByPage = ({
  data, page,
}) => ({
  type: CACHE_WAGER_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentWagerPage = data => ({
  type: UPDATE_CURRENT_WAGER_PAGE,
  payload: data,
});
