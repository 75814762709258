import * as React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFilterFormContext } from '../../../context/form/filterForm.context';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingRight: 0,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
      },
    },
    actions: {
      paddingTop: theme.spacing(3),
      minHeight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
      },
    },
  }),
  {
    name: 'RaListToolbar',
  },
);

const ListToolbar = props => {
  const {
    filters, actions, searchPanel, ...rest
  } = props;
  const classes = useStyles(props);

  const { filterDisplayed } = useFilterFormContext();

  return (
    <Toolbar className={classes.toolbar}>
      {actions
        && React.cloneElement(actions, {
          ...rest,
          className: classes.actions,
          filters,
          ...actions.props,
        })}

      {/* searchPanel of report list guesser */}
      {searchPanel && searchPanel}

      {filterDisplayed && (
        <Box width="100%">
          {filters && React.cloneElement(filters, {
            ...rest,
            context: 'form',
          })}
        </Box>
      )}

      <span />
    </Toolbar>
  );
};

ListToolbar.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.element,
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  searchPanel: PropTypes.element,
};

ListToolbar.defaultProps = {
  classes: {},
  filters: null,
  actions: null,
  exporter: null,
  searchPanel: null,
};

export default React.memo(ListToolbar);
