/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslate, FormDataConsumer, required, minValue } from 'ra-core';
import { SimpleForm, AutocompleteInput } from 'react-admin';
import { Box, Button, makeStyles, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Slider from 'react-slick';
import classNames from 'classnames';
import { useFormState, useForm } from 'react-final-form';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import { Button } from '../../../base/components/ra/buttons';
import { stepValidation } from '../inbox-rule.utils';

const useStyles = makeStyles(theme => ({
  btnStyle: {
    margin: 0,
    '& > span': {
      display: 'flex',
      gap: '5px',
    }
  },
  btnNext: {
    marginLeft: 'auto',
  },
}));

function HiddenArrow(props) {
  const {
    className, style, onClick,
  } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'none',
        background: 'green',
      }}
      // onClick={onClick}
    />
  );
}

const StepperBasicForm = props => {
  const {
    children, onStepperChange, isNextDisabled, isBackDisabled,
  } = props;

  const classes = useStyles();
  const muiTheme = useTheme();

  const { values: formValues } = useFormState();

  const translate = useTranslate();
  const sliderRef = useRef();
  const [sliderState, setSliderState] = useState({
    curIndex: 0,
  });

  const [navButton, setNavButton] = useState({
    back: {
      isDisabled: false,
    },
    next: {
      isDisabled: false,
    },
  });

  const settings = useRef({
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    adaptiveHeight: true,
    nextArrow: <HiddenArrow />,
    prevArrow: <HiddenArrow />,
    beforeChange: (currentIndex, nextIndex) => {
      if (typeof onStepperChange === 'function') {
        onStepperChange(nextIndex);
      }
      setSliderState({
        curIndex: nextIndex,
      });
    },
  }).current;

  useEffect(() => {
    const isValidForm = stepValidation({
      formValues,
      step: sliderState.curIndex + 1,
    });

    setNavButton({
      ...navButton,
      next: {
        isDisabled: !isValidForm,
      },
    });
  }, [formValues, sliderState.curIndex]);

  return (
    <Box marginTop="16px">
      <Box
        style={{
          width: '100%',
          display: "flex",
          justifyContent: "center",
          marginBottom: '10px',
          // color: '#989898',
          color: muiTheme.palette.primary.main,
          fontWeight: 700
        }}
      >
        {translate('ra.text.step')} {sliderState.curIndex + 1}/3 {sliderState.curIndex + 1 === 3 && `- ${translate('ra.text.preview')}`}
      </Box>
      <Slider
        ref={sliderRef}
        {...settings}
      >
        {children}
      </Slider>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        position="relative"
      >
        {sliderState.curIndex > 0 && (
          <Button
            className={classes.btnStyle}
            onClick={() => {
              sliderRef.current.slickGoTo(sliderState.curIndex - 1);
              window.scrollTo(0, 0); // Scroll to top
            }}
            disabled={isBackDisabled || navButton.back.isDisabled}
          >
            <ArrowBackIcon /> {translate('ra.action.back')}
          </Button>
        )}

        {/* <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // color: '#989898',
            color: muiTheme.palette.primary.main,
            fontWeight: 700
          }}
        >
          Step {sliderState.curIndex + 1}/3 {sliderState.curIndex + 1 === 3 && "- Preview"}
        </Box> */}

        {sliderState.curIndex < 2 && (
          <Button
            className={classNames(classes.btnStyle, classes.btnNext)}
            onClick={() => {
              sliderRef.current.slickGoTo(sliderState.curIndex + 1);
              window.scrollTo(0, 0); // Scroll to top
            }}
            disabled={isNextDisabled || navButton.next.isDisabled}
          >
            {translate('ra.navigation.next')} <ArrowForwardIcon /> 
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default StepperBasicForm;
