import BetSettingCreate from './bet-setting.create';
import BetSettingShow from './bet-setting.show';
import BetSettingEdit from './bet-setting.edit';
import BetSettingList from './bet-setting.list';

export default {
  create: BetSettingCreate,
  show: BetSettingShow,
  edit: BetSettingEdit,
  list: BetSettingList,
};
