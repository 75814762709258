import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';
import 'codemirror/lib/codemirror.css';
import PropTypes from 'prop-types';
import * as React from 'react';

const TuiViewerField = props => {
  const {
    record, source,
  } = props;

  return (
    <Viewer
      initialValue={record?.[source]}
      previewStyle="vertical"
      height="600px"
      initialEditType="markdown"
      useCommandShortcut
    />
  );
};

TuiViewerField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

TuiViewerField.defaultProps = {
  record: {},
  source: '',
};

export default TuiViewerField;
