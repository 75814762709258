/* eslint-disable */
import { makeStyles } from '@material-ui/core';
import { PICKER_MAX_WIDTH, PICKER_MIN_WIDTH } from './bet-setting-picker.constant';

export const useClasses = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  pickerControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    overflowX: 'auto',
  },
  divideChar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 238,
    boxSizing: 'content-box',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
    padding: theme.spacing(1),
    border: '1px solid transparent',
  },
  addBetControlWrapper: {
    display: 'flex',
    gap: '28px',
  },
  addBetControl: {
    width: PICKER_MAX_WIDTH,
    marginTop: 10,
    padding: theme.spacing(1),
    border: '1px solid transparent',
  },
  addBetControlContent: {
    // width: PICKER_MAX_WIDTH,
  },
  pickerColumnWrapper: {
    flex: 1,
    maxWidth: PICKER_MAX_WIDTH,
    minWidth: PICKER_MIN_WIDTH,
    border: '1px solid #000',
    borderColor: 'transparent',
    overflow: 'hidden',
    padding: theme.spacing(1),
  },
  invalidValue: {
    border: '1px solid #000',
    borderRadius: 4,
    borderColor: theme.palette.error.main,
  },
  invalidColor: {
    color: theme.palette.error.main,
  },
  helpText: {
    margin: '4px 14px 0px',
    color: theme.palette.error.main,
  },
  iconLeft: {
    color: '#FDF6EC',
  },
  iconRight: {
    color: '#E83A14',
  },
  defaultCls: {
    color: '#019267',
  },
}));
