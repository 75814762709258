import React from 'react';
import { Box, Typography } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';

export default function Empty({ text }) {
  const translate = useTranslate();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <InboxIcon
        style={{
          fontSize: '100px',
          color: '#D6D6D6',
        }}
      />
      <Typography
        variant="h4"
        paragraph
      >
        {text || translate('ra.text.noRecords')}
      </Typography>
    </Box>
  );
}

Empty.propTypes = {
  text: PropTypes.string,
};

Empty.defaultProps = {
  text: '',
};
