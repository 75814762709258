/* eslint-disable react/prop-types, consistent-return, no-param-reassign */
import React from 'react';
import { RadioButtonGroupInput, useLocale } from 'react-admin';
import { startCase, camelCase, capitalize, get, words } from 'lodash';
import { Field, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import clsx from 'clsx';
import { CondOperator } from '@nestjsx/crud-request';
import moment from 'moment';
import { makeStateStory } from '../../../../services/util/makeStateStory';
import { clearDateRangePrefix } from '../../../../services/util';
import { useEnumOptions } from '../../../hooks';
import { TooltipForDisabledInput } from '../../custom-tooltip';
import {
  CheckBoxGroup,
  SelectInput,
  TextInput,
  DateTimeInput,
  NumberInput,
  DateQuickRange,
  NullableBooleanInput,
  ReferenceInput,
} from '../../ra/inputs';
import DateRangeInput from '../date-range-input';
import { handleFilterOn } from '../wealth-input.guesser.utils';
import MonthYearInput from './monthYear.input';
import DateInput from '../date-input';
import WrapperAutocompleteInput from './wrapper-autocomplete';
import { getSyntaxJsonUnquoteExtract } from '../../../../constant';

const handleFilterOnRef = (filterOnRef = {}, formValues = {}) => {
  try {
    const filterOnRefResult = {};

    const filterOnRefProps = Object.keys(filterOnRef || {});

    if (filterOnRef) {
      filterOnRefProps.forEach(f => {
        const value = formValues?.[filterOnRef[f].default];
        if (value?.id) {
          filterOnRefResult[filterOnRef[f]?.format || f] = value.id;
        } else if (value) {
          filterOnRefResult[filterOnRef[f]?.format || f] = value;
        }
      });
    }

    return filterOnRefResult;
  } catch (error) {
    return {};
  }
};

const reportSchemaProperties = {
  brand: {
    refName: 'brand',
    refField: '#/components/schemas/Brand',
    filterOn: {
      group: {
        default: 'group',
        format: 'group.id||$eq',
      },
    },
  },
  currency: {
    refName: 'currency',
    refField: '#/components/schemas/Currency',
    filterOn: {
      currencyType: {
        default: 'currencyType',
        format: 'type||$eq',
      },
    },
    filterOnRef: {
      brand: {
        default: 'brand',
        format: 'brands.id||$in',
      },
      group: {
        default: 'group',
        format: 'groups.id||$in',
      },
    },
  },
  consolidated: {
    filter: {
      skipACL: true,
    },
  },
};

export function ReportInputGuesser({
  filter,
  form,
  classes,
  resource,
  translate,
  defaultRefFilter,
  filterValues,
  user,
  reportServiceVersion,
}) {
  const enumOptions = useEnumOptions(filter.reference, filter.source);

  const realLabel = filter.label?.indexOf('.') > -1 ? filter.label : `resources.report.fields.${camelCase(filter.label)}`;
  const label = startCase(translate(realLabel));
  let optionText = filter?.optionText?.[0] || 'name';

  const {
    values: formValues, modified,
  } = useFormState();

  const locale = useLocale();

  const stateStoryKey = `REPORT-SEARCH-${resource}`;
  const {
    setStoryState, getOlderState,
  } = makeStateStory(stateStoryKey);

  setStoryState(formValues);

  const {
    allowEmpty = true, optionValue = 'id',
  } = filter;

  let fieldComponent = null;
  const extraFieldProps = {
    key: filter?.source,
    helpText: '',
  };
  const internalFieldProps = {
    className: clsx(classes.fieldStyle, classes.noWarning),
    disabled: filter?.disabled || false,
  };

  const { translatable } = filter;

  switch (filter.inputType) {
    case 'reference':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => {
            const sourceSchemaProperties = reportSchemaProperties[filter?.source];
            const filterOn = sourceSchemaProperties?.filterOn;
            const filterOnProps = Object.keys(filterOn || {});
            const sourceFilterList = filterOnProps.map(item => {
              if (['group'].includes(item)) {
                return 'group';
              }
              return item;
            });

            const hasFilter = sourceFilterList?.length > 0;
            const refName = sourceSchemaProperties?.refName;
            const refFilter = handleFilterOn(filterOn, {
              user,
              refName,
              changeInputValue: form?.change,
              formValues,
            });

            // Check one of list filterOn has a value
            // If one has the value => filter this input
            const filterActivate = sourceFilterList.some(item => get(formValues, item));

            // Check this field DISABLED or NOT depend on `source filterOn`
            let inputDisabledByFilterOn = hasFilter ? !filterActivate : false;

            // Brand won't set DISABLED when role's user is GROUP
            const belongToBrand = ['brand'].includes(refName);
            if (user?.role?.type === 'GROUP' && belongToBrand) {
              inputDisabledByFilterOn = false;
            }

            // Check `source filterOn` has UPDATED or NOT
            const olderStory = getOlderState();
            const sourceFilterUpdated = sourceFilterList?.some(item => {
              const currentValue = get(formValues, item);
              const olderValue = get(olderStory, item);
              const isModified = get(modified, item);

              if (isModified && JSON.stringify(currentValue) !== JSON.stringify(olderValue)) {
                return true;
              }

              return false;
            });

            // Source filterOn unSelect or update value => this field clear data
            if ((inputDisabledByFilterOn || sourceFilterUpdated) && formValues[filter?.source]) {
              form.change(filter?.source, undefined);
            }

            // Translate all sourceNames in an array
            const getResourceNameTranslation = resourceList => resourceList.map(item => {
              const realResourceName = words(item)[0];
              return translate(`resources.${realResourceName}.name`);
            });

            const listResourceNameTranslated = getResourceNameTranslation(sourceFilterList);
            const disabledInputMessage = translate('ra.message.pleaseSelectFirst', {
              smart_name: listResourceNameTranslated?.join(', '),
            });

            const tooltipDisabledInputProps = {
              title: disabledInputMessage,
              placement: 'top-start',
              showMessage: inputDisabledByFilterOn,
            };

            const source = filter?.source;
            let optionTextQuery = optionText;
            const optionTextSort = optionText;

            if (translatable) {
              optionTextQuery = `${optionText}${getSyntaxJsonUnquoteExtract(locale)}`;

              optionText = `${optionText}.${locale}`;
            }

            return (
              <TooltipForDisabledInput {...tooltipDisabledInputProps}>
                <ReferenceInput
                  {...internalFieldProps}
                  fullWidth
                  label={label}
                  source={source}
                  reference={filter?.reference}
                  optionText={translatable ? optionTextQuery : optionTextSort}
                  filter={{
                    ...filter?.filter,
                    ...refFilter,
                    ...sourceSchemaProperties?.filter,
                    ...handleFilterOnRef(sourceSchemaProperties?.filterOnRef, formValues),
                  }}
                  disabled={filter?.disabled || inputDisabledByFilterOn || false}
                  sort={
                    filter.sort
                      ? {
                        ...filter.sort,
                      }
                      : {
                        field: optionTextSort || 'id',
                        order: 'ASC',
                      }
                  }
                  perPage={filter?.choiceLimit || 100}
                >
                  <WrapperAutocompleteInput
                    reference={filter?.reference}
                    defaultChoicesOnTop={filter?.defaultChoicesOnTop}
                    optionText={optionText}
                    defaultRefFilter={defaultRefFilter}
                    filterValues={filterValues}
                    optionValue={optionValue}
                    resettable={allowEmpty}
                  />
                </ReferenceInput>
              </TooltipForDisabledInput>
            );
          }}
        </Field>
      );
      break;
    case 'enum':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <SelectInput
              {...internalFieldProps}
              source={filter?.source}
              optionText="name"
              choices={enumOptions}
              allowEmpty={allowEmpty}
              emptyText={`--${translate('ra.text.all').toUpperCase()}--`}
            />
          )}
        </Field>
      );
      break;
    case 'date-range': {
      const startDate = clearDateRangePrefix(formValues.created).split(',')[0];
      const endDate = clearDateRangePrefix(formValues.created).split(',')[1];

      const today = moment();
      const yesterday = moment().subtract(1, 'days');

      const dateRangeLabel = {
        from: capitalize(translate('wa.common.from')),
        to: capitalize(translate('wa.common.to')),
      };

      if (yesterday.isSame(moment(startDate), 'day') && today.isSame(moment(endDate), 'day')) {
        dateRangeLabel.from = capitalize(translate('ra.text.yesterday'));
        dateRangeLabel.to = capitalize(translate('ra.text.today'));
      }

      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <DateRangeInput
              {...internalFieldProps}
              reportServiceVersion={reportServiceVersion}
              entity="report"
              source={filter?.source}
              resource={resource}
              label={`${dateRangeLabel.from} → ${dateRangeLabel.to}`}
              prefix
              clearable={false}
              dateRange={formValues.created && [startDate, endDate]}
            />
          )}
        </Field>
      );
      break;
    }
    case 'datetime':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <DateTimeInput
              {...internalFieldProps}
              source={filter?.source}
              resource={resource}
              label={label}
            />
          )}
        </Field>
      );
      break;
    case 'date-input':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <DateInput
              {...internalFieldProps}
              source={filter?.source}
              resource={resource}
              allowEmpty
              label={label || capitalize(translate('wa.common.from'))}
              clearable
            />
          )}
        </Field>
      );
      break;
    case 'number':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <NumberInput
              {...internalFieldProps}
              source={filter?.source}
              label={label}
              variant="outlined"
            />
          )}
        </Field>
      );
      break;
    case 'checkboxGroup':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <CheckBoxGroup
              {...internalFieldProps}
              className={clsx('margin-custom-search-panel', internalFieldProps.className)}
              source={filter?.source}
              label={label}
              choices={filter?.choices}
              resource={resource}
              direction="row"
            />
          )}
        </Field>
      );
      break;
    case 'select':
      fieldComponent = (
        <>
          <Field
            {...extraFieldProps}
            name={filter?.source}
          >
            {() => (
              <SelectInput
                {...internalFieldProps}
                allowEmpty={allowEmpty}
                source={filter?.source}
                label={label}
                choices={filter?.choices}
                variant="outlined"
                translateChoice
                emptyText={`--${translate('ra.text.all').toUpperCase()}--`}
              />
            )}
          </Field>
        </>
      );
      break;
    case 'monthYear':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <MonthYearInput
              {...internalFieldProps}
              source={filter?.source}
              label={label}
            />
          )}
        </Field>
      );
      break;
    case 'radioGroup':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <RadioButtonGroupInput
              {...internalFieldProps}
              className={clsx('margin-custom-search-panel', internalFieldProps.className)}
              source={filter?.source}
              label={label}
              choices={filter?.choices}
            />
          )}
        </Field>
      );
      break;
    case 'dateQuickRange':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <DateQuickRange
              {...internalFieldProps}
              className={clsx('margin-custom-search-panel', internalFieldProps.className)}
              source={filter?.source}
              label={label}
              choices={filter?.choices}
              form={form}
            />
          )}
        </Field>
      );
      break;
    case 'boolean':
      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.source}
        >
          {() => (
            <NullableBooleanInput
              key={filter?.source}
              label={`resources.${resource}.fields.${filter?.source}`}
              source={filter?.source}
              resource={resource}
              trueLabel="ra.boolean.true"
              falseLabel="ra.boolean.false"
              nullLabel={`--${translate('ra.text.all').toUpperCase()}--`}
              allowEmpty
              resettable
              alwaysOn
            />
          )}
        </Field>
      );
      break;
    default: {
      let newSource = `${filter?.source}||${CondOperator.CONTAINS}`;

      // Query operation by resource's ID should be 'EQUAL'
      if (['playerId', 'wagerId', 'nativeId'].includes(filter?.source)) {
        newSource = `${filter?.source}||${CondOperator.EQUALS}`;
      }

      fieldComponent = (
        <Field
          {...extraFieldProps}
          name={filter?.reference}
        >
          {() => (
            <TextInput
              {...internalFieldProps}
              // `source`: Query operation by resource's ID should be 'EQUAL'
              source={newSource}
              label={label}
              variant="outlined"
            />
          )}
        </Field>
      );
    }
  }

  return (
    <div
      key={filter?.source}
      className={clsx({
        'search-panel__radio-button': filter.inputType === 'radioGroup',
      })}
    >
      {fieldComponent}
      <OnChangeCustom name={filter?.source} />
    </div>
  );
}

export function OnChangeCustom(props) {
  const {
    name, change,
  } = props;

  return (
    <OnChange name={name}>
      {(value, previous) => {
        if (typeof change === 'function') {
          change(value, previous);
        }
      }}
    </OnChange>
  );
}
