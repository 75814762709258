/* eslint-disable */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSchema, useViewType } from '../../hooks';
import { useComponentRequest } from './utils';
import { MetaDataContext } from './context';

export const MetaDataProvider = ({ children, basePath }) => {
  const { api, ref } = useSchema();
  const viewType = useViewType();
  const componentRequest = useComponentRequest({ api, ref, basePath, viewType });

  const metaData = useMemo(() => {
    return {
      viewType,
      componentRequest: componentRequest || {},
    };
  }, [viewType, componentRequest]);

  return (
    <MetaDataContext.Provider
      value={metaData}
    >
      {children}
    </MetaDataContext.Provider>
  );
};

MetaDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  basePath: PropTypes.string.isRequired,
};

MetaDataProvider.defaultProps = {};
