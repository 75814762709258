import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  formStyle: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    '& [class*="MuiGrid-root"]': {
      gap: '0 !important',
    },
    '& [class*="MuiGrid-item"]': {
      marginRight: '16px',
    },
  },
  fieldStyle: {
    minWidth: '190px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
    '& [class*="MuiFormHelperText-root"]': {
      display: 'none',
    },
  },
  filterInputDisabled: {},
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  btn: {
    width: 150,
    padding: 8,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  filterContainer: {},
}));
