import React, { useEffect, useState } from 'react';
import { IconButton, Popover, useTheme } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useGetList,
  useLocale,
  useRefresh,
  useSetLocale,
  useTranslate,
} from 'react-admin';
import moment from 'moment';
import LanguageItem from './LanguageItem';
import { useLanguageContext } from '../../../../context/language';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import './asset/sprite-flags-24x24.css';
import useStyles from './index.style';
import 'moment/locale/th';
import 'moment/locale/zh-cn';
import CmtCard from '../../../../../theme/@coremat/CmtCard';
import CmtCardHeader from '../../../../../theme/@coremat/CmtCard/CmtCardHeader';
import CmtList from '../../../../../theme/@coremat/CmtList';

let firstLoad = true;
const WaLanguage = {};

const LanguageSwitcherDesktop = props => {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const locale = useLocale();
  const setLocale = useSetLocale();
  const translate = useTranslate();
  const { setLanguage } = useLanguageContext();
  const refresh = useRefresh();

  const { setLocalStorage } = useLocalStorage('waLocale');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'language' : undefined;

  const switchLanguage = language => {
    setLocale(language.locale);
    setLocalStorage(language.locale);
    handleClose();

    // Avoid unnecessary issue
    window.location.reload();
  };

  const {
    data, error,
  } = useGetList('language', {
    page: 1,
    perPage: 100,
  });

  if (!error && data) {
    Object.keys(data).forEach(langId => {
      WaLanguage[data[langId].locale] = data[langId];
    });
  }

  useEffect(() => {
    const text = translate('ra.boolean.true');
    if (text === '' && firstLoad && locale) {
      firstLoad = false;
      setLocale(locale);
    }
  });

  useEffect(() => {
    if (WaLanguage?.[locale]?.enabled) {
      setLanguage(WaLanguage?.[locale]);
      moment.locale(locale);
    } else {
      const langEnabled = Object.values(data || {}).find(
        lang => lang.enabled,
      );
      if (langEnabled) {
        switchLanguage(langEnabled);
        setLanguage(langEnabled);
        moment.locale(langEnabled.locale);
      }
    }
  }, [WaLanguage?.[locale]]);

  // Refresh to change languages for report modules
  useEffect(() => {
    refresh();
  }, [locale]);

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        data-tut="reactour__localization"
      >
        <i
          className={`flag flag-24 flag-${WaLanguage?.[locale]?.icon || 'us'}`}
        />
      </IconButton>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title={translate('ra.switch.language')}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <PerfectScrollbar className={classes.perfectScrollbarLanguage}>
            <CmtList
              data={Object.values(WaLanguage).filter(lang => lang.enabled)}
              renderRow={(item, index) => (
                <LanguageItem
                  key={index}
                  language={item}
                  onClick={switchLanguage}
                />
              )}
            />
          </PerfectScrollbar>
        </CmtCard>
      </Popover>
    </>
  );
};

export default LanguageSwitcherDesktop;
