/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  SimpleForm,
  useEditController,
  useTranslate,
} from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import { Box } from '@material-ui/core';

import { useUpdateCustom, useAuthUser } from '../../base/hooks';
import { Edit } from '../../base/components/ra/views';
import rcSlug from '../../constant/resource-slug';

import { formatDynamicRuleFromRecord, formatPayloadData } from './inbox-rule.utils';
import StepperBasicForm from './components/StepperBasicForm';
import GeneralStep from './components/steps/general-step';
import DynamicRuleStep from './components/steps/dynamic-rule-step';
import PreviewStep from './components/steps/preview-step';

const InboxRuleFormController = ({
  children, record,
}) => {
  const form = useForm();

  useEffect(() => {
    form.change('hub', record.hub);
    form.change('type', record.type);

    const dynamicGroupValue = formatDynamicRuleFromRecord(record);
    form.change('dynamicGroupStep', dynamicGroupValue);
    form.change('groups', _.keys(dynamicGroupValue.groups));
  }, [record]);

  return <Box>{children}</Box>;
};

const InboxRuleEdit = props => {
  const user = useAuthUser();
  const { record } = useEditController(props);
  const translate = useTranslate();
  
  const { update } = useUpdateCustom({
    resource: rcSlug.INBOX_RULE,
    resourceTranslated: translate(`resources.${rcSlug.INBOX_RULE}.name`),
  });

  const [step, setStep] = useState();

  const onStepperChange = stepIndex => {
    setStep(stepIndex + 1);
  };

  const updateInboxRule = (values = {}) => {
    const data = formatPayloadData(values);

    if (data.name) {
      delete data.name;
    }

    update({
      resource: rcSlug.INBOX_RULE,
      payload: {
        id: record?.id,
        data,
      },
    });
  };

  return (
    <Edit
      {...props}
      alwaysEnableSaveButton
      onCustomSave={updateInboxRule}
    >
      <SimpleForm redirect="show">
        <FormDataConsumer>
          {({ formData }) => (
            <InboxRuleFormController record={record}>
              <StepperBasicForm onStepperChange={onStepperChange}>
                <GeneralStep formData={formData} />
                <DynamicRuleStep formData={formData} />
                <PreviewStep formData={formData} />
              </StepperBasicForm>
            </InboxRuleFormController>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default InboxRuleEdit;
