/* eslint-disable */
import React from 'react';
import { isValidElement, Children, cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, useListContext, useVersion } from 'ra-core';
import {
  Checkbox,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  useMediaQuery,
  useTheme,
  Box,
  CircularProgress,
} from '@material-ui/core';
import classnames from 'classnames';
import { DatagridLoading, DatagridBody, PureDatagridBody } from 'react-admin';
import { get, isEmpty } from 'lodash';
import DatagridHeaderCell from './DatagridHeaderCell';
import useStyles from './index.style';
import EmptyTable from '../../guesser/report/emptyTable';
import { HEADER_HEIGHT } from '../../../../custom-theme/themeConfig';

const Datagrid = React.forwardRef((props, ref) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const screenMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const {
    optimized = false,
    body = optimized ? <PureDatagridBody classes={classes} /> : <DatagridBody classes={classes} />,
    children,
    classes: classesOverride,
    className,
    expand,
    hasBulkActions = false,
    hover,
    isRowSelectable,
    resource,
    rowClick,
    rowStyle,
    size = 'small',
    refManyEditAddButton,
    defaultColumns,
    properties,
    ...rest
  } = props;

  const { basePath, currentSort, data, ids, loaded, onSelect, onToggleItem, selectedIds, setSort, total, loading } =
    useListContext(props);
  const version = useVersion();

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        currentSort.field === newField
          ? currentSort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order;

      setSort(newField, newOrder);
    },
    [currentSort.field, currentSort.order, setSort],
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        const all = ids.concat(selectedIds.filter((id) => !ids.includes(id)));
        onSelect(isRowSelectable ? all.filter((id) => isRowSelectable(data[id])) : all);
      } else {
        onSelect([]);
      }
    },
    [data, ids, onSelect, isRowSelectable, selectedIds],
  );

  /**
   * Depend on `field` to get real source name
   * @param {*} field
   * @returns source or null
   */
  const getRealSourceName = (field) => {
    const reference = get(field, 'props.reference');
    const source = get(field, 'props.source');

    if (reference && reference?.split('.')?.length > 1) {
      return reference;
    }

    if (source) {
      return source;
    }

    return null;
  };

  /**
   * Purpose: Check a field which is a param
   * if type of this field is Array (Many-to-many), we DO NOT perform sort function
   */
  const checkDisableSort = (field) => {
    const sortable = get(field, 'props.sortable') ?? true;
    const isReferenceField = !!get(field, 'props.reference'); // For Report Field
    const hasForceSorting = !!get(field, 'props.forceSorting');

    if (!sortable || (isReferenceField && !hasForceSorting)) {
      return true;
    }

    const { fieldName } = getSourceFromChildrenComponent(field);

    const sortableOnServerConfig = properties?.[fieldName]?.properties?.sortable;
    if (typeof sortableOnServerConfig !== 'undefined') {
      return !sortableOnServerConfig;
    }

    // Todo: should define sort in Backend
    // gameId: For Report Field
    const disabledFieldList = ['payload', 'res', 'gameId'];
    if (disabledFieldList.includes(fieldName)) {
      return true;
    }

    if (isEmpty(data)) {
      return true;
    }

    // Valid field always exist source name
    const realSourceName = getRealSourceName(field);
    if (!realSourceName) {
      return true;
    }

    return false;
  };

  const getSourceFromChildrenComponent = (field) => {
    const source = get(field, 'props.source', '');
    const [fieldName] = source.split('.');
    return {
      source,
      fieldName,
    };
  };

  /**
   * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
   * if loaded is true, the data for the list has at least been returned once by the dataProvider
   * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
   */
  if (loaded === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    );
  }

  /**
   * Once loaded, the data for the list may be empty. Instead of
   * displaying the table header with zero data rows,
   * the datagrid displays nothing in this case.
   */
  if (loaded && (ids.length === 0 || total === 0)) {
    return <EmptyTable />;
  }

  const all = isRowSelectable ? ids.filter((id) => isRowSelectable(data[id])) : ids;

  const tableContainerHeight = screenMdUp
    ? `calc(100vh - ${HEADER_HEIGHT.lg}px - 200px)`
    : `calc(100vh - ${HEADER_HEIGHT.md}px - 200px)`;

  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */
  return (
    <TableContainer
      style={{
        maxHeight: tableContainerHeight,
      }}
    >
      {loading ? (
        <Box display="flex" justifyContent="center" width="100%" p={3} mt={3}>
          <CircularProgress size={28} disableShrink/>
        </Box>
      ) : (
        <Table
          ref={ref}
          className={classnames(classes.table, className)}
          size={size}
          {...sanitizeListRestProps(rest)}
          stickyHeader
        >
          <TableHead className={classes.thead}>
            <TableRow className={classnames(classes.row, classes.headerRow)}>
              {expand && <TableCell padding="none" className={classnames(classes.headerCell, classes.expandHeader)} />}
              {hasBulkActions && (
                <TableCell padding="checkbox" className={classes.headerCell}>
                  <Checkbox
                    className="select-all"
                    color="primary"
                    checked={selectedIds.length > 0 && all.length > 0 && all.every((id) => selectedIds.includes(id))}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}
              {Children.map(children, (field, index) => {
                if (!isValidElement(field)) {
                  return null;
                }

                const disableSort = checkDisableSort(field);
                const headerCellClass = classnames({
                  [classes.headerCell]: true,
                  [classes.disableSort]: disableSort,
                });

                return (
                  <DatagridHeaderCell
                    key={field.props.source || index}
                    resource={resource}
                    field={field}
                    className={headerCellClass}
                    classesObj={classes}
                    currentSort={currentSort}
                    disableSort={disableSort}
                    updateSort={updateSort}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          {cloneElement(
            body,
            {
              basePath,
              className: classes.tbody,
              expand,
              rowClick,
              data,
              hasBulkActions,
              hover,
              ids,
              onToggleItem,
              resource,
              rowStyle,
              selectedIds,
              isRowSelectable,
              version,
            },
            children,
          )}
        </Table>
      )}
    </TableContainer>
  );
});

Datagrid.propTypes = {
  basePath: PropTypes.string,
  body: PropTypes.element,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  // @ts-ignore
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  setSort: PropTypes.func,
  total: PropTypes.number,
  version: PropTypes.number,
  isRowSelectable: PropTypes.func,
};

export default Datagrid;
