/* eslint-disable no-shadow */
import { makeStyles } from '@material-ui/core';
import get from 'lodash/get';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { useDataProvider, useLocale, useTranslate } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import TransferList from '../../../base/components/transfer-list';
import { useApiProperties } from '../../../base/hooks';
import rcSlug from '../../../constant/resource-slug';
import { BORDER_COLOR } from './constants';

const useStyles = makeStyles(() => ({
  leftHeadClassName: {
    backgroundColor: '#e0e0e0',
  },
  leftContentClassName: {
    backgroundColor: '#ffffff',
  },
  rightHeadClassName: {
    backgroundColor: '#e0e0e0',
  },
  rightContentClassName: {
    backgroundColor: '#ffffff',
  },
}));

const GameTransferList = props => {
  const { sourceNamePrefix } = props;
  const source = `${sourceNamePrefix}games`;

  const [listGameData, setListGameData] = useState([]);
  const [gameListOptions, setListGameOptions] = useState([]);
  const [isTransferListInit, setIsTransferInit] = useState(false);
  const [leftLoading, setLeftLoading] = useState(true);
  const [rightLoading, setRightLoading] = useState(true);

  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { values: formValues } = useFormState();
  const form = useForm();

  const { getPropertiesByFieldName } = useApiProperties();
  const propertiesGame = getPropertiesByFieldName(rcSlug.GAME);

  const resources = useSelector(({ admin }) => admin.resources);
  const gameDataFetched = get(resources, 'game.data');

  const getListGameOptions = listGame => listGame.map(game => ({
    value: game.id,
    label: game.name[locale] || game.name.en || game.name,
    enabled: game.enabled,
  }));

  const getListGame = async () => {
    try {
      const { data } = await dataProvider.getList(rcSlug.GAME, {
        pagination: {
          page: 1,
          perPage: propertiesGame?.choiceLimit || 500,
        },
        sort: {
          field: propertiesGame.choiceSort?.field || 'name',
          order: propertiesGame.choiceSort?.order || 'ASC',
        },
        filter: {
          enabled: true,
        },
      });

      if (Array.isArray(data)) {
        setListGameData(data);
      }

      setIsTransferInit(true);
      setLeftLoading(false);

      const gameIds = getListGameOptions(data);
      setListGameOptions(gameIds);
    } catch (err) {
      console.error('[ERROR] Get All Games', err?.message);
    }
  };

  const getLeftInitValues = listGameInit => {
    if (Array.isArray(listGameInit)) {
      const gameIdsExisted = listGameInit.map(item => item.id);
      return gameListOptions.filter(gameId => !gameIdsExisted.includes(gameId.value));
    }
    return gameListOptions;
  };

  const getRightInitValues = listGameInit => {
    if (Array.isArray(listGameInit)) {
      return listGameInit.map(game => ({
        value: game.id,
        label: game.name[locale] || game.name.en || game.name,
        enabled: game.enabled,
      }));
    }
    return [];
  };

  const gameInitValues = useMemo(() => {
    const gamesSelected = get(formValues, source, []) || [];
    return listGameData.filter(gItem => gamesSelected.some(item => item.id === gItem.id));
  }, [listGameData]);

  const allGamesText = translate('ra.text.allGames');
  const availableGamesText = translate('ra.text.availableGames');

  const leftOptions = useMemo(
    () => ({
      initValues: getLeftInitValues(gameInitValues),
      title: (
        <strong
          style={{
            textTransform: 'uppercase',
          }}
        >
          {allGamesText}
        </strong>
      ),
    }),
    [isTransferListInit, locale, gameInitValues, gameListOptions],
  );

  const rightOptions = useMemo(
    () => ({
      initValues: getRightInitValues(gameInitValues),
      title: (
        <strong
          style={{
            textTransform: 'uppercase',
          }}
        >
          {availableGamesText}
        </strong>
      ),
    }),
    [isTransferListInit, locale, gameInitValues, gameListOptions],
  );

  useEffect(() => {
    const listGameFromCache = values(gameDataFetched);
    if (listGameFromCache.length > 0) {
      setListGameData(listGameFromCache);
      setIsTransferInit(true);
      setLeftLoading(false);

      const gameIds = getListGameOptions(listGameFromCache);
      setListGameOptions(gameIds);
    } else {
      getListGame({
        ...formValues,
      });
    }

    setRightLoading(false);
  }, []);

  return (
    <TransferList
      style={{
        boxShadow: 'none',
        border: `1px solid ${BORDER_COLOR}`,
      }}
      itemSourceName="resources.game.name"
      leftOptions={leftOptions}
      rightOptions={rightOptions}
      leftLoading={leftLoading}
      rightLoading={rightLoading}
      classes={{
        leftHead: classes.leftHeadClassName,
        leftContent: classes.leftContentClassName,
        rightHead: classes.rightHeadClassName,
        rightContent: classes.rightContentClassName,
      }}
      onChange={(_leftValues, rightValues) => {
        const gamesSelected = rightValues.map(item => ({
          id: item.value,
        }));
        form.change(source, gamesSelected);
      }}
    />
  );
};

GameTransferList.defaultProps = {
  sourceNamePrefix: '',
};

GameTransferList.propTypes = {
  sourceNamePrefix: PropTypes.string,
};

export default GameTransferList;
