import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../ra/inputs';

const StringInput = ({
  source, ...otherProps
}) => (
  <TextInput
    helperText={false}
    label={`common.fields.${source}`}
    resettable
    source={source}
    {...otherProps}
  />
);

export default StringInput;

StringInput.propTypes = {
  inputProps: PropTypes.object,
  source: PropTypes.string.isRequired,
};

StringInput.defaultProps = {
  inputProps: {},
};
