/* eslint react/prop-types: 0 */
import React, { memo, useEffect, useState } from 'react';
import {
  ReferenceArrayInput,
  FormDataConsumer,
  useTranslate,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import loGet from 'lodash/get';
import { AutocompleteArrayInput } from '../../../base/components/ra/inputs';
import { useSchema, useApiProperties } from '../../../base/hooks';
import { TooltipForDisabledInput } from '../../../base/components/custom-tooltip';
import { useUserFormContext } from '../context/user.context';
import { RoleAccessibility } from '../../../services/util';

const CustomBrandInput = props => {
  const { roleSelected } = useUserFormContext();
  const form = useForm();
  const role = roleSelected || form.getState().values.role;

  const {
    api, ref,
  } = useSchema();

  const translate = useTranslate();

  const { getPropertiesByFieldName } = useApiProperties();
  const properties = getPropertiesByFieldName('brands');

  const [hiddenOnRoleId, setHiddenOnRoleId] = useState();

  useEffect(() => {
    if (api) {
      try {
        const brandHiddenOnRoleId = loGet(
          ref.get('#/components/schemas/UpdateUserDto'),
          [
            'properties',
            'brands',
            'properties',
            'hiddenOn',
            'properties',
            'role.id',
            'default',
          ],
        );
        setHiddenOnRoleId(brandHiddenOnRoleId);
      } catch {
        // Do nothing!
      }
    }
  }, [api]);

  const disabledInputMessage = translate('ra.message.pleaseSelectFirst', {
    smart_name: 'Group',
  });

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const hiddenOn = [role?.id, formData.role?.id].includes(hiddenOnRoleId);
        if (hiddenOn) {
          return null;
        }

        const isCustomRole = role?.accessibility === RoleAccessibility.CUSTOM_ROLE
          && role?.group?.id;

        const filter = {
          'group.id': isCustomRole ? role.group?.id : formData.group?.id,
        };

        const inputDisabledByFilterOn = !formData.group?.id;

        const tooltipDisabledInputProps = {
          title: disabledInputMessage,
          placement: 'top-start',
          showMessage: inputDisabledByFilterOn,
        };

        const optionText = properties.optionText || 'name';

        return (
          <TooltipForDisabledInput {...tooltipDisabledInputProps}>
            <ReferenceArrayInput
              {...props}
              label="resources.user.fields.brands"
              source="brands"
              reference="brand"
              fullWidth
              parse={ids => ids?.map(id => ({
                id,
              }))}
              filter={filter}
              validate={required()}
              format={data => data?.map(d => d.id)}
              disabled={inputDisabledByFilterOn}
              perPage={properties.choiceLimit || 100}
              sort={{
                field: properties.choiceSort?.field || optionText,
                order: properties.choiceSort?.order || 'ASC',
              }}
            >
              <AutocompleteArrayInput optionText={optionText} />
            </ReferenceArrayInput>
          </TooltipForDisabledInput>
        );
      }}
    </FormDataConsumer>
  );
};

export default memo(CustomBrandInput);
