/* eslint-disable react/require-default-props, react/prop-types */
import React from 'react';
import { required, useNotify, useTranslate } from 'react-admin';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Box, Divider, Typography, makeStyles } from '@material-ui/core';
import GroupSelector from '../customized/common-input/GroupSelector';
import CommonSubmitFormWrapper from '../customized/common-wrapper/CommonSubmitFormWrapper';

const requiredValidator = required();
const useStyles = makeStyles({
  root: {
    backgroundColor: '#f5f5f5',
    border: '1px solid rgba(224, 224, 224, 1)',
    "& > *[class*='CardContent']": {
      paddingTop: 10,
    },
    "& > *[class*='Toolbar'][role='toolbar']": {
      minHeight: 'fit-content',
      paddingBottom: 16,
      marginTop: 0,
    },
  },
  paper: {
    borderRadius: 12,
  },
  title: {
    width: '100%',
    '&>*:first-child': {
      marginBottom: 10,
    },
    '& hr': {
      marginLeft: -16,
      width: 'calc(100% + 16px * 2)',
      marginBottom: 10,
    },
  },
});

const GroupConfig = ({
  operatorId, configurationId, groupInfo, groupSelectorProps,
}) => {
  const styles = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmitGroup = async data => {
    try {
      if (configurationId) {
        // Call API to update
        await axios.patch(`/api/${operatorId}/config/${configurationId}`, {
          [groupInfo?.key]: data?.[groupInfo?.key],
        });
        notify(translate('resources.reverse-integration.groupConfig.updatedSuccessfulMessage'), 'success');
      } else {
        // Call API to create
        await axios.post(`/api/${operatorId}/config`, {
          [groupInfo?.key]: data?.[groupInfo?.key],
        });
        notify(translate('resources.reverse-integration.groupConfig.createdSuccessfulMessage'), 'success');
      }
    } catch (error) {
      if (configurationId) {
        notify(translate('resources.reverse-integration.groupConfig.updatedFailedMessage'), 'error');
      } else {
        notify(translate('resources.reverse-integration.groupConfig.createdFailedMessage'), 'error');
      }
    }
  };

  return (
    <CommonSubmitFormWrapper
      containerProps={{
        elevation: 0,
        className: styles.paper,
      }}
      onSubmit={handleSubmitGroup}
      confirmDialogInfo={{
        title: 'resources.reverse-integration.groupConfig.name',
        content: configurationId
          ? 'resources.reverse-integration.groupConfig.editConfirmationMessage'
          : 'resources.reverse-integration.groupConfig.creationConfirmationMessage',
      }}
      className={styles.root}
    >
      <Box className={styles.title}>
        <Typography variant="h6">{translate('resources.reverse-integration.groupConfig.name')}</Typography>
        <Divider />
      </Box>
      <GroupSelector
        variant="outlined"
        defaultValue={groupInfo?.value}
        fullWidth
        validate={requiredValidator}
        sort={{
          field: 'created',
          order: 'DESC',
        }}
        source={groupInfo?.key}
        {...groupSelectorProps}
      />
    </CommonSubmitFormWrapper>
  );
};

GroupConfig.propTypes = {
  operatorId: PropTypes.string.isRequired,
  configurationId: PropTypes.string,
  groupInfo: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  groupSelectorProps: PropTypes.object,
};

GroupConfig.defaultProps = {
  groupInfo: {
    key: 'groupId',
  },
};

export default GroupConfig;
