/* eslint-disable  */
import React, { memo, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  useLocale,
  TranslatableInputs as RaTranslatableInputs,
  useTranslatableContext,
} from "react-admin";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm, useFormState } from "react-final-form";
import pick from "lodash/pick";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
// import RaTranslatableInputs from "./RaTranslatableInput/TranslatableInputs";
import useSetting from "../../../hooks/useSetting";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    borderRadius: 0,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#f5f5f5",
  },
  rootInput: {
    marginBottom: theme.spacing(8),
  },
  error: {
    border: "1px solid red",
    borderRadius: '4px',
    '& > div': {
      margin: 0
    },
  },
}));

const Selector = memo(({ defaultLocale, dataLocales }) => {
  const { locales, selectLocale, selectedLocale } = useTranslatableContext();

  useEffect(() => {
    selectLocale(defaultLocale);
  }, [defaultLocale]);

  useEffect(() => {
    if (!locales.includes(selectedLocale)) {
      const locale = locales.includes(defaultLocale)
        ? defaultLocale
        : locales[0];
      selectLocale(locale);
    }
  }, [locales, selectedLocale]);

  const classes = useStyles();

  const handleChange = (e, value) => {
    selectLocale(value);
  };

  return (
    locales.length > 0 && (
      <Tabs
        classes={{
          root: classes.root,
        }}
        value={selectedLocale}
        indicatorColor="primary"
        onChange={handleChange}
        variant="fullWidth"
      >
        {locales.map((locale) => {
          const { name, icon } = dataLocales[locale];
          return (
            <Tab
              key={locale}
              label={
                <Box display="flex" justifyContent="center" alignItems="center">
                  <i className={`flag flag-24 flag-${icon}`} />
                  <Box ml={3}>{name}</Box>
                </Box>
              }
              value={locale}
            />
          );
        })}
      </Tabs>
    )
  );
});

Selector.propTypes = {
  dataLocales: PropTypes.object.isRequired,
  defaultLocale: PropTypes.string.isRequired,
};

const TranslatableInputs = (props) => {
  const { children, record, resource, basePath, groupKey } = props;

  const classes = useStyles();

  const defaultLocale = useLocale();
  const dataLanguage = useSelector((state) =>
    Object.values(state.admin.resources.language.data || {})?.sort(
      (a, b) => a?.created - b?.created
    )
  );

  const { values: valueTranslation } = useFormState();
  const { change: onChange } = useForm();

  const locales = useMemo(
    () => dataLanguage.map((lang) => lang.locale),
    [dataLanguage]
  );

  const dataLocales = useMemo(
    () =>
      dataLanguage.reduce(
        (acc, lang) => ({
          ...acc,
          [lang.locale]: {
            name: lang.name,
            icon: lang.icon,
          },
        }),
        {}
      ),
    [dataLanguage]
  );

  useEffect(() => {
    if (!record?.id) {
      const funcs = [];
      const sources = Array.isArray(children)
        ? children.map((c) => c.props.source)
        : [children.props.source];
      const valuesNeedCheck = pick(valueTranslation, sources);

      Object.keys(valuesNeedCheck).forEach((source) => {
        const valueCheck = valuesNeedCheck[source] || {};
        Object.keys(valueCheck).forEach((locale) => {
          if (!locales.includes(locale)) {
            const newValue = omit(valueCheck, [locale]);
            funcs.push({
              name: source,
              value: newValue,
            });
          }
        });
      });

      funcs.forEach(({ name, value }) => onChange(name, value));
    }
  }, [locales]);

  const setting = useSetting();

  const systemDefaultLangauge = dataLanguage?.find(
    (l) => l.id === setting?.default_language
  );

  const { getState } = useForm();

  const isError = !isEmpty(getState()?.errors?.[props.source]);

  return (
    <div className={clsx(classes.rootInput, isError ? classes.error : "")}>
      {locales.length > 0 && (
        <RaTranslatableInputs
          record={record}
          resource={resource}
          basePath={basePath}
          selector={
            <Selector
              defaultLocale={defaultLocale}
              dataLocales={dataLocales}
              source={props.source}
            />
          }
          locales={locales}
          defaultLocale={defaultLocale}
          groupKey={groupKey}
          systemDefaultLocale={systemDefaultLangauge?.locale}
        >
          {children}
        </RaTranslatableInputs>
      )}
    </div>
  );
};

TranslatableInputs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object,
  groupKey: PropTypes.string,
};

TranslatableInputs.defaultProps = {
  groupKey: null,
  record: {},
};

export default memo(TranslatableInputs);
