import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Button, useTranslate } from 'react-admin';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import { goToHomePage } from '../../../services/util';

const FinalStepDialog = props => {
  const {
    isVisible,
    subject,
    content,
    onClose,
  } = props;

  const [open, setOpen] = useState(isVisible);
  const descriptionElementRef = useRef(null);
  const translate = useTranslate();

  const handleClose = (_event, reason) => {
    if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
      _event.stopPropagation();
      return;
    }

    setOpen(false);
    goToHomePage();
    if (onClose instanceof Function) {
      const shouldClose = onClose();
      // prevent the unnecessary close event while we're trying to display multiple announcements from this popup
      if (!shouldClose) {
        return;
      }
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>{subject}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          ref={descriptionElementRef}
          component="div"
          tabIndex={-1}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<HomeIcon />}
          label={translate('ra.action.homePage')}
          style={{
            margin: '8px 16px',
          }}
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

FinalStepDialog.defaultProps = {};

FinalStepDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  subject: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FinalStepDialog;
