/* eslint-disable react/require-default-props, react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { sanitizeInputRestProps, InputHelperText, ResettableTextField } from 'react-admin';

const convertStringToNumber = value => {
  const float = Number.parseInt(value, 10);

  return Number.isNaN(float) ? null : float;
};

/**
 * An Input component for a integer only
 *
 * @example
 * <NumberInput source="nb_views" />
 *
 * You can customize the `step` props (which defaults to "any")
 * @example
 * <NumberInput source="nb_views" step={1} />
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 */
const NumberInput = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = convertStringToNumber,
  resource,
  source,
  step,
  min,
  max,
  validate,
  variant = 'outlined',
  inputProps: overrideInputProps,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: {
      error, submitError, touched,
    },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const handleOnChange = e => {
    const regex = new RegExp(/^[0-9]*$/);
    if (!e.target || (regex.test(e.target.value) && e.target.value <= 999999999999999)) input.onChange(e);
  };

  const inputProps = {
    ...overrideInputProps,
    step,
    min,
    max,
  };

  return (
    <ResettableTextField
      id={id}
      {...input}
      variant={variant}
      error={!!(touched && (error || submitError))}
      helperText={(
        <InputHelperText
          touched={touched}
          error={error || submitError}
          helperText={helperText}
        />
      )}
      label={(
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      )}
      margin={margin}
      inputProps={inputProps}
      {...options}
      {...sanitizeInputRestProps(rest)}
      onChange={e => handleOnChange(e)}
    />
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NumberInput.defaultProps = {
  options: {},
  step: 'any',
  // textAlign: 'right',
  label: '',
};

export default NumberInput;
