/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import {  NumberField } from '../../../../base/components/ra/fields';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-operator-profit.list';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import BaseCurrencyField from '../../../../base/components/reports/ReportTable/BaseCurrencyField';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';

const StandardTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();

  return (
    <CustomReportTable
      title={translate('resources.report.standard')}
      reportType={REPORT_TYPES.STANDARD}
      reportList={LIST_REPORTS}
      resource={resourceSlug.REPORT_OPERATOR_PROFIT}
    >
      <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.report.fields.betType" source="betType" sortable={false} />
      <NumberField label="resources.report.fields.betCount" source="betCount" sortable={false} />
      <NumberField label="resources.report.fields.playerCount" source="playerCount" sortable={false} />
      <NumberField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField
        link="show"
        source="currency"
        reference="currency"
        label="resources.currency.name"
        sortable={false}
      >
        <TextField source="code" />
      </ReferenceField>
      {Boolean(appliedFilters.consolidated) && <BaseCurrencyField label="resources.report.fields.baseCurrency" />}
      <TextField label="resources.report.fields.betAmount" source="betAmount" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
      <TextField label="resources.report.fields.profit" source="profit" sortable />
      <TextField label="resources.report.fields.rtp" source="rtp" sortable />
    </CustomReportTable>
  );
};

export default StandardTable;
