/* eslint-disable */
import React from 'react';
import ReportDateRangeInput from '../../../../base/components/reports/ReportFilterWrapper/ReportDateRangeInput';
import CurrencyTypeSelector from '../../../../base/components/reports/ReportFilterWrapper/CurrencyTypeSelector';
import CurrencyInput from '../../../../base/components/reports/ReportFilterWrapper/CurrencyInput';
import GroupInput from '../../../../base/components/reports/ReportFilterWrapper/GroupInput';
import BrandInput from '../../../../base/components/reports/ReportFilterWrapper/BrandInput';
import { useAuthUser } from '../../../../base/hooks';
import ConsolidatedInput from '../../../../base/components/reports/ReportFilterWrapper/ConsolidatedInput';
import ChannelSelect from '../../../../base/components/reports/ReportFilterWrapper/ChannelSelect';
import BetTypeSelect from '../../../../base/components/reports/ReportFilterWrapper/BetTypeSelect';
import GameInput from '../../../../base/components/reports/ReportFilterWrapper/GameInput';
import GameIdInput from '../../../../base/components/reports/ReportFilterWrapper/GameIdInput';
import IntervalSelect from './IntervalSelect';

const GamePerformanceReportFilters = ({ reportServiceVersion }) => {
  const user = useAuthUser();

  const isUnderGroupManagement = Boolean(user?.group?.id);

  return (
    <>
      <ReportDateRangeInput reportServiceVersion={reportServiceVersion} />
      {!isUnderGroupManagement && <GroupInput />}
      <BrandInput />
      <GameIdInput />
      <ConsolidatedInput />
      <CurrencyTypeSelector />
      <CurrencyInput />
      <GameInput />
      <ChannelSelect />
      <BetTypeSelect />
      <IntervalSelect />
    </>
  );
};

export default GamePerformanceReportFilters;
