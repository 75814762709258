/* eslint-disable react/prop-types */
import React from 'react';
import { useLocale } from 'ra-core';
import { Labeled, ReferenceField, TextField } from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { ActionMenu } from './components/action.menu';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import resourceSlug from '../../constant/resource-slug';

const BetShow = props => {
  const { permissions } = props;
  const locale = useLocale();

  return (
    <WealthShowGuesser
      {...props}
      actionMenu={(
        <ActionMenu
          type="toolbar"
          permissions={permissions}
        />
      )}
      excludeFields={['player', 'game', 'currency']}
      extraInformBlocks={[
        <Labeled
          label="resources.game.name"
          source="game"
        >
          <CustomLinkField
            source="game"
            href={record => `/#/${resourceSlug.GAME}/${record.game?.id}/show`}
            title={record => record.game?.name?.[locale]}
            {...props}
          />
        </Labeled>,
        <Labeled
          label="resources.currency.name"
          source="currency"
        >
          <CustomLinkField
            source="currency"
            href={record => `/#/${resourceSlug.CURRENCY}/${record.currency?.id}/show`}
            title={record => record.currency?.name}
            {...props}
          />
        </Labeled>,
        <Labeled
          label="resources.player.name"
          source="player.id"
        >
          <ReferenceField
            source="player.id"
            reference="player"
            link="show"
          >
            <TextField source="nativeId" />
          </ReferenceField>
        </Labeled>,
      ]}
      informFieldOrder={[
        'groupId',
        'brandId',
        'player.id',
        'game',
        'amount',
        'currency',
        'earn',
        'betType',
        'betSize',
        'betLevel',
        'playerBalance',
        'endingBalance',
        'betStatus',
        'id',
        'channel',
        'endTime',
      ]}
    />
  );
};

export default BetShow;
