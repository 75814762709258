import { SETTINGS, TOGGLE_NAV_COLLAPSED } from '../root.actions';

const initialSettings = {
  navCollapsed: false,
  commonSettings: null,
};

export const settingReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
      };

    case TOGGLE_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: action.payload,
      };

    case SETTINGS:
      return {
        ...state,
        commonSettings: action.settings,
      };
    default:
      return state;
  }
};
