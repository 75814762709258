import { GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_FAILED, GET_ANNOUNCEMENTS_SUCCESS } from '../root.actions';

const initialState = {
  announcements: [],
};

export const announcementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
      };
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: action.payload.announcements || [],
      };

    case GET_ANNOUNCEMENTS_FAILED:
      return {
        ...state,
        announcements: [],
      };

    default:
      return state;
  }
};
