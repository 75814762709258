import pick from 'lodash/pick';
import { csvNumberFormatter } from '../../../../../services/util';
import { addExportListener } from './exporters.util';

const salesAndProfitExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;

  const brand = await fetchRelatedRecords(records, 'brand', 'brand');
  const currency = await fetchRelatedRecords(records, 'currency', 'currency');
  const game = await fetchRelatedRecords(records, 'gameId', 'game');

  const saveAndProfitForExport = records.map(data => {
    const allData = {
      ...data,
      betAmount: csvNumberFormatter(data.betAmount),
      wonAmount: csvNumberFormatter(data.wonAmount),
      rtp: csvNumberFormatter(data.rtp),
      profit: csvNumberFormatter(data.profit),
      brand: brand[data.brand]?.name,
      gameId: game[data.gameId]?.gameId,
      currency: currency[data.currency]?.code,
    };

    const result = {
      ...pick(allData, [
        'id',
        'brand',
        'gameId',
        'gameName',
        'gameType',
        'channel',
        'accountType',
        'betType',
        'betCount',
        'playerCount',
        'currency',
        'betAmount',
        'wonAmount',
        'rtp',
        'profit',
      ]),
    };

    return result;
  });

  addExportListener({
    dataList: saveAndProfitForExport,
    dateString: date,
    fileName: 'Sales And Profit Report',
  });
};

export default salesAndProfitExporter;
