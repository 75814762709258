import React from 'react';
import { ArrayField as RaArrayField } from 'react-admin';
import PropTypes from 'prop-types';
import FieldWrapper from './field-wrapper';

const ArrayField = props => {
  const {
    hasEllipsis,
    ...restProps
  } = props;

  return hasEllipsis ? (
    <FieldWrapper {...restProps}>
      <RaArrayField {...restProps} />
    </FieldWrapper>
  ) : (
    <RaArrayField {...restProps} />
  );
};

ArrayField.propTypes = {
  hasEllipsis: PropTypes.bool,
};

ArrayField.defaultProps = {
  hasEllipsis: false,
};

export default ArrayField;
