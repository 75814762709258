/* eslint-disable react/prop-types, consistent-return */
import React, { useEffect, memo } from 'react';
import { useTranslate, FormDataConsumer, required, minValue, useLocale } from 'ra-core';
import { AutocompleteInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import { useForm } from 'react-final-form';
import { useAuthUser } from '../../../base/hooks';
import { ReferenceInput as ReferenceInputCustom } from '../../../base/components/ra/inputs';
import BetSettingPicker from '../../../base/components/rmc-picker-custom/bet-setting-picker.input';
import { BetSettingPickerProvider } from '../../../base/components/rmc-picker-custom/bet-setting-picker.context';
import { useBetSettingFormContext } from '../utils/bet-setting-form.context';
import { checkSystemAdmin, checkTransatableField } from '../../../services/util';
import { getBetSettingInfo, validateTotalBetMax } from '../../../services/util/bet-setting.utils';
import { testByRegex } from '../../../services/util/validate/validateMethods';
import useTranslateSchemaRef from '../../../base/hooks/useTranslateSchemaRef';
import useTranslateSchema from '../../../base/hooks/useTranslateSchema';
import { TWO_DECIMAL_REGEX } from '../../../services/util/validate/regularExpression';
import NumberInput from '../../../base/components/ra/inputs/commonNumber.input';

const BetSettingRefInput = memo(props => {
  const locale = useLocale();

  let optionText = 'name';

  let isTranslatable = false;

  if (props.reference === 'game') {
    isTranslatable = checkTransatableField(props.record.game, 'name', locale);
  }

  if (isTranslatable) {
    optionText = `name.${locale}`;
  }

  return (
    <ReferenceInputCustom
      fullWidth
      validate={required()}
      perPage={100}
      variant="outlined"
      disabled
      {...props}
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInputCustom>
  );
});

const BetSettingEditForm = ({ record }) => {
  const translate = useTranslate();
  const user = useAuthUser();
  const form = useForm();
  const translateSchema = useTranslateSchema();
  const translateSchemaRef = useTranslateSchemaRef();

  const baseBet = get(record, 'game.baseBet');

  const {
    // Default bet setting from API
    defaultSettingValue,
    // Current setting default
    currentSettingDefaultValue,
    setCurrentSettingDefaultValue,
    // Current setting
    currentSettingValue,
    setCurrentSettingValue,
    // Utils
    resetBetSettingDefault,
    getBetSettingDefault,
  } = useBetSettingFormContext();

  const isSystemAdmin = checkSystemAdmin(user);

  const totalBetMaxMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMax',
    },
  });

  const totalBetMinMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMin',
    },
  });

  const onlyTwoDigits = fieldName => testByRegex(translateSchemaRef, {
    regex: TWO_DECIMAL_REGEX,
    translationSchema: {
      name: 'ra.validation.onlyTwoDigitsAfter',
      params: {
        smart_name: fieldName,
      },
    },
  });

  /**
   * Preload Bet Setting Default from `game` and `currency`
   */
  useEffect(() => {
    if (!isEmpty(record?.game?.id) && !isEmpty(record?.currency?.id)) {
      getBetSettingDefault(record?.game?.id, record?.currency?.id);
    }
  }, [record]);

  /**
   * Display setting from `record`
   */
  useEffect(() => {
    const [listSettingObj, listSettingDefaultObj] = getBetSettingInfo(record);
    setCurrentSettingValue(listSettingObj);
    setCurrentSettingDefaultValue(listSettingDefaultObj);

    form.change('totalBetMin', record?.totalBetLimit?.[0]);
    form.change('totalBetMax', record?.totalBetLimit?.[1]);
  }, [record]);

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Box>
          {isSystemAdmin && (
            <BetSettingRefInput
              label="resources.group.name"
              source="group.id"
              reference="group"
            />
          )}

          <BetSettingRefInput
            label="resources.brand.name"
            source="brand.id"
            reference="brand"
          />

          <BetSettingRefInput
            label="resources.game.name"
            source="game.id"
            reference="game"
            record={formData}
          />

          <BetSettingRefInput
            label="resources.currency.name"
            source="currency.id"
            reference="currency"
            filter={{
              enabled: true,
            }}
          />

          <NumberInput
            fullWidth
            source="totalBetMin"
            label={translate('ra.field.totalBetMin')}
            validate={[required(), minValue(0.01, totalBetMinMustGreatThanZero), onlyTwoDigits('ra.field.totalBetMin')]}
          />

          <NumberInput
            fullWidth
            source="totalBetMax"
            label={translate('ra.field.totalBetMax')}
            validate={[
              required(),
              minValue(0, totalBetMaxMustGreatThanZero),
              onlyTwoDigits('ra.field.totalBetMax'),
              validateTotalBetMax(translateSchemaRef),
            ]}
          />

          <BetSettingPickerProvider>
            <BetSettingPicker
              source="picker"
              baseBetInit={baseBet}
              listItemInit={currentSettingValue}
              defaultValueInit={currentSettingDefaultValue}
              onResetSetting={isEmpty(defaultSettingValue) ? null : resetBetSettingDefault}
            />
          </BetSettingPickerProvider>
        </Box>
      )}
    </FormDataConsumer>
  );
};

export default BetSettingEditForm;
