/* eslint-disable  */
import React from "react";
import pick from "lodash/pick";
import WealthEditGuesser from "../../base/components/guesser/wealth-edit.guesser";
import WealthInputWrapper from "../../base/components/guesser/wealth-input-wrapper";
import CustomRoleInput from "./components/custom-role.input";
import CustomBrandInput from "./components/custom-brand.input";
import { UserFormContextProvider } from "./context/user.context";
import { FormDataConsumer } from "ra-core";

const UserEdit = (props) => {
  const transform = (data) => {
    return pick(data, [
      "brands",
      "role",
      "enabled",
      "desc",
      "email",
      "firstName",
      "lastName",
      "phone",
    ]);
  };

  return (
    <UserFormContextProvider>
      {({ customFields }) => (
        <WealthEditGuesser
          {...props}
          transform={transform}
          customFields={["role", ...customFields]}
        >
          <WealthInputWrapper source="role">
            <FormDataConsumer>
              {({ formData: record }) => (
                <CustomRoleInput isEdit record={record} />
              )}
            </FormDataConsumer>
          </WealthInputWrapper>
          {!customFields.includes("brands") && (
            <WealthInputWrapper source="brands">
              <CustomBrandInput />
            </WealthInputWrapper>
          )}
        </WealthEditGuesser>
      )}
    </UserFormContextProvider>
  );
};

export default UserEdit;
