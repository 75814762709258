import { addExportListener } from './exporters.util';

const reportOperatorProfitExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider, _resource, additionData = {},
) => {
  const { date } = additionData;

  const brand = await fetchRelatedRecords(records, 'brand', 'brand');
  const currency = await fetchRelatedRecords(records, 'currency', 'currency');

  const operatorProfitForExport = records.map(data => {
    const result = {
      id: data.id,
      brand: brand[data.brand]?.name,
      betType: data.betType,
      country: data.country,
      channel: data.channel,
      betCount: data.betCount,
      playerCount: data.playerCount,
      currency: currency[data.currency]?.code,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
      wonAmount: data.wonAmount?.toString?.()?.replace?.('.', ','),
      rtp: data.rtp?.toString?.()?.replace?.('.', ','),
      profit: data.profit?.toString?.()?.replace?.('.', ','),
    };

    return result;
  });

  addExportListener({
    dataList: operatorProfitForExport,
    dateString: date,
    fileName: 'Operator profit Report',
  });
};

export default reportOperatorProfitExporter;
