/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, update } from 'firebase/database';

import { getBackofficeEnv } from '../util';
import resourceSlug from '../../constant/resource-slug';

export const initFirebase = () => {
  const boEnv = getBackofficeEnv();
  initializeApp({
    apiKey: boEnv.FIREBASE_API_KEY,
    authDomain: boEnv.FIREBASE_AUTH_DOMAIN,
    databaseURL: boEnv.FIREBASE_DATABASE_URL,
    projectId: boEnv.FIREBASE_PROJECT_ID,
    storageBucket: boEnv.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: boEnv.FIREBASE_MESSAGING_SENDER_ID,
    appId: boEnv.FIREBASE_APP_ID,
  });
};

const toastDisplaying = {};
export const informProcessStatusByUser = (dbPath, MessageComponent) => {
  const cleanNotificationData = async () => {
    try {
      const db = getDatabase();
      await update(ref(db), {
        [dbPath]: null,
      });
    } catch (err) {
      console.debug('[ERROR] at clean notification on firebase realtime db\n', err.message);
    }
  };

  // if (!toastDisplaying[dbPath]) {
  //   window.addEventListener('beforeunload', cleanNotificationData);
  // }

  const handleNewValueChanging = (snapshot) => {
    const rawData = snapshot.val();
    let data = {
      ...rawData,
    };

    // Need to wait data and language to display with correct locale
    if (isEmpty(data)) {
      if (toastDisplaying[dbPath]) {
        toast.dismiss(toastDisplaying[dbPath]);
      }
      return;
    }

    // Quick-fix: Filter to remove exported report notifications in their export history detail page
    const paths = window.location.hash.split('/');
    data = Object.keys(data).reduce((currentData, currentTimestamp) => {
      const noti = data[currentTimestamp];

      if (!(paths.includes(noti.id) && paths.includes(resourceSlug.EXPORT_HISTORY))) {
        currentData[currentTimestamp] = noti;
      }

      return currentData;
    }, {});

    if (!Object.keys(data).length) {
      return;
    }

    if (toastDisplaying[dbPath]) {
      toast.update(toastDisplaying[dbPath], {
        data,
      });
    } else {
      // Display notification as Toast.
      // Only click [x] to close this one.
      const toastId = toast(<MessageComponent />, {
        type: 'success',
        autoClose: false,
        icon: false,
        draggable: false,
        pauseOnHover: false,
        closeOnClick: false,

        // When component haven't mounted yet.
        // Need to delay to display stable!
        delay: 100,

        // Data is passed into message component.
        data,

        onClose: async () => {
          toastDisplaying[dbPath] = false;
          await cleanNotificationData();
        },
      });

      // Store toastId at the first displaying.
      toastDisplaying[dbPath] = toastId;
    }
  };

  try {
    const db = getDatabase();
    const address = ref(db, dbPath);
    onValue(address, handleNewValueChanging, (err) => {
      console.debug('[ERROR] at firebase subscribe value changing\n', err.message);
    });
  } catch (err) {
    console.debug('[ERROR] before firebase subscribe value changing\n', err.message);
  }
};
