import React from 'react';
import { ReferenceArrayInput } from 'react-admin';
import EnsurePermission from '../../../base/components/EnsurePermission';
import resourceSlug from '../../../constant/resource-slug';
import { AutocompleteArrayInput } from '../../../base/components/ra/inputs';

const FeaturesInput = props => (
  <EnsurePermission
    canRead
    resource={resourceSlug.FEATURES}
  >
    <ReferenceArrayInput
      label="resources.feature-flag.name"
      source="features"
      reference={resourceSlug.FEATURES}
      format={items => items?.map(item => item?.id || item)}
      filterToQuery={value => ({
        'name||$cont': value || '',
        isActive: true,
      })}
      {...props}
    >
      <AutocompleteArrayInput variant="outlined" />
    </ReferenceArrayInput>
  </EnsurePermission>
);

export default FeaturesInput;
