/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { alpha } from '@material-ui/core/styles';
import { useFormState, useForm } from 'react-final-form';
import { get } from 'lodash';
import classNames from 'classnames';

import {
  ReferenceInput,
} from '../../../../base/components/ra/inputs';
import AutoCompleteCustomInput from './AutoCompleteCustomInput';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    padding: '8px 24px 16px',
    background: '#e5e5e5',

    '& [class^="MuiFormHelperText-root"]': {
      display: 'none',
    }
  },
  tableRowContainer: {
    borderBottom: '1px solid #8080808c',
  },
  tableRow: {
    padding: '12px 30px',
    '&:hover': {
      background: '#e5e5e596',
    },

    [theme.breakpoints.up('md')]: {
      padding: '16px 80px',
    },
  },

  active: {
    background: `${alpha(theme.palette.primary.light, 0.5)} !important`,
  },

  verticalMiddle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  deleteIcon: {
    left: 24,
    color: theme.palette.error.main,
    cursor: 'pointer',
  },

  endAdornmentSt: {
    right: 24,
    color: theme.palette.error.main,
  },

}), {
  name: 'MultipleAutoComplete_Style',
});

const ListEmpty = ({ text }) => {
  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '36px',
        color: '#848484',
      }}
    >
      {text}
    </Box>
  );
};

const MultipleAutoComplete = props => {
  const {
    optionText,
    fieldComparison,
    label,
    source,
    name,
    reference,
    filter,
    filterToQuery,
    onAdd,
    onDelete,
    afterChange,
    itemChosenOnClick,
    activeItem,
    title,
    listHeight,
    itemsChosen: initItemsChosen,
    renderEndAdornment,
    metadata,
    listEmptyMessage,
  } = props;

  const classes = useStyles();
  const muiTheme = useTheme();

  const [itemsChosen, setItemsChosen] = useState(initItemsChosen || []);
  const { values: formValues } = useFormState();
  const form = useForm();
  const choiceRef = useRef();

  const newValueSelected = get(formValues, source);

  useEffect(() => {
    if (newValueSelected) {
      const newItemsChosen = [newValueSelected, ...itemsChosen];
      setItemsChosen(newItemsChosen);

      form.change(source, undefined);

      if (typeof onAdd === 'function') {
        onAdd(newValueSelected);
      }
      if (typeof afterChange === 'function') {
        afterChange(newItemsChosen);
      }
    }
  }, [newValueSelected]);

  return (
    <Box>
      <Box>
        <Box className={classes.tableHeader}>
          <Box textAlign="center">{title}</Box>

          <ReferenceInput
            fullWidth
            className={classNames(
              'CustomRaInput_none-label-focused',
              'CustomRaInput_none-loading-icon',
            )}
            label={label}
            source={source}
            reference={reference}
            filter={filter}
            filterToQuery={filterToQuery}
            perPage={100}
            InputLabelProps={{
              shrink: false,
            }}
          >
            <AutoCompleteCustomInput
              choiceRef={choiceRef}
              optionText={optionText}
              itemsChosen={itemsChosen}
              fieldComparison={fieldComparison}
            />
          </ReferenceInput>
        </Box>
        <Box
          style={{
            height: listHeight,
            overflow: 'auto',
          }}
        >

          {itemsChosen.length > 0 ? itemsChosen.map(item => {
            const displayName = get(metadata, [item, optionText]) || get(choiceRef.current, [item, optionText]);
            return displayName ? (
              <Box
                key={item}
                className={classNames(classes.tableRowContainer, {
                  [classes.active]: activeItem === item,
                })}
                onClick={() => {
                  const optionValue = choiceRef.current[item];
                  if (typeof itemChosenOnClick === 'function' && optionValue) {
                    itemChosenOnClick(optionValue);
                  }
                }}
              >
                <Box
                  position="relative"
                  textAlign="center"
                  className={classes.tableRow}
                >
                  <DeleteIcon
                    className={classNames(classes.deleteIcon, classes.verticalMiddle)}
                    onClick={e => {
                      e.stopPropagation();
                      const newItemsChosen = itemsChosen.filter(curItem => curItem !== item);
                      setItemsChosen(newItemsChosen);

                      if (typeof onDelete === 'function') {
                        onDelete(item);
                      }

                      if (typeof afterChange === 'function') {
                        afterChange(newItemsChosen);
                      }
                    }}
                  />

                  <span>{displayName}</span>

                  {renderEndAdornment
                    ? (
                      <Box className={classNames(classes.endAdornmentSt, classes.verticalMiddle)}>
                        {typeof renderEndAdornment === 'function' ? renderEndAdornment(item) : renderEndAdornment}
                      </Box>
                    )
                    : null}
                </Box>
              </Box>
            ) : null;
          }) : <ListEmpty text={listEmptyMessage} />}
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleAutoComplete;
