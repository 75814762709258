/* eslint-disable import/no-cycle */
import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  makeStyles,
  TextField,
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Axios from 'axios';
import { useNotify, useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Toolbar, TopToolbar, useRefresh } from 'react-admin';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { useError, useSchema, useAuthUser } from '../../../base/hooks';
import OwnerChangePasswordForm from '../../../base/components/change-password/owner-change-password';
import CustomTooltip from '../../../base/components/custom-tooltip';
import { withStandardButtonProps } from '../../../base/hoc/with-standard-button-props';
import { fetchUser } from '../../../services/provider/authProvider';
import { guessProperty } from '../../../base/components/guesser/wealth-guesser';
import { setAuthInfo } from '../../../services/redux/auth/auth.action';

const Button = withStandardButtonProps(MuiButton);

const useStyle = makeStyles(theme => ({
  wrapperAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(6),
    },
  },
  chip: {
    marginBottom: theme.spacing(2),
  },
  main: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  bottomToolbar: {
    [theme.breakpoints.up('sm')]: {
      left: theme.spacing(-4),
      width: `calc(100% + ${theme.spacing(8)}px)`,
      bottom: theme.spacing(-6),
    },
  },
  topToolbar: {
    '& button': {
      padding: '6px 16px',
    },
  },
  btnSubmit: {},
}));

const Profile = () => {
  const classes = useStyle();
  const t = useTranslate();
  const notify = useNotify();
  const [guessedInfo, setGuessedInfo] = useState({});
  const { notifyError } = useError();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  const [stateUser, setStateUser] = useState({
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
  });

  const {
    api, ref,
  } = useSchema();

  const user = useAuthUser();

  useEffect(() => {
    if (api) {
      const { paths } = api;
      let requestRef = paths?.['/auth/update-profile']?.post?.requestBody?.content?.[
        'application/json'
      ]?.schema;
      requestRef = requestRef?.$ref;

      const requestComponent = ref.get(requestRef);
      const {
        properties, required,
      } = requestComponent;

      const writableFields = Object.keys(properties);

      const components = writableFields?.map(i => guessProperty({
        source: i,
        properties,
        apiRef: ref,
        view: 'edit',
        resource: 'user',
        resources: [],
        required,
      }));
      setGuessedInfo({
        components,
      });
    }
  }, [api]);

  useEffect(() => {
    if (user?.id) {
      setStateUser(pick(user, ['phone', 'lastName', 'firstName', 'email']));
    }
  }, [user?.id]);

  const renderUserStatus = () => {
    let color;
    switch (user?.status?.name) {
      case 'Active':
        color = 'primary';
        break;
      case 'Locked':
        color = 'secondary';
        break;
      default:
        color = 'default';
        break;
    }

    return (
      <Chip
        label={`${t('resources.status.name')}: ${user?.status?.name}`}
        color={color}
        className={classes.chip}
      />
    );
  };

  const handleSubmitForm = async values => {
    let success;
    const {
      phone = null,
      email = '',
      firstName = null,
      lastName = null,
    } = values;
    try {
      await Axios.post('api/auth/update-profile', {
        phone,
        email,
        firstName,
        lastName,
      });
      notify(
        t('ra.notification.updated', {
          smart_name: t('ra.text.profile'),
        }),
        'success',
      );
      refresh();
      fetchUser(true); // update cache after change profile
      dispatch(setAuthInfo(values));
      success = true;
    } catch (e) {
      notifyError(e);
    }

    return success;
  };

  const [openModalPassword, setOpenModalPassword] = useState(false);

  return (
    <>
      <OwnerChangePasswordForm
        record={user}
        open={openModalPassword}
        close={() => setOpenModalPassword()}
      />
      <Box className={classes.main}>
        <TopToolbar className={classes.topToolbar}>
          {renderUserStatus()}
          <Box>
            <CustomTooltip
              title={t('ra.action.changePassword')}
              placement="top"
            >
              <MuiButton
                onClick={() => {
                  setOpenModalPassword(true);
                }}
                label=""
                size="small"
                color="primary"
              >
                <VpnKeyIcon />
              </MuiButton>
            </CustomTooltip>
          </Box>
        </TopToolbar>
        <Card>
          <CardContent>
            <Form
              initialValues={stateUser}
              onSubmit={async (values, form) => {
                const success = await handleSubmitForm(values);
                if (success) {
                  setTimeout(() => {
                    form.reset(values);
                  }, 100);
                }
              }}
              render={({
                handleSubmit, valid, saving, pristine,
              }) => (
                <form
                  className={classes.root}
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <TextField
                    id="username-id"
                    value={user.username}
                    label={t('resources.user.fields.username')}
                    disabled
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    id="role-id"
                    label={t('resources.user.fields.role')}
                    value={user.role?.name}
                    disabled
                    fullWidth
                    margin="normal"
                  />
                  <div
                    style={{
                      padding: '10px 0',
                    }}
                  />
                  {guessedInfo?.components}
                  <Toolbar className={classes.bottomToolbar}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      width="100%"
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className={classes.btnSubmit}
                        margin="normal"
                        disabled={pristine || saving || !valid}
                      >
                        {t('ra.action.save')}
                      </Button>
                    </Box>
                  </Toolbar>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Profile;
