/* eslint-disable consistent-return */
import { useTranslate } from 'ra-core';
import upperCase from 'lodash/upperCase';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import lowerFirst from 'lodash/lowerFirst';
import keys from 'lodash/keys';

export const TranslateSchemaType = {
  UPPERCASE: 'UPPERCASE',
  UPPER_FIRST: 'UPPER_FIRST',
  LOWERCASE: 'LOWERCASE',
  LOWER_FIRST: 'LOWER_FIRST',
};

/**
 * Example translation data
 *
 * ra: {
 *   test: {
 *     mustGreatThanZero: '%{smart_name} is not zero',
 *     field: 'field %{field_name}',
 *     bothField: '%{field_a} and ${field_b}',
 *   },
 * }
 *
 * Translation schema => structure: {name, params, transform}
 * {
 *   name: 'ra.test.mustGreatThanZero',
 *   params: {
 *     smart_name: {
 *       name: 'ra.test.field',
 *       params: {
 *         field_name: {
 *           name: 'ra.test.bothField',
 *           params: {
 *             field_a: {
 *               name: 'bet size',
 *             },
 *             field_b: {
 *               name: 'bet level',
 *               transform: TranslateSchemaType.UPPERCASE,
 *             },
 *           },
 *         },
 *       },
 *     },
 *   },
 * }
 */

const useTranslateSchema = () => {
  const translate = useTranslate();

  const translateTransform = (name, transform, params) => {
    const text = translate(name, params);

    switch (transform) {
      case TranslateSchemaType.UPPERCASE:
        return upperCase(text);
      case TranslateSchemaType.UPPER_FIRST:
        return upperFirst(text);
      case TranslateSchemaType.LOWERCASE:
        return lowerCase(text);
      case TranslateSchemaType.LOWER_FIRST:
        return lowerFirst(text);
      default:
        return text;
    }
  };

  const translateParam = param => {
    if (typeof param === 'string') {
      return translate(param);
    }

    if (typeof param === 'object' && 'name' in param) {
      return translateTransform(param.name, param.transform);
    }

    return '';
  };

  const generateTranslation = schema => {
    const params = {};
    keys(schema.params).forEach(pKey => {
      if (typeof schema.params?.[pKey] === 'object' && 'params' in schema.params?.[pKey]) {
        const translated = generateTranslation(schema.params[pKey]);
        params[pKey] = translated;
        return;
      }

      params[pKey] = translateParam(schema.params[pKey]);
    });

    return translateTransform(schema.name, schema.transform, params);
  };

  return function translateSchema(schema) {
    if (!schema?.name) {
      console.error('Error: generate translate mismatch');
      return null;
    }

    // Translation default is upper-first
    const newSchema = {
      transform: TranslateSchemaType.UPPER_FIRST,
      ...schema,
    };

    const result = generateTranslation(newSchema);

    // Cannot find translation for input name => return itself
    if (lowerCase(schema.name) === lowerCase(result)) {
      return schema.name;
    }

    return result;
  };
};

export default useTranslateSchema;
