import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

import { API_URL } from '../../constant';
import LocalStorage, { FIREBASE_AUTH_TOKEN_KEY } from '../../services/local-storage';
import useAuthUser from './useAuthUser';
import { informProcessStatusByUser } from '../../services/firebase';
import {
  CheatModeCreatingProcessMessage,
  ExportProcessMessage,
  PrepaidProcessMessage,
} from '../components/react-toastify-custom-msg';

const useFirebase = () => {
  const user = useAuthUser();

  useEffect(() => {
    (async () => {
      if (!user?.id) {
        return;
      }

      const firebaseAuthTokenCache = LocalStorage.getItem(FIREBASE_AUTH_TOKEN_KEY);
      const firebaseAuthTokenCacheDecode = firebaseAuthTokenCache ? jwtDecode(firebaseAuthTokenCache) : {};

      try {
        // Get firebase authentication token (from cache or server).
        let firebaseAuthToken = firebaseAuthTokenCache;
        if (
          !firebaseAuthTokenCacheDecode.exp
          || firebaseAuthTokenCacheDecode.exp <= moment().unix()
          || firebaseAuthTokenCacheDecode?.uid !== user.id
        ) {
          const { data } = await axios.post(API_URL.AUTHORIZE_FIREBASE_BO);
          firebaseAuthToken = data?.firebaseAuthToken;
          LocalStorage.setItem(FIREBASE_AUTH_TOKEN_KEY, data?.firebaseAuthToken);
        }

        // Authorize token with firebase.
        const auth = getAuth();
        const userCredential = await signInWithCustomToken(auth, firebaseAuthToken);
        if (user.id !== userCredential?.user?.uid) {
          LocalStorage.removeItem(FIREBASE_AUTH_TOKEN_KEY);
          return;
        }

        informProcessStatusByUser(`cheat-mode/${user.id}`, CheatModeCreatingProcessMessage);
        informProcessStatusByUser(`exports/${user.id}`, ExportProcessMessage);
        informProcessStatusByUser(`prepaid/${user.id}`, PrepaidProcessMessage);
      } catch (err) {
        LocalStorage.removeItem(FIREBASE_AUTH_TOKEN_KEY);
        console.log('[ERROR] when firebase reach authentication\n', err.message);
      }
    })();
  }, [user?.id]);
};

export default useFirebase;
