/* eslint-disable  */
import React from "react";
import { Datagrid, TextField, ArrayField, ReferenceField } from "react-admin";
import WealthShowGuesser, {
  OtherFieldSection,
} from "../../base/components/guesser/wealth-show.guesser";
import { IdField } from "../../base/components/ra/fields";

const CheatModeLinkShow = (props) => {
  return (
    <WealthShowGuesser
      {...props}
      excludeFields={["players"]}
      sections={[
        <ArrayField source="players" addLabel={false}>
          <Datagrid>
            <IdField source="id" link />
            <TextField source="nativeId" />
            <IdField source="wallet.id" link label="resources.wallet.name" />
          </Datagrid>
        </ArrayField>,
      ]}
    />
  );
};

export default CheatModeLinkShow;
