/* eslint-disable  */
import React, { useRef, useEffect, useState } from "react";
import {
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  useTranslate,
} from "react-admin";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "../../base/components/ra/inputs";
import { useSelector } from "react-redux";
import Edit from "../../base/components/ra/views/edit";
import MUIInput from "@material-ui/core/TextField";
import useLoading from "../../base/hooks/useLoading";

const RecordFieldTranslationEdit = (props) => {
  const [refForm, setRefForm] = useState({});

  const translate = useTranslate();
  const [valueEnums, setValueEnums] = useState({});

  const { resource, id } = props;

  const dataRecordFieldTranslation = useSelector(
    (state) => state.admin.resources[resource]?.data?.[id]
  );

  const setDataRef = async () => {
    const objState = {};

    if (dataRecordFieldTranslation.enumsValue?.length > 0) {
      objState.enums = {
        [dataRecordFieldTranslation.resourceField
          .name]: dataRecordFieldTranslation.enumsValue.map((i) => i.key),
      };

      const valueEnums = dataRecordFieldTranslation.enumsValue.reduce(
        (acc, cur) => {
          return {
            ...acc,
            [cur.key]: cur.value,
          };
        },
        {}
      );

      setValueEnums(valueEnums);
    }

    setRefForm({
      ...refForm,
      ...objState,
    });
  };

  useEffect(() => {
    if (dataRecordFieldTranslation?.resource?.id) {
      setDataRef(dataRecordFieldTranslation);
    }
  }, [dataRecordFieldTranslation]);

  const { toggleLoading } = useLoading();

  useEffect(() => {
    return () => toggleLoading(false);
  }, []);

  const transform = (data) => {
    const { translation, enumsValue } = data;

    return {
      translation,
      enumsValue,
    };
  };

  const alwaysEnableSaveButton =
    Object.keys(valueEnums).length > 0 ? true : undefined;

  return (
    <Edit
      {...props}
      undoable
      alwaysEnableSaveButton={alwaysEnableSaveButton}
      transform={transform}
    >
      <SimpleForm redirect={`/${resource}/${id}/show`}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <ReferenceInput
                  fullWidth
                  disabled
                  source="resource.id"
                  reference="resource"
                  label="resources.record-field-translation.fields.resource"
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  fullWidth
                  disabled
                  source="resourceField.id"
                  reference="resource-field"
                  label="resources.record-field-translation.fields.resourceField"
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
                {formData.record && (
                  <ReferenceInput
                    fullWidth
                    disabled
                    source="record"
                    reference={formData.resource.name}
                    label="resources.record-field-translation.fields.record"
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                )}
                <ReferenceInput
                  fullWidth
                  disabled
                  source="language.id"
                  reference="language"
                  label="resources.record-field-translation.fields.language"
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
                {refForm?.enums?.[formData.resourceField.name] && (
                  <div style={{ marginTop: "-20px" }}>
                    <div>Type: ENUM</div>
                    {refForm?.enums?.[formData.resourceField.name].map((i) => {
                      return (
                        <div
                          key={i}
                          className="flex"
                          style={{
                            overflow: "scroll",
                            padding: "8px 0",
                          }}
                        >
                          <MUIInput variant="outlined" value={i} disabled />
                          <MUIInput
                            onChange={(e) => {
                              const stateEnum = {
                                ...valueEnums,
                                [i]: e.target.value,
                              };
                              setValueEnums(stateEnum);

                              const enumsValue = Object.keys(stateEnum).map(
                                (key) => ({
                                  key,
                                  value: stateEnum[key],
                                })
                              );

                              formData.enumsValue = enumsValue;
                            }}
                            value={valueEnums[i]}
                            variant="outlined"
                            label={translate(
                              "resources.record-field-translation.fields.translation"
                            )}
                            style={{ marginLeft: "8px" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                {formData.translation && (
                  <TextInput
                    label="resources.record-field-translation.fields.translation"
                    source="translation"
                    fullWidth
                  />
                )}
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default RecordFieldTranslationEdit;
