import { Box, makeStyles } from '@material-ui/core';
import { Table } from 'antd';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  bordered: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '0px 16px',
  },
}));

const AntTable = ({
  border = true, className, pagination, wrapperProps, ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Box
      {...wrapperProps}
      className={clsx(
        {
          [classes.bordered]: border,
        },
        wrapperProps?.className,
      )}
    >
      <Table
        pagination={{
          simple: true,
          ...pagination,
        }}
        className={clsx('bo-table', className)}
        {...otherProps}
      />
    </Box>
  );
};

export default AntTable;
