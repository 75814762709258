/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Axios from 'axios';
import { useNotify, useTranslate } from 'ra-core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/styles';
import SmallButton from '@material-ui/core/Button';
import { useAuthUser, useError } from '../../../base/hooks';
import { RoleBuiltIn } from '../../../services/util';
import { BUILT_IN_ROLE_NAME } from '../../../constant';

const useStyles = makeStyles(() => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px',
    background: '#FFF',
  },
}));

const DeleteFirebaseSessions = () => {
  const classes = useStyles();
  const user = useAuthUser();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useError();
  const translate = useTranslate();
  const notify = useNotify();

  const shouldShowButton = [RoleBuiltIn.SYSTEM_ADMIN].includes(user.role?.id) || user.role?.name === BUILT_IN_ROLE_NAME.CSO;

  if (!shouldShowButton) {
    return null;
  }

  const handleDeleteFirebaseSessions = async () => {
    setLoading(true);
    try {
      await Axios.post('api/player/delete-firebase-sessions');
      const successMessage = translate('ra.notification.firebaseSessionsDeleted');
      notify(successMessage, 'success');
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  const components = (
    <SmallButton
      color="primary"
      className={classes.button}
      disabled={loading}
      onClick={handleDeleteFirebaseSessions}
      startIcon={<DeleteForeverIcon />}
    >
      {translate('ra.action.deleteFirebaseSessions')}
    </SmallButton>
  );

  return components;
};

export default DeleteFirebaseSessions;
