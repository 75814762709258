import React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { ActionMenu } from './components/action.menu';

const CheatModeList = props => (
  <WealthListGuesser
    {...props}
    extraActions={(
      <ActionMenu
        label="ra.field.action"
        list
      />
    )}
  />
);

export default CheatModeList;
