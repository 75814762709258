import { useNotify } from 'ra-core';
import { useDispatch } from 'react-redux';
import { useError } from '../../../base/hooks';
import { setHook } from './index';

function registerHooks() {
  // Register any hook which you need to use outside app
  setHook('useNotify', useNotify)
    .setHook('useDispatch', useDispatch)
    .setHook('useError', useError);
}

export default registerHooks;
