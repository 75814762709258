import React, { cloneElement, createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDataProvider,
} from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { useForm } from 'react-final-form';

import { fetchDefaultBetSetting, getBetSettingInfo } from '../../../services/util/bet-setting.utils';

const BetSettingFormContext = createContext({
  defaultSettingValue: null,
  setDefaultSettingValue: noop,

  currentSettingDefaultValue: {},
  setCurrentSettingDefaultValue: noop,

  currentSettingValue: {},
  setCurrentSettingValue: noop,

  betSettingDefaultLoaded: false,
  setBetSettingDefaultLoaded: noop,

  // Utils
  getBetSettingDefault: noop,
});

export const useBetSettingFormContext = () => useContext(BetSettingFormContext);

export const BetSettingFormProvider = ({
  children, ...restProps
}) => {
  const dataProvider = useDataProvider();
  const form = useForm();

  const [defaultSettingValue, setDefaultSettingValue] = useState(null);
  const [currentSettingValue, setCurrentSettingValue] = useState();
  const [currentSettingDefaultValue, setCurrentSettingDefaultValue] = useState();
  const [betSettingDefaultLoaded, setBetSettingDefaultLoaded] = useState();

  const getBetSettingDefault = async (gameId, currencyId) => {
    setBetSettingDefaultLoaded(false);

    try {
      // Data of default bet setting
      const data = await fetchDefaultBetSetting(dataProvider, {
        gameId,
        currencyId,
      });

      if (!isEmpty(data)) {
        setDefaultSettingValue(data);
      } else {
        setDefaultSettingValue({});
      }
    } catch (err) {
      console.error('[ERROR] Get Bet Setting Default', err?.message);
    } finally {
      setBetSettingDefaultLoaded(true);
    }
  };

  const resetBetSettingDefault = () => {
    const [listSettingObj, listSettingDefaultObj] = getBetSettingInfo(defaultSettingValue);

    // Reset list of betSize & betLevel to default
    if (!isEmpty(listSettingObj)) {
      setCurrentSettingValue(listSettingObj);
    }

    // Reset `default value` of betSize & betLevel to default
    if (!isEmpty(listSettingDefaultObj)) {
      setCurrentSettingDefaultValue(listSettingDefaultObj);
    }

    // Reset totalBetLimit
    form.change('totalBetMin', defaultSettingValue?.totalBetLimit?.[0]);
    form.change('totalBetMax', defaultSettingValue?.totalBetLimit?.[1]);
  };

  return (
    <BetSettingFormContext.Provider
      value={{
        defaultSettingValue,
        setDefaultSettingValue,

        currentSettingDefaultValue,
        setCurrentSettingDefaultValue,

        currentSettingValue,
        setCurrentSettingValue,

        betSettingDefaultLoaded,
        setBetSettingDefaultLoaded,

        // Utils
        getBetSettingDefault,
        resetBetSettingDefault,
      }}
    >
      {cloneElement(children, {
        ...restProps,
      })}
    </BetSettingFormContext.Provider>
  );
};

BetSettingFormProvider.propTypes = {
  children: PropTypes.node,
};
BetSettingFormProvider.defaultProps = {
  children: null,
};
