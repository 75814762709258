/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Dialog,
  Button,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useLocale, useTranslate } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { Typography } from 'antd';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import BetSettingCreateForm from '../../../base/components/bet-setting/bet-setting-create-form';
import { useBetSettingFormContext } from '../../../base/components/bet-setting/utils/bet-setting-form.context';
import { BET_SETTING_CONFIG_STATUS } from './constants';
import { compareListItem, generateBetSettingKey, sortListItem } from './utils';

const checkBetSettingStructure = betSetting => !!(
  betSetting?.betLevel?.length
  && betSetting?.betSize?.length
  && betSetting?.betLevelDefault
  && betSetting?.betSizeDefault
  && betSetting?.currency?.id
  && betSetting?.game?.id
  && betSetting?.totalBetLimit?.length === 2
);

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: 'calc(100vw - 40px)',
    maxWidth: '1350px',
    padding: '0',
  },
  divideHorizontal: {
    padding: 0,
    position: 'relative',
    '&:before': {
      content: '""',
      width: '1px',
      height: '20px',
      background: '#787878',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  closeBtnWrapper: {
    flex: 1,
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      width: '80px',
    },
  },
}));

const BetSettingDialog = props => {
  const {
    open,
    onClose: onCloseParent,
    game,
    currency,
    sourceNamePrefix,
    totalBetMinSourceName,
    totalBetMaxSourceName,
    pickerSourceName,
  } = props;

  const betStKey = generateBetSettingKey({
    gameCode: game.code,
    currencyCode: currency.code,
  });

  const {
    defaultSettingApplyRef,
    betSettingDefaultLoaded,
    defaultSettingValue,
    getBetSettingDefault,
  } = useBetSettingFormContext();

  const form = useForm();
  const { values: formValues } = useFormState();

  const classes = useStyles();
  const locale = useLocale();
  const muiTheme = useTheme();
  const translate = useTranslate();

  const [initByCustomData, setInitByCustomData] = useState(null);

  const defaultValue = get(formValues, `${sourceNamePrefix}picker.defaultValue`);
  const betSize = get(formValues, `${sourceNamePrefix}picker.listItem.betSize`);
  const betLevel = get(formValues, `${sourceNamePrefix}picker.listItem.betLevel`);
  const totalBetMin = get(formValues, `${sourceNamePrefix}totalBetMin`);
  const totalBetMax = get(formValues, `${sourceNamePrefix}totalBetMax`);

  const configStatus = (() => {
    // Bet Setting Default fetching...
    if ([undefined, false].includes(betSettingDefaultLoaded)) {
      return undefined;
    }

    // Bet Setting Default fetched!
    if (!defaultSettingValue) {
      const betSetting = get(form.getState().values, `${sourceNamePrefix}betSettings.${betStKey}`, {});
      const hasBetSettingCustom = checkBetSettingStructure(betSetting);

      if (hasBetSettingCustom) {
        return BET_SETTING_CONFIG_STATUS.customSetting;
      }
      return BET_SETTING_CONFIG_STATUS.noSetting;
    }

    const initFromBetSettingDefault = (
      defaultSettingApplyRef.defaultValue?.betSize === defaultValue?.betSize
      && defaultSettingApplyRef.defaultValue?.betLevel === defaultValue?.betLevel
      && compareListItem(sortListItem(defaultSettingApplyRef.listItem?.betSize), betSize)
      && compareListItem(sortListItem(defaultSettingApplyRef.listItem?.betLevel), betLevel)
      && defaultSettingValue.totalBetLimit[0] === totalBetMin
      && defaultSettingValue.totalBetLimit[1] === totalBetMax
    );

    if (initFromBetSettingDefault) {
      return BET_SETTING_CONFIG_STATUS.defaultSetting;
    }

    return BET_SETTING_CONFIG_STATUS.customSetting;
  })();

  const isTranslatable = typeof game.name === 'object';

  const Badge = ({ children }) => (
    <Box
      style={{
        color: muiTheme.palette.primary.main,
        fontWeight: 700,
        padding: '2px 8px',
        background: alpha(muiTheme.palette.primary.main, 0.1),
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );

  const updateBetSettingsInForm = useCallback(value => {
    let betSettings = get(form.getState().values, `${sourceNamePrefix}betSettings`);
    if (isEmpty(betSettings)) {
      betSettings = {};
    }

    // Bet Settings existed in formValues
    if (isObject(value)) {
      betSettings[betStKey] = value;
    } else if (betSettings[betStKey]) {
      delete betSettings[betStKey];
    }

    form.change(`${sourceNamePrefix}betSettings`, betSettings);
  }, [formValues, sourceNamePrefix, betStKey]);

  const updateBetSettingsConfigStatusInForm = useCallback(value => {
    let betSettingConfigStatus = get(form.getState().values, `${sourceNamePrefix}betSettingConfigStatus`);
    if (!isObject(betSettingConfigStatus)) {
      betSettingConfigStatus = {};
    }

    // Bet Settings existed in formValues
    if (isObject(value)) {
      betSettingConfigStatus[betStKey] = value;
    } else {
      // Just remove any Bet Setting Data if it does not use the custom.
      if (betSettingConfigStatus[betStKey]) {
        delete betSettingConfigStatus[betStKey];
      }

      form.change(`${sourceNamePrefix}betSettingConfigStatus`, betSettingConfigStatus);
    }
  }, [formValues, sourceNamePrefix, betStKey]);

  const handleClose = (e, reason) => {
    if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
      e.stopPropagation();
      return;
    }

    if (typeof onCloseParent === 'function') {
      onCloseParent(e, reason);
    }

    const {
      picker, betSettingConfigStatus,
    } = get(formValues, sourceNamePrefix.slice(0, sourceNamePrefix.length - 1), {});

    if (!picker) {
      return;
    }

    if (betSettingConfigStatus?.[betStKey]?.value === BET_SETTING_CONFIG_STATUS.defaultSetting.value) {
      setInitByCustomData(null);
      updateBetSettingsInForm(null);
      updateBetSettingsConfigStatusInForm(null);
      return;
    }

    if (betSettingConfigStatus?.[betStKey]?.value !== BET_SETTING_CONFIG_STATUS.defaultSetting.value) {
      const newInitByCustomData = {
        game: {
          id: game.id,
        },
        currency: {
          id: currency.id,
        },
        betSize: picker.listItem.betSize.map(item => ({
          value: item.value,
        })),
        betLevel: picker.listItem.betLevel.map(item => ({
          value: item.value,
        })),
        betSizeDefault: picker.defaultValue.betSize,
        betLevelDefault: picker.defaultValue.betLevel,
        totalBetLimit: [totalBetMin, totalBetMax],
      };
      const hasBetSettingCustom = checkBetSettingStructure(newInitByCustomData);
      if (hasBetSettingCustom) {
        setInitByCustomData(newInitByCustomData);
        updateBetSettingsInForm(newInitByCustomData);
      }
    } else {
      setInitByCustomData(null);
      if (betSettingConfigStatus?.[betStKey]?.value === BET_SETTING_CONFIG_STATUS.defaultSetting.value) {
        updateBetSettingsInForm(null);
        updateBetSettingsConfigStatusInForm(null);
      }
    }

    // Before close this Dialog... Need to remove this picker input values from the Form
    form.change(`${sourceNamePrefix}picker`, undefined);
    form.change(`${sourceNamePrefix}totalBetMin`, undefined);
    form.change(`${sourceNamePrefix}totalBetMax`, undefined);
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    if (configStatus?.value) {
      const { betSettingConfigStatus } = get(formValues, sourceNamePrefix.slice(0, sourceNamePrefix.length - 1), {});

      const prevBetSettingConfigStatus = isObject(betSettingConfigStatus) ? {
        ...betSettingConfigStatus,
      } : {};

      form.change(`${sourceNamePrefix}betSettingConfigStatus`, {
        ...prevBetSettingConfigStatus,
        [betStKey]: configStatus,
      });
    }
  }, [open, configStatus?.value, betStKey]);

  useEffect(() => {
    if (game?.id && currency?.id && betSettingDefaultLoaded === undefined) {
      getBetSettingDefault(game.id, currency.id);
    }
  }, [game, currency, betSettingDefaultLoaded]);

  useEffect(() => {
    const betSettingConfigStatus = get(form.getState().values, `${sourceNamePrefix}betSettingConfigStatus`);
    const betSettings = get(form.getState().values, `${sourceNamePrefix}betSettings`);

    if (isObject(betSettingConfigStatus?.[betStKey]) && isObject(betSettings?.[betStKey])) {
      setInitByCustomData(betSettings[betStKey]);
      updateBetSettingsInForm(betSettings[betStKey]);
    }
  }, []);

  useEffect(() => {
    if (configStatus?.value) {
      updateBetSettingsConfigStatusInForm(configStatus);
    }
  }, [configStatus?.value]);

  return (
    <Box marginTop="24px">
      <Dialog
        open={open}
        classes={{
          paper: classes.dialogPaper,
        }}
        onClose={handleClose}
      >
        <Box
          style={{
            marginBottom: '24px',
            padding: '8px 20px',
            background: '#ebebeb',
            borderBottom: '1px solid #d8d8d8',
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              fontWeight: 600,
            }}
          >
            <Box>
              {translate('ra.text.configBetSetting')}
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '6px',
              }}
            >
              <Badge>
                {isTranslatable ? game.name?.[locale] : game.name}
              </Badge>
              <Box color="#787878">&</Box>
              <Badge>
                {currency.name}
              </Badge>
              {configStatus?.text && (
                <>
                  <Box
                    component="span"
                    className={classes.divideHorizontal}
                  />
                  <Typography>{translate(configStatus.text)}</Typography>
                </>
              )}
            </Box>
            <Box className={classes.closeBtnWrapper}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClose}
              >
                {translate('ra.action.ok')}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          padding="8px 20px"
        >
          {/* <CircularProgress /> */}

          <BetSettingCreateForm
            sourceNamePrefix={sourceNamePrefix}
            initByCustomData={initByCustomData}
            game={game}
            currency={currency}
            totalBetMinSourceName={totalBetMinSourceName}
            totalBetMaxSourceName={totalBetMaxSourceName}
            pickerSourceName={pickerSourceName}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

BetSettingDialog.defaultProps = {
  sourceNamePrefix: '',
};

BetSettingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  game: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  sourceNamePrefix: PropTypes.string,
  totalBetMinSourceName: PropTypes.string.isRequired,
  totalBetMaxSourceName: PropTypes.string.isRequired,
  pickerSourceName: PropTypes.string.isRequired,
};

export default BetSettingDialog;
