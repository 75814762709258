import { addExportListener } from './exporters.util';

const wagerExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider,
  _resource,
  additionData = {},
) => {
  const { date } = additionData;

  const group = await fetchRelatedRecords(records, 'group', 'group');
  const brand = await fetchRelatedRecords(records, 'brand', 'brand');
  const game = await fetchRelatedRecords(records, 'gameId', 'game');
  const currency = await fetchRelatedRecords(records, 'currency', 'currency');
  const player = await fetchRelatedRecords(records, 'playerId', 'player');

  const wagersForExport = records.map(data => {
    const result = {
      date: data.created,
      wagerId: data.id,
      gameId: game[data.gameId]?.gameId,
      game: data.game,
      nativeId: player[data.playerId]?.nativeId,
      playerId: data.playerId,
      brand: brand[data.brand]?.name,
      group: group[data.group]?.name,
      gameType: game[data.gameId]?.gameType?.name,
      betType: data.betType,
      currency: currency[data.currency]?.code,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
      wonAmount: data.wonAmount?.toString?.()?.replace?.('.', ','),
      description: '',
    };

    return result;
  });

  addExportListener({
    dataList: wagersForExport,
    dateString: date,
    showTime: true,
    fileName: 'Wager Report',
  });
};

export default wagerExporter;
