const getItem = key => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return undefined;
  }
};

const setItem = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
};

const removeItem = key => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

const LocalStorage = {
  getItem,
  setItem,
  removeItem,
};

export const userStorageKey = 'user.me';
export const FIREBASE_AUTH_TOKEN_KEY = 'firebaseAuthToken';
export const QUICK_SETUP_NEW_GROUP = 'quick-setup/new-group/cache';
export const QUICK_SETUP_NEW_BRAND = 'quick-setup/new-brand/cache';
export const REDIRECT_KEY = 'redirect';

export default LocalStorage;
