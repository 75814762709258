import React from 'react';
import { useShowController } from 'ra-core';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { ActionMenu } from './components/action.menu';

const CronOldShow = props => {
  const { loading } = useShowController(props);

  return (
    <WealthShowGuesser
      {...props}
      actionMenu={(
        <ActionMenu
          type="toolbar"
          loading={loading}
        />
      )}
    />
  );
};

export default CronOldShow;
