/* eslint-disable */
import React from 'react';
import { useTranslate } from 'react-admin';
import { Box, Link } from '@material-ui/core';

const FieldPreview = ({
  value, label, isReference, href
}) => {
  const translate = useTranslate();

  return (
    <Box
      padding="10px 16px"
      marginBottom="20px"
      style={{
        background: '#F0F1F2',
        borderRadius: '4px',
      }}
    >
      <Box
        style={{
          marginTop: '8px',
          marginBottom: '4px',
        }}
      >
        <Box
          component="legend"
          style={{
            color: '#A5A5A5',
            width: 'fit-content',
            fontSize: '10px',
            fontWeight: '500 !important',
            lineHeight: 1,
          }}
        >
          {translate(label).toUpperCase()}
        </Box>
        <Box
          minHeight="17px"
          style={{
            fontWeight: 700,
            padding: '4px 0 2px',
            lineHeight: 1,
          }}
        >
          {isReference && href
            ? (
              <Link href={href} style={{ textDecoration: 'none' }}>{value}</Link>
            )
            : value}
        </Box>
      </Box>
    </Box>
  );
};

export default FieldPreview;
