/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import { NumberField } from '../../../../base/components/ra/fields';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-operator-profit.list';

const OverallTable = () => {
  const translate = useTranslate();
  return (
    <CustomReportTable
      title={translate('resources.report.overall')}
      resource={resourceSlug.REPORT_OPERATOR_PROFIT}
      reportList={LIST_REPORTS}
      reportType={REPORT_TYPES.OVERALL}
    >
      <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
        <TextField source="name" />
      </ReferenceField>
      <NumberField label="resources.report.fields.betCount" source="betCount" sortable />
      <NumberField label="resources.report.fields.playerCount" source="playerCount" sortable={false} />
      <ReferenceField link="show" source="currency" reference="currency" label="resources.currency.name" sortable>
        <TextField source="code" />
      </ReferenceField>
      <TextField label="resources.report.fields.betAmount" source="betAmount" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
      <TextField label="resources.report.fields.profit" source="profit" sortable />
    </CustomReportTable>
  );
};

export default OverallTable;
