/* eslint-disable */
import React from 'react';
import GroupInput from '../../../base/components/reports/ReportFilterWrapper/GroupInput';
import BrandInput from '../../../base/components/reports/ReportFilterWrapper/BrandInput';
import PlayerIdInput from '../../../base/components/reports/ReportFilterWrapper/PlayerId';
import GameInput from '../../../base/components/reports/ReportFilterWrapper/GameInput';
import { useReportContext } from '../../../base/components/reports/ReportLayout';

const DetailPlayerReportFilters = () => {
  const { appliedFilters } = useReportContext();
  return (
    <>
      <GroupInput initialValue={appliedFilters.group} disabled />
      <BrandInput initialValue={appliedFilters.brand} disabled />
      <PlayerIdInput initialValue={appliedFilters.playerId} disabled />
      <GameInput initialValue={appliedFilters.gameId} />
    </>
  );
};

export default DetailPlayerReportFilters;
