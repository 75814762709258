/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import { NumberField } from '../../../../base/components/ra/fields';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-performance.list';
import BaseCurrencyField from '../../../../base/components/reports/ReportTable/BaseCurrencyField';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';
import { GROUP_BY_OPTIONS } from '../PerformanceReportFilters/GroupByCheckbox';
import TranslatableField from '../../../../base/components/ra/fields/translatable.field';

const OverallTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();

  return (
    <CustomReportTable
      title={translate('resources.report.overall')}
      resource={resourceSlug.REPORT_PERFORMANCE}
      reportList={LIST_REPORTS}
      reportType={REPORT_TYPES.OVERALL}
    >
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.DATE) && (
        <TextField source="date" label="resources.report.fields.date" sortable />
      )}
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.GROUP) && (
        <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
          <TextField source="name" />
        </ReferenceField>
      )}
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.BRAND) && (
        <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
          <TextField source="name" />
        </ReferenceField>
      )}
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.GAME) && (
        <TranslatableField label="resources.report.fields.gameName" source="game" />
      )}
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.GAME_TYPE) && (
        <TextField label="resources.report.fields.gameType" source="gameType" sortable={false} />
      )}
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.BET_TYPE) && (
        <TextField label="resources.report.fields.betType" source="betType" sortable={false} />
      )}
      {appliedFilters.groupBy?.includes(GROUP_BY_OPTIONS.MONTH) && (
        <TextField source="month" label="resources.report.fields.month" sortable />
      )}
      <NumberField label="resources.report.fields.playerCount" source="playerCount" sortable={false} />
      <TextField label="resources.report.fields.betAmount" source="betAmount" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
      <NumberField label="resources.report.fields.betCount" source="betCount" sortable />
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField link="show" source="currency" reference="currency" label="resources.currency.name">
        <TextField source="code" />
      </ReferenceField>
      <BaseCurrencyField label="resources.report.fields.baseCurrency" />
    </CustomReportTable>
  );
};

export default OverallTable;
