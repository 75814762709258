import React from 'react';
import {
  Breadcrumbs,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  textSm: {
    fontSize: 12,
    maxWidth: '160px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      maxWidth: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80px',
    },
  },
  linkBlock: {
    display: 'block',
    color: 'inherit',
  },
}));

const PageBreadcrumbs = ({
  items, ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const totalMiddleItems = xs ? 0 : sm ? 1 : md ? 2 : 10;

  return (
    <Breadcrumbs
      className="bread-crumbs"
      aria-label="breadcrumb {...rest}"
    >
      {items.map((item, index) => {
        const { length } = items;
        if (index > 0 && index < length - 1 - totalMiddleItems) {
          if (index === 1) {
            return (
              <Typography
                key={index}
                className={classes.textSm}
                color="textPrimary"
              >
                ...
              </Typography>
            );
          }

          return null;
        }

        return item.isActive || !item.link ? (
          <Typography
            key={index}
            className={classes.textSm}
            color="textPrimary"
          >
            {item.label}
          </Typography>
        ) : (
          <NavLink
            key={index}
            className={clsx(classes.textSm, classes.linkBlock)}
            color="inherit"
            to={item.link || '/'}
          >
            {item.label}
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
