import React from 'react';
import { Show as RAShow } from 'react-admin';
import Actions from './action-toolbar';

const Show = props => <RAShow {...props} />;

Show.defaultProps = {
  actions: <Actions />,
};

export default Show;
