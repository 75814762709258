import React from 'react';
import { useFormState } from 'react-final-form';
import BrandSelector from '../../../../base/components/customized/common-input/BrandSelector';

const BrandInput = props => {
  const { values: { groupId } } = useFormState();

  return (
    <BrandSelector
      disabled={!groupId}
      filter={{
        'group.id': groupId,
      }}
      {...props}
    />
  );
};

export default BrandInput;
