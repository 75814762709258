/* eslint-disable */
import React, { useEffect, cloneElement, memo } from 'react';
import { TitlePropType } from 'ra-ui-materialui/lib/layout/Title';
import DefaultPagination from 'ra-ui-materialui/lib/list/pagination/Pagination';
import DefaultActions from 'ra-ui-materialui/lib/list/ListActions';
import Card from '@material-ui/core/Card';
import { useCheckMinimumRequiredProps, ListContextProvider, useListContext, defaultExporter } from 'ra-core';
import PropTypes from 'prop-types';
import { useLoading } from '../../../hooks';
import { FormContextProvider } from '../../../context/form/filterForm.context';
import ListView from './ListView';
import EmptyTable from '../../guesser/report/emptyTable';
import ListToolbar from './ListToolbar';
import { useReloadContext } from '../../../context/reload/reload.context';
import useListController from '../../../hooks/useCustomListController';

const EmptyListView = memo((props) => {
  const {
    filters,
    actions = <DefaultActions />,
    hideExporter,
    exporter = defaultExporter,
    searchPanel,
    pagination = <DefaultPagination />,
  } = props;
  const listContext = useListContext(props);

  return (
    <>
      {(filters || actions) && (
        <ListToolbar
          filters={filters}
          actions={actions}
          exporter={hideExporter ? false : exporter} // deprecated, use ListContext instead, to be removed in v4
          searchPanel={searchPanel}
        />
      )}

      <Card>
        <EmptyTable />
        {pagination && cloneElement(pagination, listContext)}
      </Card>
    </>
  );
});

const emptyListProps = {
  data: {},
  ids: [],
  total: 0,
  page: 1,
  perPage: 25,
  setPage: () => {},
  setPerPage: () => {},
  setSort: () => {},
  currentSort: { field: 'id', order: 'DESC' },
  basePath: `/fake`,
  resource: 'fake',
  selectedIds: [],
  setFilters: () => {},
  hideFilter: () => {},
  hasList: false,
};

const List = ({ searchPanel, emptyTable, controllerProps:  overriddenControllerProps, ...props }) => {
  if (emptyTable) {
    return (
      <>
        <ListContextProvider value={emptyListProps}>
          <FormContextProvider initShowPanel={false}>
            <EmptyListView {...props} {...emptyListProps} searchPanel={searchPanel} />
          </FormContextProvider>
        </ListContextProvider>
      </>
    );
  }

  useCheckMinimumRequiredProps('List', ['children'], props);
  const controllerProps = useListController(props);
  const { toggleLoading } = useLoading();

  const reloadContext = useReloadContext();

  useEffect(() => {
    toggleLoading(controllerProps.loading);
    return () => toggleLoading(false);
  }, [controllerProps.loading]);

  const overideControllerProps = {
    ...controllerProps,
    ...(reloadContext.valuesContext || {}),
    ...(overriddenControllerProps || {})
  };

  return (
    <ListContextProvider value={overideControllerProps}>
      <FormContextProvider initShowPanel={false}>
        <ListView {...props} {...overideControllerProps} searchPanel={searchPanel} />
      </FormContextProvider>
    </ListContextProvider>
  );
};

List.propTypes = {
  // The props you can change
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  aside: PropTypes.element,
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: TitlePropType,
  // The props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  location: PropTypes.any,
  match: PropTypes.any,
  path: PropTypes.string,
  resource: PropTypes.string.isRequired,
  controllerProps: PropTypes.object
};

List.defaultProps = {
  filter: {},
  perPage: 10,
  controllerProps: {}
};

export default List;
