/* eslint-disable */
import React from "react";
import { useTranslate } from "ra-core";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import _, { get } from "lodash";

import { useViewType } from "../../../../base/hooks";

import { formatPayloadData } from "../../inbox-rule.utils";
import { InboxHub, InboxModule, InboxType } from "../../inbox-rule.const";
import DynamicGroupList from "../DynamicGroupList";

import InputReview from "../InputReview";
import MultilineInputReview from "../MultilineInputReview";
import FieldReview from "../FieldReview";
import MultilineFieldReview from "../MultilineFieldReview";

// const formatDataPreview = values => {
//   const {
//     name,
//     message,
//     desc,
//     hub,
//     module,
//     type,
//     dynamicGroupStep,
//     groups,
//   } = values;

//   if (!_.isObject(dynamicGroupStep?.groups)) {
//     return {};
//   }

//   const dynamicRuleValue = _.values(dynamicGroupStep?.groups).reduce((dynamicRule, curGroupItem, curIndex) => {
//     if (!curGroupItem?.id || !_.isObject(curGroupItem?.brands)) {
//       return dynamicRule;
//     }

//     const dynamicRuleCloned = [...dynamicRule];

//     const brandData = _.toPairs(curGroupItem.brands).map(([bId, bValue]) => {
//       const playerData = Array.isArray(bValue?.players)
//         ? bValue?.players.map(pId => ({
//           id: pId,
//         }))
//         : _.keys(bValue?.players).map(pId => ({
//           id: pId,
//           ...bValue?.players[pId],
//         }));

//       return {
//         id: bId,
//         ...bValue,
//         players: playerData.length > 0 ? playerData : undefined,
//       };
//     });

//     dynamicRuleCloned[curIndex] = {
//       id: curGroupItem.id,
//       brands: brandData.length > 0 ? brandData : undefined,
//     };

//     return dynamicRuleCloned;
//   }, []);

//   const creatingPayload = {
//     name,
//     message,
//     desc,
//     hub,
//     module,
//     type,
//     game,
//     groups: dynamicRuleValue,
//   };

//   return creatingPayload;
// };

const PreviewStep = (props) => {
  const { formData } = props;

  const { isShowView } = useViewType();

  const translate = useTranslate();

  const TextPreview = isShowView ? FieldReview : InputReview;
  const TextMultilinePreview = isShowView
    ? MultilineFieldReview
    : MultilineInputReview;

  const resources = useSelector(({ admin }) => admin.resources);
  const groupDataFetched = _.get(resources, 'group.data');
  const brandDataFetched = _.get(resources, 'brand.data');
  const playerDataFetched = _.get(resources, 'player.data');
  const gameDataFetched = _.get(resources, 'game.data');

  const groupsData = isShowView ? formData.groups : formatPayloadData(
    JSON.parse(JSON.stringify(formData)),
  ).groups;
  
  return (
    <Box className="InboxRule_preview-step" margin="0" marginTop="10px">
      <TextPreview
        label={translate("resources.inbox-rule.fields.name")}
        value={formData.name}
      />
      <TextMultilinePreview
        label={translate("resources.inbox-rule.fields.desc")}
        value={formData.desc}
      />
      <TextMultilinePreview
        label={translate("resources.inbox-rule.fields.message")}
        value={formData.message?.data}
      />
      {/* <TextPreview
        label={translate('resources.inbox-rule.fields.hub')}
        value={_.get(InboxHub, [formData.hub, 'displayName'])}
      /> */}
      {/* <TextPreview
        label={translate('resources.inbox-rule.fields.module')}
        value={_.get(InboxModule, [formData.module, 'displayName'])}
      /> */}
      <TextPreview
        label={translate("resources.inbox-rule.fields.type")}
        value={_.get(InboxType, [formData.type, "displayName"])}
      />
      {formData.type === InboxType.GAME_BASED.value && (
        <TextPreview
          label={translate("resources.inbox-rule.fields.game")}
          value={
            formData.game?.name ||
            get(gameDataFetched, [formData.game?.id, "name"])
          }
          isReference={!!formData.game?.id}
          href={formData.game?.id ? `/#/game/${formData.game?.id}/show` : ""}
        />
      )}
      {Array.isArray(groupsData) &&
        groupsData.map((gItem) => (
          <DynamicGroupList
            key={gItem.id}
            data={gItem}
            groupMapIdData={groupDataFetched}
            brandMapIdData={brandDataFetched}
            playerMapIdData={playerDataFetched}
          />
        ))}
    </Box>
  );
};

export default PreviewStep;
