import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import FormField from '../../../../base/components/reports/ReportFilterWrapper/FormField';
import { SelectInput } from '../../../../base/components/ra/inputs';

const INTERVAL_VALUES = {
  daily: 'daily',
  hourly: 'hourly',
  monthly: 'monthly',
};

const IntervalSelect = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  const choices = useMemo(
    () => [
      {
        id: INTERVAL_VALUES.daily,
        name: translate('resources.report.fields.daily'),
      },
      {
        id: INTERVAL_VALUES.hourly,
        name: translate('resources.report.fields.hourly'),
      },
      {
        id: INTERVAL_VALUES.monthly,
        name: translate('resources.report.fields.monthly'),
      },
    ],
    [],
  );

  return (
    <FormField name={source}>
      {() => (
        <SelectInput
          label={translate('resources.report.fields.interval')}
          source={source}
          choices={choices}
          defaultValue={choices[0].id}
          {...otherProps}
        />
      )}
    </FormField>
  );
};

IntervalSelect.propTypes = {
  source: PropTypes.string,
};

IntervalSelect.defaultProps = {
  source: 'interval',
};

export default IntervalSelect;
