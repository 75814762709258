/* eslint-disable no-nested-ternary */
import React from 'react';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import GameFeaturesInput from './components/GameFeaturesInput';
import { GAME_FEATURE_VALUE_TYPES } from '../../constant';

export default function RegulationEdit(props) {
  const transformData = values => ({
    code: values.code,
    name: values.name,
    gameFeatures: values.gameFeatures?.map(item => ({
      ...item,
      valueType:
          typeof item?.value === 'number'
            ? GAME_FEATURE_VALUE_TYPES.NUMBER
            : typeof item?.value === 'string'
              ? GAME_FEATURE_VALUE_TYPES.STRING
              : GAME_FEATURE_VALUE_TYPES.BOOLEAN,
      value: !item?.value ? true : item.value,
    })),
  });

  return (
    <WealthEditGuesser
      {...props}
      alwaysEnableSaveButton={false}
      transform={transformData}
      fieldOrder={['name', 'code', 'gameFeatures']}
    >
      <GameFeaturesInput source="gameFeatures" />
    </WealthEditGuesser>
  );
}
