import React from 'react';
import { useRefresh, useShowController } from 'react-admin';
import { Button } from '@material-ui/core';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import ToolbarMenu from '../../base/components/action-menu/toolbar-menu';
import withComplete from './components/withComplete';
import ParentBetField from './components/ParentBetField';
import ChildrenBetField from './components/ChildrenBetField';
import NativeIdField from './components/NativeIdField';
import Labeled from '../../base/components/ra/labeled';
import resourceSlug from '../../constant/resource-slug';

const CompleteButton = withComplete(Button);

const PlayerStateManagementShow = props => {
  const { record } = useShowController(props);
  const refresh = useRefresh();

  return (
    <WealthShowGuesser
      excludeFields={['parentBet', 'childrenBets', 'playerId']}
      forceEditAble={false}
      actionMenu={(
        <ToolbarMenu>
          <CompleteButton
            size="small"
            color="primary"
            record={record}
            onSuccess={refresh}
          />
        </ToolbarMenu>
      )}
      extraInformBlocks={[
        <Labeled
          source="playerId"
          label={`resources.${resourceSlug.PLAYER}.fields.nativeId`}
        >
          <NativeIdField {...props} />
        </Labeled>,
      ]}
      extraBlocks={[<ParentBetField {...props} />, <ChildrenBetField {...props} />]}
      informFieldOrder={['groupId', 'brandId', 'gameId', 'playerId', 'betType', 'remainFreeSpin', 'currency']}
      {...props}
    />
  );
};

export default PlayerStateManagementShow;
