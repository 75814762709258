/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { GAME_FEATURE_VALUE_TYPES } from '../../../constant';
import resourceSlug from '../../../constant/resource-slug';
import NumberInput from '../../../base/components/ra/inputs/commonNumber.input';

const GameFeature = ({
  getSource, scopedFormData, formData,
}) => {
  const translate = useTranslate();
  const [focus, setFocus] = useState(false);
  const [currentValueType, setCurrentValueType] = useState(scopedFormData?.valueType);

  return (
    <>
      <ReferenceInput
        label="resources.game-feature.name"
        source={getSource('id')}
        reference={resourceSlug.GAME_FEATURE}
        filter={{
          'id||$notin': formData.gameFeatures
            .filter(item => item?.id)
            .map(item => item.id)
            .join(','),
        }}
        filterToQuery={value => ({
          'name||$cont': value,
        })}
        variant="outlined"
        enableGetChoices={() => !!focus}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        helperText={false}
        validate={required()}
      >
        <FeatureInput setCurrentValueType={setCurrentValueType} />
      </ReferenceInput>
      {GAME_FEATURE_VALUE_TYPES.STRING === currentValueType && (
        <TextInput
          label="resources.game-feature.fields.value"
          placeholder={translate('ra.action.typeYourText')}
          source={getSource('value')}
          variant="outlined"
          helperText={false}
          validate={required()}
        />
      )}
      {GAME_FEATURE_VALUE_TYPES.NUMBER === currentValueType && (
        <NumberInput
          label="resources.game-feature.fields.value"
          placeholder={translate('ra.action.typeYourNumber')}
          source={getSource('value')}
          variant="outlined"
          helperText={false}
          validate={required()}
        />
      )}
    </>
  );
};

const FeatureInput = ({
  setCurrentValueType, ...props
}) => {
  const {
    input: { value },
    choices,
  } = props;

  useEffect(() => {
    const selectedChoice = choices.find(item => item.id === value);

    if (selectedChoice?.valueType) {
      setCurrentValueType(selectedChoice.valueType);
    }
  }, [value, choices]);

  return <AutocompleteInput {...props} />;
};

const GameFeaturesInput = props => {
  const translate = useTranslate();

  return (
    <ArrayInput
      label={<Box mt={-6}>{translate('resources.game-feature.fields.gameFeatures')}</Box>}
      {...props}
    >
      <SimpleFormIterator
        inline
        disableReordering
      >
        <FormDataConsumer>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
            getSource, // A function to get the valid source inside an ArrayInput
            ...rest
          }) => (
            <GameFeature
              getSource={getSource}
              scopedFormData={scopedFormData}
              formData={formData}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

GameFeaturesInput.propTypes = {};

GameFeaturesInput.defaultProps = {};

export default GameFeaturesInput;
