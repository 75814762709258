import React, { useState } from 'react';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import {
  useQuery,
  ListContextProvider,
  Pagination,
  Loading,
} from 'react-admin';

const WealthCustomQueryList = props => {
  const {
    resource,
    basePath,
    perPage,
    filter,
    sort,
    selectedIds,
    children,
    ...rest
  } = props;
  const [page, setPage] = useState(1);
  const {
    data, total, error, loading,
  } = useQuery({
    type: 'getList',
    resource,
    payload: {
      pagination: {
        page,
        perPage,
      },
      sort,
      filter,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  return (
    <ListContextProvider
      value={{
        basePath,
        resource,
        data: keyBy(data, 'id'),
        ids: data?.map(({ id }) => id),
        currentSort: sort,
        selectedIds,
        ...rest,
      }}
    >
      {children}
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
        rowsPerPageOptions={[perPage]}
      />
    </ListContextProvider>
  );
};

WealthCustomQueryList.propTypes = {
  basePath: PropTypes.string,
  resource: PropTypes.string,
  filter: PropTypes.object,
  sort: PropTypes.object,
  selectedIds: PropTypes.array,
  perPage: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

WealthCustomQueryList.defaultProps = {
  basePath: '',
  resource: '',
  filter: {},
  sort: {
    field: 'id',
    order: 'ASC',
  },
  selectedIds: [],
  perPage: 25,
};

export default WealthCustomQueryList;
