/* eslint-disable */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
  makeStyles,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useTranslate, useResourceContext } from 'ra-core';
import axios from 'axios';
import throttle from 'lodash/throttle';
import Button from '../../ra/buttons/Button';
import clsx from 'clsx';
import { useReportContext } from '../ReportLayout';
import {
  composeFilter,
  composeOr,
  composeQueryParams,
  mergeEncodedQueries,
} from '../../../../services/provider/dataProvider';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('md')]: {
      background: theme.palette.primary.light,
    },
  },
}));

const validateSort = (sorts) => {
  if (!Array.isArray(sorts)) return sorts;
  return sorts.map((sort) => sort?.split(','));
};

const getQueryUrl = (resource, params) => {
  const { page = 1, perPage = 25 } = params?.pagination || {};
  const { q: queryParams, ...filter } = params?.filter || {};

  // Get rid of null/undefined/empty string search value
  const enhancedFilters = Object.entries(filter || {}).reduce((obj, [key, value]) => {
    if (!Number.isInteger(value) && typeof value !== 'boolean') {
      if (!value) {
        return obj;
      }
    }
    obj[key] = value;
    return obj;
  }, {});

  const encodedQueryParams = composeQueryParams(queryParams);
  const encodedQueryFilter = RequestQueryBuilder.create({
    filter: composeFilter(enhancedFilters, resource),
    or: composeOr(enhancedFilters),
  })
    .setLimit(perPage)
    .setPage(page)
    .sortBy(validateSort(params.sort))
    .setOffset((page - 1) * perPage)
    .query();
  const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
  const url = `api/${resource}?${query}`;

  return {
    query,
    url,
  };
};

const ExportButton = ({ resource: defaultResource, className, onExport, ...otherProps }) => {
  const classes = useStyles();
  const resourceFromContext = useResourceContext();
  const [isOpenWaitingDialog, setIsOpenWaitingDialog] = useState(false);
  const { appliedFilters, sort } = useReportContext();

  const handleExport = useCallback(
    throttle(async () => {
      try {
        if (onExport) {
          onExport();
        } else {
          const resource = defaultResource || resourceFromContext;
          const { type, ...otherFilters } = appliedFilters;
          const params = { ...otherFilters };

          if (type) {
            params.q = { type };
          }

          const { query: queryString } = getQueryUrl(resource, {
            sort: sort,
            filter: params,
          });

          axios.post(`api/${resource}/export?${queryString}`);
        }
        setIsOpenWaitingDialog(true);
      } catch (error) {
        // Do nothing!
      }
    }, 1000),
    [onExport, appliedFilters, sort],
  );

  return (
    <>
      <Button
        className={clsx(classes.button, className)}
        onClick={handleExport}
        label="ra.action.export"
        {...otherProps}
      >
        <DownloadIcon />
      </Button>
      <DialogExportAfter open={isOpenWaitingDialog} onClose={() => setIsOpenWaitingDialog(false)} />
    </>
  );
};

const DialogExportAfter = ({ onClose, open }) => {
  const translate = useTranslate();

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle style={{ textTransform: 'uppercase' }}>
        {translate('ra.message.gotoExportHistoryDetailTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{translate('ra.text.messageAfterClickExport')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={() => onClose()} color="primary">
          {translate('ra.action.close')}
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportButton;
