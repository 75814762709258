/* eslint-disable react/require-default-props */
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  EditButton,
  ListButton,
  ShowButton,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { Workflow } from '../../../action-menu/workflow';
import CustomTooltip from '../../../custom-tooltip';
import { useViewType } from '../../../../hooks';

function Actions({
  // eslint-disable-next-line react/prop-types
  basePath,
  data,
  hasEdit,
  hasList,
  hasShow,
  resource,
  record,
}) {
  const translate = useTranslate();
  const { isEditView } = useViewType();

  const editable = hasEdit && !isEditView;

  // function renderUserStatus(user) {
  //   if (!user?.status?.name) {
  //     return null;
  //   }

  //   let color;
  //   switch (user?.status?.name) {
  //     case 'Active':
  //       color = 'primary';
  //       break;
  //     case 'Locked':
  //       color = 'secondary';
  //       break;
  //     default:
  //       color = 'default';
  //       break;
  //   }

  //   return (
  //     <Chip
  //       label={`${translate('resources.status.name')}: ${data?.status?.name}`}
  //       color={color}
  //     />
  //   );
  // }

  return (
    <TopToolbar>
      <div />
      {/* {resource === 'user' && basePath === '/user' ? (
        renderUserStatus(data)
      ) : (
        <div />
      )} */}
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {hasList && (
          <CustomTooltip
            title={translate('ra.action.list')}
            placement="top"
          >
            <ListButton
              basePath={basePath}
              label=""
            />
          </CustomTooltip>
        )}

        {hasShow && (
          <CustomTooltip
            title={translate('ra.action.show')}
            placement="top"
          >
            <ShowButton
              basePath={basePath}
              record={data}
              label=""
            />
          </CustomTooltip>
        )}

        {editable && (
          <CustomTooltip
            title={translate('ra.action.edit')}
            placement="top"
          >
            <EditButton
              basePath={basePath}
              record={data}
              label=""
            />
          </CustomTooltip>
        )}
        <Workflow
          resource={resource}
          record={record}
        />
      </Box>
    </TopToolbar>
  );
}

Actions.propTypes = {
  basePath: PropTypes.string,
  resource: PropTypes.string,
  data: PropTypes.object,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
};

export default Actions;
