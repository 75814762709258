/* eslint-disable */
import React from 'react';
import ReportDateRangeInput from '../../../base/components/reports/ReportFilterWrapper/ReportDateRangeInput';
import CurrencyTypeSelector from '../../../base/components/reports/ReportFilterWrapper/CurrencyTypeSelector';
import CurrencyInput from '../../../base/components/reports/ReportFilterWrapper/CurrencyInput';
import { useAuthUser } from '../../../base/hooks';
import ConsolidatedInput from '../../../base/components/reports/ReportFilterWrapper/ConsolidatedInput';
import ChannelSelect from '../../../base/components/reports/ReportFilterWrapper/ChannelSelect';
import BetTypeSelect from '../../../base/components/reports/ReportFilterWrapper/BetTypeSelect';
import GameTypeInput from '../../../base/components/reports/ReportFilterWrapper/GameTypeInput';
import GameInput from '../../../base/components/reports/ReportFilterWrapper/GameInput';
import GameIdInput from '../../../base/components/reports/ReportFilterWrapper/GameIdInput';

const RtpReportFilters = ({ reportServiceVersion }) => {
  return (
    <>
      <ReportDateRangeInput reportServiceVersion={reportServiceVersion} />
      <ConsolidatedInput />
      <CurrencyTypeSelector />
      <CurrencyInput />
      <GameTypeInput />
      <GameIdInput />
      <GameInput />
      <BetTypeSelect />
      <ChannelSelect />
    </>
  );
};

export default RtpReportFilters;
