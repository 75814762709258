import moment from 'moment';

export const validateDateTime = translate => dateTime => {
  const invalidDate = !dateTime || !moment(dateTime).isValid();
  if (invalidDate) {
    return translate('ra.validation.invalidDateTime');
  }
  return undefined;
};

export const testByRegex = ({ translateSchema }, {
  regex, translationSchema,
}) => value => {
  if (typeof regex?.test !== 'function') {
    console.error('Error: validate by regex\n--- regex param invalid');
    return undefined;
  }

  if (!regex.test(value) && typeof translateSchema === 'function') {
    return translateSchema(translationSchema);
  }

  return undefined;
};

export const dateTimeMustBeAfterCurrent = translate => dateTime => {
  if (!dateTime || !moment(dateTime).isValid()) {
    // Validation valid date time in the other
    return undefined;
  }

  const invalidDate = moment().unix() > moment(dateTime).startOf('second').unix();
  if (invalidDate) {
    return translate('ra.validation.dateTimeMustBeAfterCurrent');
  }
  return undefined;
};
