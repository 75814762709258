/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useLocale, useTranslate } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { AutocompleteInput } from '../../../base/components/ra/inputs';
import { BetSettingFormProvider } from '../../../base/components/bet-setting/utils/bet-setting-form.context';
import { generateBetSettingKey, getCodeFromBetSettingKey } from './utils';
import { BET_SETTING_CONFIG_STATUS } from './constants';
import BetSettingDialog from './BetSettingDialog';

const useStyles = makeStyles(() => ({
  container: {
    '& [class^=MuiFormHelperText-root]': {
      display: 'none',
    },
  },
  rowRoot: {
    '&:last-of-type > td': {
      border: 'none',
    },
  },
}));

const GAME_CURRENCY_PAIR_SPLIT_CHAR = '__';

const BetSettingPanel = props => {
  const { index: brandFormIndex } = props;
  const sourceNamePrefix = `brands[${brandFormIndex}].`;
  const { values: formValues } = useFormState();

  const [curBetSettingDisplay, setCurBetSettingDisplay] = useState();
  const [currencyGamePair, setCurrencyGamePair] = useState([]);
  const [currencyGamePairFormData, setCurrencyGamePairFormData] = useState({
    id: null,
    game: null,
    currency: null,
  });

  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  const form = useForm();
  const muiTheme = useTheme();
  const screenLg = useMediaQuery(muiTheme.breakpoints.up('lg'));

  const resources = useSelector(({ admin }) => admin.resources);
  const gameDataFetched = get(resources, 'game.data');
  const currencyDataFetched = get(resources, 'currency.data');

  const games = get(formValues, `${sourceNamePrefix}games`, []);
  const currencies = get(formValues, `${sourceNamePrefix}currencies`, []);

  const isTranslatable = games[0]?.id && typeof get(gameDataFetched, [games[0].id, 'name']) === 'object';

  const handleClickOnEditButton = (e, curItem) => {
    e.preventDefault();
    setCurBetSettingDisplay(curItem);
  };

  const handleClickOnDeleteButton = (e, curItem) => {
    e.preventDefault();
    setCurrencyGamePair(currencyGamePair.filter(item => item.id !== curItem.id));
    const betStKey = generateBetSettingKey({
      gameCode: curItem.game.code,
      currencyCode: curItem.currency.code,
    });

    // Remove Bet Setting Status
    const betSettingConfigStatus = cloneDeep(get(form.getState().values, `${sourceNamePrefix}betSettingConfigStatus`));
    delete betSettingConfigStatus[betStKey];
    const betSettingConfigStatusKey = `${sourceNamePrefix}betSettingConfigStatus`;

    // Remove Bet Setting Config
    const betSettings = cloneDeep(get(form.getState().values, `${sourceNamePrefix}betSettings`));
    delete betSettings[betStKey];
    const betSettingsKey = `${sourceNamePrefix}betSettings`;

    form.change(betSettingConfigStatusKey, betSettingConfigStatus);
    form.change(betSettingsKey, betSettings);
  };

  const listGame = useMemo(() => {
    if (Object.keys(gameDataFetched).length === 0) {
      return [];
    }

    const listGameResult = games.reduce((result, gItem) => {
      if (gameDataFetched[gItem.id]) {
        return [...result, gameDataFetched[gItem.id]];
      }
      return result;
    }, []);

    return listGameResult;
  }, [games, gameDataFetched, currencyGamePair]);

  const listCurrency = useMemo(() => {
    if (Object.keys(currencyDataFetched).length === 0) {
      return [];
    }

    const currencyListResult = currencies.reduce((result, cItem) => {
      if (currencyDataFetched[cItem.id]) {
        return [...result, currencyDataFetched[cItem.id]];
      }
      return result;
    }, []);

    return currencyListResult;
  }, [currencies, currencyDataFetched, currencyGamePair]);

  const handleGameSelect = gameId => {
    setCurrencyGamePairFormData({
      ...currencyGamePairFormData,
      game: gameDataFetched[gameId],
    });
  };

  const handleCurrencySelect = currencyId => {
    setCurrencyGamePairFormData({
      ...currencyGamePairFormData,
      currency: currencyDataFetched[currencyId],
    });
  };

  const handleConfig = () => {
    // Reset Bet Setting Panel form
    form.change('currencyGamePair', {});

    const newPairId = `${currencyGamePairFormData.game.id}${GAME_CURRENCY_PAIR_SPLIT_CHAR}${currencyGamePairFormData.currency.id}`;
    const newPairExist = currencyGamePair.some(item => item.id === newPairId);
    if (newPairExist) {
      return;
    }

    const currentPair = {
      id: `${currencyGamePairFormData.game.id}${GAME_CURRENCY_PAIR_SPLIT_CHAR}${currencyGamePairFormData.currency.id}`,
      game: currencyGamePairFormData.game,
      currency: currencyGamePairFormData.currency,
    };
    setCurrencyGamePair([currentPair, ...currencyGamePair]);
    setCurBetSettingDisplay(currentPair);
    setCurrencyGamePairFormData({
      id: null,
      game: null,
      currency: null,
    });
  };

  // Get the BetSetting list back and put into the state
  useEffect(() => {
    if (
      Object.keys(gameDataFetched).length > 0
      && Object.keys(currencyDataFetched).length > 0
      && currencyGamePair.length === 0
    ) {
      const betSettingConfigStatusKey = `${sourceNamePrefix}betSettingConfigStatus`;
      const betSettingConfigStatus = get(formValues, betSettingConfigStatusKey, {});

      const currencyMapByCode = Object.keys(currencyDataFetched).reduce(
        (result, key) => ({
          ...result,
          [currencyDataFetched[key].code.toUpperCase()]: currencyDataFetched[key],
        }),
        {},
      );

      const newCurrentGamePair = Object.keys(betSettingConfigStatus).map(key => {
        const [gameCode, currencyCode] = getCodeFromBetSettingKey(key);
        return {
          id: `${gameDataFetched[gameCode].id}${GAME_CURRENCY_PAIR_SPLIT_CHAR}${currencyMapByCode[currencyCode].id}`,
          game: gameDataFetched[gameCode],
          currency: currencyMapByCode[currencyCode],
        };
      });

      setCurrencyGamePair(newCurrentGamePair);
    }
  }, [gameDataFetched, currencyDataFetched]);

  // Update Bet Setting when Available Game or Currency Support is changed
  useEffect(() => {
    const currencyGamePairUnSupport = [];

    // Generate ID list of Currency and Games
    const listCurrencySupportIds = listCurrency.map(item => item.id);
    const listAvailableGameIds = listGame.map(item => item.id);

    // Filter pair and remove any pair if it's not be added
    const newCurrentGamePair = currencyGamePair.filter(item => {
      if (listAvailableGameIds.includes(item?.game?.id) && listCurrencySupportIds.includes(item?.currency?.id)) {
        return true;
      }
      currencyGamePairUnSupport.push({
        gameCode: item?.game?.code,
        currencyCode: item?.currency?.code,
      });
      return false;
    });

    // Detach the changing and update state and form value
    if (newCurrentGamePair.length !== currencyGamePair.length) {
      setCurrencyGamePair(newCurrentGamePair);

      const betSettings = get(form.getState().values, `${sourceNamePrefix}betSettings`);
      const betSettingConfigStatus = get(form.getState().values, `${sourceNamePrefix}betSettingConfigStatus`);

      currencyGamePairUnSupport.forEach(item => {
        const betStKey = generateBetSettingKey({
          gameCode: item.gameCode,
          currencyCode: item.currencyCode,
        });

        delete betSettings[betStKey];
        delete betSettingConfigStatus[betStKey];
      });

      form.change(`${sourceNamePrefix}betSettings`, betSettings);
      form.change(`${sourceNamePrefix}betSettingConfigStatus`, betSettingConfigStatus);
    }
  }, [get(formValues, `${sourceNamePrefix}games`), listCurrency, listGame]);

  return (
    <Box className={classes.container}>
      <Box
        id="game-currency-selection"
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {listGame.length > 0 && (
          <AutocompleteInput
            fullWidth
            label={translate('ra.action.selectGame')}
            source="currencyGamePair.game"
            disabled={!games.length}
            choices={listGame}
            optionText={isTranslatable ? `name.${locale}` : 'name'}
            optionValue="id"
            onChange={handleGameSelect}
          />
        )}
        {listCurrency.length > 0 && (
          <AutocompleteInput
            fullWidth
            label={translate('ra.action.selectCurrency')}
            source="currencyGamePair.currency"
            disabled={!currencies.length}
            choices={listCurrency}
            optionValue="id"
            onChange={handleCurrencySelect}
          />
        )}
        <Button
          color="primary"
          variant="contained"
          style={{
            height: '40px',
            marginTop: '8px',
          }}
          disabled={!currencyGamePairFormData?.game || !currencyGamePairFormData?.currency}
          onClick={handleConfig}
        >
          {translate('ra.action.setting', {
            _: 'Setting',
          })}
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        style={{
          marginTop: '8px',
          boxShadow: 'none',
        }}
      >
        <Table>
          <TableBody>
            {currencyGamePair.length > 0
              && currencyGamePair.map(gcMixItem => {
                const gameName = isTranslatable ? gcMixItem.game?.name?.[locale] : gcMixItem.game?.name;
                const betStKey = generateBetSettingKey({
                  gameCode: gcMixItem.game.code,
                  currencyCode: gcMixItem.currency.code,
                });

                const configStatus = get(
                  formValues,
                  `${sourceNamePrefix}betSettingConfigStatus.${betStKey}`,
                  BET_SETTING_CONFIG_STATUS.defaultSetting,
                );

                return (
                  <TableRow
                    key={gcMixItem.id}
                    className={classes.rowRoot}
                  >
                    <TableCell
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          gap: '8px',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <Chip
                            label={gameName}
                            style={{
                              width: screenLg ? '400px' : '200px',
                            }}
                          />
                          <AddIcon />
                          <Chip
                            label={gcMixItem.currency?.name}
                            style={{
                              width: screenLg ? '400px' : '200px',
                            }}
                          />
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            marginLeft: 'auto',
                          }}
                        >
                          <Typography
                            style={{
                              textTransform: 'uppercase',
                              fontSize: '15px',
                            }}
                          >
                            {translate(configStatus?.text)}
                          </Typography>
                          <IconButton
                            disableRipple
                            color="primary"
                            style={{
                              background: '#c3d9f2',
                              padding: '4px 12px',
                              borderRadius: '4px',
                              '&:hover': {
                                background: '#b1d3f9',
                              },
                            }}
                            onClick={e => handleClickOnEditButton(e, gcMixItem)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            disableRipple
                            color="primary"
                            style={{
                              background: '#ffd9f2',
                              padding: '4px 12px',
                              borderRadius: '4px',
                              '&:hover': {
                                background: '#ffd3f9',
                              },
                            }}
                            onClick={e => handleClickOnDeleteButton(e, gcMixItem)}
                          >
                            <DeleteForeverIcon htmlColor={muiTheme.palette.error.main} />
                          </IconButton>
                        </Box>

                        <BetSettingFormProvider
                          name={gcMixItem.game.name}
                          sourceNamePrefix={sourceNamePrefix}
                          game={cloneDeep(gcMixItem.game)}
                          currency={cloneDeep(gcMixItem.currency)}
                        >
                          <BetSettingDialog
                            open={curBetSettingDisplay?.id === gcMixItem.id}
                            onClose={() => {
                              setCurBetSettingDisplay(undefined);
                            }}
                            game={gcMixItem.game}
                            currency={gcMixItem.currency}
                            sourceNamePrefix={sourceNamePrefix}
                            totalBetMinSourceName={`${sourceNamePrefix}totalBetMin`}
                            totalBetMaxSourceName={`${sourceNamePrefix}totalBetMax`}
                            pickerSourceName={`${sourceNamePrefix}picker`}
                          />
                        </BetSettingFormProvider>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}

            {/* EMPTY CASE */}
            {!currencyGamePair.length && (
              <TableRow
                className={classes.rowRoot}
                style={{
                  height: 100,
                }}
              >
                <TableCell>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#787878',
                    }}
                  >
                    {translate('ra.message.betSettingFormWithoutProperty')}
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

BetSettingPanel.defaultProps = {};

BetSettingPanel.propTypes = {
  index: PropTypes.number.isRequired,
};

export default BetSettingPanel;
