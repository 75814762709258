import React from 'react';
import { useTranslate } from 'react-admin';
import ReferenceInput from '../../../base/components/ra/inputs/reference.input';
import { AutocompleteInput } from '../../../base/components/ra/inputs';

const CurrencyFilter = () => {
  const translate = useTranslate();

  return (
    <ReferenceInput
      label={translate('resources.currency.name')}
      source="currency"
      reference="currency"
      perPage={null}
    >
      <AutocompleteInput
        emptyText={`--${translate('ra.text.all').toUpperCase()}--`}
        optionText="name"
        resettable
        filterToQuery={value => ({
          'name||$cont': value,
        })}
      />
    </ReferenceInput>
  );
};

export default CurrencyFilter;
