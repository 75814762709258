import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SmallButton } from '../../../base/components/ra/buttons';
import Confirm from '../../../base/components/ra/layout/Confirm';

// Confirm call to action
const ConfirmCTA = ({
  title,
  content,
  onConfirm,
  buttonTrigger: {
    icon: buttonTriggerIcon, label: buttonTriggerLabel,
  },
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  return (
    <>
      <Confirm
        isOpen={confirmDialogOpen}
        title={title}
        content={content}
        onConfirm={() => {
          if (typeof onConfirm === 'function') {
            onConfirm();
          }
          setConfirmDialogOpen(false);
        }}
        onClose={() => setConfirmDialogOpen(false)}
      />
      <SmallButton
        label={buttonTriggerLabel}
        onClick={() => setConfirmDialogOpen(true)}
        startIcon={buttonTriggerIcon}
      />
    </>
  );
};

ConfirmCTA.defaultProps = {
  title: '',
  content: '',
  onConfirm: f => f,
  buttonTrigger: PropTypes.shape({
    icon: '',
    label: '',
  }),
};

ConfirmCTA.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func,
  buttonTrigger: PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string,
  }),
};

export default ConfirmCTA;
