/* eslint-disable import/no-named-as-default,no-shadow */
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, Box, Button, Stepper as MuiStepper, Step, StepLabel, Card, CardContent } from '@material-ui/core';
import { required, useTranslate, SimpleForm, FormDataConsumer, useNotify, AutocompleteInput } from 'react-admin';
import { useForm } from 'react-final-form';
import axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useError, useLoading } from '../../../base/hooks';
import QuickSetupStepper from '../quick-setup-components/QuickSetupStepper';
import QuickSetupPreview from '../quick-setup-components/QuickSetupPreview';
import {
  getFormDataFromStorage,
  removeFormDataToStorage,
  transformErrorResponseToObject,
  transformNewGroupQuickSetupPayload,
  saveFormDataToStorage,
} from '../quick-setup-components/utils';
import { useQuickSetupErrorHandling } from '../quick-setup-components/utils/QuickSetupErrorHandling';
import CreatedConfirmation from '../quick-setup-components/CreatedConfirmation';
import MultiBrandForm from '../quick-setup-components/forms/MultiBrandForm';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';
import { QUICK_SETUP_NEW_BRAND } from '../../../services/local-storage';
import {
  FieldName,
  FieldValue,
  FieldWrapper,
  Title,
} from '../quick-setup-components/QuickSetupPreview/HelperComponents';
import ReferenceInput from '../../../base/components/ra/inputs/reference.input';
import DetectStorageVersionDialog from '../quick-setup-components/DetectStorageVersionDialog';
import { FORM_INIT_VERSION } from '../quick-setup-components/constants';
import SlickContent from '../../../base/components/simple-slick/SlickContent';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .simple-form > div': {
      padding: 0,
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

function getSteps(translate) {
  const stepsRawText = ['ra.text.selectGroup', 'ra.text.createNewBrands', 'ra.text.preview'];

  if (typeof translate === 'function') {
    return stepsRawText.map(text => translate(text));
  }

  return stepsRawText;
}

const GroupForm = () => {
  const form = useForm();
  const translate = useTranslate();

  useEffect(() => {
    const formDataStorage = getFormDataFromStorage(QUICK_SETUP_NEW_BRAND);
    if (isEmpty(formDataStorage)) {
      return;
    }
    form.initialize(formDataStorage);
  }, []);

  const anyText = translate('ra.text.any').toUpperCase();

  return (
    <Card>
      <CardContent>
        <ReferenceInput
          label="resources.group.name"
          alwaysOn
          reference="group"
          source="group.id"
          perPage={100}
          filterToQuery={value => ({
            name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
          })}
          validate={required()}
        >
          <AutocompleteInput
            resettable
            emptyText={anyText}
            optionText="name"
          />
        </ReferenceInput>
      </CardContent>
    </Card>
  );
};

const StepContentWrapper = ({ children }) => <Box padding="4px">{children}</Box>;

StepContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const QuickSetupNewBrand = () => {
  const translate = useTranslate();
  const steps = getSteps(translate);
  const { toggleLoading } = useLoading();
  const notify = useNotify();
  const { notifyError } = useError();
  const { setQuickSetupError } = useQuickSetupErrorHandling();

  const appLoading = useSelector(({ app }) => app.isLoading);
  const resources = useSelector(({ admin }) => admin.resources);

  const [createdDataRes, setCreatedDataRes] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [readyToLoadForm, setReadyToLoadForm] = useState(false);
  const timeoutSaveStorage = useRef();

  const classes = useStyles();

  const handleSaveClick = async (values, form) => {
    const payload = transformNewGroupQuickSetupPayload(values, true);

    try {
      toggleLoading(true);
      const { data } = await axios.post('api/quick-setup', payload);
      setCreatedDataRes(data);
      // notify('Quick setup create new group is successfully', 'success');

      // Clean form data
      form.reset({});
      removeFormDataToStorage(QUICK_SETUP_NEW_BRAND);
    } catch (err) {
      console.debug('[ERROR] when requesting to create new group quick setup\n', err.message);

      const errorMessageData = get(err, 'response.data.message');
      if (!Array.isArray(errorMessageData)) {
        notifyError(err);
        return;
      }

      notify('We have some issues with request data. Please check it again.', {
        type: 'error',
        autoHideDuration: 3000,
      });
      const errorDataObj = transformErrorResponseToObject(errorMessageData);
      setQuickSetupError(errorDataObj);
    } finally {
      toggleLoading(false);
    }
  };

  const handleFormInitVersion = version => {
    if (version === FORM_INIT_VERSION.NEW_FORM) {
      removeFormDataToStorage(QUICK_SETUP_NEW_BRAND);
    }
    setReadyToLoadForm(true);
  };

  return (
    <div className={classes.root}>
      {readyToLoadForm && (
        <>
          <Box padding="4px">
            <MuiStepper
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </MuiStepper>
          </Box>

          <SimpleForm
            initialValues={{}}
            toolbar={null}
          >
            <FormDataConsumer>
              {({ formData }) => {
                const form = useForm();

                useEffect(() => {
                  timeoutSaveStorage.current = setInterval(() => {
                    saveFormDataToStorage(form.getState().values, QUICK_SETUP_NEW_BRAND);
                  }, 3000);

                  return function cleanUp() {
                    clearInterval(timeoutSaveStorage.current);
                  };
                }, []);

                return (
                  <QuickSetupStepper
                    storageKey={QUICK_SETUP_NEW_BRAND}
                    numOfStep={3}
                    onStepperChange={index => {
                      setActiveStep(index);
                    }}
                    buttonLastStep={(
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginLeft: 'auto',
                        }}
                        disabled={appLoading}
                        onClick={e => {
                          e.stopPropagation();
                          handleSaveClick(formData, form);
                          clearInterval(timeoutSaveStorage.current);
                        }}
                      >
                        {translate('ra.action.save')}
                      </Button>
                    )}
                  >
                    <SlickContent name="group-form-step">
                      <StepContentWrapper>
                        <GroupForm />
                      </StepContentWrapper>
                    </SlickContent>

                    <SlickContent name="multi-brand-form-step">
                      <StepContentWrapper>
                        <MultiBrandForm storageKey={QUICK_SETUP_NEW_BRAND} />
                      </StepContentWrapper>
                    </SlickContent>

                    <SlickContent name="preview-step">
                      <StepContentWrapper>
                        <FormDataConsumer>
                          {({ formData }) => {
                            const groupDataFetched = get(resources, 'group.data');
                            const groupData = formData?.group?.id ? groupDataFetched[formData.group.id] : {};
                            return (
                              <QuickSetupPreview
                                groupInfo={(
                                  <>
                                    <Title>{translate('ra.text.groupInformation')}</Title>
                                    <Box
                                      style={{
                                        gap: '20px',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        padding: '12px',
                                        paddingBottom: '16px',
                                      }}
                                    >
                                      <FieldWrapper>
                                        <FieldName>ID</FieldName>
                                        <FieldValue>
                                          <a
                                            className={classes.link}
                                            href={`/#/group/${groupData?.id}/show`}
                                          >
                                            {groupData?.id}
                                          </a>
                                        </FieldValue>
                                      </FieldWrapper>
                                      {keys(pick(groupData, ['name', 'code', 'enabled'])).map(gKeyItem => (
                                        <FieldWrapper key={gKeyItem}>
                                          <FieldName>{translate(`resources.group.fields.${gKeyItem}`)}</FieldName>
                                          <FieldValue>{get(groupData, gKeyItem, '')?.toString()}</FieldValue>
                                        </FieldWrapper>
                                      ))}
                                    </Box>
                                  </>
                                )}
                              />
                            );
                          }}
                        </FormDataConsumer>
                      </StepContentWrapper>
                    </SlickContent>
                  </QuickSetupStepper>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </>
      )}

      <DetectStorageVersionDialog
        storageKey={QUICK_SETUP_NEW_BRAND}
        setFormInitVersion={handleFormInitVersion}
      />

      {createdDataRes && (
        <CreatedConfirmation
          data={createdDataRes}
          title={translate('ra.text.newBrandCreateSuccess')}
        />
      )}
    </div>
  );
};

export default QuickSetupNewBrand;
