import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';

const ClearableButton = ({
  handleClear,
  isDisplay,
}) => (isDisplay ? (
  <ClearIcon
    style={{
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.26)',
      width: '16px',
      height: '16px',
      margin: '0 6px',
    }}
    onClick={e => handleClear?.(e)}
  />
) : null);

ClearableButton.propTypes = {
  handleClear: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool.isRequired,
};

ClearableButton.defaultProps = {};

export default ClearableButton;
