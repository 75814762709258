import Axios from 'axios';
import { getResources, useNotify, useRefresh, useTranslate } from 'ra-core';
import { makeStyles, Button, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Type from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import { docStatus } from '../../enumStatus';
import RejectModal from '../../guesser/component/rejectModal';
import Confirm from '../../ra/layout/Confirm';

const useStyles = makeStyles(theme => ({
  btn: {
    padding: '7px 22px',
  },
  cancel: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export const Workflow = ({
  record, resource,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resources = useSelector(getResources);

  const {
    options: {
      approvable, submittable, cancelAfterApproval,
    },
  } = resources?.find(r => r.name === resource);

  const [isOpenDialog, setDialog] = useState();
  const [isConfirmSubmit, setConfirmSubmit] = useState(false);
  const [isConfirmApprove, setConfirmApprove] = useState(false);
  const [isConfirmCancel, setConfirmCancel] = useState(false);

  const approve = async () => {
    try {
      await Axios.post(`api/${resource}/${record.id}/approve`);

      notify(translate('resources.fields.docStatus.approved'), 'success');
      refresh();
    } catch (error) {
      if (!error?.response) {
        notify(translate('ra.message.tryAgain'), 'error');
      }
      notify(`Error! ${error.response.data.message}`, 'error');
    }
  };

  const submit = async () => {
    try {
      await Axios.post(`api/${resource}/${record.id}/submit`);

      notify(translate('resources.fields.docStatus.submitted', 'success'));
      refresh();
    } catch (error) {
      if (!error?.response) {
        notify(translate('ra.message.tryAgain'), 'error');
      }
      notify(`Error! ${error.response.data.message}`, 'error');
    }
  };

  const cancelAfterApprovalFunc = async () => {
    try {
      await Axios.post(`api/${resource}/${record.id}/cancelAfterApproval`);

      notify(
        translate('resources.fields.docStatus.cancelAfterApproval'),
        'success',
      );
      refresh();
    } catch (error) {
      if (!error?.response) {
        notify(translate('ra.message.tryAgain'), 'error');
      }
      notify(`Error! ${error.response.data.message}`, 'error');
    }
  };

  return (
    <>
      {record?.docStatus === docStatus.DRAFT && submittable && (
        <Tooltip
          title={translate('ra.action.submit')}
          placement="top"
        >
          <Button
            color="primary"
            className={classes.btn}
            variant="contained"
            onClick={() => setConfirmSubmit(true)}
          >
            <CheckIcon />
          </Button>
        </Tooltip>
      )}

      {record?.docStatus === docStatus.SUBMITTED && approvable && (
        <Tooltip
          title={translate('ra.action.approve')}
          placement="top"
        >
          <Button
            color="primary"
            className={classes.btn}
            variant="contained"
            onClick={() => setConfirmApprove(true)}
          >
            <CheckIcon />
          </Button>
        </Tooltip>
      )}

      {record?.docStatus === docStatus.APPROVED && cancelAfterApproval && (
        <Tooltip
          title={translate('ra.action.cancel')}
          placement="top"
        >
          <Button
            color="primary"
            className={clsx(classes.btn, classes.cancel)}
            variant="contained"
            onClick={() => {
              setConfirmCancel(true);
            }}
          >
            <CloseIcon />
          </Button>
        </Tooltip>
      )}

      {record?.docStatus === docStatus.SUBMITTED && (
        <Tooltip
          title={translate('ra.action.cancel')}
          placement="top"
        >
          <Button
            color="primary"
            className={clsx(classes.btn, classes.cancel)}
            variant="contained"
            onClick={() => setDialog(true)}
          >
            <CloseIcon />
          </Button>
        </Tooltip>
      )}

      <Confirm
        isOpen={isConfirmSubmit}
        title={translate('ra.field.submit')}
        content={translate('ra.message.submit')}
        onConfirm={() => {
          submit();
          setConfirmSubmit(false);
        }}
        onClose={() => setConfirmSubmit(false)}
      />
      <Confirm
        isOpen={isConfirmApprove}
        title={translate('ra.field.approve')}
        content={translate('ra.message.approve')}
        onConfirm={() => {
          approve();
          setConfirmApprove(false);
        }}
        onClose={() => setConfirmApprove(false)}
      />
      <Confirm
        isOpen={isConfirmCancel}
        title={translate('ra.field.cancelAfterApprovalFunc')}
        content={translate('ra.message.cancelAfterApprovalFunc')}
        onConfirm={() => {
          cancelAfterApprovalFunc();
          setConfirmCancel(false);
        }}
        onClose={() => setConfirmCancel(false)}
      />
      <RejectModal
        resource={resource}
        record={record}
        open={isOpenDialog}
        onClose={() => setDialog(false)}
      />
    </>
  );
};

Workflow.propTypes = {
  record: Type.object,
  resource: Type.string,
};

Workflow.defaultProps = {
  record: {},
  resource: '',
};
