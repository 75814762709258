import React, { useEffect, useRef } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';
import CurrenciesConfig from '../../../base/components/currencies-config';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';

export const CURRENCIES_FIELD_KEY = 'currencies';

const CurrenciesConfigWrapper = props => {
  const { initialGroupId } = props;
  const { values } = useFormState();
  const form = useForm();
  const record = useRecordContext();
  const groupId = values.group?.id || record.group?.id || initialGroupId;
  const isMount = useRef(false);

  const currencies = values?.[CURRENCIES_FIELD_KEY];

  useEffect(() => {
    // To reset currencies when group changes after rendering (To prevent reset when the brand has a group already)
    if (!isMount.current) {
      isMount.current = true;
      return;
    }
    form.change(CURRENCIES_FIELD_KEY, []);
  }, [values.group?.id]);

  return (
    <CurrenciesConfig
      source={CURRENCIES_FIELD_KEY}
      selectedCurrencies={currencies}
      currenciesSelectorProps={{
        filterToQuery: value => ({
          name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
          'groups.id||$in': groupId,
        }),
      }}
      {...props}
    />
  );
};

CurrenciesConfigWrapper.propTypes = {
  initialGroupId: PropTypes.string,
};

CurrenciesConfigWrapper.defaultProps = {
  initialGroupId: '',
};

export default CurrenciesConfigWrapper;
