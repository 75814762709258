/* eslint-disable import/no-dynamic-require, global-require */
import * as React from 'react';
import { Route } from 'react-router-dom';
import { CUSTOM_ROUTES } from '../../constant/customRoutes';
import { RoleBuiltIn } from '../../services/util';
import ResetPassword from '../../theme/@jumbo/components/Common/authComponents/ResetPassword';
import Profile from './profile';
import { QuickSetupNewGroupRoute, QuickSetupNewBrandRoute } from './quick-setup-components';
import { CUSTOM_RESOURCES } from '../../constant/customResources';

export const customRoutes = [
  <Route
    exact
    path={CUSTOM_ROUTES.PROFILE}
    component={Profile}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.QUICK_SETUP_NEW_GROUP}
    component={QuickSetupNewGroupRoute}
    resource={CUSTOM_RESOURCES.QUICK_SETUP.name}
    accessibleRoleIds={[RoleBuiltIn.SYSTEM_ADMIN]}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.QUICK_SETUP_NEW_BRAND}
    component={QuickSetupNewBrandRoute}
    resource={CUSTOM_RESOURCES.QUICK_SETUP.name}
    accessibleRoleIds={[RoleBuiltIn.SYSTEM_ADMIN]}
  />,
];

export const publicCustomRoutes = [<Route
  exact
  path="/reset-password/:token"
  component={ResetPassword}
  noLayout
/>];
