import { GROUP_ADMIN_LEVEL } from '../../../constant';
import { canI, RbacAction } from '../../../services/provider/rbacProvider';
import {
  RoleAccessibility,
  RoleBuiltIn,
  RoleType,
} from '../../../services/util';
import { genUUID } from '../../../services/util/bet-setting.utils';

const betSettingId = genUUID('bet-setting', 'resource');
const betSettingDefaultId = genUUID('bet-setting-default', 'resource');

const isCustomRole = role => role?.accessibility === RoleAccessibility.CUSTOM_ROLE;

const validateSpecialResource = (resourceId, action, role) => {
  const isPermInDbCR = isCustomRole(role);

  const isUpdatePermCRLv10 = isPermInDbCR && role.level === GROUP_ADMIN_LEVEL;

  if ([betSettingId].includes(resourceId)) {
    // Custom Role Level 10 cannot create / delete Bet Setting
    // CR lv10 can only update read + list + update
    if (
      isUpdatePermCRLv10
      && [RbacAction.CREATE, RbacAction.DELETE, RbacAction.EXPORT].includes(action)
    ) {
      return true;
    }

    // All roles except CR lv10 can only update read + list
    if (
      !isUpdatePermCRLv10
      && [
        RbacAction.CREATE,
        RbacAction.DELETE,
        RbacAction.UPDATE,
        RbacAction.EXPORT,
      ].includes(action)
    ) {
      return true;
    }
  }

  // Bet setting default resource can only update read action.
  if (betSettingDefaultId === resourceId && action !== RbacAction.READ) {
    return true;
  }

  return false;
};

export const checkDisabledUpdatePerm = (role = {}, reqUser = {}) => {
  const reqUserRole = reqUser?.role;
  const isPermInDbCR = isCustomRole(role);
  const isReqUserCR = isCustomRole(reqUserRole);

  const isUpdatePermCRLv10 = isPermInDbCR && role.level === GROUP_ADMIN_LEVEL;

  // only SA update custom role lv 10
  if (isUpdatePermCRLv10 && reqUserRole?.id !== RoleBuiltIn.SYSTEM_ADMIN) {
    return true;
  }

  // custom role can update only custom role
  if (isReqUserCR && !isPermInDbCR) {
    return true;
  }

  // group admin can not update role all groups
  if (reqUserRole?.id === RoleBuiltIn.GROUP_ADMIN && !isPermInDbCR) {
    return true;
  }

  // cannot update permission of roles valid in another group
  if (
    isPermInDbCR
    && reqUser.role?.type === RoleType.GROUP
    && reqUser.group?.id !== role.group?.id
  ) {
    return true;
  }

  return false;
};

export const checkDisableAction = ({
  action, resource, permissions, role,
}) => {
  if (action === 'onlyMe') {
    return false;
  }

  const disabledSeedPerm = resource.seedPerm?.[action];

  // validateSpecialResource Bet Setting + Bet Setting Default
  const disabledSpecialResource = validateSpecialResource(
    resource.id,
    action,
    role,
  );

  let disabledHighestPerm = false;

  // validatePermBasedOnHighestRole
  if (![betSettingId, betSettingDefaultId].includes(resource.id)) {
    disabledHighestPerm = !resource.highestPerm?.[action];
  }

  const disabled = disabledSpecialResource
    || disabledHighestPerm
    || disabledSeedPerm
    || !resource[action]
    || !canI(action, resource.name, permissions);

  return disabled;
};
