/* eslint-disable react/require-default-props */
import { usePermissions, useResourceContext } from 'react-admin';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAuthUser } from '../hooks';

const EnsurePermission = ({
  canRead,
  canUpdate,
  canList,
  canDelete,
  canExport,
  resource: customizedResource,
  children,
  fallback,
}) => {
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const defaultResource = useResourceContext();
  const resource = customizedResource || defaultResource;

  const canShow = useMemo(() => {
    if (Array.isArray(permissions) && !permissions.length) return false;
    if (!resource || user?.superman) return true;
    const hasReadPermission = canRead
      ? !!permissions?.find(item => item.resource.name === resource)?.read
      : undefined;
    const hasListPermission = canList
      ? !!permissions?.find(item => item.resource.name === resource)?.list
      : undefined;
    const hasUpdatePermission = canUpdate
      ? !!permissions?.find(item => item.resource.name === resource)?.update
      : undefined;
    const hasDeletePermission = canDelete
      ? !!permissions?.find(item => item.resource.name === resource)?.delete
      : undefined;
    const hasExportPermission = canExport
      ? !!permissions?.find(item => item.resource.name === resource)?.export
      : undefined;

    return [hasReadPermission, hasListPermission, hasUpdatePermission, hasDeletePermission, hasExportPermission]
      .filter(item => typeof item === 'boolean')
      .every(item => !!item);
  }, [canRead, canUpdate, canList, canDelete, canExport, resource, permissions, user]);

  if (!canShow) return fallback || null;
  return children;
};

EnsurePermission.propTypes = {
  canRead: PropTypes.bool,
  canUpdate: PropTypes.bool,
  canList: PropTypes.bool,
  canDelete: PropTypes.bool,
  canExport: PropTypes.bool,
  resource: PropTypes.string,
  children: PropTypes.ReactNode,
  fallback: PropTypes.ReactNode,
};

export default EnsurePermission;
