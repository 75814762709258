/* eslint-disable  */
import React from "react";
import pick from "lodash/pick";
import WealthCreateGuesser from "../../base/components/guesser/wealth-create.guesser";
import WealthInputWrapper from "../../base/components/guesser/wealth-input-wrapper";
import CheatModeTemplateInput from "./components/template.input";

const CheatModeTemplateCreate = (props) => {
  const customFields = ["scripts"];

  return (
    <WealthCreateGuesser {...props} customFields={customFields}>
      <WealthInputWrapper source="scripts">
        <CheatModeTemplateInput />
      </WealthInputWrapper>
    </WealthCreateGuesser>
  );
};

export default CheatModeTemplateCreate;
