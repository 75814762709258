import axios from 'axios';
import { forceLogout } from '../util/handleByHttpCode';
import { handleRequireChangePassword, handleNotifyErrorOutside } from './interceptor.controller';
import { STATUS_REQUIRED_CHANGE_PASSWORD } from '../../constant';

const handleResponseError = error => {
  if (error?.response?.status === 401) {
    forceLogout();
  }

  const excludeGeneralHandleErrorNotify = ['quick-setup', 'auth/me'];
  const belongToExcludeRoute = excludeGeneralHandleErrorNotify.some(item => error?.response?.config?.url.endsWith(item));
  if (belongToExcludeRoute) {
    return Promise.reject(error);
  }
  handleNotifyErrorOutside(error);

  return Promise.reject(error);
};

const handleResponseSuccess = response => {
  if (response.status === STATUS_REQUIRED_CHANGE_PASSWORD) {
    handleRequireChangePassword();
  }

  return response;
};

// Add a request interceptor
axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
