/* eslint-disable */
import React from 'react';
import { useTranslate } from 'ra-core';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

export const useStyles = makeStyles(() => ({
  attentionBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
    background: '#5f5f5fde',
    padding: 32,
    zIndex: 100,
  },
}));

export const AddIcon = () => (
  <AddCircleOutlineIcon
    style={{
      fontSize: '1.5rem',
    }}
  />
);

export const renderBrandItemEndAdornment = (playerIds, theme) => (playerIds?.length > 0 ? (
  <PlaylistAddCheckIcon
    style={{
      color: theme.palette.primary.main,
    }}
  />
) : (
  <PlaylistAddIcon
    style={{
      color: '#d3d3d3',
    }}
  />
));

// export const PlayerAttentionBox = ({ brandsSelected }) => {
//   const classes = useStyles();
//   const translate = useTranslate();

//   return (
//     <Box className={classes.attentionBox}>
//       {brandsSelected.length > 0
//         ? translate('ra.message.sendAllPlayersOfBrandsWhenSomeBrandSelected')
//         : translate('ra.message.sendAllPlayersOfBrandsWhenNoBrandSelected')}
//     </Box>
//   );
// };

export const TableTitle = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    fontSize="16px"
    fontWeight={700}
  >
    {children}
  </Box>
);

export const TableTitleBright = ({
  theme, name,
}) => (
  <span
    style={{
      color: theme.palette.primary.light,
    }}
  >
    {name?.length > 20
      ? `${name.slice(0, 10)}...${name.slice(-10)}`
      : name}
  </span>
);
