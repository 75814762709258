import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { CreateButton, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import ReverseIntegrationLayout from '../../../base/components/reverse-integration/common-layout';
import { REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';
import GroupConfig from '../../../base/components/reverse-integration/group-configuration';
import OperatorList from './components/OperatorList';
import CommonWrapper from '../../../base/components/reverse-integration/common-wrapper';
import { NavigationContext } from '../../../App';

const useStyles = makeStyles(theme => ({
  createButton: {
    color: 'white',
    background: theme.palette.primary.main,
  },
}));

const EmptyOperator = ({ setIsEmpty }) => {
  const styles = useStyles();
  const translate = useTranslate();

  useEffect(() => {
    setIsEmpty(true);
    return () => {
      setIsEmpty(false);
    };
  }, []);
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <CreateButton
        className={styles.createButton}
        label={translate('resources.reverse-integration.operator.addOperator')}
      />
    </Box>
  );
};

EmptyOperator.propTypes = {
  setIsEmpty: PropTypes.func.isRequired,
};

const OperatorListPage = () => {
  const { setNavigationData } = useContext(NavigationContext);
  const translate = useTranslate();
  const [{
    groupId, id: configurationId,
  }, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  const handleFetchConfig = async () => {
    try {
      const configResponse = await axios.get(`api/${REVERSE_INTEGRATION_OPERATORS.QTECH.id}/config`);
      const config = configResponse.data.data?.[0] || {};
      setData(config);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchConfig();
  }, []);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: translate('resources.reverse-integration.name'),
      },
      breadcrumbs: [
        {
          label: translate('ra.page.home'),
          link: '/',
        },
        {
          label: translate('resources.group.system-setup.name'),
        },
        {
          label: translate('resources.reverse-integration.name'),
          link: '/reverse-integration',
        },
        {
          label: REVERSE_INTEGRATION_OPERATORS.QTECH.name,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, []);

  return (
    <ReverseIntegrationLayout
      searchBarProps={{
        defaultValue: REVERSE_INTEGRATION_OPERATORS.QTECH,
      }}
    >
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent="center"
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            gap: 24,
          }}
        >
          {isEmpty && (
            <GroupConfig
              groupInfo={{
                value: groupId,
                key: 'groupId',
              }}
              configurationId={configurationId}
              operatorId={REVERSE_INTEGRATION_OPERATORS.QTECH.id}
            />
          )}
          <CommonWrapper title={!isEmpty && translate('resources.reverse-integration.operator.name')}>
            <OperatorList
              resource={`${REVERSE_INTEGRATION_OPERATORS.QTECH.id}/operator`}
              groupId={groupId}
              empty={<EmptyOperator setIsEmpty={setIsEmpty} />}
            />
          </CommonWrapper>
        </Box>
      )}
    </ReverseIntegrationLayout>
  );
};

export default OperatorListPage;
