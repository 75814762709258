import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';

import { Create } from '../../base/components/ra/views';
import { useCreateCustom } from '../../base/hooks';
import { showConfirmDialog } from '../../services/redux/app/app.actions';
import rcSlug from '../../constant/resource-slug';
import PrepaidCreateForm from './components/prepaidCreateForm';
import { sanitizeObject } from '../../services/util';
import { dateTimeMustBeAfterCurrent } from '../../services/util/validate/validateMethods';
import { EXPIRES_INPUT_ID } from './components/ExpiresInput';

const PrepaidCreate = props => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { create } = useCreateCustom({
    resource: rcSlug.PREPAID,
    resourceTranslated: translate(`resources.${rcSlug.PREPAID}.name`),
  });

  const transform = data => {
    const isSelectAllPlayer = !!(!Array.isArray(data.players) || !data.players?.length);
    return sanitizeObject({
      name: data.name,
      desc: data.desc,
      currency: {
        id: data.currencyId,
      },
      brand: {
        id: data.brandId,
      },
      group: {
        id: data.groupId,
      },
      game: {
        id: data.gameId,
      },
      reward: {
        typeId: data.spinTypeId,
        module: 'prepaid',
        count: Number(data.count),
        claimBefore: data.claimBefore,
      },
      inboxRule: {
        message: {
          data: data.inboxRuleMgsTemplate,
        },
      },
      betSetting: {
        betSize: Number(data.betSize),
        betLevel: Number(data.betLevel),
      },
      players: isSelectAllPlayer ? null : data.players,
      issuer: data.issuer,
    });
  };

  const createPrepaid = values => {
    if (dateTimeMustBeAfterCurrent(translate)(values.claimBefore)) {
      const prepaid = document.getElementById(EXPIRES_INPUT_ID);
      // To trigger customized validation event
      prepaid.focus();
      prepaid.blur();
      prepaid.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    } else {
      const confirmCreate = () => {
        create({
          resource: rcSlug.PREPAID,
          payload: {
            data: transform(values),
          },
        });
      };

      const isSelectAllPlayer = !!(!Array.isArray(values.players) || !values.players?.length);
      const confirmationContent = isSelectAllPlayer
        ? translate('ra.message.savePrepaidConfirmationWithAllPlayer')
        : translate('ra.message.savePrepaidConfirmationWithSelectedPlayer');

      dispatch(
        showConfirmDialog({
          isOpen: true,
          title: 'ra.action.create',
          content: confirmationContent,
          onConfirm: confirmCreate,
        }),
      );
    }
  };

  return (
    <Create
      {...props}
      forceCustomSave
      onCustomSave={createPrepaid}
    >
      <SimpleForm redirect="show">
        <PrepaidCreateForm />
      </SimpleForm>
    </Create>
  );
};

export default PrepaidCreate;
