import * as React from 'react';
import { Box, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { ID_EMPTY } from '../../../../constant';
import { transformIdValue } from '../../../../services/util/stringUtil';

const IdField = props => {
  const {
    record, source = 'id', hasShow, resource,
  } = props;

  const id = get(record, source);

  const content = id ? transformIdValue(id) : ID_EMPTY;

  return (
    <Box>
      {(hasShow && resource) ? (
        <Link
          href={`/#/${resource}/${id}/show`}
        >
          {content}
        </Link>
      ) : content}
    </Box>
  );
};

IdField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  hasShow: PropTypes.bool,
  resource: PropTypes.string,
};
IdField.defaultProps = {
  record: {},
  source: 'id',
  hasShow: false,
  resource: undefined,
};

export default IdField;
