/* eslint-disable react/prop-types */
import RepeatIcon from '@material-ui/icons/RepeatOne';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import Axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate } from 'ra-core';
import React from 'react';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import { SmallButton } from '../../../base/components/ra/buttons';

const ActionMenu = props => {
  const {
    record, resource,
  } = props;

  const notify = useNotify();
  const translate = useTranslate();

  const triggerEvent = () => {
    Axios.post(`api/${resource}/${record?.id}/retry`)
      .then(res => {
        if (res.status === 201) {
          notify(translate('ra.notification.retrySuccess'), 'success');
        }
      })
      .catch(() => {
        notify(translate('ra.notification.retryFailure'), 'error');
      });
  };

  const checkTransactionByBet = () => {
    const filter = {
      bet: {
        id: record?.betId,
      },
    };

    const filterQueryString = encodeURIComponent(JSON.stringify(filter));

    window.open(`/#/transaction-seamless?filter=${filterQueryString}`, '_blank');
  };

  return (
    <ToolbarMenu {...props}>
      {record?.status !== 'COMPLETED' && (
        <SmallButton
          label="ra.action.retry"
          onClick={triggerEvent}
          startIcon={<RepeatIcon />}
        />
      )}

      <SmallButton
        label="ra.action.viewTransaction"
        onClick={checkTransactionByBet}
        startIcon={<CallMissedOutgoingIcon />}
      />
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};

export default ActionMenu;
