/* eslint-disable */
import _ from 'lodash';
import { InboxType } from './inbox-rule.const';

export const formatPayloadData = (values = {}) => {
  const {
    name,
    message,
    desc,
    hub,
    module,
    type,
    game,
    dynamicGroupStep,
    groups,
  } = values;

  if (!_.isObject(dynamicGroupStep?.groups)) {
    return {};
  }

  const dynamicRuleValue = _.values(dynamicGroupStep?.groups).reduce((dynamicRule, curGroupItem, curIndex) => {
    if (!curGroupItem?.id || !_.isObject(curGroupItem?.brands)) {
      return dynamicRule;
    }

    const dynamicRuleCloned = [...dynamicRule];

    const brandData = _.toPairs(curGroupItem.brands).map(([bId, bValue]) => {
      const playerData = Array.isArray(bValue?.players)
        ? bValue?.players.map(pId => ({
          id: pId,
        }))
        : _.keys(bValue?.players).map(pId => ({
          id: pId,
        }));

      return {
        id: bId,
        players: playerData.length > 0 ? playerData : undefined,
      };
    });

    dynamicRuleCloned[curIndex] = {
      id: curGroupItem.id,
      brands: brandData.length > 0 ? brandData : undefined,
    };

    return dynamicRuleCloned;
  }, []);

  const creatingPayload = {
    name,
    message,
    desc,
    hub,
    module,
    type,
    // game, will add with condition
    groups: dynamicRuleValue,
  };

  if (type === InboxType.GAME_BASED.value) {
    creatingPayload.game = game
  }

  return creatingPayload;
};

export const stepValidation = ({
  formValues, step,
}) => {
  switch (step) {
    case 1: {
      const {
        name, message, hub, module, type, game,
      } = formValues;

      const anyRequiredFieldEmpty = !name || !message || !hub || !module || !type;
      if (anyRequiredFieldEmpty) {
        return false;
      }

      if (type === InboxType.GAME_BASED.value && !game) {
        return false;
      }

      return true;
    }
    case 2: {
      const {
        groups, dynamicGroupStep,
      } = formValues;

      if (
        groups?.length > 0
        && _.isObject(dynamicGroupStep?.groups)
        && groups.every(gId => dynamicGroupStep.groups[gId])
      ) {
        return true;
      }

      return false;
    }
    default:
      console.debug('WARNING: inbox-rule validate nothing!');
      return false;
  }
};

export const formatDynamicRuleFromRecord = record => {
  if (!record.groups?.length) {
    return {};
  }

  const groupsData = record.groups.reduce((groupAcc, curGroupItem, index) => {
    if (!curGroupItem.id) {
      return groupAcc;
    }

    const groupAccCloned = {
      ...groupAcc,
    };

    groupAccCloned[curGroupItem.id] = {
      id: curGroupItem.id,
      name: curGroupItem.name,
      brands: curGroupItem.brands.reduce((brandAcc, curBrandItem) => {
        if (!curBrandItem.id) {
          return brandAcc;
        }

        const brandAccCloned = {
          ...brandAcc,
        };

        brandAccCloned[curBrandItem.id] = {
          id: curBrandItem.id,
          name: curBrandItem.name,
          players: curBrandItem.players?.reduce((pAcc, pItem) => {
            if (!pItem?.id) return pAcc;
            const pAccCloned = {
              ...pAcc,
            };
            pAccCloned[pItem.id] = {
              id: pItem.id,
              nativeId: pItem.nativeId,
            };
            return pAccCloned;
          }, {}),
        };

        return brandAccCloned;
      }, {}),
    };

    return groupAccCloned;
  }, {});

  return {
    groups: groupsData,
  };
};
