import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const MenuField = props => {
  const {
    onClick, record,
  } = props;
  const handleClick = React.useCallback(
    event => {
      onClick(event, record);
    },
    [onClick, record],
  );

  return (
    <IconButton onClick={handleClick}>
      <MoreVertIcon />
    </IconButton>
  );
};

MenuField.propTypes = {
  onClick: PropTypes.func.isRequired,
  record: PropTypes.object,
};

MenuField.defaultProps = {
  record: {},
};

export default MenuField;
