/* eslint-disable no-nested-ternary */
import React from 'react';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import GameFeaturesInput from './components/GameFeaturesInput';
import { GAME_FEATURE_VALUE_TYPES } from '../../constant';

export default function GameFeatureCreate(props) {
  const transformData = values => ({
    code: values.code,
    name: values.name,
    gameFeatures: values.gameFeatures?.map(item => ({
      ...item,
      valueType:
          typeof item?.value === 'number'
            ? GAME_FEATURE_VALUE_TYPES.NUMBER
            : typeof item?.value === 'string'
              ? GAME_FEATURE_VALUE_TYPES.STRING
              : GAME_FEATURE_VALUE_TYPES.BOOLEAN,
      value: !item?.value ? true : item.value,
    })),
  });

  return (
    <WealthCreateGuesser
      transform={transformData}
      excludeFields={['gameFeatures']}
      fieldOrder={['name', 'code', 'gameFeatures']}
      {...props}
    >
      <GameFeaturesInput source="gameFeatures" />
    </WealthCreateGuesser>
  );
}
