/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import { NumberField } from '../../../../base/components/ra/fields';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-rtp.list';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import TranslatableField from '../../../../base/components/ra/fields/translatable.field';
import BaseCurrencyField from '../../../../base/components/reports/ReportTable/BaseCurrencyField';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';

const StandardTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();
  return (
    <CustomReportTable
      title={translate('resources.report.standard')}
      reportType={REPORT_TYPES.STANDARD}
      reportList={LIST_REPORTS}
      resource={resourceSlug.REPORT_RTP}
    >
      <ReferenceField link="show" source="gameId" reference="game" label="resources.game.fields.gameId">
        <TextField source="gameId" />
      </ReferenceField>
      <TranslatableField label="resources.report.fields.gameName" source="gameName" />
      <TextField label="resources.report.fields.gameType" source="gameType" sortable={false} />
      <NumberField label="resources.report.fields.betCount" source="betCount" sortable />
      <NumberField label="resources.report.fields.playerCount" source="playerCount" sortable />
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField link="show" source="currency" reference="currency" label="resources.currency.name" sortable>
        <TextField source="code" />
      </ReferenceField>
      {Boolean(appliedFilters.consolidated) && <BaseCurrencyField label="resources.report.fields.baseCurrency" />}
      <TextField label="resources.bet.fields.channel" source="channel" sortable={false} />
      <TextField label="resources.report.fields.rtp" source="rtp" sortable />
      <TextField label="resources.report.fields.betAmount" source="betAmount" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
    </CustomReportTable>
  );
};

export default StandardTable;
