/* eslint-disable react/prop-types, no-unused-vars */
import React, { useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { CreateButton, sanitizeListRestProps, TopToolbar, useListContext, useTranslate } from 'react-admin';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import CustomTooltip from '../../custom-tooltip';
import { useFilterFormContext } from '../../../context/form/filterForm.context';
import { ExportButton } from '../../ra/buttons';
import { canI, RbacAction } from '../../../../services/provider/rbacProvider';
import exporters from '../../ra/list/exporters';
import ClearCacheButton from '../component/clear-cache-button';

export const useStyle = makeStyles(
  theme => ({
    showFilterBtn: {
      fontSize: '12px',
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'transparent',
      },
    },
  }),
  {
    name: 'TopToolbarCustom',
  },
);

const ListActions = props => {
  const {
    className, ...rest
  } = props;
  const {
    resource, permissions, user,
  } = rest;
  const {
    hasCreate, basePath, exporter: defaultExporter, filterValues, loading,
  } = useListContext();

  const exporter = Object.keys(exporters).includes(resource) ? exporters[resource] : defaultExporter;

  const translate = useTranslate();
  const classes = useStyle();

  const {
    setFilterDisplayed, filterDisplayed,
  } = useFilterFormContext();

  const filterLabel = filterDisplayed ? translate('ra.action.hideFilter') : translate('ra.action.showFilter');

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      setFilterDisplayed(true);
    }
  }, []);

  const {
    resourceId, ...restTopToolbarProps
  } = rest;

  return (
    <TopToolbar
      className={clsx(className, 'hidden-label-filter')}
      {...sanitizeListRestProps(restTopToolbarProps)}
    >
      <ClearCacheButton
        resource={resource}
        user={user}
      />
      <CustomTooltip
        title={filterLabel}
        placement="top"
      >
        <MuiButton
          color="primary"
          variant="text"
          size="small"
          className={classes.showFilterBtn}
          startIcon={filterDisplayed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={() => setFilterDisplayed(!filterDisplayed)}
        >
          {filterLabel}
        </MuiButton>
      </CustomTooltip>

      {hasCreate && (
        <CustomTooltip
          title={translate('ra.action.create')}
          placement="top"
        >
          <CreateButton
            basePath={basePath}
            label=""
          />
        </CustomTooltip>
      )}

      {exporter
        && canI(RbacAction.EXPORT, resource, permissions)
        && canI(RbacAction.LIST, resource, permissions)
        && canI(RbacAction.READ, resource, permissions) && (
        <CustomTooltip
          title={translate('ra.action.export')}
          placement="top"
        >
          <ExportButton
            label=""
            date={filterValues?.created}
            disabled={loading}
          />
        </CustomTooltip>
      )}
    </TopToolbar>
  );
};

export default ListActions;

ListActions.propTypes = {};

ListActions.defaultProps = {};
