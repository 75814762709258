/* eslint-disable no-shadow */
import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { ActionMenu } from './components/action.menu';

const CheatModeShow = props => (
  <WealthShowGuesser
    {...props}
    actionMenu={<ActionMenu type="toolbar" />}
  />
);

export default CheatModeShow;
