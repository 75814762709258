/* eslint-disable */
import { Box, CardContent, Card } from '@material-ui/core';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import ChartLoading from './chart-loading';
import ChartFilterWrapper from './filter-wrapper';
import GGRByCurrencyBarChart from './ggr-by-currency-bar-chart';
import { getDateFilter } from './utils';
import { useQueryParams } from '../../../../base/hooks';
import { CURRENCY_TYPES } from '../../../../constant';
import ChartTitle from './chart-title';
import { FIAT_X_AXIS_FIELD_NAME } from './ggr-fiat-bar-chart';
import { CRYPTO_X_AXIS_FIELD_NAME } from './ggr-crypto-bar-chart';

const ChartWrapper = () => {
  const dataProvider = useDataProvider();

  const [data, setGGRData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const {
    date = DATE_VALUES.TODAY_TILL_NOW,
    type = TYPE_VALUES.CURRENCY,
    utcOffset = UTC_VALUES[0],
  } = useQueryParams();

  const notify = useNotify();

  const handleFetchData = async () => {
    setIsLoading(true);
    let ggrData = {};
    try {
      switch (type) {
        case TYPE_VALUES.CURRENCY:
          const [{ data: fiatData }, { data: cryptoData }] = await Promise.all(
            Object.values(CURRENCY_TYPES).map((currencyType) =>
              dataProvider.getCustomList(`dashboard/gross-gaming-revenue`, {
                filter: {
                  created: getDateFilter(date, utcOffset),
                  q: {
                    type,
                    currencyType,
                  },
                },
                sort: {
                  field: currencyType === CURRENCY_TYPES.fiat ? FIAT_X_AXIS_FIELD_NAME : CRYPTO_X_AXIS_FIELD_NAME,
                  order: 'DESC',
                },
                pagination: {
                  page: null,
                  perPage: null,
                },
              }),
            ),
          );

          ggrData = {
            fiatData,
            cryptoData,
          };
          break;
        default:
          break;
      }

      setGGRData(ggrData);
      setIsLoading(false);
    } catch (error) {
      setGGRData({});
      notify(error?.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [date, type, utcOffset]);

  return (
    <GGRContext.Provider
      value={{
        data,
        query: { utcOffset, date, type },
      }}
    >
      <Box
        component={Card}
        height="100%"
        p={{
          xs: 4,
          sm: 6,
        }}
        sx={{
          "&[class*='Card']": {
            overflow: 'auto',
          },
        }}
      >
        <Box
          component={CardContent}
          height="100%"
          sx={{
            "&[class*='MuiCardContent']": {
              padding: 0,
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              gap: 24,
            }}
            minHeight="100%"
          >
            <Box display="flex" sx={{ flexDirection: { xs: 'column', lg: 'row' }, gap: 24 }}>
              <ChartFilterWrapper />
              {!isLoading && Object.keys(data).length > 0 && (
                <Box margin="auto">
                  <ChartTitle />
                </Box>
              )}
            </Box>
            {isLoading ? <ChartLoading /> : type === TYPE_VALUES.CURRENCY ? <GGRByCurrencyBarChart /> : null}
          </Box>
        </Box>
      </Box>
    </GGRContext.Provider>
  );
};

export const UTC_VALUES = {
  0: '00:00',
  8: '08:00',
};

export const UTC_OPTIONS = [
  { value: UTC_VALUES[0], name: 'UTC +00:00' },
  { value: UTC_VALUES[8], name: 'UTC +08:00' },
];

export const DATE_VALUES = {
  TODAY_TILL_NOW: 'today_till_now',
  YESTERDAY: 'yesterday',
  '3_DAYS_AGO': '3_days_ago',
  '7_DAYS_AGO': '7_days_ago',
  MONTH_TILL_NOW: 'month_till_now',
  PREVIOUS_MONTH: 'previous_month',
  '3_MONTHS_AGO': '3_months_ago',
};

export const DATE_OPTIONS = [
  {
    value: DATE_VALUES.TODAY_TILL_NOW,
    name: 'resources.dashboard.fields.todayTillNow',
  },
  {
    value: DATE_VALUES.YESTERDAY,
    name: 'resources.dashboard.fields.yesterday',
  },
  {
    value: DATE_VALUES['3_DAYS_AGO'],
    name: 'resources.dashboard.fields.3DaysAgo',
  },
  {
    value: DATE_VALUES['7_DAYS_AGO'],
    name: 'resources.dashboard.fields.7DaysAgo',
  },
  {
    value: DATE_VALUES.MONTH_TILL_NOW,
    name: 'resources.dashboard.fields.monthTillNow',
  },
  {
    value: DATE_VALUES.PREVIOUS_MONTH,
    name: 'resources.dashboard.fields.previousMonth',
  },
  {
    value: DATE_VALUES['3_MONTHS_AGO'],
    name: 'resources.dashboard.fields.3MonthsAgo',
  },
];

export const TYPE_VALUES = {
  CURRENCY: 'currency',
};

export const TYPE_OPTIONS = [
  {
    value: TYPE_VALUES.CURRENCY,
    name: 'resources.dashboard.fields.currency',
  },
];

const GGRContext = createContext();

export const useGGRContext = () => useContext(GGRContext);

export default ChartWrapper;
