export const validationFieldsByStep = (step, fieldNames) => {
  if (step === 0) {
    return fieldNames.filter(name => name.startsWith('group'));
  }
  if (step === 1) {
    return fieldNames.filter(name => name.startsWith('brands'));
  }
  return [];
};

export const validateFormValueByStep = (step, form) => {
  const { modified } = form.getState();
  const validationFields = validationFieldsByStep(step, Object.keys(modified));
  const fieldsValid = validationFields.every(
    fieldName => {
      const isValid = form?.getFieldState(fieldName)?.valid;
      return isValid;
    },
  );
  return fieldsValid;
};
