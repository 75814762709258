/* eslint-disable */
import PropTypes from "prop-types";
import { JsonField } from "react-admin-json-view";
import * as React from "react";
import get from "lodash/get";
import set from "lodash/set";
import { TextField } from "react-admin";

const CustomJsonField = (props) => {
  const { record, source } = props;
  let { jsonString } = props;

  const data = get(record, source);

  if (!data) {
    return <TextField label="" />;
  }

  if (
    typeof data === "string" &&
    !data.startsWith("{") &&
    !data.endsWith("}")
  ) {
    set(record, source, JSON.stringify({
      message: data,
    }));
  }

  return <JsonField {...props} jsonString={jsonString} record={record} />;
};

CustomJsonField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
};
CustomJsonField.defaultProps = {
  record: {},
};

export default CustomJsonField;
