import axios from 'axios';
import Cookie from './handleCookie';
import LocalStorage, { FIREBASE_AUTH_TOKEN_KEY, userStorageKey } from '../local-storage';
import { getHook } from './react-hooks-outside';
import { goToLoginPage } from '.';

const informUnAuthorized = options => {
  const notify = getHook('useNotify');
  if (notify) {
    if (options?.hideMessage) {
      return;
    }

    if (options?.message) {
      notify(options.message, options.messageType);
      return;
    }

    notify('ra.message.yourAccountUnAuthorized', 'error');
  }
};

export const cleanUserClue = () => {
  Cookie.removeItem('accessToken');
  Cookie.removeItem('refreshToken');
  LocalStorage.removeItem(userStorageKey);
};

const requestSignOut = async () => {
  try {
    await axios.post('/api/auth/signout');
  } catch {
    // Do nothing!
  } finally {
    cleanUserClue();
  }
};

const cleanFirebaseAuth = () => {
  LocalStorage.removeItem(FIREBASE_AUTH_TOKEN_KEY);
};

export const forceLogout = (options, goToLogin = true) => {
  if (!window.location.hash.endsWith('/login')) {
    requestSignOut();
    cleanFirebaseAuth();
    informUnAuthorized(options);
    if (goToLogin) {
      goToLoginPage();
    }
  }
};
