import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import ChangePasswordForm from './changePassword.form';

function ForceChangePasswordForm(props) {
  const {
    isOpen, onClose, user, userRequireChangePassword,
  } = props;
  const translate = useTranslate();

  const handleClose = () => {
    onClose();
  };

  return (
    <ChangePasswordForm
      title={(
        <span>
          {translate('ra.text.changePasswordTitle', {
            smart_text: `#${user?.username}`,
          })}
        </span>
      )}
      record={user}
      open={isOpen}
      close={handleClose}
      hasCloseButton
      hasLogoutButton={false}
      hasRequireChangePassword={false}
      userRequireChangePassword={userRequireChangePassword}
    />
  );
}

ForceChangePasswordForm.propTypes = {
  isOpen: PropTypes.bool,
  userRequireChangePassword: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object.isRequired,
};

ForceChangePasswordForm.defaultProps = {
  isOpen: false,
  userRequireChangePassword: false,
  onClose: f => f,
};

export default ForceChangePasswordForm;
