/* eslint-disable no-case-declarations */
import { CACHE_WAGER_BY_PAGE, UPDATE_CURRENT_WAGER_PAGE } from '../root.actions';

const initialWagerStates = {
  wagerList: {},
  currentWagerPage: 1,
};

export const wagerReducer = (state = initialWagerStates, action) => {
  switch (action.type) {
    case CACHE_WAGER_BY_PAGE:
      const newPage = action.payload?.page || 1;
      return {
        ...state,
        currentWagerPage: newPage,
        wagerList:
          newPage === 1
            ? {
              1: action.payload?.data,
            }
            : {
              ...state.wagerList,
              [newPage]: action.payload?.data,
            },
      };

    case UPDATE_CURRENT_WAGER_PAGE:
      return {
        ...state,
        currentWagerPage: action.payload,
      };
    default:
      return state;
  }
};
