/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getResources, Loading, useLocale, useTranslate } from 'react-admin';
import { get } from 'lodash';
import { spreadComponents } from '../../../services/util';
import { useSchema } from '../../hooks';
import WealthCreate from './wealth-create';
import WealthInputWrapper from './wealth-input-wrapper';
import WealthInputArrangementProvider from './wealth-input-arrangement-provider';
import { guessProperty } from './wealth-guesser';

const WealthCreateGuesser = props => {
  const resources = useSelector(getResources);
  const [guessedInfo, setGuessedInfo] = useState({});
  const locale = useLocale();
  const translate = useTranslate();

  const {
    api, ref,
  } = useSchema();
  const {
    excludeFields, customFields, fieldOrder, ...rest
  } = props;
  const {
    hasCreate, basePath, resource,
  } = rest;

  const transform = writableFields => data => {
    const fieldsInPayload = [...(writableFields || []), ...customFields];
    if (resource === 'user') {
      fieldsInPayload.push('role');
    }

    if (fieldsInPayload?.length) {
      return fieldsInPayload.reduce((result, field) => {
        result[field] = data?.[field];
        return result;
      }, {});
    }
    return data;
  };
  useEffect(() => {
    if (api) {
      const { paths } = api;

      // Get requestSchema in requestBody that define createOne's DTO
      const requestSchema = get(paths?.[basePath], 'post.requestBody.content.[application/json].schema');

      // If it's not especial - get full fields in Entity, just only get from response's schema
      const responseSchema = get(paths?.[basePath], 'post.responses.201.content[application/json].schema');

      const schema = requestSchema || responseSchema;

      const refPath = schema?.$ref || schema?.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

      const requestComponent = ref.get(refPath);
      const {
        properties, required,
      } = requestComponent;

      const writableFields = Object.keys(properties)
        ?.filter(key => !excludeFields?.includes(key) && !customFields?.includes(key))
        ?.filter(key => {
          const simpleFieldRO = properties?.[key]?.readOnly;
          const complexFieldRO = properties?.[key]?.allOf?.some(p => p.readOnly);

          return !simpleFieldRO && !complexFieldRO;
        });

      const components = writableFields?.map(fieldName => (
        <WealthInputWrapper source={fieldName}>
          {guessProperty({
            source: fieldName,
            properties,
            apiRef: ref,
            view: 'create',
            resource,
            resources,
            required,
            locale,
            translate,
          })}
        </WealthInputWrapper>
      ));

      setGuessedInfo({
        required,
        components,
        writableFields,
      });
    }
  }, [api, basePath, excludeFields?.length, customFields?.length]);

  if (!hasCreate) {
    throw new Error('This resource does not support create');
  }

  const inputComponentList = [...spreadComponents(guessedInfo?.components), ...spreadComponents(rest.children)];

  // To customize the order of fields
  if (Array.isArray(fieldOrder)) {
    inputComponentList.sort((a, b) => fieldOrder.indexOf(a?.prop?.source) - fieldOrder.indexOf(b?.props?.source));
  }

  return api ? (
    <WealthCreate
      required={guessedInfo?.required}
      resource={resource}
      writableFields={guessedInfo?.writableFields}
      {...rest}
      transform={rest.transform || transform(guessedInfo?.writableFields)}
    >
      <WealthInputArrangementProvider
        basePath={basePath}
        fieldOrder={fieldOrder}
      >
        {inputComponentList}
      </WealthInputArrangementProvider>
    </WealthCreate>
  ) : (
    <Loading />
  );
};

WealthCreateGuesser.propTypes = {
  hasCreate: PropTypes.bool,
  basePath: PropTypes.string,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  customFields: PropTypes.arrayOf(PropTypes.string),
  fieldOrder: PropTypes.arrayOf(PropTypes.string),
};

WealthCreateGuesser.defaultProps = {
  hasCreate: false,
  basePath: null,
  excludeFields: [],
  customFields: [],
  fieldOrder: null,
};

export default WealthCreateGuesser;
