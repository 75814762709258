import imageCompression from 'browser-image-compression';

export async function handleCompressImage(imageFile) {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(imageFile, options);
    return await imageCompression.getDataUrlFromFile(compressedFile);
  } catch (error) {
    console.debug('[ERROR] Compress image\n', error);
  }
  return '';
}
