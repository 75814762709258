import React from 'react';
import { useTranslate } from 'react-admin';
import { TextInput } from '../../../../base/components/ra/inputs';
import FormField from '../../../../base/components/reports/ReportFilterWrapper/FormField';

const PrepaidIdInput = () => {
  const translate = useTranslate();
  return (
    <>
      <FormField name="prepaidId||$cont">
        {() => (
          <TextInput
            label={translate('resources.report.fields.prepaidId')}
            source="prepaidId||$cont"
            resettable
          />
        )}
      </FormField>
    </>
  );
};

PrepaidIdInput.propTypes = {};

PrepaidIdInput.defaultProps = {};

export default PrepaidIdInput;
