/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  useListController,
  useNotify,
  useRefresh,
  useTranslate,
  minValue,
} from 'react-admin';
import {
  Button,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Form } from 'react-final-form';
import IconButton from '@material-ui/core/IconButton';
import Axios from 'axios';
import { useError, useSchema } from '../../../../base/hooks';
import { guessProperty } from '../../../../base/components/guesser/wealth-guesser';
import ButtonFilter from '../../../../base/components/guesser/actions/button-filter.action';
import RecallListController from './retrigger';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { getUserAvatar } from '../../../../services/util/stringUtil';
import isEmpty from 'lodash/isEmpty';

const useStyle = makeStyles({
  btn: {
    marginLeft: 8,
    fontWeight: 'medium',
  },
  contentText: {
    color: grey[600],
  },
  profilePic: {
    height: 45,
    width: 45,
    fontSize: 24,
  },
});

const CopyPasswordButton = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [copied, setCopied] = useState(false);
  const copyToClipboard = (e) => {
    setCopied(true);

    navigator.clipboard.writeText(props.value);

    notify(translate('ra.notification.copied'), 'success');
  };

  useEffect(() => {
    let timeoutCopy = '';
    if (copied) {
      timeoutCopy = setTimeout(() => setCopied(false), 400);
    }

    return () => {
      clearTimeout(timeoutCopy);
    };
  }, [copied]);

  return (
    <Button variant="contained" color="primary" onClick={copyToClipboard}>
      {translate('ra.action.copyPassword')}
    </Button>
  );
};

const ActionList = (props) => {
  const { propsController, ...rest } = props;

  const pathRequest = '/user/create-user-and-link-player';
  const [openRecall, setOpenRecall] = useState(false);

  const translate = useTranslate();
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState({});
  const [guessedInfo, setGuessedInfo] = useState({});
  const { notifyError } = useError();
  const notify = useNotify();
  const refresh = useRefresh();

  const { ids: listContextIds, data: listContextData } = useListContext();

  const { api, ref } = useSchema();

  useEffect(() => {
    if (api) {
      const { paths } = api;

      let requestRef = paths?.[pathRequest]?.post?.requestBody?.content?.['application/json']?.schema;
      requestRef = requestRef?.$ref;

      const requestComponent = ref.get(requestRef);
      const { properties, required } = requestComponent;

      const writableFields = Object.keys(properties);

      const components = writableFields?.map((i) => {
        // To add more validators for the balance field
        if (i === 'balance') {
          properties[i].validate = [minValue(1)];
        }
        return guessProperty({
          source: i,
          properties,
          apiRef: ref,
          view: 'create',
          resource: 'user',
          resources: [],
          required,
        });
      });

      setGuessedInfo({
        components,
      });
    }
  }, [api]);

  const handleSubmitForm = async (values) => {
    let success;

    try {
      const dataRes = await Axios.post(`api${pathRequest}`, values);

      const data = dataRes?.data;

      if (data) {
        const { player, user } = data;

        notify(
          translate('ra.message.successfully', {
            smart_name: translate('ra.text.createPlayer'),
          }),
          'success',
        );

        setOpen(false);

        setOpenDialogSuccess({
          player,
          user,
          password: values.password,
          visibility: false,
        });

        setOpenRecall(true);

        success = true;
      }
    } catch (e) {
      notifyError(e);
    }

    return success;
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <ButtonFilter />
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {translate('ra.text.createPlayer')}
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <Form
          initialValues={{
            balance: 10000,
          }}
          onSubmit={async (values, form) => {
            const success = await handleSubmitForm(values);
            if (success) {
              setTimeout(() => {
                form.reset(values);
              }, 100);
            }
          }}
          render={({ handleSubmit, valid, saving, pristine }) => (
            <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">{translate('ra.text.createPlayer')}</DialogTitle>
              <DialogContent>
                <DialogContentText>{guessedInfo?.components}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  color="primary"
                >
                  {translate('ra.action.cancel')}
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  className={classes.btnSubmit}
                  margin="normal"
                  disabled={pristine || saving || !valid}
                >
                  {translate('ra.action.create')}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
      <Dialog
        open={!isEmpty(openDialogSuccess)}
        maxWidth="sm"
        onConfirm={() => {
          setOpen(false);
          setOpenDialogSuccess({});
        }}
      >
        <DialogContent>
          <DialogContentText>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center" mb={4} textAlign="center">
              {translate('ra.text.descAfterCreateUser')}
            </Box>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center" mb={3}>
              <Avatar className={classes.profilePic}>
                {getUserAvatar(openDialogSuccess.user?.displayName, openDialogSuccess.user?.email)}
              </Avatar>
            </Box>
            <Box width="100%" textAlign="center" mb={3}>
              {openDialogSuccess.user?.displayName ||
                openDialogSuccess.user?.username?.split(`${openDialogSuccess.user?.group?.code}_`)?.[1]}
            </Box>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <Box width="70%">
                <div style={{ fontSize: '16px' }}>
                  {translate('resources.user.fields.username')}:{' '}
                  {openDialogSuccess.user?.username?.split(`${openDialogSuccess.user?.group?.code}_`)?.[1]}{' '}
                </div>
                <div style={{ fontSize: '16px' }}>
                  {translate('resources.user.fields.group')}: {openDialogSuccess.user?.group?.code}{' '}
                </div>
                <div style={{ fontSize: '16px', marginTop: '-10px' }}>
                  {translate('resources.user.fields.password')}:{' '}
                  {openDialogSuccess.visibility
                    ? openDialogSuccess.password
                    : openDialogSuccess.password?.replace(/[\w\W\b]/g, '*')}
                  <IconButton
                    onClick={() => {
                      setOpenDialogSuccess({
                        ...openDialogSuccess,
                        visibility: !openDialogSuccess.visibility,
                      });
                    }}
                  >
                    {openDialogSuccess?.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </div>
              </Box>
            </Box>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <CopyPasswordButton value={openDialogSuccess.password} />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenDialogSuccess({});
            }}
          >
            {translate('ra.action.close')}
          </Button>
        </DialogActions>
      </Dialog>
      {openRecall && (
        <RecallListController
          propsController={propsController}
          setOpenRecall={setOpenRecall}
          listContextIds={listContextIds}
          open={openRecall}
          listContextData={listContextData}
        />
      )}
    </TopToolbar>
  );
};

ActionList.propTypes = {};

export default ActionList;
