/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import cls from 'classnames';
import isObject from 'lodash/isObject';

const useStyles = makeStyles(() => ({
  stepContainer: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    opacity: 0,
  },
  currentStep: {
    width: '100%',
    height: 'auto',
    opacity: 1,
    overflow: 'visible',
  },
}));

/**
 * @example
 *
 * <SlickContainer>
 *   <SlickPanelContent name="step-1">
 *     <YourComponentDisplayOnStep1 />
 *   </SlickPanelContent>
 *   <SlickPanelContent name="step-2">
 *     <YourComponentDisplayOnStep2 />
 *   </SlickPanelContent>
 * </SlickContainer>
 */
const SlickContainer = React.forwardRef((props, ref) => {
  const {
    children,
    initStep,
    beforeChange,
  } = props;

  const [allStep] = useState(() => React.Children.map(children, stepEl => stepEl.props.name));
  const [currentStep, setCurrentStep] = useState(initStep || 0);

  const classes = useStyles();

  const slickGoTo = useCallback(value => {
    // eslint-disable-next-line no-restricted-globals
    if (value < 0 || value > allStep.length || isNaN(value)) {
      return;
    }

    if (typeof beforeChange === 'function') {
      beforeChange(value);
    }
    setCurrentStep(value);
  }, [currentStep, allStep]);

  useEffect(() => {
    if (!isObject(ref?.current)) {
      // eslint-disable-next-line no-param-reassign
      ref.current = {};
    }

    if (ref.current) {
      // eslint-disable-next-line no-param-reassign
      ref.current.slickGoTo = slickGoTo;
    }
  }, [slickGoTo]);

  return (
    React.Children.map(children, (stepEl, stepIndex) => {
      const shouldRenderContent = currentStep === stepIndex;

      return (
        <Box
          key={stepEl.props.name}
          className={cls(classes.stepContainer, {
            [classes.currentStep]: currentStep === stepIndex,
          })}
        >
          {shouldRenderContent && React.cloneElement(stepEl, {})}
        </Box>
      );
    })
  );
});

export default SlickContainer;
