/* eslint-disable import/no-cycle */
import { Box, alpha, makeStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { forceLogout } from '../../../../services/util/handleByHttpCode';
import CmtAvatar from '../../../../theme/@coremat/CmtAvatar';
import CmtDropdownMenu from '../../../../theme/@coremat/CmtDropdownMenu';
import { useAuthUser } from '../../../hooks';
import ChangePasswordForm from '../../change-password/owner-change-password';
import { setAuthInfo } from '../../../../services/redux/auth/auth.action';

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.up('sm')]: {
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 2,
        zIndex: 1,
        height: 35,
        width: 1,
        backgroundColor: alpha(theme.palette.common.dark, 0.15),
      },
    },
  },
}));

const UserDropDown = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useAuthUser();
  const dispatch = useDispatch();

  const [openModalPassword, setOpenModalPassword] = useState(false);
  const actionsList = [
    {
      icon: <PersonIcon />,
      label: 'ra.text.profile',
      id: 'profile',
      needTranslate: true,
    },
    {
      icon: <VpnKeyIcon />,
      label: 'ra.text.changePassword',
      id: 'changePassword',
      needTranslate: true,
    },
    {
      icon: <ExitToAppIcon />,
      label: 'ra.text.logout',
      id: 'logout',
      needTranslate: true,
    },
  ];

  const onItemClick = item => {
    switch (item.id) {
      case 'profile':
        history.push('/profile');
        break;
      case 'changePassword':
        setOpenModalPassword(true);
        break;
      case 'logout': {
        dispatch(setAuthInfo({}));
        forceLogout({
          hideMessage: true,
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <ChangePasswordForm
        record={user}
        open={openModalPassword}
        close={() => setOpenModalPassword()}
      />
      <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
        <CmtDropdownMenu
          onItemClick={onItemClick}
          TriggerComponent={(
            <CmtAvatar
              size="small"
              src={user.photoURL}
              alt={user.displayName || user.email || 'A'}
              color="secondary"
            />
          )}
          items={actionsList}
          record={user}
        />
      </Box>
    </>
  );
};

export default UserDropDown;
