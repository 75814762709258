/* eslint-disable  */
import React, { useEffect } from "react";
import { useListController } from "react-admin";
import { difference, isEqual, get } from "lodash";
import { useReloadContext } from "../../../../base/context/reload/reload.context";

const RecallListController = ({
  propsController,
  listContextIds,
  setOpenRecall,
  listContextData,
  open,
}) => {
  const listControllerContext = useListController(propsController);
  const { setValuesContext } = useReloadContext();

  useEffect(() => {
    const payload = {
      ids: Object.keys(listControllerContext.data),
      data: listControllerContext.data,
    };
    const dirrerenceIds = difference(payload.ids, listContextIds);

    if (dirrerenceIds?.length) {
      setValuesContext(listControllerContext);
      setOpenRecall((value) => false);
    } else {
      const isChanged = Object.keys(listControllerContext.data).some((key) => {
        const oldBalacne = get(
          listContextData,
          `${key}.players[0].wallet.balance`
        );
        const newBalance = get(
          payload.data,
          `${key}.players[0].wallet.balance`
        );
        if (oldBalacne !== newBalance) {
          return true;
        }
        return false;
      });

      if (isChanged) {
        setValuesContext(listControllerContext);
        setOpenRecall((value) => false);
      }
    }
  }, [open, listControllerContext, listContextData]);

  return null;
};

export default RecallListController;
