import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, TopToolbar } from 'react-admin';
import clsx from 'clsx';

const CustomizedListActionWrapper = ({
  children, className, ...otherProps
}) => (
  <TopToolbar
    className={clsx(className, 'hidden-label-filter')}
    {...sanitizeListRestProps(otherProps)}
  >
    {children}
  </TopToolbar>
);

export default CustomizedListActionWrapper;

CustomizedListActionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CustomizedListActionWrapper.defaultProps = {
  className: '',
};
