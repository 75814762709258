/* eslint-disable react/prop-types */
import { Box, Paper, TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { Search } from '@material-ui/icons';
import { REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';

const useStyles = makeStyles(() => ({
  root: {
    width: 500,
  },
  popupIndicatorOpen: {
    transform: 'rotate(0deg)',
  },
}));

const ReverseIntegrationLayout = ({
  children, containerProps, searchBarProps,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const t = useTranslate();
  return (
    <Box
      component={Paper}
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
      }}
      p={{
        xs: 4,
        sm: 6,
      }}
      {...containerProps}
    >
      <Autocomplete
        blurOnSelect
        classes={styles}
        size="small"
        popupIcon={<Search />}
        disableClearable
        options={Object.values(REVERSE_INTEGRATION_OPERATORS)}
        getOptionLabel={option => option.name}
        onChange={(e, value) => {
          history.push(value.mainResource);
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={t('common.fields.operator')}
          />
        )}
        {...searchBarProps}
      />
      {children}
    </Box>
  );
};

export default ReverseIntegrationLayout;
