import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { JsonField } from '../../../base/components/ra/fields';
import resourceSlug from '../../../constant/resource-slug';
import Labeled from '../../../base/components/ra/labeled';

const ParentBetField = props => {
  const translate = useTranslate();
  const history = useHistory();

  const handleClickOnId = useCallback((json = {}) => {
    const {
      name, value,
    } = json;
    if (name !== 'id') return;

    history.push(`/${resourceSlug.BET}/${value}/show`);
  }, []);

  return (
    <Labeled label={translate(`resources.${resourceSlug.PLAYER_STATE_MANAGEMENT}.fields.parentBet`)}>
      <JsonField
        source="parentBet"
        addLabel
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: true,
          displayDataTypes: false,
          onSelect: handleClickOnId,
        }}
        {...props}
      />
    </Labeled>
  );
};

export default ParentBetField;
