/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Type from 'prop-types';
import { sanitizeListRestProps, useListContext } from 'ra-core';
import { Box } from '@material-ui/core';
import { TopToolbar } from 'ra-ui-materialui';
import ButtonFilter from '../../../base/components/guesser/actions/button-filter.action';
import ButtonUnlock from './ButtonUnlock';
import DeleteFirebaseSessions from './DeleteFirebaseSessions';
import { getQueryUrl } from '../../../services/provider/dataProvider';
import { useReloadContext } from '../../../base/context/reload/reload.context';

export const ActionList = props => {
  const { setValuesContext } = useReloadContext();

  useEffect(
    () => () => {
      setValuesContext({});
    },
    [],
  );

  const { data } = props;

  const disabled = Object.keys(data || {}).length === 0;

  const {
    filter,
    filterValues: raFilterValues = {},
    loading: listLoading,
  } = useListContext(props);

  useEffect(() => {
    if (!listLoading) {
      setValuesContext({});
    }
  }, [listLoading]);

  const { query: queryString } = getQueryUrl('player', {
    filter: filter
      ? {
        ...raFilterValues,
        ...filter,
      }
      : raFilterValues,
  });

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <Box>
        <ButtonFilter />
        <ButtonUnlock
          disabled={disabled}
          queryString={queryString}
          isUnlockAll
        />
        <DeleteFirebaseSessions />
      </Box>
    </TopToolbar>
  );
};

ActionList.propTypes = {
  filters: Type.node.isRequired,
};
