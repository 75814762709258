/* eslint-disable */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import inflection from "inflection";
import {
  useTranslate,
  Record,
  RedirectionSideEffect,
  useDeleteWithConfirmController,
  OnSuccess,
  OnFailure,
  useResourceContext,
} from "ra-core";
import Confirm from "../layout/Confirm";
import Button from "./Button";

const DeleteWithConfirmButton = (props) => {
  const {
    basePath,
    classes: classesOverride,
    className,
    confirmTitle = "ra.message.delete_title",
    confirmContent = "ra.message.delete_content",
    icon = defaultIcon,
    label = "ra.action.delete",
    onClick,
    record,
    redirect = "list",
    onSuccess,
    onFailure,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    record,
    redirect,
    basePath,
    onClick,
    onSuccess,
    onFailure,
  });
  const resource = useResourceContext(props);

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames(
          "ra-delete-button",
          classes.deleteButton,
          className
        )}
        key="button"
        {...rest}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  );
};

const defaultIcon = <ActionDelete />;

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: alpha(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }),
  {
    name: "RaDeleteWithConfirmButton",
  }
);

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};

export default DeleteWithConfirmButton;
