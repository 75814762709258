/* eslint-disable react/require-default-props, react/prop-types, no-restricted-globals */
import React, { useEffect } from 'react';
import { makeStyles, Box, TextField as MuiTextField } from '@material-ui/core';
import {
  sanitizeInputRestProps,
  InputHelperText,
  useTranslate,
} from 'react-admin';
import { useInput, FieldTitle } from 'ra-core';
import { withStandardInputProps } from '../../../hoc/with-standard-input-props';

const TextField = withStandardInputProps(MuiTextField);

const stringifyObject = value => {
  if (typeof value === 'object') {
    if ('min' in value || 'max' in value) {
      return `[${value.min}, ${value.max}]`;
    }
    return JSON.stringify(value);
  }
  return value;
};

const parseToObject = value => {
  let result = null;

  if (
    Array.isArray(value)
    && !isNaN(+value[0])
    && !isNaN(+value[1])
    && value.length > 0
    && value.length <= 2
  ) {
    const min = value?.[0] ?? '';
    const max = value?.[1] ?? '';
    return {
      min,
      max,
    };
  }

  try {
    if (typeof value === 'string') {
      result = JSON.parse(value);

      if (
        Array.isArray(result)
        && !isNaN(+result[0])
        && !isNaN(+result[1])
        && result.length > 0
        && result.length <= 2
      ) {
        const listStringValue = value
          .replace(/\[|\]/g, '')
          .replace(/\s{1,}/g, '')
          .split(',');
        const min = listStringValue?.[0] ?? '';
        const max = listStringValue?.[1] ?? '';
        return {
          min,
          max,
        };
      }

      return value;
    }
  } catch {
    // Do nothing!
  }

  return result || value;
};

const useStyle = makeStyles(() => ({
  wealthMinMaxInput: {
    '& textarea': {
      width: '100%',
      resize: 'none',
    },
  },
}));

const MinMaxBoundInput = ({
  initValue,
  assignValueResetting,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = parseToObject,
  format = stringifyObject,
  resource,
  source,
  validate,
  variant,
  inputProps,
  ...rest
}) => {
  const classes = useStyle();
  const translate = useTranslate();

  // minMaxValidate return `undefined` being value valid
  // if not this value invalid
  const minMaxValidate = inputValue => {
    if (
      typeof inputValue === 'object'
      && !isNaN(+inputValue.min)
      && !isNaN(+inputValue.max)
    ) {
      if (+inputValue.min > +inputValue.max) {
        return translate(
          'wa.exception.shouldNotLessThan',
          {
            sourceField: translate('ra.field.max'),
            destField: translate('ra.field.min'),
          },
        );
      }
      return undefined;
    }

    return translate('wa.exception.invalidMinMaxValue');
  };

  const newValidate = validate || [];
  newValidate.push(minMaxValidate);

  const {
    id,
    input,
    isRequired,
    meta: {
      error, submitError, touched,
    },
  } = useInput({
    onBlur,
    onChange,
    onFocus,
    parse,
    format,
    resource,
    source,
    validate: newValidate,
    ...rest,
  });

  const resetValue = value => {
    if (value != null) {
      input.onChange(value);
    }
  };

  useEffect(() => {
    if (
      Array.isArray(initValue)
      && initValue.length === 2
      && initValue.every(item => !isNaN(item))
    ) {
      input.onChange(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    if (typeof assignValueResetting === 'function') {
      assignValueResetting(resetValue);
    }
  }, [assignValueResetting]);

  return (
    <Box className={classes.wealthMinMaxInput}>
      <TextField
        id={id}
        {...input}
        variant={variant}
        error={!!(touched && (error || submitError))}
        helperText={(
          <InputHelperText
            touched={touched}
            error={error || submitError}
            helperText={helperText}
          />
        )}
        label={(
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )}
        margin={margin}
        inputProps={inputProps}
        {...options}
        {...sanitizeInputRestProps(rest)}
      />
    </Box>
  );
};

MinMaxBoundInput.propTypes = {};

MinMaxBoundInput.defaultProps = {};

export default MinMaxBoundInput;
