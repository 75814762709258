/* eslint-disable */
import { Box, Button as MuiButton, alpha } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { Notification, useLogin, useTranslate, useSetLocale, useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import ReactHelmet from 'react-helmet';
import { getRawTheme } from '../../../custom-theme/themeConfig';
import CmtImage from '../../../theme/@coremat/CmtImage';
import AuthWrapper from '../../../theme/@jumbo/components/Common/authComponents/AuthWrapper';
import ContentLoader from '../../../theme/@jumbo/components/ContentLoader';
import { withStandardButtonProps } from '../../hoc/with-standard-button-props';
import { PasswordInput, TextInput } from '../ra/inputs';
import { getBackofficeEnv } from '../../../services/util';
import { appBrandConfigMapping } from '../../../constant/appBrandMapping';

const Button = withStandardButtonProps(MuiButton);

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  authContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: (props) => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
      padding: 30,
      width: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.black, 0.12),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  btnLogin: {
    backgroundColor: ({ defaultTheme }) => defaultTheme.palette.primary.main,
  },
  logo: {
    textAlign: 'center',
  },
}));

const LoginPage = ({ variant = 'default', wrapperVariant = 'default' }) => {
  const { APP_THEME, APP_BRAND } = getBackofficeEnv();
  const { companyName, loginPageLogo } = appBrandConfigMapping[APP_BRAND];

  const setLocale = useSetLocale();
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles({
    variant,
    defaultTheme: getRawTheme(APP_THEME),
  });

  const onSubmit = (values) => {
    login({ ...values }).catch(() => {
      // Error message is handled at the axios interceptor
    });
  };

  const handleValidation = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  useEffect(() => {
    setLocale('en'); // need to set locale to get translations from server
  }, []);

  return (
    <AuthWrapper variant={wrapperVariant}>
      <ReactHelmet>
        <title>{`${companyName} | Back office`}</title>
      </ReactHelmet>
      {variant === 'default' ? (
        <Box className={classes.authThumb} flex="1">
          <CmtImage src={loginPageLogo} style={{ width: '90%', maxWidth: '165px' }} />
        </Box>
      ) : null}
      <Box className={classes.authContent} flex="1">
        <Box className={classes.logo}>
          <CmtImage height={40} src={loginPageLogo} />
        </Box>
        <Typography component="div" variant="h5" className={classes.titleRoot}>
          {translate('ra.page.login')}
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={handleValidation}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="username" component={TextInput}>
                {({ input, meta }) => (
                  <TextInput
                    {...input}
                    label={translate('ra.auth.username')}
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    error={!!(meta.touched && meta.error)}
                  />
                )}
              </Field>
              <Field name="password" component={PasswordInput}>
                {({ input, meta }) => (
                  <PasswordInput
                    {...input}
                    label={translate('ra.auth.password')}
                    type="password"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    error={!!(meta.touched && meta.error)}
                  />
                )}
              </Field>
              <Field name="groupCode" component={TextInput}>
                {({ input, meta }) => (
                  <TextInput
                    {...input}
                    label="Group Code"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    error={!!(meta.touched && meta.error)}
                  />
                )}
              </Field>
              <Box mt={2.5}>
                <Button variant="contained" color="primary" type="submit" fullWidth className={classes.btnLogin}>
                  {translate('ra.auth.sign_in')}
                </Button>
              </Box>
            </form>
          )}
        />
        <ContentLoader />
      </Box>
      <Notification />
    </AuthWrapper>
  );
};

export default LoginPage;
