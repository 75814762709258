/* eslint react/prop-types: 0 */
import React, { memo, useEffect, useRef } from 'react';
import { required } from 'react-admin';
import { useForm } from 'react-final-form';
import {
  ReferenceInput,
  AutocompleteInput,
} from '../../../base/components/ra/inputs';
import { useApiProperties } from '../../../base/hooks';
import { RoleAccessibility } from '../../../services/util';
import { useUserFormContext } from '../context/user.context';

const CustomGroupInput = props => {
  const { roleSelected } = useUserFormContext();
  const refGroupId = useRef();
  const form = useForm();

  const { getPropertiesByFieldName } = useApiProperties();
  const properties = getPropertiesByFieldName('group');

  const optionText = properties.optionText || 'name';

  useEffect(() => {
    const isCustomRole = roleSelected?.accessibility === RoleAccessibility.CUSTOM_ROLE
      && roleSelected?.group?.id;

    if (isCustomRole) {
      form.change('group', {
        id: roleSelected?.group?.id,
      });

      if (refGroupId.current !== roleSelected?.group?.id) {
        form.change('brands', null);
      }
    }

    refGroupId.current = roleSelected?.group?.id || form.getState().values.group?.id;
  }, [roleSelected, form.getState().values.group]);

  const isCustomRole = roleSelected?.accessibility === RoleAccessibility.CUSTOM_ROLE
    && roleSelected?.group?.id;

  const defaultFilter = {
    enabled: true,
  };

  if (isCustomRole) {
    defaultFilter.id = roleSelected.group.id;
  }

  return (
    <ReferenceInput
      {...props}
      label="resources.role.fields.group"
      source="group.id"
      reference="group"
      optionText={optionText}
      validate={required()}
      fullWidth
      filter={defaultFilter}
      disabled={isCustomRole}
      perPage={properties.choiceLimit || 100}
      sort={{
        field: properties.choiceSort?.field || optionText,
        order: properties.choiceSort?.order || 'ASC',
      }}
      onChange={() => {
        form.change('brands', null);
      }}
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  );
};

export default memo(CustomGroupInput);
