import React from 'react';
import useNavigation from '../../../../../../base/hooks/useNavigation';
import { PageBreadcrumbs, PageHeader as DefaultPageHeader } from '../../../PageComponents';

const PageHeader = () => {
  const { breadcrumbs, pageTitleInfo } = useNavigation();

  return (
    <DefaultPageHeader
      pageTitleInfo={pageTitleInfo}
      breadcrumbComponent={breadcrumbs && <PageBreadcrumbs items={breadcrumbs} />}
    />
  );
};

export default PageHeader;
