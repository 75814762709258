import OperatorShow from './operator.show';
import OperatorCreate from './operator.create';
import OperatorListPage from './operator.list';
import OperatorEdit from './operator.edit';

export default {
  list: OperatorListPage,
  edit: OperatorEdit,
  create: OperatorCreate,
  show: OperatorShow,
};
