/* eslint-disable no-sparse-arrays, react/no-array-index-key, react/prop-types */
import React from 'react';
import {
  Box,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  withStyles,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useLocale, useTranslate } from 'react-admin';
import get from 'lodash/get';
import keys from 'lodash/keys';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import isNaN from 'lodash/isNaN';
import kebabCase from 'lodash/kebabCase';
import isObject from 'lodash/isObject';
import {
  calculateTotalBet,
  generateTotalBetMap,
} from '../utils';
import {
  QuickSetupErrorHandlingConsumer,
  QuickSetupFieldValidationWrap,
} from '../utils/QuickSetupErrorHandling';
import { WalletImplementationEnum } from '../../../../constant';

export const fieldBgColor = '#F0F1F2';

export const especialField = {
  currencies: {
    ref: 'currency',
    type: 'array',
    source: 'name',
    label: 'resources.brand.fields.currencies',
  },
  languages: {
    ref: 'language',
    type: 'array',
    source: 'name',
    label: 'resources.brand.fields.languages',
  },
  defaultLanguage: {
    ref: 'language',
    type: 'text',
    source: 'name',
    label: 'resources.brand.fields.defaultLanguage',
  },
  games: {
    ref: 'game',
    type: 'array',
    source: 'name.en',
    label: 'resources.available-game.fields.games',
  },
  payoutConfig: {
    type: 'property-collection',
    source: 'payoutConfig',
    label: 'resources.wallet-integration.fields.payoutConfig',
  },
  placebetConfig: {
    type: 'property-collection',
    source: 'placebetConfig',
    label: 'resources.wallet-integration.fields.placebetConfig',
  },
  cancelbetConfig: {
    type: 'property-collection',
    source: 'cancelbetConfig',
    label: 'resources.wallet-integration.fields.cancelbetConfig',
  },
};

export const arrayFields = keys(especialField).filter(key => especialField[key].type === 'array');

export const PropertyCollectionField = props => {
  const {
    record, source,
  } = props;

  const fieldData = get(record, source);

  if (!isObject(fieldData)) {
    return null;
  }

  return (
    <Box>
      {Object.keys(fieldData).map(key => (
        <Box
          key={key}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <input
            type="checkbox"
            id={`${source}.${key}`}
            name={`${source}.${key}`}
            checked={!!fieldData[key]}
          />
          <label htmlFor={`${source}.${key}`}>{key}</label>
        </Box>
      ))}
    </Box>
  );
};

export const Title = withStyles({
  root: {
    fontSize: '24px',
    fontWeight: '700',
    marginBottom: '15px',
  },
})(Typography);

export const ErrorIcon = ({
  error, errorColor, locale,
}) => (
  <Tooltip
    arrow
    placement="top"
    title={get(error, ['translation', locale])}
  >
    <IconButton>
      <InfoIcon
        style={{
          color: errorColor,
        }}
      />
    </IconButton>
  </Tooltip>
);

export const FieldWrapper = withStyles({
  root: {
    background: fieldBgColor,
    padding: '10px 16px',
    wordBreak: 'break-all',
    borderRadius: '4px',
  },
})(({
  children, fullWidth, borderColor, error, ...rest
}) => {
  const style = get(rest, 'style', {});
  const locale = useLocale();
  const muiTheme = useTheme();
  return (
    <Box
      {...rest}
      style={{
        position: 'relative',
        width: fullWidth ? undefined : '330px',
        border: `2px solid ${fieldBgColor}`,
        borderColor: borderColor || fieldBgColor,
        ...style,
      }}
    >
      {children}
      {error && get(error, ['translation', locale]) && (
        <Box
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <ErrorIcon
            error={error}
            errorColor={muiTheme.palette.error.main}
            locale={locale}
          />
        </Box>
      )}
    </Box>
  );
});

export const getFieldName = name => kebabCase(name).split('-').map(item => capitalize(item)).join(' ');

export const FieldName = withStyles({})(({
  children, color: colorCustom, error, ...rest
}) => {
  const style = get(rest, 'style', {});
  const locale = useLocale();
  const muiTheme = useTheme();
  return (
    <Box
      {...rest}
      style={{
        color: colorCustom || muiTheme.palette.primary.subText,
        ...style,
      }}
    >
      {children}

      {error && get(error, ['translation', locale]) && (
        <ErrorIcon
          error={error}
          errorColor={muiTheme.palette.error.main}
          locale={locale}
        />
      )}
    </Box>
  );
});

export const FieldValue = withStyles({
  root: {
    fontWeight: 700,
    color: 'currentColor',
    width: '100%',
    border: '0',
    margin: '0',
    display: 'block',
    padding: '4px 0 2px',
    background: 'none',
    boxSizing: 'content-box',
    fontFamily: '"Open Sans", sans-serif',
    verticalAlign: 'middle',
  },
})(({
  children, color, ...rest
}) => {
  const style = get(rest, 'style', {});
  return (
    <Box
      {...rest}
      style={{
        color,
        ...style,
      }}
    >
      {children}
    </Box>
  );
});

export const ArrayField = ({
  listItem, source,
}) => listItem.map((item, index) => (
  <Chip
    key={index}
    style={{
      marginRight: '15px',
      marginBottom: '15px',
    }}
    label={get(item, source)}
  />
));

export const renderEspecialField = ({
  data, key, reference, record,
}) => {
  if (!isObject(data)) {
    return data?.toString ? data.toString() : data;
  }

  const {
    source, ref, type,
  } = especialField[key] || {};

  if (type === 'text' && source) {
    if (ref && reference && reference[ref]) {
      return get(reference, [ref, data.id, source]);
    }
    return get(data, source);
  }

  if (type === 'array' && source && Array.isArray(data)) {
    let listItem = [];
    if (ref && reference && reference[ref]) {
      listItem = data.map(item => get(reference, [ref, item.id]));
    }
    return (
      <ArrayField
        key={source}
        listItem={listItem}
        source={source}
      />
    );
  }

  if (type === 'property-collection' && source) {
    return (
      <PropertyCollectionField
        key={source}
        record={record}
        source={source}
      />
    );
  }

  return get(data, key);
};

export function BrandInformationBlock(props) {
  const {
    brandIndex,
    data: brandData,
    reference,
  } = props;
  const muiTheme = useTheme();
  const translate = useTranslate();

  const extraFieldsBySeamless = brandData.implementation === WalletImplementationEnum.SEAMLESS ? {
    // extra URLs
    payOutUrl: brandData.payOutUrl || '',
    placeBetUrl: brandData.placeBetUrl || '',
    betStatusUrl: brandData.betStatusUrl || '',
    cancelBetUrl: brandData.cancelBetUrl || '',
    createPlayerUrl: brandData.createPlayerUrl || '',
    editBalanceUrl: brandData.editBalanceUrl || '',
    // configs
    payoutConfig: brandData.payoutConfig,
    placebetConfig: brandData.placebetConfig,
    cancelbetConfig: brandData.cancelbetConfig,
  } : [];

  const generalFields = {
    name: brandData.name,
    desc: brandData.desc,
    enabled: brandData.enabled,
    domain: brandData.domain,
    defaultLanguage: brandData.defaultLanguage,
    implementation: brandData.implementation,
    authorizeUrl: brandData.authorizeUrl || '',
    getBalanceUrl: brandData.getBalanceUrl || '',
    ...extraFieldsBySeamless,
  };

  const generalFieldsElement = keys(generalFields).map(generalItemKey => (
    <QuickSetupFieldValidationWrap
      key={generalItemKey}
      name={`brands.${brandIndex}.${generalItemKey}`}
    >
      <QuickSetupErrorHandlingConsumer>
        {({
          quickSetupError, name,
        }) => {
          const brandGeneralFieldErr = get(quickSetupError, name);
          return (
            <FieldWrapper
              key={generalItemKey}
              borderColor={brandGeneralFieldErr ? muiTheme.palette.error.main : fieldBgColor}
              error={brandGeneralFieldErr}
            >
              <FieldName
                color={brandGeneralFieldErr ? muiTheme.palette.error.main : undefined}
              >
                {translate(`resources.brand.fields.${generalItemKey}`)}
              </FieldName>
              <FieldValue
                color={brandGeneralFieldErr ? muiTheme.palette.error.main : undefined}
              >
                {renderEspecialField({
                  key: generalItemKey,
                  record: generalFields,
                  data: generalFields[generalItemKey],
                  reference,
                })}
              </FieldValue>
            </FieldWrapper>
          );
        }}
      </QuickSetupErrorHandlingConsumer>
    </QuickSetupFieldValidationWrap>
  ));

  const fieldsWithoutBetSetting = omit(brandData, ['betSettings', 'betSettingConfigStatus']);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          paddingBottom: '0px 0px 20px',
        }}
      >
        {generalFieldsElement}
      </Box>

      {/* Render Array Fields */}
      {keys(fieldsWithoutBetSetting)
        .filter(key => arrayFields.includes(key))
        .map(key => (
          <QuickSetupFieldValidationWrap
            key={key}
            name={`brands.${brandIndex}.${key}`}
          >
            <QuickSetupErrorHandlingConsumer>
              {({
                quickSetupError, name,
              }) => {
                const arrayFieldError = get(quickSetupError, name);
                return (
                  <FieldWrapper
                    fullWidth
                    borderColor={arrayFieldError ? muiTheme.palette.error.main : fieldBgColor}
                    style={{
                      marginTop: '20px',
                    }}
                    error={arrayFieldError}
                  >
                    <FieldName
                      color={arrayFieldError ? muiTheme.palette.error.main : undefined}
                    >
                      {/* {getFieldName(key)} */}
                      {translate(especialField[key]?.label || getFieldName(key))}
                    </FieldName>
                    <FieldValue>
                      {renderEspecialField({
                        key,
                        data: brandData[key],
                        reference,
                      })}
                    </FieldValue>
                  </FieldWrapper>
                );
              }}
            </QuickSetupErrorHandlingConsumer>
          </QuickSetupFieldValidationWrap>
        ))}

      <QuickSetupFieldValidationWrap
        name={`brands.${brandIndex}.whitelist`}
      >
        <QuickSetupErrorHandlingConsumer>
          {({
            quickSetupError, name,
          }) => {
            const arrayFieldError = get(quickSetupError, name);
            return (
              <FieldWrapper
                fullWidth
                borderColor={arrayFieldError ? muiTheme.palette.error.main : fieldBgColor}
                style={{
                  marginTop: '20px',
                }}
                error={arrayFieldError}
              >
                <FieldName
                  color={arrayFieldError ? muiTheme.palette.error.main : undefined}
                >
                  {translate('resources.white-list.name')}
                </FieldName>
                <FieldValue>
                  {isObject(brandData?.whitelist) ? (
                    <>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Box>
                          {translate('resources.white-list.fields.name')}
                          :
                        </Box>
                        <Box fontWeight={500}>{brandData?.whitelist?.name}</Box>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Box>
                          {translate('resources.white-list.fields.ips')}
                          :
                        </Box>
                        <Box
                          fontWeight={500}
                          note
                          dangerouslySetInnerHTML={{
                            __html: JSON.stringify(brandData?.whitelist?.ips, null, 2),
                          }}
                        />
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Box>
                          {translate('resources.white-list.fields.notes')}
                          :
                        </Box>
                        <Box fontWeight={500}>{brandData?.whitelist?.note}</Box>
                      </Box>
                    </>
                  ) : (
                    <Box height="20px" />
                  )}
                </FieldValue>
              </FieldWrapper>
            );
          }}
        </QuickSetupErrorHandlingConsumer>
      </QuickSetupFieldValidationWrap>

      {/* Render Bet Setting Empty */}
      {!keys(brandData?.betSettings).length && (
        <QuickSetupFieldValidationWrap
          name={`brands.${brandIndex}.betSettings`}
        >
          <QuickSetupErrorHandlingConsumer>
            {({
              quickSetupError, name,
            }) => {
              const betSettingErrorKey = keys(quickSetupError).find(errKey => errKey.startsWith(name));
              const betSettingError = get(quickSetupError, betSettingErrorKey);
              return (
                <FieldWrapper
                  fullWidth
                  borderColor={betSettingError ? muiTheme.palette.error.main : fieldBgColor}
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <FieldName
                    color={betSettingError ? muiTheme.palette.error.main : undefined}
                    error={betSettingError}
                  >
                    {translate('ra.text.betSettingCustom')}
                  </FieldName>
                  <FieldValue>
                    <Box height="32px" />
                  </FieldValue>
                </FieldWrapper>
              );
            }}
          </QuickSetupErrorHandlingConsumer>
        </QuickSetupFieldValidationWrap>
      )}

      {/* Render Bet Setting List */}
      {keys(brandData?.betSettings).map((betStKey, betStIndex) => {
        const {
          game,
          currency,
          totalBetLimit,
          betSize,
          betLevel,
          betSizeDefault,
          betLevelDefault,
        } = brandData.betSettings[betStKey];

        const gameData = reference.game[game.id];
        const baseBet = gameData?.gameConfig?.baseBet;
        const currencyData = reference.currency[currency.id];
        const totalBet = generateTotalBetMap({
          betSize,
          betLevel,
          baseBet: [{
            value: baseBet,
          }],
        });

        const BetSettingNumberItem = ({
          label, isDefault, style, ...rest
        }) => {
          if (isNaN(label)) {
            return null;
          }

          const numberComp = (
            <Box
              style={{
                padding: '2px 8px',
                background: isDefault ? muiTheme.palette.primary.light : '#e0e0e0',
                color: isDefault ? '#ffffff' : undefined,
                borderRadius: '6px',
                ...(style || {}),
              }}
              {...rest}
            >
              {label}
            </Box>
          );

          if (isDefault) {
            return (
              <Tooltip
                arrow
                placement="top"
                title="Default value"
                style={{
                  cursor: 'default',
                }}
              >
                {numberComp}
              </Tooltip>
            );
          }
          return numberComp;
        };

        const BetSettingRow = ({ children }) => (
          <Box
            margin="4px 0"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {children}
          </Box>
        );

        const ListNumberWrap = ({ children }) => (
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            {children}
          </Box>
        );

        return (
          <QuickSetupFieldValidationWrap
            key={betStKey}
            name={`brands.${brandIndex}.betSettings.${betStIndex}`}
          >
            <QuickSetupErrorHandlingConsumer>
              {({
                quickSetupError, name,
              }) => {
                const betSettingErrorKey = keys(quickSetupError).find(errKey => errKey.startsWith(name));
                const betSettingError = get(quickSetupError, betSettingErrorKey);
                return (
                  <FieldWrapper
                    fullWidth
                    borderColor={betSettingError ? muiTheme.palette.error.main : fieldBgColor}
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <FieldName
                      color={betSettingError ? muiTheme.palette.error.main : undefined}
                      error={betSettingError}
                    >
                      {translate('ra.text.betSettingCustom')}
                    </FieldName>
                    <FieldValue>
                      <Box>
                        <Box>
                          <Box component="span">{gameData?.name?.en}</Box>
                          {' '}
                          &
                          {' '}
                          <Box component="span">{currencyData?.name}</Box>
                        </Box>
                      </Box>

                      <Box
                        style={{
                          paddingLeft: '15px',
                          fontWeight: 500,
                        }}
                      >
                        {/* eslint-disable-next-line no-restricted-properties */}
                        {!window.isNaN(totalBetLimit[0]) && (
                          <BetSettingRow>
                            <Box component="span">
                              {translate('ra.field.totalBetMin')}
                              :
                            </Box>
                            <BetSettingNumberItem
                              label={totalBetLimit[0]}
                              style={{
                                width: 'fit-content',
                              }}
                            />
                          </BetSettingRow>
                        )}

                        {/* eslint-disable-next-line no-restricted-properties */}
                        {!window.isNaN(totalBetLimit[1]) && (
                          <BetSettingRow>
                            <Box component="span">
                              {translate('ra.field.totalBetMax')}
                              :
                            </Box>
                            <BetSettingNumberItem
                              label={totalBetLimit[1]}
                              style={{
                                width: 'fit-content',
                              }}
                            />
                          </BetSettingRow>
                        )}

                        <BetSettingRow>
                          <Box>
                            {translate('ra.field.betSize')}
                            :
                            {' '}
                          </Box>
                          <ListNumberWrap>
                            {betSize.map((item, bsIndex) => (
                              <BetSettingNumberItem
                                key={bsIndex}
                                label={item.value}
                                isDefault={item.value === betSizeDefault}
                              />
                            ))}
                          </ListNumberWrap>
                        </BetSettingRow>

                        <BetSettingRow>
                          <Box>
                            {translate('ra.field.betLevel')}
                            :
                            {' '}
                          </Box>
                          <ListNumberWrap>
                            {betLevel.map((item, blIndex) => (
                              <BetSettingNumberItem
                                key={blIndex}
                                label={item.value}
                                isDefault={item.value === betLevelDefault}
                              />
                            ))}
                          </ListNumberWrap>
                        </BetSettingRow>

                        <BetSettingRow>
                          <Box>
                            {translate('ra.field.totalBet')}
                            :
                            {' '}
                          </Box>
                          <ListNumberWrap>
                            {Object.values(totalBet).map((totalBetValue, ttbIndex) => (
                              <BetSettingNumberItem
                                key={ttbIndex}
                                label={calculateTotalBet({
                                  betSize: totalBetValue.betSize,
                                  betLevel: totalBetValue.betLevel,
                                  baseBet: totalBetValue.baseBet,
                                })}
                              />
                            ))}
                          </ListNumberWrap>
                        </BetSettingRow>
                      </Box>
                    </FieldValue>
                  </FieldWrapper>
                );
              }}
            </QuickSetupErrorHandlingConsumer>
          </QuickSetupFieldValidationWrap>
        );
      })}

    </Box>
  );
}
