import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { CreateButton, useListContext, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';

const WealthEmptyPage = ({
  emptyMessage, readOnly,
}) => {
  const translate = useTranslate();
  const {
    basePath, resource,
  } = useListContext();
  const realResource = resource.includes('/') ? resource.split('/')[1] : resource;

  return (
    <Box
      textAlign="center"
      m={1}
    >
      <NoteAddIcon
        style={{
          fontSize: '5rem',
        }}
      />
      <Typography
        variant="h4"
        paragraph
        style={{
          marginTop: '24px',
          marginBottom: '24px',
        }}
      >
        {emptyMessage
          || translate('ra.page.empty', {
            name: translate(`resources.${realResource}.name`),
          })}
      </Typography>

      {!readOnly && (
        <CreateButton
          color="primary"
          variant="contained"
          basePath={basePath}
        />
      )}
    </Box>
  );
};

WealthEmptyPage.propTypes = {
  emptyMessage: PropTypes.string,
  readOnly: PropTypes.bool,
};

WealthEmptyPage.defaultProps = {
  emptyMessage: '',
  readOnly: false,
};

export default WealthEmptyPage;
