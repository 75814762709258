import React, { useState } from 'react';
import AccessTime from '@material-ui/icons/AccessTime';
import axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate, useRefresh } from 'ra-core';
import { SmallButton } from '../../../base/components/ra/buttons';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import Confirm from '../../../base/components/ra/layout/Confirm';

export const CronStatusEnum = {
  IN_PROGRESS: 'IN_PROGRESS',
  ON_SCHEDULE: 'ON_SCHEDULE',
  SUSPENDED: 'SUSPENDED',
  ACCOMPLISHED: 'ACCOMPLISHED',
};

export const ActionMenu = props => {
  const {
    record, loading,
  } = props;
  const cronId = record?.id;
  const cronStatus = record?.status;

  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const canResumeCron = cronStatus === CronStatusEnum.SUSPENDED;
  const requestUrl = canResumeCron ? `api/cron/${cronId}/resume` : `api/cron/${cronId}/suspend`;
  const actionName = translate(canResumeCron ? 'ra.action.resume' : 'ra.action.suspend');

  const triggerEvent = async () => {
    try {
      await axios.post(requestUrl);

      const successMessage = translate('ra.notification.actionSuccess', {
        smart_name: actionName,
      });

      notify(successMessage, 'success');
      refresh();
      setConfirmSubmit(false);
    } catch (error) {
      const errorMessage = translate('ra.message.failure', {
        smart_name: actionName,
      });
      notify(errorMessage, 'error');
    }
  };

  const confirmContent = translate('ra.message.confirmImpactOnCron', {
    smart_name: actionName.toLowerCase(),
  });

  return (
    <ToolbarMenu {...props}>
      <SmallButton
        label={actionName}
        onClick={() => setConfirmSubmit(true)}
        startIcon={<AccessTime />}
        disabled={loading}
      />
      <Confirm
        isOpen={confirmSubmit}
        title={translate('ra.action.trigger')}
        content={confirmContent}
        onConfirm={triggerEvent}
        onClose={() => setConfirmSubmit(false)}
      />
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
  loading: Type.bool.isRequired,
};

ActionMenu.defaultProps = {
  record: {},
};
