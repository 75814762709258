import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {*} props [name]
 * @returns only cloned children
 *
 * @example
 *
 * <SlickPanelContent name="name-of-step">
 *   <YourComponentDisplayOnStep />
 * </SlickPanelContent>
 */
const SlickContent = props => React.cloneElement(props.children);

SlickContent.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SlickContent;
