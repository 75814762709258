/* eslint-disable no-case-declarations */
import { CACHE_LARGE_WIN_BY_PAGE, UPDATE_CURRENT_LARGE_WIN_PAGE } from '../root.actions';

const initialBetStates = {
  largeWinList: {},
  currentLargeWinPage: 1,
};

export const largeWinReducer = (state = initialBetStates, action) => {
  switch (action.type) {
    case CACHE_LARGE_WIN_BY_PAGE:
      const newPage = action.payload?.page || 1;
      return {
        ...state,
        currentLargeWinPage: newPage,
        largeWinList:
          newPage === 1
            ? {
              1: action.payload?.data,
            }
            : {
              ...state.largeWinList,
              [newPage]: action.payload?.data,
            },
      };

    case UPDATE_CURRENT_LARGE_WIN_PAGE:
      return {
        ...state,
        currentLargeWinPage: action.payload,
      };
    default:
      return state;
  }
};
