import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_SUCCESS, GET_ANNOUNCEMENTS_FAILED } from '../root.actions';
import * as api from './announcement.api';

function* fetchAnnouncements() {
  try {
    const { data } = yield call(api.getAnnouncements);

    yield put({
      type: GET_ANNOUNCEMENTS_SUCCESS,
      payload: {
        announcements: data,
      },
    });
  } catch (ex) {
    yield put({
      type: GET_ANNOUNCEMENTS_FAILED,
      payload: {
        announcements: [],
      },
    });
  }
}

export function* announcementSaga() {
  yield takeLatest(GET_ANNOUNCEMENTS, fetchAnnouncements);
}
