import React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import DynamicReferenceField from './components/dynamic-reference.field';

function AuditLogList(props) {
  return (
    <WealthListGuesser
      {...props}
      rowClick={false}
      excludeFields={['recordId']}
    >
      <DynamicReferenceField
        key="recordId"
        source="recordId"
        link="show"
        label="resources.audit-log.fields.recordId"
      />
    </WealthListGuesser>
  );
}

export default AuditLogList;
