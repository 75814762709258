/* eslint-disable  */
import React from 'react';
import {
  SingleFieldList,
  useTranslate,
  ArrayField,
  useLocale,
  FunctionField,
  UrlField,
  BooleanField,
  TextField,
} from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { ChipField } from '../../base/components/ra/fields';
import isEmpty from 'lodash/isEmpty';
import DefaultChip from '../../base/components/DefaultChip';
import ArrayWithPaginationField from '../../base/components/ra/fields/arrayWithPagination.field';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import resourceSlug from '../../constant/resource-slug';
import { ID_EMPTY } from '../../constant';
import { transformIdValue } from '../../services/util/stringUtil';
import { Box } from '@material-ui/core';

const WrapperArrayField = (props) => {
  const locale = useLocale();
  const translate = useTranslate();

  if (isEmpty(props?.record)) {
    return null;
  }

  const isTranslatable = props?.record?.games?.some((item) => typeof item.name === 'object');

  return (
    <ArrayField label={translate('resources.available-game.fields.games')} source="games" fieldKey="id">
      <SingleFieldList linkType={false}>
        <ChipField clickable={false} source={isTranslatable ? `name.${locale}` : 'name'} />
      </SingleFieldList>
    </ArrayField>
  );
};

const AvailableGameShow = (props) => {
  const locale = useLocale();
  const translate = useTranslate();

  return (
    <WealthShowGuesser
      excludeFields={['games']}
      sections={[
        <ArrayWithPaginationField source="games" label={false}>
          <CustomLinkField
            sortable={false}
            source="id"
            href={(record) => `/#/${resourceSlug.GAME}/${record.id}/show`}
            title={(record) => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
          />
          <TextField source={`name.${locale}`} sortable={false} label={'resources.game.fields.name'} />
          <TextField source="code" sortable={false} />
          <TextField source="gameId" sortable={false} label={'resources.game.fields.gameId'} />
          <TextField source="slotType" sortable={false} />
          <FunctionField
            label={'resources.available-game.fields.rtp'}
            source="rtpVersion"
            sortable={false}
            render={(record) => {
              if (record.rtpVersion) return record.rtpVersion;
              return translate('ra.text.default');
            }}
          />
          <UrlField source="playUrl" sortable={false} label={'resources.game.fields.playUrl'} />
          <FunctionField
            source="baseBet"
            sortable={false}
            render={(record) => {
              if (Array.isArray(record.multiBaseBets)) {
                return (
                  <Box display="flex" alignItems="center">
                    <DefaultChip />
                    <Box component="span">{record.baseBet}</Box>
                  </Box>
                );
              }
              return record.baseBet;
            }}
            label={'resources.game.fields.baseBet'}
          />
          <BooleanField source="prepaidEnabled" sortable={false} label={'resources.game.fields.prepaidEnabled'} />
          <BooleanField source="isHotGame" sortable={false} label={'resources.game.fields.isHotGame'} />
          <BooleanField source="isNewGame" sortable={false} label={'resources.game.fields.isNewGame'} />
        </ArrayWithPaginationField>,
      ]}
      {...props}
    >
      <WrapperArrayField {...props} />
    </WealthShowGuesser>
  );
};

export default AvailableGameShow;
