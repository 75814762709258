import * as React from 'react';
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDataProvider,
} from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';

const AddRelatedDataButton = props => {
  const {
    selectedIds, refManyEditProps,
  } = props;
  const {
    referenceResource,
    editingResourceId,
    editingResource,
    onToggleDrawer,
    isManyToMany,
    referenceResourceNameAsProperty,
  } = refManyEditProps || {};

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();

  // update one-to-many
  const updateOneToMany = async () => {
    try {
      await dataProvider.updateMany(
        referenceResource,
        {
          ids: selectedIds,
          data: {
            [camelCase(editingResource)]: {
              id: editingResourceId,
            },
          },
        },
        {
          onSuccess: () => {
            refresh();
            notify('Updated!', 'success');
            unselectAll(referenceResource);
          },
          onFailure: () => {
            notify('Error!', 'error');
          },
        },
      );
    } catch {
      notify('Error!', 'error');
    }
  };

  // update many-to-many
  const updateManyToMany = async () => {
    try {
      const { data: editingResourceData } = await dataProvider.getOne(
        editingResource,
        {
          id: editingResourceId,
        },
      );
      const existedIds = editingResourceData?.[
        referenceResourceNameAsProperty
      ]?.map(d => ({
        id: d.id,
      }));
      const newIds = selectedIds.map(id => ({
        id,
      }));

      await dataProvider.update(
        editingResource,
        {
          id: editingResourceId,
          data: {
            [referenceResourceNameAsProperty]: [...newIds, ...existedIds],
          },
          previousData: editingResourceData,
        },
        {
          onSuccess: () => {
            refresh();
            notify('Updated!', 'success');
            unselectAll(referenceResource);
          },
          onFailure: () => {
            notify('Error!', 'error');
          },
        },
      );
    } catch (error) {
      notify('Error!', 'error');
    }
  };

  const handleClick = () => {
    if (isManyToMany) {
      updateManyToMany();
    } else {
      updateOneToMany();
    }
    onToggleDrawer();
  };

  return (
    <Button
      label="ra.action.add"
      onClick={handleClick}
    >
      <AddCircleOutlineIcon />
    </Button>
  );
};

AddRelatedDataButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/require-default-props
  refManyEditProps: PropTypes.shape({
    editingResource: PropTypes.string,
    editingResourceId: PropTypes.string,
    editingResourceNameAsProperty: PropTypes.string,
    referenceResource: PropTypes.string,
    referenceResourceNameAsProperty: PropTypes.string,
    isManyToMany: PropTypes.bool,
    refManyBulkAction: PropTypes.string,
    onToggleDrawer: PropTypes.func,
  }),
};

AddRelatedDataButton.defaultProps = {
  selectedIds: [],
};

export default AddRelatedDataButton;
