/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import ReactHelmet from 'react-helmet';
import CmtHorizontal from '../../../../theme/@coremat/CmtNavigation/Horizontal';
import CmtVertical from '../../../../theme/@coremat/CmtNavigation/Vertical';
import useNavigation from '../../../hooks/useNavigation';
import useSite from '../../../hooks/useSite';

const AppSidebar = ({ vertical }) => {
  const {
    title, favicon, author, url,
  } = useSite();

  const { navigationMenus } = useNavigation({
    removeHiddenOptions: true,
  });

  const Component = vertical ? CmtVertical : CmtHorizontal;

  return (
    <>
      <ReactHelmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Helmet application"
        />
        <meta
          name="author"
          content={author}
        />
        <meta
          name="og:url"
          content={url}
        />
        <meta
          name="og:title"
          content={title}
        />
        <link
          rel="icon"
          // href={favicon.href}
          href={favicon.default}
          onError={`this.onerror=null;this.href='${favicon.default}'`}
          id="site-favicon"
        />
      </ReactHelmet>
      <Component menuItems={navigationMenus} />
    </>
  );
};

export default AppSidebar;

AppSidebar.defaultProps = {
  vertical: false,
};

AppSidebar.propTypes = {
  vertical: PropTypes.bool,
};
