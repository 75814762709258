import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const BaseCurrencyField = props => (
  <ReferenceField
    source="baseCurrency"
    reference="currency"
    sortable={false}
    link="show"
    {...props}
  >
    <TextField source="code" />
  </ReferenceField>
);

export default BaseCurrencyField;
