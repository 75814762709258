import React, { useState, memo, useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useInput, useLocale, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import * as moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'querystring';
import { get, range } from 'lodash';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';

import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';

import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';
import { clearDateRangePrefix, getBackofficeEnv } from '../../../../services/util';

import RangePickerReport from './RangePickerReport';

const { RangePicker } = DatePicker;

const localLocale = moment();
const dateRangePrefix = FILTER_PREFIX_ON_VALUE.BETWEEN;

const getAntDesignLocale = locale => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const DateRangeInput = props => {
  const {
    entity,
    source,
    resource,
    label,
    isFilter,
    dateRange: initDateRange,
    prefix,
    disabled = false,
    clearable,
    reportServiceVersion,
    disabledDate,
    ...rest
  } = props;

  const { REPORT_BET_QUERY_SUPPORT_DAYS } = getBackofficeEnv();
  const [dateRange, setDateRange] = useState(() => {
    const hasDateString = Array.isArray(initDateRange) && initDateRange.some(item => typeof item === 'string');
    let result = null;
    if (hasDateString) {
      result = initDateRange.map(item => moment(item));
    }
    return result || initDateRange || [null, null];
  });

  const [dateFormat, setDateFormat] = useState();

  const isReportPage = entity === 'report' || ['wager'].includes(resource);

  const history = useHistory();
  const { input: { onChange } } = useInput(props);
  const translate = useTranslate();
  const form = useForm();

  const formValues = form.getState().values;

  const [startDateString, endDateString] = clearDateRangePrefix(formValues[source])?.split(',') || ['', ''];

  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  let localeInMoment = locale;
  if (locale === 'cn') {
    localeInMoment = 'zh-cn';
  }

  const paramsFilter = useSelector(state => state.admin.resources[resource]?.list.params);

  const onChangeRangePicker = dates => {
    const [start, end] = dates || [null, null];
    setDateRange([start, end]);

    // `dates` is null => cleanup date range!
    if (!dates) {
      onChange('');
    }
  };

  const disabledTimesInReports = selected => {
    const isReportPageWithoutWager = entity === 'report';
    const selectedDate = moment(selected);

    if (isReportPageWithoutWager && moment().diff(selectedDate, 'days') >= REPORT_BET_QUERY_SUPPORT_DAYS) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => range(0, 60),
        disabledSeconds: () => range(0, 60),
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const dateRangesStr = [
        moment(dateRange[0]).startOf('second').toISOString(),
        moment(dateRange[1]).startOf('second').toISOString(),
      ];

      const value = prefix
        ? `${dateRangePrefix}${dateRangesStr[0]},${dateRangesStr[1]}`
        : `${dateRangesStr[0]},${dateRangesStr[1]}`;

      if (onChange) {
        onChange(value);
      }

      if (isFilter) {
        form.change(source, value);
      }
    }
  }, [dateRange]);

  useEffect(() => {
    const { filter } = queryString.parse(history.location.search.substring(1)) || {};
    const objFilter = filter ? JSON.parse(filter) : {};
    const dateRangeUrl = objFilter?.[source] || paramsFilter?.filter?.[source];

    let dateRangeStr = dateRangeUrl || '';
    if (typeof dateRangeUrl === 'string' && dateRangeUrl.includes(dateRangePrefix)) {
      dateRangeStr = dateRangeUrl.replace(dateRangePrefix, '');
    }

    const [startDate, endDate] = dateRangeStr?.split(',') || [];

    if (startDate && endDate) {
      setDateRange([moment(startDate), moment(endDate)]);
    }
  }, []);

  useEffect(() => {
    // Get date format depend on locale.
    // Example:
    //    + locale of `th`: DD/MM/YYYY
    //    + locale of `en` or `cn`: MM/DD/YYYY
    const currentFormat = get(localLocale.locale(localeInMoment), '_locale._config.longDateFormat.L', 'MM/DD/YYYY');

    setDateFormat(`${currentFormat} HH:mm:ss`);
  }, [localeInMoment]);

  const dateRangeOnCalendar = useMemo(() => {
    const isChoosing = dateRange.some(d => d === null);
    if (isChoosing) {
      return dateRange;
    }
    return [moment(startDateString), moment(endDateString)];
  }, [startDateString, endDateString, dateRange]);

  useEffect(() => {
    if (prefix && formValues[source] && formValues[source].indexOf(dateRangePrefix) === -1) {
      form.change(source, `${dateRangePrefix}${formValues[source]}`);
    }
  }, [formValues[source]]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        border: '1px solid #0000003B',
        borderRadius: '4px',
        background: '#FFFFFF',
        marginTop: '8px',
        position: 'relative',
      }}
    >
      {/* Background of Label */}
      <Box
        component="label"
        style={{
          position: 'absolute',
          top: -3,
          left: 0,
          padding: '0 6px',
          maxWidth: '1000px',
          background: '#FFFFFF',
          color: '#FFFFFF',
          fontSize: '1rem',
          fontFamily: 'Normal',
          fontWeight: 400,
          lineHeight: 1,
          transform: 'scale(0.75)',
        }}
      >
        {label}
      </Box>

      {/* Value of Label */}
      <Box
        component="label"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '0 6px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '1rem',
          fontFamily: 'Normal',
          fontWeight: 400,
          lineHeight: 1,
          transform: 'translateY(-50%) scale(0.75)',
        }}
      >
        {label}
      </Box>
      {isReportPage ? (
        <RangePickerReport
          value={dateRangeOnCalendar}
          resource={resource}
          allowClear={false}
          disabled={disabled}
          bordered={false}
          format={dateFormat}
          locale={antDesignLocale}
          onChange={onChangeRangePicker}
          translate={translate}
          reportServiceVersion={reportServiceVersion}
          disabledDate={disabledDate}
          disabledTime={disabledTimesInReports}
          source={source}
          {...rest}
        />
      ) : (
        <RangePicker
          style={{
            maxWidth: '380px',
          }}
          allowClear={clearable}
          ranges={{
            [translate('ra.text.today')]: [moment().startOf('day'), moment().endOf('day')],
            [translate('ra.text.yesterday')]: [
              moment().subtract(1, 'days').startOf('day'),
              moment().subtract(1, 'days').endOf('day'),
            ],
            [translate('ra.text.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
            [translate('ra.text.lastMonth')]: [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
          }}
          value={dateRangeOnCalendar}
          disabled={disabled}
          bordered={false}
          format={dateFormat}
          locale={antDesignLocale}
          onChange={onChangeRangePicker}
          disabledDate={disabledDate}
          showTime={{
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          }}
          {...rest}
        />
      )}
    </Box>
  );
};

DateRangeInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isFilter: PropTypes.bool,
  dateRange: PropTypes.array,
  prefix: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  entity: PropTypes.oneOf(['report', undefined]),
  reportServiceVersion: PropTypes.number,
  disabledDate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

DateRangeInput.defaultProps = {
  isFilter: false,
  dateRange: null,
  prefix: true,
  disabled: false,
  clearable: true,
  entity: undefined,
  reportServiceVersion: 0,
  disabledDate: undefined,
};

export default memo(DateRangeInput);
