import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {},
}));

export const withStandardButtonProps = WrappedComponent => props => {
  const classes = useStyles();

  return (
    <WrappedComponent
      className={classes.root}
      size="medium"
      variant="contained"
      disableElevation
      {...props}
    />
  );
};

export const withStandardSmallButtonProps = WrappedComponent => props => {
  const classes = useStyles();

  return (
    <WrappedComponent
      variant="text"
      color="primary"
      size="small"
      className={classes.root}
      {...props}
    />
  );
};
