import React from 'react';
import PropTypes from 'prop-types';
import { NumberField as RANumberField, useLocale } from 'react-admin';
import { get } from 'lodash';

const NumberField = props => {
  const {
    record, source, options = {}, ...rest
  } = props;

  const locale = useLocale();

  if (get(record, source) == null) {
    return null;
  }

  return (
    <RANumberField
      record={record}
      source={source}
      locales={locale}
      options={{
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        ...options,
      }}
      {...rest}
    />
  );
};

NumberField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  currency: PropTypes.string,
  addLabel: PropTypes.bool,
  options: PropTypes.object,
};

NumberField.defaultProps = {
  record: {},
  currency: undefined,
  addLabel: true,
  options: {},
};

export default NumberField;
