/* eslint-disable no-use-before-define, no-shadow */
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { MenuItem, Select, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import AntTable from '../../../base/components/antd/AntdTable';

const useStyles = makeStyles({
  betAmountSelector: {
    width: 160,
  },
});

const RtpConfigTable = () => {
  const [rtpVersions, setRtpVersions] = useState([]);

  const handleFetchRtpVersion = async () => {
    try {
      const response = await axios.get('/api/game/rtp-version');
      setRtpVersions(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchRtpVersion();
  }, []);

  return (
    <Field
      name="games"
      component={ConfigTable}
      rtpVersions={rtpVersions}
    />
  );
};

const ConfigTable = ({
  input: { value: data }, rtpVersions,
}) => {
  const t = useTranslate();

  const columns = [
    {
      title: t('resources.game.name'),
      dataIndex: 'label',
      key: 'name',
      render: name => name,
    },
    {
      title: t('resources.available-game.fields.rtp'),
      width: '40%',
      key: 'rtpVersion',
      render: (_, record) => {
        const gameIndex = data.findIndex(item => item.value === record.value);
        return (
          <Field
            name={`games.${gameIndex}.rtpVersion`}
            component={RtpSelector}
            rtpVersions={rtpVersions}
          />
        );
      },
    },
  ];

  return (
    !!data.length && (
      <AntTable
        columns={columns}
        dataSource={data}
        wrapperProps={{
          my: '28px',
        }}
      />
    )
  );
};

const RtpSelector = ({
  input: {
    onChange, value,
  }, rtpVersions,
}) => {
  const classes = useStyles();
  const t = useTranslate();
  const defaultOption = {
    value: null,
    label: t('ra.text.default'),
  };

  const options = useMemo(() => {
    if (!rtpVersions.length) return [];
    return [
      defaultOption,
      ...rtpVersions.map(item => ({
        value: item.rtpVersion,
        label: item.rtpVersion,
      })),
    ];
  }, [defaultOption, rtpVersions]);

  return (
    <Select
      value={value}
      onChange={e => {
        onChange?.(e.target.value);
      }}
      variant="outlined"
      className={classes.betAmountSelector}
      displayEmpty
      renderValue={value => (rtpVersions?.length ? (options.find(option => option.value === value) || defaultOption).label : '')}
    >
      {options.map(item => (
        <MenuItem
          key={item.value}
          value={item.value}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RtpConfigTable;
