/* eslint-disable  */
import { FormDataConsumer, useTranslate } from "ra-core";
import { SimpleForm, ReferenceInput } from "react-admin";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { BooleanInput, SelectInput } from "../../base/components/ra/inputs";
import { Edit } from "../../base/components/ra/views";
import { RbacAction } from "../../services/provider/rbacProvider";
import { useSelector } from "react-redux";
import useAllowedResources from "../../base/hooks/useAllowedResources";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginTop: "-25px",
    fontSize: "12px",
  },
}));

const PermEdit = (props) => {
  const { permissions, resource, id } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const allowedResources = useAllowedResources();

  const generateHelpText = ({ resource, action }) => {
    const resourceTranslated = translate(`resources.${resource}.name`);
    const actionTranslated = translate(`ra.action.${action}`);

    return translate("wa.exception.invalidPermission", {
      resource: resourceTranslated.toLowerCase(),
      action: actionTranslated.toLowerCase(),
    });
  };

  const renderCheckBoxPerm = (resourceName) =>
    Object.values(RbacAction).map((action) => {
      let enabled = allowedResources?.[resourceName]?.[action];

      if (action === RbacAction.EXPORT) {
        enabled =
          enabled && allowedResources?.[resourceName]?.[RbacAction.LIST];
      }

      return (
        <React.Fragment key={action}>
          <BooleanInput
            label={`resources.perm.fields.${action}`}
            fullWidth
            source={action}
            disabled={!enabled}
          />
          {!enabled && (
            <div className={classes.error}>
              {generateHelpText({
                resource: resourceName,
                action,
              })}
            </div>
          )}
        </React.Fragment>
      );
    });

  const transform = (data) => {
    const { resource, role, ...rest } = data;

    return rest;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm redirect={`/${resource}/${id}/show`}>
        <ReferenceInput
          fullWidth
          label="resources.perm.fields.role"
          source="role.id"
          reference="role"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          fullWidth
          label="resources.perm.fields.resource"
          source="resource.id"
          reference="resource"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            formData.resource && renderCheckBoxPerm(formData.resource.name)
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default PermEdit;
