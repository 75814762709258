/* eslint-disable react/prop-types */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Button, Link, Divider, useTheme, alpha } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';

import { NUMBER_TO_SHOW_CHEAT_MODE_CREATED_MESSAGE } from '../../../constant';

const MessageItem = props => {
  const {
    timestamp,
    muiTheme,
    data: {
      brand,
      group,
      // cheatModeId,
    },
  } = props;

  const translate = useTranslate();

  const [fromNow, setFromNow] = useState(moment(timestamp).fromNow());
  const intervalSetFromNow = useRef();

  useEffect(() => {
    intervalSetFromNow.current = setInterval(() => {
      setFromNow(moment(timestamp).fromNow());
    }, 1000);

    return function cleanUp() {
      clearInterval(intervalSetFromNow.current);
    };
  }, []);

  const creatingProcessMessage = translate('ra.text.cheatModeWasCreatedMessage', {
    group_name: group?.name,
    brand_name: brand?.name,
  });

  return (
    <>
      <Box
        style={{
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
          fontSize: '12px',
        }}
      >
        <Box>
          <Box
            component="span"
            style={{
              fontWeight: 700,
              fontSize: '14px',
            }}
          >
            {creatingProcessMessage}
          </Box>
          <Box
            component="span"
            style={{
              color: alpha(muiTheme.palette.primary.subText, 0.62),
            }}
          >
            {` - ${fromNow}`}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          marginTop: 4,
        }}
      >
        <Link
          href={`/#/cheat-mode-apply?filter=${JSON.stringify({
            group: {
              id: group.id,
            },
            brand: {
              id: brand.id,
            },
          })}`}
          target="_blank"
          style={{
            fontSize: '13px',
          }}
        >
          {translate('ra.action.viewDetail')}
        </Link>
      </Box>
    </>
  );
};

const CheatModeCreatingProcessMessage = props => {
  const {
    closeToast, toastProps,
  } = props;

  const translate = useTranslate();
  const muiTheme = useTheme();

  if (isEmpty(toastProps?.data)) {
    return null;
  }

  const listCheatModeCreated = Object.keys(toastProps?.data)
    .splice(0, NUMBER_TO_SHOW_CHEAT_MODE_CREATED_MESSAGE)
    .map((timestamp, index) => {
      const {
        cheatModeId,
        brand,
        group,
      } = toastProps.data[timestamp];

      return (
        <Fragment key={timestamp}>
          <MessageItem
            timestamp={+timestamp}
            muiTheme={muiTheme}
            data={{
              brand,
              group,
              cheatModeId,
            }}
          />
          {index < (Object.keys(toastProps.data).length - 1) && (
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
          )}
        </Fragment>
      );
    });

  return (
    <Box>
      <Box
        style={{
          textAlign: 'center',
          fontSize: '15px',
          fontWeight: 500,
          marginBottom: '16px',
        }}
      >
        {translate('ra.text.newCheatModeCreated')}
      </Box>
      <Box>{listCheatModeCreated}</Box>
      {Object.keys(toastProps?.data).length > NUMBER_TO_SHOW_CHEAT_MODE_CREATED_MESSAGE && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '3px',
          }}
        >
          <Button
            size="small"
            color="private"
            onClick={() => {
              window.open('/#/cheat-mode', '_blank');
              closeToast();
            }}
            style={{
              fontSize: '12px',
              fontWeight: 500,
              color: muiTheme.palette.primary.main,
            }}
          >
            {translate('ra.action.showMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CheatModeCreatingProcessMessage;
