/* eslint-disable react/prop-types */
import React, { useState, memo, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useListContext, useInput, useLocale } from 'react-admin';
import * as moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'querystring';
import { get } from 'lodash';
import { TextInput } from '../../ra/inputs';
import ClearableButton from '../../clearable-button';

const localLocale = moment();

const formatDate = (date, dateFormat) => {
  if (!date) return '';

  // Format this date to `L`, it's meaning format date with current locale
  return moment(date, dateFormat).format('L');
};

export const convertDate = (value, startDay) => {
  if (startDay) {
    return moment(value)
      .startOf('day')
      .toISOString();
  }
  return moment(value)
    .endOf('day')
    .toISOString();
};

const DateInput = props => {
  const {
    source,
    resource,
    label,
    isFilter,
    clearable,
    initValue,
    allowEmpty,
  } = props;
  const [date, setDate] = useState(allowEmpty ? null : initValue || new Date());
  const [openDialog, setOpenDialog] = useState(false);
  const [dateFormat, setDateFormat] = useState();
  const context = useListContext();
  const history = useHistory();
  const { input: { onChange } } = useInput(props);
  let locale = useLocale();

  if (locale === 'cn') locale = 'zh-cn';

  const paramsFilter = useSelector(
    state => state.admin.resources[resource]?.list.params,
  );

  const handleClearDateRange = e => {
    e.stopPropagation();
    setDate(null);
    onChange('');
  };

  useEffect(() => {
    if (date) {
      setOpenDialog(false);

      if (onChange) {
        onChange(convertDate(date, true));
      }

      if (isFilter) {
        context.setFilters({
          ...context.filterValues,
          [source]: convertDate(date, true),
        });
      }
    }
  }, [date]);

  useEffect(() => {
    const { filter } = queryString.parse(history.location.search.substring(1)) || {};
    const objFilter = filter ? JSON.parse(filter) : {};
    const dateInput = objFilter?.[source] || paramsFilter?.filter?.[source];
    if (dateInput) {
      setDate(dateInput);
    }
  }, []);

  useEffect(() => {
    // Get date format depend on locale
    // Example
    // + locale of `th`: DD/MM/YYYY
    // + locale of `en` or `cn`: MM/DD/YYYY
    const currentFormat = get(
      localLocale.locale(locale),
      '_locale._config.longDateFormat.L',
      'MM/DD/YYYY',
    );

    setDateFormat(currentFormat);
  }, [locale]);

  return (
    <DatePicker
      open={openDialog}
      value={date}
      onChange={newDate => {
        setDate(newDate);
      }}
      onClose={() => setOpenDialog(false)}
      renderInput={startProps => {
        const format = formatDate(startProps?.inputProps?.value, dateFormat);
        return (
          <TextInput
            className="date-input"
            source={source}
            label={label}
            inputRef={startProps.inputRef}
            onClick={() => setOpenDialog(true)}
            fullWidth
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearableButton
                    isDisplay={clearable}
                    handleClear={handleClearDateRange}
                  />
                  <DateRangeIcon />
                </InputAdornment>
              ),
            }}
            placeholder={dateFormat}
            format={() => format}
          />
        );
      }}
      animateYearScrolling
    />
  );
};

export default memo(DateInput);
