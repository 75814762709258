import React, { useState } from 'react';
import { Box, Tooltip, Button as MuiButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import cls from 'classnames';
import { Done } from '@material-ui/icons';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { alpha } from '@material-ui/core/styles';
import moment from 'moment';
import Confirm from '../../../../base/components/ra/layout/Confirm';
// eslint-disable-next-line
import { useStyles as useCommonStyles } from '.';
import resourceSlug from '../../../../constant/resource-slug';
import { PREPAID_APPROVAL_STATUS } from '../../../../constant';
import SimpleBackdrop from '../../../../base/components/layout/SimpleBackdrop';

const useStyles = makeStyles(
  theme => ({
    approveButton: {
      '&[class*="contained"]': {
        color: theme.palette.secondary.main,
        '&, &:hover': {
          background: theme.palette.primary.light,
        },
        '&:disabled': {
          color: alpha('#000000', 0.26),
          background: theme.palette.grey[300],
        },
      },
    },
  }),
  {
    index: 2,
  },
);

const ApproveButton = props => {
  const { record } = props;

  if (!record) return null;

  const notify = useNotify();
  const commonClasses = useCommonStyles();
  const translate = useTranslate();
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);
  const [isPrepaymentExpirationDialogOpened, setIsPrepaymentExpirationDialogOpened] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isCheckingExpiry, setIsCheckingExpiry] = useState(false);
  const hasApproved = record.approvalStatus === PREPAID_APPROVAL_STATUS.approved;
  const disableApproveButton = hasApproved || isApproving || isCheckingExpiry;
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const handleApproveClick = async e => {
    e.stopPropagation();

    try {
      setIsCheckingExpiry(true);
      const { data } = await dataProvider.getOne(resourceSlug.REWARD, {
        id: record.reward?.id,
      });

      const expiredDate = data.claimBefore;
      const hasExpired = moment().isAfter(moment(expiredDate));

      if (hasExpired) {
        setIsPrepaymentExpirationDialogOpened(true);
      } else {
        setIsConfirmationDialogOpened(true);
      }
    } finally {
      setIsCheckingExpiry(false);
    }
  };

  const handleDialogConfirm = async () => {
    try {
      setIsApproving(true);
      await axios.post(`/api/${resourceSlug.PREPAID}/${record.id}/approve`);
      refresh();
      notify(translate('resources.prepaid.approvedSuccessfully'), {
        type: 'success',
        autoHideDuration: 3500,
      });
    } catch (err) {
      // This error is handled in the interceptor
      console.debug('[ERROR] after prepaid approve\n', err.message);
    } finally {
      setIsConfirmationDialogOpened(false);
      setIsApproving(false);
    }
  };

  return (
    <>
      <Tooltip title={translate('ra.action.approve')}>
        <MuiButton
          variant="contained"
          size="small"
          className={cls(commonClasses.button, classes.approveButton, props?.className)}
          onClick={handleApproveClick}
          disabled={disableApproveButton}
        >
          <Box
            component="span"
            display="flex"
            alignItems="center"
            style={{
              gap: '3px',
            }}
          >
            <Done fontSize="16px" />
            <Box component="span">{translate('ra.action.approve')}</Box>
          </Box>
        </MuiButton>
      </Tooltip>
      <Confirm
        isOpen={isConfirmationDialogOpened}
        title={translate('resources.prepaid.confirmToApproveTitle')}
        content={translate('resources.prepaid.confirmToApproveMessage')}
        onConfirm={handleDialogConfirm}
        onClose={() => setIsConfirmationDialogOpened(false)}
      />
      <Confirm
        isOpen={isPrepaymentExpirationDialogOpened}
        title={translate('resources.prepaid.prepaymentExpirationTitle')}
        content={translate('resources.prepaid.prepaymentExpirationMessage')}
        onClose={() => setIsPrepaymentExpirationDialogOpened(false)}
        cancel="OK"
        readOnly
      />
      <SimpleBackdrop open={isCheckingExpiry} />
    </>
  );
};

ApproveButton.propTypes = {
  record: PropTypes.object,
  className: PropTypes.string,
};

ApproveButton.defaultProps = {
  record: null,
  className: null,
};

export default ApproveButton;
