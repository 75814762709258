import { cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';

export default function WealthInputWrapper(props) {
  const {
    children, ...rest
  } = props;

  return (
    isValidElement(children)
      ? cloneElement(children, {
        ...rest,
      })
      : children
  );
}

WealthInputWrapper.propTypes = {
  // `source` do not use in this component, but wealth-create/wealth-edit will use it
  source: PropTypes.string.isRequired,
};
