import { CACHE_BETS_BY_PAGE, UPDATE_CURRENT_BET_PAGE } from '../root.actions';

export const cacheBetsByPage = ({
  data, page,
}) => ({
  type: CACHE_BETS_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentBetPage = data => ({
  type: UPDATE_CURRENT_BET_PAGE,
  payload: data,
});
