/* eslint-disable */
import React, { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import axios from 'axios';
import Confirm from '../../../base/components/ra/layout/Confirm';
import resourceSlug from '../../../constant/resource-slug';
import { useHistory } from 'react-router-dom';
import LocalStorage, { REDIRECT_KEY } from '../../../services/local-storage';
import { useError } from '../../../base/hooks';

const ERROR_KEY_NOT_FOUND_PLAYER_TOKEN_URL = 'wa.exception.notFoundGetPlayerTokenUrl';
const ERROR_KEY_PLAYER_STILL_IN_GAME = 'wa.exception.playerInTheGame';
const ERROR_KEY_LOCKED_SPIN_PLAYER = 'wa.exception.playerLockedSpinning';

const withComplete =
  (CompleteButton) =>
  ({ onSuccess, onError, record, ...otherProps }) => {
    const history = useHistory();
    const translate = useTranslate();
    const notify = useNotify();
    const { notifyError } = useError();
    const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);
    const [isNotExistDialogOpened, setIsNotExistDialogOpened] = useState(false);
    const [isNotFoundDialogOpened, setIsNotFoundDialogOpened] = useState(false);
    const [isSessionSkippedDialogOpened, setIsSessionSkippedDialogOpened] = useState(false);
    const [isLockedSpinPlayerDialogOpened, setIsLockedSpinPlayerDialogOpened] = useState(false);
    const [isChecking, setIsChecking] = useState(false);

    const handleCheckValid = async (e) => {
      e.stopPropagation();

      setIsChecking(true);

      try {
        const recordDetail = await axios.get(`/api/${resourceSlug.PLAYER_STATE_MANAGEMENT}/${record.id}`);
        const hasGetPlayerTokenUrl = recordDetail?.data?.hasGetPlayerTokenUrl;

        if (hasGetPlayerTokenUrl) {
          setIsConfirmationDialogOpened(true);
        } else {
          setIsNotExistDialogOpened(true);
        }
      } finally {
        setIsChecking(false);
      }
    };

    const handleGoToSettings = () => {
      LocalStorage.setItem(REDIRECT_KEY, `/${resourceSlug.PLAYER_STATE_MANAGEMENT}/${record.id}/show`);
      history.push(`/${resourceSlug.BRAND}/${record.brandId}`);
    };

    const handleGoToPlayerDetail = () => {
      history.push(`/${resourceSlug.PLAYER}/${record.playerId}/show`);
    };

    const handleComplete = async (bodyData) => {
      let errorKey;
      try {
        // To avoid customized axios interceptor
        const Axios = axios.create({ baseURL: '/' });
        await Axios.post(`/api/${resourceSlug.PLAYER_STATE_MANAGEMENT}/${record.id}/active`, bodyData);
        if (onSuccess) {
          onSuccess();
        }
        notify(translate('resources.player-state-management.completedSuccessfully'), {
          type: 'success',
        });
      } catch (err) {
        errorKey = err?.response?.data?.key;

        if (onError) {
          onError(err);
        } else if (errorKey === ERROR_KEY_NOT_FOUND_PLAYER_TOKEN_URL) {
          setIsNotFoundDialogOpened(true);
        } else if (errorKey === ERROR_KEY_PLAYER_STILL_IN_GAME) {
          setIsSessionSkippedDialogOpened(true);
        } else if (errorKey === ERROR_KEY_LOCKED_SPIN_PLAYER) {
          setIsLockedSpinPlayerDialogOpened(true);
        } else {
          notifyError(err);
        }
      } finally {
        setIsConfirmationDialogOpened(false);
        if (errorKey !== ERROR_KEY_PLAYER_STILL_IN_GAME) {
          setIsSessionSkippedDialogOpened(false);
        }
      }
    };

    return (
      <>
        <CompleteButton disabled={isChecking} onClick={handleCheckValid} {...otherProps}>
          {translate('resources.player-state-management.actions.complete')}
        </CompleteButton>
        {isConfirmationDialogOpened && (
          <Confirm
            isOpen={isConfirmationDialogOpened}
            title={translate('resources.player-state-management.confirmToCompleteTitle')}
            content={translate('resources.player-state-management.confirmToCompleteMessage')}
            onConfirm={() => handleComplete({ skipSession: false })}
            onClose={() => setIsConfirmationDialogOpened(false)}
          />
        )}
        {isNotExistDialogOpened && (
          <Confirm
            isOpen={isNotExistDialogOpened}
            title={translate('resources.player-state-management.getPlayerTokenUrlNotExistTitle')}
            content={translate('resources.player-state-management.getPlayerTokenUrlNotExistMessage')}
            onConfirm={handleGoToSettings}
            onClose={() => setIsNotExistDialogOpened(false)}
            confirm={translate('resources.player-state-management.actions.goToSettings')}
          />
        )}
        {isNotFoundDialogOpened && (
          <Confirm
            isOpen={isNotFoundDialogOpened}
            title={translate('resources.player-state-management.getPlayerTokenUrlNotFoundTitle')}
            content={translate('resources.player-state-management.getPlayerTokenUrlNotFoundMessage')}
            onConfirm={handleGoToSettings}
            onClose={() => setIsNotFoundDialogOpened(false)}
            confirm={translate('resources.player-state-management.actions.goToSettings')}
          />
        )}
        {isSessionSkippedDialogOpened && (
          <Confirm
            isOpen={isSessionSkippedDialogOpened}
            title={translate('resources.player-state-management.skipSessionTitle')}
            content={translate('resources.player-state-management.skipSessionMessage')}
            onConfirm={() => handleComplete({ skipSession: true })}
            onClose={() => setIsSessionSkippedDialogOpened(false)}
            confirm={translate('resources.player-state-management.actions.complete')}
          />
        )}
        {isLockedSpinPlayerDialogOpened && (
          <Confirm
            isOpen={isLockedSpinPlayerDialogOpened}
            title={translate('resources.player-state-management.lockedSpinPlayerTitle')}
            content={translate('resources.player-state-management.lockedSpinPlayerMessage')}
            onConfirm={handleGoToPlayerDetail}
            onClose={() => setIsLockedSpinPlayerDialogOpened(false)}
            confirm={translate('resources.player-state-management.actions.goToDetails')}
          />
        )}
      </>
    );
  };

export default withComplete;
