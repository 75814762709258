import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';
import resourceSlug from '../../../../constant/resource-slug';
import { useReportContext } from '../ReportLayout';
import { useAuthUser } from '../../../hooks';

const GameIdInput = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();
  const { currentFilters } = useReportContext();
  const user = useAuthUser();

  const brand = currentFilters?.brand || user?.brands?.[0]?.id;

  return (
    <FormField name={source}>
      {() => (
        <ReferenceInput
          label={translate('resources.report.fields.gameId')}
          source={source}
          reference={resourceSlug.GAME}
          filter={{
            brandId: brand,
          }}
          filterToQuery={value => ({
            'gameId||$cont': value,
          })}
          sort={{
            field: 'gameId',
            order: 'ASC',
          }}
          perPage={100}
          {...otherProps}
        >
          <AutocompleteInput
            optionText="gameId"
            resettable
          />
        </ReferenceInput>
      )}
    </FormField>
  );
};

GameIdInput.propTypes = {
  source: PropTypes.string,
};

GameIdInput.defaultProps = {
  source: 'gameId',
};

export default GameIdInput;
