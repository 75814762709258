import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { FieldTitle, useInput } from 'ra-core';

import { sanitizeInputRestProps, InputPropTypes } from 'react-admin';

const BooleanInput = ({
  format,
  label,
  onBlur,
  onChange,
  onFocus,
  options,
  disabled,
  parse,
  resource,
  source,
  validate,
  ...rest
}) => {
  const {
    id,
    input: {
      onChange: finalFormOnChange, ...inputProps
    },
    isRequired,
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value);
    },
    [finalFormOnChange],
  );

  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        control={(
          <Checkbox
            id={id}
            color="primary"
            onChange={handleChange}
            {...inputProps}
            {...options}
            disabled={disabled}
          />
        )}
        label={(
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )}
      />
    </FormGroup>
  );
};

BooleanInput.propTypes = {
  ...InputPropTypes,
  options: PropTypes.shape(Checkbox.propTypes),
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
};

BooleanInput.defaultProps = {
  options: {},
};

export default BooleanInput;
