import React, { useState } from 'react';

import {
  SimpleForm,
  ReferenceInput,
  Create,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TextInput,
  DateTimeInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';
import axios from 'axios';
import { NumberInput } from '../../base/components/ra/inputs';

function MissionCreate(props) {
  const [supportedMissions, setSupportedMissions] = useState();

  async function getSupportedMissions(id) {
    try {
      const res = await axios.get(`api/mission/supportedMissions/${id}`);
      setSupportedMissions(
        Object.keys(res?.data || {})?.map(key => ({
          id: key,
          name: res.data[key],
        })),
      );
    } catch (error) {
      console.log('error :>> ', error);
    }
  }

  return (
    <Create {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        redirect="show"
      >
        <ReferenceInput
          label="resources.game.name"
          source="game"
          reference="game"
          fullWidth
          perPage={100}
          onChange={event => getSupportedMissions(event)}
        >
          <AutocompleteInput
            optionText="name"
            on
          />
        </ReferenceInput>

        <TextInput
          fullWidth
          source="name"
        />

        <TextInput
          fullWidth
          source="desc"
        />

        <NumberInput
          fullWidth
          source="minBet"
        />

        <DateTimeInput
          fullWidth
          source="startDate"
        />
        <DateTimeInput
          fullWidth
          source="endDate"
        />

        <NumberInput
          fullWidth
          source="value.prize"
        />

        <SelectInput
          source="value.type"
          fullWidth
          choices={supportedMissions || []}
        />

        <BooleanInput
          fullWidth
          source="isAllPlayer"
        />
        <FormDataConsumer>
          {({ formData }) => !formData.isAllPlayer && (
            <ReferenceArrayInput
              label="resources.player.name"
              source="players"
              reference="player"
              fullWidth
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
export default MissionCreate;
