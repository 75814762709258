import { useMemo } from 'react';

/**
 * To get component request (create view)
 */
export const getComponentRequestByCreateView = ({
  paths, basePath, ref,
}) => {
  let requestRef = paths?.[basePath]?.post?.requestBody?.content?.['application/json']?.schema;
  requestRef = requestRef?.$ref || requestRef?.allOf?.filter(i => i?.$ref)?.[0]?.$ref;
  return requestRef ? ref.get(requestRef) : null;
};

/**
 * To get component request (list view)
 */
export const getComponentRequestByListView = ({
  paths, basePath, ref,
}) => {
  try {
    const responseSchema = paths?.[basePath]?.get?.responses?.['200']?.content?.['application/json']?.schema;

    if (!responseSchema?.oneOf) {
      return null;
    }

    let responseRef = responseSchema.oneOf.filter(r => r.type === 'array')?.[0]?.items;
    responseRef = responseRef?.$ref || responseRef?.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

    return responseRef ? ref.get(responseRef) : null;
  } catch (error) {
    return null;
  }
};

/**
 * To get component request (edit view)
 */
export const getComponentRequestByEditView = ({
  paths, basePath, ref,
}) => {
  const target = `${basePath}/{id}`;
  let requestRef = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;
  requestRef = requestRef?.$ref || requestRef?.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

  return requestRef ? ref.get(requestRef) : null;
};

/**
 * To get component request (show view)
 */
export const getComponentRequestByShowView = ({
  paths, basePath, ref,
}) => {
  const responseSchema = paths?.[basePath]?.get?.responses?.['200']?.content?.['application/json']?.schema;
  if (!responseSchema?.oneOf) {
    return null;
  }

  let responseRef = responseSchema.oneOf.filter(r => r.type === 'array')?.[0]?.items;
  responseRef = responseRef?.$ref || responseRef?.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

  return responseRef ? ref.get(responseRef) : null;
};

/**
 * To get componentRequest object
 * + including `properties` as configuration of fields (useful to rendering fields and inputs)
 * + and required fields
 * + and type "object"
 *
 * return { properties, required, type }
 */
export const useComponentRequest = ({
  api, ref, basePath, viewType,
}) => {
  const apiPaths = api?.paths;

  const componentRequest = useMemo(() => {
    if (!apiPaths || !basePath || !ref) return null;

    const params = {
      paths: apiPaths,
      ref,
      basePath,
    };

    switch (true) {
      case viewType.isCreateView:
        return getComponentRequestByCreateView(params);
      case viewType.isListView:
        return getComponentRequestByListView(params);
      case viewType.isEditView:
        return getComponentRequestByEditView(params);
      case viewType.isShowView:
        return getComponentRequestByShowView(params);
      default:
        return null;
    }
  }, [ref, apiPaths, basePath, viewType.isCreateView, viewType.isListView, viewType.isEditView, viewType.isShowView]);

  return componentRequest;
};
