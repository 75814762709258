import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';
import resourceSlug from '../../../../constant/resource-slug';

const GameTypeInput = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <FormField name={source}>
      {() => (
        <ReferenceInput
          label={translate('resources.report.fields.gameType')}
          source={source}
          reference={resourceSlug.GAME_TYPE}
          filterToQuery={value => ({
            'name||$cont': value,
          })}
          sort={{
            field: 'name',
            order: 'ASC',
          }}
          perPage={100}
          {...otherProps}
        >
          <AutocompleteInput
            optionText="name"
            resettable
          />
        </ReferenceInput>
      )}
    </FormField>
  );
};

GameTypeInput.propTypes = {
  source: PropTypes.string,
};

GameTypeInput.defaultProps = {
  source: 'gameType',
};

export default GameTypeInput;
