/* eslint-disable */
import React, { useEffect } from "react";
import { useTranslate } from "react-admin";
import WealthEditGuesser from "../../base/components/guesser/wealth-edit.guesser";
import WealthInputWrapper from "../../base/components/guesser/wealth-input-wrapper";
import { SelectInput } from "../../base/components/ra/inputs";
import { useEnumOptions } from "../../base/hooks";
import { useTitleContext } from "../../base/context/title";

const CheatModeApplyStatus = ({ source }) => {
  let cheatModeEnum = useEnumOptions("cheat_mode_apply", "status");

  cheatModeEnum = (cheatModeEnum || []).filter(
    (mode) => mode?.id !== "DISABLE"
  );

  return <SelectInput source={source} choices={cheatModeEnum} />;
};

const CheatModeApplyEdit = (props) => {
  const translate = useTranslate();
  const { setTitle } = useTitleContext();

  const customFields = ["status"];

  const pageTitle = translate("resources.cheat-mode-apply.name");
  const pageNote = translate("resources.cheat-mode-apply.text.note");

  useEffect(() => {
    const element = (
      <div>
        <div>{pageTitle}</div>
        <div
          style={{
            fontSize: "14px",
            marginTop: "5px",
          }}
        >
          {pageNote}
        </div>
      </div>
    );
    setTitle(element);

    return function cleanUp() {
      setTitle(null);
    };
  }, [pageTitle, pageNote]);

  return (
    <WealthEditGuesser {...props} customFields={customFields}>
      <WealthInputWrapper source="status">
        <CheatModeApplyStatus />
      </WealthInputWrapper>
    </WealthEditGuesser>
  );
};

export default CheatModeApplyEdit;
