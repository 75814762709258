import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { PULL_WEALTH_SCHEMA } from '../../services/redux/root.actions';
import { pascalCase } from '../../services/util/stringUtil';

let apiCached = null;
const useSchema = () => {
  const {
    api, ref,
  } = useSelector(state => state?.schema);

  const dispatch = useDispatch();

  // Get a metadata list of resource as `properties`
  const getPropertiesByResourceSchema = resource => get(api, `components.schemas.${pascalCase(resource)}.properties`);

  // From `resource` and `source` as field of resource
  // we can get a metadata/properties of this field
  const getPropertiesBySource = (resource, source) => get(
    api,
    `components.schemas.${pascalCase(resource)}.properties.${source}.properties`,
  );

  useEffect(() => {
    if (!api && api !== apiCached) {
      dispatch({
        type: PULL_WEALTH_SCHEMA,
      });
    }
    apiCached = api;
  }, [api]);

  return {
    api,
    ref,
    resourceSchemas: get(api, 'components.schemas'),
    getPropertiesByResourceSchema,
    getPropertiesBySource,
  };
};

export default useSchema;
