import { useDispatch } from 'react-redux';
import { toggleLoading as toggleLoadingAction } from '../../services/redux/app/app.actions';

const useLoading = () => {
  const dispatch = useDispatch();

  const toggleLoading = visible => {
    dispatch(toggleLoadingAction(visible));
  };

  return {
    toggleLoading,
  };
};

export default useLoading;
