import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import 'codemirror/lib/codemirror.css';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useInput } from 'react-admin';
import { FormControl, FormHelperText, Box } from '@material-ui/core';
import { InputHelperText } from 'ra-ui-materialui';

const TuiEditorInput = props => {
  const {
    record, source, height, placeholder, helperText,
  } = props;

  const {
    input: { onChange },
    meta: {
      touched, error,
    },
  } = useInput(props);

  const editorRef = React.useRef();

  const onContentChange = () => {
    if (editorRef?.current?.editorInst) {
      onChange(editorRef?.current?.editorInst?.getMarkdown());
    }
  };

  return (
    <FormControl>
      <Box
        border={(touched && error) ? '1px solid red' : 'none'}
        borderRadius="4px"
        overflow="hidden"
      >
        <Editor
          initialValue={record?.[source]}
          previewStyle="vertical"
          height={height}
          initialEditType="markdown"
          useCommandShortcut
          ref={editorRef}
          onChange={onContentChange}
          placeholder={placeholder}
        />
      </Box>
      <Box margin="4px 14px 0">
        <FormHelperText error={!!error}>
          <InputHelperText
            error={error}
            helperText={helperText}
            touched={touched}
          />
        </FormHelperText>
      </Box>
    </FormControl>
  );
};

TuiEditorInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

TuiEditorInput.defaultProps = {
  record: {},
  source: '',
  height: '600px',
  placeholder: '',
  helperText: '',
};

export default TuiEditorInput;
