/* eslint-disable react/forbid-prop-types, react/require-default-props */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';
import { useTranslate } from 'react-admin';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import ForceChangePasswordForm from '../../../base/components/change-password/forceChangePassword.form';
import { sanitizeButtonRestProps } from '../../../base/components/ra/buttons/Button';

const useStyles = makeStyles(() => ({
  viewButton: {
    color: '#EAAA00',
    '&:hover': {
      backgroundColor: alpha('#EAAA00', 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const defaultIcon = <RestorePageIcon />;

function ChangePasswordButton(props) {
  const {
    record, icon = defaultIcon, className, ...rest
  } = props;
  const [showForm, setShowChangePasswordForm] = useState(false);
  const style = useStyles();
  const translate = useTranslate();

  if (!record || record.id == null) {
    return null;
  }

  return (
    <>
      <Tooltip title={translate('ra.action.changePassword')}>
        <IconButton
          onClick={e => {
            setShowChangePasswordForm(true);
            e.stopPropagation();
          }}
          className={classnames(className, style.viewButton)}
          {...sanitizeButtonRestProps(rest)}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <ForceChangePasswordForm
        isOpen={showForm}
        user={record}
        onClose={() => setShowChangePasswordForm(false)}
      />
    </>
  );
}

ChangePasswordButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.any,
  extraActions: PropTypes.any,
};

const PureResetPasswordButton = memo(
  ChangePasswordButton,
  (props, nextProps) => (
    props.record && nextProps.record
      ? props.record.id === nextProps.record.id
      : props.record === nextProps.record
  ),
);

export default PureResetPasswordButton;
