import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useRecordContext } from 'react-admin';
import {
  DATETIME_DISPLAY_FORMAT,
  DATETIME_DISPLAY_FORMAT_WITH_SECONDS,
  DATETIME_DISPLAY_FORMAT_WITH_MILLISECOND,
} from '../../../../constant';

const DateField = props => {
  const defaultRecord = useRecordContext();

  const {
    record, source, showSecond, showMillisecond, dateFormat,
  } = props;

  const dateString = record?.[source] || defaultRecord?.[source];
  const date = moment(dateString).utc();

  const getDateFormat = () => {
    if (dateFormat) return dateFormat;
    switch (true) {
      case showSecond:
        return DATETIME_DISPLAY_FORMAT_WITH_SECONDS;
      case showMillisecond:
        return DATETIME_DISPLAY_FORMAT_WITH_MILLISECOND;
      default:
        return DATETIME_DISPLAY_FORMAT;
    }
  };

  return <Box>{date.isValid() ? date.format(getDateFormat()) : ''}</Box>;
};

DateField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
  showSecond: PropTypes.bool,
  showMillisecond: PropTypes.bool,
  dateFormat: PropTypes.string,
};
DateField.defaultProps = {
  record: {},
  showSecond: false,
  showMillisecond: false,
  dateFormat: undefined,
};

export default DateField;
