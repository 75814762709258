/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Box } from '@material-ui/core';

export const InputContainer = ({
  children, ...restProps
}) => (
  <Box
    padding="0px 10px"
    {...restProps}
  >
    {children}
  </Box>
);

export const InputGridWrapper = React.memo(({ children }) => (
  <Grid
    item
    xs={12}
    lg={4}
  >
    <InputContainer>
      {children}
    </InputContainer>
  </Grid>
));
