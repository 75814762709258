import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';

export default function GameShow(props) {
  return (
    <WealthShowGuesser
      {...props}
    />
  );
}
