/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { Box, Button, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { saveFormDataToStorage } from './utils';
import { validateFormValueByStep } from './utils/validation';
import TooltipButton from '../../../base/components/button/TooltipButton';
import SlickContainer from '../../../base/components/simple-slick/SlickContainer';

const useStyles = makeStyles(() => ({
  btnStyle: {
    margin: 0,
    '& > span': {
      display: 'flex',
      gap: '5px',
    }
  },
  btnNext: {},
}));

const QuickSetupStepper = props => {
  const {
    children,
    onStepperChange,
    isNextDisabled,
    isBackDisabled,
    numOfStep,
    buttonLastStep,
    storageKey,
  } = props;

  const appLoading = useSelector(({ app }) => app.isLoading);

  const classes = useStyles();

  const { values: formValues } = useFormState();
  const form = useForm();

  const translate = useTranslate();
  const sliderRef = useRef();
  
  const [sliderState, setSliderState] = useState({
    curIndex: 0,
  });

  const [settings] = useState({
    beforeChange: (nextIndex) => {
      if (typeof onStepperChange === 'function') {
        onStepperChange(nextIndex);
      }
      setSliderState({
        curIndex: nextIndex,
      });
    },
  });

  const areFieldsByStepValid = validateFormValueByStep(sliderState.curIndex, form);

  const handleNextOnClick = () => {
    sliderRef.current.slickGoTo(sliderState.curIndex + 1);
    window.scrollTo(0, 0);

    saveFormDataToStorage(formValues, storageKey);
  };

  return (
    <Box marginTop="16px">
      <SlickContainer
        ref={sliderRef}
        initStep={0}
        beforeChange={settings.beforeChange}
      >
        {children}
      </SlickContainer>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        position="relative"
        marginTop="16px"
      >
        {sliderState.curIndex > 0 && (
          <Button
            className={classes.btnStyle}
            onClick={() => {
              sliderRef.current.slickGoTo(sliderState.curIndex - 1);
              window.scrollTo(0, 0);
              saveFormDataToStorage(formValues, storageKey);
            }}
            disabled={isBackDisabled || appLoading}
          >
            <ArrowBackIcon /> {translate('ra.action.back')}
          </Button>
        )}

        <Box style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '14px' }}>
          {sliderState.curIndex < numOfStep - 1 && (
            <TooltipButton
              tooltipProps={{
                title: areFieldsByStepValid ? '' : translate('ra.message.correctAllInputsBeforeNext')
              }}
              buttonProps={{
                className: classNames(classes.btnStyle, classes.btnNext),
                onClick: handleNextOnClick,
                disabled: isNextDisabled || !areFieldsByStepValid,
              }}
            >
              {translate('ra.navigation.next')} <ArrowForwardIcon /> 
            </TooltipButton>
          )}

          {sliderState.curIndex === numOfStep - 1 && buttonLastStep}
        </Box>
      </Box>
    </Box>
  );
};

export default QuickSetupStepper;
