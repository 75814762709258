import { Box, Menu, MenuItem, Divider } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';

const UsernameBox = styled(MenuItem)`
  background-color: #FFFFFF;
  font-weight: 700;
  opacity: 0.8;
  text-transform: uppercase;

  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  padding-right: 0;
  margin-right: 16px;
  &:hover {
    background-color: #FFFFFF;
  }
`;

const CmtDropdownMenu = ({
  TriggerComponent, items, onItemClick, record,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState(items);
  const open = Boolean(anchorEl);
  const translate = useTranslate();

  const { username } = record;

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item, selectedIndex) => {
    let updatedItem = {
      ...item,
    };

    if (item.onClick && typeof item.onClick === 'function') {
      updatedItem = item.onClick(item);
    } else if (onItemClick && typeof onItemClick === 'function') {
      updatedItem = onItemClick(item);
    }

    setMenuItems(
      menuItems.map((item, index) => {
        if (updatedItem && selectedIndex === index) {
          item = updatedItem;
        }
        return item;
      }),
    );

    closeMenu();
  };

  return (
    <>
      <Box className="pointer">
        <TriggerComponent.type
          {...TriggerComponent.props}
          onClick={openMenu}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {username && typeof username === 'string' && (
          <UsernameBox disabled>
            {/* rid of `_` which connect a group code and username */}
            {username.replace(/^.+\_/, "")}
          </UsernameBox>
        )}
        {username && typeof username === 'string' && <Divider />}
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item.disabled}
            onClick={() => handleMenuItemClick({
              ...item,
            }, index)}
          >
            {item.icon}
            <Box ml={2}>{item?.needTranslate ? translate(item.label) : item.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

CmtDropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  TriggerComponent: PropTypes.element.isRequired,
  onItemClick: PropTypes.func,
};

export default CmtDropdownMenu;
