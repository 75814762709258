/* eslint-disable react/require-default-props, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import {
  useTranslate,
  useDeleteWithConfirmController,
  useResourceContext,
} from 'ra-core';
import Confirm from '../../../layout/Confirm';

const useStyles = makeStyles(
  theme => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  {
    name: 'RaDeleteWithConfirmButton',
  },
);

const defaultIcon = <ActionDelete />;
const DeleteWithConfirmButton = props => {
  const {
    basePath,
    className,
    confirmTitle = 'ra.message.delete_title',
    confirmContent = 'ra.message.delete_content',
    icon = defaultIcon,
    onClick,
    record,
    redirect = 'list',
    onSuccess,
    onFailure,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    record,
    redirect,
    basePath,
    onClick,
    onSuccess,
    onFailure,
  });
  const resource = useResourceContext(props);

  return (
    <>
      <Tooltip title={translate('ra.action.delete')}>
        <IconButton
          onClick={handleDialogOpen}
          className={classnames(
            'ra-delete-button',
            classes.deleteButton,
            className,
          )}
          key="button"
          {...rest}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true,
            ),
          }),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  );
};

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default DeleteWithConfirmButton;
