/* eslint-disable */
import { regex } from 'react-admin';
import { get } from 'lodash';

export const getRegexValidate = (properties) => {
  const validationData = get(properties, "validate", {});
  const {
    description,
    pattern,
  } = validationData;
  
  const nullValue = null;

  if (!pattern || !description) {
    return nullValue;
  }

  // Trim first and last symbol => format to get a correct regex string
  const formatPattern = pattern.replace(/^\//, '').replace(/\/$/, '');

  let result;
  try {
    const regexPattern = new RegExp(formatPattern);
    result = regex(regexPattern, description);
  } catch {
    console.log("WARN: regex validation is invalid");
    return nullValue;
  }

  return result;
};
