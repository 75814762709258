import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookie from '../../services/util/handleCookie';

const useAccessToken = () => {
  const user = useSelector(({ auth }) => auth?.user);

  const accessTokenData = useMemo(() => {
    const userToken = Cookie.getItem('accessToken');
    let tokenDataDecoded = {};
    try {
      tokenDataDecoded = jwtDecode(userToken);
    } catch (error) {
      // Do nothing!
      console.error(error);
    }
    return tokenDataDecoded;
  }, [user]);

  return accessTokenData;
};

export default useAccessToken;
