/* eslint-disable no-use-before-define */
import moment from 'moment';
import React, { useCallback } from 'react';
import { List, FunctionField, useTranslate, useLocale } from 'react-admin';
import { makeStyles, Tooltip } from '@material-ui/core';
import Pagination from '../../base/components/customizable-colums/Pagination';
import WealthEmptyPage from '../../base/components/guesser/wealth-empty-page';
import Datagrid from '../../base/components/ra/data-grid';

const RetentionReportList = props => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      actions={false}
      empty={<WealthEmptyPage />}
      pagination={<Pagination />}
      bulkActionButtons={false}
      perPage={25}
      sort={{
        field: 'time',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <SheetField label={translate('resources.report-retention.report')} />
      </Datagrid>
    </List>
  );
};

const SheetField = () => {
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  const handleGetSheetName = useCallback(
    currentRecord => {
      const { time } = currentRecord;

      return translate('resources.report-retention.sheetName', {
        date: moment(time).locale(locale).format('MMM YYYY'),
      });
    },
    [locale],
  );

  return (
    <Tooltip
      title={translate('ra.tooltip.clickToDownload')}
      placement="bottom"
    >
      {/* To add ref for tooltip */}
      <div>
        <FunctionField
          source="name"
          render={record => (
            <a
              className={classes.sheet}
              href={record.url}
              target="_blank"
              rel="noreferrer"
            >
              {handleGetSheetName(record)}
            </a>
          )}
        />
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles(theme => ({
  sheet: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export default RetentionReportList;
