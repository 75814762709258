/* eslint-disable react/prop-types, */
import React, { useEffect, useMemo, memo, useState } from 'react';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { AutocompleteInput, useDataProvider } from 'react-admin';
import { useFormContext } from './form.context';

const WrapperAutocompleteInput = memo(
  ({
    filterValues, defaultRefFilter = {}, reference, source, choices, defaultChoicesOnTop, ...props
  }) => {
    const {
      setRefValues, isMounted, setMounted,
    } = useFormContext();

    const dataProvider = useDataProvider();

    const [topItems, setTopItems] = useState([]);
    const topItemIds = topItems.filter(item => item.id).map(item => item.id);

    useEffect(() => {
      if (!isObject(defaultChoicesOnTop)) {
        return;
      }

      (async () => {
        const { data } = await dataProvider.getList(reference, {
          pagination: {
            page: 1,
            perPage: defaultChoicesOnTop.values?.length,
          },
          filter: {
            [`${defaultChoicesOnTop.field}||$in||`]: defaultChoicesOnTop.values.join(','),
          },
          sort: defaultChoicesOnTop.sort,
        });

        if (data.length > 0) {
          setTopItems(data);
        }
      })();
    }, [defaultChoicesOnTop, choices.length, isMounted]);

    // Set default value for GROUP's select
    // GROUP's value of default as the first item
    useEffect(() => {
      if (defaultRefFilter?.[source] && choices.length > 0 && !filterValues?.[source] && !isMounted) {
        setMounted(true);
        setRefValues({
          [source]: choices?.[0].id,
        });
      }
    }, [choices.length, isMounted]);

    const choicesFilter = useMemo(() => {
      const choicesResult = choices?.filter(item => get(item, props?.optionText) && !topItemIds.includes(item.id)) || [];
      return [...topItems.filter(item => get(item, props?.optionText)), ...choicesResult];
    }, [props?.optionText, choices, topItems]);

    return (
      <AutocompleteInput
        {...props}
        source={source}
        choices={choicesFilter}
      />
    );
  },
);

export default WrapperAutocompleteInput;
