import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';

const formatDate = (dateStr, showTime) => {
  const dateList = dateStr?.split(',');
  const format = showTime ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY';
  return dateList.map(d => moment(d).format(format)).join(' - ');
};

export const addExportListener = ({
  dataList,
  dateString,
  fileName,
  showTime,
}) => {
  jsonExport(dataList, {
    rowDelimiter: ';',
  }, (_, csv) => {
    let reportName = fileName;

    // Generate csv name with date
    if (dateString && typeof dateString === 'string') {
      const reportDate = formatDate(dateString, showTime);
      reportName = `${fileName} - ${reportDate}`.replace(/\s-\s$/, '');
    }

    downloadCSV(csv, reportName);
  });
};
