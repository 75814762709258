import React, { useEffect } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { required, ReferenceArrayInput, regex, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import _, { keys } from 'lodash';
import PropTypes from 'prop-types';
import { TextInput, AutocompleteArrayInput, BooleanInput, SelectInput } from '../../../base/components/ra/inputs';
import { REGEX_URL } from '../../../services/util/validate/regularExpression';
import GameTransferList from './GameTransferList';
import BetSettingPanel from './BetSettingPanel';
import { SettingLabel, SettingWrapper } from './SettingWrapper';
import ConfigField from './ConfigField';
import { WhiteListForm } from './forms/WhitelistForm';
import CurrenciesConfig from '../../../base/components/currencies-config';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';

/**
 *
 * @param {*} props
 * @returns Some inputs to create new Brand
 */
const BrandForm = props => {
  const {
    // eslint-disable-next-line no-unused-vars
    id: brandFormId,
    index: brandFormIndex,
    source: brandSource,
  } = props;

  const sourceNamePrefix = `${brandSource}.`;

  const isUrl = () => regex(REGEX_URL, 'Please put a correct URL format');

  const form = useForm();
  const { values: formValues } = useFormState();
  const muiTheme = useTheme();

  const translate = useTranslate();

  const resources = useSelector(({ admin }) => admin.resources);
  const languageDataFetched = _.get(resources, 'language.data');

  const extraFieldsForSeamless = [
    {
      label: 'resources.brand.fields.payOutUrl',
      source: `${sourceNamePrefix}payOutUrl`,
      validate: [required(), isUrl()],
    },
    {
      label: 'resources.brand.fields.placeBetUrl',
      source: `${sourceNamePrefix}placeBetUrl`,
      validate: [required(), isUrl()],
    },
    {
      label: 'resources.brand.fields.betStatusUrl',
      source: `${sourceNamePrefix}betStatusUrl`,
      validate: [required(), isUrl()],
    },
    {
      label: 'resources.brand.fields.cancelBetUrl',
      source: `${sourceNamePrefix}cancelBetUrl`,
      validate: [required(), isUrl()],
    },
    {
      label: 'resources.brand.fields.createPlayerUrl',
      source: `${sourceNamePrefix}createPlayerUrl`,
      validate: isUrl(),
    },
    {
      label: 'resources.brand.fields.editBalanceUrl',
      source: `${sourceNamePrefix}editBalanceUrl`,
      validate: isUrl(),
    },
    {
      type: 'payload-config',
      source: 'payoutConfig',
      label: 'Payout Config',
      description: 'If fields is enabled,these fields will be attached to the payload when call request Payout.',
      configFields: [
        {
          label: 'betType',
          source: 'betType',
          defaultValue: false,
        },
        {
          label: 'isLastFreeSpins',
          source: 'isLastFreeSpins',
          defaultValue: false,
        },
      ],
    },
    {
      type: 'payload-config',
      source: 'placebetConfig',
      label: 'Placebet Config',
      description: 'If fields is enabled,these fields will be attached to the payload when call request Placebet.',
      configFields: [
        {
          label: 'betType',
          source: 'betType',
          defaultValue: false,
        },
        {
          label: 'isLastFreeSpins',
          source: 'isLastFreeSpins',
          defaultValue: false,
        },
      ],
    },
    {
      type: 'payload-config',
      source: 'cancelbetConfig',
      label: 'Cancelbet Config',
      description: 'If fields is enabled,these fields will be attached to the payload when call request Cancelbet.',
      configFields: [
        {
          label: 'playerToken',
          source: 'playerToken',
          defaultValue: false,
        },
      ],
    },
  ];

  const implementation = _.get(formValues, `${sourceNamePrefix}implementation`);

  // Reset some fields depend on `implementation` SEAMLESS
  useEffect(() => {
    if (implementation && implementation !== 'SEAMLESS') {
      extraFieldsForSeamless.forEach(item => {
        form.change(item.source, undefined);
      });
    }
  }, [implementation]);

  useEffect(() => {
    form.change(`${sourceNamePrefix}enabled`, true);
  }, []);

  return (
    <Box>
      <SettingWrapper>
        <SettingLabel
          style={{
            color: muiTheme.palette.primary.dark,
          }}
        >
          {translate('ra.text.brandInformation')}
        </SettingLabel>
        <TextInput
          fullWidth
          label="resources.brand.fields.name"
          source={`${sourceNamePrefix}name`}
          validate={required()}
        />
        <TextInput
          fullWidth
          multiline
          label="resources.brand.fields.desc"
          source={`${sourceNamePrefix}desc`}
          minRows={5}
        />
        <CurrencySelector
          source={`${sourceNamePrefix}currencies`}
          selectedCurrencies={_.get(formValues, `${sourceNamePrefix}currencies`, [])}
        />
        <TextInput
          fullWidth
          label="resources.brand.fields.domain"
          source={`${sourceNamePrefix}domain`}
          validate={[required(), isUrl()]}
        />
        <ReferenceArrayInput
          fullWidth
          label="resources.brand.fields.languages"
          source={`${sourceNamePrefix}languages`}
          reference="language"
          validate={required()}
          parse={ids => ids?.map(id => ({
            id,
          }))}
          format={data => data?.map(d => d.id)}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          label="resources.brand.fields.defaultLanguage"
          source={`${sourceNamePrefix}defaultLanguage.id`}
          choices={(() => {
            const languageSelectedIds = _.get(formValues, `${sourceNamePrefix}languages`, []);
            if (keys(languageDataFetched).length === 0) {
              return [];
            }

            return languageSelectedIds.map(langItem => ({
              id: languageDataFetched[langItem.id].id,
              name: languageDataFetched[langItem.id].name,
            }));
          })()}
          disabled={!_.get(formValues, `${sourceNamePrefix}languages`, []).length}
          validate={required()}
        />
        <SelectInput
          label="resources.brand.fields.implementation"
          source={`${sourceNamePrefix}implementation`}
          choices={[
            {
              id: 'TRANSFER',
              name: 'TRANSFER',
            },
            {
              id: 'SEAMLESS',
              name: 'SEAMLESS',
            },
          ]}
          validate={required()}
        />
        <TextInput
          fullWidth
          label="resources.brand.fields.authorizeUrl"
          source={`${sourceNamePrefix}authorizeUrl`}
          validate={[required(), isUrl()]}
        />
        <TextInput
          fullWidth
          label="resources.brand.fields.getBalanceUrl"
          source={`${sourceNamePrefix}getBalanceUrl`}
          validate={[required(), isUrl()]}
        />
        {implementation === 'SEAMLESS'
          && extraFieldsForSeamless.map(item => {
            if (item.type === 'payload-config') {
              return (
                <ConfigField
                  label={item.label}
                  configFields={item.configFields}
                  sourcePrefix={`${sourceNamePrefix}${item.source}.`}
                  description={item.description}
                />
              );
            }
            return (
              <TextInput
                key={item.source}
                fullWidth
                label={item.label}
                source={item.source}
                validate={item.validate}
              />
            );
          })}

        <BooleanInput
          label="resources.brand.fields.enabled"
          source={`${sourceNamePrefix}enabled`}
          validate={required()}
        />
      </SettingWrapper>

      <SettingWrapper>
        <SettingLabel
          style={{
            color: muiTheme.palette.primary.dark,
          }}
        >
          {translate('ra.text.availableGamesSetting')}
        </SettingLabel>
        <GameTransferList
          sourceNamePrefix={sourceNamePrefix}
          source={`${sourceNamePrefix}games`}
        />
      </SettingWrapper>

      <SettingWrapper>
        <SettingLabel
          style={{
            color: muiTheme.palette.primary.dark,
          }}
        >
          {translate('ra.text.betSettingConfig')}
        </SettingLabel>
        <BetSettingPanel index={brandFormIndex} />
      </SettingWrapper>

      <SettingWrapper
        style={{
          color: muiTheme.palette.primary.dark,
        }}
      >
        <WhiteListForm source={`${sourceNamePrefix}whitelist`} />
      </SettingWrapper>
    </Box>
  );
};

BrandForm.defaultProps = {};

BrandForm.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
};

const CurrencySelector = props => {
  const { values: formValues } = useFormState();

  const groupId = formValues.group?.id;

  const isQuickSetUpBrand = Boolean(groupId);

  return (
    <CurrenciesConfig
      currenciesSelectorProps={{
        filterToQuery: value => ({
          name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
          [isQuickSetUpBrand ? 'groups.id||$in' : 'id||$in']: isQuickSetUpBrand
            ? groupId
            : formValues.group.currencies.map(currency => currency.id).join(','),
        }),
      }}
      {...props}
    />
  );
};

export default BrandForm;
