import React from 'react';
import NumberInput from '../../../base/components/ra/inputs/integer.input';

const FailedRetryFilter = () => (
  <NumberInput
    label="resources.seamless-reconciliation.fields.failedRetry"
    source="failedRetry||$eq"
    min={0}
    resettable
  />
);

export default FailedRetryFilter;
