import React, { useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Box,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';

import { API_URL } from '../../../constant';
import GroupingTable from './grouping-table';

const useStyles = makeStyles(
  {
    root: {
      marginTop: 24,
    },
  },
  {
    name: 'FormEditPerm',
  },
);

const FormEditPerm = props => {
  const {
    permissions, id,
  } = props;
  const classes = useStyles();

  const [dataPerms, setDataPerms] = useState([]);

  const fetchPermByRole = async roleId => {
    try {
      const res = await axios.get(API_URL.GET_PERM_BY_ROLE(roleId));
      if (Array.isArray(res?.data)) {
        setDataPerms(res.data);
      }
    } catch {
      // Do nothing!
    }
  };

  useEffect(() => {
    if (id) {
      fetchPermByRole(id);
    }
  }, [id]);

  return (
    <Box className={classes.root}>
      <Card>
        <CardContent className={classes.cartContent}>
          <GroupingTable
            id={id}
            permissions={permissions}
            dataPerms={dataPerms}
          />
        </CardContent>
        <CardActions>
          {/* <SaveButton
            size="small"
            color="primary"
            variant="text"
            handleSubmitWithRedirect={onSave}
          /> */}
        </CardActions>
      </Card>
    </Box>
  );
};

FormEditPerm.propTypes = {
  permissions: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

FormEditPerm.defaultProps = {};

export default FormEditPerm;
