import { addExportListener } from './exporters.util';

const reportInvoicingExporter = async (
  records,
  fetchRelatedRecords,
  _dataProvider,
  _resource,
  additionData = {},
) => {
  const { date } = additionData;

  const brand = await fetchRelatedRecords(records, 'brand', 'brand');
  const currency = await fetchRelatedRecords(records, 'currency', 'currency');

  const invoicingForExport = records.map(data => {
    const result = {
      id: data.id,
      brand: brand[data.brand]?.name,
      currency: currency[data.currency]?.code,
      betType: data.betType,
      betAmount: data.betAmount?.toString?.()?.replace?.('.', ','),
      wonAmount: data.wonAmount?.toString?.()?.replace?.('.', ','),
    };

    return result;
  });

  addExportListener({
    dataList: invoicingForExport,
    dateString: date,
    fileName: 'Invoicing Report',
  });
};

export default reportInvoicingExporter;
