/* eslint react/prop-types: 0 */
import React from 'react';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';
import TableCell from './table-cell';

const CheckBoxCell = ({
  children, classes, className,
}) => (
  <TableCell
    classes={classes}
    className={classnames(classes.checkboxColumn, className)}
  >
    <Box className={classes.checkboxColumnContent}>{children}</Box>
  </TableCell>
);

export default CheckBoxCell;
