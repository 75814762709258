import React, { useContext, useEffect } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { IntlProvider } from 'react-intl';
import { createTheme, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

const AppWrapper = ({ match, location, children, theme: themeOverride, ...restProps }) => {
  const { theme, locale, direction } = useContext(AppContext);
  const currentAppLocale = AppLocale[locale.locale];
  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ThemeProvider theme={createTheme(theme)}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          <AppLayout>{children}</AppLayout>
        </StylesProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
