import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';

const MediaGalleryShow = props => (
  <WealthShowGuesser
    {...props}
  />
);

export default MediaGalleryShow;
