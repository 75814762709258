/* eslint-disable */
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classnames from "classnames";

import {
  hideNotification,
  getNotification,
  undo,
  complete,
  undoableEventEmitter,
  useTranslate,
} from "ra-core";

const useStyles = makeStyles(
  (theme) => ({
    snackbar: {
      zIndex: 10000,
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: "white",
    },
    warning: {
      backgroundColor: theme.palette.error.main,
      color: "white",
    },
    info: {
      backgroundColor: theme.palette.info.main,
      color: "white",
    },
    default: {
      backgroundColor: "rgb(48, 48, 49)",
      color: "white",
    },
    undo: (props) => ({
      color:
        props.type === "success"
          ? theme.palette.success.contrastText
          : theme.palette.primary.light,
    }),
  }),
  {
    name: "RaNotification",
  }
);

const Notification = (props) => {
  const {
    classes: classesOverride,
    type,
    className,
    autoHideDuration,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const notification = useSelector(getNotification);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const styles = useStyles(props);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = useCallback(() => {
    if (notification && notification.undoable) {
      dispatch(complete());
      undoableEventEmitter.emit("end", {
        isUndo: false,
      });
    }
    dispatch(hideNotification());
  }, [dispatch, notification]);

  const handleUndo = useCallback(() => {
    dispatch(undo());
    undoableEventEmitter.emit("end", {
      isUndo: true,
    });
  }, [dispatch]);

  return (
    <Snackbar
      open={open}
      message={
        notification &&
        notification.message &&
        translate(notification.message, notification.messageArgs)
      }
      autoHideDuration={
        (notification && notification.autoHideDuration) || autoHideDuration
      }
      classes={{ root: styles.snackbar }}
      disableWindowBlurListener={notification && notification.undoable}
      onClose={handleRequestClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      ContentProps={{
        className: classnames(
          styles[(notification && notification.type) || type],
          className
        ),
      }}
      action={
        notification && notification.undoable ? (
          <Button
            color="primary"
            className={styles.undo}
            size="small"
            onClick={handleUndo}
          >
            {translate("ra.action.undo")}
          </Button>
        ) : null
      }
      {...rest}
    />
  );
};

Notification.propTypes = {
  type: PropTypes.string,
};

Notification.defaultProps = {
  type: "default",
  autoHideDuration: 4000,
};

export default Notification;
