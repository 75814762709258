/* eslint-disable import/no-named-as-default,react/prop-types */
import React from 'react';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FinalStepDialog from './FinalStepDialog';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
  },
  subject: {
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
  },
  subjectIcon: {
    color: theme.palette.success.dark,
  },
  subjectContent: {
    backgroundColor: '#f4f4f4',
    borderRadius: '4px',
    padding: '12px',
    '&:not(:last-of-type)': {
      marginBottom: '12px',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
    borderBottom: '1px solid #e1e1e1',
    padding: '8px 16px',
    '&:last-of-type': {
      marginBottom: 0,
      borderBottom: 'none',
    },
  },
  cell: {
    '&:first-of-type': {
      width: '170px',
      textTransform: 'uppercase',
    },
    '&:last-of-type': {
      flex: '2',
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  viewDetailBtn: {
    margin: '20px 0 0 16px',
  },
}));

const CreatedConfirmation = props => {
  const {
    data = {}, title,
  } = props;
  const {
    group, brands,
  } = data;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <FinalStepDialog
      isVisible
      subject={title}
      content={(
        <Box className={classes.dialogContent}>
          <Box marginBottom="20px">
            <Box className={classes.subject}>
              <Box>{translate('resources.group.name')}</Box>
              <CheckCircleIcon className={classes.subjectIcon} />
            </Box>
            <Box className={classes.subjectContent}>
              <Box className={classes.row}>
                <Box className={classes.cell}>{translate('resources.group.fields.name')}</Box>
                <Box className={classes.cell}>{group.name}</Box>
              </Box>
              <Box className={classes.row}>
                <Box className={classes.cell}>ID</Box>
                <Box className={classes.cell}>
                  <a
                    className={classes.link}
                    href={`/#/group/${group.id}/show`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {group.id}
                  </a>
                </Box>
              </Box>
              <Box className={classes.row}>
                <Box className={classes.cell}>{translate('resources.group.fields.code')}</Box>
                <Box className={classes.cell}>{group.code}</Box>
              </Box>

              <Button
                variant="outlined"
                color="primary"
                className={classes.viewDetailBtn}
                onClick={e => {
                  e.preventDefault();
                  const groupDetailUrl = `/#/group/${group.id}/show`;
                  window.open(groupDetailUrl, '_blank');
                }}
              >
                {translate('ra.action.viewDetail')}
              </Button>
            </Box>
          </Box>
          <Box>
            <Box className={classes.subject}>
              <Box>
                {translate('resources.brand.name')}
                {' '}
                {`(${brands.length} ${translate('ra.text.items')})`}
              </Box>
              <CheckCircleIcon className={classes.subjectIcon} />
            </Box>
            {brands.map(bItem => (
              <Box
                key={bItem.id}
                className={classes.subjectContent}
              >
                <Box className={classes.row}>
                  <Box className={classes.cell}>{translate('resources.brand.fields.name')}</Box>
                  <Box className={classes.cell}>{bItem.name}</Box>
                </Box>
                <Box className={classes.row}>
                  <Box className={classes.cell}>ID</Box>
                  <Box className={classes.cell}>
                    <a
                      className={classes.link}
                      href={`/#/brand/${bItem.id}/show`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bItem.id}
                    </a>
                  </Box>
                </Box>
                <Box className={classes.row}>
                  <Box className={classes.cell}>{translate('resources.brand.fields.code')}</Box>
                  <Box className={classes.cell}>{bItem.code}</Box>
                </Box>
                <Box className={classes.row}>
                  <Box className={classes.cell}>{translate('resources.wallet-integration.name')}</Box>
                  <Box className={classes.cell}>
                    <a
                      className={classes.link}
                      href={`/#/wallet-integration/${bItem.walletIntegration?.id}/show`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bItem.walletIntegration?.name}
                    </a>
                  </Box>
                </Box>
                {!!bItem.whitelist?.id && (
                  <Box className={classes.row}>
                    <Box className={classes.cell}>{translate('resources.white-list.name')}</Box>
                    <Box className={classes.cell}>
                      <a
                        className={classes.link}
                        href={`/#/white-list/${bItem.whitelist?.id}/show`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {bItem.whitelist?.name}
                      </a>
                    </Box>
                  </Box>
                )}

                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.viewDetailBtn}
                  onClick={e => {
                    e.preventDefault();
                    const brandDetailUrl = `/#/brand/${bItem.id}/show`;
                    window.open(brandDetailUrl, '_blank');
                  }}
                >
                  {translate('ra.action.viewDetail')}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      onClose={e => e}
    />
  );
};

export default CreatedConfirmation;
