/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import { DateField, NumberField } from '../../../../base/components/ra/fields';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-prepaid.list';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import CustomLinkField from '../../../../base/components/ra/fields/customLink.field';
import { ID_EMPTY } from '../../../../constant';
import { transformIdValue } from '../../../../services/util/stringUtil';

const PrepaidTable = () => {
  const translate = useTranslate();
  return (
    <CustomReportTable
      title={translate('resources.report.prepaid')}
      reportType={REPORT_TYPES.PREPAID}
      reportList={LIST_REPORTS}
      resource={resourceSlug.REPORT_PREPAID}
    >
      <CustomLinkField
        sortable={false}
        source="id"
        href={(record) => `/#/${resourceSlug.PREPAID}/${record.id}/show`}
        title={(record) => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
      />
      <DateField source="created" label="common.fields.created" sortable />
      <ReferenceField source="group" reference="group" label="resources.group.name" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="brand" reference="brand" label="resources.brand.name" link="show">
        <TextField source="name" />
      </ReferenceField>
      <CustomLinkField
        source="requester"
        label="resources.prepaid.fields.requester"
        href={(record) => `/#/${resourceSlug.USER}/${record.requester?.id}/show`}
        title={(record) => record.requester?.username}
        sortable
      />
      <NumberField label="resources.report.fields.noIssued" source="noIssued" sortable={false} />
      <NumberField label="resources.report.fields.noClaimed" source="noClaimed" sortable={false} />
      <ReferenceField source="currency" reference="currency" label="resources.currency.name" link="show">
        <TextField source="code" />
      </ReferenceField>
      <TextField label="resources.report.fields.amountPerSpin" source="amountPerSpin" sortable={false} />
      <TextField label="resources.report.fields.eventExpense" source="eventExpense" sortable={false} />
    </CustomReportTable>
  );
};

export default PrepaidTable;
