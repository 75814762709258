/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslate, required } from 'ra-core';
import {
  AutocompleteInput,
  ArrayInput,
} from 'react-admin';
import { Box, Button as MuiButton, useTheme } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import SimpleFormIterator from '../../../../custom-core-admin/SimpleFormIterator';
import { ReferenceInput } from '../../../../base/components/ra/inputs';
import MultipleAutoComplete from '../MultipleAutoComplete';
import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';

import {
  AddIcon,
  renderBrandItemEndAdornment,
  // PlayerAttentionBox,
  TableTitle,
  TableTitleBright,
} from './steps.utils';

// Schema
/**
  const formData = {
    dynamicGroupStep: {
      groups: [
        {
          id: 'f5a05b6f-a48b-42e6-a04c-bed2f60eed33',
          brands: {
            'f5a05b6f-a48b-42e6-a04c-bed2f60eed33': {
              players: {
                'f5a05b6f-a48b-42e6-a04c-bed2f60eed32': null,
                'f5a05b6f-a48b-42e6-a04c-bed2f60eed33': null,
              }
            }
          }
        }
      ]
    }
  }
 */

const SimpleFormIteratorContent = props => {
  const {
    formData, indexOfForm, dynamicRuleRef, ...restProps
  } = props;

  const muiTheme = useTheme();
  const translate = useTranslate();
  const form = useForm();
  const { values: formValues } = useFormState();

  const resources = useSelector(({ admin }) => admin.resources);
  const brandDataFetched = _.get(resources, 'brand.data');

  const [openingState, setOpeningState] = useState({
    choosingBrand: false,
    choosingPlayer: false,
  });

  const [activeBrand, setActiveBrand] = useState();
  const [playerChosen, setPlayerChosen] = useState({});
  const [brandChosen, setBrandChosen] = useState([]);
  
  const playerInternalId = [`players_${indexOfForm}`];
  const brandInternalId = [`brands_${indexOfForm}`];

  const currentGroupId = formData.groups?.[indexOfForm];
  const ableToChooseBrand = openingState.choosingBrand && currentGroupId;
  const brandsSelected = _.get(formValues, `dynamicGroupStep.groups[${currentGroupId}].brands`, {});

  const getPlayerListByBrandId = brandId => {
    if (!brandId) {
      return null;
    }

    const brandExist = _.has(brandsSelected, brandId);
    const playersFromBrandId = _.get(brandsSelected, [brandId, 'players']);

    if (!brandExist || !playersFromBrandId) {
      return null;
    }

    return playersFromBrandId;
  };

  const handleBrandOnAdd = newBrandId => {
    if (!currentGroupId) {
      return;
    }

    const dynamicGroupStepUpdated = formValues.dynamicGroupStep;
    const currentGroup = _.get(dynamicGroupStepUpdated, ['groups', currentGroupId]);

    // Check brands object, if not exist => create new
    const isBrandListObject = _.isObject(currentGroup.brands);
    if (!isBrandListObject) {
      currentGroup.brands = {};
    }

    // If new brand is existed => do nothing!
    if (currentGroup.brands[newBrandId]) {
      return;
    }

    // Add new brand and init player list by empty object
    currentGroup.brands[newBrandId] = {
      players: {},
    };

    // Manual update into FormData
    form.change('dynamicGroupStep', dynamicGroupStepUpdated);
  };

  const handleBrandOnDelete = brandIdDeleted => {
    if (!currentGroupId) {
      return;
    }

    const dynamicGroupStepUpdated = formValues.dynamicGroupStep;
    const currentGroup = _.get(dynamicGroupStepUpdated, ['groups', currentGroupId]);

    // Check brands object, if not exist => create new
    const isBrandListObject = _.isObject(currentGroup.brands);
    if (!isBrandListObject) {
      currentGroup.brands = {};
    }

    // If new brand is existed => DELETE
    if (currentGroup.brands[brandIdDeleted]) {
      delete currentGroup.brands[brandIdDeleted];

      // Manual update into FormData
      form.change('dynamicGroupStep', dynamicGroupStepUpdated);

      const newPlayerChosen = {...playerChosen};
      delete newPlayerChosen[brandIdDeleted];
      setPlayerChosen(newPlayerChosen);

      if (activeBrand?.id && brandIdDeleted === activeBrand.id) {
        setActiveBrand(undefined);
      }
    }
  };

  const handleBrandMultipleChoice = values => {
    setBrandChosen(values);

    if (values.length === 1) {
      setActiveBrand({
        id: values[0],
        name: _.get(brandsSelected, [values[0], 'name']) || _.get(brandDataFetched, [values[0], 'name']),
      });
    }
  };

  const brandItemOnClick = brand => {
    if (brand?.id && brand?.name) {
      setActiveBrand({
        id: brand.id,
        name: brand.name,
      });
    }
  };

  const handlePlayerOnAdd = newPlayerId => {
    if (!currentGroupId || !activeBrand?.id) {
      return;
    }

    const dynamicGroupStepUpdated = formValues.dynamicGroupStep;

    // Check current brand is exist
    const currentBrand = _.get(dynamicGroupStepUpdated, ['groups', currentGroupId, 'brands', activeBrand.id]);
    if (!currentBrand || !_.isObject(currentBrand)) {
      return;
    }

    // Check players property
    if (!currentBrand.players) {
      currentBrand.players = {};
    }

    // Check player exist
    if (_.has(currentBrand.players, newPlayerId)) {
      return;
    }

    // Player is latest level => value is null
    currentBrand.players[newPlayerId] = null;

    form.change('dynamicGroupStep', dynamicGroupStepUpdated);
  };

  const handlePlayerOnDelete = playerIdDeleted => {
    if (!currentGroupId || !activeBrand?.id) {
      return;
    }

    const dynamicGroupStepUpdated = formValues.dynamicGroupStep;

    // Check current brand is exist
    const currentBrand = _.get(dynamicGroupStepUpdated, ['groups', currentGroupId, 'brands', activeBrand.id]);

    if (!currentBrand) {
      return;
    }

    // Check player exist
    if (_.has(currentBrand.players, playerIdDeleted)) {
      // Player is latest level => value is null
      delete currentBrand.players[playerIdDeleted];

      form.change('dynamicGroupStep', dynamicGroupStepUpdated);
    }
  };

  const handlePlayerMultipleChoice = values => {
    if (!activeBrand?.id) {
      return;
    }

    setPlayerChosen({
      ...playerChosen,
      [activeBrand.id]: values,
    });
  };

  const validateDuplicateGroup = value => {
    const { groups } = formValues;

    // Sometime choose the first group and then this one is undefined
    if (groups[indexOfForm] === undefined) {
      groups[indexOfForm] = value;
      form.change('groups', groups);
    }

    const numOfTheSameGroup = groups.reduce((counter, curItem) => (curItem === value ? counter + 1 : counter), 0);
    const isDuplicate = numOfTheSameGroup > 1;

    return isDuplicate ? translate('wa.exception.duplicateGroup') : '';
  };

  const initNewBrandByGroupId = groupId => {
    const dynamicGroupStep = formValues.dynamicGroupStep || {};

    // Groups must be object, If not => init with empty object
    if (!_.isObject(dynamicGroupStep?.groups)) {
      dynamicGroupStep.groups = {};
    }

    // Check exit Group ID, If not => create new
    if (!dynamicGroupStep.groups[groupId]) {
      dynamicGroupStep.groups[groupId] = {
        id: groupId,
        index: indexOfForm,
        brands: {},
      };

      form.change('dynamicGroupStep', dynamicGroupStep);
    }
  };

  // Init dynamicGroupStep when choose group in the first time
  useEffect(() => {
    if (!currentGroupId) {
      return;
    }

    initNewBrandByGroupId(currentGroupId);
  }, [currentGroupId]);

  // Reset list Brands & Players when choosing another group
  useEffect(() => {
    setActiveBrand(undefined);
    setOpeningState({
      ...openingState,
      choosingBrand: false,
    });
  }, [currentGroupId]);

  // Component Unmounted
  useEffect(() => function cleanUp() {
    const currentDynamicGroupStep = form.getState()?.values?.dynamicGroupStep;

    if (!_.isObject(currentDynamicGroupStep) || !_.isObject(currentDynamicGroupStep.groups)) {
      return;
    }

    const groupUnMounted = _.values(currentDynamicGroupStep.groups).find(item => item?.index === indexOfForm);

    if (!groupUnMounted?.id || !currentDynamicGroupStep.groups[groupUnMounted.id]) {
      return;
    }

    delete currentDynamicGroupStep.groups[groupUnMounted.id];
    form.change('dynamicGroupStep', currentDynamicGroupStep);

    // When use choose duplicate Group and remove one of them => happened!
    const groupIds = form.getState()?.values?.groups;
    if (groupIds && Array.isArray(groupIds)) {
      groupIds.forEach(gId => {
        if (!currentDynamicGroupStep.groups[gId]) initNewBrandByGroupId(gId);
      });
    }
  }, []);

  // Must be after cleanUp
  useEffect(() => {
    const dynamicGroupStepUpdated = formValues.dynamicGroupStep;

    if (typeof _.get(dynamicGroupStepUpdated, ['groups', currentGroupId, 'index']) !== 'undefined') {
      dynamicGroupStepUpdated.groups[currentGroupId].index = indexOfForm;
      form.change('dynamicGroupStep', dynamicGroupStepUpdated);
    }
  }, [indexOfForm]);

  const brandsInRule = _.get(formValues, `dynamicGroupStep.groups[${currentGroupId}].brands`);

  useEffect(() => {
    if (_.keys(brandsInRule).length > 0 && brandChosen.length === 0) {
      const brandIds = _.keys(brandsInRule);
      setBrandChosen(brandIds);
      setActiveBrand({
        id: brandIds[0],
        name: _.get(brandsSelected, [brandIds[0], 'name']) || _.get(brandDataFetched, [brandIds[0], 'name']),
      });
    }
  }, [brandsInRule]);

  useEffect(() => {
    if (brandChosen.length > 0 && !openingState.choosingBrand) {
      setOpeningState({
        ...openingState,
        choosingBrand: true,
      });
    }
  }, [brandChosen]);

  useEffect(() => {
    if (!activeBrand?.id) {
      return;
    }

    const players = _.get(formValues, `dynamicGroupStep.groups[${currentGroupId}].brands[${activeBrand.id}].players`);
    if (_.keys(players).length > 0 && _.isEmpty(playerChosen)) {
      setPlayerChosen({
        [activeBrand.id]: _.keys(players),
      });
    }
  }, [activeBrand?.id]);

  const playerIdsTest = _.get(formValues, `dynamicGroupStep.groups[${currentGroupId}].brands[${activeBrand?.id}].players`);

  const playerHeadText = activeBrand
    ? (
      <TableTitle>
        <span
          style={{
            marginRight: '4px',
          }}
        >
          {translate('ra.message.choosePlayerFor')}
        </span>
        <TableTitleBright
          theme={muiTheme}
          name={activeBrand.name}
        />
      </TableTitle>
    ) : <TableTitle>{translate('ra.message.choosePlayers')}</TableTitle>;

  return (
    <Box
      className="InboxRule_dynamic-rule-step"
      padding="0px 0px 28px"
    >
      <ReferenceInput
        label="resources.group.name"
        source={`groups.${indexOfForm}`}
        reference="group"
        fullWidth
        autoFocus
        perPage={100}
        validate={[required(), validateDuplicateGroup]}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <MuiButton
        variant="text"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => {
          setOpeningState({
            ...openingState,
            choosingBrand: true,
          });
        }}
        disabled={!_.get(formData, `groups[${indexOfForm}]`) || _.get(form.getState(), `errors.groups[${indexOfForm}]`)}
      >
        {translate('ra.action.chooseBrand')}
      </MuiButton>

      <Box
        display="flex"
        width="100% !important"
        border="1px solid #8080808c"
        borderRadius="4px"
        overflow="hidden"
      >
        {ableToChooseBrand && (
          <Box
            flex="1"
            borderRight="1px solid #8080808c"
          >
            <MultipleAutoComplete
              title={<TableTitle>{translate('ra.action.chooseBrands')}</TableTitle>}
              renderEndAdornment={brandId => {
                const players = getPlayerListByBrandId(brandId);
                return renderBrandItemEndAdornment(_.keys(players), muiTheme);
              }}
              label="resources.brand.name"
              source={brandInternalId.toString()}
              reference="brand"
              optionText="name"
              fieldComparison="id"
              listEmptyMessage={translate("ra.text.chooseBrandHasNoItem")}
              afterChange={handleBrandMultipleChoice}
              onDelete={handleBrandOnDelete}
              onAdd={handleBrandOnAdd}
              itemChosenOnClick={brandItemOnClick}
              activeItem={activeBrand?.id}
              listHeight={300}
              filter={{
                'group.id': currentGroupId ? `${FILTER_PREFIX_ON_VALUE.EQUALS}${formData.groups[indexOfForm]}` : undefined,
              }}
              itemsChosen={brandChosen}
              metadata={brandsSelected}
            />
          </Box>
        )}

        {ableToChooseBrand && activeBrand && (
          <Box
            flex="1"
            position="relative"
          >
            <MultipleAutoComplete
              key={activeBrand?.id}
              name="player"
              title={playerHeadText}
              label="resources.player.name"
              source={playerInternalId.toString()}
              reference="player"
              optionText="nativeId"
              fieldComparison="id"
              listEmptyMessage={translate("ra.text.choosePlayerHasNoItem", {
                brand_name: activeBrand?.name || ''
              })}
              filter={{
                groupId: currentGroupId ? `${FILTER_PREFIX_ON_VALUE.EQUALS}${currentGroupId}` : undefined,
                brandId: activeBrand?.id ? `${FILTER_PREFIX_ON_VALUE.EQUALS}${activeBrand.id}` : undefined,
              }}
              filterToQuery={value => ({
                nativeId: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
              })}
              afterChange={handlePlayerMultipleChoice}
              onAdd={handlePlayerOnAdd}
              onDelete={handlePlayerOnDelete}
              listHeight={300}
              itemsChosen={playerChosen?.[activeBrand?.id]}
              metadata={_.get(brandsSelected, [activeBrand?.id, 'players'])}
            />

            {/* {!activeBrand && <PlayerAttentionBox brandsSelected={brandsSelected} />} */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DynamicRuleStep = props => {
  const { formData } = props;

  const dynamicRuleRef = useRef({});

  return (
    <Box
      padding="0px 16px"
      margin="0 10px"
    >
      <ArrayInput
        className="arr-templates"
        source="groups"
        validate={[required()]}
        style={{
          background: 'red !important',
        }}
      >
        <SimpleFormIterator>
          <SimpleFormIteratorContent
            formData={formData}
            dynamicRuleRef={dynamicRuleRef}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

export default DynamicRuleStep;
