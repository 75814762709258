/* eslint-disable react/no-array-index-key */
import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useStyles } from '../guesser/wealth-filter.guesser.utils';
import { noop } from '../../../services/util';
import { useFilterFormContext } from '../../context/form/filterForm.context';

const CustomizedFilterWrapper = ({ children }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    filterValues, setFilters,
  } = useListContext();

  const { setValues } = useFilterFormContext();

  useEffect(
    () => function cleanUp() {
      setValues({});
      setFilters({});
    },
    [],
  );

  return (
    <Box className={classes.filterContainer}>
      <Form
        initialValues={{
          ...filterValues,
        }}
        onSubmit={noop}
        render={({ values: formValues }) => (
          <form className={classes.formStyle}>
            <Grid
              container
              spacing={1}
              display="flex"
              sx={{
                gap: '1rem',
                padding: '0 2px',
              }}
            >
              {children
                && React.Children.map(children, (child, idx) => (
                  <Grid
                    item
                    key={idx}
                    xs={12}
                    sm="auto"
                  >
                    {React.cloneElement(child)}
                  </Grid>
                ))}
            </Grid>
            <Grid>
              <Box
                textAlign="right"
                marginTop={2}
              >
                <Button
                  onClick={() => setFilters(formValues)}
                  className={classes.btn}
                  disabled={JSON.stringify(formValues) === JSON.stringify(filterValues)}
                >
                  {translate('ra.action.search')}
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default CustomizedFilterWrapper;

CustomizedFilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
