/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { useReloadContext } from '../../base/context/reload/reload.context';
import { useSchema } from '../../base/hooks';
import ActionLink from './components/action.link';

function GameList(props) {
  const { setValuesContext } = useReloadContext();
  const [requestFields, setRequestFields] = useState([]);

  useEffect(
    () => () => {
      setValuesContext({});
    },
    [],
  );

  const {
    api, ref,
  } = useSchema();

  useEffect(() => {
    if (api) {
      const { paths } = api;

      const basePath = '/game';
      const target = `${basePath}/{id}`;
      let requestRef = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;

      requestRef = requestRef.$ref || requestRef.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

      const requestComponent = ref.get(requestRef);

      const { properties } = requestComponent;

      const writableFields = Object.keys(properties);

      setRequestFields(writableFields);
    }
  }, [api]);

  return (
    <>
      <WealthListGuesser
        {...props}
        excludeFilterInputs={['isAvailable']}
        extraActions={(
          <ActionLink
            label="ra.field.action"
            requestFields={requestFields}
            {...props}
          />
        )}
        excludeFields={['enabled', 'isAvailable']}
      />
    </>
  );
}

export default GameList;
