/* eslint-disable react/prop-types */
import React from 'react';
import Type from 'prop-types';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import ButtonUnlockShow from './ButtonUnlockShow';

export const ActionMenu = props => {
  const { record } = props;

  return (
    <ToolbarMenu {...props}>
      <ButtonUnlockShow
        playerId={record?.id}
        nativeId={record?.nativeId}
        shouldShowBtn={record?.lockSpinning}
      />
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};
