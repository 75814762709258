import PartnerShow from './partner.show';
import PartnerCreate from './partner.create';
import PartnerListPage from './partner.list';
import PartnerEdit from './partner.edit';

export default {
  list: PartnerListPage,
  edit: PartnerEdit,
  create: PartnerCreate,
  show: PartnerShow,
};
