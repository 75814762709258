/* eslint-disable no-nested-ternary, no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  Box,
  Chip,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ContentCreate from '@material-ui/icons/Create';
import ActionList from '@material-ui/icons/List';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { linkToRecord } from 'ra-core';
import {
  Button,
  EditButton,
  ListButton,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import CustomTooltip from '../../custom-tooltip';
import { MenuField } from '../../ra/fields';
import { useAuthUser, useViewType } from '../../../hooks';
import { Workflow } from '../workflow';
import { canIEditWith } from '../../../../services/provider/rbacProvider';

const ToolbarMenuDisplayType = Object.freeze({
  context: 'context',
  button: 'button',
});

const useStyles = makeStyles(theme => ({
  groupChip: {
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  topToolbarStyle: {
    whiteSpace: 'nowrap',
  },
}));

const ToolbarMenu = props => {
  const {
    basePath,
    resource,
    record,
    hasList,
    hasEdit,
    children,
    extraOptions,
    displayType,
    isCustom,
    metaDataComponents,
    forceEditAble,
    styleWrapper,
  } = props;

  const { isEditView } = useViewType();
  const classes = useStyles();
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState();
  const theme = useTheme();
  const user = useAuthUser();
  const screenLg = useMediaQuery(theme.breakpoints.up('lg'));

  let editable = canIEditWith(resource, {
    defaultCondition: hasEdit && !isEditView,
    record,
    user,
  });

  if (
    typeof forceEditAble === 'boolean'
    || typeof forceEditAble === 'function'
  ) {
    editable = typeof forceEditAble === 'function'
      ? forceEditAble(record)
      : forceEditAble;
  }

  const openExtraMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeExtraMenu = () => {
    setAnchorEl(false);
  };

  const handleClick = action => {
    closeExtraMenu();
    if (action?.onClick instanceof Function) {
      action.onClick();
    }
  };

  const defaultOptions = useMemo(() => {
    const results = [];

    if (!screenLg) {
      if (hasList) {
        results.push({
          name: translate('ra.action.list'),
          condition: true,
          icon: <ActionList />,
          to: basePath,
        });
      }

      if (editable) {
        results.push({
          name: translate('ra.action.edit'),
          condition: true,
          icon: <ContentCreate />,
          to: linkToRecord(basePath, record && record.id),
        });
      }
    }

    return results;
  }, [
    hasList,
    hasEdit,
    basePath,
    record,
    translate,
    screenLg,
    isEditView,
    user,
  ]);

  const defaultButtons = useMemo(() => {
    if (!screenLg) return null;

    return (
      <>
        {hasList && (
          <CustomTooltip
            title={translate('ra.action.list')}
            placement="top"
          >
            <ListButton
              basePath={basePath}
              record={record}
              label=""
            />
          </CustomTooltip>
        )}
        {editable && (
          <CustomTooltip
            title={translate('ra.action.edit')}
            placement="top"
          >
            <EditButton
              basePath={basePath}
              record={record}
              label=""
            />
          </CustomTooltip>
        )}
      </>
    );
  }, [
    hasList,
    hasEdit,
    basePath,
    record,
    translate,
    screenLg,
    isEditView,
    user,
  ]);

  const contextMenuItems = useMemo(
    () => [...defaultOptions, ...extraOptions],
    [defaultOptions, extraOptions],
  );

  const renderBaseChips = () => {
    if (!record?.enabled) {
      return null;
    }

    return (
      <Chip
        key="enabled"
        label={translate(`resources.${resource}.fields.enabled`)}
        color="primary"
      />
    );
  };

  const renderContextMenu = () => (
    <>
      <MenuField
        record={record}
        onClick={openExtraMenu}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={closeExtraMenu}
      >
        {contextMenuItems.map(
          action => action.condition && (
            <MenuItem
              key={action.name}
              component={action?.to ? Link : 'span'}
              to={action?.to}
              onClick={() => handleClick(action)}
            >
              {action.icon && (
                <ListItemIcon>
                  {React.cloneElement(action.icon, {
                    fontSize: 'small',
                  })}
                </ListItemIcon>
              )}
              <Typography variant="inherit">{action.name}</Typography>
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );

  const renderButtonMenu = () => extraOptions?.map(
    option => option.condition && (
      <Button
        key={option.name}
        startIcon={option.icon}
        label={option.name}
        to={option.to || ''}
        {...(option.onClick instanceof Function
          ? {
            onClick: () => option.onClick(),
          }
          : {
            component: Link,
          })}
      />
    ),
  );

  const listComponentChip = [...metaDataComponents];

  return (
    <TopToolbar className={classes.topToolbarStyle}>
      {listComponentChip?.length ? (
        <div className={classes.groupChip}>{listComponentChip}</div>
      ) : null}

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          ...styleWrapper,
        }}
      >
        {children}
        {defaultButtons}
        {!isCustom && (
          <Workflow
            record={record}
            resource={resource}
          />
        )}
        {displayType === ToolbarMenuDisplayType.context
        && contextMenuItems.length ? (
            contextMenuItems.every(option => option.condition === false) ? (
              <></>
            ) : (
              renderContextMenu()
            )
          ) : (
            renderButtonMenu()
          )}
      </Box>
    </TopToolbar>
  );
};

ToolbarMenu.propTypes = {
  forceEditAble: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object,
  children: PropTypes.node,
  hasList: PropTypes.bool,
  hasEdit: PropTypes.bool,
  extraOptions: PropTypes.arrayOf(
    PropTypes.shape({
      condition: PropTypes.bool,
      to: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.object,
      onClick: PropTypes.func,
    }),
  ),
  displayType: PropTypes.oneOf([
    ToolbarMenuDisplayType.context,
    ToolbarMenuDisplayType.button,
  ]),
  metaDataComponents: PropTypes.array,
  isCustom: PropTypes.bool,
  styleWrapper: PropTypes.object,
};

ToolbarMenu.defaultProps = {
  forceEditAble: null,
  extraOptions: [],
  hasList: false,
  children: null,
  hasEdit: false,
  displayType: ToolbarMenuDisplayType.context,
  record: undefined,
  metaDataComponents: [],
  isCustom: false,
  styleWrapper: {},
};

export default ToolbarMenu;
