import React, { useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { useTranslate } from 'ra-core';
import { OPTIMAL_BREAK_POINT_MOBILE, SIDE_TYPE } from './transfer-list.constant';
import Loading from './transfer-list.loading';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 400,
    margin: 'auto',
    [theme.breakpoints.down(OPTIMAL_BREAK_POINT_MOBILE)]: {
      height: 'auto',
      flexDirection: 'column',
    },
  },
  h100percent: {
    height: '100%',
  },
  cardStyle: {
    height: '100%',
    minWidth: 258,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(OPTIMAL_BREAK_POINT_MOBILE)]: {
      minWidth: 258,
    },
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  listWrap: {
    flex: 1,
    padding: '0 !important',
    [theme.breakpoints.down(OPTIMAL_BREAK_POINT_MOBILE)]: {
      width: '100%',
    },
  },
  list: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    [theme.breakpoints.down(OPTIMAL_BREAK_POINT_MOBILE)]: {
      maxHeight: 256,
    },
  },
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  moveButtons: {
    display: 'flex',
    flexDirection: 'column',
    '& > button': {
      minWidth: 40,
      margin: theme.spacing(2, 1.5),
      padding: theme.spacing(10, 0),
    },
    [theme.breakpoints.down(OPTIMAL_BREAK_POINT_MOBILE)]: {
      flexDirection: 'row',
      '& > button': {
        margin: theme.spacing(2, 1.5),
        padding: theme.spacing(1, 10),
      },
    },
  },
  disableItem: {
    '& > span': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > svg': {
      fontSize: '1.2rem',
      marginLeft: '5px',
    },
  },
}));

function itemsOfBoth(b) {
  return ({ value }) => {
    const valueOfB = b.map(item => item.value);
    return valueOfB.indexOf(value) !== -1;
  };
}

function itemsExcludeB(b) {
  return ({ value }) => {
    const valueOfB = b.map(item => item.value);
    return valueOfB.indexOf(value) === -1;
  };
}

function not(a, b) {
  return a.filter(itemsExcludeB(b));
}

function intersection(a, b) {
  return a.filter(itemsOfBoth(b));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function TransferList(props) {
  const {
    style,
    itemSourceName,
    onChange,
    leftLoading,
    rightLoading,
    classes: {
      leftHead: leftHeadClass = '',
      leftContent: leftContentClass = '',
      rightHead: rightHeadClass = '',
      rightContent: rightContentClass = '',
    },
    leftOptions: {
      initValues: leftInitValue = [],
      title: leftTitle = '',
    },
    rightOptions: {
      initValues: rightInitValue = [],
      title: rightTitle = '',
    },
  } = props;

  const classes = useStyles();
  const muiTheme = useTheme();
  const translate = useTranslate();
  const matchMobileBreakPoint = useMediaQuery(
    muiTheme.breakpoints.down(OPTIMAL_BREAK_POINT_MOBILE),
  );

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const initValueState = useRef({
    left: null,
    right: null,
  });

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const valueCheckedList = checked.map(checkItem => checkItem.value);

  const handleToggle = item => () => {
    const currentIndex = valueCheckedList.indexOf(item.value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const leftItems = not(left, leftChecked);
    setLeft(leftItems);

    // setRight(right.concat(leftChecked));
    const rightItems = leftChecked.concat(right);
    setRight(rightItems);

    setChecked(not(checked, leftChecked));
    onChange(leftItems, rightItems);
  };

  const handleCheckedLeft = () => {
    const leftItems = left.concat(rightChecked);
    setLeft(leftItems);

    const rightItems = not(right, rightChecked);
    setRight(rightItems);

    setChecked(not(checked, rightChecked));
    onChange(leftItems, rightItems);
  };

  const customList = ({
    title, items, sideType, headClassName, contentClass, loading,
  }) => {
    const subHeader = translate('ra.text.selected')
      .replace('%{smart_count}', `${numberOfChecked(items)}/${items.length}`);

    const emptyBox = (
      <Box
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="90%"
      >
        <Box color="#8A8A8A">
          {translate('ra.text.listEmpty')}
        </Box>
      </Box>
    );

    const generateTooltipMessage = () => {
      const gameTranslated = translate(itemSourceName);

      return translate('ra.text.disableTooltipMessage', {
        smart_text: gameTranslated.toLowerCase(),
      });
    };

    let isLoading = false;
    let isListEmpty = false;

    if (
      (sideType === SIDE_TYPE.left && loading.left)
      || (sideType === SIDE_TYPE.right && loading.right)
    ) {
      isLoading = true;
    }

    if (!isLoading && items.length === 0) {
      isListEmpty = true;
    }

    return (
      <Card
        className={classes.cardStyle}
        style={{
          ...(style || {}),
        }}
      >
        <CardHeader
          className={clsx(classes.cardHeader, headClassName)}
          avatar={(
            <Checkbox
              color="primary"
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length
                && numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
            />
          )}
          title={title}
          subheader={subHeader}
        />
        <Divider />
        <List
          dense
          role="list"
          component="div"
          className={clsx(classes.list, contentClass)}
        >
          {isLoading && <Loading sideType={sideType} />}
          {isListEmpty ? emptyBox : null}

          {items.map(item => {
            const labelId = `transfer-list-all-item-${item.value}-label`;
            return (
              <ListItem
                key={item.value}
                classes={{
                  root: classes.listItem,
                }}
                button
                onClick={handleToggle(item)}
              >
                <ListItemIcon>
                  <Checkbox
                    disableRipple
                    checked={valueCheckedList.indexOf(item.value) !== -1}
                    tabIndex={-1}
                    style={{
                      color: muiTheme.palette.primary.light,
                    }}
                  />
                </ListItemIcon>
                {item.enabled ? (
                  <ListItemText
                    id={labelId}
                    primary={item.label}
                  />
                ) : (
                  <Box
                    color={muiTheme.palette.error.main}
                    display="flex"
                    alignItems="center"
                  >
                    <ListItemText
                      id={labelId}
                      primary={item.label}
                      classes={{
                        root: classes.disableItem,
                      }}
                    />
                    <Tooltip
                      placement="top-start"
                      title={generateTooltipMessage()}
                    >
                      <Box className={classes.icon}>
                        <ErrorIcon />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  useEffect(() => {
    if (!leftInitValue.length || initValueState.current.left) {
      return;
    }

    setLeft(leftInitValue);
    initValueState.current.left = true;
  }, [leftInitValue]);

  useEffect(() => {
    if (!rightInitValue.length || initValueState.current.right) {
      return;
    }

    setRight(rightInitValue);
    initValueState.current.right = true;
  }, [rightInitValue]);

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
    >
      <Grid
        item
        className={clsx(classes.h100percent, classes.listWrap)}
      >
        {customList({
          title: leftTitle,
          items: left,
          sideType: SIDE_TYPE.left,
          headClassName: leftHeadClass,
          contentClass: leftContentClass,
          loading: {
            left: leftLoading,
          },
        })}
      </Grid>
      <Grid item>
        <Grid
          container
          className={classes.moveButtons}
          direction="column"
          alignItems="center"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            {matchMobileBreakPoint ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            {matchMobileBreakPoint ? <ExpandLessIcon /> : <ChevronLeftIcon />}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(classes.h100percent, classes.listWrap)}
      >
        {customList({
          title: rightTitle,
          items: right,
          sideType: SIDE_TYPE.right,
          headClassName: rightHeadClass,
          contentClass: rightContentClass,
          loading: {
            right: rightLoading,
          },
        })}
      </Grid>
    </Grid>
  );
}

TransferList.defaultProps = {
  itemSourceName: '',
  leftOptions: {},
  rightOptions: {},
  onChange: f => f,
  leftLoading: false,
  rightLoading: false,
  classes: {},
  style: {},
};

TransferList.propTypes = {
  itemSourceName: PropTypes.string,
  leftOptions: PropTypes.shape({
    initValues: PropTypes.array,
    title: PropTypes.node,
  }),
  rightOptions: PropTypes.shape({
    initValues: PropTypes.array,
    title: PropTypes.node,
  }),
  classes: PropTypes.shape({
    leftHead: PropTypes.string,
    leftContent: PropTypes.string,
    rightHead: PropTypes.string,
    rightContent: PropTypes.string,
  }),
  onChange: PropTypes.func,
  leftLoading: PropTypes.bool,
  rightLoading: PropTypes.bool,
  style: PropTypes.object,
};

export default memo(TransferList);
