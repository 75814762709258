/* eslint-disable */
import React from "react";
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';

const BetSettingDefaultList = (props) => {
  return (
    <WealthListGuesser
      {...props}
      excludeFields={['brand', 'group']}
      excludeFilterInputs={['brand', 'group']}
    />
  );
};

export default BetSettingDefaultList;
