import React from 'react';
import { CardContent, CardHeader, Box, Card } from '@material-ui/core';
import moment from 'moment';
import { useLocale, useTranslate } from 'react-admin';

const ChartUnderConstruction = () => {
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <Box
      component={Card}
      height="100%"
    >
      <CardHeader
        title={translate('wa.common.chart')}
        subheader={(
          <span
            style={{
              fontSize: '12px',
            }}
          >
            {moment().locale(locale).format('LLLL').toString()}
          </span>
        )}
      />

      <CardContent>
        {translate('ra.text.constructionNote')}
        ...
      </CardContent>
    </Box>
  );
};

export default ChartUnderConstruction;
