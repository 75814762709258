/* eslint-disable no-use-before-define,import/no-cycle, react/no-array-index-key */
import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';

const ChartTitle = ({
  sx, ...otherProps
}) => {
  const { palette } = useTheme();

  const translate = useTranslate();

  return (
    <Box
      color={palette.primary.light}
      fontWeight={600}
      sx={{
        fontSize: {
          xs: 24,
          lg: 40,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {translate('resources.dashboard.fields.title')}
    </Box>
  );
};

ChartTitle.propTypes = {
  sx: PropTypes.object,
};

ChartTitle.defaultProps = {
  sx: {},
};

export default ChartTitle;
