const arrayToSelector = values => values.join(' ');

const pickerCellInnerSelector = arrayToSelector([
  '.ant-picker-cell-in-view.ant-picker-cell-selected',
  '.ant-picker-cell-inner,',
  '.ant-picker-cell-in-view.ant-picker-cell-range-start',
  '.ant-picker-cell-inner,',
  '.ant-picker-cell-in-view.ant-picker-cell-range-end',
  '.ant-picker-cell-inner',
]);

export const themeAntDesignCheatEnv = {
  '.ant-picker-range .ant-picker-active-bar': {
    background: '#036D53',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before': {
    borderColor: '#29D76B !important',
  },
  '.ant-picker-ranges .ant-picker-preset > span': {
    color: '#036D53 !important',
    borderColor: '#29D76B !important',
    background: '#E6FFF1 !important',
  },
  [pickerCellInnerSelector]: {
    background: '#036D53',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-in-range::before': {
    background: '#E6FFF1',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-in-range::after': {
    background: '#E6FFF1',
  },
  '.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner': {
    background: '#E6FFF1',
  },
  '.ant-btn-primary': {
    background: '#036D53',
    borderColor: '#036D53',
  },
  '.ant-btn-primary:hover': {
    background: '#036D53',
    borderColor: '#036D53',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:after': {
    background: '#036D53 !important',
    borderColor: '#036D53 !important',
  },
  '.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:before': {
    background: '#036d534d !important',
    borderColor: '#036D53 !important',
  },
  '.ant-picker-cell-range-hover-start, .ant-picker-cell-range-hover, .ant-picker-cell-range-hover-end': {
    borderColor: '#036D53 !important',
  },
  '.ant-picker-now .ant-picker-now-btn': {
    color: '#036D53 !important',
  },
};
