import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import InfinitePagination from '../../../base/components/ra/list/InfinitePagination';
import { cacheLargeWinByPage, updateCurrentLargeWinPage } from '../../../services/redux/largeWin/largeWin.action';
import resourceSlug from '../../../constant/resource-slug';
import { sanitizeObject } from '../../../services/util';

const LargeWinPagination = () => {
  const {
    largeWinList, currentLargeWinPage,
  } = useSelector(({ largeWin }) => largeWin);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();

  const disableNext = useMemo(
    () => !largeWinList?.[currentLargeWinPage]?.nextPagination,
    [currentLargeWinPage, largeWinList],
  );

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!largeWinList[newPage]) {
          const nextPagination = largeWinList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(resourceSlug.LARGE_WIN, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheLargeWinByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentLargeWinPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [largeWinList, currentLargeWinPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentLargeWinPage === 1}
      onNextPage={() => {
        handlePageChange(currentLargeWinPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentLargeWinPage - 1);
      }}
      currentPage={currentLargeWinPage}
    />
  );
};

export default LargeWinPagination;
