import React from 'react';
import { ReferenceInput as RaReferenceInput } from 'react-admin';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';

import { useApiProperties } from '../../../hooks';
import { withStandardInputProps } from '../../../hoc/with-standard-input-props';
import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';

const ReferenceInput = ({
  children,
  onChange,
  afterOnChange,
  ...rest
}) => {
  const {
    filterToQuery,
    optionText,
    source,
  } = rest;

  const { getPropertiesByFieldName } = useApiProperties();
  const properties = getPropertiesByFieldName(source);

  const newOptionText = properties.optionText || optionText || 'name';

  const form = useForm();

  const handleOnChange = event => {
    if (typeof onChange === 'function') {
      onChange(event);
    }

    if (typeof afterOnChange === 'function') {
      afterOnChange(event, form);
    }
  };

  const handleFilterToQuery = value => ({
    [newOptionText]: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
  });

  return (
    <RaReferenceInput
      {...rest}
      onChange={handleOnChange}
      filterToQuery={typeof filterToQuery === 'function' ? filterToQuery : handleFilterToQuery}
    >
      {children}
    </RaReferenceInput>
  );
};

ReferenceInput.propTypes = {
  onChange: PropTypes.func,
  afterOnChange: PropTypes.func,
  optionText: PropTypes.string,
  children: PropTypes.node,
};

ReferenceInput.defaultProps = {
  onChange: f => f,
  afterOnChange: f => f,
  children: null,
  optionText: undefined,
};

export default withStandardInputProps(ReferenceInput);
