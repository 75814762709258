import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { regex } from 'react-admin';
import StringInput from './StringInput';
/* eslint-disable react/require-default-props */

import { REGEX_URL } from '../../../../services/util/validate/regularExpression';

const UrlInput = ({
  validate, ...otherProps
}) => {
  const urlValidate = useMemo(() => {
    const urlValidator = [regex(REGEX_URL, 'wa.exception.invalidUrl')];
    if (Array.isArray(validate)) {
      return [...validate, ...urlValidator];
    }
    if (validate) {
      return [validate, ...urlValidator];
    }
    return urlValidator;
  }, [validate]);

  return (
    <StringInput
      validate={urlValidate}
      {...otherProps}
    />
  );
};

export default UrlInput;

UrlInput.propTypes = {
  validate: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
};
