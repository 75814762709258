import { Link } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';

const RefField = props => {
  const {
    source, reference: defReference, children, record, resource,
  } = props;

  const realSourcePaths = source
    ?.split('.')
    ?.filter((_, i) => i !== source?.split('.').length - 1);

  const realSource = realSourcePaths.join('.');

  const recordId = get(record, source);
  const reference = defReference || (realSourcePaths?.length && realSourcePaths[0]) || resource;

  return (
    <Link
      {...recordId && {
        href: `/#/${reference}/${recordId}/show`,
      }}
    >
      {React.Children.map(children, element => React.cloneElement(element, {
        ...props,
        ...element.props,
        source: element?.props?.source === 'id' ? 'id' : `${realSource}.${element.props.source}`,
        record,
      }))}
    </Link>
  );
};

RefField.propTypes = {
  children: PropTypes.element.isRequired,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
};
RefField.defaultProps = {
  reference: '',
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
  record: {},
  resource: undefined,
};

export default RefField;
