import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const ChartLoading = () => (
  <Box
    flexGrow={1}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Box>
);

export default ChartLoading;
