/* eslint-disable no-unused-vars */
import React from 'react';
import { BulkDeleteButton } from 'react-admin';
import PropTypes from 'prop-types';
import AddRelatedDataButton from './addRelatedData.button';
import RemoveRelatedDataButton from './removeRelatedData.button';
import RefManyBulkActionTypes from '../refManyBulkActionTypes.constant';

const BulkActionButtons = ({
  refManyEditProps,
  refManyEditGuesser,
  hasDelete,
  hasCreate,
  hasEdit,
  ...rest
}) => {
  const { refManyBulkAction } = refManyEditProps || {};

  return (
    <>
      {/* write custom Bulk Action here */}
      {/* https://marmelab.com/react-admin/List.html#bulk-action-buttons */}
      {refManyEditGuesser && (
        <>
          {refManyBulkAction === RefManyBulkActionTypes.ADD && (
            <AddRelatedDataButton
              refManyEditProps={refManyEditProps}
              {...rest}
            />
          )}
          {refManyBulkAction === RefManyBulkActionTypes.REMOVE && (
            <RemoveRelatedDataButton
              refManyEditProps={refManyEditProps}
              {...rest}
            />
          )}
        </>
      )}
      {hasDelete && <BulkDeleteButton {...rest} />}
    </>
  );
};

BulkActionButtons.propTypes = {
  hasDelete: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasCreate: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  refManyEditProps: PropTypes.shape({
    editingResource: PropTypes.string,
    editingResourceId: PropTypes.string,
    editingResourceNameAsProperty: PropTypes.string,
    referenceResource: PropTypes.string,
    referenceResourceNameAsProperty: PropTypes.string,
    isManyToMany: PropTypes.bool,
    refManyBulkAction: PropTypes.string,
    onToggleDrawer: PropTypes.func,
  }),
  refManyEditGuesser: PropTypes.bool,
};

BulkActionButtons.defaultProps = {
  hasDelete: false,
  hasEdit: false,
  hasCreate: false,
  refManyEditGuesser: false,
};

export default BulkActionButtons;
