import axios from 'axios';
import axiosRetry from 'axios-retry';
import { AXIOS_RETRIES } from '../../constant';

export const getAxiosRetryInstance = ({
  baseURL = '/',
  retryConfig = {},
  axiosConfig = {},
} = {}) => {
  const axiosRetryInstance = axios.create({
    baseURL,
    ...axiosConfig,
  });
  axiosRetry(axiosRetryInstance, {
    retries: AXIOS_RETRIES,
    ...retryConfig,
  });
  return axiosRetryInstance;
};
