import {
  useDataProvider,
  useRedirect,
  useUnselectAll,
  useNotify,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import { useEffect } from 'react';

let bulk = {};

const useBulk = writableFields => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const {
    pathname, search,
  } = useLocation();

  const setBulkPayload = payload => {
    bulk = {
      ...bulk,
      ...payload,
    };
  };

  const clearBulkPayload = () => {
    bulk = {};
  };

  const generateQueryString = source => {
    const { resource } = bulk;

    let newSource;
    if (writableFields?.length > 0) {
      newSource = {};
      writableFields.forEach(fieldName => {
        newSource[fieldName] = source[fieldName];
      });
    } else {
      newSource = source;
    }

    const queryString = `/${resource}/create?${stringify({
      source: JSON.stringify(newSource),
    })}`;
    return queryString;
  };

  /**
   * @getClonableRecord will request API and get the record data from an id.
   * The record id will be the first one in queue.
   * @result will return an object includes record data and query string.
   */
  const getClonableRecord = async () => {
    const {
      resource, selectedIds,
    } = bulk;

    try {
      const id = selectedIds[0];
      const { data } = await dataProvider.getOne(resource, {
        id,
      });

      // remove the existing id because we're cloning, it's equal to create (without any id)
      delete data.id;
      delete data.docStatus;
      delete data.created;
      delete data.updated;
      delete data.buildIn;

      // generate query string to prefill data fields into create form
      const queryString = generateQueryString(data);

      return {
        data,
        queryString,
      };
    } catch (ex) {
      return null;
    }
  };

  /**
   * @prefillCloneRecord will extract data and query string from @getClonableRecord.
   * Finally, it should redirect to cloning page (it's simple a create page with prefill data).
   */
  const prefillCloneRecord = async () => {
    const {
      resource, selectedIds,
    } = bulk;

    if (!resource || !(selectedIds?.length > 0)) return;

    try {
      const { queryString } = await getClonableRecord(selectedIds);
      setBulkPayload({
        queryString,
      });
      redirect(`/${resource}`); // do the trick: reset create form before prefilling the new one, otherwise, it won't work. :)
      redirect(queryString);
    } catch (ex) {
      // TODO: we may need to handle for specific case as:
      // network error (should retry or do sth),
      // or the data isn't exist (then skip to next one)... etc
    }
  };

  /**
   * @startCloneRecords should be called anytime we want to start cloning bulk of records.
   */
  const startCloneRecords = payload => {
    if (!payload) return;
    setBulkPayload({
      ...payload,
      queryString: '',
      count: 0,
      total: payload.selectedIds?.length || 0,
    });
    prefillCloneRecord();
  };

  /**
   * @completeCloningCurrentRecord should be called when finish cloning a record.
   * It should redirect to the next one or finalize the bulk clone process.
   */
  const completeCloningCurrentRecord = () => {
    const {
      resource, count,
    } = bulk;
    let { selectedIds } = bulk;

    if (selectedIds?.length > 1) {
      // move to next record
      selectedIds = selectedIds.slice(1);
      setBulkPayload({
        selectedIds,
        count: count + 1,
      });
      prefillCloneRecord();
    } else {
      // finish
      clearBulkPayload();
      unselectAll(resource);
      redirect(`/${resource}`);
      notify('Updated!', 'success');
    }
  };

  const getTotalClonedRecords = () => ({
    count: bulk?.count,
    total: bulk?.total,
  });

  const updateQueryStringBasedOnWritableFields = () => {
    if (!search) return;

    const queryString = search.replace('?', '');
    const queryObject = parse(queryString);
    if (!queryObject.source) return;

    const source = JSON.parse(queryObject.source);
    const newQueryString = generateQueryString(source);
    setBulkPayload({
      newQueryString,
    });
    redirect(newQueryString);
  };

  const isClonable = () => {
    const {
      selectedIds, queryString,
    } = bulk;
    // prevent wrong state by comparing currentPath (route) with current cloning record's query string
    const currentPath = `${pathname}${search}`;
    return selectedIds?.length > 0 && queryString === currentPath;
  };

  useEffect(() => {
    if (writableFields?.length > 0) {
      updateQueryStringBasedOnWritableFields({
        writableFields,
      });
    }
  }, [writableFields]);

  return {
    startCloneRecords,
    completeCloningCurrentRecord,
    isClonable,
    getTotalClonedRecords,
  };
};

export default useBulk;
