import { useRef } from 'react';
import { useTranslate } from 'ra-core';
import useTranslateSchema from '../../hooks/useTranslateSchema';

export const useBetSettingPickerTranslation = () => {
  const translate = useTranslate();
  const translateSchema = useTranslateSchema();

  const betSizeName = translate('resources.bet-setting.fields.betSizes');
  const betSizeDefaultName = translate('ra.field.betSizeDefault');

  const betLevelName = translate('resources.bet-setting.fields.betLevels');
  const betLevelDefaultName = translate('ra.field.betLevelDefault');

  const textRef = useRef({
    betSize: {
      isRequired: '',
      defaultIsRequired: '',
      name: '',
      mustGreatThanZero: '',
      onlyTwoDigitsAfter: '',
      existed: '',
    },
    betLevel: {
      isRequired: '',
      defaultIsRequired: '',
      name: '',
      mustGreatThanZero: '',
      onlyTwoDigitsAfter: '',
      existed: '',
    },
    totalBet: '',
    totalBetExceedLimit: '',
    baseBet: '',
    betSizeDefault: '',
    betSizeDefaultIsRequired: '',
    betLevelDefault: '',
    betLevelDefaultIsRequired: '',
  });

  const translateIsRequired = fieldName => translateSchema({
    name: 'ra.validation.fieldIsRequired',
    params: {
      smart_name: fieldName,
    },
  });

  textRef.current = {
    betSize: {
      name: betSizeName,
      isRequired: translateIsRequired(betSizeName),
      mustGreatThanZero: translateSchema({
        name: 'ra.validation.mustGreatThanZero',
        params: {
          smart_name: betSizeName,
        },
      }),
      onlyTwoDigitsAfter: translateSchema({
        name: 'ra.validation.onlyTwoDigitsAfter',
        params: {
          smart_name: betSizeName,
        },
      }),
      existed: translateSchema({
        name: 'ra.notification.fieldExisted',
        params: {
          smart_name: betSizeName,
        },
      }),
    },
    betLevel: {
      name: betLevelName,
      isRequired: translateIsRequired(betLevelName),
      mustGreatThanZero: translateSchema({
        name: 'ra.validation.mustGreatThanZero',
        params: {
          smart_name: betLevelName,
        },
      }),
      onlyTwoDigitsAfter: translateSchema({
        name: 'ra.validation.onlyTwoDigitsAfter',
        params: {
          smart_name: betLevelName,
        },
      }),
      existed: translateSchema({
        name: 'ra.notification.fieldExisted',
        params: {
          smart_name: betLevelName,
        },
      }),
    },
    totalBet: translate('ra.field.totalBet'),
    totalBetExceedLimit: translate('wa.exception.totalBetExceedLimit'),
    baseBet: translate('ra.field.baseBet'),
    betSizeDefault: betSizeDefaultName,
    betSizeDefaultIsRequired: translateIsRequired(betSizeDefaultName),
    betLevelDefault: betLevelDefaultName,
    betLevelDefaultIsRequired: translateIsRequired(betLevelDefaultName),
  };

  return textRef.current;
};
