/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DeleteWithUndoButton from './delete-with-undo.button';
import DeleteWithConfirmButton from './delete-with-confirm.button';

function DeleteButton(props) {
  const {
    record, undoable,
  } = props;
  if (!record || record.id == null) {
    return null;
  }
  return undoable ? (
    <DeleteWithUndoButton {...props} />
  ) : (
    <DeleteWithConfirmButton {...props} />
  );
}

DeleteButton.propTypes = {
  label: PropTypes.string,
  record: PropTypes.any,
  undoable: PropTypes.bool,
};

const PureDeleteButton = memo(
  DeleteButton,
  (props, nextProps) => (props.record && nextProps.record
    ? props.record.id === nextProps.record.id
      : props.record == nextProps.record) && // eslint-disable-line
    props.basePath === nextProps.basePath,
);

export default PureDeleteButton;
