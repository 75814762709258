import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import InfinitePagination from '../../../base/components/ra/list/InfinitePagination';
import { cacheWagerByPage, updateCurrentWagerPage } from '../../../services/redux/wager/wager.action';
import resourceSlug from '../../../constant/resource-slug';
import { sanitizeObject } from '../../../services/util';

const WagerPagination = () => {
  const {
    wagerList, currentWagerPage,
  } = useSelector(({ wager }) => wager);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();

  const disableNext = useMemo(() => !wagerList?.[currentWagerPage]?.nextPagination, [currentWagerPage, wagerList]);

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!wagerList[newPage]) {
          const nextPagination = wagerList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(resourceSlug.WAGER, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheWagerByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentWagerPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [wagerList, currentWagerPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentWagerPage === 1}
      onNextPage={() => {
        handlePageChange(currentWagerPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentWagerPage - 1);
      }}
      currentPage={currentWagerPage}
    />
  );
};

export default WagerPagination;
