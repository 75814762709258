/* eslint-disable */
import React, { useEffect } from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Card,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useInput, useTranslate } from "react-admin";
import queryString from "querystring";
import { useHistory } from "react-router-dom";
import { startCase } from "lodash";

const Stack = (props) => {
  const { direction, children } = props;
  const propsBox = {
    display: "flex",
  };

  if (direction === "row") {
    propsBox.flexWrap = "wrap";
    propsBox.flexDirection = "row";
    propsBox.alignItems = "center";
  } else {
    propsBox.flexDirection = "column";
  }

  return <Box {...propsBox}>{children}</Box>;
};

Stack.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]).isRequired,
  children: PropTypes.node.isRequired,
};

Stack.defaultProps = {};

function CheckBoxGroup(props) {
  const history = useHistory();
  const translate = useTranslate();
  const { choices = [], resource, source, label, direction } = props;

  const {
    input: { onChange, value: inputValue },
  } = useInput(props);

  const paramsFilter = useSelector(
    (state) => state.admin.resources[resource]?.list?.params
  );

  const handleOnChange = (value) => {
    let correctValue = value;
    if (Array.isArray(correctValue)) {
      correctValue = correctValue.join(",");
    }
    onChange(correctValue);
  };

  // Display based on url
  useEffect(() => {
    const { filter } =
      queryString.parse(history.location.search.substring(1)) || {};
    const objFilter = filter ? JSON.parse(filter) : {};
    const paramValues = objFilter?.[source] || paramsFilter?.filter?.[source];

    if (paramValues) {
      let params = paramValues;
      if (!Array.isArray(paramValues)) {
        params = paramValues.split([","]);
      }
      handleOnChange(params);
    }
  }, []);

  function handleToggle(e) {
    const selected = e.target.name;
    let newValue = inputValue;

    if (!newValue) {
      handleOnChange([selected]);
      return;
    }

    if (typeof newValue === "string" && newValue.split(",").length > 1) {
      newValue = newValue.split(",");
    }

    if (Array.isArray(newValue) === false) {
      newValue = [newValue];
    }

    if (newValue?.indexOf(selected) === -1) {
      handleOnChange([...newValue, selected]);
    } else {
      handleOnChange([...newValue?.filter((v) => v !== selected)]);
    }
  }

  const getChecked = (listItem, item) => {
    let cloneValue = listItem;

    // Handle when list as `abc,xyz,def`
    if (Array.isArray(listItem) === false) {
      cloneValue = listItem.split(",");
    }

    return cloneValue.some((val) => val === item);
  };

  return (
    <FormControl
      component="fieldset"
      margin="dense"
      style={{
        marginTop: "16px",
      }}
    >
      <Card
        variant="outlined"
        style={{
          boxShadow: "unset",
          paddingBottom: 0,
          background: "none",
          backgroundColor: "#FFFFFF",
          padding: "2px 14px",
          border: "1px solid #0000003B",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <FormLabel
          component="legend"
          style={{
            transform: "translate(0, 2px) scale(0.75)",
            transformOrigin: "top left",
          }}
        >
          {startCase(translate(label))}
        </FormLabel>

        <FormGroup>
          <Stack direction={direction}>
            {choices?.map((choice) => (
              <FormControlLabel
                key={choice.id}
                label={startCase(translate(choice.name))}
                style={{
                  padding: "0px 8px 0px",
                }}
                control={
                  <Checkbox
                    checked={getChecked(inputValue, choice.id)}
                    onChange={handleToggle}
                    name={choice.id}
                    color="primary"
                    style={{
                      paddingTop: "7px",
                      paddingBottom: "7px",
                    }}
                  />
                }
              />
            ))}
          </Stack>
        </FormGroup>
        <FormHelperText />
      </Card>
    </FormControl>
  );
}

CheckBoxGroup.propTypes = {
  choices: PropTypes.array.isRequired,
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  direction: PropTypes.oneOf(["row", "column"]),
};

CheckBoxGroup.defaultProps = {
  label: "",
  direction: "row",
};

export default CheckBoxGroup;
