import React from 'react';
import { useRecordContext } from 'react-admin';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';
import CurrenciesConfigWrapper from './currencies-config-wrapper';

const CurrenciesConfigForBrandEdit = props => {
  const record = useRecordContext();

  return (
    <CurrenciesConfigWrapper
      currenciesSelectorProps={{
        filterToQuery: value => ({
          name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
          'groups.id||$in': record?.group?.id,
          skipBrandACL: true,
        }),
      }}
      {...props}
    />
  );
};

export default CurrenciesConfigForBrandEdit;
