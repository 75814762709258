/* eslint-disable */
import moment from 'moment';
import { DATE_VALUES, UTC_VALUES } from '.';
import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';

export const measureTextWidth = (str, fontSize) => {
  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875,
    0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625,
    0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375,
    0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625,
    0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375,
    0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875,
    0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625,
    0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5,
    0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;
  return Array.from(str).reduce((acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0) * fontSize;
};

export const formatNumber = (num, currencyCode = '') => {
  try {
    const formattedNumber = num.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });

    if (num >= 0 && currencyCode) {
      return `${currencyCode}${formattedNumber}`;
    } else if (currencyCode) {
      return formattedNumber.replace('-', `-${currencyCode}`);
    } else {
      return formattedNumber;
    }
  } catch (error) {
    return num;
  }
};

const getIsoString = (date) => {
  try {
    return date.toISOString();
  } catch (error) {
    return '';
  }
};

const getTimeZoneByOffset = (utcOffset) => {
  switch (utcOffset) {
    case UTC_VALUES[8]:
      return 'Etc/GMT-8';
    default:
      return 'Africa/Abidjan';
  }
};

export const getDateFilter = (value, utcOffset = 0) => {
  let startDate = '';
  let endDate = '';

  const timezone = getTimeZoneByOffset(utcOffset);

  switch (value) {
    case DATE_VALUES.TODAY_TILL_NOW:
      startDate = moment().tz(timezone).startOf('day');
      endDate = moment().tz(timezone);
      break;
    case DATE_VALUES.YESTERDAY:
      startDate = moment().tz(timezone).subtract(1, 'day').startOf('day');
      endDate = moment().tz(timezone).subtract(1, 'day').endOf('day');
      break;

    case DATE_VALUES['3_DAYS_AGO']:
      startDate = moment().tz(timezone).subtract(3, 'day').startOf('day');
      endDate = moment().tz(timezone).subtract(1, 'day').endOf('day');
      break;

    case DATE_VALUES['7_DAYS_AGO']:
      startDate = moment().tz(timezone).subtract(7, 'day').startOf('day');
      endDate = moment().tz(timezone).subtract(1, 'day').endOf('day');
      break;

    case DATE_VALUES.MONTH_TILL_NOW:
      startDate = moment().tz(timezone).startOf('month').startOf('day');
      endDate = moment().tz(timezone);
      break;
    case DATE_VALUES.PREVIOUS_MONTH:
      startDate = moment().tz(timezone).subtract(1, 'month').startOf('month').startOf('day');
      endDate = moment().tz(timezone).subtract(1, 'month').endOf('month').endOf('day');
      break;
    case DATE_VALUES['3_MONTHS_AGO']:
      startDate = moment().tz(timezone).subtract(3, 'month').startOf('month').startOf('day');
      endDate = moment().tz(timezone).subtract(1, 'month').endOf('month').endOf('day');
    default:
      break;
  }

  return `${FILTER_PREFIX_ON_VALUE.BETWEEN}${getIsoString(startDate)},${getIsoString(endDate)}`;
};

export const GGR_BAR_SIZE = 35;
export const GGR_BAR_GAP = 10;

export const getChartHeight = (totalItem, extendHeight = 200) => {
  return totalItem * (GGR_BAR_SIZE + GGR_BAR_GAP) + extendHeight;
};

export const getCustomDomain = (data = [], dataKey = '') => {
  const hasOnlyNegativeValues = !data.some((item) => item[dataKey] > 0);
  const hasOnlyPositiveValues = !data.some((item) => item[dataKey] < 0);

  if (hasOnlyNegativeValues) {
    return ['auto', 0];
  } else if (hasOnlyPositiveValues) {
    return [0, 'auto'];
  } else {
    return undefined;
  }
};
