import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import { camelCase, kebabCase } from 'lodash';
import * as moment from 'moment';
import { useTranslate } from 'ra-core';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CmtCard from '../../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import CmtList from '../../../../../../@coremat/CmtList';

const convertDocStatus = _status => {
  switch (_status) {
    case '0':
      return {
        title: DocStatus.DRAFT,
        color: '#b7b7b7',
      };
    case '5':
      return {
        title: DocStatus.SUBMITED,
        color: '#ffd700',
      };
    case '6':
      return {
        title: DocStatus.APPROVED,
        color: '#00b516',
      };
    case '9':
      return {
        title: DocStatus.APPROVED,
        color: '#c4080c',
      };
  }
};

const DocStatus = {
  DRAFT: 'Draft',
  SUBMITED: 'Submitted',
  APPROVED: 'Approved',
  CANCELLED: 'Cancelled',
};

const useStyles = makeStyles(() => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  click: {
    cursor: 'pointer',
  },
  flexGrowAll: {
    flexGrow: 1,
  },
  message: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
  },
  messageDetail: {
    marginTop: '5px',
    display: 'flex',
  },
  transactionChip: {
    height: '20px',
    borderRadius: '3px',
    fontSize: '10px',
    textTransform: 'capitalize',
    fontWeight: 900,
  },
}));

const HeaderTransactions = props => {
  const { onClosePopOver } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const data = useSelector(({ notification }) => notification.transaction.data);

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        {data?.length > 0 ? (
          <PerfectScrollbar className={classes.scrollbarRoot}>
            <CmtList
              data={data}
              renderRow={item => (
                <TransactionItem
                  key={item.id}
                  item={item}
                  onClosePopOver={onClosePopOver}
                />
              )}
            />
          </PerfectScrollbar>
        ) : (
          <Box p={6}>
            <Typography variant="body2">{translate('ra.text.noTransactionFound')}</Typography>
          </Box>
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

const TransactionItem = ({
  item, onClosePopOver,
}) => {
  if (!item) return;

  const translate = useTranslate();
  const classes = useStyles();

  const formatDate = dateToFormat => {
    const created = moment(dateToFormat);
    const diffMins = -created.diff(moment(), 'minutes');
    const diffHours = -created.diff(moment(), 'hours');

    if (diffHours === 0) {
      return translate('ra.text.minuteAgo', {
        smart_count: diffMins,
      });
    }

    if (diffHours < 24) {
      return translate('ra.text.hourAgo', {
        smart_count: diffHours,
      });
    }

    return moment(item[property].created).format('hh:mm:ss DD/MM');
  };

  const property = camelCase(null);
  return (
    <Box
      pt={2}
      pb={2}
      borderBottom="1px solid rgba(2, 2, 2, 0.035)"
      className={classes.click}
    >
      <Box className={classes.message}>
        <Chip
          label={convertDocStatus(item?.[property]?.docStatus)?.title}
          style={{
            background: convertDocStatus(item?.[property]?.docStatus)?.color,
          }}
          className={classes.transactionChip}
        />
        {translate('ra.text.gotRecord', {
          username: item[property]?.user?.username?.substr(5),
          status: translate(`resources.fields.docStatus.${convertDocStatus(item?.[property]?.docStatus)?.title.toLowerCase()}`),
          type: translate(`resources.${kebabCase(item.type)}.name`),
        })}
      </Box>
      <div className={classes.messageDetail}>
        {formatDate(item?.[property]?.updated)}
        <div className={classes.flexGrowAll} />
        {
          (item[property]?.amount || item[property]?.earn) && (
            <>
              {item.currency?.symbol}
              {' '}
              {item[property]?.amount || item[property]?.earn}
            </>
          )
        }
      </div>
    </Box>
  );
};

export default HeaderTransactions;
