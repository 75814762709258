import { useMetaDataContext } from '../context/metadata/context';

const useApiProperties = () => {
  const { componentRequest } = useMetaDataContext();

  const getPropertiesByFieldName = field => {
    const sourceSchema = componentRequest?.properties?.[field];
    if (!sourceSchema) {
      return {};
    }

    const optionText = sourceSchema?.properties?.optionText?.default;
    const choiceLimit = sourceSchema?.properties?.choiceLimit;
    const choiceSort = sourceSchema?.properties?.choiceSort;
    const translatable = sourceSchema?.properties?.translatable?.default;
    const format = sourceSchema?.format || sourceSchema?.items?.format;

    return {
      optionText,
      choiceLimit,
      choiceSort,
      format,
      translatable,
    };
  };

  return {
    getPropertiesByFieldName,
    properties: componentRequest?.properties,
  };
};

export default useApiProperties;
