import React, { useMemo } from 'react';
import { useTheme, Button } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Link } from 'react-router-dom';
import { useRecordContext } from 'react-admin';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';
import resourceSlug from '../../../../constant/resource-slug';

export default function ReportWinnersLink(props) {
  const {
    type, ...otherProps
  } = props;
  const record = useRecordContext();
  const playerId = get(record, 'playerId', '');
  const gameId = get(record, 'gameId', '');
  const groupId = get(record, 'group', '');
  const brandId = get(record, 'brand', '');
  const translate = useTranslate();
  const muiTheme = useTheme();

  const { appliedFilters } = useReportContext();

  const filtersOmitted = useMemo(() => {
    const clonedFilters = {
      ...appliedFilters,
      group: groupId,
      brand: brandId,
      type,
    };
    if (playerId) {
      clonedFilters.playerId = playerId;
    }

    if (gameId) {
      clonedFilters.gameId = gameId;
    }

    const sanitizedFilters = omit(clonedFilters, ['nativeId', 'consolidated']);

    return Object.keys(sanitizedFilters).reduce(
      (currentFilter, currentParamKey) => `${currentFilter}&${currentParamKey}=${sanitizedFilters[currentParamKey]}`,
      '',
    );
  }, [appliedFilters, playerId, groupId, brandId, gameId, type]);

  return (
    <Button
      color="secondary"
      variant="contained"
      size="small"
      style={{
        background: muiTheme.palette.primary.light,
        color: muiTheme.palette.secondary.main,
      }}
      component={Link}
      to={{
        pathname: `/${resourceSlug.REPORT_DETAIL_PLAYER}`,
        search: filtersOmitted,
      }}
      {...otherProps}
    >
      {translate('ra.action.viewDetail')}
    </Button>
  );
}

ReportWinnersLink.propTypes = {
  type: PropTypes.string.isRequired,
};
