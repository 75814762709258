import React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { IdField } from '../../base/components/ra/fields';

function BetSettingList(props) {
  return (
    <>
      <WealthListGuesser
        {...props}
        rowClick={false}
        excludeFields={['id']}
      >
        <IdField source="id" />
      </WealthListGuesser>
    </>
  );
}

export default BetSettingList;
