import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CurrenciesSelector from './currencies-selector';
import ThinEnabledCurrenciesConfig from './thin-enabled-currencies-config';
import { CurrencyDisplayModeEnum } from '../../../constant';

const CurrenciesConfig = ({
  source, selectedCurrencies, currenciesSelectorProps,
}) => (
  <Box
    sx={{
      '&>*:not(:last-child)': {
        marginBottom: 2,
      },
    }}
  >
    <CurrenciesSelector
      source={source}
      selectedCurrencies={selectedCurrencies}
      {...currenciesSelectorProps}
    />
    <ThinEnabledCurrenciesConfig
      source={source}
      selectedCurrencies={selectedCurrencies}
    />
  </Box>
);

CurrenciesConfig.propTypes = {
  source: PropTypes.string.isRequired,
  selectedCurrencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      mode: PropTypes.oneOf(Object.values(CurrencyDisplayModeEnum)),
    }),
  ).isRequired,
  currenciesSelectorProps: PropTypes.object,
};

CurrenciesConfig.defaultProps = {
  currenciesSelectorProps: {},
};

export default CurrenciesConfig;
