/* eslint-disable no-unused-vars */
import { CondOperator } from '@nestjsx/crud-request';
import kebabCase from 'lodash/kebabCase';

// Get `reference name` from schema data
// it uses to checking or getting metadata of field
export const getRefName = refField => {
  let refName = kebabCase(refField?.split('/schemas/')?.pop());
  if (refName.endsWith('status')) {
    refName = 'status';
  }
  return refName;
};

// Get `reference field` as path to resource
// it uses to reference/link to this resource
export const getReference = sourceSchema => {
  const refField = sourceSchema?.$ref
    || sourceSchema?.items?.$ref
    || sourceSchema?.allOf?.filter(i => i?.$ref)?.[0]?.$ref
    || sourceSchema?.properties?.reference?.$ref;

  const refName = getRefName(refField);

  const optionText = sourceSchema?.properties?.optionText?.default;

  return {
    refField,
    refName,
    optionText,
  };
};

// Handle `filterOn` from ApiProperties
// If Brand filterOn Group
// => Brand's data when display must be under Group
export const handleFilterOn = (filterOn, options) => {
  const filterOnProps = Object.keys(filterOn || {});
  const {
    user, refName, formValues, changeInputValue, realResource,
  } = options;

  const isCheatMode = ['cheat-mode-link', 'cheat-mode-apply'].includes(realResource);

  const filterOnResult = {};

  filterOnProps.forEach(item => {
    const filterValue = formValues?.[item];
    const filterFormat = filterOn[item].format;

    if (filterValue) {
      if (Array.isArray(filterValue)) {
        const arrId = filterValue.map(i => i.id).join(',');
        if (filterFormat) {
          filterOnResult[filterFormat] = arrId;
        } else {
          const queryOperation = filterValue.length > 1 ? CondOperator.IN : CondOperator.EQUALS;
          filterOnResult[`${item}||${queryOperation}`] = arrId;
        }
      } else if (['groupId', 'group'].includes(item)) {
        if (refName === 'user') {
          filterOnResult.group = {
            id: filterValue?.id || filterValue,
          };
        } else {
          filterOnResult[filterFormat || 'group.id'] = filterValue?.id || filterValue || user?.group?.id;
        }
      } else if (filterValue?.id) {
        if (filterFormat) {
          filterOnResult[filterFormat] = filterValue.id;
        } else {
          filterOnResult[item] = {
            id: filterValue.id,
          };
        }
      } else {
        filterOnResult[filterFormat || item] = filterValue;
      }
    }
  });

  return filterOnResult;
};

// Handle `filterOnRef` from ApiProperties
export const handleFilterOnRef = (sourceSchema, options) => {
  const { formValues } = options;
  const filterOnRefResult = {};

  const filterOnRef = sourceSchema?.properties?.filterOnRef?.properties;
  const filterOnRefProps = Object.keys(filterOnRef || {});

  if (filterOnRef) {
    filterOnRefProps.forEach(f => {
      const value = formValues?.[filterOnRef?.[f]?.default];
      const format = filterOnRef?.[f]?.format;

      if (value?.id) {
        filterOnRefResult[format || f] = value.id;
      } else if (value) {
        filterOnRefResult[format || f] = value;
      }
    });
  }

  return filterOnRefResult;
};

// Handle `filter` from ApiProperties
export const handleFilter = sourceSchema => {
  const filterResult = {};

  const filter = sourceSchema?.properties?.filter?.properties;
  const filterProps = Object.keys(filter || {});

  if (filter) {
    filterProps.forEach(f => {
      filterResult[f] = filter[f].default;
    });
  }

  return filterResult;
};

// Get `reference filters` for input
export const getFiltersFromSchema = (sourceSchema, options) => {
  const filterOn = sourceSchema?.properties?.filterOn?.properties || {};

  const refFilterResult = {
    ...handleFilterOn(filterOn, options),
    ...handleFilterOnRef(sourceSchema, options),
    ...handleFilter(sourceSchema, options),
  };

  return refFilterResult;
};
