import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyle = makeStyles(theme => ({
  root: {
    width: '320px',
    display: 'flex',
    gap: '16px',

    '&:first-of-type': {
      marginBottom: '16px',
    },

    [theme.breakpoints.up('sm')]: {
      width: '520px',
    },
  },
  text: {
    width: '260px',
    [theme.breakpoints.up('sm')]: {
      width: '350px',
    },
  },
}), {
  name: 'AuditLogSkeleton',
});

export default function AuditLogSkeleton() {
  const classes = useStyle();

  return (
    <Box>
      {['one', 'two'].map(item => (
        <Box
          key={item}
          className={classes.root}
        >
          <Skeleton
            variant="circle"
            width={30}
            height={30}
          />
          <Box>
            <Skeleton
              variant="text"
              width="260px"
            />
            <Skeleton
              variant="text"
              width="220px"
            />
            <Skeleton
              variant="text"
              className={classes.text}
            />
            <Skeleton
              variant="text"
              width="180px"
            />
            <Skeleton
              variant="text"
              width="250px"
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
