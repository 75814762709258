/* eslint-disable */
import { Loading, getResources } from 'react-admin';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PrepaidReportFilters from './components/PrepaidReportFilters';
import ReportLayout from '../../base/components/reports/ReportLayout';
import PrepaidReportResults from './components/PrepaidReportResults';
import { REPORT_TYPES } from '../../constant/resource-slug';

export const LIST_REPORTS = [REPORT_TYPES.OVERALL, REPORT_TYPES.PREPAID];

function ReportPrepaidList({ resource }) {
  const originalResources = useSelector(getResources);

  const resources = useMemo(() => originalResources, [originalResources?.length]);

  if (!resources?.length) {
    return null;
  }

  const currentResource = resources.filter((r) => r.name === resource)?.[0];
  if (!currentResource) {
    return <Loading />;
  }

  const {
    options: { reportInterface },
  } = currentResource;

  return (
    <ReportLayout
      totalTables={LIST_REPORTS.length}
      filterChildren={<PrepaidReportFilters reportServiceVersion={reportInterface?.serviceVersion} />}
      resultChildren={<PrepaidReportResults />}
    />
  );
}

export default ReportPrepaidList;
