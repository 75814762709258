import InboxRuleCreate from './inbox-rule.create';
import InboxRuleEdit from './inbox-rule.edit';
import InboxRuleShow from './inbox-rule.show';
import InboxRuleList from './inbox-rule.list';

export default {
  create: InboxRuleCreate,
  edit: InboxRuleEdit,
  show: InboxRuleShow,
  list: InboxRuleList,
};
