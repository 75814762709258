import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { getAllowedResources } from '../../services/redux/app/app.actions';
import { formatResources } from '../../services/util';

const useAllowedResources = () => {
  const dispatch = useDispatch();

  const allowedResources = useSelector(({ app }) => app?.allowedResources);

  const fetchAllowedResources = async () => {
    const permittedResource = await axios.get('api/resource/allowed');

    const resources = formatResources(permittedResource?.data);

    const result = resources.reduce(
      (
        acc,
        {
          name,
          list,
          read,
          create,
          update,
          delete: hasDelete,
          export: hasExport,
        },
      ) => ({
        ...acc,
        [name]: {
          list,
          read,
          create,
          update,
          delete: hasDelete,
          export: hasExport,
        },
      }),
      {},
    );

    dispatch(getAllowedResources(result));
  };

  useEffect(() => {
    if (isEmpty(allowedResources)) {
      fetchAllowedResources();
    }
  }, [allowedResources]);

  return allowedResources;
};

export default useAllowedResources;
