/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Axios from 'axios';
import { useNotify, useTranslate, useRefresh } from 'ra-core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/styles';
import SmallButton from '@material-ui/core/Button';
import { useAuthUser, useError } from '../../../base/hooks';
import { RoleBuiltIn } from '../../../services/util';

const useStyles = makeStyles(() => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px',
    background: '#FFF',
  },
}));

const ButtonUnlockShow = ({
  playerId,
  nativeId,
  shouldShowBtn: initShouldShowBtn = true,
}) => {
  const classes = useStyles();
  const user = useAuthUser();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useError();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const shouldShowButton = [
    RoleBuiltIn.SYSTEM_ADMIN,
    RoleBuiltIn.GROUP_ADMIN,
    RoleBuiltIn.BRAND_ADMIN,
  ].includes(user.role?.id);

  if (!shouldShowButton || !initShouldShowBtn) {
    return null;
  }

  const label = translate('ra.action.unlock');

  const unlock = async () => {
    setLoading(true);
    try {
      const url = `api/player/${playerId}/unlock`;

      await Axios.post(url);

      const message = `${translate('ra.action.unlock')} #${nativeId}`;

      const successMessage = translate('ra.notification.actionSuccess', {
        smart_name: message,
      });

      refresh();

      notify(successMessage, 'success');
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  const components = (
    <SmallButton
      color="primary"
      className={classes.button}
      disabled={loading}
      onClick={() => unlock()}
      startIcon={<LockOpenIcon />}
    >
      {label}
    </SmallButton>
  );

  return components;
};

export default ButtonUnlockShow;
