/* eslint react/prop-types: 0 */
import React, { forwardRef } from 'react';
import classnames from 'classnames';
import MuiTableCell from '@material-ui/core/TableCell';

const TableCell = forwardRef((props, ref) => {
  const {
    classes, className, children, ...rest
  } = props;

  return (
    <MuiTableCell
      ref={ref}
      {...rest}
      className={classnames(classes.customTableCell, className)}
    >
      {children}
    </MuiTableCell>
  );
});

export default TableCell;
