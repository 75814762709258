/* eslint-disable no-return-assign, no-restricted-globals */
import { isEmpty } from 'lodash';
import md5 from 'md5';
import rcSlug from '../../constant/resource-slug';

export const genUUID = (target, scope) => {
  const hashStatus = md5(`${target}@${scope}`);
  return `${hashStatus.substr(0, 8)}-${hashStatus.substr(
    8,
    4,
  )}-${hashStatus.substr(12, 4)}-${hashStatus.substr(
    16,
    4,
  )}-${hashStatus.substr(20, 12)}`;
};

/**
 * getBetSettingInfo to get list item value & and list item default value
 * @param {*} data
 * @returns [listItemValue, listDefaultValue]
 */
export const getBetSettingInfo = data => {
  if (
    isEmpty(data)
    || !Array.isArray(data.betSizes)
    || !Array.isArray(data.betLevels)
  ) {
    return [];
  }

  const listItem = {
    betSize: [],
    betLevel: [],
  };

  const listDefaultValue = {
    betSize: null,
    betLevel: null,
  };

  // Update betSize list & betSize default value
  data.betSizes.forEach(item => {
    listItem.betSize.push({
      value: item.value,
    });
    if (item.default) {
      listDefaultValue.betSize = item.value;
    }
  });

  // Update betLevel list & betLevel default value
  data.betLevels.forEach(item => {
    listItem.betLevel.push({
      value: item.value,
    });
    if (item.default) {
      listDefaultValue.betLevel = item.value;
    }
  });

  return [listItem, listDefaultValue];
};

export const fetchDefaultBetSetting = async (
  dataProvider,
  {
    gameId, currencyId,
  } = {},
) => {
  const { data } = await dataProvider?.getOne(rcSlug.BET_SETTING_DEFAULT, {
    id: genUUID(gameId, currencyId),
  });

  return data;
};

export const fetchGamesBeingAvailable = async (
  dataProvider,
  filters = {},
  params = {},
) => {
  const {
    groupId, brandId,
  } = filters;
  const {
    pagination, sort,
  } = params;

  const { data } = await dataProvider.getList(rcSlug.AVAILABLE_GAME, {
    filter: {
      'group.id': groupId,
      'brand.id': brandId,
    },
    pagination,
    sort,
  });

  // Fake
  const objGames = {};
  data.forEach(item => {
    if (Array.isArray(item.games)) {
      item.games.forEach(gameItem => objGames[gameItem.id] = gameItem);
    }
  });

  const listGame = Object.keys(objGames).map(gameId => objGames[gameId]);
  return listGame;
};

export const fetchGameDetail = async (
  dataProvider,
  gameId,
) => {
  const { data } = await dataProvider.getOne(rcSlug.GAME, {
    id: gameId,
  });
  return data;
};

export const validateTotalBetMax = ({ translateSchema }) => (totalBetMax, allValues) => {
  const { totalBetMin } = allValues;

  if (totalBetMax <= totalBetMin) {
    return translateSchema({
      name: 'ra.validation.mustGreatThan',
      params: {
        field_a: 'ra.field.totalBetMax',
        field_b: 'ra.field.totalBetMin',
      },
    });
  }

  return undefined;
};
