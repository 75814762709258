/* eslint-disable react/prop-types */
import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

export const TooltipForDisabledInput = props => {
  const {
    showMessage = false, children, innerProps, ...rest
  } = props;

  // When this tooltip show the message
  // That's mean input must be disabled
  // => prevent all the user interactions to the input
  const isDisabledInput = showMessage;

  let disableProps;
  // Do not show tooltip when the input isn't disabled
  if (!showMessage) {
    disableProps = {
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
    };
  }

  return (
    <Tooltip
      {...(disableProps || {})}
      {...rest}
    >
      <Box>
        <Box
          className={clsx({
            'pointer-event-none': isDisabledInput,
          })}
        >
          {React.cloneElement(children, {
            ...innerProps,
          })}
        </Box>
      </Box>
    </Tooltip>
  );
};

const TooltipWrapper = React.forwardRef(({ children }, ref) => (
  <Box
    ref={ref}
    className="tooltip-wrapper"
  >
    {children}
  </Box>
));

const CustomTooltip = ({
  children,
  ...props
}) => (
  <Tooltip {...props}>
    <TooltipWrapper>{children}</TooltipWrapper>
  </Tooltip>
);

export default CustomTooltip;
