import PropTypes from 'prop-types';
import * as React from 'react';

const MaskField = ({
  record, source,
}) => (
  <span>{Array.from(Array(record?.[source]?.length)).map(() => '*')?.join('')}</span>
);

MaskField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  source: PropTypes.string,
};
MaskField.defaultProps = {
  record: {},
  source: '',
};

export default MaskField;
