/* eslint-disable import/no-cycle */
import { Box, MenuItem, TextField, Select, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { DATE_OPTIONS, TYPE_OPTIONS, UTC_OPTIONS, useGGRContext } from './index';

const ChartFilterWrapper = () => {
  const translate = useTranslate();
  const history = useHistory();
  const { pathname } = useLocation();
  const { query = {} } = useGGRContext();

  const handleUpdateUrl = queryParams => {
    const params = new URLSearchParams(queryParams);
    history.push({
      pathname,
      search: params.toString(),
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth={{
        xs: '100%',
        lg: 300,
      }}
      sx={{
        '&>*:not(:last-child)': {
          marginBottom: 12,
        },
      }}
    >
      <TextField
        select
        value={query.utcOffset}
        label={translate('resources.dashboard.fields.UTCOffsets')}
        onChange={e => handleUpdateUrl({
          ...query,
          utcOffset: e.target.value,
        })}
        variant="outlined"
      >
        {UTC_OPTIONS.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        value={query.date}
        label={translate('resources.dashboard.fields.timeRange')}
        onChange={e => handleUpdateUrl({
          ...query,
          date: e.target.value,
        })}
        variant="outlined"
      >
        {DATE_OPTIONS.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
          >
            {translate(option.name)}
          </MenuItem>
        ))}
      </TextField>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          gap: 12,
        }}
      >
        <Typography>{`${translate('resources.dashboard.fields.filterBy')}:`}</Typography>
        <Box
          component={Select}
          value={query.type}
          onChange={newType => handleUpdateUrl({
            ...query,
            type: newType,
          })}
          variant="outlined"
          sx={{
            flexGrow: 1,
          }}
        >
          {TYPE_OPTIONS.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {translate(option.name)}
            </MenuItem>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChartFilterWrapper;
