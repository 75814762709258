/* eslint-disable */
import { Box, CardContent, Divider, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f5f5f5',
    paddingTop: 10,
    "&, &:last-child": {
      paddingBottom: 10,
    },
    borderRadius: 12,
    border: '1px solid rgba(224, 224, 224, 1)',
    "& [class*='Paper']": {
      border: '1px solid rgba(224, 224, 224, 1)',
      borderRadius: 12,
      boxShadow: "none"
    },
  },
  title: {
    width: '100%',
    '&>*:first-child': {
      marginBottom: 10,
    },
    '& hr': {
      marginLeft: -16,
      width: 'calc(100% + 16px * 2)',
      marginBottom: 10,
    },
  },
});

const CommonWrapper = ({ title, children }) => {
  const styles = useStyles();
  return (
    <CardContent className={styles.root}>
      {Boolean(title) && (
        <Box className={styles.title}>
          <Typography variant="h6">{title}</Typography>
          <Divider />
        </Box>
      )}
      {children}
    </CardContent>
  );
};

export default CommonWrapper;
