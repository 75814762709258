import queryString from 'query-string';
import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

// pascalCase (ex: UserClass, UserName)
export const pascalCase = str => startCase(camelCase(str)).replace(/ /g, '');

export const sanitizeMultipleWhitespace = text => text.replace(/\s{1,}/g, ' ');

export const getFilterParam = () => {
  let filters = {};
  try {
    const search = window.location.href?.split('?')?.[1];
    const searchParsed = queryString.parse(search);
    filters = JSON.parse(searchParsed?.filter);
  } catch {
    // Do nothing!
  }
  return isObject(filters) ? filters : {};
};

export const transformIdValue = id => `#${id?.slice(0, 4)}`;

export const getBasePath = pathName => {
  const secondSlashIndex = pathName.indexOf('/', 1);
  if (secondSlashIndex === -1) {
    return pathName;
  }
  return pathName.slice(0, secondSlashIndex);
};

export const getUserAvatar = (displayName = '', userEmail = '') => {
  if (displayName) {
    return displayName.charAt(0).toUpperCase();
  }
  if (userEmail) {
    return userEmail.charAt(0).toUpperCase();
  }
  return 'A';
};
