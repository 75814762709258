/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { FunctionField, ReferenceField, TextField, useListContext, useRecordContext, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import axios from 'axios';
import StringFilter from '../../../../base/components/customized/common-input/StringInput';
import TimeRangeFilter from '../../../../base/components/customized/common-input/TimeRangeInput';
import CreateButton from '../../../../base/components/customized/common-list-action-buttons/CreateButton';
import FilterButton from '../../../../base/components/customized/common-list-action-buttons/FilterButton';
import CustomizedFilterWrapper from '../../../../base/components/customized/CustomizedFilterWrapper';
import CustomizedList from '../../../../base/components/customized/CustomizedList';
import CustomizedListActionWrapper from '../../../../base/components/customized/CustomizedListActionWrapper';
import { DateField, IdField, NameField } from '../../../../base/components/ra/fields';
import RowActionField from '../../../../base/components/ra/fields/row-action-field/row-action.field';
import { CUSTOM_ROUTES } from '../../../../constant/customRoutes';
import { CUSTOM_RESOURCES } from '../../../../constant/customResources';
import BrandSelector from '../../../../base/components/customized/common-input/BrandSelector';
import { transformIdValue } from '../../../../services/util/stringUtil';

export const getAvailableBrandsEndpoint = groupId => `api/qtech/operator/brand-available/${groupId}`;

const OperatorList = ({
  groupId, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <CustomizedList
      actions={<OperatorListAction groupId={groupId} />}
      filters={<OperatorFilterWrapper groupId={groupId} />}
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      emptyProps={{
        emptyMessage: translate('resources.reverse-integration.operator.empty'),
      }}
      basePath={CUSTOM_ROUTES.QTECH_OPERATOR}
      hasList
      hasShow
      hasCreate
      hasEdit
      {...otherProps}
    >
      <IdField hasShow />
      <DateField
        source="createdAt"
        label="common.fields.created"
      />
      <DateField
        source="updatedAt"
        label="common.fields.updated"
      />
      <NameField label="common.fields.name" />
      <TextField
        source="operatorId"
        label="resources.reverse-integration.operator.fields.operatorId"
      />

      <ReferenceField
        source="groupId"
        reference="group"
        link="show"
        label="resources.group.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brandId"
        reference="brand"
        link="show"
        label="resources.brand.name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="walletIntegrationId"
        reference={CUSTOM_RESOURCES.QTECH_OPERATOR_INTEGRATION.name}
        link="show"
        label="resources.reverse-integration.operator.fields.operatorIntegrationId"
      >
        <FunctionField render={record => transformIdValue(record.id)} />
      </ReferenceField>
      <RowAction label={translate('ra.field.action')} />
    </CustomizedList>
  );
};

OperatorList.propTypes = {
  resource: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

const RowAction = props => {
  const translate = useTranslate();
  const { record } = useRecordContext();
  return (
    <RowActionField
      {...props}
      hasDelete
      deleteButtonProps={{
        confirmTitle: translate('resources.reverse-integration.operator.confirmToDeleteTitle', {
          id: record?.id,
        }),
        translatedResourceName: translate('resources.reverse-integration.operator.name'),
      }}
      sortable={false}
    />
  );
};

const OperatorListAction = ({ groupId }) => {
  const { total: totalOperator } = useListContext();
  const [hasAvailableBrands, setHasAvailableBrands] = useState(false);

  useEffect(() => {
    if (!groupId || typeof totalOperator !== 'number') return;
    axios
      .get(getAvailableBrandsEndpoint(groupId))
      .then(({ data }) => {
        setHasAvailableBrands(data.data.length > 0);
      })
      .catch(() => {
        setHasAvailableBrands(true);
      });
  }, [totalOperator]);

  return (
    <CustomizedListActionWrapper>
      <FilterButton />
      {hasAvailableBrands && <CreateButton basePath={CUSTOM_ROUTES.QTECH_OPERATOR} />}
    </CustomizedListActionWrapper>
  );
};

OperatorListAction.propTypes = {
  groupId: PropTypes.string.isRequired,
};

const OperatorFilterWrapper = ({ groupId }) => (
  <CustomizedFilterWrapper>
    <TimeRangeFilter
      resource="createdAt"
      source="createdAt"
      label="common.fields.created"
    />
    <TimeRangeFilter
      resource="updatedAt"
      source="updatedAt"
      label="common.fields.updated"
    />
    <StringFilter
      source="name||$cont"
      label="common.fields.name"
    />
    <StringFilter
      source="operatorId||$cont"
      label="resources.reverse-integration.operator.fields.operatorId"
    />
    <BrandSelector
      filter={{
        'group.id': groupId,
      }}
    />
  </CustomizedFilterWrapper>
);

OperatorFilterWrapper.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default OperatorList;
