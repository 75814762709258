/* eslint-disable */
import { get, set } from 'lodash';
import React from 'react';
import { TextField, useLocale } from 'react-admin';

const TranslatableField = (props) => {
  let { record, source } = props;
  const locale = useLocale();

  const value = get(record, source);

  try {
    if (typeof value === 'string') {
      set(record, source, JSON.parse(value));
    }

    source = `${source}.${locale}`;
  } catch {
    //
  }

  return <TextField {...props} source={source} />;
};

export default TranslatableField;
