import React, { memo } from 'react';
import PropTypes from 'prop-types';
import List from '../../ra/list/List';
import { useFormContext } from './form.context';

function ReportCustomList(props) {
  const {
    children, ...rest
  } = props;

  const {
    values, showReport,
  } = useFormContext();

  return (
    <List
      {...rest}
      filter={values}
      filterDefaultValues={
        values
      } /* TODO: will remove as this is quite confuse */
      emptyTable={!showReport}
    >
      {children}
    </List>
  );
}

ReportCustomList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  filterDefaultValues: PropTypes.object,
};

ReportCustomList.defaultProps = {
  filterDefaultValues: {},
};

export default memo(ReportCustomList);
