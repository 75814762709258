import { Card } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { ShowSection } from '../../../base/components/guesser/wealth-show.guesser';
import CommonShowWrapper from '../../../base/components/customized/common-wrapper/CommonShowWrapper';
import { DateField } from '../../../base/components/ra/fields';
import Labeled from '../../../base/components/ra/labeled';
import { CUSTOM_RESOURCES } from '../../../constant/customResources';
import { NavigationContext } from '../../../App';
import { UUID_REGEX } from '../../../constant';
import { CUSTOM_ROUTES, REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';

const PartnerShow = props => {
  const translate = useTranslate();
  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: translate('resources.reverse-integration.partner.name'),
      },
      breadcrumbs: [
        {
          label: translate('ra.page.home'),
          link: '/',
        },
        {
          label: translate('resources.group.system-setup.name'),
        },
        {
          label: translate('resources.reverse-integration.name'),
          link: '/reverse-integration',
        },
        {
          label: REVERSE_INTEGRATION_OPERATORS.VERTBET.name,
          link: CUSTOM_ROUTES.VERTBET_PARTNER,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, []);

  return (
    <CommonShowWrapper {...props}>
      <Card>
        <ShowSection title={translate('common.show.general')}>
          <TextField
            source="id"
            label="common.fields.id"
          />
          <Labeled label="common.fields.created">
            <DateField source="createdAt" />
          </Labeled>
          <Labeled label="common.fields.updated">
            <DateField source="updatedAt" />
          </Labeled>
        </ShowSection>
        <ShowSection title={translate('resources.reverse-integration.partner.partnerInformation')}>
          <TextField
            source="name"
            label="common.fields.name"
          />
          <TextField
            source="partnerId"
            label="resources.reverse-integration.partner.fields.partnerId"
          />
          <ReferenceField
            reference="group"
            source="groupId"
            label="common.fields.group"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference="brand"
            source="brandId"
            label="common.fields.brand"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference={CUSTOM_RESOURCES.VERTBET_PARTNER_INTEGRATION.name}
            source="walletIntegrationId"
            label="resources.reverse-integration.partner.fields.partnerIntegrationId"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
        </ShowSection>
      </Card>
    </CommonShowWrapper>
  );
};

export default PartnerShow;
