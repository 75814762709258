import { useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { useNotify, useCreate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-final-form';
import keys from 'lodash/keys';
import useError from './useError';

const useCreateButton = props => {
  const {
    resource, transform, onAfterSave, translatedResourceName,
  } = props;

  const translate = useTranslate();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();

  const {
    values: formValues, modified,
  } = useFormState();

  const onCreateSuccess = response => {
    const { data } = response;
    const recordId = data?.id;

    const resourceNameTranslated = translatedResourceName || translate(`resources.${resource}.name`);
    notify(translate('ra.notification.created'), 'success', {
      smart_name: resourceNameTranslated,
    });

    if (recordId) {
      history.push(`/${resource}/${recordId}/show`);
    } else {
      history.push(`/${resource}`);
    }
  };

  const [create, {
    loading: createLoading, error,
  }] = useCreate(undefined, undefined, {
    onSuccess: onCreateSuccess,
  });

  const summitOnCreate = () => {
    const data = keys(modified).reduce((acc, curKey) => {
      const fieldName = curKey.split('.')[0];
      acc[fieldName] = formValues[fieldName];
      return acc;
    }, {});

    create({
      resource,
      payload: {
        data: transform?.(data) || data,
      },
    });

    if (typeof onAfterSave === 'function') {
      onAfterSave();
    }
  };

  useEffect(() => {
    if (!createLoading) {
      if (error) {
        notifyError(error);
      }
    }
  }, [createLoading, error]);

  return {
    summitOnCreate,
  };
};

export default useCreateButton;
