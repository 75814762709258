import React, { useEffect, useState } from 'react';
import { useTranslate, FormDataConsumer, required, useLocale } from 'ra-core';
import { AutocompleteInput, useEditController } from 'react-admin';
import { Box } from '@material-ui/core';
import { get } from 'lodash';
import { useForm } from 'react-final-form';
import useTranslateSchemaRef from '../../../base/hooks/useTranslateSchemaRef';
import { ReferenceInput as ReferenceInputCustom } from '../../../base/components/ra/inputs';
import BetSettingPicker from '../../../base/components/rmc-picker-custom/bet-setting-picker.input';
import { BetSettingPickerProvider } from '../../../base/components/rmc-picker-custom/bet-setting-picker.context';
import { getBetSettingInfo, validateTotalBetMax } from '../../../services/util/bet-setting.utils';
import { TWO_DECIMAL_REGEX } from '../../../services/util/validate/regularExpression';
import { testByRegex } from '../../../services/util/validate/validateMethods';
import { checkTransatableField } from '../../../services/util';
import NumberInput from '../../../base/components/ra/inputs/commonNumber.input';

const BetSettingDefaultEditForm = props => {
  const translate = useTranslate();
  const { record } = useEditController(props);
  const form = useForm();
  const translateSchemaRef = useTranslateSchemaRef();

  const onlyTwoDigits = fieldName => testByRegex(translateSchemaRef, {
    regex: TWO_DECIMAL_REGEX,
    translationSchema: {
      name: 'ra.validation.onlyTwoDigitsAfter',
      params: {
        smart_name: fieldName,
      },
    },
  });

  const baseBet = get(record, 'game.baseBet');

  const [currentSettingValue, setCurrentSettingValue] = useState();
  const [currentSettingDefaultValue, setCurrentSettingDefaultValue] = useState();

  /**
   * Display setting from `record`
   */
  useEffect(() => {
    const [listSettingObj, listSettingDefaultObj] = getBetSettingInfo(record);
    setCurrentSettingValue(listSettingObj);
    setCurrentSettingDefaultValue(listSettingDefaultObj);

    form.change('totalBetMin', record?.totalBetLimit?.[0]);
    form.change('totalBetMax', record?.totalBetLimit?.[1]);
  }, [record]);

  const locale = useLocale();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        let optionTextGame = 'name';

        const translatable = checkTransatableField(formData?.game, 'name', locale);

        if (translatable) {
          optionTextGame = `name.${locale}`;
        }

        return (
          <Box>
            <ReferenceInputCustom
              disabled
              label="resources.game.name"
              source="game.id"
              reference="game"
              fullWidth
              validate={required()}
              perPage={100}
              variant="outlined"
            >
              <AutocompleteInput optionText={optionTextGame} />
            </ReferenceInputCustom>

            <ReferenceInputCustom
              disabled
              label="resources.currency.name"
              source="currency.id"
              reference="currency"
              filter={{
                enabled: true,
              }}
              fullWidth
              validate={required()}
              perPage={100}
              variant="outlined"
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInputCustom>

            <NumberInput
              fullWidth
              source="totalBetMin"
              label={translate('ra.field.totalBetMin')}
              validate={[onlyTwoDigits('ra.field.totalBetMin')]}
            />

            <NumberInput
              fullWidth
              source="totalBetMax"
              label={translate('ra.field.totalBetMax')}
              validate={[validateTotalBetMax(translateSchemaRef), onlyTwoDigits('ra.field.totalBetMax')]}
            />

            <BetSettingPickerProvider>
              <BetSettingPicker
                source="picker"
                baseBetInit={baseBet}
                listItemInit={currentSettingValue}
                defaultValueInit={currentSettingDefaultValue}
              />
            </BetSettingPickerProvider>
          </Box>
        );
      }}
    </FormDataConsumer>
  );
};

export default BetSettingDefaultEditForm;
