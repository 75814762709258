import React from 'react';
import { useTranslate } from 'react-admin';
import { Box, Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
  },
});

const PlayerChip = props => {
  const {
    text,
    value,
    ...restProps
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const label = (
    <>
      <Box>{translate(text)}</Box>
      {': '}
      {value}
    </>
  );

  return (
    <Chip
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      classes={{
        label: classes.chipLabel,
      }}
      label={label}
      color="primary"
      {...restProps}
    />
  );
};

PlayerChip.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

PlayerChip.defaultProps = {};

export default PlayerChip;
