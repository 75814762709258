import React from 'react';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate, useRefresh } from 'ra-core';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import { API_URL, CREDENTIAL_TYPE } from '../../../constant';
import ConfirmCTA from './confirm-cta';

export const ActionMenu = props => {
  const { record } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();

  const resetCredential = async type => {
    let successMsgKey = '';

    switch (type) {
      case CREDENTIAL_TYPE.NODE_RSA_KEY:
        successMsgKey = 'ra.message.resetKeyPairSuccess';
        break;
      case CREDENTIAL_TYPE.SECRET_KEY:
        successMsgKey = 'ra.message.resetSecretKeySuccess';
        break;
      case CREDENTIAL_TYPE.API_KEY:
        successMsgKey = 'ra.message.resetApiKeySuccess';
        break;
      default:
        return;
    }

    try {
      const { status } = await axios.post(
        `${API_URL.RESET_CREDENTIAL}/${record?.id}`,
        {
          type,
        },
      );

      if (status === 201) {
        notify(translate(successMsgKey), 'success');
        refresh();
      }
    } catch {
      // Do nothing!
    }
  };

  return (
    <ToolbarMenu {...props}>
      {record?.implementation === 'SEAMLESS' && (
        <ConfirmCTA
          title={translate('ra.action.resetKeyPair')}
          content={translate('ra.message.confirmResetKeyPair')}
          onConfirm={() => resetCredential(CREDENTIAL_TYPE.NODE_RSA_KEY)}
          buttonTrigger={{
            icon: <RotateLeftIcon />,
            label: 'ra.action.resetKeyPair',
          }}
        />
      )}

      {record?.implementation === 'TRANSFER' && (
        <ConfirmCTA
          title={translate('ra.action.resetSecretKey')}
          content={translate('ra.message.confirmResetSecretKey')}
          onConfirm={() => resetCredential(CREDENTIAL_TYPE.SECRET_KEY)}
          buttonTrigger={{
            icon: <RotateLeftIcon />,
            label: 'ra.action.resetSecretKey',
          }}
        />
      )}

      <ConfirmCTA
        title={translate('ra.action.resetApiKey')}
        content={translate('ra.message.confirmResetApiKey')}
        onConfirm={() => resetCredential(CREDENTIAL_TYPE.API_KEY)}
        buttonTrigger={{
          icon: <RotateLeftIcon />,
          label: 'ra.action.resetApiKey',
        }}
      />
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};
