import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, required, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Edit } from '../../../base/components/ra/views';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import BrandInput from '../partner/components/BrandInput';
import { registerViewType, EnumViewType } from '../../../base/hooks/useViewType';
import { NavigationContext } from '../../../App';
import { UUID_REGEX } from '../../../constant';
import { CUSTOM_ROUTES, REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';

const requiredValidator = required();

const ReverseWalletIntegrationEdit = ({
  resource, ...otherProps
}) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const { setNavigationData } = useContext(NavigationContext);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: translate('resources.reverse-integration.partnerIntegration.name'),
      },
      breadcrumbs: [
        {
          label: translate('ra.page.home'),
          link: '/',
        },
        {
          label: translate('resources.group.system-setup.name'),
        },
        {
          label: translate('resources.reverse-integration.name'),
          link: '/reverse-integration',
        },
        {
          label: REVERSE_INTEGRATION_OPERATORS.VERTBET.name,
          link: CUSTOM_ROUTES.VERTBET_PARTNER,
        },
        {
          label: translate('resources.reverse-integration.partnerIntegration.name'),
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, []);

  useEffect(() => {
    // Hotfix: To self register view type because the current system doesn't cover this case
    registerViewType(pathname, EnumViewType.EDIT);
  }, []);

  const handleDataTransformation = data => {
    const excludedFields = ['groupId', 'brandId'];
    return Object.keys(data).reduce((currentData, currentKey) => {
      if (excludedFields.includes(currentKey)) return currentData;
      return {
        ...currentData,
        [currentKey]: data[currentKey],
      };
    }, {});
  };

  return (
    <Edit
      hasEdit
      resource={resource}
      confirmDialogInfo={{
        content: 'resources.reverse-integration.partnerIntegration.confirmToEditContent',
      }}
      translatedResourceName={translate('resources.reverse-integration.partnerIntegration.name')}
      transform={handleDataTransformation}
      {...otherProps}
    >
      <SimpleForm redirect="show">
        <GroupSelector
          disabled
          validate={requiredValidator}
          fullWidth
          variant="outlined"
          inputProps={{
            helperText: true,
            resettable: false,
          }}
        />
        <BrandInput
          disabled
          fullWidth
          variant="outlined"
          validate={requiredValidator}
          inputProps={{
            helperText: true,
            resettable: false,
          }}
        />
        <UrlInput
          source="authorizeUrl"
          label="common.fields.authorizeUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="placeBetUrl"
          label="common.fields.placeBetUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="payOutUrl"
          label="common.fields.payOutUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="getBalanceUrl"
          label="common.fields.getBalanceUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
        <UrlInput
          source="cancelBetUrl"
          label="common.fields.cancelBetUrl"
          validate={requiredValidator}
          fullWidth
          helperText
        />
      </SimpleForm>
    </Edit>
  );
};

ReverseWalletIntegrationEdit.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default ReverseWalletIntegrationEdit;
