import { AppBar, useMediaQuery } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSetLocale, useLocale } from 'react-admin';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';

import { toggleNavCollapsed } from '../../../services/redux/setting/setting.actions';
import { LanguageContextProvider } from '../../context/language';
import {
  useClickOutside,
  useFirebase,
} from '../../hooks';
import { useLocalStorage } from '../../hooks/useLocalStorage';

// React Toastify (js file & css file)
import 'react-toastify/dist/ReactToastify.min.css';

import DialogManager from '../dialog';
import Notification from './notification';

function Layout(props) {
  useFirebase();

  const { children } = props;
  const locale = useLocale();
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const setSidebarOpen = isCollapsed => {
    dispatch(toggleNavCollapsed(isCollapsed));
  };

  const { storedValue } = useLocalStorage('waLocale');
  const setLocale = useSetLocale();

  // Close sidebar when click outside on small screen
  const clickRef = React.useRef();
  useClickOutside(clickRef, () => {
    if (smallScreen) {
      setSidebarOpen(false);
    }
  });

  useEffect(() => {
    setLocale(storedValue || 'en');
  }, []);

  useEffect(() => {
    setSidebarOpen(!smallScreen);
  }, [smallScreen]);

  return (
    <LanguageContextProvider>
      {() => {
        let providerLocale = locale;
        if (locale === 'cn') providerLocale = 'zh-cn';

        return (
          <LocalizationProvider
            dateLibInstance={moment}
            dateAdapter={MomentAdapter}
            locale={providerLocale}
          >
            <>
              {children}
              <ToastContainer />
              <Notification />
              <DialogManager />
            </>
          </LocalizationProvider>
        );
      }}
    </LanguageContextProvider>
  );
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
});

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: <div />,
};

// Enhance Layout with Router
const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props
  ),
  withRouter,
)(Layout);

// Enhnace Layout with Theme provider
const LayoutWithTheme = ({
  theme: themeOverride, ...props
}) => {
  const themeProp = React.useRef(themeOverride);
  const [theme, setTheme] = useState(createTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout
        {...props}
        appBar={AppBar}
      />
    </ThemeProvider>
  );
};

LayoutWithTheme.propTypes = {};

LayoutWithTheme.defaultProps = {};

export default LayoutWithTheme;
