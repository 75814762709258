import React, { useState, useMemo } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {
  useLocale,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { BetSettingFormProvider } from '../../../base/components/bet-setting/utils/bet-setting-form.context';
import { generateBetSettingKey } from './utils';
import { BET_SETTING_CONFIG_STATUS } from './constants';
import BetSettingDialog from './BetSettingDialog';

const useStyles = makeStyles(() => ({
  rowRoot: {
    '&:last-of-type > td': {
      border: 'none',
    },
  },
}));

const BetSettingTable = props => {
  const { index: brandFormIndex } = props;
  const sourceNamePrefix = `brands[${brandFormIndex}].`;

  const [curBetSettingDisplay, setCurBetSettingDisplay] = useState();

  const { values: formValues } = useFormState();
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  const muiTheme = useTheme();
  const screenLg = useMediaQuery(muiTheme.breakpoints.up('lg'));

  const resources = useSelector(({ admin }) => admin.resources);
  const gameDataFetched = _.get(resources, 'game.data');
  const currencyDataFetched = _.get(resources, 'currency.data');

  const games = _.get(formValues, `${sourceNamePrefix}games`, []);
  const currencies = _.get(formValues, `${sourceNamePrefix}currencies`, []);

  const isTranslatable = (
    games[0]?.id
    && typeof _.get(gameDataFetched, [games[0].id, 'name']) === 'object'
  );

  const gameAndCurrencyMixing = useMemo(() => games.reduce((mixingList, gItem) => {
    currencies.forEach(cItem => {
      if (gameDataFetched[gItem.id] && currencyDataFetched[cItem.id]) {
        mixingList.push({
          id: `${gItem.id}+${cItem.id}`,
          game: gameDataFetched[gItem.id],
          currency: currencyDataFetched[cItem.id],
        });
      }
    });
    return mixingList;
  }, []), [games, currencies, gameDataFetched, currencyDataFetched]);

  const handleClickOnEditButton = (e, curItem) => {
    e.preventDefault();
    setCurBetSettingDisplay(curItem);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        marginTop: '24px',
        boxShadow: 'none',
      }}
    >
      <Table>
        <TableBody>
          {gameAndCurrencyMixing.length > 0 && gameAndCurrencyMixing.map(gcMixItem => {
            const gameName = isTranslatable ? gcMixItem.game?.name?.[locale] : gcMixItem.game?.name;
            const betStKey = generateBetSettingKey({
              gameCode: gcMixItem.game.code,
              currencyCode: gcMixItem.currency.code,
            });

            const configStatus = _.get(
              formValues,
              `${sourceNamePrefix}betSettingConfigStatus.${betStKey}`,
              BET_SETTING_CONFIG_STATUS.defaultSetting,
            );

            return (
              <TableRow
                key={gcMixItem.id}
                className={classes.rowRoot}
              >
                <TableCell>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <Chip
                        label={gameName}
                        style={{
                          width: screenLg ? '400px' : '200px',
                        }}
                      />
                      <AddIcon />
                      <Chip
                        label={gcMixItem.currency?.name}
                        style={{
                          width: screenLg ? '400px' : '200px',
                        }}
                      />
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        marginLeft: 'auto',
                      }}
                    >
                      <Typography
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '15px',
                        }}
                      >
                        {translate(configStatus?.text)}
                      </Typography>
                      <IconButton
                        disableRipple
                        color="primary"
                        style={{
                          background: '#c3d9f2',
                          padding: '4px 12px',
                          borderRadius: '4px',
                          '&:hover': {
                            background: '#b1d3f9',
                          },
                        }}
                        onClick={e => handleClickOnEditButton(e, gcMixItem)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>

                    <BetSettingFormProvider
                      name={gcMixItem.game.name}
                      sourceNamePrefix={sourceNamePrefix}
                      game={cloneDeep(gcMixItem.game)}
                      currency={cloneDeep(gcMixItem.currency)}
                    >
                      <BetSettingDialog
                        open={curBetSettingDisplay?.id === gcMixItem.id}
                        onClose={() => {
                          setCurBetSettingDisplay(undefined);
                        }}
                        game={gcMixItem.game}
                        currency={gcMixItem.currency}
                        sourceNamePrefix={sourceNamePrefix}
                        totalBetMinSourceName={`${sourceNamePrefix}totalBetMin`}
                        totalBetMaxSourceName={`${sourceNamePrefix}totalBetMax`}
                        pickerSourceName={`${sourceNamePrefix}picker`}
                      />
                    </BetSettingFormProvider>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}

          {/* EMPTY CASE */}
          {!gameAndCurrencyMixing.length && (
            <TableRow
              className={classes.rowRoot}
              style={{
                height: 100,
              }}
            >
              <TableCell>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#787878',
                  }}
                >
                  {translate('ra.message.betSettingFormWithoutProperty')}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BetSettingTable.defaultProps = {};

BetSettingTable.propTypes = {
  index: PropTypes.number.isRequired,
};

export default BetSettingTable;
