/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const QuickSetupErrorHandlingContext = createContext({
  defaultSettingValue: null,
  setDefaultSettingValue: noop,

  currentSettingDefaultValue: {},
  setCurrentSettingDefaultValue: noop,

  currentSettingValue: {},
  setCurrentSettingValue: noop,

  betSettingDefaultLoaded: false,
  setBetSettingDefaultLoaded: noop,

  getBetSettingDefault: noop,
});

export const useQuickSetupErrorHandling = () => useContext(QuickSetupErrorHandlingContext);

// ===============================================================

export const QuickSetupErrorHandlingProvider = ({ children }) => {
  const [quickSetupError, setQuickSetupError] = useState();

  return (
    <QuickSetupErrorHandlingContext.Provider
      value={{
        quickSetupError,
        setQuickSetupError,
      }}
    >
      {children}
    </QuickSetupErrorHandlingContext.Provider>
  );
};

QuickSetupErrorHandlingProvider.propTypes = {
  children: PropTypes.node,
};
QuickSetupErrorHandlingProvider.defaultProps = {
  children: null,
};

// ===============================================================

export const QuickSetupErrorHandlingConsumer = ({
  children, name,
}) => (
  <QuickSetupErrorHandlingContext.Consumer>
    {values => children({
      ...values,
      name,
    })}
  </QuickSetupErrorHandlingContext.Consumer>
);
QuickSetupErrorHandlingConsumer.displayName = 'consumer';

// ===============================================================

export const QuickSetupFieldValidationWrap = ({
  children, name,
}) => {
  const { quickSetupError } = useQuickSetupErrorHandling();
  // const isConsumerAsDirectlyChildren = get(children, 'type.displayName') === 'consumer';
  console.debug('[ERROR] quickSetupError', quickSetupError);
  return React.cloneElement(children, {
    name,
  });
};

QuickSetupFieldValidationWrap.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
};
QuickSetupFieldValidationWrap.defaultProps = {
  children: null,
};
