/* eslint-disable  */
import React from "react";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import WealthEditGuesser from "../../base/components/guesser/wealth-edit.guesser";
import WealthInputWrapper from "../../base/components/guesser/wealth-input-wrapper";
import CheatModeTemplateInput from "./components/template.input";
import {
  AutocompleteInput,
  ReferenceInput,
} from "../../base/components/ra/inputs";
import { FormDataConsumer, useLocale } from "react-admin";
import { checkTransatableField } from "../../services/util";

const WrapperInputGame = (props) => {
  const locale = useLocale();

  if (isEmpty(props?.record?.game)) {
    return null;
  }

  let optionText = "name";

  const isTranslatable = checkTransatableField(
    props.record?.game,
    "name",
    locale
  );

  if (isTranslatable) {
    optionText = `name.${locale}`;
  }

  return (
    <ReferenceInput
      {...props}
      source="game.id"
      label="resources.game.name"
      reference="game"
      fullWidth
      variant="outlined"
      disabled
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  );
};

const CheatModeTemplateEdit = (props) => {
  const customFields = ["scripts"];

  return (
    <WealthEditGuesser {...props} customFields={customFields}>
      <FormDataConsumer>
        {({ formData }) => <WrapperInputGame record={formData} />}
      </FormDataConsumer>

      <WealthInputWrapper source="scripts">
        <CheatModeTemplateInput />
      </WealthInputWrapper>
    </WealthEditGuesser>
  );
};

export default CheatModeTemplateEdit;
