import React from 'react';
import { useTranslate } from 'react-admin';
import { TextInput } from '../../../base/components/ra/inputs';
import resourceSlug from '../../../constant/resource-slug';

const ParentBetFilter = () => {
  const translate = useTranslate();

  return (
    <TextInput
      label={translate(`resources.${resourceSlug.PLAYER_STATE_MANAGEMENT}.fields.parentBet`)}
      source="parentBet||$eq"
      resettable
    />
  );
};

export default ParentBetFilter;
