/* eslint-disable */
import * as React from 'react';
import { createElement, useMemo, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { CoreAdminRouter, Ready } from 'ra-core';
import { useLocation } from 'react-router';
import { NotFound } from 'ra-ui-materialui';
import { useSelector } from 'react-redux';

import registerHooks from '../services/util/react-hooks-outside/register-hooks';
import { goToLoginPage } from '../services/util';
import { ReactHooksWrapper } from '../services/util/react-hooks-outside';
import ChangePasswordPage from '../base/components/layout/changePasswordPage';
import { APP_ROUTE } from '../constant';
import { registerViewType } from '../base/hooks/useViewType';
import { MetaDataProvider } from '../base/context/metadata/provider';
import { getBasePath } from '../services/util/stringUtil';
import { useAuthUser } from '../base/hooks';
import { hasAccessPermissionByRoleId } from '../constant/customRoutes';

registerHooks();

const DefaultLayout = ({ children }) => <>{children}</>;

const CoreAdminUI = ({
  catchAll = NotFound,
  children,
  customRoutes = [],
  dashboard,
  layout = DefaultLayout,
  loading = Noop,
  loginPage = false,
  logout,
  menu, // deprecated, use a custom layout instead
  ready = Ready,
  theme,
  title = 'React Admin',
  publicCustomRoutes,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { requireChangePassword } = useSelector((state) => state.auth);
  const userData = useAuthUser();

  // To detect the view type as one in [list/show/edit/create or...other]
  registerViewType(location.pathname);

  const logoutElement = useMemo(() => logout && createElement(logout), [logout]);

  // To remove custom routes if users don't have the permission to access
  const sanitizedCustomRoutes = useMemo(() => {
    const userRoleId = userData?.role?.id;
    const perms = userData?.perms?.filter((p) => p.create || p.read || p.update || p.delete);
    return customRoutes.filter((route) => {
      const accessibleRoleIds = route.props.accessibleRoleIds;
      const resource = route.props.resource;

      if (resource) {
        return Boolean(perms?.some((perm) => perm.resource.name === resource));
      } else {
        return hasAccessPermissionByRoleId(userRoleId, accessibleRoleIds);
      }
    });
  }, [customRoutes, userData]);

  const renderCustomRoutesWithoutLayout = (route, routeProps) => {
    if (route.props.render) {
      return route.props.render({
        ...routeProps,
        title,
      });
    }
    if (route.props.component) {
      return createElement(route.props.component, {
        ...routeProps,
        title,
      });
    }
  };

  useEffect(() => {
    if (requireChangePassword === true) {
      history.replace(APP_ROUTE.REQUIRE_CHANGE_PASSWORD_ROUTE);
    }
  }, [requireChangePassword]);

  return (
    <>
      <ReactHooksWrapper />
      <Switch>
        {loginPage !== false && loginPage !== true ? (
          <Route
            exact
            path="/login"
            render={(props) =>
              createElement(loginPage, {
                ...props,
                title,
                theme,
              })
            }
          />
        ) : null}

        {publicCustomRoutes
          .filter((route) => route.props.noLayout)
          .map((route, key) =>
            React.cloneElement(route, {
              key,
              render: (routeProps) =>
                renderCustomRoutesWithoutLayout(route, {
                  ...routeProps,
                  title,
                  theme,
                }),
              component: undefined,
            }),
          )}

        {/**
         * ** Change Password Page **
         * New account user must change their password at the first time login
         */}
        <Route
          exact
          path={APP_ROUTE.REQUIRE_CHANGE_PASSWORD_ROUTE}
          render={() => {
            if (requireChangePassword === null) {
              goToLoginPage();
              return null;
            }
            return <ChangePasswordPage />;
          }}
        />

        <Route
          path="/"
          render={(props) => (
            <MetaDataProvider basePath={getBasePath(location.pathname)}>
              <CoreAdminRouter
                catchAll={catchAll}
                customRoutes={sanitizedCustomRoutes}
                dashboard={dashboard}
                layout={layout}
                loading={loading}
                logout={logoutElement}
                menu={menu}
                ready={ready}
                theme={theme}
                title={title}
                {...props}
              >
                {children}
              </CoreAdminRouter>
            </MetaDataProvider>
          )}
        />
      </Switch>
    </>
  );
};

const Noop = () => null;

export default CoreAdminUI;
