/* eslint-disable  */
import React from "react";
import CustomRoleInput from "./components/custom-role.input";
import CustomGroupInput from "./components/custom-group.input";
import WealthCreateGuesser from "../../base/components/guesser/wealth-create.guesser";
import WealthInputWrapper from "../../base/components/guesser/wealth-input-wrapper";
import CustomBrandInput from "./components/custom-brand.input";
import { UserFormContextProvider } from "./context/user.context";

const UserCreate = (props) => {
  const transform = (data) => data;

  return (
    <UserFormContextProvider>
      {({ customFields, roleSelected }) => (
        <WealthCreateGuesser
          {...props}
          customFields={["role", "group", ...customFields]}
          transform={transform}
        >
          <WealthInputWrapper source="role">
            <CustomRoleInput />
          </WealthInputWrapper>
          {!customFields.includes("group") && (
            <WealthInputWrapper source="group">
              <CustomGroupInput />
            </WealthInputWrapper>
          )}
          {!customFields.includes("brands") && (
            <WealthInputWrapper source="brands">
              <CustomBrandInput />
            </WealthInputWrapper>
          )}
        </WealthCreateGuesser>
      )}
    </UserFormContextProvider>
  );
};

export default UserCreate;
