/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-game-performance.list';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import TranslatableField from '../../../../base/components/ra/fields/translatable.field';
import BaseCurrencyField from '../../../../base/components/reports/ReportTable/BaseCurrencyField';
import { useReportContext } from '../../../../base/components/reports/ReportLayout';

const PrepaidTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();

  return (
    <CustomReportTable
      title={translate('resources.report.prepaid')}
      reportType={REPORT_TYPES.PREPAID}
      reportList={LIST_REPORTS}
      resource={resourceSlug.REPORT_GAME_PERFORMANCE}
    >
      <ReferenceField link="show" source="gameId" reference="game" label="resources.game.fields.gameId">
        <TextField source="gameId" />
      </ReferenceField>
      <ReferenceField source="group" reference="group" label="resources.group.name" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="brand" reference="brand" label="resources.brand.name" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TranslatableField label="resources.report.fields.gameName" source="game" />
      <TextField label="resources.bet.fields.channel" source="channel" />
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField source="currency" reference="currency" label="resources.currency.name" link="show">
        <TextField source="code" />
      </ReferenceField>
      {Boolean(appliedFilters.consolidated) && <BaseCurrencyField label="resources.report.fields.baseCurrency" />}
      <TextField label="resources.report.fields.betAmount" source="betAmount" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
      <TextField source="date" label="resources.report.fields.date" sortable />
    </CustomReportTable>
  );
};

export default PrepaidTable;
