/* eslint-disable no-sparse-arrays, react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  useTheme,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import keys from 'lodash/keys';
import PropTypes from 'prop-types';
import {
  QuickSetupErrorHandlingConsumer,
  QuickSetupFieldValidationWrap,
  useQuickSetupErrorHandling,
} from '../utils/QuickSetupErrorHandling';
import { BrandInformationBlock, Title } from './HelperComponents';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      id={index}
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  tabsRoot: {
    display: 'flex',
  },
  tabsScroller: {
    width: 'calc(100vw - 150px)',
  },
  tabsScrollButtonsDesktop: {},
  tabTitle: {
    fontWeight: '600',
    border: '2px dashed black',
    padding: '10px 10px',
    width: '200px',
    borderRadius: '8px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
  },
}));

const QuickSetupPreview = props => {
  const { groupInfo } = props;
  const muiTheme = useTheme();
  const translate = useTranslate();
  const classes = useStyles();

  const { values: formValues } = useFormState();

  const { setQuickSetupError } = useQuickSetupErrorHandling();

  const [previewData, setPreviewData] = useState();
  const [currBrandTab, setCurrBrandTab] = useState(0);

  const resources = useSelector(({ admin }) => admin.resources);
  const groupDataFetched = get(resources, 'group.data');
  const gameDataFetched = get(resources, 'game.data');
  const languageDataFetched = get(resources, 'language.data');
  const currencyDataFetched = get(resources, 'currency.data');

  const reference = {
    group: groupDataFetched,
    game: gameDataFetched,
    language: languageDataFetched,
    currency: currencyDataFetched,
  };

  useEffect(() => {
    // TODO: validate formValues
    // if valid => render preview page
    if (!isEmpty(formValues)) {
      const {
        group, brands,
      } = formValues;
      const newPreviewData = {
        group,
        brands,
      };

      setPreviewData(newPreviewData);
    }
  }, [formValues]);

  useEffect(() => {
    // Clean error messages
    setQuickSetupError({});
  }, [formValues]);

  if (!previewData) {
    return null;
  }

  return (
    <Box>
      <Card>
        <CardContent>
          {groupInfo}
          <Title>{translate('ra.text.brandsInformation')}</Title>
          <Box
            style={{
              gap: '20px',
              display: 'flex',
              padding: '0',
              flexWrap: 'wrap',
              paddingBottom: '16px',
            }}
          >
            <Box>
              <Tabs
                classes={{
                  root: classes.tabsRoot,
                  scroller: classes.tabsScroller,
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                value={currBrandTab}
                onChange={(event, currValue) => {
                  setCurrBrandTab(currValue);
                }}
              >
                {previewData?.brands?.map((bItem, bIndex) => (
                  <Tab
                    key={bItem.id}
                    id={bIndex}
                    scrollButtons="auto"
                    label={(
                      <QuickSetupFieldValidationWrap name={`brands.${bIndex}`}>
                        <QuickSetupErrorHandlingConsumer>
                          {({
                            quickSetupError, name,
                          }) => {
                            const brandHasErr = keys(quickSetupError).some(
                              errKey => errKey.startsWith(name),
                            );
                            const activeColor = currBrandTab === bIndex ? muiTheme.palette.primary.light : undefined;
                            return (
                              <Tooltip
                                title={bItem.name}
                                placement="top"
                              >
                                <Typography
                                  className={classes.tabTitle}
                                  style={{
                                    color: brandHasErr
                                      ? muiTheme.palette.error.main
                                      : activeColor,
                                    borderColor: currBrandTab === bIndex ? muiTheme.palette.primary.light : '#000000',
                                  }}
                                >
                                  {bItem.name}
                                </Typography>
                              </Tooltip>
                            );
                          }}
                        </QuickSetupErrorHandlingConsumer>
                      </QuickSetupFieldValidationWrap>
                    )}
                  />
                ))}
              </Tabs>
              {previewData?.brands?.map((bItem, bIndex) => (
                <TabPanel
                  key={bItem.id}
                  value={currBrandTab}
                  index={bIndex}
                >
                  <BrandInformationBlock
                    brandIndex={bIndex}
                    data={bItem}
                    reference={reference}
                  />
                </TabPanel>
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

QuickSetupPreview.defaultProps = {
  groupInfo: {},
};

QuickSetupPreview.propTypes = {
  groupInfo: PropTypes.object,
};

export default QuickSetupPreview;
