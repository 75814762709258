import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Box, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {
  BooleanInput,
} from '../../../base/components/ra/inputs';

const ConfigField = props => {
  const {
    label, configFields, sourcePrefix, description,
  } = props;
  const translate = useTranslate();

  console.log('!@@@@@', configFields);

  return (
    <Box>
      {translate(label)}
      <Tooltip
        arrow
        placement="top"
        title={translate(description || '')}
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      {configFields.map(cfField => (
        <BooleanInput
          key={cfField.source}
          label={cfField.label}
          source={`${sourcePrefix}${cfField.source}`}
          defaultValue={cfField.defaultValue}
        />
      ))}
    </Box>
  );
};

ConfigField.defaultProps = {
  sourcePrefix: '',
  description: '',
};

ConfigField.propTypes = {
  label: PropTypes.string.isRequired,
  configFields: PropTypes.string.isRequired,
  sourcePrefix: PropTypes.string,
  description: PropTypes.string,
};

export default ConfigField;
