import React from 'react';
import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';
import { useStyles } from '../../guesser/wealth-filter.guesser.utils';

const ReferenceAutocompleteInput = ({
  inputProps, source, reference, ...otherProps
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <ReferenceInput
      className={classes.fieldStyle}
      reference={reference}
      source={source}
      filterToQuery={value => ({
        name: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
      })}
      perPage={null} // To remove initialPageSize (25) from default filter values
      {...otherProps}
    >
      <AutocompleteInput
        emptyText={`--${translate('ra.text.all').toUpperCase()}--`}
        resettable
        variant="outlined"
        optionText="name"
        {...inputProps}
      />
    </ReferenceInput>
  );
};

export default ReferenceAutocompleteInput;

ReferenceAutocompleteInput.propTypes = {
  inputProps: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};

ReferenceAutocompleteInput.defaultProps = {
  inputProps: {},
};
