import React, { useImperativeHandle } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Footer from '../../../../base/components/layout/footer';

const useStyles = makeStyles(theme => ({
  appMainContentWrapper: {
    position: 'relative',
    paddingBottom: 30,
    width: '100%',
    margin: '0 auto',

    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  appMainContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    boxSizing: 'border-box',
  },
  appMainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    boxSizing: 'border-box',
  },
  extendHeight: {
    [theme.breakpoints.down('md')]: {
      height: '72px !important',
    },
    height: ({ height }) => (height ? `${height}px` : '200px'),
  },
  footer: {
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: '0.5rem',
  }
}));

const CmtContent = React.forwardRef((props, ref) => {
  const {
    children, header, height,
  } = props;
  useImperativeHandle(ref, () => ({}));
  const contentRef = React.createRef();
  const classes = useStyles({
    height,
  });

  return (
    <>
    <Box
      className={classes.appMainContentWrapper}
      bgcolor="background.main"
    >
      <div className={classes.extendHeight} />
      <Box className={classes.appMainContentHeader}>{header}</Box>
      <Box
        ref={contentRef}
        className={classes.appMainContent}
      >
        {children}
      </Box>
    </Box>
    <Footer />
    </>
  );
});

export default CmtContent;
CmtContent.defaultProps = {
  name: 'LayoutContent',
};
