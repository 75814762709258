function getCookie(cookieName) {
  const cookieValue = document.cookie
    ? document.cookie
      .split('; ')
      .find(row => row.startsWith(cookieName))
      ?.split('=')[1]
    : undefined;
  return cookieValue;
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

function removeCookie(cname) {
  setCookie(cname, '', -1);
}

const Cookie = {
  getItem: getCookie,
  setItem: setCookie,
  removeItem: removeCookie,
};

export default Cookie;
