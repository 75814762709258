import { useState, useEffect } from 'react';
import Axios from 'axios';

import rcSlug from '../../../constant/resource-slug';

const useGettingSpinTypes = ({ gameId }) => {
  const [listSpinTypes, setListSpinTypes] = useState([]);

  const getListSpinType = async () => {
    try {
      const { data: listGameTypeData } = await Axios.get(
        `api/${rcSlug.GAME}/${gameId}/config`,
      );
      if (listGameTypeData) {
        setListSpinTypes(listGameTypeData);
      } else {
        setListSpinTypes([]);
      }
    } catch (err) {
      console.error('[ERROR] get list spin types\n', err.message);
    }
  };

  useEffect(() => {
    if (gameId) {
      getListSpinType();
    }
  }, [gameId]);

  return {
    listSpinTypes,
  };
};

export default useGettingSpinTypes;
