/* eslint-disable react/prop-types, no-shadow */
import * as React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import get from 'lodash/get';
import { Box, Link } from '@material-ui/core';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { DateField, IdField, JsonField } from '../../base/components/ra/fields';
import resourceSlug from '../../constant/resource-slug';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';

const excludeFields = [
  {
    source: 'group',
    optionText: 'name',
    customOptionText: 'code',
  },
  {
    source: 'brand',
    optionText: 'name',
    customOptionText: 'code',
  },
  {
    source: 'currency',
    optionText: 'name',
    customOptionText: 'code',
  },
];

const CustomField = ({
  record,
  source,
  resource,
  optionText = 'name',
  formatOptionText,
  customOptionText = 'name',
  formatCustomOptionText,
  ...props
}) => {
  if (get(record, `${source}.id`)) {
    return (
      <ReferenceField
        record={record}
        source={`${source}.id`}
        reference={source}
        label={`resources.${resource}.fields.${source}`}
        link={record => `/${source}/${get(record, `${source}.id`)}/show`}
        {...props}
      >
        {formatOptionText === 'id' ? <IdField source="id" /> : <TextField source={optionText} />}
      </ReferenceField>
    );
  }

  return formatCustomOptionText === 'id' ? (
    <IdField
      {...props}
      record={record}
      source={`${source}.${customOptionText}`}
    />
  ) : (
    <TextField
      {...props}
      record={record}
      source={`${source}.${customOptionText}`}
    />
  );
};

const TransactionTransferList = props => (
  <WealthListGuesser
    {...props}
    excludeFields={[...excludeFields.map(({ source }) => source), 'player', 'wallet', 'payload', 'created']}
    fieldOrder={[
      'id',
      'created',
      'nativeTransactionId',
      'type',
      'status',
      'group',
      'brand',
      'game.id',
      'player',
      'wallet',
      'bet.id',
      'amount',
      'currency',
      'payload',
      'requestHeader',
      'responseHeader',
      'res',
    ]}
  >
    {excludeFields.map(({
      source, optionText, formatOptionText, customOptionText, formatCustomOptionText, label,
    }) => (
      <CustomField
        source={source}
        key={source}
        optionText={optionText}
        formatOptionText={formatOptionText}
        customOptionText={customOptionText}
        formatCustomOptionText={formatCustomOptionText}
        sortable={false}
        label={label}
      />
    ))}
    <CustomLinkField
      source="player"
      href={record => `/#/${resourceSlug.PLAYER}/${record.player?.id}/show`}
      title={record => (record.player?.id ? transformIdValue(record.player?.id) : '')}
      component={record => (record.player?.id ? Link : Box)}
      sortable={false}
    />
    <CustomLinkField
      source="wallet"
      href={record => `/#/${resourceSlug.WALLET}/${record.wallet?.id}/show`}
      title={record => (record.wallet?.id ? transformIdValue(record.wallet?.id) : '')}
      component={record => (record.wallet?.id ? Link : Box)}
      sortable={false}
    />
    <JsonField
      {...props}
      source="payload"
      sortable={false}
      jsonString
      reactJsonOptions={{
        name: null,
        collapsed: true,
        enableClipboard: true,
        displayDataTypes: false,
      }}
    />

    <DateField
      {...props}
      source="created"
    />
  </WealthListGuesser>
);

export default TransactionTransferList;
