/* eslint-disable no-undef, react/require-default-props, react/default-props-match-prop-types, no-unused-vars, react/prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';

const sanitizeFieldRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  emptyText,
  formClassName,
  fullWidth,
  headerClassName,
  label,
  linkType,
  link,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  sortByOrder,
  source,
  textAlign,
  translateChoice,
  ...props
}) => props;

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
    },
  },
  {
    name: 'RaBooleanField',
  },
);

const TRULY_VALUES = [true, 'true', 1, '1'];
const FALSY_VALUES = [false, 'false', 0, '0'];

export const BooleanField = memo(props => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    record = {},
    valueLabelTrue,
    valueLabelFalse,
    TrueIcon,
    FalseIcon,
    ...rest
  } = props;

  const translate = useTranslate();
  const classes = useStyles(props);
  const value = get(record, source);
  let ariaLabel = value ? valueLabelTrue : valueLabelFalse;

  if (!ariaLabel) {
    ariaLabel = value === false ? 'ra.boolean.false' : 'ra.boolean.true';
  }

  if (TRULY_VALUES.includes(value) || FALSY_VALUES.includes(value)) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={classnames(classes.root, className)}
        {...sanitizeFieldRestProps(rest)}
      >
        <Tooltip
          title={translate(ariaLabel, {
            _: ariaLabel,
          })}
        >
          {TRULY_VALUES.includes(value) ? (
            <TrueIcon
              data-testid="true"
              fontSize="small"
            />
          ) : (
            <FalseIcon
              data-testid="false"
              fontSize="small"
            />
          )}
        </Tooltip>
      </Typography>
    );
  }

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {emptyText}
    </Typography>
  );
});

BooleanField.defaultProps = {
  addLabel: true,
  TrueIcon: DoneIcon,
  FalseIcon: ClearIcon,
};

BooleanField.propTypes = {
  valueLabelFalse: PropTypes.string,
  valueLabelTrue: PropTypes.string,
  TrueIcon: PropTypes.elementType,
  FalseIcon: PropTypes.elementType,
};

export default BooleanField;
