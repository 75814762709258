import React from 'react';
import {
  SimpleForm,
  ReferenceInput,
  Create,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TextInput,
  DateTimeInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
} from 'react-admin';
import { PrizeTableInput } from '../../base/components/ra/inputs';

const TournamentCreate = props => (
  <Create {...props}>
    <SimpleForm
      warnWhenUnsavedChanges
      redirect="show"
    >
      <ReferenceInput
        label="resources.game.name"
        source="game"
        reference="game"
        fullWidth
        perPage={100}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <TextInput
        fullWidth
        source="name"
      />

      <TextInput
        fullWidth
        source="desc"
      />

      <NumberInput
        fullWidth
        source="minBet"
      />

      <DateTimeInput
        fullWidth
        source="startDate"
      />
      <DateTimeInput
        fullWidth
        source="endDate"
      />

      <BooleanInput
        fullWidth
        source="isAllPlayer"
      />
      <FormDataConsumer>
        {({ formData }) => !formData.isAllPlayer && (
          <ReferenceArrayInput
            label="resources.player.name"
            reference="player"
            source="players"
          >
            <AutocompleteArrayInput
              optionText="name"
              optionValue="id"
            />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
      <PrizeTableInput
        label="Prize Table"
        source="value"
      />
    </SimpleForm>
  </Create>
);

TournamentCreate.propTypes = {};

export default TournamentCreate;
