import { themeAntDesignCheatEnv } from './themeAntDesignCheatEnv';

export const theme = {
  palette: {
    primary: {
      main: '#036D53',
      light: '#29875B',
    },
    sidebar: {
      textActiveColor: '#036D53',
    },
    horizontalNav: {
      textActiveColor: '#036D53',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          '&:hover': {
            color: '#29D76B',
          },
        },
        ...themeAntDesignCheatEnv,
      },
    },
    RaReferenceField: {
      link: {
        color: '#29875B',
        '&:hover': {
          color: '#29D76B',
        },
      },
    },
  },
};
