import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@material-ui/core';

const NameField = props => {
  const {
    record, source = 'name', resource,
  } = props;

  const nameValue = record?.[source] || record.record?.[source];
  const id = record?.id || record.record?.id;

  return (
    <Box>
      {resource
        ? (
          <Link
            href={`/#/${resource}/${id}/show`}
          >
            {nameValue}
          </Link>
        )
        : nameValue}
    </Box>
  );
};

NameField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
};
NameField.defaultProps = {
  record: {},
  source: 'name',
  resource: undefined,
};

export default NameField;
