import React from 'react';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';

const BasicAuth = {
  onRegister: ({
    name, email, password,
  }) => dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(fetchSuccess());
      const user = {
        name,
        email,
        password,
      };
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setAuthUser(user));
    }, 300);
  },

  onLogin: ({
    email, password,
  }) => dispatch => {
    try {
      dispatch(fetchStart());

      setTimeout(() => {
        const user = {
          name: 'Admin',
          email,
          password,
        };
        dispatch(fetchSuccess());
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  },
  onLogout: () => dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(fetchSuccess());
      localStorage.removeItem('user');
      dispatch(setAuthUser(null));
    }, 300);
  },

  getAuthUser: (loaded = false) => dispatch => {
    dispatch(fetchStart());
    dispatch(updateLoadUser(loaded));

    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
    }, 300);
  },

  onForgotPassword: () => dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(setForgetPassMailSent(true));
      dispatch(fetchSuccess());
    }, 300);
  },
  getSocialMediaIcons: () => <> </>,
};

export default BasicAuth;
