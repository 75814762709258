import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import InfinitePagination from '../../../base/components/ra/list/InfinitePagination';
import { cacheBetsByPage, updateCurrentBetPage } from '../../../services/redux/bet/bet.action';
import resourceSlug from '../../../constant/resource-slug';
import { sanitizeObject } from '../../../services/util';

const BetPagination = () => {
  const {
    betList, currentBetPage,
  } = useSelector(({ bet }) => bet);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();

  const disableNext = useMemo(() => !betList?.[currentBetPage]?.nextPagination, [currentBetPage, betList]);

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!betList[newPage]) {
          const nextPagination = betList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(resourceSlug.BET, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheBetsByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentBetPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [betList, currentBetPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentBetPage === 1}
      onNextPage={() => {
        handlePageChange(currentBetPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentBetPage - 1);
      }}
      currentPage={currentBetPage}
    />
  );
};

export default BetPagination;
