/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import noop from 'lodash/noop';

import Picker from '../../../../services/util/rmc-picker-scroll/Picker';

// Picker style
import '../../../../services/util/rmc-picker-scroll/picker-style.css';

// Constants
const TIME_TO_START_SCROLL = 100; // 100ms
const TIME_TO_END_SCROLL = 100; // 100ms

const useClasses = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',

    '& .rmc-picker-mask': {
      pointerEvents: 'none',
      backgroundImage: `
        linear-gradient(
          to bottom,
          rgb(255 255 255 / 95%),
          rgb(255 255 255 / 35%)
        ),
        linear-gradient(
          to top,
          rgb(255 255 255 / 95%),
          rgb(255 255 255 / 35%)
        )
      `,
    },

    '& .rmc-picker-indicator': {
      pointerEvents: 'none',
    },
  },
  pickerColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  pickerWrapper: {
    background: '#B1B1B1',
    maxWidth: ({ width }) => width || 300,
    width: '100%',
    height: 238,
    border: '1px solid #959595',
    borderRadius: 4,
    overflow: 'hidden',
  },
  pickerTitle: {
    textAlign: 'center',
    color: theme.palette.common.black,
    fontWeight: 700,
    height: 32,
    lineHeight: '32px',
  },
  pickerItem: {
    cursor: 'default',
    position: 'relative',
    userSelect: 'none',
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.common.black,

    '&.rmc-picker-item-selected:hover .gmf-picker-item-tool svg': {
      opacity: ({ iconLeftDisplayWhenHover }) => iconLeftDisplayWhenHover ? 1 : 0,
    },
    '&.rmc-picker-item-selected:hover .gmf-picker-item-tool-item svg': {
      opacity: ({ iconLeftDisplayWhenHover }) => iconLeftDisplayWhenHover ? 1 : 0,
    },

    '&.rmc-picker-item-selected .gmf-picker-item-tool-item': {
      cursor: 'pointer',
    },

    '&.rmc-picker-item-selected': {
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.common.black,
    },
  },

  pickerItemTool: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'opacity 0.2s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    boxSizing: 'border-box',
  },
  pickerItemToolItem: {
    display: 'flex',
    alignItems: 'center',
  },
  pickerItemToolLeft: {
    marginRight: 'auto',
    '& svg': {
      opacity: 0,
    },
    '&.gmf-force-display svg': {
      opacity: 1,
    },
  },
  pickerItemToolRight: {
    marginLeft: 'auto',
    '& svg': {
      opacity: 0,
    },

    '&.gmf-force-display svg': {
      opacity: 1,
    },
  },
  hide: {
    '& svg': {
      opacity: '0 !important',
    }
  },
  show: {
    '& svg': {
      opacity: '1 !important',
    }
  },
}), {
  name: 'PickerColumn',
});

function PickerColumn(props) {
  const {
    name,
    title,
    listItem = [],
    selectedValue: selectedFromProps,
    onSelected = noop,
    // Left icon rendering
    generateIconLeft = noop,
    iconLeftForceDisplay = [],
    iconLeftOnClick = noop,
    iconLeftDisplayWhenHover = true,
    // Right icon rendering
    generateIconRight = noop,
    width,
    iconRightForceDisplay = [],
    iconRightOnClick = noop,
    // Rest props
    ...rest
  } = props;

  const classes = useClasses({
    width,
    iconLeftDisplayWhenHover,
  });

  const [selected, setSelected] = useState(selectedFromProps);
  const [startScroll, setStartScroll] = useState(false);

  const tOutDetectScroll = useRef(null);
  const selectedRef = useRef(null);

  // Fire when user click/touch to scrolling
  const handleStartScroll = () => {
    tOutDetectScroll.current = setTimeout(() => {
      setStartScroll(true);
    }, TIME_TO_START_SCROLL);
  };

  // Fire when user end scrolling
  const handleEndScroll = () => {
    if (tOutDetectScroll.current) {
      clearTimeout(tOutDetectScroll.current);
    }

    setTimeout(() => {
      setStartScroll(false);
    }, TIME_TO_END_SCROLL);

    if (typeof onSelected === 'function') {
      onSelected(selectedRef.current, 'scroll-end');
    }
  };

  const handleIconLeftClick = value => {
    if (typeof iconLeftOnClick === 'function' && selected === value) {
      iconLeftOnClick(value);
    }
  };

  useEffect(() => {
    setSelected(selectedFromProps);
    selectedRef.current = selectedFromProps;
  }, [selectedFromProps]);

  useEffect(() => {
    if (listItem.length === 1) {
      setSelected(listItem[0].value);
      selectedRef.current = listItem[0].value;
    }
  }, [listItem.length]);

  return (
    <Box className={classes.wrapper}>
      <Box
        className={classes.pickerColumn}

        onMouseDown={handleStartScroll}
        onMouseUp={handleEndScroll}
        onTouchStart={handleStartScroll}
        onTouchEnd={handleEndScroll}
      >
        <Box className={classes.pickerTitle}>{title}</Box>

        <Box className={classes.pickerWrapper}>
          <Picker
            indicatorClassName="gmf-picker-indicator"
            indicatorStyle={{
              position: 'relative',
            }}
            indicatorChildren={null}
            selectedValue={selected}
            onScrollChange={value => {
              selectedRef.current = value;
            }}
            onValueChange={value => {
              if (typeof onSelected === 'function') {
                onSelected(value);
              }
            }}
            {...rest}
          >
            {Array.isArray(listItem) && listItem.map(({ value }) => (
              <Picker.Item
                key={value}
                className={classNames('gmf-picker-view-item', classes.pickerItem)}
                value={value}
              >
                <Box>{value}</Box>
                <Box
                  className={classNames('gmf-picker-item-tool', classes.pickerItemTool)}
                >
                  {generateIconLeft && (
                    <Box
                      onClick={() => handleIconLeftClick(value)}
                      className={classNames({
                        'gmf-picker-item-tool-item': true,
                        [classes.pickerItemToolItem]: true,
                        [classes.pickerItemToolLeft]: true,
                        'gmf-force-display': iconLeftForceDisplay.includes(value),
                        [classes.hide]: startScroll && !iconLeftForceDisplay.includes(value),
                      })}
                    >
                      {generateIconLeft(value)}
                    </Box>
                  )}
                  {generateIconRight(value) && (
                    <Box
                      onClick={iconRightOnClick.bind(null, value)}
                      className={classNames({
                        'gmf-picker-item-tool-item': true,
                        [classes.pickerItemToolItem]: true,
                        [classes.pickerItemToolRight]: true,
                        'gmf-force-display': iconRightForceDisplay.includes(value),
                        [classes.hide]: startScroll && !iconRightForceDisplay.includes(value),
                      })}
                    >
                      {generateIconRight(value)}
                    </Box>
                  )}
                </Box>
              </Picker.Item>
            ))}
          </Picker>
        </Box>
      </Box>
    </Box>
  );
}

export default PickerColumn;
