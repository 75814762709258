import React from 'react';
import { useLocale, useNotify, useTranslate } from 'react-admin';
import { makeStyles, Box } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  btn: {
    cursor: 'pointer',
  },
}));

const CustomLabel = React.memo(props => {
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const locale = useLocale();

  const {
    realResource, record, source, isCopy, translatableInput,
  } = props;

  let title = translate(`resources.${realResource}.fields.${source}`);
  // TRANSLATABLE_INPUT
  if (translatableInput) {
    title = `${title} (${locale})`;
  }

  return (
    <Box
      display="inline-flex"
      alignItems="center"
    >
      <span>{title}</span>

      {isCopy && (
        <Box
          display="inline"
          ml={3}
          className={classes.btn}
          onClick={e => {
            e.preventDefault();
            navigator.clipboard.writeText(record[source]);
            notify('Copied', 'success');
          }}
        >
          <FileCopyIcon />
        </Box>
      )}
    </Box>
  );
});

CustomLabel.propTypes = {
  realResource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  isCopy: PropTypes.object,
  translatableInput: PropTypes.bool,
};

CustomLabel.defaultProps = {
  isCopy: undefined,
  translatableInput: false,
};

export default CustomLabel;
