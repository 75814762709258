import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import queryString from 'querystring';
import isEmpty from 'lodash/isEmpty';

const FormContext = React.createContext(null);

export const useFilterFormContext = () => useContext(FormContext);

export const FormContextProvider = ({
  children, initValues = {}, initShowPanel = false,
}) => {
  const location = useLocation();
  const refValues = useRef(initValues);
  const refValueGroup = useRef({});
  const searchingCounter = useRef(0);
  const [filterDisplayed, setFilterDisplayed] = useState(initShowPanel);

  const [showReport, setShowReport] = useState(() => {
    try {
      const { filter = '{}' } = queryString.parse(location.search.substring(1)) || {};

      if (!isEmpty(JSON.parse(filter))) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  });

  const setValues = (values = {}) => {
    refValues.current = {
      ...refValues.current,
      ...values,
    };
  };

  const getValues = () => refValues.current;

  const setValueGroup = (values = {}) => {
    refValueGroup.current = {
      ...refValueGroup.current,
      ...values,
    };
  };

  const startSearching = () => {
    searchingCounter.current += 1;
  };

  const getNumberOfSearch = () => searchingCounter.current;

  return (
    <FormContext.Provider
      value={{
        values: refValues.current,
        setValues,
        getValues,
        startSearching,
        getNumberOfSearch,
        showReport,
        setShowReport,
        refValueGroup: refValueGroup.current,
        setValueGroup,
        filterDisplayed,
        setFilterDisplayed,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

FormContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  initValues: PropTypes.object,
  initShowPanel: PropTypes.bool,
};

FormContextProvider.defaultProps = {
  initValues: {},
  initShowPanel: false,
};
