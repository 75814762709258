/* eslint react/prop-types: 0 , react/destructuring-assignment: 0 */
import React, { memo, useEffect, useRef, useMemo } from 'react';
import { useForm, useField } from 'react-final-form';
import { required } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import {
  ReferenceInput,
  AutocompleteInput,
} from '../../../base/components/ra/inputs';
import { useAuthUser, useApiProperties } from '../../../base/hooks';
import { useUserFormContext } from '../context/user.context';
import { RoleAccessibility } from '../../../services/util';

const CustomAutocompleteInput = props => {
  const {
    setRoleSelected, setCustomFields, optionText,
  } = useUserFormContext();

  const form = useForm();

  const refMount = useRef();

  useEffect(() => {
    if (!refMount.current && !isEmpty(props.record)) {
      const excludeFields = props.record.role?.type === 'SYSTEM' ? ['brands'] : [];
      setCustomFields(excludeFields);

      refMount.current = true;
    }
  }, [props.record]);

  return (
    <AutocompleteInput
      {...props}
      optionText={optionText}
      fullwidth
      onSelect={role => {
        setRoleSelected(role);
        const excludeFields = role?.type === 'SYSTEM' ? ['group', 'brands'] : [];
        setCustomFields(excludeFields);

        if (excludeFields.length) {
          if (!props.isEdit) {
            form.change('group', null);
          }
          form.change('brands', null);
        }
      }}
    />
  );
};

const CustomRoleInput = ({
  isEdit, ...props
}) => {
  useField('role.id');
  const form = useForm();
  const user = useAuthUser();

  const { getPropertiesByFieldName } = useApiProperties();
  const properties = getPropertiesByFieldName('role');

  const optionText = properties.optionText || 'name';

  const { record } = props;
  const roleType = record?.role?.type;

  const filter = useMemo(
    () => (roleType
      ? {
        type: record.role.type,
      }
      : {}),
    [roleType],
  );

  filter['enabled||$eq'] = true;
  filter['level||$lt'] = user?.role?.level;

  if (isEdit) {
    filter.accessibility = record?.role?.accessibility;
    if (
      record?.role?.accessibility === RoleAccessibility.CUSTOM_ROLE
      && form.getState().values?.group?.id
    ) {
      // filter.$or = [`group.id||$eq||${form.getState().values?.group?.id}`];
      filter['group.id'] = form.getState().values?.group?.id;
    }
  }

  return (
    <ReferenceInput
      source="role.id"
      reference="role"
      label="resources.user.fields.role"
      perPage={properties.choiceLimit || 100}
      sort={{
        field: properties.choiceSort?.field || optionText,
        order: properties.choiceSort?.order || 'ASC',
      }}
      filter={filter}
      validate={[required()]}
    >
      <CustomAutocompleteInput
        {...props}
        isEdit={isEdit}
        optionText={optionText}
      />
    </ReferenceInput>
  );
};

export default memo(CustomRoleInput);
