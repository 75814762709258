/* eslint-disable  */
import React from "react";
import { ReferenceArrayInput, ReferenceField, TextField } from "react-admin";
import WealthEditGuesser from "../../base/components/guesser/wealth-edit.guesser";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
} from "../../base/components/ra/inputs";

const CheatModeLinkEdit = (props) => {
  return (
    <WealthEditGuesser {...props}>
      <ReferenceInput
        {...props}
        source="groupId"
        reference="group"
        fullWidth
        variant="outlined"
        disabled
        index={3}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        {...props}
        source="brandId"
        reference="brand"
        fullWidth
        variant="outlined"
        disabled
        index={2}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        {...props}
        source="user.id"
        label="resources.user.name"
        reference="user"
        fullWidth
        variant="outlined"
        disabled
        index={1}
      >
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
    </WealthEditGuesser>
  );
};

export default CheatModeLinkEdit;
