/* eslint-disable */
import React from 'react';
import ReportLayout from '../../base/components/reports/ReportLayout';
import DetailPlayerReportResults from './components/DetailPlayerReportResults';
import { REPORT_TYPES } from '../../constant/resource-slug';
import DetailPlayerReportFilters from './components/DetailPlayerReportFilters';

export const LIST_REPORTS = [REPORT_TYPES.OVERALL, REPORT_TYPES.PREPAID, REPORT_TYPES.STANDARD];

function ReportDetailPlayerList() {
  return (
    <ReportLayout
      totalTables={LIST_REPORTS.length}
      filterChildren={<DetailPlayerReportFilters />}
      resultChildren={<DetailPlayerReportResults />}
    />
  );
}

export default ReportDetailPlayerList;
