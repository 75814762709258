/* eslint-disable */
import React from 'react';
import { useTranslate } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textarea: {
    width: '100%',
    resize: 'none',
    border: 'none',
    background: 'transparent',

    '&:focus-visible': {
      outline: 'none',
    },
  },
}));

const MultilineFieldReview = ({
  value, label,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box
      padding="10px 16px"
      marginBottom="20px"
      style={{
        background: '#F0F1F2',
        borderRadius: '4px',
      }}
    >
      <Box
        style={{
          marginTop: '8px',
          marginBottom: '4px',
        }}
      >
        <Box
          component="legend"
          style={{
            color: '#A5A5A5',
            width: 'fit-content',
            fontSize: '10px',
            fontWeight: '500 !important',
            lineHeight: 1,
          }}
        >
          {translate(label).toUpperCase()}
        </Box>
        <Box>
          <textarea
            readOnly
            rows="5"
            className={classes.textarea}
            value={value}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MultilineFieldReview;
