/* eslint-disable  */
import { FormDataConsumer, required } from "ra-core";
import { SimpleForm, ReferenceInput } from "react-admin";
import React from "react";
import {
  BooleanInput,
  SelectInput,
  TextInput,
  AutocompleteInput,
} from "../../base/components/ra/inputs";
import { Create } from "../../base/components/ra/views";
import { RoleTypeInput, RoleLevelInput } from "./components";
import {
  useAuthUser,
  useEnumOptions,
  useApiProperties,
} from "../../base/hooks";
import { RoleAccessibility, RoleType } from "../../services/util";

const RoleCreate = (props) => {
  const { getPropertiesByFieldName } = useApiProperties();
  const propertiesGroup = getPropertiesByFieldName("group");
  const groupOptionText =
    propertiesGroup.choiceSort?.field || propertiesGroup.optionText || "name";

  const transform = (data) => {
    const { level, name, enabled, type, group, accessibility } = data;

    return {
      level: Number(level),
      name,
      enabled,
      type,
      group,
      accessibility,
    };
  };

  let accessibilityEnum = useEnumOptions("role", "accessibility");

  const user = useAuthUser();

  if (user?.role?.type === RoleType.GROUP) {
    accessibilityEnum = accessibilityEnum.filter(
      (i) => i.id === RoleAccessibility.CUSTOM_ROLE
    );
  }

  const defaultFilterGroup = {
    enabled: true,
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <TextInput validate={required()} fullWidth source="name" />
        <SelectInput
          fullWidth
          source="accessibility"
          choices={accessibilityEnum}
          defaultValue={accessibilityEnum[0]?.id}
          validate={required()}
        />
        <RoleTypeInput source="type" user={user} />
        <RoleLevelInput source="level" user={user} />
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "GROUP" &&
            formData.accessibility === RoleAccessibility.CUSTOM_ROLE && (
              <ReferenceInput
                label="resources.role.fields.group"
                source="group.id"
                reference="group"
                validate={required()}
                fullWidth
                filterToQuery={(value) => ({
                  [groupOptionText]: value || "",
                })}
                disabled={user.group?.id}
                filter={
                  user.group?.id
                    ? { id: user.group.id, ...defaultFilterGroup }
                    : { ...defaultFilterGroup }
                }
                defaultValue={user.group?.id && user.group.id}
                perPage={propertiesGroup.choiceLimit || 100}
                sort={{
                  field: groupOptionText,
                  order: propertiesGroup.choiceSort?.order || "ASC",
                }}
              >
                <AutocompleteInput optionText={groupOptionText} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <BooleanInput fullWidth source="enabled" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default RoleCreate;
