/* eslint-disable */
import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDataProvider } from 'react-admin';
import { useReportContext } from '../ReportLayout';
import CustomReportTableLayout from './CustomReportTableLayout';
import PropTypes from 'prop-types';

const CustomReportTable = ({ title, reportType, reportList, resource, children, ...otherProps }) => {
  const dataProvider = useDataProvider();
  const {
    appliedFilters,
    setSort,
    setPage,
    setData: setAllData,
    setLoading: setLoadingAllData,
    setPerPage,
    query,
    page,
    perPage,
    sort,
  } = useReportContext();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const hasDataDisplayedRef = useRef(false);

  const reportIdx = reportList.findIndex((item) => item === reportType);

  const updatePagination = useCallback((currentValues, valueIndex, newValue) => {
    const clonedCurrentValues = [...currentValues];
    clonedCurrentValues[valueIndex] = newValue;

    return clonedCurrentValues;
  }, []);

  const getReportData = useCallback(async (filters) => {
    setLoading(true);
    setData({});

    const { sort, page, perPage, type, ...otherFilters } = filters;

    try {
      const { data } = await dataProvider.getCustomList(resource, {
        filter: {
          ...otherFilters,
          q: {
            type: reportType || type,
          },
        },
        sort: {
          field: sort?.[reportIdx]?.split(',')?.[0] || 'id',
          order: sort?.[reportIdx]?.split(',')?.[1] || 'ASC',
        },
        pagination: {
          page: page?.[reportIdx],
          perPage: perPage?.[reportIdx],
        },
      });

      setData(data);
    } catch {
      // Do nothing
    } finally {
      setLoading(false);
      setLoadingAllData(false);
    }
  }, []);

  useEffect(() => {
    const hasDataDisplayed = Boolean(Object.values(data)?.length);
    hasDataDisplayedRef.current = hasDataDisplayed;
  }, [data]);

  useEffect(() => {
    setAllData((currentData) => ({ ...currentData, [reportType]: data }));
  }, [data]);

  useEffect(() => {
    if (!Object.keys(query)?.length) return;
    getReportData({ ...appliedFilters, sort, perPage, page });
  }, [appliedFilters, query]);

  useEffect(() => {
    if (!hasDataDisplayedRef.current) return;
    getReportData({ ...appliedFilters, sort, perPage, page });
  }, [sort[reportIdx], perPage[reportIdx], page[reportIdx]]);

  if (!loading && !data?.total) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 12,
      }}
    >
      {Boolean(title) && <Typography variant="h4">{title}</Typography>}
      <CustomReportTableLayout
        resource={resource}
        data={data?.data}
        total={data?.total}
        page={page[reportIdx]}
        currentSort={{
          field: sort[reportIdx]?.split(',')?.[0] || 'id',
          order: sort[reportIdx]?.split(',')?.[1] || 'ASC',
        }}
        setPage={(newPage) => setPage(updatePagination(page, reportIdx, newPage))}
        setSort={(newField, newOrder) => setSort(updatePagination(sort, reportIdx, `${newField},${newOrder}`))}
        setPerPage={(newPerPage) => setPerPage(updatePagination(perPage, reportIdx, newPerPage))}
        perPage={perPage[reportIdx]}
        loading={loading}
        {...otherProps}
      >
        {children}
      </CustomReportTableLayout>
    </Box>
  );
};

CustomReportTable.propTypes = {
  reportType: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  reportList: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
};

CustomReportTable.defaultProps = {
  children: null,
  title: null,
};

export default CustomReportTable;
