/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDataProvider } from 'ra-core';
import axios from 'axios';
import { canI, RbacAction } from '../../../services/provider/rbacProvider';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import { SmallButton } from '../../../base/components/ra/buttons';
import { BET_STATUS_TO_VIEW_REPLAY } from '../../../constant';

export const ActionMenu = props => {
  const {
    record, resource, permissions,
  } = props;

  const hasEdit = record?.betStatus === 'PENDING' && canI(RbacAction.UPDATE, resource, permissions);

  const dataProvider = useDataProvider();
  const [reconciliation, setReconciliation] = useState(undefined);
  const [betReplayUrl, setBetReplayUrl] = useState('');

  const gotoReconciliationDetail = async reconciliationId => {
    if (reconciliationId) {
      window.open(`/#/seamless-log/${reconciliationId}/show`, '_blank');
    }
  };

  const checkReconciliation = async () => {
    try {
      const { data } = await dataProvider.getList('seamless-log', {
        pagination: {
          page: 1,
        },
        filter: {
          betId: record?.id,
        },
      });

      if (data?.length > 0) {
        setReconciliation(data[0]);
      }
    } catch {
      // Do nothing!
    }
  };

  const getReplayUrlFromServer = async ({
    betId, brandId,
  }) => {
    try {
      const { data: replayDetailUrl } = await axios.get(`api/bet/${betId}/replay-url?brandId=${brandId}`);
      setBetReplayUrl(replayDetailUrl);
    } catch (error) {
      console.debug('Error', error);
    }
  };

  useEffect(() => {
    if (['OPERATOR_UNKNOWN_ERROR', 'PENDING'].includes(record?.betStatus)) {
      checkReconciliation();
    }
  }, [record?.betStatus]);

  useEffect(() => {
    const {
      brandId, id: betId, betStatus,
    } = record || {};

    if (brandId && betId && BET_STATUS_TO_VIEW_REPLAY.includes(betStatus)) {
      getReplayUrlFromServer({
        brandId,
        betId,
      });
    }
  }, [record]);

  return (
    <ToolbarMenu
      {...props}
      hasEdit={hasEdit}
    >
      {betReplayUrl && (
        <Fade
          in
          timeout={200}
        >
          <SmallButton
            label="ra.action.replayBet"
            onClick={() => window.open(betReplayUrl, '_blank')}
            startIcon={<PlayArrowIcon />}
          />
        </Fade>
      )}

      {reconciliation?.id && (
        <Fade
          in
          timeout={200}
        >
          <SmallButton
            label="ra.action.reconciliationDetail"
            onClick={() => gotoReconciliationDetail(reconciliation?.id)}
            startIcon={<CallMissedOutgoingIcon />}
          />
        </Fade>
      )}
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: PropTypes.object,
};

ActionMenu.defaultProps = {
  record: {},
};
