import React from 'react';
import { FormDataConsumer, required } from 'ra-core';
import { useForm } from 'react-final-form';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import WealthInputWrapper from '../../base/components/guesser/wealth-input-wrapper';
import { NumberInput } from '../../base/components/ra/inputs';

const NumberInputCom = () => {
  const source = 'fixedRate';
  const form = useForm();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        if (formData.type === 'CL') {
          form.change(source, null);
          return null;
        }

        return (
          <>
            <NumberInput
              source={source}
              min={0}
              step={0.1}
              validate={[required()]}
              onChange={e => {
                let { value: correctValue } = e.target;

                if (correctValue == null || correctValue < 0) {
                  correctValue = 0;
                }

                form.change(source, correctValue);
              }}
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const FixedRatesEdit = props => {
  const transform = data => {
    const {
      type, fixedRate,
    } = data;

    return {
      type,
      fixedRate: parseFloat(fixedRate),
    };
  };

  return (
    <WealthEditGuesser
      {...props}
      customFields={['fixedRate']}
      transform={transform}
    >
      <WealthInputWrapper source="fixedRate">
        <NumberInputCom />
      </WealthInputWrapper>
    </WealthEditGuesser>
  );
};

export default FixedRatesEdit;
