import React from 'react';
import { useNotify, useTranslate } from 'react-admin';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';

const CheatModeCreate = props => {
  const notify = useNotify();
  const translate = useTranslate();

  const onAfterSave = () => {
    notify(
      translate('ra.text.generateCheatModeListMsg'),
      'info',
    );
  };

  return (
    <WealthCreateGuesser
      {...props}
      onAfterSave={onAfterSave}
    />
  );
};

export default CheatModeCreate;
