/* eslint-disable */
import React from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { useReportContext } from '../ReportLayout';
import { useStyles } from '../../guesser/report/search-panel';

const FormField = ({
  children, name, className, ...otherProps
}) => {
  const classes = useStyles();
  const { setCurrentFilters } = useReportContext();
  return (
    <Grid
      item
      className={clsx(classes.fieldStyle, classes.noWarning, classes.gridItem, className)}
    >
      <Field
        name={name}
        {...otherProps}
      >
        {children}
      </Field>
      <OnChange name={name}>
        {value => {
          setCurrentFilters(filters => ({
            ...filters,
            [name]: value,
          }));
        }}
      </OnChange>
    </Grid>
  );
};

export default FormField;
