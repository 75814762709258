import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { FieldTitle, useInput } from 'ra-core';
import { sanitizeInputRestProps, InputHelperText, InputPropTypes } from 'react-admin';
import clsx from 'clsx';

const useStyle = makeStyles(theme => ({
  checkBoxInput: {
    padding: 0,
    paddingRight: theme.spacing(2),
  },
  controlLabelRoot: {
    margin: theme.spacing(2, 0, 1, 0),
  },
}));

const BooleanInput = ({
  format,
  label,
  helperText,
  onBlur,
  onChange,
  onFocus,
  options,
  disabled,
  parse,
  resource,
  source,
  validate,
  // eslint-disable-next-line
  fullWidth,
  disabledError,
  ...rest
}) => {
  const {
    id,
    input: {
      onChange: finalFormOnChange, ...inputProps
    },
    isRequired,
    meta: {
      error, touched,
    },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const classes = useStyle();

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value);
    },
    [finalFormOnChange],
  );

  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        classes={{
          root: classes.controlLabelRoot,
        }}
        control={(
          <Checkbox
            id={id}
            color="primary"
            onChange={handleChange}
            classes={{
              root: classes.checkBoxInput,
            }}
            {...inputProps}
            {...options}
            disabled={disabled}
          />
        )}
        label={(
          <Box whiteSpace="nowrap">
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          </Box>
        )}
      />

      <FormHelperText
        className={clsx(disabledError && 'hidden')}
        error={!!error}
      >
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  );
};

BooleanInput.propTypes = {
  ...InputPropTypes,
  options: PropTypes.shape(Checkbox.propTypes),
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  disabledError: PropTypes.bool,
};

BooleanInput.defaultProps = {
  options: {},
  disabledError: false,
};

export default BooleanInput;
