import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import DateRangeInput from '../../guesser/date-range-input';

const TimeRangeInput = ({
  label, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <DateRangeInput
      showMillisecond
      label={translate(label)}
      {...otherProps}
    />
  );
};

export default TimeRangeInput;

TimeRangeInput.propTypes = {
  label: PropTypes.string,
};

TimeRangeInput.defaultProps = {
  label: '',
};
