/* eslint-disable react/require-default-props, react/prop-types */
import * as React from 'react';
import { makeStyles, Box, TextField as MuiTextField } from '@material-ui/core';
import {
  sanitizeInputRestProps,
  InputHelperText,
  useTranslate,
} from 'react-admin';
import { useInput, FieldTitle } from 'ra-core';
import { withStandardInputProps } from '../../../hoc/with-standard-input-props';

const TextField = withStandardInputProps(MuiTextField);

const stringifyObject = value => {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  return value;
};

const parseToObject = value => {
  let result = null;
  try {
    if (typeof value === 'string') {
      result = JSON.parse(value);
    }
  } catch {
    // Do nothing!
  }

  return result || value;
};

const useStyle = makeStyles(() => ({
  wealthJsonInput: {
    '& textarea': {
      width: '100%',
      resize: 'none',
    },
  },
}));

const JsonRawInput = ({
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = parseToObject,
  format = stringifyObject,
  resource,
  source,
  validate,
  variant,
  inputProps,
  ...rest
}) => {
  const classes = useStyle();
  const translate = useTranslate();

  const jsonValidation = value => {
    // Try to parse the string as json
    try {
      if (typeof value === 'string') {
        JSON.parse(value);
      }
    } catch {
      return translate('wa.exception.invalidJSON');
    }

    return undefined;
  };

  const newValidate = validate || [];
  newValidate.push(jsonValidation);

  const {
    id,
    input,
    isRequired,
    meta: {
      error, submitError, touched,
    },
  } = useInput({
    onBlur,
    onChange,
    onFocus,
    parse,
    format,
    resource,
    source,
    validate: newValidate,
    ...rest,
  });

  return (
    <Box className={classes.wealthJsonInput}>
      <TextField
        id={id}
        {...input}
        multiline
        rows={10}
        variant={variant}
        error={!!(touched && (error || submitError))}
        helperText={(
          <InputHelperText
            touched={touched}
            error={error || submitError}
            helperText={helperText}
          />
        )}
        label={(
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )}
        margin={margin}
        inputProps={inputProps}
        {...options}
        {...sanitizeInputRestProps(rest)}
      />
    </Box>
  );
};

JsonRawInput.propTypes = {};

JsonRawInput.defaultProps = {};

export default JsonRawInput;
