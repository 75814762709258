import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import rcSlug from '../../../constant/resource-slug';

const useGettingAvailableGame = ({
  groupId, brandId,
}) => {
  const dataProvider = useDataProvider();

  const [availableGame, setAvailableGame] = useState([]);

  const getAvailableGame = async () => {
    try {
      const { data } = await dataProvider.getOneWithFilter(
        rcSlug.AVAILABLE_GAME,
        {
          filter: {
            'group.id': groupId,
            'brand.id': brandId,
          },
        },
      );

      if (data && data.games) {
        setAvailableGame(data.games);
      }
    } catch (err) {
      console.error('[ERROR] Get Available Game', err?.message);
    }
  };

  useEffect(() => {
    if (groupId && brandId) {
      getAvailableGame();
    }
  }, [groupId, brandId]);

  return {
    availableGame,
  };
};

export default useGettingAvailableGame;
