import BrandShow from './brand.show';
import BrandEdit from './brand.edit';
import BrandCreate from './brand.create';
import BrandList from './brand.list';

export default {
  show: BrandShow,
  edit: BrandEdit,
  create: BrandCreate,
  list: BrandList,
};
