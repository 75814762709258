/* eslint-disable */
import { Box, Button as MuiButton } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { Notification, useLogin, useTranslate, useSetLocale, useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import CmtImage from '../../../theme/@coremat/CmtImage';
import AuthWrapper from '../../../theme/@jumbo/components/Common/authComponents/AuthWrapper';
import ContentLoader from '../../../theme/@jumbo/components/ContentLoader';
import { withStandardButtonProps } from '../../hoc/with-standard-button-props';
import { PasswordInput, TextInput } from '../ra/inputs';
import ReactHelmet from 'react-helmet';
import { appBrandConfigMapping } from '../../../constant/appBrandMapping';
import { getBackofficeEnv } from '../../../services/util';

const Button = withStandardButtonProps(MuiButton);

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  authContent: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      width: (props) => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
      padding: 30,
      flexDirection: 'row',

      background: 'url(/images/mbo-logos/login-illustrations.png)',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
    },

    [theme.breakpoints.up('xl')]: {
      padding: '50px 30px',
    },
  },
  titleRoot: {
    marginBottom: 14,
    fontWeight: 600,

    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.primary,
      fontWeight: 'normal',
    },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.black, 0.12),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  btnLogin: {
    backgroundColor: ({ defaultTheme }) => defaultTheme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ defaultTheme }) => defaultTheme.palette.primary.light,
    },
  },
  illustrationWrap: {
    flex: 1,
  },
  illustration: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,

    [theme.breakpoints.up('md')]: {
      display: 'block',

      marginTop: -10,
      marginLeft: -15,
      marginBottom: 0,
    },
  },
  loginFormContainer: {
    flex: 1,
    '& input': {
      backgroundColor: '#ffffff',
      borderRadius: '4px',
    },
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
}));

const GreenPacificQueenLoginPage = ({ variant = 'default', wrapperVariant = 'cheat-mode', theme }) => {
  const setLocale = useSetLocale();
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles({
    variant,
    defaultTheme: theme,
  });

  const { APP_BRAND } = getBackofficeEnv();
  const { companyName, loginPageLogo } = appBrandConfigMapping[APP_BRAND];

  const onSubmit = (values) => {
    login({ ...values }).catch(() => {
      // Error message is handled at the axios interceptor
    });
  };

  const handleValidation = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  useEffect(() => {
    setLocale('en'); // need to set locale to get translations from server
  }, []);

  return (
    <AuthWrapper variant={wrapperVariant} cardStyle={{ borderRadius: 20 }}>
      <ReactHelmet>
        <title>{`${companyName} | Back office`}</title>
      </ReactHelmet>
      <Box className={classes.authContent} flex="1">
        <Box className={classes.illustrationWrap}>
          <Box className={classes.logo}>
            <CmtImage
              height={80}
              style={{
                filter: 'drop-shadow(0.5px 0.5px 0.5px black)',
              }}
              src={loginPageLogo}
            />
          </Box>
        </Box>
        <Box className={classes.loginFormContainer}>
          <Box className={classes.avatar}>
            <CmtImage height={50} src="/images/mbo-logos/avt.png" />
          </Box>
          <Typography
            component="div"
            variant="h5"
            className={classes.titleRoot}
            style={{ textAlign: 'center', fontWeight: 600 }}
          >
            {translate('WELCOME')}
          </Typography>
          <Form
            onSubmit={onSubmit}
            validate={handleValidation}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="username" component={TextInput}>
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      label={translate('ra.auth.username')}
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      helperText={meta.touched && meta.error ? meta.error : ''}
                      error={!!(meta.touched && meta.error)}
                    />
                  )}
                </Field>
                <Field name="password" component={PasswordInput}>
                  {({ input, meta }) => (
                    <PasswordInput
                      {...input}
                      label={translate('ra.auth.password')}
                      type="password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      helperText={meta.touched && meta.error ? meta.error : ''}
                      error={!!(meta.touched && meta.error)}
                    />
                  )}
                </Field>
                <Field name="groupCode" component={TextInput}>
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      label="Group Code"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      helperText={meta.touched && meta.error ? meta.error : ''}
                      error={!!(meta.touched && meta.error)}
                    />
                  )}
                </Field>
                <Box mt={2.5}>
                  <Button variant="contained" color="primary" type="submit" fullWidth className={classes.btnLogin}>
                    {translate('ra.auth.sign_in')}
                  </Button>
                </Box>
              </form>
            )}
          />
        </Box>
        <ContentLoader />
      </Box>
      <Notification />
    </AuthWrapper>
  );
};

export default GreenPacificQueenLoginPage;
