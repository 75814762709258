import { useRef, useEffect } from 'react';
import useTranslateSchema from './useTranslateSchema';

const useTranslateSchemaRef = () => {
  const translateSchema = useTranslateSchema();

  const translateSchemaRef = useRef({
    translateSchema: f => f,
  });

  useEffect(() => {
    if (typeof translateSchema === 'function') {
      translateSchemaRef.current.translateSchema = translateSchema;
    }
  }, [translateSchema]);

  return translateSchemaRef.current;
};

export default useTranslateSchemaRef;
