import Axios from 'axios';
import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { useDispatch, useSelector } from 'react-redux';
import { SETTINGS } from '../../services/redux/root.actions';

let isSettingLoading = false;

const useSetting = () => {
  const dispatch = useDispatch();
  const settings = useSelector(({ setting }) => {
    if (isObject(setting.commonSettings)) {
      return setting.commonSettings;
    }
    return {};
  });

  // Get settings common
  useEffect(() => {
    async function fetchSetting() {
      if (!isEmpty(settings) || isSettingLoading) return;

      isSettingLoading = true;
      const { data } = await Axios.get('api/shared/setting');
      isSettingLoading = false;

      dispatch({
        type: SETTINGS,
        settings: {
          ...data,
        },
      });
    }

    fetchSetting();
  }, [settings]);

  return settings;
};

export default useSetting;
