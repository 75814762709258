import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import RecordIdField from './component/RecordIdField';
import { ChipField } from '../../base/components/ra/fields';

const useStyles = makeStyles(() => ({
  wrapper: {
    '& td': {
      maxWidth: 'unset !important',
    },
  },
}));

function RewardList(props) {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.wrapper}>
      <WealthListGuesser
        {...props}
        hideDeleteAction
        excludeFields={['recordId', 'desc', 'data', 'module']}
        fieldOrder={[
          'id',
          'created',
          'updated',
          'groupId',
          'brandId',
          'name',
          'desc',
          'recordId',
          'module',
          'count',
          'claimBefore',
          'type',
        ]}
        excludeFilterInputs={['data']}
      >
        <RecordIdField
          source="recordId"
          label={translate('resources.reward.fields.recordId')}
          {...props}
        />
        <ChipField source="module" />
      </WealthListGuesser>
    </Box>
  );
}

export default RewardList;
