import * as React from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { ActionList } from './components/action.list';

const CronOldList = props => (
  <>
    <WealthListGuesser
      {...props}
      actions={<ActionList />}
    />
  </>
);

export default CronOldList;
