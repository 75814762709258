import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import { docStatus } from '../../enumStatus';

const DocStatusField = ({
  record, source,
}) => {
  const translate = useTranslate();

  return (
    <>
      {record?.[source] === docStatus.DRAFT && translate('resources.fields.docStatus.draft')}
      {record?.[source] === docStatus.SUBMITTED && translate('resources.fields.docStatus.submitted')}
      {record?.[source] === docStatus.APPROVED && translate('resources.fields.docStatus.approved')}
      {record?.[source] === docStatus.DISABLED && translate('resources.fields.docStatus.disabled')}
      {record?.[source] === docStatus.CANCELED && translate('resources.fields.docStatus.canceled')}
    </>
  );
};

DocStatusField.propTypes = {
  record: PropTypes.shape({
    docStatus: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
};
DocStatusField.defaultProps = {
  record: {},
};

export default DocStatusField;
