/* eslint react/prop-types: 0 */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';

import { COLUMN_COUNT, LIST_PERM_KEY } from '../constants';
import { checkDisableAction } from '../utils';
import TableCell from './table-cell';
import EmptyColumn from './empty-column';
import CheckBoxCell from './checkbox-cell';

export default function Row({
  data,
  readOnly,
  onChange,
  dataUpdate,
  translate,
  permissions,
  classes,
  checkboxNotify,
  role,
}) {
  const [open, setOpen] = useState();

  const renderCheckBox = resource => LIST_PERM_KEY.map((action, index) => {
    const disabled = checkDisableAction({
      action,
      resource,
      permissions,
      role,
    });

    return (
      <CheckboxTooltip
        key={`checkbox-cell-${index}`}
        classes={classes}
        checked={
          dataUpdate[resource.perm.id]?.[action] ?? resource.perm[action]
        }
        readOnly={readOnly}
        disabled={readOnly || disabled}
        onChange={e => {
          onChange(resource, action, e.target.checked);
        }}
        notify={checkboxNotify}
        action={action}
        resource={resource}
        translate={translate}
      />
    );
  });

  return (
    <>
      {/* Table row parent */}
      <TableRow
        colSpan={COLUMN_COUNT}
        className={classes.rowParent}
        onClick={() => setOpen(!open)}
      >
        <TableCell
          classes={classes}
          className={classes.cellOfGroup}
        >
          <Box className={classes.titleOfGroup}>
            {/* Collapse icon */}
            <IconButton size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Box component="span">
              {translate(`resources.group.${kebabCase(data.name)}.name`)}
            </Box>
          </Box>
        </TableCell>
        <EmptyColumn
          col={COLUMN_COUNT - 2}
          classes={classes}
        />
      </TableRow>

      {/* Table row children */}
      {data.children.map((resource, childIndex) => (
        <React.Fragment key={`row-children-${childIndex}`}>
          <TableRow className={classes.rowChildrenWrapper}>
            <TableCell
              classes={classes}
              className={classes.cellCollapse}
              colSpan={COLUMN_COUNT}
            >
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Table>
                  <TableBody>
                    <TableRow
                      colSpan={COLUMN_COUNT}
                      className={classes.rowChildren}
                    >
                      <TableCell
                        classes={classes}
                        className={classes.childTitleCol}
                      >
                        {translate(`resources.${resource.name}.name`)}
                      </TableCell>
                      {renderCheckBox(resource)}
                    </TableRow>
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </>
  );
}

function CheckboxTooltip({
  classes,
  checked,
  readOnly,
  disabled,
  onChange,
  notify,
  action,
  resource,
  translate,
}) {
  const [tooltipTitle, setTooltipTitle] = useState(
    disabled ? translate('wa.exception.notAllowToUpdate') : '',
  );
  const toutTooltipTitle = useRef();

  useEffect(() => {
    if (
      !isEmpty(notify)
      && notify.resource?.id === resource?.id
      && notify.action === action
    ) {
      clearTimeout(toutTooltipTitle.current);
      setTooltipTitle(notify.message);
      toutTooltipTitle.current = setTimeout(() => setTooltipTitle(''), 3000);
    }
    return function cleanUp() {
      setTooltipTitle('');
      clearTimeout(toutTooltipTitle.current);
    };
  }, [notify]);

  return (
    <CheckBoxCell classes={classes}>
      <Tooltip
        arrow
        placement="top"
        // leaveDelay={1000}
        title={readOnly ? '' : tooltipTitle}
        // disableHoverListener
        // open={!!tooltipTitle}
      >
        <Box>
          <Checkbox
            color="primary"
            checked={checked}
            disableRipple={readOnly}
            className={classnames({
              [classes.checkboxReadOnly]: readOnly,
            })}
            disabled={disabled}
            onChange={onChange}
          />
        </Box>
      </Tooltip>
    </CheckBoxCell>
  );
}
