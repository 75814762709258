/* eslint-disable */
import React from 'react';
import { useTranslate } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textarea: {
    width: '100%',
    resize: 'none',
    border: 'none',
    background: 'transparent',

    '&:focus-visible': {
      outline: 'none',
    },
  },
}));

const MultilineInputReview = ({
  value, label,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box
      marginBottom="20px"
    >
      <Box
        style={{
          padding: '10.5px 14px',
          position: 'relative',
          borderRadius: '4px',
          background: '#f1f1f1',
        }}
      >
        <Box
          component="legend"
          style={{
            fontSize: '12px',
            fontWeight: '700',
          }}
        >
          {translate(label)}
        </Box>
        <Box>
          <textarea
            readOnly
            rows="5"
            className={classes.textarea}
            value={value}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MultilineInputReview;
