import React from 'react';
import { Box, Tooltip, Button as MuiButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { AddToPhotos } from '@material-ui/icons';
// eslint-disable-next-line
import { useStyles as useCommonStyles } from '.';
import { useLoading } from '../../../../base/hooks';
import useBulk from '../../../../base/hooks/useBulk';

const DuplicateButton = ({ prepaidId }) => {
  const commonClasses = useCommonStyles();
  const translate = useTranslate();

  const { toggleLoading } = useLoading();
  const { startCloneRecords } = useBulk();

  const handleDuplicateClick = () => {
    toggleLoading(true);
    startCloneRecords({
      resource: 'prepaid',
      selectedIds: [prepaidId],
      action: 'clone',
    });
  };

  return (
    <Tooltip
      title={translate('ra.action.duplicate', {
        _: 'Duplicate',
      })}
    >
      <MuiButton
        variant="contained"
        size="small"
        className={commonClasses.button}
        onClick={handleDuplicateClick}
      >
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{
            gap: '3px',
          }}
        >
          <AddToPhotos fontSize="16px" />
          <Box component="span">
            {translate('ra.action.duplicate', {
              _: 'Duplicate',
            })}
          </Box>
        </Box>
      </MuiButton>
    </Tooltip>
  );
};

DuplicateButton.propTypes = {
  prepaidId: PropTypes.string.isRequired,
};

DuplicateButton.defaultProps = {};

export default DuplicateButton;
