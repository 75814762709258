/* eslint-disable */
import React, { useRef } from 'react';
import {
  email,
  ImageField,
  number,
  ReferenceArrayInput,
  regex,
  required,
  useTranslate,
  minLength,
  maxLength,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { kebabCase, get, words } from 'lodash';
import { singularize } from 'inflection';
import { makeStateStory } from '../../../services/util/makeStateStory';
import { TooltipForDisabledInput } from '../custom-tooltip';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
  JsonRawInput,
  ReferenceInput,
  NumberInput,
} from '../ra/inputs';
import { guessInputComponent } from './wealth-component.guesser';
import { getReference, getFiltersFromSchema } from './wealth-input.guesser.utils';
import { REGEX_URL } from '../../../services/util/validate/regularExpression';
import { convertMbToByte } from '../../../services/util/formatNumber';
import { FILTER_PREFIX_ON_VALUE } from '../../../constant';
import ImageInput from '../../../custom-core-admin/ImageInput';

const MAXIMUM_UPLOADED_IMAGE__SIZE_IN_MB = 5;

const getBlobUrl = (file) => {
  if (!(file instanceof window.File)) {
    return {
      blobUrl: file?.blobUrl || file,
    };
  }

  const preview = URL.createObjectURL(file);

  return {
    blobUrl: preview,
  };
};

const transformFile = (files) => {
  if (!files) {
    return null;
  }

  return getBlobUrl(files);
};

const checkTheOtherValidate = ({ listExtraValidate, currValidate, sourceSchema }) =>
  listExtraValidate.reduce((result, currItem) => {
    const scheDataByType = get(sourceSchema, currItem.type);
    if (scheDataByType) {
      const {
        validation: { hasParams, action },
      } = currItem;
      const validateAction = hasParams ? action(scheDataByType) : action();
      result.push(validateAction);
    }
    return result;
  }, currValidate || []);

const listOtherValidate = [
  {
    type: 'minLength',
    validation: {
      action: minLength,
      hasParams: true,
    },
  },
  {
    type: 'maxLength',
    validation: {
      action: maxLength,
      hasParams: true,
    },
  },
];

const guessInputComponentWithProp = ({
  source,
  properties,
  apiRef,
  resource,
  resources,
  required: requiredField = [],
  record,
  locale,
}) => {
  let sourceName = source;
  let isReferenceInput;
  let isReferenceArrayInput;
  let isImage;
  let refFilter = {};
  let optionText = 'name';

  const realResource = resource.split('/').pop();
  const sourceSchema = properties?.[source];
  const type = sourceSchema?.format;
  const isJSON = type === 'json';
  const isRequired = requiredField.includes(sourceName);
  // TRANSLATABLE INPUTS
  let translatableInput = sourceSchema?.properties?.translatable?.default;
  const stateStoryKey = `NORMAL-FORM-${resource}`;
  const { setStoryState, getOlderState } = makeStateStory(stateStoryKey);

  // Find $ref
  const { refField, refName, optionText: optionTextGetReference } = getReference(sourceSchema);

  // Guess ReferenceInput (one/many to one)
  // $ref - reference to a defined object type

  if (refField) {
    // Type === 'array' is ReferenceArrayInput, not ReferenceInput
    if (sourceSchema?.type === 'array') {
      isReferenceArrayInput = true;
    } else {
      isReferenceInput = true;
    }

    if (refName.endsWith('status')) {
      refFilter = {
        type: refField.split('/schemas/').pop(),
      };
    }

    if (refName === 'user') {
      optionText = 'username';
      sourceName = `${source}.id`;
    }

    // Find optionText
    Object.keys(apiRef.get(refField).properties).forEach((prp) => {
      if (apiRef.get(refField).properties[prp]?.properties?.optionText?.default) {
        optionText = prp;
      }
    });

    if (refName === 'player') {
      optionText = optionTextGetReference || 'id';
    }

    const enabledFilter = apiRef.get(refField).properties.enabled !== undefined;
    if (enabledFilter) {
      refFilter = {
        enabled: '1',
      };
    }

    if (!sourceSchema?.properties?.reference?.$ref) {
      sourceName = `${source}.id`;
    }

    if (refName === 'blob') {
      isImage = true;
    }

    // TRANSLATABLE_INPUT
    if (apiRef.get(refField).properties[optionText]?.properties?.translatable?.default) {
      optionText = `${optionText}.${locale}`;
      translatableInput = true;
    }
  }

  const { InputComponent, guessedProps, WrapperComponent } = guessInputComponent(sourceSchema, {
    isRequired,
  });

  // Guess validation for "value" of "setting" resource
  const InputContainer = ({ children, defaultValue, requiredField, ...props }) => {
    const { record, source: inputSource, resource: inputResource } = props;

    const form = useForm();
    const translate = useTranslate();
    const translateRef = useRef();
    translateRef.current = translate;
    const user = useSelector(({ auth }) => auth.user);

    const { values: formValues, modified } = useFormState();
    setStoryState(formValues);

    const realRef = resources?.find((r) => {
      if (r.name.includes('/')) {
        return r.name === kebabCase(refName);
      }
      return false;
    });

    // Check if this input depend on any other value
    const trueSourceName = inputSource.split('.')?.[0];
    let validate = requiredField.includes(trueSourceName) ? [required()] : [];

    if (Array.isArray(sourceSchema?.validate)) {
      guessedProps.validate = [...guessedProps.validate, ...sourceSchema.validate];
    }

    const dependOnProperties = properties?.[trueSourceName]?.properties?.dependOn?.properties || {};
    const allow = properties?.[trueSourceName]?.properties?.allow?.default; // allow user of a role to create/update the record's properties
    const exclude = properties?.[trueSourceName]?.properties?.exclude?.default; // exclude user of a role to create/update the record's properties
    const dependOnPropertyList = Object.keys(dependOnProperties);

    const hiddenOnProperties = properties?.[trueSourceName]?.properties?.hiddenOn?.properties || {};
    const hiddenOnPropertyList = Object.keys(hiddenOnProperties);

    const shouldBeHiddenOn = hiddenOnPropertyList.some((i) => {
      const propertyValue = get(formValues, i);
      const dependOnValue = get(hiddenOnProperties, i);
      if (dependOnValue?.default && Array.isArray(dependOnValue?.default)) {
        return dependOnValue?.default?.includes(propertyValue);
      }

      if (dependOnValue?.default) {
        return dependOnValue.default === propertyValue;
      }

      return propertyValue || propertyValue?.length !== 0;
    });

    if (shouldBeHiddenOn) {
      form.change(trueSourceName, undefined);
      return null;
    }

    const shouldBeHidden = dependOnPropertyList.some((i) => {
      const propertyValue = get(formValues, i);
      const dependOnValue = get(dependOnProperties, i);

      if (dependOnValue?.default && Array.isArray(dependOnValue?.default)) {
        return !dependOnValue?.default?.includes(propertyValue);
      }

      if (dependOnValue?.default) {
        return dependOnValue?.default !== propertyValue;
      }

      return !propertyValue || propertyValue?.length === 0;
    });

    if (allow && !allow.includes(user?.role?.type)) return null;

    if (exclude && exclude.includes(user?.role?.type)) return null;

    if (shouldBeHidden) {
      form.change(props.source, undefined);
      return null;
    }

    const maxSizeValid = (mbValue, translatableInput) => (value, formValues) => {
      const rawImageFile = get(value, 'rawFile');
      const isImageFile = rawImageFile instanceof window.File;

      if (!isImageFile) {
        return undefined;
      }

      if (rawImageFile?.size && rawImageFile.size > convertMbToByte(mbValue)) {
        return translateRef.current('ra.validation.imagesUpdateCannotGreaterThanSpecificSize', {
          size: MAXIMUM_UPLOADED_IMAGE__SIZE_IN_MB,
        });
      }

      let rawImageFileSize = 0;
      if (translatableInput) {
        rawImageFileSize = Object.keys(formValues[source]).reduce((totalSize, locale) => {
          const rawImageFileSize = get(formValues, [source, locale, 'rawFile', 'size']);
          return _.isNumber(rawImageFileSize) ? rawImageFileSize + totalSize : totalSize;
        }, 0);
      }

      if (rawImageFileSize && rawImageFileSize > convertMbToByte(mbValue)) {
        return translateRef.current('ra.validation.recentImagesYouSelectedCannotGreaterThanSpecificSize', {
          size: MAXIMUM_UPLOADED_IMAGE__SIZE_IN_MB,
        });
      }

      return undefined;
    };

    // Detect Blob object
    if (isImage) {
      if (translatableInput) {
        return (
          <WrapperComponent {...props}>
            <ImageInput
              key={source}
              accept="image/*"
              source={source}
              helperText={translate(sourceSchema?.description || '')}
              validate={[maxSizeValid(MAXIMUM_UPLOADED_IMAGE__SIZE_IN_MB, translatableInput)]}
            >
              <ImageField source="blobUrl" />
            </ImageInput>
          </WrapperComponent>
        );
      }

      return (
        <ImageInput
          source={source}
          accept="image/*"
          key={source}
          helperText={translate(sourceSchema?.description || '')}
          validate={[maxSizeValid(MAXIMUM_UPLOADED_IMAGE__SIZE_IN_MB, translatableInput)]}
        >
          <ImageField source="blobUrl" />
        </ImageInput>
      );
    }

    const filterOn = sourceSchema?.properties?.filterOn?.properties;
    const filterOnProps = Object.keys(filterOn || {});
    refFilter = getFiltersFromSchema(sourceSchema, {
      user,
      refName,
      changeInputValue: form.change,
      formValues,
    });

    const sourceFilterList = filterOnProps.map((item) => {
      if (['group'].includes(item)) {
        return 'group.id';
      }
      return item;
    });
    const hasFilter = sourceFilterList?.length > 0;

    // Check one of list filterOn has a value
    // If one has the value => filter this input
    const filterActivate = sourceFilterList.some((item) => {
      if (Array.isArray(get(formValues, item))) {
        return get(formValues, item).length > 0;
      }

      return get(formValues, item);
    });

    // Check this field DISABLED or NOT depend on `source filterOn`
    let inputDisabledByFilterOn = hasFilter ? !filterActivate : false;

    // Brand won't set DISABLED when role's user is GROUP
    const belongToBrand = ['brand'].includes(refName);
    if (user?.role?.type === 'GROUP' && belongToBrand) {
      inputDisabledByFilterOn = false;
    }

    // Check `source filterOn` has UPDATED or NOT
    const olderState = getOlderState();
    const sourceFilterUpdated = sourceFilterList?.some((item) => {
      const currentValue = get(formValues, item);
      const olderValue = get(olderState, item);
      const isModified = get(modified, item);

      if (isModified && JSON.stringify(currentValue) !== JSON.stringify(olderValue)) {
        return true;
      }
      return false;
    });

    // Source filterOn unSelect or update value => this field clear data
    if ((inputDisabledByFilterOn || sourceFilterUpdated) && formValues[source]) {
      form.change(source, undefined);
    }

    // Translate all sourceNames in an array
    const getResourceNameTranslation = (resourceList) =>
      resourceList.map((item) => {
        const realResourceName = singularize(words(item)[0]);
        return translate(`resources.${realResourceName}.name`).toLowerCase();
      });
    const listResourceNameTranslated = getResourceNameTranslation(sourceFilterList);
    const disabledInputMessage = translate('ra.message.pleaseSelectFirst', {
      smart_name: listResourceNameTranslated?.join(', '),
    });

    const tooltipDisabledInputProps = {
      title: disabledInputMessage,
      placement: 'top-start',
      showMessage: inputDisabledByFilterOn,
    };

    if (trueSourceName === 'group' && user?.group?.id) {
      form.change(sourceName, user?.group?.id);
    }

    if (trueSourceName === 'brand' && user?.brands?.length) {
      if (user.brands.length === 1) {
        form.change(sourceName, user?.brands[0].id);
      }
      //
    }

    if (isReferenceInput) {
      return (
        <TooltipForDisabledInput {...tooltipDisabledInputProps}>
          <ReferenceInput
            key={sourceName}
            source={sourceName}
            reference={realRef?.name || refName}
            filter={refFilter}
            optionText={optionText}
            allowEmpty={!isRequired}
            fullWidth
            label={`resources.${realResource}.fields.${source}`}
            helperText={sourceSchema.description}
            defaultValue={defaultValue}
            validate={validate}
            disabled={inputDisabledByFilterOn}
            perPage={sourceSchema?.properties?.choiceLimit || 100}
            sort={{
              field: sourceSchema?.properties?.choiceSort?.field || optionText,
              order: sourceSchema?.properties?.choiceSort?.order || 'ASC',
            }}
          >
            {realRef?.name === 'status' || refName === 'status' ? (
              <SelectInput
                optionText={optionText}
                emptyText={isRequired ? '' : `--${translate('ra.text.any').toUpperCase()}--`}
              />
            ) : (
              <AutocompleteInput
                optionText={optionText}
                variant={guessedProps.variant}
                emptyText={isRequired ? '' : `--${translate('ra.text.any').toUpperCase()}--`}
              />
            )}
          </ReferenceInput>
        </TooltipForDisabledInput>
      );
    }

    // Guess ReferenceInput [one/many to many]
    if (isReferenceArrayInput) {
      return (
        <TooltipForDisabledInput {...tooltipDisabledInputProps}>
          <ReferenceArrayInput
            key={refName}
            source={source}
            filter={refFilter}
            reference={realRef?.name || refName}
            label={`resources.${realResource}.fields.${source}`}
            parse={(ids) =>
              ids?.map((id) => ({
                id,
              }))
            }
            format={(data) => data?.map((d) => d.id)}
            fullWidth
            helperText={sourceSchema.description}
            resource={realRef?.name || refName}
            filterToQuery={(value) => ({
              [optionText]: value ? `${FILTER_PREFIX_ON_VALUE.CONTAINS}${value}` : '',
            })}
            validate={validate}
            defaultValue={defaultValue}
            disabled={inputDisabledByFilterOn}
            perPage={sourceSchema?.properties?.choiceLimit || 100}
            sort={{
              field: sourceSchema?.properties?.choiceSort?.field || optionText,
              order: sourceSchema?.properties?.choiceSort?.order || 'ASC',
            }}
          >
            <AutocompleteArrayInput optionText={optionText} variant={guessedProps.variant} />
          </ReferenceArrayInput>
        </TooltipForDisabledInput>
      );
    }

    let Component = InputComponent;
    let newProps = {
      ...props,
      defaultValue,
      validate,
    };

    // Get input for `value field` in `Setting resource`
    if (inputResource === 'setting' && sourceName === 'value') {
      validate = [required()];
      switch (record?.format) {
        case 'url':
          validate.push(regex(REGEX_URL, 'wa.exception.invalidUrl'));
          break;
        case 'email':
          validate.push(email());
          break;
        case 'number':
          validate.push(number());
          return <NumberInput min={0} source="value" validate={validate} />;
          break;
        case 'ip':
          validate.push(
            regex(
              /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,
              'Must be a valid IP address',
            ),
          );
          break;
        case 'boolean':
          Component = BooleanInput;
          break;
        case 'blob':
          return (
            <ImageInput
              source="value"
              accept="image/*"
              key="value"
              helperText={translate(sourceSchema?.description || '')}
              className="image-input"
              format={transformFile}
            >
              <ImageField source="blobUrl" />
            </ImageInput>
          );
        case 'currency':
          return (
            <ReferenceInput
              key="value"
              source="value"
              reference="currency"
              filter={{
                enabled: true,
              }}
              allowEmpty={!isRequired}
              fullWidth
              validate={validate}
              perPage={500}
              sort={{
                field: 'name',
                order: 'ASC',
              }}
            >
              <AutocompleteInput
                optionText="name"
                variant={guessedProps.variant}
                emptyText={isRequired ? '' : `--${translate('ra.text.any').toUpperCase()}--`}
              />
            </ReferenceInput>
          );
        case 'language':
          return (
            <ReferenceInput
              key="value"
              source="value"
              reference="language"
              filter={{
                enabled: true,
              }}
              allowEmpty={!isRequired}
              fullWidth
              validate={validate}
              perPage={500}
              sort={{
                field: 'name',
                order: 'ASC',
              }}
            >
              <AutocompleteInput
                optionText="name"
                variant={guessedProps.variant}
                emptyText={isRequired ? '' : `--${translate('ra.text.any').toUpperCase()}--`}
              />
            </ReferenceInput>
          );

        case 'multiline':
          newProps.multiline = true;
          newProps.rows = 20;
          break;
        default:
          break;
      }

      newProps = {
        ...newProps,
        validate,
      };
    }

    if (isJSON) {
      Component = JsonRawInput;
      newProps = {
        ...newProps,
        jsonString: false,
        reactJsonOptions: {
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        },
      };
    }

    const newValidate = checkTheOtherValidate({
      listExtraValidate: listOtherValidate,
      currValidate: newProps.validate,
      sourceSchema,
    });

    newProps = {
      ...newProps,
      validate: newValidate,
    };

    return children({
      Component,
      props: newProps,
    });
  };

  // Normal Input
  const componentProps = {
    key: sourceName,
    source: sourceName,
    record,
    resource,
  };

  // Omit type prop if value is undefined to prevent wrong behavior of Input Components
  if (type) componentProps.type = type;

  return (
    <InputContainer
      {...componentProps}
      fullWidth
      variant={guessedProps?.variant}
      requiredField={requiredField}
      defaultValue={guessedProps?.defaultValue}
    >
      {({ Component, props }) => {
        if (Component) {
          const component = (
            <Component
              {...props}
              {...guessedProps}
              validate={[...props.validate, ...guessedProps.validate]}
              placeholder={sourceSchema?.example}
              label={`resources.${realResource}.fields.${source}`}
              helperText={sourceSchema?.description}
              variant={guessedProps?.variant}
            />
          );

          // TRANSLATABLE_INPUT
          if (WrapperComponent) {
            return <WrapperComponent {...props}>{component}</WrapperComponent>;
          }

          return component;
        }

        return <></>;
      }}
    </InputContainer>
  );
};

export default guessInputComponentWithProp;
