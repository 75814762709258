/* eslint-disable */
import React from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';

const InputPreview = ({
  value, label,
}) => {
  const translate = useTranslate();

  return (
    <Box
      marginBottom="20px"
    >
      <Box
        style={{
          padding: '10.5px 14px',
          position: 'relative',
          borderRadius: '4px',
          background: '#f1f1f1',
        }}
      >
        <Box
          component="legend"
          style={{
            fontSize: '12px',
            fontWeight: '700',
          }}
        >
          {translate(label)}
        </Box>
        <Box minHeight="17px">{value}</Box>
      </Box>
    </Box>
  );
};

export default InputPreview;
