import React from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';

const GroupInput = ({
  source, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <FormField name={source}>
      {() => (
        <ReferenceInput
          label={translate('resources.group.name')}
          source={source}
          reference="group"
          filterToQuery={value => ({
            'name||$cont': value,
          })}
          sort={{
            field: 'name',
            order: 'ASC',
          }}
          perPage={100}
          {...otherProps}
        >
          <AutocompleteInput
            optionText="name"
            resettable
          />
        </ReferenceInput>
      )}
    </FormField>
  );
};

GroupInput.propTypes = {
  source: PropTypes.string,
};

GroupInput.defaultProps = {
  source: 'group',
};

export default GroupInput;
