/* eslint-disable no-shadow */
import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import get from 'lodash/get';

import rcSlug from '../../../constant/resource-slug';

// About `AutocompleteInput`: Text which display on list must be string, so number => string
const convertNumberToStringForChoices = (listItem, field) => listItem.map(item => ({
  ...item,
  [field]: String(get(item, field)),
}));

const useBetSettingHandler = () => {
  const dataProvider = useDataProvider();

  const [betSettingDefault, setBetSettingDefault] = useState({
    betSizes: [],
    betLevels: [],
  });

  const [betSetting, setBetSetting] = useState({
    betSizes: [],
    betLevels: [],
  });

  const getBetSettingDefault = async (gameId, currencyId) => {
    try {
      const { data } = await dataProvider.getOneWithFilter(
        rcSlug.BET_SETTING_DEFAULT,
        {
          filter: {
            'game.id': gameId,
            'currency.id': currencyId,
          },
        },
      );

      const [betSizes, betLevels] = [
        get(data, 'betSizes', []),
        get(data, 'betLevels', []),
      ];

      if (data && betSizes.length && betLevels.length) {
        setBetSettingDefault({
          betSizes: convertNumberToStringForChoices(betSizes, 'value'),
          betLevels: convertNumberToStringForChoices(betLevels, 'value'),
        });
      }
    } catch (err) {
      console.error('[ERROR] Get Bet Setting Default', err?.message);
    }
  };

  const getBetSetting = async (groupId, brandId, gameId, currencyId) => {
    try {
      const { data } = await dataProvider.getOneWithFilter(rcSlug.BET_SETTING, {
        filter: {
          'group.id': groupId,
          'brand.id': brandId,
          'game.id': gameId,
          'currency.id': currencyId,
        },
      });

      const [betSizes, betLevels] = [
        get(data, 'betSizes', []),
        get(data, 'betLevels', []),
      ];

      if (data && betSizes.length && betLevels.length) {
        setBetSetting({
          betSizes: convertNumberToStringForChoices(betSizes, 'value'),
          betLevels: convertNumberToStringForChoices(betLevels, 'value'),
        });
      }
    } catch (err) {
      console.error('[ERROR] Get Bet Setting', err?.message);
    }
  };

  const betSizeChoices = betSetting.betSizes.length > 0
    ? betSetting.betSizes
    : betSettingDefault.betSizes;

  const betLevelChoices = betSetting.betLevels.length > 0
    ? betSetting.betLevels
    : betSettingDefault.betLevels;

  return {
    betSizeChoices,
    betLevelChoices,

    getBetSettingDefault,
    setBetSettingDefault,

    getBetSetting,
    setBetSetting,
  };
};

export default useBetSettingHandler;
