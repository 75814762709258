import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
  defaultChip: {
    fontSize: 9,
    padding: 0,
    borderRadius: 4,
    marginRight: 4,
  },
  defaultChipLabel: {
    padding: '2px 4px !important', // To override default styles
  },
});

const DefaultChip = () => {
  const translate = useTranslate();
  const styles = useStyles();
  return (
    <Chip
      component="span"
      className={styles.defaultChip}
      classes={{
        label: styles.defaultChipLabel,
      }}
      label={translate('ra.text.default')}
    />
  );
};

export default DefaultChip;
