/* eslint-disable */
import React from 'react';
import ReportDateRangeInput from '../../../../base/components/reports/ReportFilterWrapper/ReportDateRangeInput';
import CurrencyTypeSelector from '../../../../base/components/reports/ReportFilterWrapper/CurrencyTypeSelector';
import CurrencyInput from '../../../../base/components/reports/ReportFilterWrapper/CurrencyInput';
import GroupInput from '../../../../base/components/reports/ReportFilterWrapper/GroupInput';
import BrandInput from '../../../../base/components/reports/ReportFilterWrapper/BrandInput';
import { useAuthUser } from '../../../../base/hooks';
import PrepaidIdInput from './PrepaidIdInput';

const PrepaidReportFilters = ({ reportServiceVersion }) => {
  const user = useAuthUser();

  const isUnderGroupManagement = Boolean(user?.group?.id);

  return (
    <>
      <ReportDateRangeInput reportServiceVersion={reportServiceVersion} />
      {!isUnderGroupManagement && <GroupInput />}
      <BrandInput />
      <CurrencyTypeSelector />
      <CurrencyInput />
      <PrepaidIdInput />
    </>
  );
};

export default PrepaidReportFilters;
