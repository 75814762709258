import React from 'react';
import moment from 'moment';
import { useTranslate } from 'react-admin';
import { capitalize } from '@material-ui/core';
import PropTypes from 'prop-types';
import { clearDateRangePrefix } from '../../../../services/util';
import { useReportContext } from '../ReportLayout';
import FormField from './FormField';
import DateRangeInput from '../../guesser/date-range-input';

const ReportDateRangeInput = ({
  source, reportServiceVersion,
}) => {
  const {
    currentFilters, appliedFilters,
  } = useReportContext();

  const created = currentFilters?.[source] || appliedFilters?.[source];

  const translate = useTranslate();
  const startDate = clearDateRangePrefix(created).split(',')?.[0];
  const endDate = clearDateRangePrefix(created).split(',')?.[1];

  const today = moment();
  const yesterday = moment().subtract(1, 'days');

  const dateRangeLabel = {
    from: capitalize(translate('wa.common.from')),
    to: capitalize(translate('wa.common.to')),
  };

  if (yesterday.isSame(moment(startDate), 'day') && today.isSame(moment(endDate), 'day')) {
    dateRangeLabel.from = capitalize(translate('ra.text.yesterday'));
    dateRangeLabel.to = capitalize(translate('ra.text.today'));
  }

  return (
    <FormField name={source}>
      {() => (
        <DateRangeInput
          reportServiceVersion={reportServiceVersion}
          entity="report"
          source={source}
          resource={source}
          label={`${dateRangeLabel.from} → ${dateRangeLabel.to}`}
          prefix
          clearable={false}
          dateRange={created && [startDate, endDate]}
        />
      )}
    </FormField>
  );
};

ReportDateRangeInput.propTypes = {
  source: PropTypes.string,
  reportServiceVersion: PropTypes.number.isRequired,
};

ReportDateRangeInput.defaultProps = {
  source: 'created',
};

export default ReportDateRangeInput;
