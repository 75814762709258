import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const TitleContext = React.createContext(null);

export const useTitleContext = () => useContext(TitleContext);

export const TitleContextProvider = ({ children }) => {
  const [title, setTitle] = React.useState(null);

  return (
    <TitleContext.Provider
      value={{
        title,
        setTitle,
      }}
    >
      {children}
    </TitleContext.Provider>
  );
};

TitleContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};
