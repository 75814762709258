import { useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { useNotify, useUpdate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import useError from './useError';

const useUpdateCustom = ({
  resource, resourceTranslated,
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();

  const handleUpdateSuccess = response => {
    notify(
      translate('ra.notification.updated', {
        smart_name: translate(resourceTranslated),
      }),
      'success',
    );

    if (response?.data?.id) {
      history.push(`/${resource}/${response?.data.id}/show`);
    }
  };

  const [update, {
    loading: updateLoading, error,
  }] = useUpdate(
    undefined,
    undefined,
    undefined,
    undefined,
    {
      onSuccess: handleUpdateSuccess,
    },
  );

  useEffect(() => {
    if (!updateLoading && error) {
      notifyError(error);
    }
  }, [updateLoading, error]);

  return {
    update,
    updateLoading,
    error,
  };
};

export default useUpdateCustom;
