import React from 'react';
import { useFormState } from 'react-final-form';
import { minValue } from 'react-admin';
import WealthInputWrapper from '../../../base/components/guesser/wealth-input-wrapper';
import { BooleanInput, NumberInput } from '../../../base/components/ra/inputs';

export const PLAYER_LIMIT_FIELD_KEY = 'playerLimit';
export const UNLIMITED_PLAYER_VALUE = null;

const PlayerLimitWrapper = () => {
  const { values } = useFormState();

  const playerLimit = values?.[PLAYER_LIMIT_FIELD_KEY];

  return (
    <>
      <WealthInputWrapper
        source={PLAYER_LIMIT_FIELD_KEY}
        key={PLAYER_LIMIT_FIELD_KEY}
        validate={minValue(1)}
        disabled={playerLimit === UNLIMITED_PLAYER_VALUE}
      >
        <NumberInput label="resources.brand.fields.playerLimit" />
      </WealthInputWrapper>
      <WealthInputWrapper
        source={PLAYER_LIMIT_FIELD_KEY}
        parse={value => (value ? UNLIMITED_PLAYER_VALUE : undefined)}
        format={value => value === UNLIMITED_PLAYER_VALUE}
      >
        <BooleanInput
          label="resources.brand.fields.unlimitedPlayer"
          disabledError
        />
      </WealthInputWrapper>
    </>
  );
};

export default PlayerLimitWrapper;
