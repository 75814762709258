/* eslint-disable  */
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { BooleanInput } from '../../../base/components/ra/inputs';
import { useSchema } from '../../../base/hooks';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core//Tooltip';

export const customFieldsConfig = ["payoutConfig", "placebetConfig", "cancelbetConfig"];

const CustomSelectConfig = ({ source, basePath, implementation }) => {
  if (implementation === 'TRANSFER') {
    return null;
  }

  const translate = useTranslate();

  const [componentInfo, setComponentInfo] = useState({});

  const { api, ref } = useSchema();

  useEffect(() => {
    if (api) {
      const { paths } = api;
      const target = `${basePath}/{id}`;
      let requestRef = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;

      requestRef = requestRef.$ref || requestRef.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;

      const requestComponent = ref.get(requestRef);
      const { properties, required } = requestComponent;

      setComponentInfo(properties[source]);
    }
  }, [api]);

  return (
    <div>
      {translate(`resources.wallet-integration.fields.${source}`)}
      <Tooltip title={translate(componentInfo?.description || '')}>
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>

      {Object.keys(componentInfo?.properties?.fields || {}).map((key) => {
        const field = componentInfo?.properties?.fields[key];
        if (
          ['createPlayerUrl', 'editBalanceUrl'].includes(key) ||
          ['createPlayerUrl', 'editBalanceUrl'].includes(field)
        ) {
          return null;
        }

        return <BooleanInput key={key} source={`${source}.${key}`} defaultValue={field.default} label={key} />;
      })}
    </div>
  );
};

export default CustomSelectConfig;
