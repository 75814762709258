import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  () => ({
    root: {
      color: '#fff',
      zIndex: 9999,
    },
  }),
  {
    index: 1,
  },
);

const SimpleBackdrop = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.root}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

SimpleBackdrop.propTypes = {
  open: PropTypes.bool,
};

SimpleBackdrop.defaultProps = {
  open: true,
};

export default SimpleBackdrop;
