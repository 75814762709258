import { Typography } from '@material-ui/core';
import React from 'react';
import CmtFooter from '../../../theme/@coremat/CmtLayouts/Horizontal/Footer';
import { getBackofficeEnv } from '../../../services/util';
import { appBrandConfigMapping } from '../../../constant/appBrandMapping';

function Footer() {
  const { APP_BRAND } = getBackofficeEnv();
  const { companyName } = appBrandConfigMapping[APP_BRAND];

  return (
    <CmtFooter>
      <Typography
        align="center"
        variant="caption"
      >
        {`© ${companyName} ${new Date().getFullYear()}. All Rights Reserved.`}
      </Typography>
    </CmtFooter>
  );
}

export default Footer;
