/* eslint-disable  */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslate, FormDataConsumer } from 'ra-core';
import {
  SimpleForm,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  useCreate,
  useCreateController,
  useLocale,
} from 'react-admin';
import { makeStyles, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import enhancedDataProvider from '../../services/provider/dataProvider';
import { Create } from '../../base/components/ra/views';
import useError from '../../base/hooks/useError';
import ReferenceInputCustom from '../../base/components/ra/inputs/reference.input';
import TransferList from './components/transfer-list';
import resourceSlug from '../../constant/resource-slug';

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
  leftContent: {
    flex: 1,
  },
  rightContent: {},
  raCreateMain: {
    // maxWidth: 800,
    margin: '0 auto',
  },
  leftHeadClassName: {
    backgroundColor: '#fff5dc94',
  },
  leftContentClassName: {
    backgroundColor: '#fffcf394',
  },
  rightHeadClassName: {
    backgroundColor: '#9de89d',
  },
  rightContentClassName: {
    backgroundColor: '#c6f9d4',
  },
}));

const AvailableCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();
  const { record } = useCreateController(props);
  const locale = useLocale();

  const [allGamesData, setAllGamesData] = useState([]);
  const [allGameOptions, setAllGameOptions] = useState([]);
  const [isTransferListInit, setIsTransferInit] = useState(false);
  const [leftLoading, setLeftLoading] = useState(true);
  const [rightLoading, setRightLoading] = useState(true);

  const onCreateSuccess = (response) => {
    const { data } = response;
    const availableGameId = data?.id;

    notify(translate('ra.notification.created'), 'success');

    if (availableGameId) {
      history.push(`/available-game/${availableGameId}/show`);
    } else {
      history.push('/available-game');
    }
  };

  const [create, { loading: createLoading, error }] = useCreate(undefined, undefined, {
    onSuccess: onCreateSuccess,
  });

  const saveAvailableGame = (values) => {
    if (!values.group?.id) {
      // TODO: add this string-template to BE-message '%{fieldName} should be not empty',
      notify(
        translate('ra.message.shouldNotEmpty').replace(
          '%{fieldName}',
          translate('resources.available-game.fields.group'),
        ),
        'error',
      );
      return;
    }

    const { brand, ...restValues } = values;
    const brandId = brand?.id;

    create({
      resource: resourceSlug.AVAILABLE_GAME,
      payload: {
        data: {
          ...restValues,
          brands: brandId ? [{ id: brandId }] : undefined,
        },
      },
    });
  };

  const getListGame = async () => {
    try {
      const { data } = await enhancedDataProvider.getList(resourceSlug.GAME, {
        pagination: {
          page: 1,
        },
        filter: {
          enabled: true,
        },
      });

      if (Array.isArray(data)) {
        setAllGamesData(data);
      }

      setIsTransferInit(true);
      setLeftLoading(false);
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const getLeftInitValues = (availableGamesInitial) => {
    if (Array.isArray(availableGamesInitial)) {
      const gameIdsExisted = availableGamesInitial.map((item) => item.id);
      return allGameOptions.filter((gameId) => !gameIdsExisted.includes(gameId.value));
    }

    return allGameOptions;
  };

  const getRightInitValues = (availableGamesInitial) => {
    if (Array.isArray(availableGamesInitial)) {
      return availableGamesInitial.map((game) => ({
        value: game.id,
        label: game.name,
      }));
    }

    return [];
  };

  const leftOptions = useMemo(
    () => ({
      initValues: getLeftInitValues(record?.games),
      title: <strong>{translate('ra.text.allGames')}</strong>,
    }),
    [isTransferListInit, locale],
  );

  const rightOptions = useMemo(
    () => ({
      initValues: getRightInitValues(record?.games),
      title: <strong>{translate('ra.text.availableGames')}</strong>,
    }),
    [isTransferListInit, locale],
  );

  useEffect(() => {
    if (!createLoading) {
      if (error) {
        notifyError(error);
      }
    }
  }, [createLoading, error]);

  useEffect(() => {
    getListGame();
    setRightLoading(false);
  }, []);

  useEffect(() => {
    if (allGamesData.length > 0) {
      const gameIdsFromAllGamesData = allGamesData.map((game) => ({
        value: game.id,
        label: game.name,
      }));
      setAllGameOptions(gameIdsFromAllGamesData);
    }
  }, [allGamesData]);

  return (
    <Create
      {...props}
      classes={{ main: classes.raCreateMain }}
      alwaysEnableSaveButton={true}
      onCustomSave={saveAvailableGame}
    >
      <SimpleForm warnWhenUnsavedChanges redirect="show">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <Box className={classes.contentWrap}>
                <Box className={classes.leftContent}>
                  <ReferenceInputCustom
                    label="resources.group.name"
                    source="group.id"
                    reference="group"
                    fullWidth
                    perPage={100}
                    afterOnChange={(_, form) => {
                      // NOTE: brand.id is source name of brand ReferenceInput
                      form.change('brand.id', '');
                    }}
                    variant="outlined"
                  >
                    <AutocompleteInput optionText="name" on />
                  </ReferenceInputCustom>
                  <ReferenceInput
                    label="resources.brand.name"
                    source="brand.id"
                    reference="brand"
                    fullWidth
                    perPage={100}
                    filter={{
                      'group.id': formData.group?.id,
                    }}
                    disabled={!formData.group?.id}
                    variant="outlined"
                    allowEmpty
                  >
                    <AutocompleteInput optionText="name" on />
                  </ReferenceInput>
                </Box>
                <Box className={classes.rightContent}>
                  <TransferList
                    leftOptions={leftOptions}
                    rightOptions={rightOptions}
                    leftLoading={leftLoading}
                    rightLoading={rightLoading}
                    classes={{
                      leftHead: classes.leftHeadClassName,
                      leftContent: classes.leftContentClassName,
                      rightHead: classes.rightHeadClassName,
                      rightContent: classes.rightContentClassName,
                    }}
                    onChange={(_, rightValues) => {
                      formData.games = rightValues.map((item) => ({
                        id: item.value,
                      }));
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default AvailableCreate;
