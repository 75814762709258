/* eslint-disable */
import React, { useState, useCallback } from 'react';
import { useTranslate, FormDataConsumer } from 'ra-core';
import { SimpleForm } from 'react-admin';
import { Create } from '../../base/components/ra/views';
import { useCreateCustom } from '../../base/hooks';
import rcSlug from '../../constant/resource-slug';
import StepperBasicForm from './components/StepperBasicForm';
import { formatPayloadData } from './inbox-rule.utils';

import GeneralStep from './components/steps/general-step';
import DynamicRuleStep from './components/steps/dynamic-rule-step';
import PreviewStep from './components/steps/preview-step';

const InboxRuleCreate = props => {
  const { resource } = props;

  const translate = useTranslate();
  const [step, setStep] = useState(1);

  const { create } = useCreateCustom({
    resource,
    resourceTranslated: translate('resources.inbox-rule.name'),
  });

  const onStepperChange = useCallback(indexOfStepper => {
    setStep(indexOfStepper + 1);
  }, []);

  const createInboxRule = values => {
    const data = formatPayloadData(values);
    create({
      resource: rcSlug.INBOX_RULE,
      payload: {
        data,
      },
    });
  };

  return (
    <Create
      {...props}
      alwaysEnableSaveButton
      onCustomSave={createInboxRule}
    >
      <SimpleForm redirect="show">
        <FormDataConsumer>
          {({ formData }) => (
            <StepperBasicForm onStepperChange={onStepperChange}>
              <GeneralStep
                formData={formData}
                onSubmit
              />
              <DynamicRuleStep formData={formData} />
              <PreviewStep formData={formData} />
            </StepperBasicForm>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default InboxRuleCreate;
