/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNotify, useTranslate, useListContext, useRefresh } from 'ra-core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useTheme, makeStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import SmallButton from '@material-ui/core/Button';
import { useAuthUser } from '../../../base/hooks';
import { RoleBuiltIn } from '../../../services/util';
import { useReloadContext } from '../../../base/context/reload/reload.context';

const useStyles = makeStyles(() => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px',
    background: '#FFF',
    margin: 'auto',
    minWidth: '122px',
  },
}));

const getButtonLabel = (playerId, isLocked, translate) => {
  if (!playerId) return translate('ra.action.unlockAllPlayers');
  if (isLocked) return translate('ra.action.unlock');

  return translate('ra.action.lock');
};

const ButtonUnlock = ({
  // common
  disabled: initDisabled = false,
  shouldShowBtn: initShouldShowBtn = true,

  // for each record of list
  playerId,
  nativeId,
  showIcon = true,
  isLocked,

  // update color
  isSecondary = false,

  // for list page
  queryString,
  isUnlockAll,
}) => {
  const classes = useStyles();
  const user = useAuthUser();
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const muiTheme = useTheme();

  const listContext = useListContext();

  const { setValuesContext } = useReloadContext();

  useEffect(() => {
    setLoading(initDisabled);
  }, [initDisabled]);

  const shouldShowButton = [RoleBuiltIn.SYSTEM_ADMIN, RoleBuiltIn.GROUP_ADMIN, RoleBuiltIn.BRAND_ADMIN].includes(
    user.role?.id,
  );

  if (!shouldShowButton || !initShouldShowBtn) {
    return null;
  }

  const label = getButtonLabel(playerId, isLocked, translate);

  const toggleLockSpinning = async () => {
    setLoading(true);
    try {
      let url = '';
      let message = '';

      if (!playerId) {
        url = `api/player/unlock-all?${queryString}`;
        message = translate('ra.action.unlockAllPlayers');
      } else if (isLocked) {
        url = `api/player/${playerId}/unlock`;
        message = `${translate('ra.action.unlock')} #${nativeId}`;
      } else {
        url = `api/player/${playerId}/lock`;
        message = `${translate('ra.action.lock')} #${nativeId}`;
      }

      await Axios.post(url);

      if (!playerId) {
        refresh();
      } else {
        const tmpListContextData = JSON.parse(JSON.stringify(listContext.data));
        Object.keys(tmpListContextData).forEach(item => {
          if (playerId && item === playerId) {
            if (isLocked) {
              tmpListContextData[playerId].lockSpinning = false;
            } else {
              tmpListContextData[playerId].lockSpinning = true;
            }
          }

          if (isUnlockAll) {
            tmpListContextData[item].lockSpinning = false;
          }
        });

        setValuesContext({
          data: tmpListContextData,
        });
      }

      const successMessage = translate('ra.notification.actionSuccess', {
        smart_name: message,
      });

      notify(successMessage, 'success');
    } finally {
      setLoading(false);
    }
  };

  const extendBtnProps = isSecondary
    ? {
      color: 'secondary',
      variant: 'contained',
      size: 'small',
      style: {
        background: muiTheme.palette.primary.light,
        color: muiTheme.palette.secondary.main,
      },
    }
    : {};

  const components = (
    <SmallButton
      color="primary"
      {...extendBtnProps}
      className={classes.button}
      disabled={loading}
      label={label}
      onClick={toggleLockSpinning}
      startIcon={!showIcon ? null : <LockOpenIcon />}
    >
      {label}
    </SmallButton>
  );

  if (isUnlockAll) {
    return (
      <Tooltip
        title="If you select group / brand on the filter bar, these players belong to that group / brand will be unlock."
        placement="bottom-end"
      >
        <span>{components}</span>
      </Tooltip>
    );
  }

  return components;
};

export default ButtonUnlock;
