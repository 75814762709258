/* eslint-disable */
import React from 'react';
import { ReferenceField, TextField, useTranslate } from 'react-admin';
import CustomReportTable from '../../../../base/components/reports/CustomReportTable';
import { NumberField } from '../../../../base/components/ra/fields';
import resourceSlug, { REPORT_TYPES } from '../../../../constant/resource-slug';
import { LIST_REPORTS } from '../../report-prepaid.list';

const OverallTable = () => {
  const translate = useTranslate();
  return (
    <CustomReportTable
      title={translate('resources.report.overallWithUSDConsolidated')}
      resource={resourceSlug.REPORT_PREPAID}
      reportList={LIST_REPORTS}
      reportType={REPORT_TYPES.OVERALL}
    >
      <ReferenceField source="group" reference="group" label="resources.group.name" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="brand" reference="brand" label="resources.brand.name" link="show">
        <TextField source="name" />
      </ReferenceField>
      <NumberField label="resources.report.fields.noIssued" source="noIssued" sortable={false} />
      <NumberField label="resources.report.fields.noClaimed" source="noClaimed" sortable={false} />
      <ReferenceField source="currency" reference="currency" label="resources.currency.name" link="show">
        <TextField source="code" />
      </ReferenceField>
      <TextField label="resources.report.fields.eventExpense" source="eventExpense" sortable={false} />
    </CustomReportTable>
  );
};

export default OverallTable;
