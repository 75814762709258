/* eslint-disable  */
import React from 'react';
import {
  ArrayField,
  TextField,
  SingleFieldList,
} from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { transformIdValue } from '../../services/util/stringUtil';
import { ID_EMPTY } from '../../constant';
import resourceSlug from '../../constant/resource-slug';
import ArrayWithPaginationField from '../../base/components/ra/fields/arrayWithPagination.field';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';

const GroupShow = (props) => {
  return (
    <WealthShowGuesser
      excludeFields={['brands', 'currencies']}
      sections={[
        <ArrayWithPaginationField source="brands" label={false}>
          <CustomLinkField
            sortable={false}
            source="id"
            href={(record) => `/#/${resourceSlug.BRAND}/${record.id}/show`}
            title={(record) => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
          />
          <TextField source="name" sortable={false} label={'resources.group.fields.name'} />
          <TextField source="code" sortable={false} />
          <ArrayField source="features" label={'resources.feature-flag.name'}>
            <SingleFieldList component="ol" linkType={false}>
              <TextField source="name" />
            </SingleFieldList>
          </ArrayField>
        </ArrayWithPaginationField>,
        <ArrayWithPaginationField source="currencies" label={false}>
          <CustomLinkField
            sortable={false}
            source="id"
            href={(record) => `/#/${resourceSlug.CURRENCY}/${record.id}/show`}
            title={(record) => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
          />
          <TextField source="name" sortable={false} label={'resources.group.fields.name'} />
          <TextField source="code" sortable={false} />
        </ArrayWithPaginationField>,
      ]}
      {...props}
    />
  );
};

export default GroupShow;
