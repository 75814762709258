import React, { useEffect, useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { usePermissions } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { useReloadContext } from '../../base/context/reload/reload.context';
import ExtraActions from './components/ExtraActionForList';
import resourceSlug from '../../constant/resource-slug';
import RequesterField from './components/RequesterField';
import { useAuthUser } from '../../base/hooks';

const useStyles = makeStyles(() => ({
  wrapper: {
    '& td': {
      maxWidth: 'unset !important',
    },
  },
}));

function PrepaidList(props) {
  const { setValuesContext } = useReloadContext();
  const classes = useStyles();
  const user = useAuthUser();
  const { permissions } = usePermissions();

  const hasPrepaidApprovalPermissionToView = user?.superman || (Array.isArray(permissions) && !!permissions?.find(
    item => item.resource.name === resourceSlug.PREPAID_APPROVAL,
  )?.read);

  const excludeFields = useMemo(() => {
    if (hasPrepaidApprovalPermissionToView) {
      return ['desc', 'requester'];
    }
    return ['desc', 'requester', 'approvalStatus'];
  }, [hasPrepaidApprovalPermissionToView]);

  const excludeFilterInputs = useMemo(
    () => (hasPrepaidApprovalPermissionToView ? [] : ['approvalStatus']),
    [hasPrepaidApprovalPermissionToView],
  );

  useEffect(
    () => function cleanUp() {
      setValuesContext({});
    },
    [],
  );

  return (
    <Box className={classes.wrapper}>
      <WealthListGuesser
        {...props}
        hideDeleteAction
        excludeFields={excludeFields}
        extraActions={<ExtraActions {...props} />}
        excludeFilterInputs={excludeFilterInputs}
        exporter={false}
      >
        <RequesterField
          label="resources.prepaid.fields.requester"
          {...props}
        />
      </WealthListGuesser>
    </Box>
  );
}

export default PrepaidList;
