/* eslint-disable */
import React, { useEffect } from "react";
import { useTranslate, useShowController } from "react-admin";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import WealthShowGuesser from "../../base/components/guesser/wealth-show.guesser";
import { useTitleContext } from "../../base/context/title";
import { ActionMenu } from "./components/action.menu";
import { canI, RbacAction } from "../../services/provider/rbacProvider";

const useStyles = makeStyles((theme) => ({
  detailText: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  detailName: {
    fontWeight: 200,
    fontSize: 18,
    marginLeft: 5,
    [theme.breakpoints.up(960)]: {
      fontSize: 20,
    },
  },
}));

const CheatModeApplyShow = (props) => {
  const { setTitle } = useTitleContext();
  const { record } = useShowController(props);

  const classes = useStyles();

  const translate = useTranslate();

  const pageTitle = translate("resources.cheat-mode-apply.name");
  const pageNote = translate("resources.cheat-mode-apply.text.note");

  useEffect(() => {
    const element = (
      <div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div>{pageTitle}</div>
          <div className={clsx(classes.detailText, classes.detailName)}>
            {record?.name || record?.id}
          </div>
        </div>
        <div
          style={{
            fontSize: "14px",
            marginTop: "5px",
          }}
        >
          {pageNote}
        </div>
      </div>
    );
    setTitle(element);

    return function cleanUp() {
      setTitle(null);
    };
  }, [pageTitle, pageNote, record]);

  const { permissions } = props;

  return (
    <WealthShowGuesser
      {...props}
      actionMenu={<ActionMenu type="toolbar" />}
      forceEditAble={(record) =>
        record.status !== "DISABLE" &&
        canI(RbacAction.UPDATE, "cheat-mode-apply", permissions)
      }
    />
  );
};

export default CheatModeApplyShow;
