/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useInput } from 'react-admin';

function MonthYearInput(props) {
  const [state, setState] = useState();
  const {
    label, source,
  } = props;
  const { input: { onChange } } = useInput(props);

  useEffect(() => {
    const month = state?.month() + 1;
    const year = state?.year();
    onChange([month, year].join(','));
  }, [state]);
  return (
    <DatePicker
      style={{
        marginTop: '8px',
      }}
      source={source}
      label={label}
      variant="outlined"
      views={['month', 'year']}
      minDate={new Date('2021-01-01')}
      value={state}
      onChange={newValue => setState(newValue)}
      renderInput={params => (
        <TextField
          {...params}
          helperText={null}
          margin="dense"
        />
      )}
    />
  );
}

export default MonthYearInput;
