/* eslint-disable react/require-default-props, react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useInput, FieldTitle } from 'ra-core';
import { sanitizeInputRestProps, InputHelperText } from 'react-admin';
import { NumericFormat } from 'react-number-format';

const convertStringToNumber = value => {
  const number = Number.parseFloat(value);

  return Number.isNaN(number) ? null : number;
};

const NumberInput = ({
  format,
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = convertStringToNumber,
  resource,
  source,
  step,
  min,
  max,
  validate,
  variant = 'outlined',
  inputProps: overrideInputProps,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: {
      error, submitError, touched,
    },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const inputProps = {
    ...overrideInputProps,
    step,
    min,
    max,
  };

  return (
    <NumericFormat
      customInput={TextField}
      id={id}
      {...input}
      variant={variant}
      error={!!(touched && (error || submitError))}
      helperText={(
        <InputHelperText
          touched={touched}
          error={error || submitError}
          helperText={helperText}
        />
      )}
      label={(
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      )}
      margin={margin}
      inputProps={inputProps}
      {...options}
      {...sanitizeInputRestProps(rest)}
    />
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NumberInput.defaultProps = {
  options: {},
  step: 'any',
  label: '',
};

export default NumberInput;
