import React from 'react';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { checkCheatModeEnv } from '../../services/util';

const WalletIntegrationShow = props => {
  const excludeFields = checkCheatModeEnv()
    ? []
    : ['createPlayerUrl', 'editBalanceUrl'];

  return (
    <WealthShowGuesser
      {...props}
      excludeFields={excludeFields}
    />
  );
};

export default WalletIntegrationShow;
