import React from 'react';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import ValueTypeSelector from './components/ValueTypeSelector';
import { GAME_FEATURE_VALUE_TYPES } from '../../constant';

export default function GameFeatureCreate(props) {
  return (
    <WealthCreateGuesser
      fieldOrder={['name', 'code', 'valueType']}
      {...props}
    >
      <ValueTypeSelector
        source="valueType"
        defaultValue={GAME_FEATURE_VALUE_TYPES.BOOLEAN}
      />
    </WealthCreateGuesser>
  );
}
