import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const UserFormContext = React.createContext(null);

export const useUserFormContext = () => useContext(UserFormContext);

export const UserFormContextProvider = ({
  children, initValues = {},
}) => {
  const [customFields, setCustomFields] = useState([]);

  const [groupSelected, setGroupSelected] = useState(
    initValues.groupSelected || {},
  );
  const [roleSelected, setRoleSelected] = useState(
    initValues.roleSelected || {},
  );

  return (
    <UserFormContext.Provider
      value={{
        groupSelected,
        setGroupSelected,
        roleSelected,
        setRoleSelected,
        customFields,
        setCustomFields,
      }}
    >
      {typeof children === 'function'
        ? children({
          groupSelected,
          setGroupSelected,
          roleSelected,
          setRoleSelected,
          customFields,
          setCustomFields,
        })
        : children}
    </UserFormContext.Provider>
  );
};

UserFormContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  initValues: PropTypes.object,
};

UserFormContextProvider.defaultProps = {
  initValues: {
    groupSelected: null,
    roleSelected: null,
  },
};
