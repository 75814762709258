import { LinearProgress, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useLanguageContext } from '../../../../../../base/context/language';
import CmtHorizontalLayout from '../../../../../@coremat/CmtLayouts/Horizontal';
import CmtContent from '../../../../../@coremat/CmtLayouts/Horizontal/Content';
import CmtFooter from '../../../../../@coremat/CmtLayouts/Horizontal/Footer';
import CmtHeader from '../../../../../@coremat/CmtLayouts/Horizontal/Header';
import CmtHeaderMain from '../../../../../@coremat/CmtLayouts/Horizontal/Header/HeaderMain';
import CmtHeaderTop from '../../../../../@coremat/CmtLayouts/Horizontal/Header/HeaderTop';
import CmtSidebar from '../../../../../@coremat/CmtLayouts/Horizontal/Sidebar';
import ContentLoader from '../../../ContentLoader';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import Footer from '../../partials/Footer';
import HeaderTop from '../../partials/Header/HeaderTop';
import SideBar from '../../partials/SideBar';
import HeaderMenus from './HeaderMenus';
import PageHeader from './PageHeader';

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 999,
    background: 'white',
  },
  loadingWrapper: {
    position: 'absolute',
    bottom: -4,
    width: '100%',
    height: 4,
  },
  loading: {
    position: 'fixed',
    width: '100%',
    zIndex: 9,
    backgroundColor: '#f4f4f7',
    height: 'calc(100vh + 3em)',
  },
}));

const HorizontalDefault = ({ className, children }) => {
  const { drawerBreakPoint, sidebarSize, sidebarStyle, showFooter } = useContext(AppContext);
  const { language } = useLanguageContext();

  const classes = useStyles();
  const isLoading = useSelector(({ app }) => app?.isLoading);
  const [height, setHeight] = useState(156);
  const [isFirstLoading, setFirstLoading] = useState(true);
  const { height: menuHeight, ref: menuRef } = useResizeDetector();

  useEffect(() => {
    setHeight(menuHeight);
  }, [menuHeight]);

  // Todo
  useEffect(() => {
    if (language) {
      const timeout = setTimeout(() => {
        setFirstLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [language]);

  return (
    <>
      {isFirstLoading && <Loading className={classes.loading} />}
      <CmtHorizontalLayout
        drawerBreakPoint={drawerBreakPoint}
        className={clsx('Cmt-horizontalDefaultLayout', className)}
      >
        <CmtHeader>
          <div ref={menuRef} className={clsx(classes.fixedHeader)}>
            <CmtHeaderTop>
              <HeaderTop />
            </CmtHeaderTop>
            {!isFirstLoading && (
              <Hidden mdDown>
                <CmtHeaderMain bgcolor="primary.main" color="white">
                  <HeaderMenus />
                </CmtHeaderMain>
              </Hidden>
            )}
            <div className={classes.loadingWrapper}>{isLoading && <LinearProgress />}</div>
          </div>
        </CmtHeader>
        <CmtSidebar sidebarWidth={sidebarSize} {...sidebarStyle}>
          <SideBar />
        </CmtSidebar>
        <CmtContent header={<PageHeader />} height={height}>
          {children}
          <ContentLoader />
        </CmtContent>
        {showFooter && (
          <CmtFooter type="static">
            <Footer />
          </CmtFooter>
        )}
      </CmtHorizontalLayout>
    </>
  );
};

export default HorizontalDefault;
