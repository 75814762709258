import { FormDataConsumer, required } from 'ra-core';
import { SimpleForm } from 'react-admin';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  SelectInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from '../../../base/components/ra/inputs';
import { Edit } from '../../../base/components/ra/views';
import { useAuthUser, useEnumOptions } from '../../../base/hooks';
import { canI, RbacAction } from '../../../services/provider/rbacProvider';

import { RoleTypeInput, RoleLevelInput } from '.';
import { RoleAccessibility } from '../../../services/util';

const FormEditGeneral = props => {
  const {
    resource, id,
  } = props;
  const [disabled, setDisabled] = useState(false);
  const refCallApi = useRef();

  const user = useAuthUser();

  const getUser = async (roleId, perms) => {
    if (canI(RbacAction.READ, 'user', perms)) {
      try {
        const res = await axios.get(
          `api/user?&filter[0]=enabled||$eq||true&filter[1]=role.id||$eq||${roleId}&limit=1`,
        );

        if (res?.data?.length) {
          setDisabled(true);
        }
      } catch {
        //
      }
    }
  };

  useEffect(() => {
    if (user?.id && !refCallApi.current) {
      getUser(id, user.perms);
      refCallApi.current = true;
    }
  }, [user]);

  const transform = data => {
    const {
      level, name, enabled, type, accessibility, group,
    } = data;

    return {
      level: Number(level),
      name,
      enabled,
      type,
      accessibility,
      group,
    };
  };

  const accessibilityEnum = useEnumOptions('role', 'accessibility');

  const defaultFilterGroup = {
    enabled: true,
  };

  return (
    <Edit
      {...props}
      transform={transform}
    >
      <SimpleForm redirect={`/${resource}/${id}/show`}>
        <TextInput
          validate={required()}
          fullWidth
          source="name"
        />
        <SelectInput
          fullWidth
          source="accessibility"
          choices={accessibilityEnum}
          validate={required()}
          disabled={disabled}
        />
        <RoleTypeInput
          source="type"
          disabled={disabled}
          isEdit
          user={user}
        />
        <RoleLevelInput
          source="level"
          isEdit
          user={user}
        />
        <FormDataConsumer>
          {({ formData }) => formData.type === 'GROUP'
            && formData.accessibility === RoleAccessibility.CUSTOM_ROLE && (
            <ReferenceInput
              label="resources.role.fields.group"
              source="group.id"
              reference="group"
              validate={required()}
              fullWidth
              filterToQuery={value => ({
                name: value || '',
              })}
              filter={
                user.group?.id
                  ? {
                    id: user.group.id,
                    ...defaultFilterGroup,
                  }
                  : {
                    ...defaultFilterGroup,
                  }
              }
              defaultValue={user.group?.id && user.group.id}
              disabled={disabled}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <BooleanInput
              fullWidth
              source="enabled"
              defaultValue={false}
              disabled={formData.enabled ? disabled : false}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

FormEditGeneral.propTypes = {
  resource: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

FormEditGeneral.defaultProps = {};

export default FormEditGeneral;
