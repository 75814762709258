import React, { useMemo, useRef } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import useSetting from '../../../../../base/hooks/useSetting';
import useAccessToken from '../../../../../base/hooks/useAccessToken';
import { checkMboEnv, getBackofficeEnv } from '../../../../../services/util';
import { appBrandConfigMapping } from '../../../../../constant/appBrandMapping';

const LOGO_JUMBO_WHITE = "/images/logo-white.png";
const LOGO_JUMBO_SYMBOL_WHITE = "/images/logo-system-white.png";

const Logo = ({
  color, ...props
}) => {
  const isMboEnv = checkMboEnv();
  const { APP_BRAND } = getBackofficeEnv();
  const { topHeaderLogo } = appBrandConfigMapping[APP_BRAND];
  
  // Usage for Desktop
  const LOGO_SYSTEM = topHeaderLogo;

  // Usage for Mobile
  const LOGO_SYSTEM_SYMBOL = topHeaderLogo;

  const settings = useSetting();
  const accessTokenData = useAccessToken();
  const logoRef = useRef();

  const logoUrlData = useMemo(() => {
    const getLogoFallback = (logo) =>
      color === "white" ? LOGO_JUMBO_WHITE : logo;

    const logoResult = {
      logoSymbol:
        color === "white" ? LOGO_JUMBO_SYMBOL_WHITE : LOGO_SYSTEM_SYMBOL,
      logoSetting: settings?.site_logo
        ? `api/blob/static?blobId=${settings?.site_logo}`
        : "",
      logoFallback: getLogoFallback(LOGO_SYSTEM),
    };

    return logoResult;
  }, [settings, accessTokenData]);

  const handleLogoFallback = (logoFallback) => {
    logoRef.current.onerror = null;
    logoRef.current.src = logoFallback;
  };

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage
            ref={logoRef}
            src={logoUrlData.logoSetting || logoUrlData.logoFallback}
            alt="logo"
            height={isMboEnv ? 50 : 40}
            style={{
              verticalAlign: "middle",
              filter: isMboEnv ? 'drop-shadow(0.5px 0.5px 0.5px black)' : 'none',
            }}
            onError={() => handleLogoFallback(logoUrlData.logoFallback)}
          />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoUrlData.logoSymbol} alt="logo" height={40} />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
