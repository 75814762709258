import Type from 'prop-types';
import React, { useMemo } from 'react';
import { Edit as RAEdit, getResources, useRedirect, useEditController, useNotify } from 'react-admin';
import { useTranslate } from 'ra-core';
import { useSelector } from 'react-redux';
import { WealthToolbar } from '../../guesser/wealth-toolbar';
import { useAuthUser, useError } from '../../../hooks';
import { canIEditWith } from '../../../../services/provider/rbacProvider';
import Actions from './action-toolbar';

const Edit = props => {
  const {
    resource,
    hasEdit,
    children,
    alwaysEnableSaveButton, // eslint-disable-line react/prop-types
    forceCustomSave,
    onCustomSave,
    transform,
    confirmDialogInfo,
    translatedResourceName: defaultTranslatedResourceName,
    redirectUrl,
  } = props;

  const resources = useSelector(getResources);

  const redirect = useRedirect();
  const { notifyError } = useError();
  const translate = useTranslate();
  const { record } = useEditController(props);
  const notify = useNotify();
  const user = useAuthUser();

  const {
    options: {
      hasDelete, isAcl,
    },
  } = resources.find(r => r.name === resource);

  const editAble = useMemo(
    () => canIEditWith(resource, {
      defaultCondition: hasEdit,
      record,
      user,
    }),
    [resource, hasEdit, record, user],
  );

  if (!editAble) {
    redirect(`/${resource}`);
    console.warn('WARN: this resource does not support edit');
  }

  const onFailure = error => {
    notifyError(error);
  };

  const translatedResourceName = defaultTranslatedResourceName || translate(`resources.${resource}.name`);
  const successMessage = translate('ra.notification.updated', {
    smart_name: translatedResourceName,
  });

  const onSuccess = () => {
    notify(successMessage);
  };

  return (
    <RAEdit
      {...props}
      undoable={false}
      onFailure={onFailure}
      onSuccess={onSuccess}
    >
      {React.cloneElement(children, {
        toolbar: (
          <WealthToolbar
            hasDelete={hasDelete}
            forceCustomSave={forceCustomSave}
            onCustomSave={onCustomSave}
            alwaysEnableSaveButton={alwaysEnableSaveButton}
            isAcl={isAcl}
            transform={transform}
            confirmDialogInfo={confirmDialogInfo}
            translatedResourceName={defaultTranslatedResourceName}
            redirectUrl={redirectUrl}
          />
        ),
      })}
    </RAEdit>
  );
};

Edit.propTypes = {
  resource: Type.string,
  children: Type.node,
  actions: Type.node,
  forceCustomSave: Type.bool,
  onCustomSave: Type.func,
  hasEdit: Type.bool.isRequired,
  isAcl: Type.bool,
  transform: Type.func,
  confirmDialogInfo: Type.shape({
    title: Type.string,
    content: Type.string,
  }),
  translatedResourceName: Type.string,
  redirectUrl: Type.string,
};

Edit.defaultProps = {
  resource: '',
  children: <></>,
  actions: <Actions />,
  forceCustomSave: false,
  onCustomSave: undefined,
  isAcl: false,
  transform: f => f,
  confirmDialogInfo: null,
  translatedResourceName: '',
  redirectUrl: null,
};

export default Edit;
