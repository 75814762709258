/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip, Button } from '@material-ui/core';
import isObject from 'lodash/isObject';

export default function TooltipButton(props) {
  const {
    tooltipProps = {}, buttonProps = {}, children,
  } = props;

  if (isObject(tooltipProps) && tooltipProps.title) {
    return (
      <Tooltip
        placement="top"
        {...tooltipProps}
      >
        <span
          style={{
            border: 'none',
            padding: 0,
            margin: 0,
            cursor: buttonProps.disabled ? 'not-allowed' : 'auto',
          }}
        >
          <Button {...buttonProps}>
            {children}
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button {...buttonProps}>{children}</Button>
  );
}
