/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { useTranslate, FormDataConsumer, required, minValue, useLocale } from 'ra-core';
import { SimpleForm, AutocompleteInput, useDataProvider } from 'react-admin';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Create } from '../../base/components/ra/views';
import { ReferenceInput as ReferenceInputCustom } from '../../base/components/ra/inputs';
import BetSettingPicker from '../../base/components/rmc-picker-custom/bet-setting-picker.input';
import { BetSettingPickerProvider } from '../../base/components/rmc-picker-custom/bet-setting-picker.context';
import rcSlug from '../../constant/resource-slug';
import { validateTotalBetMax } from '../../services/util/bet-setting.utils';
import { testByRegex } from '../../services/util/validate/validateMethods';
import { TWO_DECIMAL_REGEX } from '../../services/util/validate/regularExpression';
import { useCreateCustom, useApiProperties } from '../../base/hooks';
import useTranslateSchemaRef from '../../base/hooks/useTranslateSchemaRef';
import useTranslateSchema from '../../base/hooks/useTranslateSchema';
import NumberInput from '../../base/components/ra/inputs/commonNumber.input';

const BetSettingDefaultCreate = props => {
  const { resource } = props;

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { create } = useCreateCustom({
    resource,
    resourceTranslated: translate('resources.betSettingDefault.name'),
  });

  const { getPropertiesByFieldName } = useApiProperties();
  const propertiesGame = getPropertiesByFieldName('game');
  const propertiesCurrency = getPropertiesByFieldName('currency');

  const [currentGame, setCurrentGame] = useState(null);
  const translateSchema = useTranslateSchema();
  const translateSchemaRef = useTranslateSchemaRef();

  const baseBet = get(currentGame, 'baseBet');

  const onlyTwoDigits = fieldName => testByRegex(translateSchemaRef, {
    regex: TWO_DECIMAL_REGEX,
    translationSchema: {
      name: 'ra.validation.onlyTwoDigitsAfter',
      params: {
        smart_name: fieldName,
      },
    },
  });

  const handleGameChange = async gameId => {
    try {
      const { data: gameData } = await dataProvider.getOne('game', {
        id: gameId,
      });

      if (!isEmpty(gameData)) {
        setCurrentGame(gameData);
      }
    } catch (err) {
      console.error('[ERROR] Get One Game', err?.message);
    }
  };

  const createBetSettingDefault = values => {
    const {
      game, currency, totalBetMin, totalBetMax, ...restValue
    } = values;

    if (
      !Array.isArray(restValue?.picker?.listItem?.betSize)
      || !Array.isArray(restValue?.picker?.listItem?.betLevel)
      || isNaN(restValue?.picker?.defaultValue?.betSize)
      || isNaN(restValue?.picker?.defaultValue?.betLevel)
    ) {
      return;
    }

    const betSizes = restValue.picker.listItem.betSize.map(item => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betSize,
    }));

    const betLevels = restValue.picker.listItem.betLevel.map(item => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betLevel,
    }));

    create({
      resource: rcSlug.BET_SETTING_DEFAULT,
      payload: {
        data: {
          game,
          currency,
          totalBetLimit: [totalBetMin, totalBetMax],
          betSizes,
          betLevels,
        },
      },
    });
  };

  const totalBetMaxMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMax',
    },
  });

  const totalBetMinMustGreatThanZero = translateSchema({
    name: 'ra.validation.mustGreatThanZero',
    params: {
      smart_name: 'ra.field.totalBetMin',
    },
  });

  const locale = useLocale();

  return (
    <Create
      {...props}
      alwaysEnableSaveButton
      onCustomSave={createBetSettingDefault}
    >
      <SimpleForm redirect="show">
        <FormDataConsumer>
          {() => {
            const { translatable } = propertiesGame;

            let optionTextGame = propertiesGame.optionText || 'name';

            if (translatable) {
              optionTextGame = `name.${locale}`;
            }

            return (
              <Box>
                <ReferenceInputCustom
                  label="resources.game.name"
                  source="game.id"
                  reference="game"
                  fullWidth
                  validate={required()}
                  variant="outlined"
                  perPage={propertiesGame.choiceLimit || 100}
                  sort={{
                    field: propertiesGame.choiceSort?.field || propertiesGame.optionText || 'name',
                    order: propertiesGame.choiceSort?.order || 'ASC',
                  }}
                  onChange={handleGameChange}
                >
                  <AutocompleteInput optionText={optionTextGame} />
                </ReferenceInputCustom>
                <ReferenceInputCustom
                  label="resources.currency.name"
                  source="currency.id"
                  reference="currency"
                  filter={{
                    enabled: true,
                  }}
                  fullWidth
                  validate={required()}
                  variant="outlined"
                  perPage={propertiesCurrency.choiceLimit || 100}
                  sort={{
                    field: propertiesCurrency.choiceSort?.field || propertiesCurrency.optionText || 'name',
                    order: propertiesCurrency.choiceSort?.order || 'ASC',
                  }}
                >
                  <AutocompleteInput optionText={propertiesCurrency.optionText || 'name'} />
                </ReferenceInputCustom>

                <NumberInput
                  fullWidth
                  source="totalBetMin"
                  label={translate('ra.field.totalBetMin')}
                  validate={[
                    required(),
                    minValue(0, totalBetMinMustGreatThanZero),
                    onlyTwoDigits('ra.field.totalBetMin'),
                  ]}
                />

                <NumberInput
                  fullWidth
                  source="totalBetMax"
                  label={translate('ra.field.totalBetMax')}
                  validate={[
                    required(),
                    minValue(0, totalBetMaxMustGreatThanZero),
                    onlyTwoDigits('ra.field.totalBetMax'),
                    validateTotalBetMax(translateSchemaRef),
                  ]}
                />

                <BetSettingPickerProvider>
                  <BetSettingPicker
                    source="picker"
                    baseBetInit={baseBet}
                  />
                </BetSettingPickerProvider>
              </Box>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

BetSettingDefaultCreate.propTypes = {
  resource: PropTypes.string.isRequired,
};

BetSettingDefaultCreate.defaultProps = {};

export default BetSettingDefaultCreate;
