/* eslint-disable no-restricted-globals */
import React from 'react';
import {
  SimpleForm,
  useEditController,
  useTranslate,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useUpdateCustom } from '../../base/hooks';
import { Edit } from '../../base/components/ra/views';
import rcSlug from '../../constant/resource-slug';
import BetSettingDefaultEditForm from './components/bet-setting-default-edit-form';

const BetSettingDefaultEdit = props => {
  const { resource } = props;
  const { record } = useEditController(props);
  const translate = useTranslate();
  const { update } = useUpdateCustom({
    resource,
    resourceTranslated: translate('resources.betSettingDefault.name'),
  });

  const saveBetSettingDefault = (values = {}) => {
    const {
      game, currency, totalBetMin, totalBetMax, ...restValue
    } = values;

    if (
      !Array.isArray(restValue?.picker?.listItem?.betSize)
      || !Array.isArray(restValue?.picker?.listItem?.betLevel)
      || isNaN(restValue?.picker?.defaultValue?.betSize)
      || isNaN(restValue?.picker?.defaultValue?.betLevel)
    ) {
      return;
    }

    const betSizes = restValue.picker.listItem.betSize.map(item => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betSize,
    }));

    const betLevels = restValue.picker.listItem.betLevel.map(item => ({
      value: item.value,
      default: item.value === restValue.picker.defaultValue.betLevel,
    }));

    update({
      resource: rcSlug.BET_SETTING_DEFAULT,
      payload: {
        id: record?.id,
        data: {
          game,
          currency,
          totalBetLimit: [totalBetMin, totalBetMax],
          betSizes,
          betLevels,
        },
      },
    });
  };

  return (
    <Edit
      {...props}
      alwaysEnableSaveButton
      onCustomSave={saveBetSettingDefault}
    >
      <SimpleForm redirect="show">
        <BetSettingDefaultEditForm {...props} />
      </SimpleForm>
    </Edit>
  );
};

BetSettingDefaultEdit.propTypes = {
  resource: PropTypes.string.isRequired,
};

BetSettingDefaultEdit.defaultProps = {};

export default BetSettingDefaultEdit;
