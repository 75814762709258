/* eslint-disable react/prop-types, no-unused-vars */
import React, { useEffect, useMemo, memo } from 'react';
import get from 'lodash/get';

import {
  AutocompleteInput,
} from '../../../../base/components/ra/inputs';

const AutoCompleteCustomInput = memo(props => {
  const {
    choiceRef,
    filterValues,
    defaultRefFilter = {},
    source,
    choices,
    itemsChosen,
    fieldComparison,
    ...restProps
  } = props;

  const choicesFilter = useMemo(
    () => {
      const compareSet = new Set();

      return choices?.filter(item => {
        const value = get(item, fieldComparison);
        const isValid = !itemsChosen.includes(value) && !compareSet.has(value);
        compareSet.add(value);
        return isValid;
      }) || [];
    },
    [choices, fieldComparison, itemsChosen],
  );

  useEffect(() => {
    if (choices?.length) {
      choiceRef.current = choices.reduce((acc, curItem) => ({
        ...acc,
        [curItem[fieldComparison]]: {
          ...curItem,
        },
      }), {});
    }
  }, [choices]);

  return (
    <AutocompleteInput
      {...restProps}
      source={source}
      choices={choicesFilter}
    />
  );
});

export default AutoCompleteCustomInput;
