import React from 'react';
import { QuickSetupErrorHandlingProvider } from './utils/QuickSetupErrorHandling';
import QuickSetupNewGroup from '../quick-setup-new-group';
import QuickSetupNewBrand from '../quick-setup-new-brand';

export function QuickSetupNewGroupRoute(props) {
  return (
    <QuickSetupErrorHandlingProvider>
      <QuickSetupNewGroup {...props} />
    </QuickSetupErrorHandlingProvider>
  );
}

export function QuickSetupNewBrandRoute(props) {
  return (
    <QuickSetupErrorHandlingProvider>
      <QuickSetupNewBrand {...props} />
    </QuickSetupErrorHandlingProvider>
  );
}
