import React from 'react';
import keyBy from 'lodash/keyBy';
import { LinearProgress, ListContextProvider } from 'react-admin';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Datagrid from '../../ra/data-grid';
import Pagination from './Pagination';

export const ReportTable = ({
  basePath,
  resource,
  loading,
  data,
  total,
  page,
  currentSort,
  setPage,
  setSort,
  setPerPage,
  perPage,
  children,
}) => {
  if (loading) {
    return <LinearProgress />;
  }

  return (
    <ListContextProvider
      value={{
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        total,
        page,
        setPage,
        perPage,
        setPerPage,
        currentSort: currentSort || {
          field: 'id',
          order: 'ASC',
        },
        setSort,
        basePath: basePath || `/${resource}`,
        resource,
        selectedIds: [],
      }}
    >
      <Paper>
        <Datagrid>{children}</Datagrid>
        <Pagination />
      </Paper>
    </ListContextProvider>
  );
};

ReportTable.propTypes = {
  basePath: PropTypes.string,
  resource: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.array,
  total: PropTypes.number,
  page: PropTypes.number,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  setPage: PropTypes.func,
  setSort: PropTypes.func,
  setPerPage: PropTypes.func,
  perPage: PropTypes.number,
  children: PropTypes.node,
};

ReportTable.defaultProps = {
  basePath: null,
  loading: false,
  data: [],
  total: null,
  page: 1,
  currentSort: {
    field: 'id',
    order: 'ASC',
  },
  setPage: () => {},
  setSort: () => {},
  setPerPage: () => {},
  perPage: 25,
  children: null,
};

export default ReportTable;
