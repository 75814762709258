import React from 'react';
import { TextField } from 'react-admin';
import ArrayWithPaginationField from '../../../base/components/ra/fields/arrayWithPagination.field';
import CustomLinkField from '../../../base/components/ra/fields/customLink.field';
import resourceSlug from '../../../constant/resource-slug';
import { transformIdValue } from '../../../services/util/stringUtil';
import { ID_EMPTY } from '../../../constant';

const FeaturesTable = props => (
  <ArrayWithPaginationField
    label={false}
    {...props}
  >
    <CustomLinkField
      sortable={false}
      source="id"
      href={record => `/#/${resourceSlug.FEATURES}/${record.id}/show`}
      title={record => (record.id ? transformIdValue(record.id) : ID_EMPTY)}
    />
    <TextField
      source="name"
      sortable={false}
      label="resources.brand.fields.name"
    />
    <TextField
      source="code"
      sortable={false}
    />
  </ArrayWithPaginationField>
);

export default FeaturesTable;
