import {
  GET_AUTH_INFO,
  GET_AUTH_INFO_SUCCESS,
  UPDATE_REQUIRE_CHANGE_PASSWORD,
} from '../root.actions';

export const getAuthInfo = () => ({
  type: GET_AUTH_INFO,
});

export const setAuthInfo = user => ({
  type: GET_AUTH_INFO_SUCCESS,
  payload: {
    user,
  },
});

export const updateRequireChangePassword = ({ needChange }) => ({
  type: UPDATE_REQUIRE_CHANGE_PASSWORD,
  payload: needChange,
});
