import React from 'react';
import ReferenceAutocompleteInput from './ReferenceAutocompleteInput';

const BrandSelector = props => (
  <ReferenceAutocompleteInput
    label="common.fields.brand"
    source="brandId"
    reference="brand"
    {...props}
  />
);

export default BrandSelector;

BrandSelector.propTypes = {};

BrandSelector.defaultProps = {};
