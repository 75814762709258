/* eslint-disable react/prop-types */
import React from 'react';
import { usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import resourceSlug from '../../../constant/resource-slug';
import ApproveButton from './ExtraActionForList/ApproveButton';
import { useAuthUser } from '../../../base/hooks';

const useStyles = makeStyles(
  theme => ({
    approveButton: {
      '&[class*="contained"]': {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'rgba(15, 65, 123, 0.04)',
        },
      },
    },
  }),
  {
    index: 3,
  },
);

const ToolbarMenuForDetail = props => {
  const classes = useStyles();
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasPrepaidApprovalPermissionToUpdate = user?.superman || (Array.isArray(permissions) && !!permissions?.find(
    item => item.resource.name === resourceSlug.PREPAID_APPROVAL,
  )?.update);

  return (
    <ToolbarMenu {...props}>
      {hasPrepaidApprovalPermissionToUpdate && (
        <ApproveButton
          className={classes.approveButton}
          {...props}
        />
      )}
    </ToolbarMenu>
  );
};

ToolbarMenuForDetail.propTypes = {};

ToolbarMenuForDetail.defaultProps = {};

export default ToolbarMenuForDetail;
