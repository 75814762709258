import { Card } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { ReferenceField, TextField, UrlField, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { ShowSection } from '../../../base/components/guesser/wealth-show.guesser';
import CommonShowWrapper from '../../../base/components/customized/common-wrapper/CommonShowWrapper';
import { DateField } from '../../../base/components/ra/fields';
import Labeled from '../../../base/components/ra/labeled';
import { NavigationContext } from '../../../App';
import { CUSTOM_ROUTES, REVERSE_INTEGRATION_OPERATORS } from '../../../constant/customRoutes';
import { UUID_REGEX } from '../../../constant';

const ReverseWalletIntegrationShow = props => {
  const translate = useTranslate();
  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: translate('resources.reverse-integration.partnerIntegration.name'),
      },
      breadcrumbs: [
        {
          label: translate('ra.page.home'),
          link: '/',
        },
        {
          label: translate('resources.group.system-setup.name'),
        },
        {
          label: translate('resources.reverse-integration.name'),
          link: '/reverse-integration',
        },
        {
          label: REVERSE_INTEGRATION_OPERATORS.VERTBET.name,
          link: CUSTOM_ROUTES.VERTBET_PARTNER,
        },
        {
          label: translate('resources.reverse-integration.partnerIntegration.name'),
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, []);

  return (
    <CommonShowWrapper
      defaultToolbarProps={{
        hasList: false,
      }}
      {...props}
    >
      <Card>
        <ShowSection title={translate('common.show.general')}>
          <TextField
            source="id"
            label="common.fields.id"
          />
          <Labeled label="common.fields.created">
            <DateField source="createdAt" />
          </Labeled>
          <Labeled label="common.fields.updated">
            <DateField source="updatedAt" />
          </Labeled>
        </ShowSection>
        <ShowSection
          title={translate('resources.reverse-integration.partnerIntegration.partnerIntegrationInformation')}
        >
          <ReferenceField
            reference="group"
            source="groupId"
            label="common.fields.group"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference="brand"
            source="brandId"
            label="common.fields.brand"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <UrlField
            source="authorizeUrl"
            label="common.fields.authorizeUrl"
            target="_blank"
          />
          <UrlField
            source="getBalanceUrl"
            label="common.fields.getBalanceUrl"
            target="_blank"
          />
          <UrlField
            source="payOutUrl"
            label="common.fields.payOutUrl"
            target="_blank"
          />
          <UrlField
            source="placeBetUrl"
            label="common.fields.placeBetUrl"
            target="_blank"
          />
          <UrlField
            source="cancelBetUrl"
            label="common.fields.cancelBetUrl"
            target="_blank"
          />
        </ShowSection>
      </Card>
    </CommonShowWrapper>
  );
};

export default ReverseWalletIntegrationShow;
