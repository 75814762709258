/* eslint-disable */
import React, { useRef, useMemo, useEffect } from 'react';
import { Grid, Box, Button, makeStyles } from '@material-ui/core';
import { useListContext, useTranslate } from 'react-admin';
import { get, isEmpty } from 'lodash';
import { Form } from 'react-final-form';
import moment from 'moment';
import { noop } from '../../../../services/util';
import { useAuthUser } from '../../../hooks';
import { ExportButton } from '../../ra/buttons';
import { useFormContext } from './form.context';
import { ReportInputGuesser } from './report-input.guesser';

export const useStyles = makeStyles((theme) => ({
  fieldStyle: {
    '& [class*="MuiFormHelperText-root"]': {
      display: 'none',
    },
    '&.margin-custom-search-panel': {
      marginTop: '-3px',
    },
  },
  noWarning: {
    '& .Mui-error': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
      },
    },
    '& label.Mui-error': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  formStyle: {
    marginBottom: '20px',
    '& [class*="MuiGrid-root"]': {
      gap: '0 !important',
    },
    '& [class*="MuiGrid-item"]': {
      marginRight: '16px',
    },
    '& .search-panel__radio-button': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #0000003B',
      borderRadius: '4px',
      padding: '2px 14px',
      marginTop: '16px',
    },
  },
  btn: {
    width: 200,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.light,
    padding: theme.spacing(2, 4),
    borderRadius: '4px',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function SearchPanel(props) {
  const {
    filters,
    resource,
    optionsSearch = {
      excludeSearchFields: [],
      extendsSearch: null,
      extraSearchFields: [],
    },
    defaultRefFilter,
    showExporter,
    reportServiceVersion,
    filterProps,
  } = props;

  const { containerClassName } = filterProps || {};
  const { setFilters, filterValues = {}, loading } = useListContext();

  const translate = useTranslate();
  const refMount = useRef();
  const classes = useStyles();
  const user = useAuthUser();

  const { setValues, values, setShowReport, showReport, refValuesGroup } = useFormContext();

  const handleFilterReport = (dataReport) => {
    refMount.current = true;

    const filterInfo = {
      ...dataReport,
    };

    // Filter at the first time
    // Trigger to show report use the react-admin List
    if (!showReport) {
      return setShowReport(true);
    }

    // On filter from the second times
    // Request the report
    return setFilters(filterInfo);
  };

  const renderFilterList = (list, form, extra) => (
    <Grid
      container
      spacing={1}
      display="flex"
      style={{
        gap: '1rem',
        padding: '0 2px',
      }}
      className={containerClassName}
    >
      {list?.map((filter) => (
        <Grid item key={filter.source} className={classes.gridItem} data-field={filter.source}>
          <ReportInputGuesser
            filter={filter}
            form={form}
            classes={classes}
            resource={resource}
            translate={translate}
            defaultRefFilter={defaultRefFilter}
            filterValues={filterValues}
            user={user}
            reportServiceVersion={reportServiceVersion}
          />
        </Grid>
      ))}
      {Array.isArray(extra) && React.Children.map(extra, (searchElement) => (
        <Grid
          item
          key={get(searchElement, ['props', 'source'])}
          className={classes.gridItem}
          data-field={get(searchElement, ['props', 'source'])}
        >
          {searchElement}
        </Grid>
      ))}
    </Grid>
  );

  const checkValidSource = (sourceName) => {
    // Hide this field if Role's user is GROUP and this field is `group`
    const belongToGroup = ['group', 'groupId'].includes(sourceName);
    if (belongToGroup && user?.role?.type === 'GROUP') {
      return false;
    }

    // Check any field that need to hidden
    const excludedField = optionsSearch?.excludeSearchFields?.includes(sourceName);

    return !excludedField;
  };

  const groupFilter = useMemo(() => {
    const inputChoicesGroup = [];
    const inputInlineGroup = filters
      ?.filter((filter) => checkValidSource(filter.source))
      ?.filter((item) => {
        if (['quickRange', 'groupBy', 'dateQuickRange'].includes(item.source)) {
          inputChoicesGroup.push(item);
          return false;
        }
        return true;
      });

    return {
      inputChoicesGroup,
      inputInlineGroup,
    };
  }, [filters]);

  const initialValues = {
    ...filterValues,
    ...(isEmpty(filterValues)
      ? {
          ...refValuesGroup,
          ...values,
        }
      : {}),
  };

  const handleExportDate = (formValues) => {
    if (formValues?.minutes && formValues?.created) {
      const startDate = moment(formValues?.created).startOf('d').format();
      const endDate = moment(formValues?.created).add(parseInt(formValues?.minutes, 10), 'minutes').format();

      return [startDate, endDate].join(',');
    }

    return formValues?.created;
  };

  useEffect(
    () =>
      function cleanUp() {
        setFilters({});
      },
    [],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={noop}
      render={({ form }) => {
        const formValues = form.getState().values;
        const formNotChanged = JSON.stringify(formValues) === JSON.stringify(values);

        setValues(formValues);

        return (
          <form
            className={classes.formStyle}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid
              container
              spacing={1}
              display="flex"
              style={{
                gap: '1rem',
                padding: '0 2px',
              }}
            >
              {optionsSearch?.extendsSearch &&
                React.cloneElement(optionsSearch.extendsSearch, {
                  form,
                })}
            </Grid>
            {renderFilterList(groupFilter.inputInlineGroup, form, optionsSearch?.extraSearchFields)}
            {renderFilterList(groupFilter.inputChoicesGroup, form)}
            <Box display="flex" alignItems="center" marginTop={3} marginBottom={3}>
              <Button
                onClick={() => handleFilterReport(formValues)}
                className={`${classes.btn} search-panel__export-button`}
                disabled={refMount.current && formNotChanged}
              >
                {translate('resources.user.text.report')}
              </Button>
              {showExporter && (
                <ExportButton
                  reportView
                  className={classes.btn}
                  date={handleExportDate(formValues)}
                  additionFilter={values}
                  disabled={loading}
                />
              )}
            </Box>
          </form>
        );
      }}
    />
  );
}

export default SearchPanel;
