import { CACHE_LARGE_WIN_BY_PAGE, UPDATE_CURRENT_LARGE_WIN_PAGE } from '../root.actions';

export const cacheLargeWinByPage = ({
  data, page,
}) => ({
  type: CACHE_LARGE_WIN_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentLargeWinPage = data => ({
  type: UPDATE_CURRENT_LARGE_WIN_PAGE,
  payload: data,
});
