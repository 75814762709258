import { Box, darken, alpha, IconButton } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import { RefreshSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import {
  useNotify,
  useRefresh,
  useTranslate,
  useDataProvider,
  useLoading,
} from "react-admin";
import HeaderInfo from "../../../../../../../base/components/layout/headerInfo";
import SidebarToggleHandler from "../../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler";
import LanguageSwitcher from "../../LanguageSwitcher";
import Logo from "../../Logo";
import UserDropDown from "../../UserDropDown";
import {
  checkCheatModeEnv,
  getBackofficeEnv,
  RoleBuiltIn,
} from "../../../../../../../services/util";
import { useSelector } from "react-redux";
import { useAuthUser } from "../../../../../../../base/hooks";
import { useReloadContext } from "../../../../../../../base/context/reload/reload.context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: 10,
    padding: 0,
    "& .Cmt-appIcon": {
      color: theme.palette.text.secondary,
      "&:hover, &:focus": {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down("xs")]: {
        padding: 7,
      },
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
  langRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down("xs")]: {
      padding: 9,
    },
  },
  transactionWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  refreshIcon: {
    color: theme.palette.primary.contrastText,
  },
  viewButton: {
    color: "white",
    "&:hover": {
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const GoToLobbyPage = () => {
  const user = useAuthUser();
  const translate = useTranslate();
  const env = getBackofficeEnv();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [statePlayer, setStatePlayer] = useState();

  const shouldCallApi =
    user?.role?.id === RoleBuiltIn.STREAMER && checkCheatModeEnv();

  const brand = user?.brands?.[0];

  const getPlayer = async (user) => {
    try {
      const { data } = await dataProvider.getList("player", {
        pagination: {
          page: 1,
          perPage: 1,
        },
        filter: {
          nativeId: user?.username?.split("_")?.[1],
          groupId: user?.group?.id,
          brandId: brand?.id,
        },
      });

      if (data.length) {
        const player = data[0];
        setStatePlayer(player);
      } else {
        const { data: cheatModeLink } = await dataProvider.getOneWithFilter(
          "cheat-mode-link",
          {
            filter: {
              "user.id": user?.id,
              groupId: user?.group?.id,
              brandId: brand?.id,
            },
          }
        );

        if (cheatModeLink) {
          const player = cheatModeLink.players?.[0];
          setStatePlayer(player);
        }
      }
    } catch {
      //
    }
  };

  useEffect(() => {
    if (shouldCallApi) {
      getPlayer(user);
    }
  }, [shouldCallApi]);

  if (!shouldCallApi) {
    return null;
  }

  return (
    <Box>
      <Box className={clsx("Cmt-i18n-switch")}>
        <Tooltip
          title={translate("ra.text.lobbyPage")}
          className={classes.viewButton}
        >
          <IconButton
            onClick={(e) => {
              const brandCode = brand?.code;
              const groupCode = user?.group?.code;

              const href = `${
                env.LOBBY_PAGE_URL ||
                "https://lobby.dev.game.topasianplatform.com/"
              }?brandCode=${brandCode}&groupCode=${groupCode}&playerToken=${
                statePlayer?.playerToken
              }`;
              window.open(href, "_blank");
            }}
          >
            <SportsEsportsIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const HeaderTop = () => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const { setValuesContext } = useReloadContext();

  const pageLoading = useLoading();

  const handleRefresh = useCallback(() => {
    refresh();
    notify(translate("ra.notification.refreshed", "success"));

    return () => {
      if (!pageLoading) {
        setValuesContext({});
      }
    };
  }, [notify, refresh, translate, setValuesContext, pageLoading]);

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo
        mr={{
          xs: 2,
          sm: 4,
          lg: 6,
          xl: 8,
        }}
      />
      <Box
        display="flex"
        alignItems="center"
        ml={{ base: "'unset'", sm: "auto" }}
      >
        <Hidden xsDown>
          <HeaderInfo />
          <IconButton
            aria-label="Refresh"
            aria-haspopup="true"
            onClick={handleRefresh}
            color="default"
          >
            <RefreshSharp className={classes.refreshIcon} />
          </IconButton>
        </Hidden>
        <Box
          display={{
            xs: "none",
            sm: "block",
          }}
        >
          <Box className={clsx(classes.langRoot, "Cmt-i18n-switch")}>
            <LanguageSwitcher />
          </Box>
        </Box>

        <GoToLobbyPage />
        <UserDropDown />
      </Box>
    </Toolbar>
  );
};

export default HeaderTop;
