import React from 'react';
import { ReferenceField, TextField, useRecordContext } from 'react-admin';

const RecordIdField = () => {
  const record = useRecordContext();

  return (
    <ReferenceField
      source="recordId"
      reference={record.module}
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
  );
};

export default RecordIdField;
