import React, { cloneElement, isValidElement, useContext } from 'react';
import { List, Box, Badge } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import clsx from 'clsx';
import LayoutContext from '../../CmtLayouts/Horizontal/LayoutContext/LayoutContext';

const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: theme.palette.sidebar.textColor,
    cursor: 'pointer',
    '&:hover, &:focus': {
      color: theme.palette.sidebar.textDarkColor,
      backgroundColor: theme.palette.sidebar.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textDarkColor,
      },
    },
    '&.active': {
      color: theme.palette.sidebar.textActiveColor,
      backgroundColor: theme.palette.sidebar.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: theme.palette.sidebar.textActiveColor,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
    whiteSpace: 'nowrap',
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
}));

const NavMenuItem = props => {
  const {
    name, icon, link, count,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { setOpen } = useContext(LayoutContext);

  const handleOnClick = () => {
    history.push(link);
    setOpen(false);
  };

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const renderBadge = () => {
    if (count > 0) {
      return (
        <Box ml={2}>
          <Badge
            badgeContent={count}
            color="secondary"
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <List
      component="div"
      className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}
    >
      <Box
        className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')}
        onClick={handleOnClick}
      >
        {/* Display an icon if any */}
        {renderIcon()}
        <Box
          component="span"
          flex={1}
          className={clsx(classes.navText, 'Cmt-nav-text')}
        >
          {name}
        </Box>
        {renderBadge()}
      </Box>
    </List>
  );
};

export default NavMenuItem;
