/* eslint-disable no-case-declarations */
import { CACHE_BETS_BY_PAGE, UPDATE_CURRENT_BET_PAGE } from '../root.actions';

const initialBetStates = {
  betList: {},
  currentBetPage: 1,
};

export const betReducer = (state = initialBetStates, action) => {
  switch (action.type) {
    case CACHE_BETS_BY_PAGE:
      const newPage = action.payload?.page || 1;
      return {
        ...state,
        currentBetPage: newPage,
        betList:
          newPage === 1
            ? {
              1: action.payload?.data,
            }
            : {
              ...state.betList,
              [newPage]: action.payload?.data,
            },
      };

    case UPDATE_CURRENT_BET_PAGE:
      return {
        ...state,
        currentBetPage: action.payload,
      };
    default:
      return state;
  }
};
