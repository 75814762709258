export const DEPEND_ON_PERM = {
  create: {
    list: true,
    read: true,
  },
  update: {
    list: true,
    read: true,
  },
  delete: {
    list: true,
    read: true,
  },
  export: {
    list: true,
    read: true,
  },
  onlyMe: {
    read: true,
    list: true,
  },
};

export const PERM_CHECKBOX_WIDTH = {
  default: 200,
  lg: 150,
  md: 100,
};

export const COLUMN_COUNT = 7;
export const CONTAINER_MIN_WIDTH = 750;

export const LIST_PERM_KEY = [
  'list',
  'read',
  'create',
  'update',
  'delete',
  'export',
  'onlyMe',
];
