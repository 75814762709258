/* eslint-disable */
import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import { Box, Divider, Button, useTheme, alpha, makeStyles } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import cls from 'classnames';
import { NUMBER_TO_SHOW_PREPAID_ACTIVE_STATUS, PrepaidStatusEnum } from '../../../constant';
import TextSplitting from '../text-splitting';
import resourceSlug from '../../../constant/resource-slug';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  prepaidName: {
    fontWeight: 700,
  },
  activationSuccess: {
    color: theme.palette.success.dark,
  },
  activationFail: {
    color: theme.palette.error.main,
  },
}));

const MessageItem = (props) => {
  const {
    // prepaidId,
    prepaidName,
    status,
    timestamp,
    classes,
    muiTheme,
  } = props;

  const translate = useTranslate();

  const [fromNow, setFromNow] = useState(moment.unix(timestamp).fromNow());
  const intervalSetFromNow = useRef();

  useEffect(() => {
    intervalSetFromNow.current = setInterval(() => {
      setFromNow(moment.unix(timestamp).fromNow());
    }, 1000);

    return function cleanUp() {
      clearInterval(intervalSetFromNow.current);
    };
  }, []);

  const notificationText =
    status === PrepaidStatusEnum.ACTIVE
      ? translate('ra.notification.resourceActiveSuccessful', {
          _: 'The %{smart_name} is activated successful.',
          smart_name: `__${prepaidName}__`,
        })
      : status === PrepaidStatusEnum.APPROVED
      ? translate('ra.notification.resourceApprovedSuccessful', {
          _: 'The %{smart_name} is approved.',
          smart_name: `__${prepaidName}__`,
        })
      : translate('ra.notification.resourceActiveFailed', {
          _: 'The %{smart_name} is activated failed.',
          smart_name: `__${prepaidName}__`,
        });

  const prepaidNameTextProps = {
    className: cls(classes.prepaidName, {
      [classes.activationSuccess]: status === PrepaidStatusEnum.ACTIVE || status === PrepaidStatusEnum.APPROVED,
      [classes.activationFail]: status === PrepaidStatusEnum.INACTIVE,
    }),
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
          fontSize: '12px',
        }}
      >
        <Box>
          <Box
            component="span"
            style={{
              fontSize: '14px',
            }}
          >
            <TextSplitting
              text={notificationText}
              leftPart={{
                tag: 'span',
                limitChar: '__',
              }}
              middlePart={{
                tag: 'span',
                props: prepaidNameTextProps,
              }}
              rightPart={{
                tag: 'span',
                limitChar: '__',
              }}
            />

            <Box
              component="span"
              style={{
                color: alpha(muiTheme.palette.primary.subText, 0.62),
                textAlign: 'right',
              }}
            >
              {` - ${fromNow}`}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PrepaidProcessMessage = ({ closeToast, toastProps }) => {
  const translate = useTranslate();
  const muiTheme = useTheme();
  const classes = useStyles();
  const refresh = useRefresh();
  const { pathname } = useLocation();
  const toastData = toastProps?.data;

  if (isEmpty(toastData)) {
    return null;
  }

  useEffect(() => {
    try {
      const toastList = Object.values(toastData);
      if (!toastList.length || pathname.replace('/', '') !== resourceSlug.PREPAID) {
        return;
      } else {
        refresh();
      }
    } catch (error) {
      // Do nothing
    }
  }, [toastData]);

  const listNotification = Object.keys(toastData)
    .splice(0, NUMBER_TO_SHOW_PREPAID_ACTIVE_STATUS)
    .map((timestamp, index) => {
      const { id: prepaidId, name: prepaidName, status } = toastData[timestamp];

      return (
        <Fragment key={timestamp}>
          <MessageItem
            prepaidId={prepaidId}
            prepaidName={prepaidName}
            status={status}
            timestamp={+timestamp / 1000}
            classes={classes}
            muiTheme={muiTheme}
          />
          {index < Object.keys(toastData).length - 1 && (
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
          )}
        </Fragment>
      );
    });

  return (
    <Box>
      <Box
        style={{
          textAlign: 'center',
          fontSize: '15px',
          fontWeight: 500,
          marginBottom: '16px',
        }}
      >
        {translate('ra.text.prepaidNotificationTitle', {
          _: 'New Prepaid Notifications',
        })}
      </Box>
      <Box>{listNotification}</Box>
      {Object.keys(toastData).length > NUMBER_TO_SHOW_PREPAID_ACTIVE_STATUS && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '3px',
          }}
        >
          <Button
            size="small"
            color="private"
            onClick={() => {
              window.open('/#/prepaid', '_blank');
              closeToast();
            }}
            style={{
              fontSize: '12px',
              fontWeight: 500,
              color: muiTheme.palette.primary.main,
            }}
          >
            {translate('ra.action.showMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PrepaidProcessMessage;
