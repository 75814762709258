import * as React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { JsonInput } from 'react-admin-json-view';

const useStyle = makeStyles(() => ({
  customModalKeyName: {
    '& .key-modal-request > div': {
      marginTop: '-20px !important',
    },
  },
}));

const JsonInputComponent = props => {
  const classes = useStyle();

  return (
    <Box className={classes.customModalKeyName}>
      <JsonInput {...props} />
    </Box>
  );
};

JsonInputComponent.propTypes = {};

JsonInputComponent.defaultProps = {};

export default JsonInputComponent;
