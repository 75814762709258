import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'react-admin';
import { useSelector } from 'react-redux';
import { CurrencyDisplayModeEnum } from '../../../constant';
import CurrenciesReference from '../reference-fields/currencies';

const parseCurrenciesDisplayConfig = (currencies, selectedCurrencies, selectedIds) => selectedIds?.map(id => {
  const currencyDetail = currencies?.[id] || {};
  const { mode: currencyMode } = currencyDetail;

  const sanitizedCurrency = {
    id,
  };

  const itemInSelected = selectedCurrencies?.find(item => item.id === id);
  const modeOfItemInSelected = itemInSelected?.mode;

  if (modeOfItemInSelected) {
    sanitizedCurrency.mode = modeOfItemInSelected;
  } else if (currencyMode) {
    sanitizedCurrency.mode = currencyMode;
  } else {
    sanitizedCurrency.mode = CurrencyDisplayModeEnum.NORMAL;
  }

  return sanitizedCurrency;
});

const CurrenciesSelector = ({
  source, selectedCurrencies, ...props
}) => {
  const currenciesList = useSelector(({ admin }) => admin.resources.currency.data);

  return (
    <CurrenciesReference
      {...props}
      source={source}
      parse={ids => parseCurrenciesDisplayConfig(currenciesList, selectedCurrencies, ids)}
      validate={required()}
    />
  );
};

CurrenciesSelector.propTypes = {
  source: PropTypes.string.isRequired,
  selectedCurrencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      mode: PropTypes.oneOf(Object.values(CurrencyDisplayModeEnum)),
    }),
  ).isRequired,
};

export default CurrenciesSelector;
