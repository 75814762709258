export const InboxHub = {
  game: {
    value: 'game',
    displayName: 'Game',
  },
};

export const InboxModule = {
  INBOX: {
    value: 'INBOX',
    displayName: 'Inbox',
  },
};

export const InboxType = {
  GAME_BASED: {
    value: 'GAME_BASED',
    displayName: 'Game Based',
  },
  GENERAL: {
    value: 'GENERAL',
    displayName: 'General',
  },
};
