import React from 'react';
import PropTypes from 'prop-types';
import { SIDE_TYPE } from './transfer-list.constant';
import SkeletonItem from './transfer-list.skeleton';

function TransferListLoading(props) {
  const { sideType } = props;

  return (
    <>
      <SkeletonItem width="80%" />
      <SkeletonItem />
      {sideType === SIDE_TYPE.left && (
        <>
          <SkeletonItem width="70%" />
          <SkeletonItem width="90%" />
        </>
      )}
    </>
  );
}

TransferListLoading.propTypes = {
  sideType: PropTypes.string.isRequired,
};

export default TransferListLoading;
