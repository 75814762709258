import PrepaidCreate from './prepaid.create';
import PrepaidEdit from './prepaid.edit';
import PrepaidList from './prepaid.list';
import PrepaidShow from './prepaid.show';

export default {
  create: PrepaidCreate,
  edit: PrepaidEdit,
  list: PrepaidList,
  show: PrepaidShow,
};
