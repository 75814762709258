/* eslint-disable react/require-default-props, react/prop-types, react/jsx-no-duplicate-props */
import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  TextField as MuiTextField,
  Popper,
  Paper,
  Button,
  InputAdornment,
  makeStyles,
  ClickAwayListener,
} from '@material-ui/core';
import { sanitizeInputRestProps, InputHelperText } from 'react-admin';
import noop from 'lodash/noop';
import isNaN from 'lodash/isNaN';
import { useInput, useTranslate, FieldTitle } from 'ra-core';
import { withStandardInputProps } from '../../../hoc/with-standard-input-props';
import ClearableButton from '../../clearable-button';
import { FILTER_PREFIX_ON_VALUE } from '../../../../constant';

const STYLE_INPUT_WIDTH = {
  sm: '165px',
};

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    [theme.breakpoints.up('xs')]: {},
    [theme.breakpoints.up('sm')]: {
      width: STYLE_INPUT_WIDTH.sm,
      '& input': {
        width: STYLE_INPUT_WIDTH.sm,
        minWidth: STYLE_INPUT_WIDTH.sm,
        margin: 0,
        height: 'auto',
        boxSizing: 'border-box',
        padding: '10px 16px',
        marginRight: '10px',
      },
      '& fieldset': {
        width: STYLE_INPUT_WIDTH.sm,
      },
    },
  },
  confirmButton: {
    backgroundColor: theme.palette.background.default,
    fontSize: '12px',
    padding: '0px 15px',
  },
}));

const TextField = withStandardInputProps(MuiTextField);

const TextFieldCustom = props => {
  const classes = useStyles();
  return (
    <Box>
      <TextField
        {...props}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        classes={{
          root: classes.textFieldRoot,
        }}
      />
    </Box>
  );
};

const NumberRange = ({
  helperText,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse,
  format,
  resource,
  source,
  validate,
  variant,
  ...rest
}) => {
  const [openPopper, setOpenPopper] = useState(false);
  const [numberRangeDisplay, setNumberRangeDisplay] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const isFocusRef = useRef(false);

  const classes = useStyles();
  const rangePopperRef = useRef();

  const anchorEl = useRef();
  const translate = useTranslate();

  const {
    id,
    input,
    isRequired,
    meta: {
      error, submitError, touched,
    },
  } = useInput({
    onBlur,
    onChange,
    onFocus,
    parse,
    format,
    resource,
    source,
    validate,
    ...rest,
  });

  const updateInputValue = ([min, max] = []) => {
    if (isNaN(min) && isNaN(max)) {
      input.onChange(undefined);
      return;
    }

    let newValue = '';
    switch (true) {
      case !isNaN(min) && !isNaN(max):
        newValue = `${FILTER_PREFIX_ON_VALUE.BETWEEN}${min},${max}`;
        break;
      case isNaN(min) && !isNaN(max):
        newValue = `${FILTER_PREFIX_ON_VALUE.LOWER_THAN_EQUALS}${max}`;
        break;
      case !isNaN(min) && isNaN(max):
        newValue = `${FILTER_PREFIX_ON_VALUE.GREATER_THAN_EQUALS}${min}`;
        break;
      case min === max:
        newValue = `${FILTER_PREFIX_ON_VALUE.EQUALS}${max}`;
        break;
      default:
        newValue = undefined;
    }

    input.onChange(newValue);
  };

  const handleMinInputChange = event => {
    const value = parseFloat(event.currentTarget?.value);

    if (isNaN(value)) {
      setMinValue('');
      return;
    }

    setMinValue(Math.max(0, value));
  };

  const handleMaxInputChange = event => {
    const value = parseFloat(event.currentTarget?.value);

    if (isNaN(value)) {
      setMaxValue('');
      return;
    }

    setMaxValue(Math.max(0, value));
  };

  const getNumberRange = inputValue => {
    let min = '';
    let max = '';

    let numberRangeTemp = '';
    switch (true) {
      case inputValue.startsWith(FILTER_PREFIX_ON_VALUE.EQUALS):
        [, min] = inputValue.split(FILTER_PREFIX_ON_VALUE.EQUALS);
        max = min;
        break;
      case inputValue.startsWith(FILTER_PREFIX_ON_VALUE.BETWEEN):
        [, numberRangeTemp] = inputValue.split(FILTER_PREFIX_ON_VALUE.BETWEEN);
        [min, max] = numberRangeTemp?.split?.(',');
        break;
      case inputValue.startsWith(FILTER_PREFIX_ON_VALUE.GREATER_THAN_EQUALS):
        [, min] = inputValue.split(FILTER_PREFIX_ON_VALUE.GREATER_THAN_EQUALS);
        break;
      case inputValue.startsWith(FILTER_PREFIX_ON_VALUE.LOWER_THAN_EQUALS):
        [, max] = inputValue.split(FILTER_PREFIX_ON_VALUE.LOWER_THAN_EQUALS);
        min = 0;
        break;
      default:
        return ['', ''];
    }

    return [min, max];
  };

  const generateDisplayValue = (min, max) => {
    let displayValue = '';
    switch (true) {
      case !isNaN(min) && !isNaN(max):
        displayValue = `${min} ~ ${max}`;
        break;
      case !isNaN(min) && isNaN(max):
        displayValue = `${min} ~ ∞`;
        break;
      case isNaN(min) && !isNaN(max):
        displayValue = `0 ~ ${max}`;
        break;
      default:
        displayValue = '';
    }
    return displayValue;
  };

  const submitNumberRange = () => {
    let min = parseFloat(minValue);
    let max = parseFloat(maxValue);

    if (!isNaN(min) && !isNaN(max)) {
      [min, max] = [Math.min(min, max), Math.max(min, max)];
    }

    setNumberRangeDisplay(generateDisplayValue(min, max));
    setOpenPopper(false);
    updateInputValue([min, max]);
    setMinValue(min);
    setMaxValue(max);
  };

  const clearNumberRangeValue = e => {
    e.stopPropagation();
    input.onChange('');
    setNumberRangeDisplay('');
  };

  useEffect(() => {
    let [min, max] = getNumberRange(input.value);
    min = parseFloat(min);
    max = parseFloat(max);

    if (numberRangeDisplay === '' && (!isNaN(min) || !isNaN(max))) {
      setNumberRangeDisplay(generateDisplayValue(min, max));
      setMinValue(min);
      setMaxValue(max);
    }
  }, [input.value]);

  return (
    <Box
      width="100%"
      position="relative"
      height="fit-content"
    >
      <Box ref={anchorEl}>
        <TextField
          id={id}
          {...input}
          variant={variant}
          error={!!(touched && (error || submitError))}
          helperText={(
            <InputHelperText
              touched={touched}
              error={error || submitError}
              helperText={helperText}
            />
          )}
          label={(
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          )}
          margin={margin}
          {...options}
          {...sanitizeInputRestProps(rest)}
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="end">
                <ClearableButton
                  isDisplay={!!numberRangeDisplay}
                  handleClear={clearNumberRangeValue}
                />
              </InputAdornment>
            ),
          }}
          onFocus={() => {
            isFocusRef.current = true;
            setOpenPopper(true);
          }}
          onBlur={() => {
            isFocusRef.current = false;
          }}
          onChange={noop}
          value={numberRangeDisplay}
        />
      </Box>
      <ClickAwayListener onClickAway={() => !isFocusRef.current && setOpenPopper(false)}>
        <Popper
          id="popper-slider"
          ref={rangePopperRef}
          open={openPopper}
          anchorEl={anchorEl.current}
          style={{
            zIndex: 200,
          }}
        >
          <Paper
            style={{
              padding: '20px 24px 16px',
            }}
          >
            <Box
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
              }}
              alignItems="center"
              marginTop="8px"
            >
              <TextFieldCustom
                id="min-input"
                label={translate('ra.field.min')}
                type="number"
                placeholder="0"
                value={minValue}
                onChange={handleMinInputChange}
              />
              <Box margin="0 8px">~</Box>
              <TextFieldCustom
                id="max-input"
                label={translate('ra.field.max')}
                placeholder="∞"
                type="number"
                value={maxValue}
                onChange={handleMaxInputChange}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              marginTop="16px"
            >
              <Button
                variant="outlined"
                classes={{
                  root: classes.confirmButton,
                }}
                onClick={submitNumberRange}
              >
                {translate('ra.action.ok')}
              </Button>
            </Box>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

NumberRange.propTypes = {};

NumberRange.defaultProps = {};

export default NumberRange;
