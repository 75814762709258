/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Link, Divider, Button, useTheme, alpha } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import moment from 'moment';

import { NUMBER_TO_SHOW_EXPORTED_FILES } from '../../../constant';
import resourceSlug from '../../../constant/resource-slug';

const DownloadButton = styled(GetAppIcon)`
  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

const MessageItem = props => {
  const {
    resourceName, timestamp, downloadUrl, exportHistoryId, muiTheme,
  } = props;

  const translate = useTranslate();

  const [fromNow, setFromNow] = useState(moment.unix(timestamp).fromNow());
  const intervalSetFromNow = useRef();

  useEffect(() => {
    intervalSetFromNow.current = setInterval(() => {
      setFromNow(moment.unix(timestamp).fromNow());
    }, 1000);

    return function cleanUp() {
      clearInterval(intervalSetFromNow.current);
    };
  }, []);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
          fontSize: '12px',
        }}
      >
        <Box>
          <Box
            component="span"
            style={{
              fontWeight: 700,
              fontSize: '14px',
            }}
          >
            {resourceName}
          </Box>
          <Box
            component="span"
            style={{
              color: alpha(muiTheme.palette.primary.subText, 0.62),
            }}
          >
            {` - ${fromNow}`}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Link
          href={`/#/${resourceSlug.EXPORT_HISTORY}/${exportHistoryId}/show`}
          target="_blank"
          style={{
            fontSize: '13px',
          }}
        >
          {translate('ra.action.viewDetail')}
        </Link>
        <DownloadButton
          onClick={() => window.open(downloadUrl, '_self')}
          theme={muiTheme}
          style={{
            marginLeft: 'auto',
          }}
        />
      </Box>
    </>
  );
};

const ExportProcessMessage = ({
  closeToast, toastProps,
}) => {
  const translate = useTranslate();
  const muiTheme = useTheme();

  if (isEmpty(toastProps?.data)) {
    return null;
  }

  const listExportFile = Object.keys(toastProps.data)
    .splice(0, NUMBER_TO_SHOW_EXPORTED_FILES)
    .map((timestamp, index) => {
      const {
        id: exportHistoryId, resourceName, url: downloadUrl,
      } = toastProps.data[timestamp];

      return (
        <Fragment key={timestamp}>
          <MessageItem
            resourceName={translate('ra.message.resourceWasExported', {
              smart_name: translate(`resources.${resourceName}.name`),
            })}
            timestamp={+timestamp}
            downloadUrl={downloadUrl}
            exportHistoryId={exportHistoryId}
            data={toastProps.data}
            muiTheme={muiTheme}
          />
          {index < Object.keys(toastProps.data).length - 1 && <Divider />}
        </Fragment>
      );
    });

  return (
    <Box>
      <Box
        style={{
          textAlign: 'center',
          fontSize: '15px',
          fontWeight: 500,
          marginBottom: '16px',
        }}
      >
        {translate('ra.text.newExportedFiles')}
      </Box>
      <Box>{listExportFile}</Box>
      {Object.keys(toastProps?.data).length > NUMBER_TO_SHOW_EXPORTED_FILES && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '3px',
          }}
        >
          <Button
            size="small"
            color="private"
            onClick={() => {
              window.open(`/#/${resourceSlug.EXPORT_HISTORY}`, '_blank');
              closeToast();
            }}
            style={{
              fontSize: '12px',
              fontWeight: 500,
              color: muiTheme.palette.primary.main,
            }}
          >
            {translate('ra.action.showMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ExportProcessMessage;
