import {
  TOGGLE_LOADING,
  GET_ALLOWED_RESOURCES_SUCCESS,
  STORE_DETAIL_NAME,
  SHOW_CONFIRM_DIALOG,
} from '../root.actions';

export const toggleLoading = isLoading => ({
  type: TOGGLE_LOADING,
  payload: isLoading,
});

export const getAllowedResources = allowedResources => ({
  type: GET_ALLOWED_RESOURCES_SUCCESS,
  allowedResources,
});

export const storeDetailName = detailName => ({
  type: STORE_DETAIL_NAME,
  detailName,
});

export const showConfirmDialog = ({
  isOpen, title, content, onConfirm, onClose,
}) => ({
  type: SHOW_CONFIRM_DIALOG,
  payload: {
    isOpen,
    title,
    content,
    onConfirm,
    onClose,
  },
});
