/* eslint-disable */
import { isEmpty } from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { useListContext, useNotify, useTranslate } from 'react-admin';
import { useReloadContext } from '../../../base/context/reload/reload.context';
import { canI, RbacAction } from '../../../services/provider/rbacProvider';
import SmallButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Axios from 'axios';
import resourceSlug from '../../../constant/resource-slug';
import get from 'lodash/get';

const useStyles = makeStyles((muiTheme) => ({
  button: {
    display: 'block',
    alignItems: 'center',
    minWidth: 160,
    padding: '8px',
    background: muiTheme.palette.primary.light,
    color: muiTheme.palette.secondary.main,
    '&:hover': {
      backgroundColor: muiTheme.palette.primary.light,
    },
  },
}));

const extendBtnProps = {
  color: 'secondary',
  variant: 'contained',
  size: 'small',
};

const ActionLink = memo((props) => {
  const { record, permissions, requestFields } = props;
  const { data: listData } = useListContext();
  const notify = useNotify();

  const translate = useTranslate();
  const [loading, setLoading] = useState({ enabled: false, disabled: false, addToAll: false, removeFromAll: false });
  const classes = useStyles();

  const { setValuesContext } = useReloadContext();

  if (!canI(RbacAction.UPDATE, resourceSlug.GAME, permissions) || isEmpty(record)) {
    return null;
  }

  const { enabled } = record;

  const handleUpdateRecord = useCallback(
    async (data, loadingKey, successMessage) => {
      try {
        setLoading((previousData) => ({ ...previousData, [loadingKey]: true }));

        const previousData = requestFields.reduce((acc, field) => {
          return { ...acc, [field]: get(record, field) };
        }, {});

        const newData = { ...previousData, ...data };

        if (data.enabled) {
          delete newData.isAvailable;
        } else if (data.isAvailable) {
          delete newData.enabled;
        }

        const newRecord = await Axios.patch(`api/${resourceSlug.GAME}/${record.id}`, newData);

        const tmpListContextData = JSON.parse(JSON.stringify(listData));

        tmpListContextData[record.id] = newRecord.data;

        setValuesContext({
          data: tmpListContextData,
        });

        notify(successMessage, 'success');
      } finally {
        setLoading((previousData) => ({ ...previousData, [loadingKey]: false }));
      }
    },
    [record, requestFields, listData],
  );

  return (
    <>
      <SmallButton
        onClick={() => handleUpdateRecord({ enabled: true }, 'enabled', translate('ra.message.enabledSuccessfully'))}
        className={classes.button}
        disabled={loading.enabled || enabled}
        {...extendBtnProps}
      >
        {translate('ra.action.enabled')}
      </SmallButton>

      <SmallButton
        onClick={() => handleUpdateRecord({ enabled: false }, 'disabled', translate('ra.message.disabledSuccessfully'))}
        className={classes.button}
        disabled={loading.disabled || !enabled}
        {...extendBtnProps}
      >
        {translate('ra.action.disabled')}
      </SmallButton>
      <SmallButton
        onClick={() => handleUpdateRecord({ isAvailable: true }, 'addToAll', translate('ra.message.addedSuccessfully'))}
        className={classes.button}
        disabled={loading.addToAll || !enabled}
        {...extendBtnProps}
      >
        {translate('ra.action.addToAll')}
      </SmallButton>
      <SmallButton
        onClick={() =>
          handleUpdateRecord({ isAvailable: false }, 'removeFromAll', translate('ra.message.removedSuccessfully'))
        }
        className={classes.button}
        disabled={loading.removeFromAll || !enabled}
        {...extendBtnProps}
      >
        {translate('ra.action.removeFromAll')}
      </SmallButton>
    </>
  );
});

export default ActionLink;
