import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import queryString from 'querystring';
import isEmpty from 'lodash/isEmpty';

const FormContext = React.createContext(null);

export const useFormContext = () => useContext(FormContext);

export const FormContextProvider = ({
  children, initValues = {},
}) => {
  const location = useLocation();
  const refValues = useRef(initValues);
  const [refValuesGroup, setRefValues] = useState({});
  const [isMounted, setMounted] = useState(false);

  const [showReport, setShowReport] = useState(() => {
    try {
      const { filter = '{}' } = queryString.parse(location.search.substring(1)) || {};

      if (!isEmpty(JSON.parse(filter))) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  });

  const setValues = (values = {}) => {
    refValues.current = values;
  };

  return (
    <FormContext.Provider
      value={{
        values: refValues.current,
        setValues,
        showReport,
        setShowReport,
        setRefValues,
        refValuesGroup,
        isMounted,
        setMounted,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

FormContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  initValues: PropTypes.object,
};

FormContextProvider.defaultProps = {
  initValues: {},
};
