import React, { useContext, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const BetSettingPickerContext = createContext({
  // Setting values
  betSize: [],
  betLevel: [],
  baseBet: [],
  totalBet: [],
  // Update setting values
  setBetSize: noop,
  setBetLevel: noop,
  setBaseBet: noop,
  setTotalBet: noop,
  // Touches
  betSizeTouch: null,
  betLevelTouch: null,
  baseBetTouch: null,
  totalBetTouch: null,
  // Update touches
  setBetSizeTouch: noop,
  setBetLevelTouch: noop,
  setBaseBetTouch: noop,
  setTotalBetTouch: noop,
});

export const useBetSettingPickerContext = () => useContext(BetSettingPickerContext);

export const BetSettingPickerProvider = ({ children }) => {
  const [betSize, setBetSize] = useState([]);
  const [betLevel, setBetLevel] = useState([]);
  const [baseBet, setBaseBet] = useState([]);
  const [totalBet, setTotalBet] = useState([]);

  const [betSizeTouch, setBetSizeTouch] = useState(false);
  const [betLevelTouch, setBetLevelTouch] = useState(false);
  const [baseBetTouch, setBaseBetTouch] = useState(false);
  const [totalBetTouch, setTotalBetTouch] = useState(false);

  return (
    <BetSettingPickerContext.Provider
      value={{
        // States
        betSize,
        betLevel,
        baseBet,
        totalBet,
        // Set states
        setBetSize,
        setBetLevel,
        setBaseBet,
        setTotalBet,
        // Touch values
        betSizeTouch,
        betLevelTouch,
        baseBetTouch,
        totalBetTouch,
        // Update touch
        setBetSizeTouch,
        setBetLevelTouch,
        setBaseBetTouch,
        setTotalBetTouch,
      }}
    >
      {children}
    </BetSettingPickerContext.Provider>
  );
};

BetSettingPickerProvider.propTypes = {
  children: PropTypes.node,
};

BetSettingPickerProvider.defaultProps = {
  children: null,
};
