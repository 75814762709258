/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslate } from 'ra-core';
import { Box, makeStyles } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import lowerCase from 'lodash/lowerCase';
import isEmpty from 'lodash/isEmpty';

import PickerColumn from './picker-column';
import { PICKER_MAX_WIDTH, PICKER_MIN_WIDTH } from './bet-setting-picker.constant';
import { getNumberOnlyTwoDigitsAfter } from '../../../services/util/formatNumber';

const useClasses = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  pickerControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    overflowX: 'auto',
  },
  divideChar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 238,
    boxSizing: 'content-box',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
    padding: theme.spacing(1),
    border: '1px solid transparent',
  },
  pickerColumnWrapper: {
    flex: 1,
    maxWidth: PICKER_MAX_WIDTH,
    minWidth: PICKER_MIN_WIDTH,
    border: `1px solid #000`,
    borderColor: 'transparent',
    overflow: 'hidden',
    padding: theme.spacing(1),
  },
  invalidValue: {
    border: `1px solid #000`,
    borderRadius: 4,
    borderColor: theme.palette.error.main,
  },
  invalidColor: {
    color: theme.palette.error.main,
  },
  helpText: {
    margin: '4px 14px 0px',
    color: theme.palette.error.main,
  },
  iconLeft: {
    color: '#FDF6EC',
  },
  iconRight: {
    color: '#E83A14',
  },
  defaultCls: {
    color: '#019267',
    opacity: '1 !important',
  },
}));

function BetSettingPickerField(props) {
  const { baseBet, listItemDefault, defaultValueInit } = props;

  const classes = useClasses();
  const translate = useTranslate();

  const textRef = useRef({
    betSize: '',
    betLevel: '',
    totalBet: '',
    baseBet: '',
    defaultValue: '',
  });

  textRef.current = {
    betSize: translate('resources.bet-setting.fields.betSizes'),
    betLevel: translate('resources.bet-setting.fields.betLevels'),
    totalBet: translate('ra.field.totalBet'),
    baseBet: translate('ra.field.baseBet'),
    defaultValue: lowerCase(translate('wa.common.defaultValue')),
  };

  const [totalBetMap, setTotalBetMap] = useState({});
  const betSizeRef = useRef();
  const betLevelRef = useRef();

  const [touches, setTouches] = useState({
    betSize: false,
    betLevel: false,
    totalBet: false,
  });

  const [defaultValue, setDefaultValue] = useState(
    defaultValueInit || {
      betSize: null,
      betLevel: null,
      baseBet: null,
      totalBet: null,
    },
  );

  const [listItem, setListItem] = useState({
    betSize: [],
    betLevel: [],
    baseBet: [],
    totalBet: [],
  });

  const [selectedValue, setSelectedValue] = useState({
    betSize: null,
    betLevel: null,
    baseBet: null,
    totalBet: null,
  });

  const sortListItem = (list) => orderBy(list, ['value'], 'asc');

  const handleSetListItem = (name, newList) => {
    setListItem({
      ...listItem,
      [name]: sortListItem(newList),
    });

    if (name === 'betSize') {
      setTouches({
        ...touches,
        betSize: true,
        totalBet: true,
      });
    }
    if (name === 'betLevel') {
      setTouches({
        ...touches,
        betLevel: true,
        totalBet: true,
      });
    }
  };

  const handlePickerSelect = (pickerName, value) => {
    setSelectedValue({
      ...selectedValue,
      [pickerName]: value,
    });
  };

  const handleTotalBetSelected = (totalBet, action) => {
    // Current setting is correct!
    if (selectedValue.betSize * selectedValue.betLevel * selectedValue.baseBet === totalBet) {
      return;
    }

    // Wrong totalBet
    if (totalBetMap[totalBet] == null) {
      return;
    }

    if (action === 'scroll-end') {
      setSelectedValue({
        ...selectedValue,
        ...totalBetMap[totalBet],
      });
    } else {
      const totalBetReflecting = +(selectedValue.betSize * selectedValue.betLevel * selectedValue.baseBet)?.toFixed(2);
      setSelectedValue({
        ...selectedValue,
        totalBet: totalBetReflecting,
      });
    }
  };

  // reStructureBaseBetValue return the correct baseBet value
  // this returned value will adapt with picker and display it
  const reStructureBaseBetValue = (baseBet) => {
    switch (true) {
      // Base Bet is an array
      case Array.isArray(baseBet):
        return baseBet.map((item) => ({ value: item }));

      // Base Bet is a number
      case !isNaN(baseBet):
        return [{ value: baseBet }];

      default:
        return [];
    }
  };

  // Create listItem for Base Bet and display
  useEffect(() => {
    // Get correct baseBet
    const correctBaseBet = reStructureBaseBetValue(baseBet);

    // Update listItem with baseBet Value
    handleSetListItem('baseBet', correctBaseBet);
  }, [baseBet]);

  const referTotalBetAfterIndicatorChange = (changer) => {
    const itemsWithoutChanger = ['betSize', 'betLevel', 'baseBet'].filter((item) => item !== changer);

    const someItemInvalid =
      itemsWithoutChanger.some((item) => selectedValue[item] == null) || selectedValue[changer] == null;
    if (someItemInvalid) {
      return;
    }

    let totalBetItem = itemsWithoutChanger.reduce((acc, cur) => {
      return acc * Number(selectedValue[cur]);
    }, 1);

    // Multiple with changer
    totalBetItem *= Number(selectedValue[changer]);
    return getNumberOnlyTwoDigitsAfter(totalBetItem);
  };

  const handleDisplayTotalBet = (changer) => {
    if (listItem.totalBet.length === 0) {
      return;
    }

    const totalBet = referTotalBetAfterIndicatorChange(changer);
    if (
      totalBet in totalBetMap &&
      totalBet === selectedValue.betSize * selectedValue.betLevel * selectedValue.baseBet
    ) {
      setSelectedValue({
        ...selectedValue,
        totalBet,
      });
    } else {
      setSelectedValue({
        ...selectedValue,
        ...totalBetMap[totalBet],
        totalBet,
      });
    }
  };

  useEffect(() => {
    handleDisplayTotalBet('betSize');
  }, [selectedValue.betSize]);

  useEffect(() => {
    handleDisplayTotalBet('betLevel');
  }, [selectedValue.betLevel]);

  useEffect(() => {
    handleDisplayTotalBet('baseBet');
  }, [selectedValue.baseBet]);

  // Calculator Total Bets
  // totalBet = betSize * betLevel * baseBet
  useEffect(() => {
    const newTotalBetMap = {};
    const totalBetSet = new Set();

    listItem.betSize.forEach((bsItem) => {
      listItem.betLevel.forEach((blItem) => {
        listItem.baseBet.forEach((bbItem) => {
          const totalBetItem = +(bsItem.value * blItem.value * bbItem.value).toFixed(2);
          totalBetSet.add(totalBetItem);

          const key = getNumberOnlyTwoDigitsAfter(totalBetItem);
          newTotalBetMap[key] = {
            betSize: bsItem.value,
            betLevel: blItem.value,
            baseBet: bbItem.value,
          };
        });
      });
    });

    // Store totalBet value map into betSize & betLevel & baseBet
    // to reference by totalBet value
    setTotalBetMap(newTotalBetMap);

    const totalBetList = [...totalBetSet].map((item) => ({ value: item }));
    handleSetListItem('totalBet', totalBetList);
  }, [listItem.betSize, listItem.betLevel, listItem.baseBet]);

  const makeDefaultIconComp =
    ({ defaultValue }) =>
    (value) =>
      (
        <GradeIcon
          className={classNames(classes.iconLeft, {
            [classes.defaultCls]: defaultValue === value,
          })}
        />
      );

  useEffect(() => {
    if (
      isEmpty(defaultValueInit) ||
      !('betSize' in defaultValueInit) ||
      !('betLevel' in defaultValueInit) ||
      !('baseBet' in listItemDefault)
    ) {
      return;
    }

    const correctBaseBet = reStructureBaseBetValue(listItemDefault.baseBet);
    setListItem({
      ...listItem,
      ...listItemDefault,
      baseBet: correctBaseBet,
    });

    setDefaultValue({
      ...defaultValueInit,
    });

    setSelectedValue({
      ...selectedValue,
      betSize: defaultValueInit?.betSize,
      betLevel: defaultValueInit?.betLevel,
      baseBet: correctBaseBet?.[0]?.value,
    });
  }, [defaultValueInit, listItemDefault]);

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.pickerControl}>
          <Box ref={betSizeRef} className={classes.pickerColumnWrapper}>
            <PickerColumn
              name="betSize"
              title={textRef.current.betSize}
              width={PICKER_MAX_WIDTH}
              listItem={listItem.betSize}
              selectedValue={selectedValue.betSize}
              onSelected={handlePickerSelect.bind(null, 'betSize')}
              generateIconLeft={makeDefaultIconComp({ defaultValue: defaultValue.betSize })}
              iconLeftForceDisplay={[defaultValue.betSize]}
              iconLeftDisplayWhenHover={false}
            />
          </Box>

          <Box className={classes.divideChar}>x</Box>

          <Box ref={betLevelRef} className={classes.pickerColumnWrapper}>
            <PickerColumn
              name="betLevel"
              title={textRef.current.betLevel}
              width={PICKER_MAX_WIDTH}
              listItem={listItem.betLevel}
              selectedValue={selectedValue.betLevel}
              onSelected={handlePickerSelect.bind(null, 'betLevel')}
              generateIconLeft={makeDefaultIconComp({ defaultValue: defaultValue.betLevel })}
              iconLeftForceDisplay={[defaultValue.betLevel]}
              iconLeftDisplayWhenHover={false}
            />
          </Box>

          <Box className={classes.divideChar}>x</Box>

          <Box className={classes.pickerColumnWrapper}>
            <PickerColumn
              name="baseBet"
              title={textRef.current.baseBet}
              width={PICKER_MAX_WIDTH}
              listItem={listItem.baseBet}
              selectedValue={selectedValue.baseBet}
              onSelected={handlePickerSelect.bind(null, 'baseBet')}
            />
          </Box>

          <Box className={classes.divideChar}>=</Box>

          <Box className={classes.pickerColumnWrapper}>
            <PickerColumn
              name="totalBet"
              title={textRef.current.totalBet}
              width={PICKER_MAX_WIDTH}
              listItem={listItem.totalBet}
              selectedValue={selectedValue.totalBet}
              onSelected={handleTotalBetSelected}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BetSettingPickerField;
