/* eslint-disable  */
import { FormDataConsumer, useTranslate } from "ra-core";
import { ReferenceInput, SimpleForm } from "react-admin";
import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  AutocompleteInput,
  BooleanInput,
} from "../../base/components/ra/inputs";
import { Create } from "../../base/components/ra/views";
import { RbacAction } from "../../services/provider/rbacProvider";
import { useSelector } from "react-redux";
import useAllowedResources from "../../base/hooks/useAllowedResources";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginTop: "-25px",
    fontSize: "12px",
  },
}));

const PermCreate = (props) => {
  const { permissions } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const allowedResources = useAllowedResources();

  const renderCheckBoxPerm = (resourceName) =>
    Object.values(RbacAction).map((action) => {
      const enabled = allowedResources?.[resourceName]?.[action];

      return (
        <React.Fragment key={action}>
          <BooleanInput fullWidth source={action} disabled={!enabled} />
          {!enabled && (
            <div className={classes.error}>
              {translate("wa.exception.invalidPermission", {
                resource: resourceName,
                action,
              })}
            </div>
          )}
        </React.Fragment>
      );
    });

  const transform = (data) => {
    const { resourceName, ...rest } = data;

    return rest;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm redirect="list">
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <ReferenceInput
                fullWidth
                label="resources.perm.fields.role"
                source="role.id"
                reference="role"
                filterToQuery={(value) => ({
                  name: value || "",
                })}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <ReferenceInput
                fullWidth
                label="resources.perm.fields.resource"
                source="resource.id"
                reference="resource"
                filterToQuery={(value) => ({
                  name: value || "",
                })}
              >
                <AutocompleteInput
                  optionText="name"
                  onSelect={(e) => {
                    formData.resourceName = e.name;
                    Object.values(RbacAction).forEach((source) => {
                      formData[source] = false;
                    });
                  }}
                />
              </ReferenceInput>
              {formData.resource && renderCheckBoxPerm(formData.resourceName)}
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default PermCreate;
