import React from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import FormField from '../../../../base/components/reports/ReportFilterWrapper/FormField';
import { DateQuickRange } from '../../../../base/components/ra/inputs';

const SOURCE = 'dateQuickRange';

const QuickTimeRange = () => {
  const translate = useTranslate();
  const form = useForm();

  const choices = [
    {
      id: 'today',
      name: translate('resources.report.fields.today'),
    },
    {
      id: 'yesterday',
      name: translate('resources.report.fields.yesterday'),
    },
    {
      id: 'thisWeek',
      name: translate('resources.report.fields.thisWeek'),
    },
    {
      id: 'thisMonth',
      name: translate('resources.report.fields.thisMonth'),
    },
    {
      id: 'lastWeek',
      name: translate('resources.report.fields.lastWeek'),
    },
    {
      id: 'lastMonth',
      name: translate('resources.report.fields.lastMonth'),
    },
  ];

  return (
    <>
      <FormField name={SOURCE}>
        {() => (
          <DateQuickRange
            className="margin-custom-search-panel"
            label={translate('resources.report.fields.quickRange')}
            choices={choices}
            form={form}
          />
        )}
      </FormField>
    </>
  );
};

QuickTimeRange.propTypes = {};

QuickTimeRange.defaultProps = {};

export default QuickTimeRange;
