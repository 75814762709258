/* eslint-disable react/prop-types, no-shadow */
import * as React from 'react';
import { Box, Link } from '@material-ui/core';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import resourceSlug from '../../constant/resource-slug';
import { DateField } from '../../base/components/ra/fields';

const TransactionSeamlessList = props => (
  <WealthListGuesser
    excludeFields={['player', 'bet', 'created']}
    fieldOrder={[
      'id',
      'created',
      'type',
      'status',
      'group.id',
      'brand.id',
      'game.id',
      'player',
      'bet',
      'currency.id',
      'payload',
      'requestHeader',
      'responseHeader',
      'res',
    ]}
    {...props}
  >
    <DateField
      {...props}
      source="created"
    />
    <CustomLinkField
      source="player"
      href={record => `/#/${resourceSlug.PLAYER}/${record.player?.id}/show`}
      title={record => (record.player?.id ? transformIdValue(record.player?.id) : '')}
      component={record => (record.player?.id ? Link : Box)}
      sortable={false}
    />
    <CustomLinkField
      source="bet"
      href={record => `/#/${resourceSlug.BET}/${record.bet?.id}/show`}
      title={record => (record.bet?.id ? transformIdValue(record.bet?.id) : '')}
      component={record => (record.bet?.id ? Link : Box)}
      sortable={false}
    />
  </WealthListGuesser>
);

export default TransactionSeamlessList;
