import { SYSTEM_ADMIN_LEVEL } from '../../constant';
import { RoleAccessibility } from '../util';

export const RbacAction = {
  CREATE: 'create',
  READ: 'read',
  LIST: 'list',
  UPDATE: 'update',
  DELETE: 'delete',
  EXPORT: 'export',
};

export const RbacActionWithoutExport = {
  CREATE: 'create',
  READ: 'read',
  LIST: 'list',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const canI = (action, name, myPerms) => myPerms?.superman || (myPerms?.length > 0 && myPerms?.some(p => p.resource.name === name && p[action]));

// Check edit able with addition case
export const canIEditWith = (resource, options) => {
  const {
    defaultCondition, record, user,
  } = options;

  const userRoleLevel = user?.role?.level;

  const resourceNameOfPerm = record?.resource?.name || null;

  // Nobody can edit lv20
  if (resource === 'role') {
    const roleLevel = record?.level;
    if (roleLevel === SYSTEM_ADMIN_LEVEL) {
      return false;
    }

    if (userRoleLevel === roleLevel || user?.role?.id === record?.id) {
      return false;
    }

    if (
      user?.role?.accessibility === RoleAccessibility.CUSTOM_ROLE
      && record?.accessibility === RoleAccessibility.ALL_GROUP
    ) {
      return false;
    }

    // if (
    //   userRoleLevel !== 20
    //   && record?.accessibility === 'CUSTOM_ROLE'
    //   && record?.group?.id !== user.group?.id
    //   && user?.role?.type === 'GROUP'
    // ) {
    //   return false;
    // }

    return true;
  }

  if (
    record?.role?.level === SYSTEM_ADMIN_LEVEL
    && userRoleLevel === SYSTEM_ADMIN_LEVEL
    && resource === 'perm'
    && resourceNameOfPerm === 'perm'
  ) {
    return false;
  }

  if (['perm', 'user'].includes(resource) && record?.role?.level >= userRoleLevel && userRoleLevel !== 20) {
    return false;
  }

  // Nobody can edit permission with resource name is 'auth'
  if (resource === 'perm') {
    if (resourceNameOfPerm === 'auth') {
      return false;
    }

    return true;
  }

  return defaultCondition;
};
