import React, { useState } from 'react';
import AccessTime from '@material-ui/icons/AccessTime';
import Axios from 'axios';
import Type from 'prop-types';
import { useNotify, useTranslate } from 'ra-core';
import { SmallButton } from '../../../base/components/ra/buttons';
import ToolbarMenu from '../../../base/components/action-menu/toolbar-menu';
import Confirm from '../../../base/components/ra/layout/Confirm';

export const ActionMenu = props => {
  const { record } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const triggerEvent = () => {
    Axios.post(`api/cron/${record?.id}/trigger`)
      .then(res => {
        if (res.status === 201) {
          notify('Successfully', 'success');
          setConfirmSubmit(false);
        }
      })
      .catch(() => notify('Trigger failure', 'error'));
  };

  return (
    <ToolbarMenu {...props}>
      <SmallButton
        label="ra.action.trigger"
        onClick={() => setConfirmSubmit(true)}
        startIcon={<AccessTime />}
      />
      <Confirm
        isOpen={confirmSubmit}
        title={translate('ra.action.trigger')}
        content={translate('ra.message.confirmTriggerCron')}
        onConfirm={triggerEvent}
        onClose={() => setConfirmSubmit(false)}
      />
    </ToolbarMenu>
  );
};

ActionMenu.propTypes = {
  record: Type.object,
};

ActionMenu.defaultProps = {
  record: {},
};
