/* eslint-disable  */
import React, { useRef, useEffect, useState } from "react";
import {
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  useDataProvider,
  required,
  useTranslate,
} from "react-admin";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "../../base/components/ra/inputs";
import { useSelector } from "react-redux";
import Create from "../../base/components/ra/views/create";
import MUIInput from "@material-ui/core/TextField";
import useSchema from "../../base/hooks/useSchema";
import snakeCase from "lodash/snakeCase";
import kebabCase from "lodash/kebabCase";
import isEmpty from "lodash/isEmpty";

const RecordFieldTranslationCreate = (props) => {
  const { resource } = props;
  const { api, ref } = useSchema();
  const [error, setError] = useState();
  const dataProvider = useDataProvider();
  const [refForm, setRefForm] = useState({});
  const [valueEnums, setValueEnums] = useState({});
  const translate = useTranslate();

  const getFieldTranslatAble = (basePath) => {
    let writableFields = [];
    let enums = {};
    setError(false);
    try {
      if (api) {
        const { paths } = api;

        let requestRef =
          paths?.[basePath]?.post?.requestBody?.content?.["application/json"]
            ?.schema ||
          paths?.[basePath]?.get?.responses?.["200"]?.content?.[
            "application/json"
          ]?.schema;

        requestRef =
          requestRef?.$ref ||
          requestRef?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
          requestRef?.oneOf?.filter((i) => i?.items?.$ref)?.[0]?.items?.$ref;

        const requestComponent = ref.get(requestRef);

        const { properties, required } = requestComponent;

        writableFields = Object.keys(properties)?.filter((key) => {
          const sourceSchema = properties[key] || {};
          const fieldRef =
            key === "docStatus" ||
            ["date", "datetime-local", "date-time"].includes(
              sourceSchema?.format
            ) ||
            ["boolean", "number"].includes(sourceSchema?.type) ||
            sourceSchema?.$ref ||
            sourceSchema?.allOf ||
            sourceSchema?.oneOf ||
            sourceSchema?.items ||
            sourceSchema?.properties?.reference?.$ref;

          return !fieldRef;
        });

        writableFields.forEach((key) => {
          if (properties[key].enum) {
            enums[key] = properties[key].enum;
          }
        });
      }
    } catch (e) {
      setError(true);
    }

    return [writableFields, enums];
  };

  const handleGetResourceField = async (id, includeFields) => {
    const { data = [] } = await dataProvider.getAll("resource-field", {
      filter: {
        "resource.id": id,
      },
    });

    return data.filter((i) => includeFields.includes(i.name));
  };

  return (
    <Create {...props} disabledBtnSave={error}>
      <SimpleForm redirect="list">
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <ReferenceInput
                  label={`resources.${resource}.fields.resource`}
                  fullWidth
                  source="resource"
                  reference="resource"
                  allowEmpty
                  filterToQuery={(value) => ({
                    name: value || "",
                  })}
                >
                  <AutocompleteInput
                    optionText="name"
                    onSelect={async (e) => {
                      const [field, enums] = getFieldTranslatAble(`/${e.name}`);
                      const fieldTranslatAble = await handleGetResourceField(
                        e.id,
                        field
                      );

                      setRefForm({
                        ...refForm,
                        fieldTranslatAble: fieldTranslatAble,
                        resourceName: e.name,
                        resourceField: "",
                        record: "",
                        enums,
                      });

                      formData.resourceField = null;
                      formData.record = null;
                    }}
                  />
                </ReferenceInput>
                {error && (
                  <div
                    style={{
                      marginTop: "-20px",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    This resource doesn't support translation
                  </div>
                )}

                {formData.resource && (
                  <AutocompleteInput
                    label={`resources.${resource}.fields.resourceField`}
                    fullWidth
                    source="resourceField"
                    choices={refForm.fieldTranslatAble}
                    onSelect={(resourceField) => {
                      const { resourceName, record } = refForm;
                      setRefForm({
                        ...refForm,
                        resourceField: resourceField.name,
                      });

                      if (refForm.enums?.[resourceField.name]) {
                        const stateEnum = {};

                        refForm.enums[resourceField.name].map((i) => {
                          stateEnum[i] = i;
                        });

                        const enumsValue = Object.keys(stateEnum).map(
                          (key) => ({
                            key,
                            value: stateEnum[key],
                          })
                        );

                        formData.enumsValue = enumsValue;
                        setValueEnums(stateEnum);
                      } else {
                        setValueEnums({});
                        formData.enumsValue = [];
                      }

                      if (record) {
                        formData.translation = record[resourceField.name];
                        formData.translationKey = `resources.${resourceName}.translate.${record.id}.${resourceField.name}`;
                      }
                    }}
                  />
                )}

                {formData.resourceField &&
                  (!refForm.enums?.[refForm?.resourceField] ? (
                    <ReferenceInput
                      label={`resources.${resource}.fields.record`}
                      fullWidth
                      source="record"
                      reference={refForm.resourceName}
                    >
                      <AutocompleteInput
                        optionText={(props) => props?.name || props?.id}
                        onSelect={(record) => {
                          const { resourceField, resourceName } = refForm;
                          setRefForm({
                            ...refForm,
                            record,
                          });

                          formData.translation = record[resourceField];
                          formData.translationKey = `resources.${resourceName}.translate.${record.id}.${resourceField}`;
                        }}
                      />
                    </ReferenceInput>
                  ) : (
                    <div style={{ marginTop: "-20px" }}>
                      <div>Type: ENUM</div>
                      {refForm?.enums?.[refForm.resourceField].map((i) => {
                        return (
                          <div
                            key={i}
                            className="flex"
                            style={{
                              overflow: "scroll",
                              padding: "8px 0",
                            }}
                          >
                            <MUIInput variant="outlined" value={i} disabled />
                            <MUIInput
                              onChange={(e) => {
                                const stateEnum = {
                                  ...valueEnums,
                                  [i]: e.target.value,
                                };
                                setValueEnums(stateEnum);

                                const enumsValue = Object.keys(stateEnum).map(
                                  (key) => ({
                                    key,
                                    value: stateEnum[key],
                                  })
                                );

                                formData.enumsValue = enumsValue;
                              }}
                              value={valueEnums[i]}
                              variant="outlined"
                              label={translate(
                                `resources.${resource}.fields.translation`
                              )}
                              style={{ marginLeft: "8px" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ))}

                {formData.record && (
                  <>
                    <TextInput
                      label={`resources.${resource}.fields.translation`}
                      source="translation"
                      fullWidth
                    />
                    <TextInput
                      label={`resources.${resource}.fields.translationKey`}
                      source="translationKey"
                      fullWidth
                      disabled
                    />
                  </>
                )}

                <ReferenceInput
                  label={`resources.${resource}.fields.translation`}
                  fullWidth
                  source="language"
                  reference="language"
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default RecordFieldTranslationCreate;
