/* eslint-disable */
import { useSelector } from 'react-redux';
import React, { useContext, useMemo, useRef } from 'react';
import { Icon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewListIcon from '@material-ui/icons/ViewList';
import AssistantIcon from '@material-ui/icons/Assistant';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { without, kebabCase, camelCase } from 'lodash';
import { getResources, useLocale, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CallMerge } from '@material-ui/icons';
import useAuthUser from './useAuthUser';
import { canI, RbacAction } from '../../services/provider/rbacProvider';
import { customRoutes } from '../../routes/customRoutes';
import { checkSystemAdmin, getBackofficeEnv, isPreStagingEnv } from '../../services/util';
import { RESOURCE_GROUP, UUID_REGEX } from '../../constant';
import resourceSlug from '../../constant/resource-slug';
import { EnumViewType } from './useViewType';
import { NavigationContext } from '../../App';

const SYSTEM_SETUP_SUB_TAB_ORDER = [
  'reverse-integration',
  'group',
  'brand',
  'wallet-integration',
  'regulation',
  'game-feature',
  'language',
  'translation',
  'inbox-history',
  'cron',
  'cron-log',
  'queue-log',
  'resource',
  'setting',
  'white-list',
];

export const getViewType = (pathname) => {
  const paths = without(pathname?.split('/') || [], '');
  const lastRouteSuffix = paths[paths.length - 1];

  if (lastRouteSuffix === 'create') {
    return EnumViewType.CREATE;
  } else if (lastRouteSuffix?.match(UUID_REGEX)) {
    return EnumViewType.EDIT;
  } else if (lastRouteSuffix === 'show') {
    return EnumViewType.SHOW;
  } else {
    return EnumViewType.OTHER;
  }
};

const getRouteInfo = (name, type, icon, children, link, index, url) => ({
  name,
  type,
  icon,
  children,
  link,
  index,
  url: url || link?.replace(/^\//g, ''),
});

const useNavigation = (options = {}) => {
  const isInPreStaging = isPreStagingEnv();
  const navigationData = useContext(NavigationContext);
  const { pageTitleInfo: customizedPageTitle, breadcrumbs: customizedBreadcrumbs } = navigationData || {};
  const { removeHiddenOptions } = options;
  const resources = useSelector(getResources);
  const translate = useTranslate();
  const locale = useLocale();
  const { pathname } = useLocation();
  const specialRoutes = customRoutes.map((route) => route.props.path.slice(-(route.props.path.length - 1)));
  const hideOnMenuResourcesRef = useRef([]);

  const user = useAuthUser();
  const isSystemAdmin = checkSystemAdmin(user);
  const { APP_ENV } = getBackofficeEnv();

  const groupResources = useMemo(
    () =>
      resources?.reduce((acc, r) => {
        let currentResource = r;
        // Store a resource's name when it's hideOnMenu
        // Will check the children item to hidden on menu
        const { hideOnMenu } = r.options?.reportInterface || {};
        if (hideOnMenu) {
          hideOnMenuResourcesRef.current.push(r.name);
        }

        // Get resource group
        const valid = r.options?.isGroup === true && r.options.children?.length > 0;

        // Don't show the others item
        if (r.name !== 'others' && valid) {
          // Remove resources which have hideOnMenu prop
          currentResource.options.children = currentResource.options.children.filter((item) => {
            // TODO : Remove when finish testing on pre-staging
            const isPlayerStateManagement = item.name === resourceSlug.PLAYER_STATE_MANAGEMENT;
            if (!isPlayerStateManagement || (isPlayerStateManagement && isInPreStaging)) {
              return !item.hideOnMenu;
            } else {
              return false;
            }
            // return !item.hideOnMenu;
          });
          acc.push(currentResource);
        }

        return acc;
      }, []),
    [resources],
  );

  const nonGroupResources = useMemo(
    () =>
      resources?.filter((r) => {
        const valid = r.options?.isGroup === false && !r.options?.isChild && r.hasList;
        return valid;
      }) || [],
    [resources],
  );

  const mainNavItem = {
    name: translate('ra.page.home'),
    type: 'collapse',
    icon: <DashboardIcon />,
    children: [
      {
        name: translate('ra.page.dashboard'),
        type: 'item',
        link: '/#',
        icon: <DashboardIcon />,
      },
    ],
  };

  const displayQuickSetup = isSystemAdmin && APP_ENV === 'pre-staging';
  const quickSetupNavGroup = displayQuickSetup
    ? [
        {
          name: translate('ra.action.quickSetup'),
          type: 'collapse',
          icon: <AssistantIcon />,
          children: [
            {
              name: translate('ra.action.newGroup'),
              type: 'item',
              icon: <SupervisedUserCircleIcon />,
              link: '/quick-setup-new-group',
            },
            {
              name: translate('ra.action.newBrand'),
              type: 'item',
              icon: <SupervisedUserCircleIcon />,
              link: '/quick-setup-new-brand',
            },
          ],
        },
      ]
    : [];

  const customGroupRoutes = useMemo(() => {
    // TODO1: Need to update to grant role permission after API /auth/me is updated
    const customReverseIntegrationGroupRoute = !isSystemAdmin
      ? []
      : [
          {
            parentName: RESOURCE_GROUP.SYSTEM_SETUP.name,
            ...getRouteInfo(
              translate('resources.reverse-integration.name'),
              'item',
              <CallMerge />,
              [],
              '/reverse-integration',
              -1,
            ),
          },
        ];

    return [...customReverseIntegrationGroupRoute];
  }, [locale, isSystemAdmin]);

  const getIcon = (icon) => {
    if (!icon) return <ViewListIcon />;
    if (typeof icon !== 'string') {
      return <Icon>{React.createElement(icon)}</Icon>;
    }
    return <Icon>{icon}</Icon>;
  };

  const mapChildNavItem = (c) => {
    const realResource = c.name.includes('/') ? `${c.name.split('/')[1]}` : kebabCase(c.name);

    return {
      name: translate(`resources.${realResource}.name`),
      type: 'item',
      link: `/${c.name}`,
      icon: getIcon(c.icon),
      url: realResource,
      index: c.index,
    };
  };

  const perms = user?.superman
    ? {
        superman: user.superman,
      }
    : user?.perms?.filter((p) => p.create || p.list || p.read || p.update || p.delete);

  const checkChildrenHideOnMenu = (childrenItem) => {
    const isHideOnMenu = hideOnMenuResourcesRef.current.includes(childrenItem.name);
    return isHideOnMenu;
  };

  const checkListPerm = (childrenItem) => {
    const realResource = childrenItem.name.includes('/')
      ? `${childrenItem.name.split('/')[1]}`
      : kebabCase(childrenItem.name);

    return childrenItem.list && canI(RbacAction.LIST, realResource, perms);
  };

  const mapParentNavItem = (g) => {
    const children = [...(g.options?.children || [])].filter((r) => {
      const isHideOnMenu = checkChildrenHideOnMenu(r);
      const canList = checkListPerm(r);

      if (removeHiddenOptions) {
        return canList && !isHideOnMenu;
      } else {
        return canList;
      }
    });

    return {
      name: translate(`resources.group.${kebabCase(g.name)}.name`),
      type: 'collapse',
      icon: getIcon(g.icon),
      children: children?.map((c) => mapChildNavItem(c))?.sort((a, b) => a.index - b.index),
      url: g.name,
    };
  };

  const navigationMenus = useMemo(() => {
    const initialGroups = [mainNavItem]
      .concat(
        groupResources
          .map((g) => mapParentNavItem(g))
          .concat(nonGroupResources.map((c) => mapChildNavItem(c))?.sort((a, b) => a.index - b.index)),
      )
      .filter((menu) => menu.children?.length)
      .concat(quickSetupNavGroup);

    /* eslint-disable no-param-reassign */
    // To merge custom routes into routes fetched from the server
    const totalGroup = customGroupRoutes.reduce((currentGroups, customRoute) => {
      const { parentName, ...otherInfo } = customRoute;

      const parentNameIdx = currentGroups.findIndex((group) => group.url === parentName);
      if (parentNameIdx >= 0) {
        currentGroups[parentNameIdx].children.push(otherInfo);
        currentGroups[parentNameIdx].children.sort((a, b) =>
          parentName === 'system-setup'
            ? SYSTEM_SETUP_SUB_TAB_ORDER.findIndex((item) => item === a.url) -
              SYSTEM_SETUP_SUB_TAB_ORDER.findIndex((item) => item === b.url)
            : a.index - b.index,
        );
      } else {
        currentGroups.push(otherInfo);
      }

      return currentGroups;
    }, initialGroups);

    return totalGroup;
  }, [mainNavItem, groupResources, nonGroupResources, customGroupRoutes]);

  const pageInfo = useMemo(() => {
    let currentPage;
    const results = [];
    const paths = without(pathname?.split('/') || [], '');
    let pageTitleInfo = {};

    // home
    currentPage = {
      label: translate('ra.page.dashboard'),
      link: '/',
    };
    results.push({
      ...currentPage,
      label: translate('ra.page.home'),
    });

    // resource
    if (paths?.length) {
      if (paths.length === 1 && specialRoutes.includes(paths[0])) {
        const currentPageLabel = translate(`ra.page.${camelCase(paths[0])}`);
        currentPage = {
          label: currentPageLabel,
          link: `/${paths[0]}`,
        };
        pageTitleInfo = { label: currentPageLabel };
        results.push(currentPage);
      } else {
        const isInPathname = (url, matchedPath) => url === matchedPath;

        const traverseUntilMatchedPath = (obj, matchedPath) => {
          if (isInPathname(obj.url, matchedPath)) {
            return obj;
          }

          if (Array.isArray(obj.children) && obj.children.length > 0) {
            return obj.children.find((subObj) => traverseUntilMatchedPath(subObj, matchedPath));
          }
        };

        const updateMenu = (array, matchedPath) => {
          let currentMenuGroup = array.find((item) => traverseUntilMatchedPath(item, matchedPath));
          if (currentMenuGroup) {
            results.push({ label: currentMenuGroup.name, link: currentMenuGroup.link });
            if (Array.isArray(currentMenuGroup.children) && !isInPathname(currentMenuGroup.url, matchedPath)) {
              updateMenu(currentMenuGroup.children, matchedPath);
            }
          }
        };

        const viewType = getViewType(pathname);

        if (viewType === EnumViewType.CREATE) {
          updateMenu(navigationMenus, pathname.replace(/^\//g, '').replace('/create', ''));

          pageTitleInfo = { label: results[results.length - 1].label };

          // Default RA Create page
          results.push({
            label: translate('ra.page.create'),
          }); // create
        } else if (viewType === EnumViewType.SHOW || viewType === EnumViewType.EDIT) {
          updateMenu(
            navigationMenus,
            pathname
              .replace(/^\//g, '')
              .replace(/\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/, '')
              .replace('/show', ''),
          );

          pageTitleInfo = { label: results[results.length - 1].label };

          // Default RA Edit page || RA Show page
          const id = paths.find((path) => path.match(UUID_REGEX));
          results.push({
            label: `#${id.slice(0, 4)}`, // To get first four digits of id
          });
        } else {
          updateMenu(navigationMenus, pathname.replace(/^\//g, ''));
          pageTitleInfo = { label: results[results.length - 1].label };
        }
      }
    }

    // set active for latest item
    if (results?.length) {
      results[results.length - 1].isActive = true;
    }

    return {
      breadcrumbs: customizedBreadcrumbs || results,
      pageTitleInfo: customizedPageTitle || pageTitleInfo,
    };
  }, [resources, navigationMenus, pathname, customizedBreadcrumbs, customizedPageTitle]);

  return {
    navigationMenus,
    ...pageInfo,
  };
};

export default useNavigation;
