import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../services/provider/authProvider';
import { setAuthInfo } from '../../services/redux/auth/auth.action';

const useAuthUser = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth?.user);

  useEffect(() => {
    (async () => {
      const currentUser = await fetchUser();

      // Checking new user data & update state
      const theSameOldUserData = currentUser?.id === user?.id;
      if (currentUser && !theSameOldUserData) {
        dispatch(setAuthInfo(currentUser));
      }
    })();
  }, []);

  return (
    isEmpty(user)
      ? {
        displayName: '',
      }
      : {
        ...user,
        displayName: `${user.firstName} ${user.lastName}`,
      }
  );
};

export default useAuthUser;
