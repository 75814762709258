import React from 'react';
import {
  Box,
  Card,
  CardContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const SettingLabel = styled(Box)`
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
`;

export const SettingWrapperCard = styled(Card)`
  & {
    margin-top: 24px;
    border: 1px solid #e4e2e2;
    border-radius: 4px;
    overflow: visible;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

export const SettingWrapper = ({ children }) => (
  <SettingWrapperCard>
    <CardContent
      style={{
        overflow: 'visible',
      }}
    >
      {children}
    </CardContent>
  </SettingWrapperCard>
);

SettingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
