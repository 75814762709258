import React from 'react';
import { Box, Link } from '@material-ui/core';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import ActionList from './components/action.list';
import FailedRetryFilter from './components/FailedRetryFilter';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import resourceSlug from '../../constant/resource-slug';
import { transformIdValue } from '../../services/util/stringUtil';

const SeamlessLogList = props => (
  <WealthListGuesser
    {...props}
    excludeFields={['betId', 'playerId']}
    actions={<ActionList />}
    extraFilterInputs={[<FailedRetryFilter />]}
    fieldOrder={[
      'id',
      'created',
      'updated',
      'groupId',
      'brandId',
      'payload',
      'requestHeaders',
      'type',
      'betId',
      'playerId',
      'gameId',
      'status',
      'response',
      'responseHeaders',
      'failedRetry',
    ]}
  >
    <CustomLinkField
      source="betId"
      href={record => `/#/${resourceSlug.BET}/${record.betId}/show`}
      title={record => (record.betId ? transformIdValue(record.betId) : '')}
      component={record => (record.betId ? Link : Box)}
      sortable={false}
    />
    <CustomLinkField
      source="playerId"
      href={record => `/#/${resourceSlug.PLAYER}/${record.playerId}/show`}
      title={record => (record.playerId ? transformIdValue(record.playerId) : '')}
      component={record => (record.playerId ? Link : Box)}
      sortable={false}
    />
  </WealthListGuesser>
);

export default SeamlessLogList;
