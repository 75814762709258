/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslate } from 'react-admin';
import { BooleanInput } from '../../../base/components/ra/inputs';

function PrepaidEnabled(props) {
  const translate = useTranslate();
  return (
    <BooleanInput
      label={translate('resources.game.fields.prepaidEnabled')}
      {...props}
    />
  );
}

export default PrepaidEnabled;
