/* eslint-disable */
import React, { useMemo } from "react";
import { useShowController } from "react-admin";
import { isEmpty, get } from "lodash";

import WealthShowGuesser from "../../base/components/guesser/wealth-show.guesser";
import BetSettingPicker from "../../base/components/rmc-picker-custom/bet-setting-picker.field";

const BetSettingShow = (props) => {
  const { record } = useShowController(props);
  const baseBet = get(record, "game.baseBet", null);

  const { settingDefaultValue, settingValue } = useMemo(() => {
    if (isEmpty(record)) {
      return {};
    }

    const settingValue = {
      betSize: [],
      betLevel: [],
    };

    const settingDefaultValue = {
      betSize: null,
      betLevel: null,
    };

    // Update betSize list & betSize default value
    record.betSizes?.forEach((item) => {
      settingValue.betSize.push({ value: item.value });
      if (item.default) {
        settingDefaultValue.betSize = item.value;
      }
    });

    // Update betLevel list & betLevel default value
    record.betLevels?.forEach((item) => {
      settingValue.betLevel.push({ value: item.value });
      if (item.default) {
        settingDefaultValue.betLevel = item.value;
      }
    });

    return { settingDefaultValue, settingValue };
  }, [record]);

  return (
    <WealthShowGuesser {...props}>
      <BetSettingPicker
        listItemDefault={{
          betSize: settingValue?.betSize || [],
          betLevel: settingValue?.betLevel || [],
          baseBet,
        }}
        defaultValueInit={{
          betSize: settingDefaultValue?.betSize || null,
          betLevel: settingDefaultValue?.betLevel || null,
        }}
      />
    </WealthShowGuesser>
  );
};

export default BetSettingShow;
