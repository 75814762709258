/* eslint-disable react/prop-types */
import RepeatIcon from '@material-ui/icons/Repeat';
import Axios from 'axios';
import { sanitizeListRestProps, useNotify, useRefresh } from 'ra-core';
import { TopToolbar } from 'ra-ui-materialui';
import React, { useState } from 'react';
import ButtonFilter from '../../../base/components/guesser/actions/button-filter.action';
import Confirm from '../../../base/components/ra/layout/Confirm';
import { SmallButton } from '../../../base/components/ra/buttons';

const ActionList = props => {
  const {
    resource, total, ...rest
  } = props;

  const [show, setShow] = useState();

  const notify = useNotify();
  const refresh = useRefresh();

  const revertEvent = () => {
    Axios.post(`api/${resource}/retry-all`)
      .then(res => {
        if (res.status === 201) {
          notify('ra.message.successfully', 'success', {
            smart_name: res.data.count,
          });
          setShow(false);
          refresh();
        }
      })
      .catch(() => {
        notify('ra.notification.retryFailure', 'error');
      });
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <ButtonFilter />
      {total !== 0 && (
        <>
          <SmallButton
            label="ra.action.retryAll"
            onClick={() => setShow(true)}
            startIcon={<RepeatIcon />}
          />
          <Confirm
            isOpen={show}
            title="ra.action.retryAll"
            content="ra.message.confirmRetryAll"
            confirmColor="primary"
            onConfirm={revertEvent}
            onClose={() => setShow(false)}
          />
        </>
      )}
    </TopToolbar>
  );
};

ActionList.propTypes = {};

export default ActionList;
