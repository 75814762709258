/* eslint-disable */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";
import get from "lodash/get";
import DatagridContainer from "../../../../base/components/guesser/report/dataGridContainer";
import WealthListGuesser from "../../../../base/components/guesser/wealth-list.guesser";
import {
  AmountField,
  ChipField,
  NumberField,
} from "../../../../base/components/ra/fields";
import ActionMenu from "./streamer-admin.action";
import ActionList from "./streamer-admin.actionList";

const WrapperField = (props) => {
  const { record, source } = props;

  if (source === "player") {
    return (
      <ChipField {...props} clickable={false} source="players[0].nativeId" />
    );
  }

  return (
    <TextField
      {...props}
      record={{
        ...record,
        currency: get(record, "players[0].wallet.currency"),
      }}
      source="players[0].wallet.balance"
    />
  );
};

const StreamerAdminTable = memo((propsI) => {
  const { children, ...props } = propsI;
  const propsCheatModeLink = {
    basePath: "/cheat-mode-link",
    permissions: props.permissions,
    resource: "cheat-mode-link",
    options: {},
    hasList: true,
    hasEdit: false,
    hasShow: false,
    hasCreate: false,
    // actions: false,
    aside: null,
    bulkActionButtons: false,

    hideAction: false,
    excludeFields: [],
    excludeFilterInputs: [],
    includeFields: [],
    listType: "default",
    optimized: false,
    syncWithLocation: false,
    sort: {
      field: "created",
      order: "DESC",
    },
  };

  return (
    <div
      style={{
        marginTop: "20px",
      }}
    >
      <WealthListGuesser
        {...props}
        {...propsCheatModeLink}
        excludeFields={[
          "id",
          "updated",
          "recordId",
          "groupId",
          "brandId",
          "user",
          "players",
        ]}
        extraActions={
          <ActionMenu
            label="ra.field.action"
            propsController={propsCheatModeLink}
          />
        }
        actions={<ActionList {...props} propsController={propsCheatModeLink} />}
        isCustomListView
      >
        <ReferenceField
          reference="group"
          source="groupId"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="brand"
          source="brandId"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="user"
          label="resources.user.name"
          source="user.id"
          link={false}
          sortable={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <WrapperField source="player" sortable={false} />
        <WrapperField source="balance" sortable={false} />
      </WealthListGuesser>
    </div>
  );
});

StreamerAdminTable.propTypes = {};

export default StreamerAdminTable;
