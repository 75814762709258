export const REVERSE_INTEGRATION_OPERATORS = {
  VERTBET: {
    id: 'vertbet',
    name: 'Vertbet',
    mainResource: '/vertbet/partner',
  },
  QTECH: {
    id: 'qtech',
    name: 'QTech',
    mainResource: '/qtech/operator',
  },
};

export const CUSTOM_ROUTES = {
  QUICK_SETUP_NEW_GROUP: '/quick-setup-new-group',
  QUICK_SETUP_NEW_BRAND: '/quick-setup-new-brand',
  PROFILE: '/profile',
  VERTBET_PARTNER: `/${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/partner`,
  VERTBET_PARTNER_INTEGRATION: `/${REVERSE_INTEGRATION_OPERATORS.VERTBET.id}/vertbet-wallet-integration`,
  QTECH_OPERATOR: `/${REVERSE_INTEGRATION_OPERATORS.QTECH.id}/operator`,
  QTECH_OPERATOR_INTEGRATION: `/${REVERSE_INTEGRATION_OPERATORS.QTECH.id}/wallet-integration`,
};

export const hasAccessPermissionByRoleId = (userRoleId, accessibleRoleIds) => {
  if (!accessibleRoleIds) {
    return true;
  }
  if (!userRoleId) {
    return false;
  }
  if (Array.isArray(accessibleRoleIds)) {
    return accessibleRoleIds.includes(userRoleId);
  }
  return accessibleRoleIds === userRoleId;
};
