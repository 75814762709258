import PropTypes from 'prop-types';
import * as React from 'react';

const CodeField = ({
  record, source,
}) => <pre>{record?.[source]}</pre>;

CodeField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
CodeField.defaultProps = {
  record: {},
};

export default CodeField;
