/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import axios from 'axios';
import noop from 'lodash/noop';
import { useSelector } from 'react-redux';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { API_URL } from '../../constant';
import GroupingTable from './components/grouping-table';
import { canI, RbacAction } from '../../services/provider/rbacProvider';
import { useAuthUser } from '../../base/hooks';
import { checkDisabledUpdatePerm } from './utils';
import { RoleBuiltIn } from '../../services/util';

const PermTabContent = ({
  id,
  dataPerms,
  permissions,
  setPermissionActive = noop,
  readOnly,
  role,
  fetchPermByRole,
}) => {
  useEffect(() => {
    setPermissionActive(true);
    return function cleanUp() {
      setPermissionActive(false);
    };
  }, []);

  return (
    <GroupingTable
      id={id}
      permissions={permissions}
      dataPerms={dataPerms}
      readOnly={readOnly}
      role={role}
      fetchPermByRole={fetchPermByRole}
    />
  );
};

const RoleShow = props => {
  const {
    permissions, id,
  } = props;

  const [dataPerms, setDataPerms] = useState([]);

  const user = useAuthUser();
  const translate = useTranslate();

  const role = useSelector(({ admin }) => admin.resources.role?.data?.[id]) || {};

  const disabled = user.role?.level <= role?.level
    || [RoleBuiltIn.SYSTEM_ADMIN, RoleBuiltIn.GROUP_ADMIN].includes(role?.id)
    || checkDisabledUpdatePerm(role, user);

  const fetchPermByRole = async roleId => {
    if (canI(RbacAction.READ, 'perm', permissions)) {
      try {
        const res = await axios.get(API_URL.GET_PERM_BY_ROLE(roleId));
        if (Array.isArray(res?.data)) {
          setDataPerms(res.data);
        }
      } catch {
        // Do nothing!
      }
    }
  };

  useEffect(() => {
    if (id && permissions?.length) {
      fetchPermByRole(id);
    }
  }, [id, permissions]);

  const permissionSection = canI(RbacAction.READ, 'perm', permissions) && (
    <PermTabContent
      id={id}
      title={translate('resources.perm.name')} // Parent will use it
      dataPerms={dataPerms}
      permissions={permissions}
      readOnly={disabled || !canI(RbacAction.UPDATE, 'perm', permissions)}
      role={role}
      fetchPermByRole={fetchPermByRole}
    />
  );

  return (
    <WealthShowGuesser
      {...props}
      sections={[permissionSection]}
    />
  );
};

export default RoleShow;
