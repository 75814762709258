/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { SimpleForm, useShowController, useTranslate } from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import _ from 'lodash';
import { useFormState, useForm } from 'react-final-form';
import { Box } from '@material-ui/core';

import { useUpdateCustom, useAuthUser } from '../../base/hooks';
import { checkSystemAdmin } from '../../services/util';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';

import { ActionMenu } from './components/action.menu';

import { formatDynamicRuleFromRecord } from './inbox-rule.utils';
import PreviewStep from './components/steps/preview-step';

const InboxRuleShow = (props) => {
  const user = useAuthUser();
  const { record } = useShowController(props);
  const translate = useTranslate();

  const [step, setStep] = useState();
  const [dynamicGroup, setDynamicGroup] = useState();

  const isSystemAdmin = checkSystemAdmin(user);

  useEffect(() => {
    if (record) {
      const dynamicGroupValue = formatDynamicRuleFromRecord(record.groups);
      setDynamicGroup(dynamicGroupValue);
    }
  }, [record]);

  return (
    <WealthShowGuesser
      excludeFields={['name', 'message', 'desc', 'hub', 'type', 'externalId', 'condition', 'hub', 'groups', 'game']}
      actionMenu={<ActionMenu type="toolbar" />}
      {...props}
    >
      {/* <PreviewStep formData={dynamicGroup || {}} /> */}
      <PreviewStep formData={record || {}} />
    </WealthShowGuesser>
  );
};

export default InboxRuleShow;
