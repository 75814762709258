import React from 'react';
import { useTranslate } from 'ra-core';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles({
  btn: {
    marginLeft: 8,
    fontWeight: 'medium',
  },
  contentText: {
    color: grey[600],
  },
});

const ConfirmDialog = ({
  open,
  onDeny,
  onConfirm,
  disableDeny,
  disableConfirm,
  title,
  dialogTitle,
}) => {
  const translate = useTranslate();
  const classes = useStyle();

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={() => onDeny(false)}
    >
      <Box
        px={{
          xs: 5,
          md: 7,
        }}
        pt={{
          xs: 4,
          md: 6,
        }}
        pb={{
          xs: 2,
          md: 4,
        }}
      >
        <Box
          mb={3}
          component="h2"
          className="font-bold"
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Box>
        <Box>
          <DialogContentText
            className={classes.contentText}
            id="alert-dialog-description"
          >
            {title}
          </DialogContentText>
        </Box>
        <Box
          pt={2}
          align="right"
        >
          <Button
            className={classes.btn}
            onClick={() => onDeny(false)}
            color="primary"
            disabled={disableDeny}
          >
            {translate('ra.boolean.false')}
          </Button>
          <Button
            className={classes.btn}
            onClick={onConfirm}
            color="primary"
            autoFocus
            disabled={disableConfirm}
          >
            {translate('ra.boolean.true')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  dialogTitle: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disableDeny: PropTypes.bool,
  disableConfirm: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  disableDeny: false,
  disableConfirm: false,
};
