import CheatModeApplyShow from './cheat-mode-apply.show';
import CheatModeApplyList from './cheat-mode-apply.list';
import CheatModeApplyCreate from './cheat-mode-apply.create';
import CheatModeApplyEdit from './cheat-mode-apply.edit';

export default {
  create: CheatModeApplyCreate,
  show: CheatModeApplyShow,
  list: CheatModeApplyList,
  edit: CheatModeApplyEdit,
};
