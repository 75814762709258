/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { Children, isValidElement, cloneElement } from 'react';
import {
  getResources,
  Loading,
  useShowController,
  useTranslate,
  TextField,
  SimpleShowLayout,
  RecordContextProvider,
  useLocale,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { Box, Card, alpha, Link, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import { useSchema, useApiProperties, useAuthUser } from '../../hooks';
import useTranslateSchema from '../../hooks/useTranslateSchema';
import { checkArrayReferenceField } from '../../../services/util';
import { canI, RbacAction } from '../../../services/provider/rbacProvider';
import ToolbarMenu from '../action-menu/toolbar-menu';
import { Show } from '../ra/views';
import AuditLogSection from '../audit-log-section';
import { guessProperty } from './wealth-guesser';
import ClearCacheButton from './component/clear-cache-button';

const useStyles = makeStyles(
  theme => ({
    otherFieldsTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    btnAddNewBrand: {
      display: 'flex',
      alignItems: 'center',
      padding: 4,
      borderRadius: '50%',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      cursor: 'pointer',

      '&:hover': {
        background: alpha(theme.palette.primary.light, 0.12),
      },

      '& svg': {
        fontSize: 30,
        color: theme.palette.success.main,
      },
    },
    sectionArrayField: {
      width: '100%',
      padding: '32px 50px',

      '& .ra-field': {
        width: '100%',
      },

      '& table': {
        border: '1px solid #E0E0E0',
      },
    },
    sectionWrapper: {
      padding: '32px 50px',

      '& .ra-field': {
        width: '330px',
        background: '#F0F1F2',
        borderRadius: '4px',
        padding: '10px 16px',
        wordBreak: 'break-all',
      },

      [theme.breakpoints.down('sm')]: {
        '& .ra-field': {
          width: '100%',
          minWidth: '330px',
        },
      },
    },
    fieldsInOneLine: {
      '& .ra-field': {
        width: '100%',
        background: '#F0F1F2',
        borderRadius: '4px',
        padding: '10px 16px',
        wordBreak: 'break-all',
        marginBottom: '16px',
      },
    },
    otherInfoSection: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    childrenSection: {
      padding: '0px 50px',
      '& .ra-field': {
        marginBottom: '16px',
      },
    },
    sectionTitle: {
      fontWeight: 700,
      fontSize: '24px',
      marginBottom: '15px',
    },
    root: {
      padding: 0,

      display: 'flex',
      gap: '20px',
      flexWrap: 'wrap',
    },
  }),
  {
    name: 'WealthShowGuesser',
  },
);

const WealthShowGuesser = props => {
  const resources = useSelector(getResources);
  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();
  const translateSchema = useTranslateSchema();
  const { getPropertiesByFieldName } = useApiProperties();

  const showController = useShowController(props);
  const { record } = showController;
  const {
    basePath,
    hasShow,
    resource,
    actionMenu,
    extendsActionMenu,
    children,
    metaDataComponents,
    excludeFields = [],
    sections: extraSections = [],
    forceEditAble = null,
    permissions,
    extraBlocks,
    extraInformBlocks,
    informFieldOrder,
    ...rest
  } = props;

  const {
    options: {
      approvable, submittable,
    },
  } = resources.find(r => r.name === resource);

  if (!hasShow) {
    throw new Error('This resource does not support show');
  }

  const {
    api, ref,
  } = useSchema();

  const user = useAuthUser();

  if (api) {
    const { paths } = api;
    const responseSchema = paths?.[basePath]?.get?.responses?.['200']?.content?.['application/json']?.schema;
    let responseComponent;
    let responseRef;
    if (responseSchema?.oneOf) {
      responseRef = responseSchema.oneOf.filter(r => r.type === 'array')?.[0]?.items;

      responseRef = responseRef?.$ref || responseRef?.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

      responseComponent = ref.get(responseRef);
    }
    const { properties } = responseComponent;

    Object.keys(properties)
      .filter(key => properties[key]?.properties?.hide?.default || key.startsWith('_'))
      .forEach(i => {
        delete properties[i];
      });

    const GENERAL_FIELD_KEYS = ['created', 'updated', 'enabled'];

    const validSources = Object.keys(properties)
      ?.filter(source => !excludeFields?.includes(source) && record?.[source] !== undefined)
      ?.filter(
        source => (!checkArrayReferenceField(properties?.[source])
            || (properties?.[source]?.type === 'array' && source === 'blobs'))
          && source !== 'attachment',
      );

    const generalSources = validSources?.filter(source => GENERAL_FIELD_KEYS.includes(source));
    const fieldsInOneLine = validSources?.filter(source => {
      const { format } = getPropertiesByFieldName(source);
      if (format === 'json') {
        GENERAL_FIELD_KEYS.push(source);
        return true;
      }
      return false;
    });

    const otherSources = validSources?.filter(source => !GENERAL_FIELD_KEYS.includes(source));

    const generalFields = [
      <TextField
        key="id"
        source="id"
        label={`resources.${resource}.fields.id`}
        hasShow
        resource={resource}
      />,
      ...generalSources.map(source => guessProperty({
        source,
        properties,
        apiRef: ref,
        resource,
        resources,
        hasShow,
        record,
        translate,
        locale,
        permissions,
      })),
    ];

    const fieldsInOneLineComponent = fieldsInOneLine.map(source => guessProperty({
      source,
      properties,
      apiRef: ref,
      resource,
      resources,
      hasShow,
      record,
      locale,
      translate,
      permissions,
    }));

    const otherFields = [
      ...otherSources?.map(source => guessProperty({
        source,
        properties,
        apiRef: ref,
        resource,
        resources,
        hasShow,
        record,
        locale,
        translate,
        permissions,
      })),
      ...Children.map(extraInformBlocks, field => (field && isValidElement(field) ? field : null)).filter(
        item => item,
      ),
    ];

    // To customize the order of fields
    if (Array.isArray(informFieldOrder)) {
      otherFields.sort(
        (a, b) => informFieldOrder.indexOf(a?.props?.source) - informFieldOrder.indexOf(b?.props?.source),
      );
    }

    const arrayFields = Object.keys(properties)
      ?.filter(
        pKey => pKey !== 'blobs' && !properties?.[pKey]?.deprecated && checkArrayReferenceField(properties?.[pKey]),
      )
      ?.filter(source => !excludeFields?.includes(source))
      ?.map(pKey => {
        const tabContent = guessProperty({
          source: pKey,
          properties,
          apiRef: ref,
          resource,
          resources,
          hasShow,
          record,
          locale,
          translate,
          permissions,
        });

        return tabContent;
      });

    const actions = actionMenu
      ? {
        actions: cloneElement(actionMenu, {
          basePath,
          record,
          resource,
          submittable,
          approvable,
          forceEditAble,
          metaDataComponents,
        }),
      }
      : {
        actions: (
          <ToolbarMenu
            basePath={basePath}
            record={record}
            resource={resource}
            submittable={submittable}
            approvable={approvable}
            metaDataComponents={metaDataComponents}
            forceEditAble={forceEditAble}
          />
        ),
      };

    const realResource = resource.includes('/') ? resource.split('/')[1] : resource;

    // Change Logs / History Logs for specific resource
    const resourceAbleToShowAuditLog = ['bet-setting', 'bet-setting-default'];
    const canShowAuditLog = canI(RbacAction.READ, 'audit-log', permissions) && resourceAbleToShowAuditLog.includes(resource);
    const auditLogSection = canShowAuditLog && (
      <AuditLogSection
        resourceName={resource}
        recordId={record?.id}
      />
    );

    const fieldsInAnotherSection = [...extraSections, ...arrayFields];

    const BtnCreateNewBrand = ({ source }) => {
      if (resource === 'group' && source === 'brands') {
        return (
          <Link
            href={`/#/brand/create?groupId=${record?.id}`}
            underline="none"
            style={{
              marginLeft: 16,
            }}
          >
            <Tooltip
              title={translate('ra.action.addNewBrand')}
              placement="top"
            >
              <Box className={classes.btnAddNewBrand}>
                <AddIcon />
              </Box>
            </Tooltip>
          </Link>
        );
      }
      return null;
    };

    return (
      <RecordContextProvider value={record}>
        <Show
          component={Box}
          {...rest}
          resource={resource}
          basePath={basePath}
          title={translate('ra.page.show', {
            name: translate(`resources.${realResource}.name`),
            id: record?.id,
          })}
          actions={(
            <CustomAction
              {...actions}
              user={user}
              extendsActionMenu={extendsActionMenu}
              basePath={basePath}
              record={record}
              resource={resource}
              submittable={submittable}
              approvable={approvable}
            />
          )}
        >
          <>
            <Card>
              <ShowSection
                title={translate('wa.common.general')}
                record={record}
                {...showController}
              >
                {generalFields}
              </ShowSection>
              <ShowSection
                title={translateSchema({
                  name: 'ra.text.informationOf',
                  params: {
                    smart_name: `resources.${realResource}.name`,
                  },
                })}
                record={record}
                className={classes.otherInfoSection}
                {...showController}
              >
                {otherFields}
              </ShowSection>
              <SimpleShowLayout
                className={classes.childrenSection}
                {...showController}
              >
                {Children.map(children, field => (field && isValidElement(field) ? field : null))}
              </SimpleShowLayout>

              <SimpleShowLayout
                className={classnames(classes.childrenSection, classes.fieldsInOneLine)}
                {...showController}
              >
                {Children.map(fieldsInOneLineComponent, field => (field && isValidElement(field) ? field : null))}
                {extraBlocks}
              </SimpleShowLayout>
            </Card>

            {auditLogSection}

            {fieldsInAnotherSection.length > 0 && (
              <Card
                style={{
                  marginTop: '24px',
                }}
              >
                {Children.map(fieldsInAnotherSection, field => (field && isValidElement(field) ? (
                  <OtherFieldSection
                    key={field?.props?.source}
                    title={
                      field?.props?.title ?? (
                        <Box className={classes.otherFieldsTitle}>
                          {translate(`resources.${resource}.fields.${field?.props?.source}`)}
                          <BtnCreateNewBrand source={field?.props?.source} />
                        </Box>
                      )
                    }
                    className={classes.sectionArrayField}
                    classes={classes}
                    {...showController}
                  >
                    {cloneElement(field, {
                      ...showController,
                    })}
                  </OtherFieldSection>
                ) : null))}
                {children}
              </Card>
            )}
          </>
        </Show>
      </RecordContextProvider>
    );
  }

  return <Loading />;
};

export function ShowSection({
  title, record, children: fields, className, ...rest
}) {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.sectionWrapper, className)}>
      <Box className={classes.sectionTitle}>{title}</Box>
      <SimpleShowLayout
        className={classes.root}
        record={record}
        {...rest}
      >
        {fields}
      </SimpleShowLayout>
    </Box>
  );
}

export function OtherFieldSection({
  title, classes = {}, record, children: fields, className, ...rest
}) {
  return (
    <Box className={className}>
      <Box className={classes.sectionTitle}>{title}</Box>
      <SimpleShowLayout
        className={classes.root}
        record={record}
        {...rest}
      >
        {fields}
      </SimpleShowLayout>
    </Box>
  );
}

const CustomAction = ({
  actions, extendsActionMenu, ...props
}) => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    {extendsActionMenu || <Box />}
    <Box
      style={{
        width: '100%',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'right',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <ClearCacheButton
          {...props}
          isShowView
        />
        {cloneElement(actions, {
          ...props,
        })}
      </Box>
    </Box>
  </Box>
);

WealthShowGuesser.propTypes = {
  basePath: PropTypes.string,
  hasShow: PropTypes.bool,
  resource: PropTypes.string,
  record: PropTypes.object,
  actionMenu: PropTypes.object,
  id: PropTypes.string.isRequired,
  extraBlocks: PropTypes.arrayOf(PropTypes.node),
  extraInformBlocks: PropTypes.arrayOf(PropTypes.node),
  informFieldOrder: PropTypes.arrayOf(PropTypes.string),
};

WealthShowGuesser.defaultProps = {
  basePath: null,
  hasShow: false,
  record: null,
  actionMenu: null,
  resource: '',
  extraBlocks: [],
  extraInformBlocks: [],
  informFieldOrder: null,
};

export default WealthShowGuesser;
