/* eslint-disable */
import React from 'react';
import { CoreAdminContext, CoreAdminUI } from 'ra-core';
import CustomCoreAdminUI from './CustomCoreAdminUI';

const CustomCoreAdmin = ({
  appLayout,
  authProvider,
  catchAll,
  children,
  customReducers,
  customRoutes = [],
  customSagas,
  dashboard,
  dataProvider,
  disableTelemetry,
  history,
  i18nProvider,
  initialState,
  layout,
  loading,
  loginPage,
  logoutButton,
  menu, // deprecated, use a custom layout instead
  theme,
  title = 'React Admin',
  publicCustomRoutes,
}) => (
  <CoreAdminContext
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    history={history}
    customReducers={customReducers}
    customSagas={customSagas}
    initialState={initialState}
  >
    <CustomCoreAdminUI
      layout={appLayout || layout}
      customRoutes={customRoutes}
      dashboard={dashboard}
      disableTelemetry={disableTelemetry}
      menu={menu}
      catchAll={catchAll}
      theme={theme}
      title={title}
      loading={loading}
      loginPage={loginPage}
      logout={authProvider ? logoutButton : undefined}
      publicCustomRoutes={publicCustomRoutes}
    >
      {children}
    </CustomCoreAdminUI>
  </CoreAdminContext>
);

export default CustomCoreAdmin;
