import React from 'react';
import { useTranslate, CreateButton as DefaultCreateButton } from 'react-admin';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@material-ui/core';

const CreateButton = ({
  label, basePath, tooltipProps, ...otherProps
}) => {
  const translate = useTranslate();

  return (
    <Tooltip
      title={label || translate('ra.action.create')}
      placement="top"
      {...tooltipProps}
    >
      <Box>
        <DefaultCreateButton
          basePath={basePath}
          label={label}
          {...otherProps}
        />
      </Box>
    </Tooltip>
  );
};

export default CreateButton;

CreateButton.propTypes = {
  label: PropTypes.string,
  basePath: PropTypes.string.isRequired,
  tooltipProps: PropTypes.object,
};

CreateButton.defaultProps = {
  label: '',
  tooltipProps: {},
};
