/* eslint-disable  */
import React, { useState } from "react";
import {
  FormDataConsumer,
  required,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";
import {
  BooleanInput,
  NumberInput,
  TextInput,
  SelectInput,
} from "../../../base/components/ra/inputs";
import { useForm } from "react-final-form";

const NumberInputCom = (props) => {
  const form = useForm();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        let disabled = false;
        const index = new RegExp(/(?:\[)(.*?)(?=\])/).exec(props.source);

        if (index?.[1]) {
          disabled =
            formData.scripts?.[index[1]]?.mode === "IS_TRIGGER_FREE_SPINS";

          if (disabled && formData.scripts?.[index[0]]?.remainingSpin !== 1) {
            form.change(props.source, 1);
          }
        }

        return (
          <NumberInput
            defaultValue={1}
            disabled={disabled}
            validate={[required()]}
            onChange={(e) => {
              let { value: correctValue } = e.target;

              correctValue = parseInt(correctValue, 10);

              if (!correctValue || correctValue < 1 || isNaN(correctValue)) {
                correctValue = 1;
              }

              if (correctValue > 100) {
                correctValue = 100;
              }

              form.change(props.source, correctValue);
            }}
            {...props}
          />
        );
      }}
    </FormDataConsumer>
  );
};

const CheatModeTemplateInput = () => {
  const cheatModeEnum = [
    { id: "IS_TRIGGER_WIN", name: "IS_TRIGGER_WIN" },
    { id: "IS_TRIGGER_BIG_WIN", name: "IS_TRIGGER_BIG_WIN" },
    { id: "IS_TRIGGER_MEGA_WIN", name: "IS_TRIGGER_MEGA_WIN" },
    { id: "IS_TRIGGER_SUPER_MEGA_WIN", name: "IS_TRIGGER_SUPER_MEGA_WIN" },
    { id: "IS_TRIGGER_FREE_SPINS", name: "IS_TRIGGER_FREE_SPINS" },
    { id: "NORMAL_SPIN", name: "NORMAL_SPIN" },
  ];

  const form = useForm();

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const scriptTriggerFreeSpin = formData.scripts?.find(
          (i) => i?.mode === "IS_TRIGGER_FREE_SPINS"
        );

        let disableAdd = false;

        if (!!scriptTriggerFreeSpin) {
          if (formData.scripts?.length > 1) {
            form.change("scripts", [scriptTriggerFreeSpin]);
          }
          disableAdd = true;
        }

        return (
          <ArrayInput
            source="scripts"
            className="arr-templates"
            validate={required()}
          >
            <SimpleFormIterator disableAdd={disableAdd}>
              <SelectInput
                source="mode"
                style={{ width: "50%" }}
                label="resources.cheat-mode.name"
                choices={cheatModeEnum}
                validate={required()}
              />
              <NumberInputCom
                min={1}
                step={1}
                style={{ width: "50%" }}
                max={100}
                source="remainingSpin"
                label="resources.cheat-mode.fields.remainingSpin"
              />
            </SimpleFormIterator>
          </ArrayInput>
        );
      }}
    </FormDataConsumer>
  );
};

export default CheatModeTemplateInput;
