import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import resourceSlug from '../../../constant/resource-slug';

const NativeIdField = props => (
  <ReferenceField
    source="playerId"
    reference={resourceSlug.PLAYER}
    link="show"
    {...props}
  >
    <TextField source="nativeId" />
  </ReferenceField>
);

export default NativeIdField;
