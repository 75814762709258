import React from 'react';
import { FormDataConsumer, required, regex } from 'react-admin';
import CustomSelectConfig, {
  customFieldsConfig,
} from './components/customSelectConfig';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import { checkCheatModeEnv } from '../../services/util';
import { TextInput } from '../../base/components/ra/inputs';
import { REGEX_URL } from '../../services/util/validate/regularExpression';
import WealthInputWrapper from '../../base/components/guesser/wealth-input-wrapper';

const WalletIntegrationEdit = props => {
  const isCheatModeEnv = checkCheatModeEnv();

  const igrogeEditBalance = isCheatModeEnv
    ? []
    : ['createPlayerUrl', 'editBalanceUrl'];

  const payoutPlaceBetUrl = ['payOutUrl', 'placeBetUrl'];

  const tmpCustomFieldsConfig = [
    ...customFieldsConfig,
    ...igrogeEditBalance,
    ...payoutPlaceBetUrl,
  ];

  const validate = [required(), regex(REGEX_URL, 'wa.exception.invalidUrl')];

  return (
    <WealthEditGuesser
      {...props}
      customFields={tmpCustomFieldsConfig}
    >
      <FormDataConsumer>
        {({ formData }) => (formData.implementation === 'SEAMLESS'
          ? payoutPlaceBetUrl.map(field => (
            <WealthInputWrapper
              source={field}
              validate={validate}
            >
              <TextInput />
            </WealthInputWrapper>
          ))
          : null)}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => customFieldsConfig.map(field => (
          <CustomSelectConfig
            source={field}
            implementation={formData.implementation}
            {...props}
          />
        ))}
      </FormDataConsumer>
    </WealthEditGuesser>
  );
};

export default WalletIntegrationEdit;
